import { useMemo } from 'react';
import styles from './Icon.module.scss';
import { ReactComponent as CaretDown } from './svg/caret-down.svg';
import { ReactComponent as CaretUp } from './svg/caret-up.svg';
import { ReactComponent as Checkmark } from './svg/checkmark.svg';
import { ReactComponent as LogoFull } from './svg/logo-full.svg';
import { ReactComponent as FilledEditPen } from './svg/filled-edit-pen.svg';
import { ReactComponent as NoIssues } from './svg/no-issues.svg';
import { ReactComponent as IssuesFound } from './svg/issues-found.svg';
import { ReactComponent as ToBeInspected } from './svg/to-be-inspected.svg';
import { ReactComponent as HalfCircle } from './svg/half-circle.svg';
import { ReactComponent as TreeWithRoots } from './svg/tree-with-roots.svg';
import { ReactComponent as TreeWithRootsSelected } from './svg/tree-with-roots-selected.svg';
import { ReactComponent as Sorting } from './svg/sorting.svg';
import { ReactComponent as SortingDown } from './svg/sorting-down.svg';
import { ReactComponent as SortingUp } from './svg/sorting-up.svg';
import { ReactComponent as TreeOutline } from './svg/tree-outline.svg';
import { ReactComponent as PercentOutline } from './svg/percent-outline.svg';
import { ReactComponent as SlideIn } from './svg/slide-in.svg';
import { ReactComponent as Grid } from './svg/grid.svg';
import { ReactComponent as Frame } from './svg/frame.svg';

export function FullLogoIcon() {
  return <Icon name="logo-full" />;
}

export function NoIssuesIcon() {
  return <Icon name="no-issues" />;
}

export function IssuesFoundIcon() {
  return <Icon name="issues-found" />;
}

export function ToBeInspectedIcon() {
  return <Icon name="to-be-inspected" />;
}

export function CaretUpIcon(props: { size?: number }) {
  return <Icon size={props.size} name="caret-up" />;
}

export function CaretDownIcon(props: { size?: number }) {
  return <Icon size={props.size} name="caret-down" />;
}

export function CheckMarkIcon() {
  return <Icon name="checkmark" />;
}

export function FilledEditPenIcon() {
  return <Icon name="filled-edit-pen" />;
}

export function HalfCircleIcon() {
  return <Icon name="half-circle" size={28} />;
}

export function TreeWithRootsIcon() {
  return <Icon name="tree-with-roots" size={28} />;
}

export function TreeWithRootsSelectedIcon() {
  return <Icon name="tree-with-roots-selected" size={28} />;
}

export function SortingIcon() {
  return <Icon name="sorting" />;
}

export function SortingDownIcon() {
  return <Icon name="sorting-down" />;
}

export function SortingUpIcon() {
  return <Icon name="sorting-up" />;
}

export function TreeOutlineIcon() {
  return <Icon name="tree-outline" />;
}
export function PercentOutlineIcon() {
  return <Icon name="percent-outline" />;
}

export function SlideInIcon() {
  return <Icon name="slide-in" size={24} />;
}

export function GridIcon() {
  return <Icon name="grid" size={20} />;
}

export function FrameIcon() {
  return <Icon name="frame" size={20} />;
}

function Icon({ name, size = 16 }: { name: string, size?: number }) {
  const svg = useMemo(() => {
    switch (name) {
    case 'checkmark':
      return <Checkmark />;
    case 'caret-down':
      return <CaretDown />;
    case 'caret-up':
      return <CaretUp />;
    case 'logo-full':
      return <LogoFull />;
    case 'filled-edit-pen':
      return <FilledEditPen />;
    case 'no-issues':
      return <NoIssues />;
    case 'to-be-inspected':
      return <ToBeInspected />;
    case 'issues-found':
      return <IssuesFound />;
    case 'half-circle':
      return <HalfCircle />;
    case 'tree-with-roots':
      return <TreeWithRoots />;
    case 'tree-with-roots-selected':
      return <TreeWithRootsSelected />;
    case 'sorting':
      return <Sorting />;
    case 'sorting-down':
      return <SortingDown />;
    case 'sorting-up':
      return <SortingUp />;
    case 'tree-outline':
      return <TreeOutline />;
    case 'percent-outline':
      return <PercentOutline />;
    case 'slide-in':
      return <SlideIn />;
    case 'grid':
      return <Grid />;
    case 'frame':
      return <Frame />;
    default:
      return 'Icon not found';
    }
  }, [name]);

  const style = name === 'logo-full'
    ? { width: 'auto', height: 'auto' }
    : { width: size, height: size };

  return (
    <div
      className={styles.container}
      style={style}
    >
      {svg}
    </div>
  );
}
