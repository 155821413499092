import styles from './AdvancedFilterValueSelector.module.scss';
import { AdvancedFilterType } from '../advanced-filter/AdvancedFilter';
import { useEffect, useState } from 'react';
import Input from '../../../../components/UI/Input/Input';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import { useTranslation } from 'react-i18next';

export default function AdvancedFilterValueSelector(props: AdvancedFilterValueSelectorProps) {
  const { t } = useTranslation();

  const [min, setMin] = useState<string>(props.min?.toString() || '');
  const [max, setMax] = useState<string>(props.max?.toString() || '');

  const validateNumber = text => text !== '' && !Number.isNaN(Number(text));

  const [error, setError] = useState('');

  const validateValue = (value, type: 'min' | 'max') => {
    let currentError = '';

    let minValue, maxValue;

    if (!validateNumber(value)) {
      props.onChange(null, null);
    }

    if (type === 'min') {
      minValue = Number(value);
      maxValue = Number(max);
    }

    if (type === 'max') {
      minValue = Number(min);
      maxValue = Number(value);
    }

    if (props.propertyConfig) {
      currentError = getRangeError(value, props.propertyConfig);

      setError(currentError);
    }

    if (currentError !== '') return;

    onValueChange(minValue, maxValue);
  };

  const validateByType = (predicateType: AdvancedFilterType) => {
    let currentError = '';

    if (!props.propertyConfig) return;

    let values: string[] = [];

    if (predicateType === AdvancedFilterType.MAX) {
      values = [max];
    }

    if (predicateType === AdvancedFilterType.MIN) {
      values = [min];
    }

    if (predicateType === AdvancedFilterType.RANGE) {
      values = [min, max];
    }

    if (!values.every(validateNumber)) return;
    const numValues = values.map(it => Number(it));
    currentError = numValues.reduce((acc, it) => acc !== '' ? getRangeError(it, props.propertyConfig!) : acc, '');
    setError(currentError);
    if (currentError !== '') return;

    onValueChange(Number(min), Number(max));
  };

  const onValueChange = (minValue, maxValue) => {
    if (props.predicateType === AdvancedFilterType.RANGE) {
      props.onChange(minValue, maxValue);
    }
    if (props.predicateType === AdvancedFilterType.MIN) {
      props.onChange(minValue, null);
    }
    if (props.predicateType === AdvancedFilterType.MAX) {
      props.onChange(null, maxValue);
    }
  };

  useEffect(() => {
    validateByType(props.predicateType);
  }, [props.predicateType]);

  const getRangeError = (value: number, propertyConfig: PropertyConfiguration) => {
    if (value > propertyConfig.getMinMaxValues().max) {
      return t('treeList.advancedFiltering.errorLessThan', { value: propertyConfig.getMinMaxValues().max.toFixed(2) });
    }

    if (value < propertyConfig.getMinMaxValues().min) {
      return t('treeList.advancedFiltering.errorGreaterThan', { value: propertyConfig.getMinMaxValues().min.toFixed(2) });
    }

    return '';
  };

  return (
    <>
      <div data-testid='advanced-filter-value-selector'>
        <div className={styles.inputContainer}>
          <Input
            className={`${[AdvancedFilterType.MIN, AdvancedFilterType.RANGE].includes(props.predicateType) ? styles.visible : styles.filterValueInput} ${styles.filterInputInner}`}
            label={''}
            value={min}
            onValueChange={e => { setMin(e); validateValue(e, 'min'); }}
            inputClassName={styles.filterInputInner}
            testId='advanced-filter-input-min'
          ></Input>
          <Input
            className={`${[AdvancedFilterType.MAX, AdvancedFilterType.RANGE].includes(props.predicateType) ? styles.visible : styles.filterValueInput} ${styles.filterInputInner}`}
            label={''}
            value={max}
            onValueChange={e => { setMax(e); validateValue(e, 'max'); }}
            inputClassName={styles.filterInputInner}
            testId='advanced-filter-input-max'
          ></Input>
        </div>
      </div>
      <div className={styles.error}>
        {error}
      </div>
    </>
  );
}

interface AdvancedFilterValueSelectorProps {
  min: number | null,
  max: number | null,
  onChange: (min, max) => unknown,
  propertyConfig: PropertyConfiguration | undefined,
  predicateType: AdvancedFilterType
}
