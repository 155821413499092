import styles from '../Inventory.module.scss';
import { useTranslation } from 'react-i18next';
import DetailedTree from '../../../../../tree/DetailedTree';
import { useContext, useState } from 'react';
import { DetailsContext, ExtraObject } from '../../../LegacyDetails';
import { DisplayableTreeProperty, Tree } from '../../../../../tree/Tree';
import MoreOptionsSelector from '../../components/DataGroupHeader/MoreOptionsSelector';
import EyeIcon from '../../components/EyeIcon';
import SelectedEyeIcon from '../../components/SelectedEyeIcon';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import Input from '../../../../../components/UI/Input/Input';
import { useDebouncedCallback } from '../../../../../hooks/useDebouncedCallback';
import { TrackableTreeProperty } from '../../../../../tree/TrackableTreeProperty';
import { Organization } from '../../../../../organization/Organization';
import useMountedEffect from '../../../../../hooks/useMountedEffect';
import EditingFieldWrapper from '../../components/EditingFieldWrapper/EditingFieldWrapper';

export default function MetricsDataGroup(props: MetricsDataGroupProps) {
  const { rulers, setRulers, setHover, rulersAndHover } = useContext(DetailsContext);
  const { urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  const initialMetrics = {
    canopyHeight: Number(props.tree.canopyHeight?.toFixed(2)) || '',
    canopyWidth: Number(props.tree.canopyWidth?.toFixed(2)) || '',
    trunkDiameter: Number(props.tree.trunkDiameter?.toFixed(2)) || '',
    numberOfStems: props.tree.numberOfStems || '',
    height: Number(props.tree.height?.toFixed(2)) || '',
    trunkHeight: Number(props.tree.trunkHeight?.toFixed(2)) || '',
    leafAreaIndex: Number(props.tree.leafAreaIndex?.toFixed(2)) || '',
    crownLightExposure: Number(props.tree.crownLightExposure?.toFixed(2)) || '',
    canopyCircumference: Number(props.tree.canopyCircumference?.toFixed(2)) || ''
  };

  const [metrics, setMetrics] = useState(initialMetrics);

  const toggleProperty = (property: ExtraObject) => {
    if (rulers.includes(property)) {
      const index = rulers.indexOf(property);
      rulers.splice(index, 1);
      setRulers([...rulers]);
    } else {
      setRulers((prev: ExtraObject[]) => [...prev, property]);
    }
  };

  const toggleAllProperties = () => {
    if (rulers.length === 5) {
      setRulers([]);
    } else {
      setRulers([
        DisplayableTreeProperty.CanopyHeight,
        DisplayableTreeProperty.CanopyWidth,
        DisplayableTreeProperty.TrunkDiameter,
        DisplayableTreeProperty.TrunkHeight,
        DisplayableTreeProperty.Height
      ]);
    }
  };

  useMountedEffect(() => {
    if (urlContext.getSelectedDataPanelTab() !== null) {
      setRulers([]);
    }
  }, [JSON.stringify(urlContext.getSelectedDataPanelTab())]);

  return (
    <div className={styles.dataGroup}>
      <h3 className={styles.groupHeader}>
        <div className={styles.editableTitle}>
          {t('treeDetails.inventory.metrics')}
          <MoreOptionsSelector eyeAction={toggleAllProperties} />
          {rulers.length === 5 &&
            <div onClick={toggleAllProperties} className={styles.eyeContainer}>
              <SelectedEyeIcon />
            </div>
          }
        </div>
      </h3>
      <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : ''}`}>
        <EditingFieldWrapper
          title={t('details.properties.canopyHeight')}
          value={props.tree.canopyHeight}
          unit={Tree.getUnit(DisplayableTreeProperty.CanopyHeight, props.organization)}
          onClick={() => toggleProperty(DisplayableTreeProperty.CanopyHeight)}
          chartPropertyOnHover={TrackableTreeProperty.CanopyHeight}
        >
          <>
            {props.tree.canopyHeight &&
              <RulerToggle
                property={DisplayableTreeProperty.CanopyHeight}
                setHover={setHover}
                selected={rulersAndHover.includes(DisplayableTreeProperty.CanopyHeight)}
              />
            }
          </>
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.height')}
          value={props.tree.height}
          unit={Tree.getUnit(DisplayableTreeProperty.Height, props.organization)}
          onClick={() => toggleProperty(DisplayableTreeProperty.Height)}
          chartPropertyOnHover={TrackableTreeProperty.Height}
        >
          <>
            {props.tree.height &&
              <RulerToggle
                property={DisplayableTreeProperty.Height}
                setHover={setHover}
                selected={rulersAndHover.includes(DisplayableTreeProperty.Height)}
              />
            }
          </>
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.canopyWidth')}
          value={props.tree.canopyWidth}
          unit={Tree.getUnit(DisplayableTreeProperty.CanopyWidth, props.organization)}
          onClick={() => toggleProperty(DisplayableTreeProperty.CanopyWidth)}
          chartPropertyOnHover={TrackableTreeProperty.CanopyWidth}
        >
          <>
            {props.tree.canopyWidth &&
              <RulerToggle
                property={DisplayableTreeProperty.CanopyWidth}
                setHover={setHover}
                selected={rulersAndHover.includes(DisplayableTreeProperty.CanopyWidth)}
              />
            }
          </>
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.trunkHeight')}
          value={props.tree.trunkHeight}
          unit={Tree.getUnit(DisplayableTreeProperty.TrunkHeight, props.organization)}
          onClick={() => toggleProperty(DisplayableTreeProperty.TrunkHeight)}
          chartPropertyOnHover={TrackableTreeProperty.TrunkHeight}
        >
          <>
            {props.tree.trunkHeight &&
              <RulerToggle
                property={DisplayableTreeProperty.TrunkHeight}
                setHover={setHover}
                selected={rulersAndHover.includes(DisplayableTreeProperty.TrunkHeight)}
              />
            }
          </>
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.trunkDiameter')}
          value={props.tree.trunkDiameter}
          unit={Tree.getUnit(DisplayableTreeProperty.TrunkDiameter, props.organization)}
          onClick={() => toggleProperty(DisplayableTreeProperty.TrunkDiameter)}
          chartPropertyOnHover={TrackableTreeProperty.TrunkDiameter}
        >
          <>
            {props.tree.trunkDiameter &&
              <RulerToggle
                property={DisplayableTreeProperty.TrunkDiameter}
                setHover={setHover}
                selected={rulersAndHover.includes(DisplayableTreeProperty.TrunkDiameter)}
              />
            }
          </>
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.numberOfStems')}
          value={props.tree.numberOfStems || ''}
        >
          <Input
            label={''}
            type="number"
            value={metrics.numberOfStems}
            onChange={event => setMetrics(prev => ({ ...prev, numberOfStems: Number(event.target.value) }))}
            className={styles.editingInput}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.leafAreaIndex')}
          value={props.tree.leafAreaIndex}
          chartPropertyOnHover={TrackableTreeProperty.LeafAreaIndex}
        >
          <Input
            label={''}
            type="number"
            value={metrics.leafAreaIndex}
            onChange={event => setMetrics(prev => ({ ...prev, leafAreaIndex: Number(event.target.value) }))}
            className={styles.editingInput}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('tree.crownLightExposure')}
          value={props.tree.crownLightExposure || ''}
        >
          <Input
            label={''}
            type="number"
            value={metrics.crownLightExposure}
            onChange={event => setMetrics(prev => ({ ...prev, crownLightExposure: Number(event.target.value) }))}
            className={styles.editingInput}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('tree.canopyCircumference')}
          value={props.tree.canopyCircumference || ''}
        >
          <Input
            label={''}
            type="number"
            value={metrics.canopyCircumference}
            onChange={event => setMetrics(prev => ({ ...prev, canopyCircumference: Number(event.target.value) }))}
            className={styles.editingInput}
          />
        </EditingFieldWrapper>
      </div>
    </div>
  );
}

interface MetricsDataGroupProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organization: Organization
}

interface RulerToggleProps {
  property: ExtraObject,
  setHover: (property: ExtraObject | null) => void,
  selected: boolean
}

function RulerToggle(props: RulerToggleProps) {
  const handleMouseEnter = useDebouncedCallback(() => {
    props.setHover(props.property);
  }, 50);

  const handleMouseLeave = useDebouncedCallback(() => {
    props.setHover(null);
  }, 50);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.selected ? <SelectedEyeIcon /> : <EyeIcon />}
    </div>
  );
}
