import { CSSProperties, ReactChild, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { ArrowLeft, ArrowRight, Xmark } from 'iconoir-react';
import styles from './FullScreenImageViewer.module.scss';
import { FunctionButton } from '../UI/Button/LegacyButton';

interface FullScreenImageViewerProps {
  url: string,
  onClose: () => unknown,
  galleryUrls?: string[],
  setImgUrl?: (url: string) => void
}

export default function FullScreenImageViewer(props: FullScreenImageViewerProps) {
  const [isZoomedIn, setZoomState] = useState(false);
  const toggleZoom = () => setZoomState(state => !state);
  const imgStyle: CSSProperties = { ...(isZoomedIn ? { transform: 'scale(2)' } : {}) };
  const className = [styles.image, isZoomedIn ? styles.zoomedIn : ''].join(' ');

  const [isNavigatingLeft, setIsNavigatingLeft] = useState(false);
  const [isNavigatingRight, setIsNavigatingRight] = useState(false);

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        props.onClose();
      }
      if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
        navigateToPrev();
        setIsNavigatingLeft(true);
        setTimeout(() => {
          setIsNavigatingLeft(false);
        }, 400);
      }
      if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
        navigateToNext();
        setIsNavigatingRight(true);
        setTimeout(() => {
          setIsNavigatingRight(false);
        }, 400);
      }
    };

    window.addEventListener('keydown', keydownListener);

    return () => {
      window.removeEventListener('keydown', keydownListener);
    };
  });

  const galleryNavigationUrls = useMemo(() => {
    if (!props.galleryUrls) return;
    const index = props.galleryUrls?.findIndex(it => it === props?.url);
    return {
      prev: props.galleryUrls[index - 1],
      next: props.galleryUrls[index + 1]
    };
  }, [props.galleryUrls, props?.url]);

  const hasPrevImg = () => {
    return (galleryNavigationUrls?.prev && props.setImgUrl);
  };

  const hasNextImg = () => {
    return (galleryNavigationUrls?.next && props.setImgUrl);
  };

  const navigateToPrev = () => {
    if (hasPrevImg()) {
      props.setImgUrl!(galleryNavigationUrls!.prev);
    }
  };

  const navigateToNext = () => {
    if (hasNextImg()) {
      props.setImgUrl!(galleryNavigationUrls!.next);
    }
  };

  return (
    <Container>
      <div className={styles.content} data-testid="fullscreen-image-viewer">
        <div className={styles.controls}>
          <button className={styles.closeButton} onClick={props.onClose}>
            <Xmark strokeWidth={2} />
          </button>
        </div>

        <div className={styles.imageContainer}>
          {hasPrevImg() ?
            <FunctionButton
              className={`${styles.navigateButton} ${isNavigatingLeft ? styles.highlightArrowButton : ''}`}
              icon={<ArrowLeft/>}
              onClick={navigateToPrev}
            />
            :
            <div className={styles.buttonPlaceholder}/>
          }
          <img
            style={imgStyle}
            onClick={toggleZoom}
            className={className}
            src={props.url}
            alt="" />
          {hasNextImg() ?
            <FunctionButton
              className={`${styles.navigateButton} ${isNavigatingRight ? styles.highlightArrowButton : ''}`}
              icon={<ArrowRight/>}
              onClick={navigateToNext}
            />
            :
            <div className={styles.buttonPlaceholder}/>
          }
        </div>

      </div>
    </Container>
  );
}

function Container({ children }: { children: ReactChild }) {
  const [container] = useState(() => document.createElement('div'));
  container.classList.add(styles.container);

  useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
}
