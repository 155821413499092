import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import { TFunction } from 'react-i18next';
import TreeService from '../../../tree/TreeService';
import arrowLeftImg from './arrow-left.svg';
import PropertyColorConfiguration from '../../../properties/PropertyColorConfiguration';
import PropertyConfiguration from '../../../properties/PropertyConfiguration';
import { Organization } from '../../../organization/Organization';

export abstract class TreeNamecard<T> {
  private static readonly HIDE_TIMEOUT = 200;
  private property: DisplayableTreeProperty | null = null;
  private hideTimer: NodeJS.Timeout | null = null;
  private tree: Tree | null = null;
  private isHovering = false;

  constructor(
    private readonly treeService: TreeService,
    private readonly t: TFunction,
    public onOpen: (tree: Tree) => unknown,
    private organization: Organization,
    private windSpeed: number
  ) {}

  protected preventClosingOnHover = () => (this.isHovering = true);
  protected closeOnMouseLeave = () => this.hideImmediately();
  protected readonly onOpenButtonClick = () => {
    if (this.tree) {
      this.onOpen(this.tree);
    }
  };

  setWindSpeed(windSpeed: number) {
    this.windSpeed = windSpeed;
  }

  showProperties(property: DisplayableTreeProperty | null, propertyConfig: PropertyConfiguration | null) {
    this.property = property;
    this.showContent(propertyConfig);
    this.setUpEvents();
    return this;
  }

  show(target: T, tree: Tree, propertyConfig: PropertyConfiguration | null) {
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
    }

    if (!this.isOpen()) {
      this.openOn(target);
    }

    if (tree.id === this.tree?.id) {
      return;
    }

    this.tree = tree;

    this.setPosition(tree.getCoordinates());

    this.showContent(propertyConfig);

    this.setUpEvents();
  }

  hide() {
    if (this.isOpen()) {
      this.hideTimer = setTimeout(() => {
        if (!this.isHovering) {
          this.resetState();
        }
      }, TreeNamecard.HIDE_TIMEOUT);
    }
  }

  hideImmediately() {
    this.resetState();
  }

  setOrganization(organization: Organization) {
    this.organization = organization;
  }

  protected abstract isOpen();

  protected abstract openOn(target: T);

  protected abstract setPosition(coordinates: [number, number]);

  private resetState() {
    this.resetUI();
    this.tree = null;
    this.isHovering = false;
  }

  protected abstract resetUI();

  protected abstract setContent(content: string);

  private showContent(propertyConfig: PropertyConfiguration | null) {
    if (!this.tree) {
      return null;
    }

    const scientificName = this.tree.scientificName.includes(' x ')
      ? `<i>${this.tree.scientificName.split(' x ')[0]}</i>
          <span> x </span>
          <i>${this.tree.scientificName.split(' x ')[1]}</i>`
      : `<i>${this.tree.scientificName}</i>`;

    this.setContent(`
    <div class="treePopUpContainer">
      <div class="imageContainer">
        <div class="image loading"></div>
      </div>
      <div class="mainContainer">
        <div class="mainExternalId">${this.tree.externalId}</div>
        <div class="mainSpecies">${scientificName}</div>
        <div class="mainManagedArea">${this.tree.managedArea.getName()}</div>
        ${this.getPropertyContent(propertyConfig)}
        <button class="detailsButton">
          ${this.t('treeInfo.details')}
          <img src="${arrowLeftImg}" />
        </button>
      </div>
    </div>
    `);

    this.setTreeImage();
  }

  private getPropertyContent(propertyConfig: PropertyConfiguration | null) {
    if (this.property === null || !propertyConfig || !this.tree) return '';

    const [value, displayableValue] = (() => {
      if (propertyConfig.property === DisplayableTreeProperty.SafetyFactors) {
        const safetyFactor = this.tree.safetyFactors.find(it => it.windSpeed === this.windSpeed)?.safetyFactor || null;
        return [safetyFactor, safetyFactor?.toFixed(1) ?? '-'];
      }

      return [
        this.tree[propertyConfig.property],
        this.tree.hasProperty(this.property)
          ? this.tree[propertyConfig.property]!.toFixed(1) + ' ' + Tree.getUnit(this.property, this.organization)
          : '-'
      ];
    })();

    const index = propertyConfig.getRangeIndexForValue(value);
    const colors = PropertyColorConfiguration.getColorsForConfig(propertyConfig);
    const color = colors[index];

    return `
      <div class="properties">
        <div class="property" style="color: rgb(${color})">
          <div>${this.t('tree.' + this.property)}: ${displayableValue}</div>
        </div>
      </div>
    `;
  }

  private async setTreeImage() {
    if (!this.tree) {
      return;
    }

    if (this.tree.hasImages()) {
      const urls = await Promise.all(this.tree.images.map(it => {
        return it.getThumbnailUrlFromLayers();
      }));
      this.setImages(urls as string[]);
      return;
    }

    const fullTree = await this.treeService.find(this.organization, this.tree.id);

    if (fullTree.hasImages()) {
      const urls = await Promise.all(fullTree.images.map(it => {
        return it.getThumbnailUrlFromLayers();
      }));
      this.setImages(urls as string[]);
      return;
    }
  }

  protected abstract setImages(imageInBase64: string[]);

  private setUpEvents() {
    this.handleDetailsButtonClickEvent();
    this.handlePopupMouseOverEvent();
    this.handlePopupMouseLeave();
  }

  protected abstract handleDetailsButtonClickEvent();

  protected abstract handlePopupMouseOverEvent();

  protected abstract handlePopupMouseLeave();
}
