import mapboxgl, { CustomLayerInterface } from 'mapbox-gl';
import MinimapIndicatorImage from './UI/minimap-indicator.svg';

export class IndicatorLayer implements CustomLayerInterface {
  readonly id = 'indicator';
  readonly layerId = 'minimap-indicator';
  readonly type = 'custom';
  public indicator: { position: [number, number], rotation: number } | null = null;
  private map: mapboxgl.Map | null = null;

  render(gl: WebGLRenderingContext, matrix: number[]): void {
  }

  setIndicator(indicator: { position: [number, number], rotation: number }) {
    this.removeLayer();
    this.removeSource();

    this.indicator = indicator;

    this.addSource();
    this.addLayer();

    return this;
  }

  setRotation(rotation: number) {
    if (!this.map?.hasImage('minimap-indicator') || !this.indicator) {
      return;
    }

    this.indicator.rotation = rotation;
    this.map?.setLayoutProperty('minimap-indicator', 'icon-rotate', rotation);
  }

  async onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    if (!map.hasImage('minimap-indicator')) {
      map.addImage('minimap-indicator', await this.loadIndicatorImage());
    }

    this.map = map;
    this.addSource();
    this.addLayer();
  }

  private addLayer() {
    if (!this.map?.hasImage('minimap-indicator')) {
      return;
    }

    this.map?.addLayer({
      id: this.layerId,
      source: this.id,
      type: 'symbol',
      layout: {
        'icon-image': 'minimap-indicator',
        'icon-size': 1,
        'icon-rotate': this.indicator?.rotation || 0
      }
    });
  }

  private removeSource() {
    if (!this.map || !this.map.getSource(this.id)) {
      return;
    }

    this.map.removeSource(this.id);
  }

  private addSource() {
    if (!this.map?.hasImage('minimap-indicator')) {
      return;
    }
    this.map?.addSource(this.id, {
      type: 'geojson',
      data: {
        type: 'Feature' as const,
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: this.indicator?.position || [0, 0]
        }
      }
    });
  }

  private removeLayer() {
    if (!this.map || !this.map.getLayer(this.layerId)) {
      return;
    }

    this.map.removeLayer(this.layerId);
  }

  private loadIndicatorImage(): Promise<HTMLImageElement> {
    const image = new Image(31, 45);
    image.src = MinimapIndicatorImage;

    return new Promise((resolve, reject) => {
      image.onload = () => resolve(image);
      image.onerror = reject;
    });
  }
}
