import MembersScreen from '../../components/Settings/members/MembersScreen';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import InvitationDto from '../../registration/InvitationDto';
import { AlreadyMemberError, RegistrationService } from '../../registration/RegistrationService';
import { ComparableMemberProperty, Member, MemberSortingDirection } from '../../organization/Member';
import { Interest } from '../../account/Interest';
import { Account } from '../../account/Account';
import { MemberFilters } from '../../components/Settings/members/MemberFilters';
import { AccountStatus } from '../../account/AccountDto';
import { MemberStatus } from '../../organization/MemberStatus';
import { AccountType } from '../../account/AccountType';
import DependencyInjectionContext from '../../DependencyInjectionContext';

export function Members({ currentAccount, organizationId }: MembersProps) {
  const { accountService, organizationService } = useContext(DependencyInjectionContext);
  const [searchParams] = useSearchParams();

  const [isInvitationComplete, setInvitationState] = useState(false);
  const [isInvitedAlreadyMember, setInvitedMemberState] = useState(false);
  const [isInvitationEmailTaken, setInvitationEmailState] = useState<null | boolean>(null);
  const onInvite = useCallback(
    async (invitation: InvitationDto) => {
      try {
        setInvitedMemberState(false);
        await RegistrationService.make().invite(organizationId, invitation);
        setInvitationState(true);
        setInvitationEmailState(null);
      } catch (error) {
        if (error instanceof AlreadyMemberError) {
          return setInvitedMemberState(true);
        }
      }
    },
    [organizationId]
  );
  const onInvitationEmail = useCallback(async (email: string) => {
    setInvitationEmailState(null);
    setInvitationEmailState(await RegistrationService.make().isTaken(email));
  }, []);

  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    organizationService.listMembers(organizationId).then(setMembers);
    setInvitationState(false);
    setInvitedMemberState(false);
    setInvitationEmailState(null);
  }, [organizationId, isInvitationComplete]);

  const sortingDirection = (searchParams.get('sortingDirection') ??
    MemberSortingDirection.ASCENDING) as MemberSortingDirection;
  const sortBy = (searchParams.get('sortBy') ?? 'memberSince') as ComparableMemberProperty;

  const sortedMembers = useMemo(
    () => (members ?? []).sort((a, b) => a.compareForSorting(b, sortBy, sortingDirection)),
    [members, sortBy, sortingDirection]
  );
  const onInterestChange = useCallback(
    async (member: Member, updatedInterests: Interest[]) => {
      await accountService.replaceInterests(organizationId, member.id, updatedInterests);
      setMembers(
        members =>
          members?.map(it => (it.isSameById(member) ? member.refresh({ interests: updatedInterests }) : it)) ?? null
      );
    },
    [organizationId]
  );
  const filteredMembers = useMemo(
    () => MemberFilters.fromQuery(searchParams).filter(sortedMembers),
    [searchParams, sortedMembers]
  );
  const onStatusChange = useCallback(
    async (member: Member, status: AccountStatus) => {
      await accountService.changeStatus(organizationId, member.id, status);
      setMembers(
        members =>
          members?.map(it =>
            it.isSameById(member) ? member.refresh({ status: status as unknown as MemberStatus }) : it
          ) ?? null
      );
    },
    [organizationId]
  );
  const onTypeChange = useCallback(
    async (member: Member, type: AccountType) => {
      await accountService.changeType(organizationId, member.id, type);
      setMembers(
        members => members?.map(it => (it.isSameById(member) ? member.refresh({ accountType: type }) : it)) ?? null
      );
    },
    [organizationId]
  );

  return (
    <MembersScreen
      members={filteredMembers}
      onInvite={onInvite}
      isInvitationComplete={isInvitationComplete}
      account={currentAccount}
      isInvitationEmailTaken={isInvitationEmailTaken}
      isInvitedAlreadyMember={isInvitedAlreadyMember}
      onInvitationEmail={onInvitationEmail}
      currentSortingProperty={sortBy}
      currentSortingDirection={sortingDirection}
      onInterestChange={onInterestChange}
      onStatusChange={onStatusChange}
      onTypeChange={onTypeChange}
    />
  );
}

interface MembersProps {
  currentAccount: Account,
  organizationId: string
}
