import { Organization } from '../organization/Organization';

export type IsoMapCategories = keyof typeof IsoLineUrlGenerator.categoryToFilenameMap;

export default class IsoLineUrlGenerator {
  /**
   * This map is a lookup to which isomap category should be represented with what filename on the storage.
   * E.g.: A 'co' type isomap should be placed to the url: <cdnUrl>/<projectName>/isomaps/CO.geojson
   */
  static categoryToFilenameMap = {
    co: 'CO',
    no2: 'NO2',
    o3: 'O3',
    pm25: 'PM2_5',
    so2: 'SO2',
    thermalComfort: 'THERMAL_COMFORT',
    ndvi: 'NDVI',
    treeHealth: 'TREE_HEALTH'
  } as const;

  generateSourceUrl(organization: Organization, isoMapCategory: IsoMapCategories): string {
    const categoryInFileName = IsoLineUrlGenerator.categoryToFilenameMap[isoMapCategory];

    return organization.getCDNUrlFromRelativePath(`isomaps/${categoryInFileName}.geojson`);
  }
}
