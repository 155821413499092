import styles from '../BaseAttributes.module.scss';
import { useTranslation } from 'react-i18next';
import DetailedTree from '../../../../../tree/DetailedTree';
import { useContext, useState } from 'react';
import { TreeDto } from '../../../../../tree/Tree';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import Input from '../../../../../components/UI/Input/Input';
import { Organization } from '../../../../../organization/Organization';
import EditingFieldWrapper from '../../components/EditingFieldWrapper/EditingFieldWrapper';
import DataGroupHeader from '../../components/DataGroupHeader/DataGroupHeader';

export default function AdditionalDataGroup(props: AdditionalDataGroupProps) {
  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);
  const [editing, setEditing] = useState(false);

  const initialData = {
    customerTagId: props.tree.customerTagId || '',
    customerSiteId: props.tree.customerSiteId || '',
    customerTreeId: props.tree.customerTreeId || '',
    cultivarOrVariety: props.tree.cultivarOrVariety || ''
  };

  const [data, setData] = useState(initialData);

  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(data).forEach(key => {
      if (data[key] !== props.tree[key]) {
        updatedValues[key] = data[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(props.organization.id, props.tree.id, updatedValues);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setData(initialData);
  };

  return (
    <div>
      <div className={styles.dataGroup}>
        <DataGroupHeader
          editing={editing}
          setEditing={() => setEditing(true)}
          handleCancel={handleCancel}
          handleSave={handleSave}
          title={t('treeDetails.inventory.additionalInformation')}
        />
        <div className={props.singleColumn ? styles.singleColumn : styles.accordionPairsContainer}>
          <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
            <EditingFieldWrapper
              title={t('details.properties.cultivarOrVariety')}
              value={data.cultivarOrVariety}
              editing={editing}
            >
              <Input
                label={''}
                value={data.cultivarOrVariety}
                onChange={event => setData(prev => ({ ...prev, cultivarOrVariety: event.target.value }))}
                autoFocus={true}
                className={styles.editingInput}
              />
            </EditingFieldWrapper>
            <EditingFieldWrapper
              title={t('details.properties.coordinates')}
              value={props.tree.getWorldCoordinates().map(it => it.toFixed(5)).join(', ')}
            />
            <EditingFieldWrapper
              title={t('details.properties.customerTreeId')}
              value={data.customerTreeId}
              editing={editing}
            >
              <Input
                label={''}
                value={data.customerTreeId}
                onChange={event => setData(prev => ({ ...prev, customerTreeId: event.target.value }))}
                autoFocus={true}
                className={styles.editingInput}
              />
            </EditingFieldWrapper>
            <EditingFieldWrapper
              title={t('details.properties.treeTagNumber')}
              value={data.customerTagId}
              editing={editing}
            >
              <Input
                label={''}
                value={data.customerTagId}
                onChange={event => setData(prev => ({ ...prev, customerTagId: event.target.value }))}
                autoFocus={true}
                className={styles.editingInput}
              />
            </EditingFieldWrapper>
            <EditingFieldWrapper
              title={t('details.properties.treeKeeperId')}
              value={data.customerSiteId}
              editing={editing}
            >
              <Input
                label={''}
                value={data.customerSiteId}
                onChange={event => setData(prev => ({ ...prev, customerSiteId: event.target.value }))}
                autoFocus={true}
                className={styles.editingInput}
              />
            </EditingFieldWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

interface AdditionalDataGroupProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organization: Organization
}
