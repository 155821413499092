import { EnumCondition, Filter, NumericCondition } from './FilterConfig';
import { Tree } from '../../../../tree/Tree';

export class FilterCondition {
  static fromConfig(config: Filter): FilterCondition {
    return new FilterCondition(config.property, config.condition, config.value);
  }

  constructor(
    readonly property: keyof Tree,
    private readonly condition: NumericCondition | EnumCondition,
    private readonly value: number | string[]
  ) {}

  apply(tree: Partial<Tree>): boolean {
    if (!tree[this.property]) return false;
    // eslint-disable-next-line default-case
    switch (this.condition) {
    case NumericCondition.EQUALS:
      return tree[this.property] === this.value;
    case NumericCondition.GREATER_THAN:
      return tree[this.property]! > this.value;
    case NumericCondition.LESS_THAN:
      return tree[this.property]! < this.value;
    case EnumCondition.IN:
      return (this.value as string[]).includes(tree[this.property] as string);
    }
  }
}
