import { AxiosInstance } from 'axios';
import { Account } from './Account';
import { AccountDto, AccountStatus } from './AccountDto';
import { Interest } from './Interest';
import { AccountType } from './AccountType';

export class AccountService {
  constructor(private readonly http: AxiosInstance) {}

  async list(): Promise<Account[]> {
    const response = await this.http.get<AccountDto[]>('/v1/accounts');

    return response.data.map(Account.fromDto);
  }

  async create(organizationId: string) {
    return await this.http.put(`v1/organizations/${organizationId}/accounts`);
  }

  async replaceInterests(organizationId: string, accountId: string, interests: Interest[]) {
    await this.http.put(`/v1/organizations/${organizationId}/accounts/${accountId}/interests`, interests);
  }

  async changeStatus(organizationId: string, accountId: string, status: AccountStatus) {
    await this.http.patch(`/v1/organizations/${organizationId}/accounts/${accountId}`, { status });
  }

  async changeType(organizationId: string, accountId: string, type: AccountType) {
    await this.http.patch(`/v1/organizations/${organizationId}/accounts/${accountId}`, { type });
  }
}
