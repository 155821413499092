import styles from './InfoTab.module.scss';
import CreateOrganization from '../components/CreateOrganization';
import { Organization } from '../../../organization/Organization';
import Modal, { ModalContent } from '../../../components/Modal/Modal';
import { useContext, useState } from 'react';
import { Members } from '../../Settings/Members';
import { Account } from '../../../account/Account';
import { AccountType } from '../../../account/AccountType';
import { AccountStatus } from '../../../account/AccountDto';
import useAccounts from '../../../account/useAccounts';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import InfoTable from '../components/InfoTable';
import { useQueryClient } from 'react-query';
import { StaticQueryKeys } from '../../../StaticQueryKeys';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../../components/UI/Toast/DismissibleToast';

export default function InfoTab() {
  const { accounts } = useAccounts();
  const { accountService } = useContext(DependencyInjectionContext);
  const queryClient = useQueryClient();

  const orgIdsForUser = accounts.map(it => it.organization.id);

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  const createAccountInOrganization = async (organizationId: string) => {
    try {
      await accountService.create(organizationId);
      await queryClient.invalidateQueries(StaticQueryKeys.ACCOUNTS);
      dismissibleSuccessToast('Account created!');
    } catch (e: unknown) {
      dismissibleErrorToast('Failed: ' + (e as Error).message);
    }
  };

  return (<section className={styles.infoTabContainer}>
    <div className={styles.tableContainer}>
      <InfoTable
        manageMembers={setSelectedOrg}
        orgIdsForUser={orgIdsForUser}
        createAccountInOrganization={createAccountInOrganization}
      />
    </div>
    <CreateOrganization />
    <Modal
      isVisible={selectedOrg !== null}
      onHide={() => setSelectedOrg(null)}
      className={styles.manageMembersModal}
    >
      <ModalContent>
        <div className={styles.membersContainer}>
          {selectedOrg?.id && <Members
            currentAccount={Account.fromDto(Account.empty('', AccountType.Owner, selectedOrg, AccountStatus.Active))}
            organizationId={selectedOrg.id} />}
        </div>
      </ModalContent>
    </Modal>
  </section>);
}
