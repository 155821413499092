import { Expression } from 'mapbox-gl';
import { TrackableTreeProperty } from '../../tree/TrackableTreeProperty';
import { SigmaBoundary } from '../../routes/Explore/table-view/advanced-filter/AdvancedFilter';

export default class CohortColor {
  static readonly rgbs = {
    belowTwoSigma: '194, 54, 53',
    betweenMinusOneAndTwoSigma: '255, 122, 0',
    withinOneSigma: '78, 186, 94',
    betweenOneAndTwoSigma: '17, 160, 107',
    aboveTwoSigma: '0, 119, 82'
  };

  private readonly propertyValue: Expression;
  private readonly mu: Expression;
  private readonly sigma: Expression;

  constructor(private readonly property: TrackableTreeProperty) {
    this.propertyValue = ['get', this.property];
    this.mu = ['get', `cohort_${this.property}_mu`];
    this.sigma = ['get', `cohort_${this.property}_sigma`];
  }

  private getWithinOneSigmaExpression(): Expression {
    return [
      'all',
      ['>', this.propertyValue, ['-', this.mu, this.sigma]],
      ['<', this.propertyValue, ['+', this.mu, this.sigma]]
    ];
  }

  private getBetweenMinusOneAndTwoSigmaExpression(): Expression {
    return [
      'all',
      ['>', this.propertyValue, ['-', this.mu, ['*', this.sigma, ['literal', 2 ]]]],
      ['<=', this.propertyValue, ['-', this.mu, this.sigma]]
    ];
  }

  private getBetweenOneAndTwoSigmaExpression(): Expression {
    return [
      'all',
      ['>=', this.propertyValue, ['+', this.mu, this.sigma]],
      ['<', this.propertyValue, ['+', this.mu, ['*', this.sigma, ['literal', 2 ]]]]
    ];
  }

  private getBelowTwoSigmaExpression(): Expression {
    return ['<=', this.propertyValue, ['-', this.mu, ['*', this.sigma, ['literal', 2 ]]]];
  }

  private getAboveTwoSigmaExpression(): Expression {
    return ['>=', this.propertyValue, ['+', this.mu, ['*', this.sigma, ['literal', 2 ]]]];
  }

  private not(expression: Expression): Expression {
    return [ '!', expression];
  }

  private forTree(expression: Expression, treeId: string): Expression {
    return ['all', expression, ['==', ['get', 'id'], treeId]];
  }

  getAdvancedFilterConditionForBoundary(sigmaBoundary: SigmaBoundary): Expression {
    if (sigmaBoundary === SigmaBoundary.WITHIN_ONE_SIGMA) {
      return this.not(this.getWithinOneSigmaExpression());
    }
    if (sigmaBoundary === SigmaBoundary.BETWEEN_MINUS_ONE_AND_TWO_SIGMA) {
      return this.not(this.getBetweenMinusOneAndTwoSigmaExpression());
    }
    if (sigmaBoundary === SigmaBoundary.BELOW_TWO_SIGMA) {
      return this.not(this.getBelowTwoSigmaExpression());
    }
    if (sigmaBoundary === SigmaBoundary.BETWEEN_ONE_AND_TWO_SIGMA) {
      return this.not(this.getBetweenOneAndTwoSigmaExpression());
    }
    return this.not(this.getAboveTwoSigmaExpression());
  }

  getMapboxColorConditions(opacity: number, highlightedSigmaBoundary: SigmaBoundary | null, fadedOpacity: number) {
    return [
      this.getBelowTwoSigmaExpression(), this.getColor(SigmaBoundary.BELOW_TWO_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity),
      this.getBetweenMinusOneAndTwoSigmaExpression(), this.getColor(SigmaBoundary.BETWEEN_MINUS_ONE_AND_TWO_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity),
      this.getWithinOneSigmaExpression(), this.getColor(SigmaBoundary.WITHIN_ONE_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity),
      this.getBetweenOneAndTwoSigmaExpression(), this.getColor(SigmaBoundary.BETWEEN_ONE_AND_TWO_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity),
      this.getAboveTwoSigmaExpression(), this.getColor(SigmaBoundary.ABOVE_TWO_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity)
    ];
  }

  getMapboxColorConditionsWithSelectedTree(selectedTreeId: string, opacity: number, highlightedSigmaBoundary: SigmaBoundary | null, fadedOpacity: number) {
    return [
      this.forTree(this.getBelowTwoSigmaExpression(), selectedTreeId), this.getColor(SigmaBoundary.BELOW_TWO_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity),
      this.forTree(this.getBetweenMinusOneAndTwoSigmaExpression(), selectedTreeId), this.getColor(SigmaBoundary.BETWEEN_MINUS_ONE_AND_TWO_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity),
      this.forTree(this.getWithinOneSigmaExpression(), selectedTreeId), this.getColor(SigmaBoundary.WITHIN_ONE_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity),
      this.forTree(this.getBetweenOneAndTwoSigmaExpression(), selectedTreeId), this.getColor(SigmaBoundary.BETWEEN_ONE_AND_TWO_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity),
      this.forTree(this.getAboveTwoSigmaExpression(), selectedTreeId), this.getColor(SigmaBoundary.ABOVE_TWO_SIGMA, opacity, highlightedSigmaBoundary, fadedOpacity)
    ];
  }

  private getColor(sigmaBoundary: SigmaBoundary, opacity: number, highlightedSigmaBoundary: SigmaBoundary | null, fadedOpacity: number): string {
    if (highlightedSigmaBoundary) {
      return `rgba(${CohortColor.rgbs[sigmaBoundary]}, ${highlightedSigmaBoundary === sigmaBoundary ? opacity : fadedOpacity})`;
    }
    return `rgba(${CohortColor.rgbs[sigmaBoundary]}, ${opacity})`;
  }
}
