import { useQuery, useQueryClient } from 'react-query';
import { Account } from './Account';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import DependencyInjectionContext from '../DependencyInjectionContext';
import { StaticQueryKeys } from '../StaticQueryKeys';

const ACCOUNT_CACHE_TTL_MS = 300_000;
const CACHE_KEY = StaticQueryKeys.ACCOUNTS;

const useCachedAccounts = () => {
  const { accountService } = useContext(DependencyInjectionContext);
  return useQuery([CACHE_KEY], () => accountService.list(), {
    staleTime: ACCOUNT_CACHE_TTL_MS,
    retry: 1,
    retryOnMount: false
  });
};

export default function useAccounts() {
  const response = useCachedAccounts();

  return { accounts: response.data || [], isLoadingAccounts: response.isLoading };
}

export function useCurrentAccount(): Account {
  const { organizationId } = useParams();
  const response = useCachedAccounts();
  return response.data?.find(account => account.organization.id === organizationId) || Account.empty();
}

export function useEditAccount(id: string) {
  const queryClient = useQueryClient();

  return (updated: Account) =>
    queryClient.setQueryData<Account[]>(
      CACHE_KEY,
      accounts => accounts?.map(it => (it.id === id ? updated : it)) ?? []
    );
}
