import styles from '../Inventory/Inventory.module.scss';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import DetailedTree, { TreeEnvironment } from '../../../../tree/DetailedTree';
import Input from '../../../../components/UI/Input/Input';
import ControlledAccordion from '../../../../components/UI/Accordion/ControlledAccordion';
import { CUBIC_METER_TO_CUBIC_FEET, METER_TO_FEET } from '../../../../components/PointCloud/unitConstants';
import EditingFieldWrapper from '../components/EditingFieldWrapper/EditingFieldWrapper';

export enum EnvironmentAccordion {
  CorridorClearing = 'corridorClearing',
  ViaductClearing = 'viaductClearing',
  TrafficSignVisibility = 'trafficSignVisibility',
  DistanceToWires = 'distanceToWires'
}

export default function EnvironmentTab(props: EnvironmentTabProps) {
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const selectedEnvAccordion = useMemo(() => {
    return urlContext.getSelectedEnvAccordion();
  }, [JSON.stringify(urlContext.getSelectedEnvAccordion())]);

  const conditionalConvertMeterToFeet = value => {
    if (props.isMetrical) return value;
    return value * METER_TO_FEET;
  };

  const conditionalConvertCubicMeterToCubicFeet = value => {
    if (props.isMetrical) return value;
    return value * CUBIC_METER_TO_CUBIC_FEET;
  };

  const environment: Partial<TreeEnvironment> = {
    corridorMinimumHeightClearance: conditionalConvertMeterToFeet(props.tree.environment?.corridorMinimumHeightClearance),
    corridorObstructionHeight: conditionalConvertMeterToFeet(props.tree.environment?.corridorObstructionHeight),
    corridorObstructionVolume: conditionalConvertCubicMeterToCubicFeet(props.tree.environment?.corridorObstructionVolume),
    corridorAffectedCrown: props.tree.environment?.corridorAffectedCrown,
    viaductCriticalHeight: conditionalConvertMeterToFeet(props.tree.environment?.viaductCriticalHeight),
    viaductOutsideCriticalBoundaryPercentage: props.tree.environment?.viaductOutsideCriticalBoundaryPercentage,
    viaductOutsideCriticalBoundaryArea: props.tree.environment?.viaductOutsideCriticalBoundaryArea,
    trafficSignVisibleDistance: conditionalConvertMeterToFeet(props.tree.environment?.trafficSignVisibleDistance),
    trafficSignCoverage: props.tree.environment?.trafficSignCoverage,
    distanceToWires: conditionalConvertMeterToFeet(props.tree.environment?.distanceToWires)
  };

  const toggleAccordionOpen = (accordion: EnvironmentAccordion) => {
    if (selectedEnvAccordion === accordion) urlContext.setSelectedEnvAccordion(null);
    else urlContext.setSelectedEnvAccordion(accordion);
  };

  return (
    <div className={styles.content}>
      <div className={`${styles.dataGroup} ${styles.flexContainer}`}>
        <div className={`${styles.header} ${styles.lessPadding}`}>
          <h3 className={styles.groupHeader} style={{ margin: '4px 0', fontSize: 'var(--font-size-l)' }}>
            {t('treeDetails.tabs.subTitles.tools')}
          </h3>
        </div>
        <div className={props.singleColumn ? styles.singleColumn : styles.accordionPairsContainer}>
          <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
            <ControlledAccordion
              title={t('details.properties.environment.corridorClearing')}
              isOpen={selectedEnvAccordion === EnvironmentAccordion.CorridorClearing}
              toggleOpen={() => toggleAccordionOpen(EnvironmentAccordion.CorridorClearing)}
            >
              <Row
                environment={environment}
                environmentPropertyKey={'corridorMinimumHeightClearance'}
                unit={props.isMetrical ? 'm' : 'ft'}
              />
              <Row
                environment={environment}
                environmentPropertyKey={'corridorObstructionHeight'}
                unit={props.isMetrical ? 'm' : 'ft'}
              />
              <Row
                environment={environment}
                environmentPropertyKey={'corridorObstructionVolume'}
                unit={props.isMetrical ? 'm³' : 'ft³'}
              />
              <Row
                environment={environment}
                environmentPropertyKey={'corridorAffectedCrown'}
                unit={'%'}
              />
            </ControlledAccordion>
            <ControlledAccordion
              title={t('details.properties.environment.viaductClearing')}
              isOpen={selectedEnvAccordion === EnvironmentAccordion.ViaductClearing}
              toggleOpen={() => toggleAccordionOpen(EnvironmentAccordion.ViaductClearing)}
            >
              <Row
                environment={environment}
                environmentPropertyKey={'viaductCriticalHeight'}
                unit={props.isMetrical ? 'm' : 'ft'}
              />
              <Row
                environment={environment}
                environmentPropertyKey={'viaductOutsideCriticalBoundaryArea'}
                unit={props.isMetrical ? 'm³' : 'ft³'}
              />
              <Row
                environment={environment}
                environmentPropertyKey={'viaductOutsideCriticalBoundaryPercentage'}
                unit={'%'}
              />
            </ControlledAccordion>
          </div>
          <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
            <ControlledAccordion
              title={t('details.properties.environment.trafficSignVisibility')}
              isOpen={selectedEnvAccordion === EnvironmentAccordion.TrafficSignVisibility}
              toggleOpen={() => toggleAccordionOpen(EnvironmentAccordion.TrafficSignVisibility)}
            >
              <Row
                environment={environment}
                environmentPropertyKey={'trafficSignVisibleDistance'}
                unit={props.isMetrical ? 'm' : 'ft'}
              />
              <Row
                environment={environment}
                environmentPropertyKey={'trafficSignCoverage'}
                unit={'%'}
              />
            </ControlledAccordion>
            <ControlledAccordion
              title={t('details.properties.environment.distanceToWiresTitle')}
              isOpen={selectedEnvAccordion === EnvironmentAccordion.DistanceToWires}
              toggleOpen={() => toggleAccordionOpen(EnvironmentAccordion.DistanceToWires)}
            >
              <Row
                environment={environment}
                environmentPropertyKey={'distanceToWires'}
                unit={props.isMetrical ? 'm' : 'ft'}
              />
            </ControlledAccordion>
          </div>
        </div>
      </div>
    </div>
  );
}

interface EnvironmentTabProps {
  tree: DetailedTree,
  singleColumn: boolean,
  isMetrical: boolean
}

function Row(props: RowProps) {
  const { t } = useTranslation();

  return (
    <EditingFieldWrapper
      value={props.environment[props.environmentPropertyKey]}
      title={t(`details.properties.environment.${props.environmentPropertyKey}`)}
      editing={false}
      className={styles.environmentInputField}
      unit={props.unit}
    >
      <div className={styles.editingNumberInput}>
        <Input
          label=""
          type="number"
          value={props.environment[props.environmentPropertyKey] || ''}
          className={styles.editingInput}
        />
        {props.unit && <span className={styles.unit}>{props.unit}</span>}
      </div>
    </EditingFieldWrapper>);
}

interface RowProps {
  environment: Partial<TreeEnvironment>,
  environmentPropertyKey: string,
  unit?: string
}
