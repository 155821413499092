export enum LandUse {
  COMMERCIAL_INDUSTRIAL = 'commercialIndustrial',
  OTHER = 'other',
  INSTITUTIONAL = 'institutional',
  RESIDENTIAL = 'residential',
  MULTI_FAMILY_RESIDENTIAL = 'multiFamilyResidential',
  WATER_WETLAND = 'waterWetland',
  AGRICULTURE = 'agriculture',
  FOREST = 'forest',
  CEMETERY = 'cemetery',
  TRANSPORTATION = 'transportation',
  VACANT = 'vacant',
  PARK = 'park',
  HEALTH_CARE = 'healthCare'
}
