import styles from '../../Inventory/Inventory.module.scss';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import Dropdown, { Item } from '../../../../../components/UI/Dropdown/Dropdown';
import { TreeDto } from '../../../../../tree/Tree';
import DetailedTree from '../../../../../tree/DetailedTree';
import DataGroupHeader from '../../components/DataGroupHeader/DataGroupHeader';
import EditingFieldWrapper from '../../components/EditingFieldWrapper/EditingFieldWrapper';

export default function ObservationsDataGroup(props: ObservationsDataGroupProps) {
  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);
  const [editing, setEditing] = useState(false);

  const initialRisks = {
    deadDyingOrBrokenBranches: props.tree.deadDyingOrBrokenBranches,
    overExtendedBranches: props.tree.overExtendedBranches,
    mechanicalDamage: props.tree.mechanicalDamage,
    coDominantStems: props.tree.coDominantStems,
    girdlingRoots: props.tree.girdlingRoots
  };

  const [risks, setRisks] = useState(initialRisks);
  const deadDyingOrBrokenBranchesValue = risks.deadDyingOrBrokenBranches === null ? '' : t(`details.properties.booleanLabels.${risks.deadDyingOrBrokenBranches}`);
  const overExtendedBranchesValue = risks.overExtendedBranches === null ? '' : t(`details.properties.booleanLabels.${risks.overExtendedBranches}`);
  const coDominantStemsValue = risks.coDominantStems === null ? '' : t(`details.properties.booleanLabels.${risks.coDominantStems}`);
  const mechanicalDamageValue = risks.mechanicalDamage === null ? '' : t(`details.properties.booleanLabels.${risks.mechanicalDamage}`);
  const girdlingRootsValue = risks.girdlingRoots === null ? '' : t(`details.properties.booleanLabels.${risks.girdlingRoots}`);

  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(risks).forEach(key => {
      if (risks[key] !== props.tree[key] && risks[key] !== '') {
        updatedValues[key] = risks[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(props.organizationId, props.tree.id, updatedValues);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setRisks(initialRisks);
  };

  const convertStringToBoolean = string => {
    return string === 'true';
  };

  const booleanItems: Item[] = [
    { id: 'true', translationKey: 'details.properties.booleanLabels.true' },
    { id: 'false', translationKey: 'details.properties.booleanLabels.false' }
  ];

  return (
    <div className={styles.dataGroup}>
      <DataGroupHeader
        editing={editing}
        setEditing={() => setEditing(true)}
        handleCancel={handleCancel}
        handleSave={handleSave}
        title={t('treeDetails.tabs.subTitles.observations')}
      />
      <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : ''}`}>
        <EditingFieldWrapper
          title={t('details.properties.deadDyingOrBrokenBranches')}
          value={deadDyingOrBrokenBranchesValue}
          editing={editing}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={booleanItems}
            value={{ translationKey: deadDyingOrBrokenBranchesValue }}
            onSelect={item => setRisks(prev => ({
              ...prev,
              deadDyingOrBrokenBranches: convertStringToBoolean(item.id)
            }))}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.coDominantStems')}
          value={coDominantStemsValue}
          editing={editing}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={booleanItems}
            value={{ translationKey: coDominantStemsValue }}
            onSelect={item => setRisks(prev => ({
              ...prev,
              coDominantStems: convertStringToBoolean(item.id)
            }))}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.overExtendedBranches')}
          value={overExtendedBranchesValue}
          editing={editing}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={booleanItems}
            value={{ translationKey: overExtendedBranchesValue }}
            onSelect={item => setRisks(prev => ({
              ...prev,
              overExtendedBranches: convertStringToBoolean(item.id)
            }))}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.girdlingRoots')}
          value={girdlingRootsValue}
          editing={editing}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={booleanItems}
            value={{ translationKey: girdlingRootsValue }}
            onSelect={item => setRisks(prev => ({
              ...prev,
              girdlingRoots: convertStringToBoolean(item.id)
            }))}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.mechanicalDamage')}
          value={mechanicalDamageValue}
          editing={editing}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={booleanItems}
            value={{ translationKey: mechanicalDamageValue }}
            onSelect={item => setRisks(prev => ({
              ...prev,
              mechanicalDamage: convertStringToBoolean(item.id)
            }))}
          />
        </EditingFieldWrapper>
      </div>
    </div>
  );
}

interface ObservationsDataGroupProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organizationId: string
}
