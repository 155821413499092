import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';

import RawText from './RawText';

export default function Text({
  className = '',
  title = false,
  uppercase = false,
  capitalize = false,
  small = false,
  medium = false,
  large = false,
  weight,
  color = 'primary',
  onClick,
  clickable = !!onClick,
  translationKey,
  inline,
  disabled
}: TextProps) {
  const { t } = useTranslation();

  return (
    <RawText
      className={className}
      title={title}
      uppercase={uppercase}
      capitalize={capitalize}
      small={small}
      medium={medium}
      large={large}
      weight={weight}
      color={color}
      onClick={onClick}
      clickable={clickable}
      inline={inline}
      disabled={disabled}
    >
      {t(translationKey)}
    </RawText>
  );
}

interface TextProps {
  className?: string,
  title?: boolean,
  uppercase?: boolean,
  capitalize?: boolean,
  small?: boolean,
  medium?: boolean,
  large?: boolean,
  weight?: 400 | 500 | 600,
  color?: 'primary' | 'secondary' | 'ternary' | 'alternate' | 'highlight' | 'error',
  onClick?: MouseEventHandler<HTMLDivElement>,
  clickable?: boolean,
  translationKey: string,
  inline?: boolean,
  disabled?: boolean
}
