import * as THREE from 'three';
import { Tree } from '../../tree/Tree';
import { useContext, useEffect, useRef, useState } from 'react';
import { generateMeshFromImage, Image, parseImageProperties } from '../../routes/Explore/panoramic-view/meshGenerator';
import SphereControls from './SphereControls';
import styles from './PanoramaView.module.scss';
import Spinner from '../UI/Spinner/Spinner';
import { RulerGroup } from '../PointCloud/RulerGroup';
import { useTranslation } from 'react-i18next';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import SafetyFactorMarkerGroup from '../PointCloud/SafetyFactorMarkerGroup';
import { LeaningAngleGroup } from '../PointCloud/LeaningAngleGroup';
import { LoadablePointCloud } from '../../point-cloud/LoadablePointCloud';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { useCurrentAccount } from '../../account/useAccounts';

export default function PanoramaView(props: PanoramaViewProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const { capturePointService } = useContext(DependencyInjectionContext);

  const environmentPointSize = 1.6;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [sphere, setSphere] = useState<THREE.Group | null>(null);
  const [pointcloudGroup, setPointcloudGroup] = useState<THREE.Group | null>(null);
  const [environmentPointCloudGroup, setEnvironmentPointCloudGroup] = useState<THREE.Group | null>(null);
  const [center, setCenter] = useState<THREE.Vector3 | null>(null);
  const [renderer, setRenderer] = useState<{ render: () => void }>({ render: () => {} });
  const rulersRef = useRef<RulerGroup[]>([]);
  const safetyFactorGroupRef = useRef<SafetyFactorMarkerGroup | null>(null);
  const leaningAngleGroupRef = useRef<LeaningAngleGroup | null>(null);
  const primitiveRulers = JSON.stringify(props.rulers);

  useEffect(() => {
    Promise.all([
      new LoadablePointCloud(props.tree.getPointCloudUrl(account.organization)).loadInto(props.tree.localizedLocation, false).then(pc => {
        const standingObject = new THREE.Group().rotateX(-Math.PI / 2).add(pc);
        standingObject.position.copy(newVector3FromLocalCoords(props.tree.localizedLocation));
        setPointcloudGroup(standingObject);
      }),
      new LoadablePointCloud(props.tree.getEnvironmentPointCloudUrl(account.organization), environmentPointSize).loadInto(props.tree.localizedLocation, true).then(pc => {
        const standingObject = new THREE.Group().rotateX(-Math.PI / 2).add(pc);
        standingObject.position.copy(newVector3FromLocalCoords(props.tree.localizedLocation));
        setEnvironmentPointCloudGroup(standingObject);
      })
    ]).then(() => {});
  }, [props.tree, account.organization]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas === null) {
      return;
    }

    const parentBounds = canvas.parentElement!.getBoundingClientRect();
    canvas.style.height = `${parentBounds.height}px`;
    canvas.style.width = `${parentBounds.width}px`;

    (async () => {
      const capturePoint = await capturePointService.show(props.organizationId, props.tree.capturePointId);
      const origin = capturePoint?.location ?? props.tree.localizedLocation;

      const images = capturePoint?.imageProperties
        ? parseImageProperties(capturePoint.imageProperties, account.organization, capturePoint.snapshotId)
        : await capturePointService.getImagesBySnapshotId(account.organization, capturePoint.snapshotId);

      const meshes = await imagesToMeshes(images);

      const panoramaSphere = new THREE.Group();
      panoramaSphere.add(...meshes);

      const center = newVector3FromLocalCoords(origin);
      panoramaSphere.position.copy(center);
      panoramaSphere.rotateX(-Math.PI / 2);

      setCenter(center);
      setSphere(panoramaSphere);
    })();
  }, [canvasRef, props.organizationId, props.tree.localizedLocation, account.organization]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (
      !canvas ||
      sphere === null ||
      pointcloudGroup === null ||
      environmentPointCloudGroup === null ||
      center === null
    ) {
      return;
    }

    const scene = new THREE.Scene();
    scene.add(sphere, pointcloudGroup, environmentPointCloudGroup);

    const camera = new THREE.PerspectiveCamera(props.controls.MAX_FOV, canvas.clientWidth / canvas.clientHeight, 1, 1100);
    camera.rotation.order = 'YXZ';
    camera.position.copy(center);

    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();

    const treePosition = [...props.tree.localizedLocation];
    treePosition[2] += props.tree.height / 2;
    camera.lookAt(newVector3FromLocalCoords(treePosition));

    const renderer = new THREE.WebGLRenderer({ canvas });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor('#293336');

    const htmlRenderer = new CSS2DRenderer();

    htmlRenderer.domElement.style.position = 'absolute';
    htmlRenderer.domElement.style.top = '0px';
    htmlRenderer.domElement.style.left = '0px';
    canvas.parentElement!.appendChild(htmlRenderer.domElement);

    const render = () => {
      renderer.render(scene, camera);
      htmlRenderer.render(scene, camera);
    };

    props.controls.listen(camera, htmlRenderer.domElement as HTMLCanvasElement, [render]);

    const setCanvasSize = () => {
      if (!canvas) {
        return;
      }

      const width = canvas.parentElement?.clientWidth ?? 0;
      const height = canvas.parentElement?.clientHeight ?? 0;
      canvas.style.width = width + 'px';
      canvas.style.height = height - 4 + 'px';

      camera.aspect = width / height;

      htmlRenderer.setSize(canvas.clientWidth, canvas.clientHeight);
      renderer.setSize(canvas.clientWidth, canvas.clientHeight);
      renderer.setPixelRatio(window.devicePixelRatio);

      camera.updateProjectionMatrix();
      render();
    };
    window.addEventListener('resize', setCanvasSize);

    const isMetrical = account.organization.getIsMetrical();
    rulersRef.current = [
      RulerGroup.forHeight(props.tree, t('analytics.properties.height'), render, isMetrical, true),
      RulerGroup.forFirstBifurcation(props.tree, t('analytics.properties.trunkHeight'), render, isMetrical, true),
      RulerGroup.forCanopyWidth(props.tree, t('analytics.properties.canopyWidth'), render, isMetrical, true),
      RulerGroup.forCanopyHeight(props.tree, t('analytics.properties.canopyHeight'), render, isMetrical, true),
      RulerGroup.forDBH(account.organization, props.tree, t('analytics.properties.trunkDiameter'), render, true)
    ];

    rulersRef.current.forEach(ruler => {
      ruler.position.add(newVector3FromLocalCoords(props.tree.localizedLocation));
      ruler.renderOrder = 100;
    });

    const rulers = JSON.parse(primitiveRulers);
    rulersRef.current.forEach(it => it.setVisibility(rulers.includes(it.propertyName)));
    scene.add(...rulersRef.current);

    safetyFactorGroupRef.current = new SafetyFactorMarkerGroup(2, 0.1, 50, props.tree.getWindDirectionAngle(props.windSpeed) || 0, props.tree!.height / 6, render);
    safetyFactorGroupRef.current.position.copy(newVector3FromLocalCoords(props.tree.localizedLocation));
    safetyFactorGroupRef.current.visible = props.showSafetyFactor;
    safetyFactorGroupRef.current.renderOrder = 100;
    scene.add(safetyFactorGroupRef.current);

    leaningAngleGroupRef.current = new LeaningAngleGroup(
      props.tree?.height || 0,
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(...(props.tree?.leaningVector || [0, 0, 0])),
      t('analytics.properties.leaningAngle'),
      render,
      true
    );
    leaningAngleGroupRef.current.renderOrder = 100;
    leaningAngleGroupRef.current.position.copy(newVector3FromLocalCoords(props.tree.localizedLocation));
    leaningAngleGroupRef.current.visible = props.showLeaningAngle;
    scene.add(leaningAngleGroupRef.current);

    setCanvasSize();
    render();
    setRenderer({ render });

    return () => {
      canvas.parentElement!.removeChild(htmlRenderer.domElement);
      renderer.dispose();
      props.controls.dispose(camera, canvas, [render]);
      window.removeEventListener('resize', setCanvasSize);
    };
  }, [props.tree, sphere, center, pointcloudGroup, environmentPointCloudGroup]);

  useEffect(() => {
    if (pointcloudGroup) {
      pointcloudGroup.visible = props.showPointCloud;
      renderer.render();
    }
  }, [renderer, pointcloudGroup, props.showPointCloud]);

  useEffect(() => {
    if (environmentPointCloudGroup) {
      environmentPointCloudGroup.visible = props.showEnvironmentPointCloud;
      renderer.render();
    }
  }, [renderer, environmentPointCloudGroup, props.showEnvironmentPointCloud]);

  useEffect(() => {
    const rulers = JSON.parse(primitiveRulers);

    rulersRef.current.forEach(group => group.setVisibility(rulers.includes(group.propertyName)));
    renderer.render();
  }, [renderer, rulersRef, primitiveRulers]);

  useEffect(() => {
    if (safetyFactorGroupRef.current) {
      safetyFactorGroupRef.current?.setWindDirection(props.tree.getWindDirectionAngle(props.windSpeed) || 0);
      safetyFactorGroupRef.current.visible = props.showSafetyFactor;
      renderer.render();
    }
  }, [props.tree, renderer, safetyFactorGroupRef, props.showSafetyFactor, props.windSpeed]);

  useEffect(() => {
    if (leaningAngleGroupRef.current) {
      leaningAngleGroupRef.current.setVisibility(props.showLeaningAngle);
      renderer.render();
    }
  }, [props.tree, renderer, leaningAngleGroupRef, props.showLeaningAngle]);

  return (
    <div className={styles.container}>
      {(sphere === null || pointcloudGroup === null || environmentPointCloudGroup === null) && (
        <div className={styles.spinnerContainer}>
          <Spinner/>
        </div>
      )}

      <canvas ref={canvasRef}/>
    </div>
  );
}

interface PanoramaViewProps {
  tree: Tree,
  organizationId: string,
  controls: SphereControls,
  showPointCloud: boolean,
  showEnvironmentPointCloud: boolean,
  rulers: string[],
  showSafetyFactor: boolean,
  windSpeed: number,
  showLeaningAngle: boolean
}

async function imagesToMeshes(images: Image[]): Promise<THREE.Mesh[]> {
  const textureLoader = new THREE.TextureLoader();
  textureLoader.crossOrigin = 'use-credentials';
  return await Promise.all(
    images.map(
      it =>
        new Promise<THREE.Mesh>(resolve => {
          textureLoader.load(it.url, texture => {
            const mesh = generateMeshFromImage(it);
            mesh.material.map = texture;
            mesh.material.color = new THREE.Color(0xffffff);
            resolve(mesh);
          });
        })
    )
  );
}

function newVector3FromLocalCoords(localCoords: number[]): THREE.Vector3 {
  return new THREE.Vector3(localCoords[0], localCoords[2], -localCoords[1]);
}
