import OrganizationScreen from '../../components/Settings/organizations/OrganizationScreen';
import { Account } from '../../account/Account';
import { useEditAccount } from '../../account/useAccounts';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import { useContext } from 'react';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { Flippers } from '../../switches/Flippers';
import { Organization } from '../../organization/Organization';
import { useTracking } from '../../analytics/useTracking';

export default function OrganizationRoute({ currentAccount }: OrganizationProps) {
  const { organizationService } = useContext(DependencyInjectionContext);
  const editAccount = useEditAccount(currentAccount.id);
  const { events, track } = useTracking();

  const updateOrganizationName = useDebouncedCallback(
    (name: string) => updateOrganization('name', name),
    300
  );

  const updateOrganizationIsDemo = useDebouncedCallback(
    (isDemo: boolean) => updateOrganization('isDemo', isDemo),
    300);

  const updateOrganizationServiceDeskUrl = useDebouncedCallback(
    (serviceDeskUrl: string) => updateOrganization('serviceDeskUrl', serviceDeskUrl),
    300
  );

  const updateOrganization = (propertyName: keyof Organization, value: any) => {
    if (value !== currentAccount.organization[propertyName]) {
      if (propertyName === 'isDemo') track(events.SETTINGS_IS_DEMO_UPDATE, { isDemo: value });
      return organizationService
        .update(currentAccount.organization.id, { [propertyName]: value })
        .then(() => editAccount(currentAccount.refreshOrganization(currentAccount.organization.refresh({ [propertyName]: value }))));
    }
  };

  return <OrganizationScreen
    organization={currentAccount.organization}
    onNameEdit={updateOrganizationName}
    onDemoEdit={updateOrganizationIsDemo}
    onServiceDeskUrlEdit={updateOrganizationServiceDeskUrl}
    availableFlippers={(Object.keys(Flippers) as Flippers[])}
  />;
}

interface OrganizationProps {
  currentAccount: Account
}
