import styles from './AdvancedFilterCohortSelector.module.scss';
import PropertyColorConfiguration from '../../../../properties/PropertyColorConfiguration';
import { Check } from 'iconoir-react';
import { useState } from 'react';
import { SigmaBoundary } from '../advanced-filter/AdvancedFilter';
import { useTranslation } from 'react-i18next';

export default function AdvancedFilterCohortSelector(props: AdvancedFilterCohortSelectorProps) {
  const [sigmaBoundaries, setSigmaBoundaries] = useState<SigmaBoundary[]>(props.sigmaBoundaries);
  const { t } = useTranslation();

  const colorMap = PropertyColorConfiguration.SIZE_5;
  const handleSelect = sigmaBoundary => {
    const selectedSigmaBoundaries = [...sigmaBoundaries];
    if (sigmaBoundaries.includes(sigmaBoundary)) {
      const indexToDelete = selectedSigmaBoundaries.findIndex(it => it === sigmaBoundary);
      selectedSigmaBoundaries.splice(indexToDelete, 1);
    } else {
      selectedSigmaBoundaries.push(sigmaBoundary);
    }
    setSigmaBoundaries(selectedSigmaBoundaries);
    props.onChange(selectedSigmaBoundaries);
  };

  return (
    <div>
      {Object.values(SigmaBoundary).map((sigmaBoundary, idx) =>
        <button
          className={`${styles.sigmaRowContainer} ${sigmaBoundaries.includes(sigmaBoundary) && styles.selectedRow}`}
          onClick={() => handleSelect(sigmaBoundary)}
          key={`${sigmaBoundary}-${idx}`}>
          <div className={styles.circleAndLabel}>
            <div className={styles.circle} style={{ backgroundColor: `rgb(${colorMap[idx]})` }}></div>
            <div>
              {t('treeList.advancedFiltering.sigmaBoundaries.' + sigmaBoundary)}
            </div>
          </div>
          <div style={{ visibility: sigmaBoundaries.includes(sigmaBoundary) ? 'visible' : 'hidden' }}>
            <Check />
          </div>
        </button>
      )}
    </div>
  );
}

interface AdvancedFilterCohortSelectorProps {
  onChange: (sigmaBoundaries: SigmaBoundary[]) => unknown,
  sigmaBoundaries: SigmaBoundary[]
}
