import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SortingDirection } from '../../../components/UI/SortButton/SortButton';
import hashString from '../../../utils/hashString';

export function useTrees(
  organizationId: string,
  jobColumnName: string,
  managedAreaIds: string[],
  reversed: boolean,
  fields: string[],
  sortProperty: string | null,
  sortingDirection: SortingDirection | null
) {
  const { taskManagerService } = useContext(DependencyInjectionContext);

  const cacheKey = `${organizationId}-task-manager-trees-${jobColumnName}`;
  const managedAreaIdsCacheKey = hashString([...managedAreaIds, reversed].join(''));
  const selectedFields = hashString([...fields, sortProperty, sortingDirection].join(''));
  const { data, isLoading } = useQuery(
    [cacheKey, managedAreaIdsCacheKey, selectedFields],
    () => {
      if (organizationId) {
        return taskManagerService.listTreesByArea(organizationId, jobColumnName, managedAreaIds, reversed, fields, sortProperty, sortingDirection);
      }
    },
    { retry: 1, retryOnMount: false, keepPreviousData: true }
  );

  return { trees: data ?? [], isLoading };
}
