import styles from '../../TreeDisplayTile/TileLayout.module.scss';
import { Position } from 'iconoir-react';

export default function ResetButton(props: ResetButtonProps) {
  return (
    <button onClick={props.onClick} className={styles.tileButton}><Position /></button>
  );
}

interface ResetButtonProps {
  onClick: () => void
}
