import { Position } from 'iconoir-react';
import styles from './InlineMap.module.scss';

export default function ResetButton(props: ResetButtonProps) {
  return (
    <button onClick={props.onClick} className={styles.tileButton}><Position /></button>
  );
}

interface ResetButtonProps {
  onClick: () => void
}
