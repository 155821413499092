import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import pointInPolygon from 'point-in-polygon';
import { SearchInput } from '../../UI/Input/Input';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import styles from './LegacySearchBar.module.scss';
import LegacySearchHintsSection, {
  AddressHint,
  ManagedAreaHint,
  TreeByCustomerIdHint,
  TreeByIdHint
} from './LegacySearchHintsSection';
import { Map, MapPin, Tree } from 'iconoir-react';
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback';
import { Organization } from '../../../organization/Organization';
import { AuthContext } from '../../../auth/AuthContext';
import { ManagedArea } from '../../../managed-area/ManagedArea';
import calculateMaxBounds from '../../../utils/calculateMaxBounds';

export default function LegacySearchBar({ organization, onResultFound, visible, managedAreas }: SearchBarProps) {
  const { t } = useTranslation();
  const user = useContext(AuthContext).user;
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const { treeService, managedAreaService, addressService } = useContext(DependencyInjectionContext);

  const [treeByIdHints, setTreeByIdHints] = useState<TreeByIdHint[]>([]);
  const [treeByCustomerIdHints, setTreeByCustomerIdHints] = useState<TreeByCustomerIdHint[]>([]);
  const [managedAreasHints, setManagedAreasHints] = useState<ManagedAreaHint[]>([]);
  const [addressHints, setAddressHints] = useState<AddressHint[]>([]);
  const [noResultsFound, setNoResultsFound] = useState<boolean>(false);
  const [highlightedHint, setHighlightedHint] = useState<TreeByIdHint | TreeByCustomerIdHint | ManagedAreaHint | AddressHint | null>(null);

  const searchRef = useRef<HTMLDivElement | null>(null);

  const onInputChange = async (value: string) => {
    if (value.length === 0) {
      setManagedAreasHints([]);
      setTreeByIdHints([]);
      setTreeByCustomerIdHints([]);
      setAddressHints([]);
      return;
    }

    const treesById = await treeService.findByExternalId(organization.id, value, 5);
    const treesByCustomerTreeId = await treeService.findByCustomerTreeId(organization.id, value, 5);
    const managedAreas = await managedAreaService.findByName(organization.id, value, 5);
    const addresses = await addressService.searchByAddress(calculateMaxBounds(organization.boundaries.coordinates), value, 5, user.language);

    if (treesById.length === 0 && managedAreas.length === 0 && addresses.length === 0 && treesByCustomerTreeId.length === 0) {
      setManagedAreasHints([]);
      setTreeByIdHints([]);
      setTreeByCustomerIdHints([]);
      setAddressHints([]);
      return setNoResultsFound(true);
    }

    if (treesById.length > 0) {
      setTreeByIdHints(treesById.map(tree => ({ id: tree.id, label: tree.externalId, description: tree.managedArea.code })));
    } else {
      setTreeByIdHints([]);
    }

    if (treesByCustomerTreeId.length > 0) {
      setTreeByCustomerIdHints(treesByCustomerTreeId.map(tree => ({ id: tree.id, label: tree.customerTreeId, description: tree.managedArea.code })));
    } else {
      setTreeByCustomerIdHints([]);
    }

    if (managedAreas.length > 0) {
      setManagedAreasHints(managedAreas.map(area => ({ id: area.id, label: area.code })));
    } else {
      setManagedAreasHints([]);
    }

    if (addresses.length > 0) {
      setAddressHints(addresses.map(it => ({ label: it.placeName, coordinates: it.geometry.coordinates, description: findManagedAreaByCoordinates(it.geometry.coordinates) })));
    } else {
      setAddressHints([]);
    }

    setNoResultsFound(false);
  };

  const findManagedAreaByCoordinates = coordinates => {
    if (!managedAreas) return;
    return managedAreas.find(area => pointInPolygon(coordinates, area.boundingBox.coordinates[0]))?.code;
  };

  const treeByIdHintSelect = (hint: TreeByIdHint | TreeByCustomerIdHint) => {
    urlContext.setTreeId(hint.id);
    onResultFound();
  };

  const managedAreaHintSelect = (hint: ManagedAreaHint) => {
    if (urlContext.getReverseMASelection()) {
      urlContext.setReverseMASelection(false);
    }
    urlContext.setManagedAreaIds([hint.id]);
    onResultFound();
  };

  const addressHintSelect = (hint: AddressHint) => {
    urlContext.setPositionFromCoordinates(hint.coordinates);
    onResultFound();
  };

  const searchNavigation = (event: KeyboardEvent) => {
    if (!['ArrowDown', 'ArrowUp', 'Enter'].includes(event.code)) return;
    event.preventDefault();
    const allHints = [...treeByIdHints, ...treeByCustomerIdHints, ...managedAreasHints, ...addressHints];
    if (!highlightedHint) return setHighlightedHint(allHints[0]);
    const currentIndex = allHints.indexOf(highlightedHint);

    if (event.code === 'ArrowDown') {
      if (currentIndex === allHints.length - 1) {
        setHighlightedHint(allHints[0]);
      } else {
        setHighlightedHint(allHints[currentIndex + 1]);
      }
    }
    if (event.code === 'ArrowUp') {
      if (currentIndex === 0) {
        setHighlightedHint(allHints[allHints.length - 1]);
      } else {
        setHighlightedHint(allHints[currentIndex - 1]);
      }
    }
    if (event.code === 'Enter') {
      if (treeByIdHints.includes(highlightedHint as TreeByIdHint)) {
        treeByIdHintSelect(highlightedHint as TreeByIdHint);
      } else if (treeByCustomerIdHints.includes(highlightedHint as TreeByCustomerIdHint)) {
        treeByIdHintSelect(highlightedHint as TreeByCustomerIdHint);
      } else if (managedAreasHints.includes(highlightedHint as ManagedAreaHint)) {
        managedAreaHintSelect(highlightedHint as ManagedAreaHint);
      } else if (addressHints.includes(highlightedHint as AddressHint)) {
        addressHintSelect(highlightedHint as AddressHint);
      }
    }
  };

  useEffect(() => {
    const search = searchRef.current;
    if (!search) return;
    search.addEventListener('keydown', searchNavigation);
    return () => {
      search.removeEventListener('keydown', searchNavigation);
    };
  }, [
    searchRef.current,
    JSON.stringify([treeByIdHints, treeByCustomerIdHints, managedAreasHints, addressHints]),
    highlightedHint,
    searchNavigation
  ]);

  return (
    <>
      <div
        ref={searchRef}
        data-testid="tree-managed-area-search"
        className={styles.searchBar + ' ' + (visible ? styles.animateSearchModal : '')}
      >
        <SearchInput
          dense
          placeholder={t('navbar.searchBar.searchInputPlaceholder')}
          onValueChange={useDebouncedCallback((value: string) => onInputChange(value), 500)}
          showError={noResultsFound}
          focus
          focusTrigger={visible}
          testId={'tree-managed-area-search-input'}
          errorMessage={t('navbar.searchBar.searchInputErrorMessage')}
        />
        <div className={styles.searchHintsContainer}>
          {Boolean(treeByIdHints.length) &&
            <LegacySearchHintsSection
              onSelect={treeByIdHintSelect}
              hints={treeByIdHints}
              title={'navbar.searchBar.treesById'}
              icon={<Tree fontSize={12}/>}
              highlightedHint={highlightedHint}
            />
          }
          {Boolean(treeByCustomerIdHints.length) &&
            <LegacySearchHintsSection
              onSelect={treeByIdHintSelect}
              hints={treeByCustomerIdHints}
              title={'navbar.searchBar.treesByCustomerId'}
              icon={<Tree fontSize={12}/>}
              highlightedHint={highlightedHint}
            />
          }
          {Boolean(managedAreasHints.length) &&
            <>
              {(Boolean(treeByIdHints.length) || Boolean(treeByCustomerIdHints.length)) && <hr className={styles.separator}/>}
              <LegacySearchHintsSection
                onSelect={managedAreaHintSelect}
                hints={managedAreasHints}
                title={'navbar.searchBar.managedAreas'}
                icon={<Map fontSize={12}/>}
                highlightedHint={highlightedHint}
              />
            </>
          }
          {Boolean(addressHints.length) &&
          <>
            {(Boolean(treeByIdHints.length) || Boolean(treeByCustomerIdHints.length) || Boolean(managedAreasHints.length)) && <hr className={styles.separator}/>}
            <LegacySearchHintsSection
              onSelect={addressHintSelect}
              hints={addressHints}
              title={'navbar.searchBar.addresses'}
              icon={<MapPin fontSize={12}/>}
              highlightedHint={highlightedHint}
            />
          </>
          }
        </div>
      </div>

    </>
  );
}

interface SearchBarProps {
  organization: Organization,
  onResultFound: () => unknown,
  visible: boolean,
  managedAreas?: ManagedArea[] | null
}
