import NumericPredicate from './NumericPredicate';
import EnumPredicate from './EnumPredicate';
import TreeFilterDto from './dto/TreeFilterDto';
import { Tree } from '../tree/Tree';
import PropertyConfigurationPredicate from './PropertyConfigurationPredicate';
import { Account } from '../account/Account';

export class TreeFilter {
  static fromDto(dto: TreeFilterDto) {
    return new TreeFilter(
      dto.id,
      dto.userId,
      dto.organizationId,
      dto.isSaved,
      dto.isPrivate,
      dto.name,
      dto.numericPredicates.map(NumericPredicate.fromDto),
      dto.enumPredicates.map(EnumPredicate.fromDto),
      dto.propertyConfigurationPredicates?.map(PropertyConfigurationPredicate.fromDto) || []
    );
  }

  constructor(
    readonly id: string,
    readonly userId: string,
    readonly organizationId: string,
    readonly isSaved: boolean,
    readonly isPrivate: boolean,
    readonly name: string,
    readonly numericPredicates: NumericPredicate[],
    readonly enumPredicates: EnumPredicate[],
    readonly propertyConfigurationPredicates: PropertyConfigurationPredicate[]
  ) {}

  getName() {
    return this.name;
  }

  isSameByAnyId(ids: string[]) {
    return ids.includes(this.id);
  }

  apply(tree: Tree, account: Account) {
    return [...this.numericPredicates, ...this.enumPredicates, ...this.propertyConfigurationPredicates].every(it => it.apply(tree, account));
  }

  isEmpty() {
    return [...this.numericPredicates, ...this.enumPredicates, ...this.propertyConfigurationPredicates].flat().length === 0;
  }

  toDto(): TreeFilterDto {
    return {
      id: this.id,
      userId: this.userId,
      organizationId: this.organizationId,
      isSaved: this.isSaved,
      isPrivate: this.isPrivate,
      name: this.name,
      numericPredicates: this.numericPredicates.map(it => it.toDto()),
      enumPredicates: this.enumPredicates.map(it => it.toDto()),
      propertyConfigurationPredicates: this.propertyConfigurationPredicates.map(it => it.toDto())
    };
  }

  getPropertyNames(): (keyof Tree)[] {
    const properties: (keyof Tree)[] = this.numericPredicates
      .map(it => it.property)
      .concat(this.enumPredicates.map(it => it.property))
      .concat(this.propertyConfigurationPredicates.map(it => it.propertyConfiguration.property) as (keyof Tree)[]);
    return Array.from(new Set(properties).values());
  }

  findIdByName(treeFilters: TreeFilter[]): string | null {
    return treeFilters.find(it => it.isSaved && it.name === this.name && it.id !== this.id)?.id || null;
  }
}
