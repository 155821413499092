type EvaluatedData = Record<string, {
  summarizedScore: number,
  sections: {
    label: string,
    value: number,
    properties: { label: string, value: boolean }[]
  }[]
}>;

const demoEvaluatedData: EvaluatedData = {
  '6570': {
    summarizedScore: 4,
    sections: [
      {
        label: 'Structure',
        value: 4,
        properties: [
          { label: 'Is Base TSI > 3?', value: true },
          { label: 'Final TSI > 1.5?', value: true },
          { label: 'Is lean < 30°?', value: false },
          { label: 'Slenderness < 200?', value: true },
          { label: 'Is dieback < 60%?', value: true }
        ]
      },
      {
        label: 'Health and Vitality',
        value: 5,
        properties: [
          { label: 'Is dieback < 60%', value: true },
          { label: 'Is crown transparency < 60%', value: true },
          { label: 'Are parasites (mistletoe, etc.) not visible?', value: true },
          { label: 'Are diseases not visible?', value: true },
          { label: 'Is NDVI > 0.5?', value: true }
        ]
      },
      {
        label: 'Clearances',
        value: 5,
        properties: [
          { label: 'Is wire clearance not required?', value: true },
          { label: 'Is road clearance not required?', value: true },
          { label: 'Is building clearance not required?', value: true },
          { label: 'Is traffic signs clearance not required?', value: true },
          { label: 'Is viaduct clearing not required?', value: true }
        ]
      },
      {
        label: 'Benefits',
        value: 5,
        properties: [
          { label: 'Is Gross CO₂ sequestration not in the lower third of the cohort?', value: true },
          { label: 'Is PM₂.₅ reduction not in the lower third of the cohort?', value: true },
          { label: 'Is Oxygen production not in the lower third of the co-hort?', value: true },
          { label: 'Is Avoided water runoff not in the lower third of the co-hort?', value: true },
          { label: 'Is Potential evapotranspiration not in the lower third of the co-hort?', value: true }
        ]
      },
      {
        label: 'Allometric Anomalies',
        value: 5,
        properties: [
          { label: 'Is the DBH - LA not in the lower/upper IQR range?', value: true },
          { label: 'Is the Height - Crown Volume not in the lower/upper IQR range?', value: true },
          { label: 'Is Slenderness - DBH not in the lower/upper IQR range?', value: true },
          { label: 'Is LA - Crown Volume  not in the lower/upper IQR range?', value: true },
          { label: 'Is Crown Volume - DBH  not in the lower/upper IQR range?', value: true }
        ]
      }
    ]
  },
  '25565': {
    summarizedScore: 1,
    sections: [
      {
        label: 'Structure',
        value: 2,
        properties: [
          { label: 'Is Base TSI > 3?', value: false },
          { label: 'Final TSI > 1.5?', value: false },
          { label: 'Is lean < 30°?', value: true },
          { label: 'Slenderness < 200 ?', value: true },
          { label: 'Is dieback < 60%?', value: false }
        ]
      },
      {
        label: 'Health & Vitality',
        value: 2,
        properties: [
          { label: 'Is dieback < 60%', value: false },
          { label: 'Is crown transparency < 60%', value: false },
          { label: 'Are parasites (mistletoe, etc.) not visible?', value: true },
          { label: 'Are diseases not visible?', value: false },
          { label: 'Is NDVI > 0.5?', value: true }
        ]
      },
      {
        label: 'Clearances',
        value: 5,
        properties: [
          { label: 'Is wire clearance not required?', value: true },
          { label: 'Is road clearance not required?', value: true },
          { label: 'Is building clearance not required?', value: true },
          { label: 'Is traffic signs clearance not required?', value: true },
          { label: 'Is viaduct clearing not required?', value: true }
        ]
      },
      {
        label: 'Benefits',
        value: 0,
        properties: [
          { label: 'Is Gross CO₂ sequestration not in the lower third of the co-hort?', value: false },
          { label: 'Is PM₂.₅ reduction not in the lower third of the co-hort?', value: false },
          { label: 'Is Oxygen production not in the lower third of the co-hort?', value: false },
          { label: 'Is Avoided water runoff not in the lower third of the co-hort?', value: false },
          { label: 'Is Potential evapotranspiration not in the lower third of the co-hort?', value: false }
        ]
      },
      {
        label: 'Allometric Anomalies',
        value: 1,
        properties: [
          { label: 'Is the DBH - LA not in the lower/upper IQR range?', value: false },
          { label: 'Is the Height - Crown Volume not in the lower/upper IQR range?', value: false },
          { label: 'Is Slenderness - DBH not in the lower/upper IQR range?', value: true },
          { label: 'Is LA - Crown Volume  not in the lower/upper IQR range?', value: false },
          { label: 'Is Crown Volume - DBH  not in the lower/upper IQR range?', value: false }
        ]
      }
    ]
  },
  '8450': {
    summarizedScore: 3,
    sections: [
      {
        label: 'Structure',
        value: 4,
        properties: [
          { label: 'Is Base TSI > 3?', value: true },
          { label: 'Final TSI > 1.5?', value: false },
          { label: 'Is lean < 30°?', value: true },
          { label: 'Slenderness < 200 ?', value: true },
          { label: 'Is dieback < 60%?', value: true }
        ]
      },
      {
        label: 'Health & Vitality',
        value: 5,
        properties: [
          { label: 'Is dieback < 60%', value: true },
          { label: 'Is crown transparency < 60%', value: true },
          { label: 'Are parasites (mistletoe, etc.) not visible?', value: true },
          { label: 'Are diseases not visible?', value: true },
          { label: 'Is NDVI > 0.5?', value: true }
        ]
      },
      {
        label: 'Clearances',
        value: 3,
        properties: [
          { label: 'Is wire clearance not required?', value: false },
          { label: 'Is road clearance not required?', value: true },
          { label: 'Is building clearance not required?', value: true },
          { label: 'Is traffic signs clearance not required?', value: false },
          { label: 'Is viaduct clearing not required?', value: true }
        ]
      },
      {
        label: 'Benefits',
        value: 5,
        properties: [
          { label: 'Is Gross CO₂ sequestration not in the lower third of the co-hort?', value: true },
          { label: 'Is PM₂.₅ reduction not in the lower third of the co-hort?', value: true },
          { label: 'Is Oxygen production not in the lower third of the co-hort?', value: true },
          { label: 'Is Avoided water runoff not in the lower third of the co-hort?', value: true },
          { label: 'Is Potential evapotranspiration not in the lower third of the co-hort?', value: true }
        ]
      },
      {
        label: 'Allometric Anomalies',
        value: 5,
        properties: [
          { label: 'Is the DBH - LA not in the lower/upper IQR range?', value: true },
          { label: 'Is the Height - Crown Volume not in the lower/upper IQR range?', value: true },
          { label: 'Is Slenderness - DBH not in the lower/upper IQR range?', value: true },
          { label: 'Is LA - Crown Volume  not in the lower/upper IQR range?', value: true },
          { label: 'Is Crown Volume - DBH  not in the lower/upper IQR range?', value: true }
        ]
      }
    ]
  },
  '65': {
    summarizedScore: 5,
    sections: [
      {
        label: 'Structure',
        value: 5,
        properties: [
          { label: 'Is Base TSI > 3?', value: true },
          { label: 'Final TSI > 1.5?', value: true },
          { label: 'Is lean < 30°?', value: true },
          { label: 'Slenderness < 200 ?', value: true },
          { label: 'Is dieback < 60%?', value: true }
        ]
      },
      {
        label: 'Health & Vitality',
        value: 5,
        properties: [
          { label: 'Is dieback < 60%', value: true },
          { label: 'Is crown transparency < 60%', value: true },
          { label: 'Are parasites (mistletoe, etc.) not visible?', value: true },
          { label: 'Are diseases not visible?', value: true },
          { label: 'Is NDVI > 0.5?', value: true }
        ]
      },
      {
        label: 'Clearances',
        value: 5,
        properties: [
          { label: 'Is wire clearance not required?', value: true },
          { label: 'Is road clearance not required?', value: true },
          { label: 'Is building clearance not required?', value: true },
          { label: 'Is traffic signs clearance not required?', value: true },
          { label: 'Is viaduct clearing not required?', value: true }
        ]
      },
      {
        label: 'Benefits',
        value: 5,
        properties: [
          { label: 'Is Gross CO₂ sequestration not in the lower third of the co-hort?', value: true },
          { label: 'Is PM₂.₅ reduction not in the lower third of the co-hort?', value: true },
          { label: 'Is Oxygen production not in the lower third of the co-hort?', value: true },
          { label: 'Is Avoided water runoff not in the lower third of the co-hort?', value: true },
          { label: 'Is Potential evapotranspiration not in the lower third of the co-hort?', value: true }
        ]
      },
      {
        label: 'Allometric Anomalies',
        value: 5,
        properties: [
          { label: 'Is the DBH - LA not in the lower/upper IQR range?', value: true },
          { label: 'Is the Height - Crown Volume not in the lower/upper IQR range?', value: true },
          { label: 'Is Slenderness - DBH not in the lower/upper IQR range?', value: true },
          { label: 'Is LA - Crown Volume  not in the lower/upper IQR range?', value: true },
          { label: 'Is Crown Volume - DBH  not in the lower/upper IQR range?', value: true }
        ]
      }
    ]
  },
  '12131': {
    summarizedScore: 2,
    sections: [
      {
        label: 'Structure',
        value: 5,
        properties: [
          { label: 'Is Base TSI > 3?', value: true },
          { label: 'Final TSI > 1.5?', value: true },
          { label: 'Is lean < 30°?', value: true },
          { label: 'Slenderness < 200 ?', value: true },
          { label: 'Is dieback < 60%?', value: true }
        ]
      },
      {
        label: 'Health & Vitality',
        value: 5,
        properties: [
          { label: 'Is dieback < 60%', value: true },
          { label: 'Is crown transparency < 60%', value: true },
          { label: 'Are parasites (mistletoe, etc.) not visible?', value: true },
          { label: 'Are diseases not visible?', value: true },
          { label: 'Is NDVI > 0.5?', value: true }
        ]
      },
      {
        label: 'Clearances',
        value: 4,
        properties: [
          { label: 'Is wire clearance not required?', value: true },
          { label: 'Is road clearance not required?', value: false },
          { label: 'Is building clearance not required?', value: true },
          { label: 'Is traffic signs clearance not required?', value: true },
          { label: 'Is viaduct clearing not required?', value: true }
        ]
      },
      {
        label: 'Benefits',
        value: 5,
        properties: [
          { label: 'Is Gross CO₂ sequestration not in the lower third of the co-hort?', value: true },
          { label: 'Is PM₂.₅ reduction not in the lower third of the co-hort?', value: true },
          { label: 'Is Oxygen production not in the lower third of the co-hort?', value: true },
          { label: 'Is Avoided water runoff not in the lower third of the co-hort?', value: true },
          { label: 'Is Potential evapotranspiration not in the lower third of the co-hort?', value: true }
        ]
      },
      {
        label: 'Allometric Anomalies',
        value: 2,
        properties: [
          { label: 'Is the DBH - LA not in the lower/upper IQR range?', value: false },
          { label: 'Is the Height - Crown Volume not in the lower/upper IQR range?', value: false },
          { label: 'Is Slenderness - DBH not in the lower/upper IQR range?', value: true },
          { label: 'Is LA - Crown Volume  not in the lower/upper IQR range?', value: true },
          { label: 'Is Crown Volume - DBH  not in the lower/upper IQR range?', value: false }
        ]
      }
    ]
  },
  '5585': {
    summarizedScore: 1,
    sections: [
      {
        label: 'Structure',
        value: 2,
        properties: [
          { label: 'Is Base TSI > 3?', value: false },
          { label: 'Final TSI > 1.5?', value: false },
          { label: 'Is lean < 30°?', value: true },
          { label: 'Slenderness < 200 ?', value: true },
          { label: 'Is dieback < 60%?', value: false }
        ]
      },
      {
        label: 'Health & Vitality',
        value: 1,
        properties: [
          { label: 'Is dieback < 60%', value: false },
          { label: 'Is crown transparency < 60%', value: false },
          { label: 'Are parasites (mistletoe, etc.) not visible?', value: false },
          { label: 'Are diseases not visible?', value: true },
          { label: 'Is NDVI > 0.5?', value: false }
        ]
      },
      {
        label: 'Clearances',
        value: 5,
        properties: [
          { label: 'Is wire clearance not required?', value: true },
          { label: 'Is road clearance not required?', value: true },
          { label: 'Is building clearance not required?', value: true },
          { label: 'Is traffic signs clearance not required?', value: true },
          { label: 'Is viaduct clearing not required?', value: true }
        ]
      },
      {
        label: 'Benefits',
        value: 0,
        properties: [
          { label: 'Is Gross CO₂ sequestration not in the lower third of the co-hort?', value: false },
          { label: 'Is PM₂.₅ reduction not in the lower third of the co-hort?', value: false },
          { label: 'Is Oxygen production not in the lower third of the co-hort?', value: false },
          { label: 'Is Avoided water runoff not in the lower third of the co-hort?', value: false },
          { label: 'Is Potential evapotranspiration not in the lower third of the co-hort?', value: false }
        ]
      },
      {
        label: 'Allometric Anomalies',
        value: 1,
        properties: [
          { label: 'Is the DBH - LA not in the lower/upper IQR range?', value: false },
          { label: 'Is the Height - Crown Volume not in the lower/upper IQR range?', value: false },
          { label: 'Is Slenderness - DBH not in the lower/upper IQR range?', value: true },
          { label: 'Is LA - Crown Volume  not in the lower/upper IQR range?', value: false },
          { label: 'Is Crown Volume - DBH  not in the lower/upper IQR range?', value: false }
        ]
      }
    ]
  },
  '19299': {
    summarizedScore: 2,
    sections: [
      {
        label: 'Structure',
        value: 4,
        properties: [
          { label: 'Is Base TSI > 3?', value: true },
          { label: 'Final TSI > 1.5?', value: true },
          { label: 'Is lean < 30°?', value: false },
          { label: 'Slenderness < 200 ?', value: true },
          { label: 'Is dieback < 60%?', value: true }
        ]
      },
      {
        label: 'Health & Vitality',
        value: 4,
        properties: [
          { label: 'Is dieback < 60%', value: true },
          { label: 'Is crown transparency < 60%', value: true },
          { label: 'Are parasites (mistletoe, etc.) not visible?', value: true },
          { label: 'Are diseases not visible?', value: true },
          { label: 'Is NDVI > 0.5?', value: false }
        ]
      },
      {
        label: 'Clearances',
        value: 5,
        properties: [
          { label: 'Is wire clearance not required?', value: true },
          { label: 'Is road clearance not required?', value: true },
          { label: 'Is building clearance not required?', value: true },
          { label: 'Is traffic signs clearance not required?', value: true },
          { label: 'Is viaduct clearing not required?', value: true }
        ]
      },
      {
        label: 'Benefits',
        value: 5,
        properties: [
          { label: 'Is Gross CO₂ sequestration not in the lower third of the co-hort?', value: true },
          { label: 'Is PM₂.₅ reduction not in the lower third of the co-hort?', value: true },
          { label: 'Is Oxygen production not in the lower third of the co-hort?', value: true },
          { label: 'Is Avoided water runoff not in the lower third of the co-hort?', value: true },
          { label: 'Is Potential evapotranspiration not in the lower third of the co-hort?', value: true }
        ]
      },
      {
        label: 'Allometric Anomalies',
        value: 4,
        properties: [
          { label: 'Is the DBH - LA not in the lower/upper IQR range?', value: false },
          { label: 'Is the Height - Crown Volume not in the lower/upper IQR range?', value: true },
          { label: 'Is Slenderness - DBH not in the lower/upper IQR range?', value: true },
          { label: 'Is LA - Crown Volume  not in the lower/upper IQR range?', value: true },
          { label: 'Is Crown Volume - DBH  not in the lower/upper IQR range?', value: true }
        ]
      }
    ]
  },
  '19959': {
    summarizedScore: 5,
    sections: [
      {
        label: 'Structure',
        value: 5,
        properties: [
          { label: 'Is Base TSI > 3?', value: true },
          { label: 'Final TSI > 1.5?', value: true },
          { label: 'Is lean < 30°?', value: true },
          { label: 'Slenderness < 200 ?', value: true },
          { label: 'Is dieback < 60%?', value: true }
        ]
      },
      {
        label: 'Health & Vitality',
        value: 5,
        properties: [
          { label: 'Is dieback < 60%', value: true },
          { label: 'Is crown transparency < 60%', value: true },
          { label: 'Are parasites (mistletoe, etc.) not visible?', value: true },
          { label: 'Are diseases not visible?', value: true },
          { label: 'Is NDVI > 0.5?', value: true }
        ]
      },
      {
        label: 'Clearances',
        value: 5,
        properties: [
          { label: 'Is wire clearance not required?', value: true },
          { label: 'Is road clearance not required?', value: true },
          { label: 'Is building clearance not required?', value: true },
          { label: 'Is traffic signs clearance not required?', value: true },
          { label: 'Is viaduct clearing not required?', value: true }
        ]
      },
      {
        label: 'Benefits',
        value: 5,
        properties: [
          { label: 'Is Gross CO₂ sequestration not in the lower third of the co-hort?', value: true },
          { label: 'Is PM₂.₅ reduction not in the lower third of the co-hort?', value: true },
          { label: 'Is Oxygen production not in the lower third of the co-hort?', value: true },
          { label: 'Is Avoided water runoff not in the lower third of the co-hort?', value: true },
          { label: 'Is Potential evapotranspiration not in the lower third of the co-hort?', value: true }
        ]
      },
      {
        label: 'Allometric Anomalies',
        value: 5,
        properties: [
          { label: 'Is the DBH - LA not in the lower/upper IQR range?', value: true },
          { label: 'Is the Height - Crown Volume not in the lower/upper IQR range?', value: true },
          { label: 'Is Slenderness - DBH not in the lower/upper IQR range?', value: true },
          { label: 'Is LA - Crown Volume  not in the lower/upper IQR range?', value: true },
          { label: 'Is Crown Volume - DBH  not in the lower/upper IQR range?', value: true }
        ]
      }
    ]
  }
};

export default demoEvaluatedData;
