import { ReactNode, MouseEvent } from 'react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  PrimaryDanger = 'primaryDanger',
  SecondaryDanger = 'secondaryDanger'
}
export default function Button({
  children,
  onClick,
  disabled = false,
  variant = ButtonVariant.Primary,
  className
}: ButtonProps) {
  const buttonClass = `
    twp p-1 rounded-full button py-2 px-4 w-full
    ${variant === ButtonVariant.Primary ? 'bg-greehill-600 hover:bg-greehill-800 text-greehill-00' : ''}
    ${variant === ButtonVariant.Secondary ? 'border border-outer-space-400 hover:bg-outer-space-400 text-greehill-00' : ''}
    ${variant === ButtonVariant.PrimaryDanger ? 'bg-danger-600 hover:bg-danger-800 text-greehill-00' : ''}
    ${variant === ButtonVariant.SecondaryDanger ? 'border border-outer-space-400 hover:bg-outer-space-400 text-danger-400' : ''}
    ${disabled ? (variant === ButtonVariant.Secondary ? 'border-outer-space-500 text-outer-space-500' : 'bg-outer-space-300 text-outer-space-500') : ''}
    ${className ?? ''}
  `;
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={buttonClass}
    >
      {children}
    </button>
  );
}

interface ButtonProps {
  children?: ReactNode,
  onClick?: (event: MouseEvent) => unknown,
  disabled?: boolean,
  variant?: ButtonVariant,
  className?: string
}
