import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import { useTranslation } from 'react-i18next';
import PointCloudViewerWithFakeHistory from '../../components/PointCloud/PointCloudViewerWithFakeHistory';
import {
  PageContainer,
  PageContent,
  PageHeader,
  PropertyListItem,
  TreePropertyList,
  TreeView
} from './components/Page';
import styles from './Health.module.scss';
import { useRef, useState } from 'react';
import { AVAILABLE_DATES, NOW } from './fakes';
import TreePropertyHistoryChart from './components/TreePropertyHistoryChart';
import { TreeStatistics } from '../../tree/TreeStatistics';
import { ChartControls, Legend, LegendContainer } from './components/ChartControls';
import GrowthAnalyticsChart from './components/GrowthAnalyticsChart';
import LsaChartSample from './images/lsa-chart-sample.svg';
import { EmbededGallery, GalleryImage } from './components/Gallery';
import DemoWarning from './components/DemoWarning';

const tabs = ['lai', 'growth-analytics', 'leaf-spectral-analysis'];

const growthAnalyticsProperties = [
  DisplayableTreeProperty.Height,
  DisplayableTreeProperty.CanopyHeight,
  DisplayableTreeProperty.CanopyWidth,
  DisplayableTreeProperty.TrunkCircumference
];

export default function HealthAnalytics(props: { tree: Tree, laiStatistics: TreeStatistics, gallery: GalleryImage[] }) {
  const { t } = useTranslation();
  const [tab, setSelectedTab] = useState(tabs[0]);
  const showPointCloud = tab === 'lai' || tab === 'growth-analytics';
  const [selectedDate, setSelectedDate] = useState(AVAILABLE_DATES[1]);

  const multiPropertyChartValues = useRef(
    growthAnalyticsProperties.map(it => ({
      label: t('tree.' + it),
      values: Array.from(AVAILABLE_DATES)
        .reverse()
        .map(date => (props.tree[it] as any ?? 0) * fakeScale(date))
        .sort((a, b) => a - b)
    }))
  );

  return (
    <PageContainer>
      <PageHeader title={t('analytics.navigation.health')}>
        <TreePropertyList>
          <PropertyListItem onClick={() => setSelectedTab('lai')} active={tab === 'lai'}>
            {t('analytics.health.leafAreaIndex')}
          </PropertyListItem>

          <PropertyListItem onClick={() => setSelectedTab('growth-analytics')} active={tab === 'growth-analytics'}>
            {t('analytics.health.growthAnalytics')}
          </PropertyListItem>

          <PropertyListItem
            onClick={() => setSelectedTab('leaf-spectral-analysis')}
            active={tab === 'leaf-spectral-analysis'}
          >
            {t('analytics.health.leafSpectralAnalytics')}
          </PropertyListItem>

          <PropertyListItem disabled>{t('analytics.health.multispectralAnalyticsSat')}</PropertyListItem>
        </TreePropertyList>
      </PageHeader>

      <PageContent>
        <div className={styles.container}>
          {showPointCloud && (
            <TreeView pointCloud>
              <PointCloudViewerWithFakeHistory scale={fakeLinearScale(selectedDate)} tree={props.tree} />
            </TreeView>
          )}

          {tab === 'leaf-spectral-analysis' && (
            <>
              <div className={styles.galleryContainer}>
                <EmbededGallery images={props.gallery} />
              </div>

              <div className={styles.chartWrapper}>
                <div className={styles.chartContainer}>
                  <img
                    src={LsaChartSample}
                    alt=""
                    style={{ maxHeight: '100%', margin: '0 auto', display: 'block', width: '40vw', maxWidth: '100%' }}
                  />

                  <ChartControls>
                    <DemoWarning />

                    <LegendContainer>
                      <Legend variant="positive">{t('analytics.value')}</Legend>

                      <Legend variant="negative">{t('analytics.median')}</Legend>
                    </LegendContainer>
                  </ChartControls>
                </div>
              </div>
            </>
          )}

          <div className={styles.chartWrapper}>
            {tab === 'lai' && (
              <div className={styles.chartContainer}>
                <TreePropertyHistoryChart
                  selectable
                  onSelectLabel={setSelectedDate}
                  labelFormatter={date => date.getFullYear().toString()}
                  history={fakeLaiStatistics(props.tree, props.laiStatistics)}
                />

                <ChartControls>
                  <LegendContainer>
                    <Legend variant="positive">{t('analytics.value')}</Legend>

                    <Legend variant="negative">{t('analytics.median')}</Legend>
                  </LegendContainer>
                </ChartControls>
              </div>
            )}

            {tab === 'growth-analytics' && (
              <div className={styles.chartContainer}>
                <GrowthAnalyticsChart
                  values={multiPropertyChartValues.current}
                  labels={Array.from(AVAILABLE_DATES).reverse()}
                  onSelectLabel={setSelectedDate}
                />

                <ChartControls>
                  <DemoWarning />

                  <LegendContainer>
                    <Legend variant="positive">{t('analytics.value')}</Legend>

                    <Legend variant="negative">{t('analytics.median')}</Legend>
                  </LegendContainer>
                </ChartControls>
              </div>
            )}
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}

function fakeLaiStatistics(tree: Tree, statistics: TreeStatistics) {
  return [
    { date: NOW, value: tree.leafAreaIndex, statistics },
    {
      date: AVAILABLE_DATES[1],
      value: tree.leafAreaIndex * 0.98,
      statistics: statistics.fake(0.98)
    },
    {
      date: AVAILABLE_DATES[2],
      value: tree.leafAreaIndex * 0.96,
      statistics: statistics.fake(0.96)
    },
    {
      date: AVAILABLE_DATES[3],
      value: tree.leafAreaIndex * 0.95,
      statistics: statistics.fake(0.95)
    },
    {
      date: AVAILABLE_DATES[4],
      value: tree.leafAreaIndex * 0.91,
      statistics: statistics.fake(0.91)
    }
  ];
}

function fakeLinearScale(date: Date) {
  return 1 - getDiffInMonths(NOW, date) / 200;
}

function fakeScale(date: Date) {
  const diffInMonths = getDiffInMonths(NOW, date);
  if (diffInMonths === 0) {
    return 1;
  }
  return 1 - diffInMonths * Math.random();
}

function getDiffInMonths(now: Date, date: Date) {
  return now.getMonth() - date.getMonth() + 12 * (NOW.getFullYear() - date.getFullYear());
}
