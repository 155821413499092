import { TreeNamecard } from './TreeNamecard';
import styles from '../tree-marker/PanoramicTreeMarker.module.scss';
import { TFunction } from 'react-i18next';
import { Tree } from '../../../tree/Tree';
import TreeService from '../../../tree/TreeService';
import { Organization } from '../../../organization/Organization';

export class PanoramicTreeNamecard extends TreeNamecard<HTMLDivElement> {
  private readonly divElement: HTMLDivElement;
  private readonly wrapper: HTMLDivElement;
  private initialized = false;
  constructor(
    treeService: TreeService,
    t: TFunction,
    onOpen: (tree: Tree) => unknown,
    organization: Organization,
    windSpeed: number
  ) {
    super(treeService, t, onOpen, organization, windSpeed);
    this.divElement = document.createElement('div');
    this.divElement.classList.add('tree-namecard');
    this.divElement.classList.add(styles.hidden);
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('mapboxgl-popup-content');
    this.divElement.appendChild(this.wrapper);
  }

  protected handleDetailsButtonClickEvent() {
    const detailsButton = this.divElement?.querySelector('.detailsButton');
    detailsButton?.removeEventListener('click', this.onOpenButtonClick);
    detailsButton?.addEventListener('click', this.onOpenButtonClick);
  }

  protected handlePopupMouseOverEvent() {
    this.divElement?.removeEventListener('mouseover', this.preventClosingOnHover);
    this.divElement?.addEventListener('mouseover', this.preventClosingOnHover);
  }

  protected override closeOnMouseLeave = () => {
    this.hideImmediately();
    this.divElement.dispatchEvent(new Event('popupclose', { bubbles: true }));
  };

  protected handlePopupMouseLeave() {
    this.divElement?.removeEventListener('mouseleave', this.closeOnMouseLeave);
    this.divElement?.addEventListener('mouseleave', this.closeOnMouseLeave);
  }

  public isOpen() {
    return !this.divElement.classList.contains(styles.hidden);
  }

  protected openOn(target: HTMLDivElement) {
    if (!this.initialized) {
      target.appendChild(this.divElement);
      this.initialized = true;
    }
    this.divElement.classList.remove(styles.hidden);
    this.divElement.classList.add(styles.namecardContainer);
  }

  protected resetUI() {
    this.divElement.classList.add(styles.hidden);
  }

  protected setContent(content: string) {
    this.wrapper.innerHTML = content;
  }

  protected setImages(imageUrls: string[]) {
    const imageElements = this.divElement.querySelectorAll('.image') as NodeListOf<HTMLDivElement>;
    if (!imageElements) {
      return;
    }
    imageElements.forEach((imageElement, index) => {
      imageElement.classList.remove('loading');
      imageElement.style.backgroundImage = `url('${imageUrls[index]}')`;
    });
  }

  protected setPosition(coordinates: [number, number]) {
  }
}
