import { useRef, useState } from 'react';
import AbsoluteModal from '../../../../../components/Modal/absolute-modal/AbsoluteModal';
import ModalDialog from '../../../../../components/Modal/absolute-modal/ModalDialog';
import { EditPencil, MoreVert } from 'iconoir-react';
import styles from './MoreOptionsSelector.module.scss';
import EyeIcon from '../EyeIcon';
import { useTranslation } from 'react-i18next';

export default function MoreOptionsSelector(props: MoreOptionsSelectorProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const viewSelectorRef = useRef<HTMLDivElement | null>(null);

  const calculateModalPosition = () => {
    if (!viewSelectorRef.current) return { top: 0, left: 0 };
    const rect = viewSelectorRef.current?.getBoundingClientRect();
    return { top: rect.top + 25, left: rect.left + 10 };
  };

  const handleEyeClick = () => {
    if (props.eyeAction) props.eyeAction();
    setOpen(false);
  };

  const handlePencilClick = () => {
    if (props.pencilAction) props.pencilAction();
    setOpen(false);
  };

  return (
    <>
      <div ref={viewSelectorRef}>
        <button onClick={() => setOpen(prev => !prev)} className={styles.verticalDotsButton}>
          <MoreVert />
        </button>
      </div>
      <AbsoluteModal
        isVisible={open}
        onHide={() => setOpen(false)}
        hideOverlay={true}
      >
        <ModalDialog
          classNames={styles.modal}
          style={{
            top: calculateModalPosition().top + 'px',
            left: calculateModalPosition().left + 'px'
          }}>
          <div>
            {props.eyeAction &&
              <button onClick={handleEyeClick} className={`${styles.optionRow} ${styles.viewAllRow}`}>
                <EyeIcon />
                <span>{t('treeDetails.moreOptionsMenu.viewAll')}</span>
              </button>
            }
            {props.pencilAction &&
              <button onClick={handlePencilClick} className={styles.optionRow}>
                <EditPencil fontSize={12}/>
                <span>{t('treeDetails.moreOptionsMenu.editMode')}</span>
              </button>
            }
          </div>
        </ModalDialog>
      </AbsoluteModal>
    </>
  );
}

interface MoreOptionsSelectorProps {
  eyeAction?: () => void,
  pencilAction?: () => void
}
