import styles from './MapToolbar.module.scss';
import { Position, MapPinMinus, Ruler } from 'iconoir-react';
import { ReactComponent as DigitalTwinToggle } from '../images/gh-icon-twin-tree.svg';
import { ChangeEvent, ReactNode, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'rc-tooltip/assets/bootstrap.css';
import MapStyleSelector, { MapStyle } from './MapStyleSelector';
import { DisplayTreesOptions } from '../../../components/Navbar/DisplayModes';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { Flippers } from '../../../switches/Flippers';
import { useCurrentAccount } from '../../../account/useAccounts';
import MapSettingsButton from './MapSettingsButton';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';

export enum MapMenuTypes {
  Settings = 'settings',
  StyleSelector = 'styleSelector'
}
export default function MapToolbar(props: MapToolbarProps) {
  const { t } = useTranslation();

  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const currentAccount = useCurrentAccount();
  const [openedMenu, setOpenedMenu] = useState<MapMenuTypes | null>(null);

  const toggleOpen = (target: MapMenuTypes) => {
    setOpenedMenu(openedMenu === target ? null : target);
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.bottomRow}>
        {props.style === MapStyle.Panoramic && (
          <MapToolbarButtonSection>
            <MapToolbarCheckbox
              onChange={() => props.onTreePointCloudSwitch()}
              tooltip={t('toolbox.hideTreePointCloudTooltip')}
              checked={!props.treePointCloudEnabled}>
              <DigitalTwinToggle />
            </MapToolbarCheckbox>
          </MapToolbarButtonSection>
        )}

        <MapStyleSelector
          isOpen={openedMenu === MapMenuTypes.StyleSelector}
          toggleOpen={() => toggleOpen(MapMenuTypes.StyleSelector)}
        />

        {currentAccount.organization.isEnabled(Flippers.dashboardRedesign) && (
          <MapSettingsButton
            isOpen={openedMenu === MapMenuTypes.Settings}
            toggleOpen={() => toggleOpen(MapMenuTypes.Settings)}
            onTreeMarkerSwitch={props.onTreeMarkerSwitch}
            treeMarkersEnabled={props.treeMarkersEnabled}
          />
        )}

        {/* Conditional rendering (next line) can be removed when cleaning up dashboardRedesign flipper */}
        {(!props.lineMeasurementSwitchDisabled || !currentAccount.organization.isEnabled(Flippers.dashboardRedesign)) &&
          <MapToolbarButtonSection>
            <MapToolbarCheckbox
              disabled={props.lineMeasurementSwitchDisabled}
              tooltip={t('toolbox.lineMeasureTooltip')}
              onChange={e => props.onLineMeasurementSwitch(Boolean(e.target.value))}
              checked={props.lineMeasurementEnabled}
            >
              <Ruler />
            </MapToolbarCheckbox>
            {
              !currentAccount.organization.isEnabled(Flippers.dashboardRedesign)
              && (urlContext.getDisplayMode() === DisplayTreesOptions.markers &&
                <MapToolbarCheckbox
                  onChange={() => props.onTreeMarkerSwitch()}
                  tooltip={t('toolbox.hideMarkersTooltip')}
                  checked={props.treeMarkersEnabled}
                >
                  <MapPinMinus />
                </MapToolbarCheckbox>)
            }
          </MapToolbarButtonSection>
        }

        <MapToolbarButtonSection>
          <MapToolbarButton disabled={props.resetViewSwitchDisabled} onClick={props.onResetView}>
            <Position></Position>
          </MapToolbarButton>
        </MapToolbarButtonSection>
      </div>
      <div className={styles.topRow}></div>
    </div>
  );
}

function MapToolbarButtonSection(props) {
  return (
    <div className={styles.btnSection}>
      {props.children}
    </div>
  );
}

function MapToolbarButton(props: ToolbarButtonProps) {
  return (
    <div className={`${styles.btn} ${props.disabled && styles.switchHidden}`} onClick={props.onClick}>
      {props.children}
    </div>
  );
}

function MapToolbarCheckbox(props: ToolbarCheckboxProps) {
  const onChangeCb = props.onChange;

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChangeCb) {
      onChangeCb(event);
    }
  }, [onChangeCb]);

  const element = (
    <label className={` ${styles.btn} ${props.disabled && styles.hidden} ${props.checked && styles.checked}`}>
      <input
        type={'checkbox'}
        checked={props.checked}
        className={styles.hidden}
        onChange={onChange}></input>
      {props.children}
    </label>);

  return (
    props.tooltip ?
      <Tooltip overlay={<span>{props.tooltip}</span>}>{element}</Tooltip>
      : element
  );
}

interface MapToolbarProps {
  style: MapStyle,
  onResetView: () => void,
  onTreeMarkerSwitch: () => void,
  onTreePointCloudSwitch: () => void,
  treeMarkersEnabled: boolean,
  treePointCloudEnabled: boolean,
  onLineMeasurementSwitch: (on: boolean) => void,
  lineMeasurementEnabled: boolean,
  lineMeasurementSwitchDisabled: boolean,
  resetViewSwitchDisabled: boolean
}

interface ToolbarControlProps {
  disabled?: boolean
}

interface ToolbarCheckboxProps extends ToolbarControlProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown,
  checked: boolean,
  children?: ReactNode,
  tooltip?: string
}

interface ToolbarButtonProps extends ToolbarControlProps {
  onClick?: () => unknown,
  children?: ReactNode
}
