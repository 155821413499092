import * as THREE from 'three';
import MultiResolutionMesh from './MultiResolutionMesh';

export default class PanoramicSphere extends THREE.Group {
  constructor(private readonly meshes: MultiResolutionMesh[], position: THREE.Vector3) {
    super();
    this.add(...meshes);
    this.position.copy(position);
    this.rotateX(-Math.PI / 2);
  }

  loadLowResolutionImages() {
    return Promise.all(this.meshes.map(it => it.loadLowResolutionImageUrl()));
  }

  loadHighResolutionImages() {
    return Promise.all(this.meshes.map(it => it.loadHighResolutionImageUrl()));
  }

  dispose() {
    this.meshes.forEach(it => {
      (it.material as THREE.MeshBasicMaterial).map?.dispose();
      (it.material as THREE.MeshBasicMaterial).dispose();
      it.geometry.dispose();
    });
  }
}
