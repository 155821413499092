import { Children, ReactElement } from 'react';
import styles from './LegacyNavbar.module.scss';
import LegacyProfileMenu from './LegacyProfileMenu';
import { useCurrentAccount } from '../../account/useAccounts';
import { FunctionButton } from '../UI/Button/LegacyButton';
import { ViewGrid } from 'iconoir-react';
import { useNavigate } from 'react-router-dom';

export default function LegacyNavbar(props: NavbarProps) {
  const currentAccount = useCurrentAccount();
  const navigate = useNavigate();
  const goHome = () => navigate(currentAccount.linkToOrganization());

  const leftExtensions = Children.map(props.children, child => (child.type === Left ? child : null));
  const centerExtensions = Children.map(props.children, child => (child.type === Center ? child : null));
  const rightExtensions = Children.map(props.children, child => (child.type === Right ? child : null));

  return (
    <div className={`${styles.navBar} ${props.details ? styles.details : ''} ${props.borderless ? styles.borderless : ''}`}>
      <div className={styles.leftContainer}>
        {leftExtensions}
      </div>

      <div className={styles.centerContainer}>{centerExtensions}</div>

      <div className={styles.rightContainer}>
        {rightExtensions}
        {currentAccount.organization.isDemo ?
          <FunctionButton
            className={styles.homeButton}
            icon={<ViewGrid />}
            onClick={goHome}/>
          : null
        }
        <LegacyProfileMenu currentAccount={currentAccount} />
      </div>
    </div>
  );
}

function Left({ children }) {
  return children;
}

function Center({ children }) {
  return children;
}

function Right({ children }) {
  return children;
}

LegacyNavbar.Left = Left;
LegacyNavbar.Center = Center;
LegacyNavbar.Right = Right;

interface NavbarProps {
  children: ReactElement | ReactElement[],
  details: boolean,
  borderless: boolean
}
