import styles from './RadioButtonGroup.module.scss';
import { useTranslation } from 'react-i18next';

interface RadioButtonGroupProps {
  options: { id: string, translationKey?: string, icon?: { filled: string, outline: string }, disabled?: boolean }[],
  value: string,
  onSelect: (id: string) => void
}

export default function RadioButtonGroup(props: RadioButtonGroupProps) {
  const { t } = useTranslation();

  return <div className={styles.radioButtonGroup}>
    {props.options.map(option =>
      <div
        key={option.id}
        className={`${props.value === option.id ? styles.active : ''} ${option.disabled ? styles.disabled : ''}`}
        onClick={() => !option.disabled && props.onSelect(option.id)}
      >
        {option.translationKey && t(option.translationKey)}
        {option.icon && <img alt={option.id} src={props.value === option.id ? option.icon.filled : option.icon.outline} />}
      </div>
    )}
  </div>;
}
