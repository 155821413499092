import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ReportData } from './ReportData';
import styles from './PrintableReport.module.scss';
import GeneralInsightsSection from './sections/GeneralInsightsSection';
import SpeciesDistributionSection from './sections/SpeciesDistributionSection';
import DBHDistributionSection from './sections/DBHDistributionSection';
import CanopyCoverage from './sections/CanopyCoverageSection';
import CarbonStorageSection from './sections/CarbonStorageSection';
import AirPollutionSection from './sections/AirPollutionSection';
import AvoidedWaterSection from './sections/AvoidedWaterSection';
import { useTranslation } from 'react-i18next';
import { Label } from 'iconoir-react';
import { useCurrentAccount } from '../../../account/useAccounts';
import { AuthContext } from '../../../auth/AuthContext';

export default function PrintableDialog() {
  const location = useLocation();
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [reportData, setReportData] = useState<ReportData | null>(null);
  const [managedAreas, setManagedAreas] = useState<string | null>(null);
  const [filterNames, setFilterNames] = useState<string | null>(null);
  const [chartsAreLoaded, setChartsLoaded] = useState({
    speciesDistribution: false,
    DBHDistribution: false,
    canopyCoverage: false,
    carbonStorage: false,
    avoidedWater: false
  });

  const reportName = t('reporting.reportName', { organizationName: account.getOrganizationName() });

  const reportHasOneSpecies = reportData?.speciesDistribution.length === 1;

  useEffect(() => {
    if (reportHasOneSpecies) {
      setChartsLoaded(prevState => {
        return {
          ...prevState,
          speciesDistribution: true,
          canopyCoverage: true,
          carbonStorage: true,
          avoidedWater: true
        };
      });
    }
  }, [reportHasOneSpecies]);

  useEffect(() => {
    if (Object.values(chartsAreLoaded).every(it => it)) {
      document.title = reportName;
      window.print();
    }
  }, [chartsAreLoaded]);

  useEffect(() => {
    if (!location.state) {
      return;
    }

    setReportData((location.state as any).reportData);
    setFilterNames((location.state as any).managedAreaNamesDisplay);
    setManagedAreas((location.state as any).filterNames);
    window.onafterprint = () => {
      navigate(-1);
    };

    return () => {
      window.onafterprint = null;
    };
  }, [location.state, navigate]);

  const Wrapper = props => (
    <div className={styles.pagebreak}>
      <div className={styles.header}>
        <div className={styles.reportDescription}>
          <div className={styles.organization}>
            <img src={account.getOrganizationLogoUrl()} alt=""/>
            <strong>{reportName}</strong>
          </div>
          <div className={styles.description}>
            <div className={styles.namesContainer}>
              <div className={styles.names}>
                {managedAreas}
              </div>
              <div className={styles.names}>
                <Label></Label>
                {filterNames}
              </div>
            </div>
            <div className={styles.label}>
              <strong>{t('reporting.numberOfTrees')}</strong>
              <span>{reportData?.total || '-'}</span>
            </div>
            <div className={styles.label}>
              <strong>{t('reporting.printReport.createdBy')}</strong>
              <span>{authContext.user.getName() || '-'}</span>
            </div>
            <div className={styles.label}>
              <strong>{t('reporting.printReport.createdOn')}</strong>
              <span>{new Date().toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' })}</span>
            </div>
          </div>
        </div>
      </div>
      {props.children}
      <div className={styles.footer}>
        <span>{t('reporting.printReport.footerDescription')}</span>
        <a href={'https://www.greehill.com'}>www.greehill.com</a>
      </div>
    </div>
  );

  const setLoaded = (property: string) => {
    if (chartsAreLoaded[property]) return;
    setChartsLoaded(prevState => ({ ...prevState, [property]: true }));
  };

  return (
    <div>
      {
        reportData &&
        <div className={styles.reportsContainer}>
          <Wrapper>
            <GeneralInsightsSection
              data={reportData.generalInsights}
              containerId={'generalInsights'}
              printable={true}
            />
          </Wrapper>
          {
            (reportData.speciesDistribution.length > 1) && (

              <Wrapper>
                <SpeciesDistributionSection
                  data={reportData.speciesDistribution}
                  containerId={'speciesDistribution'}
                  animationDisabled={true}
                  printable={true}
                  onLoaded={() => setLoaded('speciesDistribution')}
                />
              </Wrapper>
            )
          }
          <Wrapper>
            <DBHDistributionSection
              data={reportData.dbhDistribution}
              containerId={'DBHDistribution'}
              animationDisabled={true}
              printable={true}
              onLoaded={() => setLoaded('DBHDistribution')}
            />
          </Wrapper>
          {
            (reportData.speciesDistribution.length > 1) && (
              <Wrapper>
                <CanopyCoverage
                  data={reportData.leafAreaAndCanopyCoverage}
                  speciesDistribution={reportData.speciesDistribution}
                  containerId={'canopyCoverage'}
                  animationDisabled={true}
                  printable={true}
                  onLoaded={() => setLoaded('canopyCoverage')}
                />
              </Wrapper>

            )
          }
          {
            (reportData.speciesDistribution.length > 1) && (
              <Wrapper>
                <CarbonStorageSection
                  data={reportData.carbonStorageAndSequestration}
                  speciesDistribution={reportData.speciesDistribution}
                  containerId={'carbonStorage'}
                  animationDisabled={true}
                  printable={true}
                  onLoaded={() => setLoaded('carbonStorage')}
                />
              </Wrapper>

            )
          }
          <Wrapper>
            <AirPollutionSection
              data={reportData.airPollutionRemoval}
              containerId={'airPollution'}
              printable={true}
            />
          </Wrapper>

          {
            (reportData.speciesDistribution.length > 1) && (
              <Wrapper>
                <AvoidedWaterSection
                  data={reportData.avoidedWaterRunoff}
                  speciesDistribution={reportData.speciesDistribution}
                  containerId={'avoidedWater'}
                  animationDisabled={true}
                  printable={true}
                  onLoaded={() => setLoaded('avoidedWater')}
                />
              </Wrapper>
            )
          }
        </div>
      }
    </div>);
}
