import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { Task, TaskStatus } from '../../../task-manager/Task';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useContext } from 'react';
import { usePossibleAssignees } from '../../../task-manager/usePossibleAssignees';
import { DateInput } from '../../../components/UI/Input/Input';
import { ConnectedModule } from '../ConnectedModule';
import ActionButtons from './ActionButtons';
import { TooltipForEllipsis } from '../../../components/UI/Tooltip/Tooltip';
import { Bell, Tree } from 'iconoir-react';
import { ContextMenuTrigger } from '../../../components/UI/ContextMenu/ContextMenuTrigger';
import { ContextMenuContent } from '../../../components/UI/ContextMenu/ContextMenuContent';
import { ContextMenu } from '../../../components/UI/ContextMenu/ContextMenu';
import { SlideInIcon } from '../../../components/UI/Icon/Icon';
import { AssigneeSelectorButton, AssigneeSelectorTrigger } from './AssigneeSelector';
import Button, { ButtonVariant } from '../../../components/UI/Button/Button';
import TaskStatusPill from './TaskStatusPill';
import { AuthContext } from '../../../auth/AuthContext';
import { TaskManagerView } from '../TaskManagerView';
import { useNavigate } from 'react-router-dom';

export default function TaskRow(props: TaskRowProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const user = useContext(AuthContext).user;
  const navigate = useNavigate();

  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { possibleAssignees } = usePossibleAssignees(props.task.organizationId);

  const updateDeadline = async (deadline: Date | null) => {
    if (deadline) {
      const todayStartOfDay = new Date().setHours(0, 0, 0, 0);
      const deadlineStartOfDay = new Date(deadline).setHours(0, 0, 0, 0);
      if (deadlineStartOfDay < todayStartOfDay) return;
    }

    await taskManagerService.updateTask(props.task.organizationId, props.task.id, { deadline });
  };

  const updateAssignee = async (id: string | null) => {
    await taskManagerService.updateTask(props.task.organizationId, props.task.id, { assigneeUserId: id });
  };

  const getProgressBarColor = () => {
    if (props.task.status === TaskStatus.ARCHIVED) return 'bg-success-600';
    const ratio = props.task.progress / props.task.treeIds.length * 100;
    if (ratio < 50) return 'bg-danger-600';
    if (ratio < 75) return 'bg-summer-yellow-600';
    return 'bg-success-600';
  };
  const progressBarColor = getProgressBarColor();

  const disabledEdit = !account.canCreateTaskManagerJobs() || ![TaskStatus.DRAFT, TaskStatus.UNASSIGNED, TaskStatus.REJECTED, TaskStatus.REVOKED].includes(props.task.status);

  return (<>
    <tr>
      <td className="py-2 px-4">
        <ActionButtons task={props.task} />
      </td>
      <td className="group">
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={async () => {
            props.onSlideInClick();
            if (props.task.usersToNotify.includes(user.id)) {
              await taskManagerService.clearNotification(props.task.organizationId, props.task.id);
            }
          }}
        >
          <div className={`${props.task.usersToNotify.includes(user.id) ? '' : 'hidden'} group-hover:hidden`}>
            <Bell fill="#FF6971" color="#FF6971" />
          </div>
          <div className="hidden group-hover:block">
            <SlideInIcon />
          </div>
        </div>
      </td>
      <td className="py-2 px-4 whitespace-nowrap max-w-72">
        <TooltipForEllipsis overlay={props.task.name}>
          <div className="w-full truncate">
            {props.task.name}
          </div>
        </TooltipForEllipsis>
      </td>
      <td className="py-2 px-4">{props.task.code}</td>
      <td className="py-2 px-4 max-w-44">
        <TooltipForEllipsis overlay={props.task.area}>
          <div className="truncate">{props.task.area}</div>
        </TooltipForEllipsis>
      </td>
      <td className="py-2 px-4">
        {props.task.status === TaskStatus.DRAFT
          ? <Button variant={ButtonVariant.Secondary} onClick={() => (navigate(`/organizations/${account.organization.id}/task-manager/${TaskManagerView.MANAGE}/${props.task.id}`))}>
            <div className="flex items-center justify-center gap-2">
              {props.task.treeIds.length}
              <Tree fontSize={14} />
            </div>
          </Button>
          : <div className="flex items-center justify-center gap-2">
            {props.task.treeIds.length}
            <Tree fontSize={14} />
          </div>}
      </td>
      <td className="py-2 px-4 whitespace-nowrap max-w-72">
        <TooltipForEllipsis overlay={props.task.type}>
          <div className="w-full truncate">
            {props.task.type}
          </div>
        </TooltipForEllipsis>
      </td>
      <td className="py-2 px-4">
        {disabledEdit && (
          <AssigneeSelectorTrigger unassigned={!props.task.assignee?.getName()}>
            {props.task.assignee?.getName() || t('taskManager.taskTable.unassigned')}
          </AssigneeSelectorTrigger>
        )}

        {!disabledEdit && (
          <ContextMenu className="inline-flex">
            <ContextMenuTrigger className="inline-flex">
              <AssigneeSelectorTrigger unassigned={!props.task.assignee?.getName()}>
                {props.task.assignee?.getName() || t('taskManager.taskTable.unassigned')}
              </AssigneeSelectorTrigger>
            </ContextMenuTrigger>

            <ContextMenuContent className="rounded-lg max-w-72 overflow-hidden">
              <ul className="flex flex-col gap-1.5 py-2 max-h-80 overflow-y-auto">
                {possibleAssignees.map(it => (
                  <li key={it.id}>
                    <AssigneeSelectorButton
                      active={props.task.assignee?.id === it.id}
                      onClick={() => updateAssignee(it.id)}
                    >
                      {it.getName()}
                    </AssigneeSelectorButton>
                  </li>
                ))}

                <li>
                  <AssigneeSelectorButton active={!props.task.assignee?.id} onClick={() => updateAssignee(null)}>
                    {t('taskManager.taskTable.unassigned')}
                  </AssigneeSelectorButton>
                </li>
              </ul>
            </ContextMenuContent>
          </ContextMenu>
        )}
      </td>
      <td className="py-2 px-4">
        <DateInput
          onValueChange={updateDeadline}
          value={props.task.deadline}
          futureDateOnly={true}
          disabled={disabledEdit}
          className={`[&>span]:rounded-full [&>span]:py-2 [&>span]:px-4 ${!props.task.deadline ? '[&>span]:border-warn-chardonnay-400' : ''}`}
          disableKeyboardEdit={true}
        />
      </td>
      <td className="py-2 px-4"><TaskStatusPill status={props.task.status} /></td>
      <td className="py-2 px-4">
        {props.task.connectedModule === ConnectedModule.remoteInspection && <div>
          <progress
            value={props.task.status === TaskStatus.ARCHIVED ? 100 : props.task.progress}
            max={props.task.status === TaskStatus.ARCHIVED ? 100 : props.task.treeIds.length}
            className={`w-24 h-3
                        [&::-webkit-progress-bar]:rounded-lg
                        [&::-webkit-progress-value]:rounded-lg
                        [&::-webkit-progress-bar]:bg-outer-space-500
                        [&::-webkit-progress-value]:${progressBarColor}`}
          />
        </div>}</td>
      <td className="py-2 px-4 whitespace-nowrap max-w-52">
        <TooltipForEllipsis overlay={props.task.createdBy?.getName()}>
          <div className="w-full truncate">
            {props.task.createdBy?.getName()}
          </div>
        </TooltipForEllipsis>
      </td>
    </tr>
  </>);
}

interface TaskRowProps {
  task: Task,
  onSlideInClick: () => void
}
