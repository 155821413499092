import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TreePropertyFilterEditor.module.scss';
import { FunctionButton } from '../../../UI/Button/LegacyButton';
import { Xmark, Plus } from 'iconoir-react';
import AbsoluteModal from '../../../Modal/absolute-modal/AbsoluteModal';
import ModalDialog from '../../../Modal/absolute-modal/ModalDialog';
import { SpeciesSearch } from './SpeciesSearch';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useMatch } from 'react-router-dom';
import { useTracking } from '../../../../analytics/useTracking';

type SpeciesSelectorProps = { selectedSpecies: string[], onSelect: (s: string[]) => unknown, onSaveTemporary: () => unknown };

export function SpeciesSelector({ selectedSpecies, onSelect, onSaveTemporary }: SpeciesSelectorProps) {
  const editButtonRef = useRef<HTMLDivElement | null>(null);
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation();
  const { genusService } = useContext(DependencyInjectionContext);
  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';
  const { track, events } = useTracking();

  const [species, setSpecies] = useState<string[]>([]);

  useEffect(() => {
    genusService.listGenera(organizationId).then(setSpecies);
  }, [genusService, organizationId]);

  const addSelectedSpecies = (newSpecies: string) => {
    const newSelectedSpecies = [...selectedSpecies, newSpecies];
    track(events.SIDEBAR_FILTER_ADD_SPECIES_PREDICATE, { values: newSpecies });
    onSelect(newSelectedSpecies);
    onSaveTemporary();
  };

  const removeSelectedSpecies = (speciesToRemove: string) => {
    const newSelectedSpecies = [...selectedSpecies.filter(it => it !== speciesToRemove)];
    track(events.SIDEBAR_FILTER_REMOVE_SPECIES_PREDICATE, { values: speciesToRemove });
    onSelect(newSelectedSpecies);
    onSaveTemporary();
  };

  const setAllSpecies = () => {
    track(events.SIDEBAR_FILTER_SELECT_ALL_SPECIES);
    onSelect([]);
    onSaveTemporary();
    setEditing(false);
  };

  const calculateModalPosition = () => {
    if (!editButtonRef.current) return { top: 0, left: 0 };
    const rect = editButtonRef.current?.getBoundingClientRect();
    if (rect.bottom > window.innerHeight - 400) {
      return { top: Math.max((rect.top - 400), 5), left: 25 };
    }

    return { top: rect.bottom, left: 25 };
  };

  return (
    <div className={styles.speciesSelector}>
      <div className={styles.speciesSelectorBar}>
        <span className={styles.speciesLabel}>{t('sidebarFilter.species')}</span>
        <div ref={editButtonRef}>
          <FunctionButton onClick={() => setEditing(true)} icon={<Plus/>}/>
        </div>
      </div>
      <ul>
        {
          selectedSpecies.length > 0 ? selectedSpecies.map((it, index) => (
            <li key={it + index.toString()} className={styles.speciesListItem}>
              {it}
              <FunctionButton icon={<Xmark/>} onClick={() => removeSelectedSpecies(it)}/>
            </li>
          )) : <li className={styles.speciesListItem}>{t('sidebarFilter.allSpecies')}</li>
        }
      </ul>
      <AbsoluteModal isVisible={editing} onHide={() => setEditing(false)}>
        <ModalDialog
          classNames={styles.speciesModal}
          style={{
            top: calculateModalPosition().top + 'px',
            left:  calculateModalPosition().left + 'px'
          }}>
          <div className={styles.speciesModalHeader}>
            <span>{t('sidebarFilter.addSpecies')}</span>
            <FunctionButton onClick={() => setAllSpecies()} icon={<></>}>{t('sidebarFilter.allSpecies').toUpperCase()}</FunctionButton>
          </div>
          <SpeciesSearch
            speciesList={species}
            selectedSpecies={selectedSpecies}
            onRemove={option => option !== null ? removeSelectedSpecies(option) : null}
            onSelect={option => option !== null ? addSelectedSpecies(option) : null}></SpeciesSearch>

        </ModalDialog>
      </AbsoluteModal>
    </div>
  );
}
