import HistoryTrackingHandle from './HistoryTrackingHandle';
import { useContext, useMemo, useState } from 'react';
import styles from './HistoryTracking.module.scss';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import HistoryTrackingTable from './HistoryTrackingTable';
import DetailedTree from '../../../../../tree/DetailedTree';
import { TrackableTreeProperty } from '../../../../../tree/TrackableTreeProperty';
import { FunctionButton } from '../../../../../components/UI/Button/LegacyButton';
import { Xmark, GraphUp } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import { CohortStudyValue } from './CohortHistoryChart';
import { TreeHistoryReportModal } from './TreeHistoryReportModal';
import { getEndYearFromScanInterval, TseTreeScan } from '../../../../../tree/TseTreeScan';
import { Cohort, DetailsContext } from '../../../LegacyDetails';

export default function HistoryTracking(props: { tree: DetailedTree }) {
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const isOpen = urlContext.isHistoryTrackingOpen();

  const [highlightedProperties, setHighlightedProperties] = useState<TrackableTreeProperty[]>([]);
  const [isGraphOpen, setGraphOpen] = useState(false);

  const removeFromHighlighted = (property: TrackableTreeProperty) => {
    setHighlightedProperties(prev => prev.filter(it => it !== property));
  };
  const { cohort } = useContext(DetailsContext);

  const treeHistoryData = useMemo(() => {
    if ((props.tree.tseTreeScans || []).length > 1) {
      return Object.fromEntries(DetailedTree.TRACKABLE_TREE_PROPERTIES.map(prop => [
        prop, getHistoricalData(prop, props.tree.tseTreeScans!, cohort)
      ])) as Record<TrackableTreeProperty, CohortStudyValue[]>;
    }
  }, [props.tree, cohort]);
  if (!treeHistoryData) return null;

  return (
    <section className={styles.historyTrackingWrapper + (isOpen ? '' : ' ' + styles.closed)}>
      <HistoryTrackingHandle />
      <div className={isOpen ? '' : styles.closed}>
        <div className={styles.dashboard}>
          <ul>
            {highlightedProperties.map(it => (
              <li key={it}>
                {t('details.properties.' + it)}
                <span onClick={() => removeFromHighlighted(it)}>
                  <Xmark />
                </span>
              </li>
            ))}
          </ul>
          <FunctionButton
            onClick={() => setGraphOpen(true)}
            disabled={highlightedProperties.length === 0}
            icon={<GraphUp />}
            className={styles.dashboardButton}
          >
            {t('treeDetails.historyTracking.graph')}
          </FunctionButton>
        </div>
        <HistoryTrackingTable
          highlightedProperties={highlightedProperties}
          setHighlightedProperties={setHighlightedProperties}
          treeHistoryData={treeHistoryData}
        />
        <TreeHistoryReportModal
          treeHistoryData={treeHistoryData}
          highlightedProperties={highlightedProperties}
          isVisible={isGraphOpen}
          onHide={() => setGraphOpen(false)}
        />
      </div>
    </section>
  );
}

function getHistoricalData(prop: TrackableTreeProperty, tseTreeScans: TseTreeScan[], cohort: Cohort | null): { value: number, year: string }[] {
  const mu = cohort?.cohortValues[prop].mu ?? 0;
  const sigma = cohort?.cohortValues[prop].sigma ?? 0;

  return tseTreeScans
    .map(scan => ({ year: getEndYearFromScanInterval(scan.scanInterval), value: scan[prop], mu, sigma }))
    .sort((a, b) => Number(a.year) - Number(b.year));
}
