import styles from './DateSelector.module.scss';

export default function DateSelector(props: DateSelectorProps) {
  return (
    <div className={styles.dateSelector}>
      {new Date(props.date).toLocaleDateString()}
    </div>
  );
}

interface DateSelectorProps {
  date: Date
}
