import { AdvancedFilterType } from '../advanced-filter/AdvancedFilter';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import styles from './AdvancedFilterValueSelector.module.scss';
import { useState } from 'react';
import { useTracking } from '../../../../analytics/useTracking';

export const AdvancedFilterNumericTypeSelector = (props: AdvancedFilterNumericTypeSelectorProps) => {
  const [predicateType, setPredicateType] = useState<AdvancedFilterType>(props.predicateType);
  const { events, track } = useTracking();

  const dropdownItems = [
    {
      id: AdvancedFilterType.MIN,
      translationKey: 'treeList.advancedFiltering.is_above'
    },
    {
      id: AdvancedFilterType.MAX,
      translationKey: 'treeList.advancedFiltering.is_below'
    },
    {
      id: AdvancedFilterType.RANGE,
      translationKey: 'treeList.advancedFiltering.in_between'
    }
  ];

  const handlePredicateChange = (type: AdvancedFilterType) => {
    track(events.ADVANCED_FILTER_PREDICATE_TYPE_CHANGED, { type });
    setPredicateType(type);
    props.onChange(type);
  };

  return (
    <Dropdown
      value={dropdownItems.find(it => it.id === predicateType)}
      items={dropdownItems.filter(it => it.id !== predicateType)}
      fieldClassName={styles.dropdown}
      menuClassname={styles.dropdownMenu}
      onSelect={item => handlePredicateChange(item.id as AdvancedFilterType)}
      testId='advanced-filter-dropdown' />
  );
};

interface AdvancedFilterNumericTypeSelectorProps {
  onChange: (type: AdvancedFilterType) => unknown,
  predicateType: AdvancedFilterType
}
