import * as THREE from 'three';
import { Line2, LineGeometry, LineMaterial } from 'three-fatline';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import styles from './TwinViewTile.module.scss';
import { METER_TO_FEET } from '../../../../components/PointCloud/unitConstants';

export default class LShapeRuler {
  private readonly lowestOverlappingBranchHeight: number = 0;
  constructor(private readonly coordinates: number[][], private readonly isMetric: boolean) {
    const points = this.coordinates.map(it => new THREE.Vector3(...it));
    if (points[3]) {
      this.lowestOverlappingBranchHeight = points[3].clone().sub(points[2]).length();
    }
  }

  /*
  *    (2)-----(1)
  *             |
  *    (3)------+
  *             |
  *             |
  *            (0)
  * */
  toThreeObject(): THREE.Group {
    const points = this.coordinates.map(it => new THREE.Vector3(...it));

    const x = points[1].clone().add(points[3].clone().sub(points[2]));

    return new THREE.Group().add(
      this.createDashedLine([points[0], points[1].clone().add(points[1].clone().sub(points[0]).multiplyScalar(1 / 5))]),
      this.createDashedLine([points[1].clone().add(points[1].clone().sub(points[2]).multiplyScalar(1 / 5)), points[2]]),
      this.createTopMarker(points[1]),
      this.createTopLabel(points[2]),
      this.createRoadKerbMarker(points[0]),
      this.createRoadKerbLabel(points[0]),
      ...this.hasOverlapping() ? [
        this.createDashedLine([x.clone().add(x.clone().sub(points[3]).multiplyScalar(1 / 5)), points[3]]),
        this.createLowestOverlappingBranchHeightMarker(points[1]),
        this.createLowestOverlappingBranchHeightLabel(points[0], points[1], points[3])
      ] : []
    );
  }

  private hasOverlapping(): boolean {
    return !!this.lowestOverlappingBranchHeight;
  }

  private createTopMarker(tallestPoint: THREE.Vector3): CSS2DObject {
    const element = document.createElement('div');
    element.className = styles.marker;

    const innerCircle = document.createElement('div');
    innerCircle.className = styles.innerCircle;
    element.appendChild(innerCircle);

    const topMarker = new CSS2DObject(element);
    topMarker.position.set(tallestPoint.x, tallestPoint.y, tallestPoint.z);

    return topMarker;
  }

  private createRoadKerbMarker(lowestPoint: THREE.Vector3): CSS2DObject {
    const element = document.createElement('div');
    element.className = styles.marker;

    const innerCircle = document.createElement('div');
    innerCircle.className = styles.innerCircle;
    element.appendChild(innerCircle);

    const roadKerbMarker = new CSS2DObject(element);
    roadKerbMarker.position.set(lowestPoint.x, lowestPoint.y, lowestPoint.z);
    return roadKerbMarker;
  }

  private createRoadKerbLabel(lowestPoint: THREE.Vector3): CSS2DObject {
    const downwardsOffset = 0.5;

    const roadKerbElement = document.createElement('div');
    roadKerbElement.innerText = 'Road kerb';
    roadKerbElement.className = styles.roadKerbLabel;

    const roadKerbLabel = new CSS2DObject(roadKerbElement);
    roadKerbLabel.position.set(lowestPoint.x, lowestPoint.y, lowestPoint.z - downwardsOffset);

    return roadKerbLabel;
  }

  private createLowestOverlappingBranchHeightMarker(tallestPoint: THREE.Vector3): CSS2DObject {
    const element = document.createElement('div');
    element.className = styles.marker;

    const innerCircle = document.createElement('div');
    innerCircle.className = styles.innerCircle;
    element.appendChild(innerCircle);

    const marker = new CSS2DObject(element);
    marker.position.set(tallestPoint.x, tallestPoint.y, tallestPoint.z - this.lowestOverlappingBranchHeight);
    return marker;
  }

  private createLowestOverlappingBranchHeightLabel(lowestPoint: THREE.Vector3, tallestPoint: THREE.Vector3, obstructionPoint: THREE.Vector3): CSS2DObject {
    const height = lowestPoint.distanceTo(
      tallestPoint.clone().sub(new THREE.Vector3(0, 0, this.lowestOverlappingBranchHeight))
    );
    const element = document.createElement('div');
    element.innerHTML = `Obstruction (height)<b>${this.conditionalMeterToFeet(height).toFixed(1)}${this.isMetric ? ' m' : ' ft'}</b>`;
    element.className = styles.pointCloudLabel;
    element.style.translate = 'calc(-50% - 12px) 0%';

    const label = new CSS2DObject(element);
    label.position.set(
      obstructionPoint.x,
      obstructionPoint.y,
      obstructionPoint.z
    );

    return label;
  }

  private createTopLabel(tallestPoint: THREE.Vector3): CSS2DObject {
    const [bottom, top] = this.coordinates.slice(0, 2).map(it => new THREE.Vector3(...it));
    const element = document.createElement('div');
    element.innerHTML = `Minimum height<b>${this.conditionalMeterToFeet(top.distanceTo(bottom)).toFixed(1)}${this.isMetric ? ' m' : ' ft'}</b>`;
    element.className = styles.pointCloudLabel;
    element.style.translate = 'calc(-50% - 12px) 0%';

    const label = new CSS2DObject(element);
    label.position.set(tallestPoint.x, tallestPoint.y, tallestPoint.z);

    return label;
  }

  private createDashedLine(points: THREE.Vector3[]): THREE.Mesh {
    const mesh = new Line2(
      new LineGeometry().setPositions(points.flatMap(it => it.toArray())),
      new LineMaterial({
        color: 0xffffff,
        linewidth: 1,
        resolution: new THREE.Vector2(640, 480),
        dashed: true,
        dashScale: 8,
        depthTest: false
      })
    ).computeLineDistances();

    mesh.renderOrder = 10000000;
    mesh.userData = { disableCameraFacingRotation: true };

    return mesh;
  }

  private conditionalMeterToFeet(value: number): number {
    return this.isMetric ? value : value * METER_TO_FEET;
  }
}
