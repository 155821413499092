import { TreeFilter } from '../../../tree-filter/TreeFilter';
import { allOf, anyOf, containsLiteral, greaterThan, lessThan, range } from './MapboxPredicateHelper';

export class LegacyCanopyLayerFilter {
  constructor(private readonly filter?: TreeFilter) {
  }

  create() {
    if (!this.filter || this.filter.isEmpty()) return [];
    return allOf(
      ...this.filter.enumPredicates.map(it => containsLiteral(it.property, it.values)),
      ...this.filter.numericPredicates.filter(it => it.max !== null).map(it => lessThan(it.property, it.max)),
      ...this.filter.numericPredicates.filter(it => it.min !== null).map(it => greaterThan(it.property, it.min)),
      ...this.filter.propertyConfigurationPredicates.map(config =>
        anyOf(...config.rangeIndices.map(it => range(config.property, config.propertyConfiguration.ranges[it])))
      ));
  }
}
