import styles from './PropertyConfigurationRangeSelector.module.scss';
import PropertyConfigurationPredicate from '../../../../tree-filter/PropertyConfigurationPredicate';
import PropertyColorConfiguration from '../../../../properties/PropertyColorConfiguration';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';

export default function PropertyConfigurationRangeSelector(props: PropertyConfigurationRangeSelectorProps) {
  const colorMap = PropertyColorConfiguration.getColorsForConfig(props.propertyConfig!);

  if (!props.propertyConfigurationPredicate || !props.propertyConfig) return (<></>);
  return (
    <div data-testid="property-range-selector">
      <div key={`range-filter-${props.propertyConfigurationPredicate.property}`} className={styles.propertyLabel}>
        {props.propertyConfig.ranges.map((range, index) =>
          <div
            data-testid={`range-index-row-${index}`}
            key={`range-filter-label-${index}`}
            className={`${styles.rangeRow} ${props.propertyConfigurationPredicate?.rangeIndices.includes(index) ? styles.selectedRow : ''}`}
            onClick={() => props.onSelect(index)}>
            <div className={styles.circleAndLabel}>
              <div className={styles.circle} style={{ backgroundColor: `rgb(${colorMap[index]})` }} />
              <div className={styles.capitalizeFirstLetter}>{range.name}</div>
            </div>
            <div className={styles.minMaxContainer}>
              {range.from.toFixed(2)}
              -
              {range.to.toFixed(2)}
            </div>
          </div>)}
      </div>
    </div>
  );
}

interface PropertyConfigurationRangeSelectorProps {
  propertyConfigurationPredicate: PropertyConfigurationPredicate | undefined,
  propertyConfig: PropertyConfiguration | undefined,
  onSelect: (rangeIndex: number) => unknown
}
