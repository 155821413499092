import { AvailablePropertiesMap } from './usePropertyConfigurations';
import { DisplayableTreeProperty } from '../tree/Tree';
import { ReactElement } from 'react';

export class AvailableProperties {
  constructor(readonly propertiesInGroups: AvailablePropertiesMap) {
  }

  map(groupCb: (name: string, properties: DisplayableTreeProperty[]) => ReactElement): ReactElement {
    return <>
      {this.propertiesInGroups.metrical.length ? groupCb('treePropertySelector.metricalInfo', this.propertiesInGroups.metrical) : <></>}
      {this.propertiesInGroups.ecosystem.length ? groupCb('treePropertySelector.ecosystemServices', this.propertiesInGroups.ecosystem) : <></>}
      {this.propertiesInGroups.health.length ? groupCb('treePropertySelector.healthIndication', this.propertiesInGroups.health) : <></>}
      {this.propertiesInGroups.safety.length ? groupCb('treePropertySelector.safety', this.propertiesInGroups.safety) : <></>}
      {this.propertiesInGroups.economical.length ? groupCb('treePropertySelector.economicalValue', this.propertiesInGroups.economical) : <></>}
      {this.propertiesInGroups.identification.length ? groupCb('treePropertySelector.identification', this.propertiesInGroups.identification) : <></>}
    </>;
  }
}
