import { CSSProperties, ReactNode, useCallback, useEffect, useState } from 'react';
import styles from './DropDownMenu.module.scss';
import { Popover } from 'react-tiny-popover';
import { useLocation } from 'react-router-dom';

export default function DropDownMenu({
  children,
  width = 300,
  bottomPosition,
  triggerFactory,
  align = 'end'
}: DropDownMenuProps) {
  const location = useLocation();
  const [isOpen, setVisibility] = useState(false);
  const toggle = useCallback(() => setVisibility(isOpen => !isOpen), []);
  const close = useCallback(() => setVisibility(false), []);
  const contentStyle: CSSProperties = {
    width: `${width}px`,
    ...(bottomPosition && { marginTop: `${bottomPosition}px` })
  };

  useEffect(() => {
    if (isOpen) {
      close();
    }

    return () => {};
  }, [close, location]);

  return (
    <>
      <Popover
        onClickOutside={close}
        content={
          <div className={styles.content} style={contentStyle}>
            {children}
          </div>
        }
        positions={['bottom']}
        isOpen={isOpen}
        containerStyle={{ zIndex: '99999' }}
        align={align}
      >
        <div onClick={toggle}>{triggerFactory(isOpen)}</div>
      </Popover>
    </>
  );
}

interface DropDownMenuProps {
  children: ReactNode,
  triggerFactory: (isOpen: boolean) => ReactNode,
  width?: number,
  bottomPosition?: number,
  align?: 'end' | 'start'
}
