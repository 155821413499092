import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PageContainer, PageHeader } from './components/Page';
import { TreeStatistics } from '../../tree/TreeStatistics';
import { AVAILABLE_DATES, NOW } from './fakes';
import {
  TreePropertyDateSelectorTableRow,
  TreePropertyTable,
  TreePropertyTableRow
} from './components/TreePropertyTable';
import { useCurrentAccount } from '../../account/useAccounts';
import { AnalyticsView } from './AnalyticsView';

const displayableTreeProperties = [
  DisplayableTreeProperty.AvoidedRunoff,
  DisplayableTreeProperty.CarbonStorage,
  DisplayableTreeProperty.CO,
  DisplayableTreeProperty.Evaporation,
  DisplayableTreeProperty.GrossCarbonSequestration,
  DisplayableTreeProperty.NO2,
  DisplayableTreeProperty.OxygenProduction,
  DisplayableTreeProperty.O3,
  DisplayableTreeProperty.NDVI,
  DisplayableTreeProperty.TreeHealth,
  DisplayableTreeProperty.PM25,
  DisplayableTreeProperty.PotentialEvapotranspiration,
  DisplayableTreeProperty.SO2,
  DisplayableTreeProperty.Transpiration,
  DisplayableTreeProperty.WaterIntercepted,
  DisplayableTreeProperty.ThermalComfort
];

export default function EcologicalAnalytics(props: EcologicalBenefitsProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const [dateA, setDateA] = useState(NOW);
  const [dateB, setDateB] = useState(AVAILABLE_DATES.at(-1)!);

  useEffect(() => {
    if (!props.compare) {
      setDateA(NOW);
      setDateB(AVAILABLE_DATES.at(-1)!);
    }
  }, [props.compare]);

  const header = (
    <PageHeader title={t('analytics.navigation.ecologicalBenefits')}>
    </PageHeader>
  );

  if (props.view === AnalyticsView.Data) {
    return (
      <PageContainer>
        <TreePropertyTable compare={props.compare} header={header}>
          {displayableTreeProperties.map(property => {
            const statistics = props.statistics.find(it => it.isForProperty(property)) ?? null;
            const current = props.tree[property] as any ?? null;
            if (statistics === null || current === null) {
              return null;
            }

            const historyMultiplierA =
              dateA.getTime() === NOW.getTime() ? 1 : 1 - Math.abs(NOW.getFullYear() - dateA.getFullYear()) / 10;
            const historyMultiplierB =
              dateB.getTime() === NOW.getTime() ? 1 : 1 - Math.abs(NOW.getFullYear() - dateB.getFullYear()) / 10;

            const propertyValue = current * historyMultiplierA;
            const comparisonValue = current * historyMultiplierB;

            return (
              <TreePropertyTableRow
                property={property}
                key={property}
                unit={Tree.getUnit(property, organization)}
                value={propertyValue}
                showComparisonValue={props.compare}
                comparisonValue={comparisonValue}></TreePropertyTableRow>
            );
          })}
          {props.compare ?
            <TreePropertyDateSelectorTableRow
              dateA={dateA}
              dateB={dateB}
              setDateA={setDateA}
              setDateB={setDateB}
              availableDates={AVAILABLE_DATES}></TreePropertyDateSelectorTableRow>
            : null
          }
        </TreePropertyTable>

      </PageContainer>
    );
  }

  return (
    <div>
      {header}

      <p>{props.view}</p>
    </div>
  );
}

interface EcologicalBenefitsProps {
  tree: Tree,
  view: AnalyticsView,
  statistics: TreeStatistics[],
  compare: boolean
}
