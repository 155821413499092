import * as THREE from 'three';
import PointMarker from './images/point-marker.svg';

export default class SafetyFactorMarkerGroup extends THREE.Group {
  private readonly dot: THREE.Sprite | null = null;
  private readonly scaledRadius: number;

  constructor(
    private readonly radius: number,
    private readonly thickness: number,
    private readonly numberOfSegments: number,
    private windDirection: number,
    private readonly scaling: number,
    private readonly triggerRepaint: () => void
  ) {
    super();

    this.scaledRadius = scaling * radius;
    const outerRadius = this.scaledRadius + thickness / 2;
    const innerRadius = this.scaledRadius - thickness / 2;
    const resolution = numberOfSegments * 2;
    const angles = [...new Array(resolution).fill(0).map((_, i) => i / resolution * 2 * Math.PI)];
    const dashes: THREE.Shape[] = [];
    for (let i = 0; i < angles.length; i += 2) {
      dashes.push(this.createDash(outerRadius, innerRadius, angles[i], angles[i + 1]));
    }
    const geometry = new THREE.ShapeGeometry(dashes);
    const material = new THREE.MeshBasicMaterial({ color: '#ffffff', side: THREE.DoubleSide });
    this.add(new THREE.Mesh(geometry, material));

    this.dot = new THREE.Sprite(
      new THREE.SpriteMaterial({
        map: new THREE.TextureLoader().load(PointMarker, triggerRepaint),
        fog: false,
        depthTest: false
      })
    );
    this.dot.scale.set(0.3, 0.3, 1);
    this.dot.position.set(this.scaledRadius * Math.sin(THREE.MathUtils.degToRad(windDirection)), this.scaledRadius * Math.cos(THREE.MathUtils.degToRad(windDirection)), 0);
    this.add(this.dot);
    this.rotateX(-Math.PI / 2);
  }

  setWindDirection(windDirection: number) {
    this.windDirection = windDirection;
    this.dot?.position.set(this.scaledRadius * Math.sin(THREE.MathUtils.degToRad(windDirection)), this.scaledRadius * Math.cos(THREE.MathUtils.degToRad(windDirection)), 0);
  }

  private createDash(r1: number, r2: number, startAngle: number, endAngle: number) {
    return new THREE.Shape([
      new THREE.Vector2(r1 * Math.sin(startAngle), r1 * Math.cos(startAngle)),
      new THREE.Vector2(r2 * Math.sin(startAngle), r2 * Math.cos(startAngle)),
      new THREE.Vector2(r2 * Math.sin(endAngle), r2 * Math.cos(endAngle)),
      new THREE.Vector2(r1 * Math.sin(endAngle), r1 * Math.cos(endAngle))
    ]);
  }
}
