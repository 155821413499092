import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ControlledExclusiveDetails, DetailsGroup } from '../../../components/UI/DetailsGroup/DetailsGroup';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import DetailedTree from '../../../tree/DetailedTree';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import RiskCategorization, {
  ConsequencesOfFailure,
  LikelihoodOfFailure,
  LikelihoodOfImpact
} from './RiskCategorization';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import { DataStatus } from '../../../tree/DataStatus';
import Toggle from '../../../components/UI/Toggle/Toggle';
import IsaRiskStandards, { IsaRiskRating } from './isaRiskStandards';
import { InspectionLimitation } from '../../../tree/InspectionLimitation';
import { RiskRating } from '../../../property-enums/RiskRating';
import { CheckCircle, Eye, EyeSolid, PlusCircle, Trash, XmarkCircle } from 'iconoir-react';
import { TreeValueMethod } from '../../../property-enums/TreeValueMethod';
import { DetailsContext, ExtraObject } from '../Details';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { TreeDisplays } from '../TreeDisplayTile/TreeDisplays';
import { JobColumnName } from '../../TaskManager/create/JobColumnName';
import { useTaskTemplates } from '../../TaskManager/create/useTaskTemplates';
import RadarChart from './RadarChart';
import demoEvaluatedData from './demoEvaluatedData';
import { Flippers } from '../../../switches/Flippers';

export const riskColorMap = new Map<string, string>(
  [
    ['low', 'bg-greehill-500'],
    ['moderate', 'bg-summer-yellow-500'],
    ['high', 'bg-warn-chardonnay-500'],
    ['extreme', 'bg-danger-500']
  ]
);

export default function CurrentState({ tree, handleChange, disableEdit, taskId }: {
  tree: DetailedTree | null,
  handleChange: <T>(property: string, value: T) => void,
  disableEdit: boolean,
  taskId: string
}) {
  const { setRulers, rulers, setRiskOverlayIsVisible, showCRZ, setCRZVisibility, showSCRZ, setSCRZVisibility } = useContext(DetailsContext);
  const { urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  const [selectedSectionIdx, setSelectedSectionIdx] = useState(0);
  const [openDetails, setOpenDetails] = useState<string | null>(null);
  const [focusedElement, setFocusedElement] = useState<string | null>(null);
  const { organization } = useCurrentAccount();
  const { taskTemplates } = useTaskTemplates(organization.id);
  const mitigationOptions = taskTemplates
    .filter(template => template.jobColumnName !== JobColumnName.Inspection)
    .map(template => ({ id: template.id, translationKey: template.name }));
  const remainingMitigationOptions = mitigationOptions
    .filter(template => (tree?.mitigations || []).every(mitigation => mitigation.taskTemplateId !== template.id));

  const StyledExclusiveDetails = (props: { children: ReactNode, summary: string, summaryActions?: ReactNode | ReactNode[] }) => (
    <ControlledExclusiveDetails
      summary={props.summary}
      summaryControl={props.summaryActions}
      className="pr-4 py-[14px]"
      summaryClassName="uppercase font-bold text-outer-space-200 text-[13px]"
      isOpen={openDetails === props.summary}
      toggleOpen={() => setOpenDetails(prev => prev === props.summary ? null : props.summary)}
    >
      {props.children}
    </ControlledExclusiveDetails>
  );

  const evaluatedData = useMemo(() => {
    return demoEvaluatedData[tree?.externalId || ''];
  }, [tree]);

  const currentEvaluationSection = evaluatedData?.sections[selectedSectionIdx];

  const toggleProperty = useCallback((property: ExtraObject) => {
    if (rulers.includes(property)) {
      const index = rulers.indexOf(property);
      rulers.splice(index, 1);
      setRulers([...rulers]);
    } else {
      setRulers((prev: ExtraObject[]) => [...prev, property]);
    }
  }, [rulers]);

  useEffect(() => {
    if (openDetails === 'Structural assessment') {
      setRiskOverlayIsVisible(true);
    } else {
      setRiskOverlayIsVisible(false);
    }
  }, [openDetails]);

  if (!tree) return null;

  const potentialTargetsChange = (index: number, property: string, value: string) => {
    handleChange('potentialTargets',
      (tree?.potentialTargets || []).map((target, i) => i === index ? {
        ...target,
        [property]: value
      } : target)
    );
  };

  const riskRatings = tree.potentialTargets?.map(it =>
    IsaRiskStandards.getRiskRating(
      Object.values(LikelihoodOfFailure).indexOf(it.likelihoodOfFailure),
      Object.values(LikelihoodOfImpact).indexOf(it.likelihoodOfImpact),
      Object.values(ConsequencesOfFailure).indexOf(it.consequences)
    )
  ) || [IsaRiskRating.low];
  const maxRiskRating = IsaRiskRating[Math.max(...riskRatings)];

  const riskRatingChips = [
    ...(tree.safetyFactorAt80Kmh && tree.safetyFactorAt80Kmh < 1.5 ? [`TSI ${tree.safetyFactorAt80Kmh.toFixed(2)}`] : []),
    ...(tree.vitalityVigor && ['low', 'dead'].includes(tree.vitalityVigor) ? [`Vigor ${tree.vitalityVigor}`] : []),
    ...(tree.foliageNoneDead ? ['Foliage None (Dead)'] : []),
    ...(tree.observations.filter(it =>
      !(it.note?.startsWith(`${t('tree.liveCrownRatio')}:`) || it.note?.startsWith(`${t('parameters.leaningAngle')}:`))
    ).map(observation => `${t(`virtualInspection.observationOptions.${observation.name}`)}`) || []),
    ...(tree.liveCrownRatio ? [`${t('tree.liveCrownRatio')}: ${tree.liveCrownRatio?.toFixed(2)}`] : []),
    ...(tree.leaningAngle ? [`${t('parameters.leaningAngle')}: ${tree.leaningAngle.toFixed(2)}`] : [])
  ];

  const toggleAllProperties = () => {
    if (rulers.length === 5 && showCRZ && showSCRZ) {
      setRulers([]);
      setCRZVisibility(false);
      setSCRZVisibility(false);
    } else {
      setRulers([
        DisplayableTreeProperty.CanopyHeight,
        DisplayableTreeProperty.CanopyWidth,
        DisplayableTreeProperty.TrunkDiameter,
        DisplayableTreeProperty.TrunkHeight,
        DisplayableTreeProperty.Height
      ]);
      setCRZVisibility(true);
      setSCRZVisibility(true);
    }
  };

  const getDimensionsAction = () => {
    if (urlContext.getDetailsTreeDisplay() === TreeDisplays.IMAGES || openDetails !== 'Dimensions') return null;
    if (rulers.length === 5 && showCRZ && showSCRZ) {
      return <EyeSolid onClick={e => {e.stopPropagation(); toggleAllProperties(); }}/>;
    }
    return <Eye onClick={e => { e.stopPropagation(); toggleAllProperties(); }}/>;
  };

  return (
    <DetailsGroup className="twp text-greehill-00 divide-y divide-outer-space-500">
      <div>
        <div className="font-medium px-5 py-2 pt-4">
          {t('taskManager.currentState.overview')}
        </div>
        <div className="flex flex-col gap-8">
          <div>
            <div className="mt-2 w-full flex gap-3 justify-center p-2 mb-6">
              <span className="text-outer-space-200 text-sm gap-2 flex items-center bg-outer-space-600 rounded w-1/2 py-2 px-2.5">
                <span>{t('taskManager.currentState.overallTreeRiskRating')}</span>
                <span className="text-greehill-00 font-bold">{maxRiskRating ? t(`treeDetails.riskRating.isaRiskRating.${maxRiskRating}`) : '-'}</span>
                <div className={`size-3 rounded-full ${riskColorMap.get(maxRiskRating)}`} />
              </span>
              <span className="text-outer-space-200 text-sm gap-2 flex items-center bg-outer-space-600 rounded w-1/2 py-2 px-2.5">
                <span>{t('details.properties.condition')}:</span>
                <span className="text-greehill-00 font-bold">{tree.condition ? t('details.properties.conditionTypes.' + tree.condition) : '-'}</span>
              </span>
            </div>
            {!evaluatedData && <div className="my-6 flex gap-2 flex-wrap">
              {riskRatingChips.map((chip, index) => (
                <span key={index} className="px-3 py-1 border border-outer-space-400 rounded">
                  {chip}
                </span>
              ))}
            </div>}
            {evaluatedData && <div className="m-auto">
              <RadarChart data={evaluatedData.sections} selectedSectionIdx={selectedSectionIdx} setSelectedSectionIdx={setSelectedSectionIdx} />
            </div>}
          </div>
          {evaluatedData && currentEvaluationSection &&
            <Valuation key={currentEvaluationSection.label} title={currentEvaluationSection.label} scoreText={`${currentEvaluationSection.value}/${currentEvaluationSection.properties?.length}`}>
              {currentEvaluationSection.properties.map((property, i) => {
                return (
                  <li key={property.label + i}>
                    <span className="text-outer-space-200 flex items-center gap-2 text-[13px]">
                      <div>{
                        property.value ?
                          <CheckCircle className="text-greehill-400"/> :
                          <XmarkCircle className="text-danger-400"/>
                      }</div>
                      <div>{property.label}</div>
                    </span>
                  </li>
                );
              })}
            </Valuation>
          }
        </div>
      </div>
      <StyledExclusiveDetails summary={t('taskManager.currentState.benefits')}>
        <PropertyFieldGroup title={t('taskManager.currentState.waterManagement')}>
          <PropertyField property={DisplayableTreeProperty.AvoidedRunoff} value={tree?.avoidedRunoff} />
          <PropertyField property={DisplayableTreeProperty.PotentialEvapotranspiration} value={tree?.potentialEvapotranspiration} />
          <PropertyField property={DisplayableTreeProperty.WaterIntercepted} value={tree?.waterIntercepted} />
          <PropertyField property={DisplayableTreeProperty.Evaporation} value={tree?.evaporation} />
          <PropertyField property={DisplayableTreeProperty.Transpiration} value={tree?.transpiration} />
        </PropertyFieldGroup>
        <PropertyFieldGroup title={t('taskManager.currentState.ecosystemServices')}>
          <PropertyField property={DisplayableTreeProperty.CarbonStorage} value={tree?.carbonStorage} />
          <PropertyField
            property={DisplayableTreeProperty.GrossCarbonSequestration}
            value={tree?.grossCarbonSequestration} />
          <PropertyField property={DisplayableTreeProperty.OxygenProduction} value={tree?.oxygenProduction} />
          <PropertyField property={DisplayableTreeProperty.PM25} value={tree?.pm25} />
          <PropertyField property={DisplayableTreeProperty.CO} value={tree?.co} />
          <PropertyField property={DisplayableTreeProperty.NO2} value={tree?.no2} />
          <PropertyField property={DisplayableTreeProperty.O3} value={tree?.o3} />
          <PropertyField property={DisplayableTreeProperty.SO2} value={tree?.so2} />
        </PropertyFieldGroup>
        {organization.isEnabled(Flippers.davey) ? '' : (
          <PropertyFieldGroup title={t('taskManager.currentState.economicalValue')}>
            {organization.treeValueMethod === TreeValueMethod.CAVAT ? <PropertyField property={DisplayableTreeProperty.TreeValueCavat} value={tree?.treeValueCavat} /> : ''}
            {organization.treeValueMethod === TreeValueMethod.KOCH ? <PropertyField property={DisplayableTreeProperty.TreeValueKoch} value={tree?.treeValueKoch} /> : ''}
            {organization.treeValueMethod === TreeValueMethod.RADO ? <PropertyField property={DisplayableTreeProperty.TreeValueRado} value={tree?.treeValueRado} /> : ''}
            {organization.treeValueMethod === TreeValueMethod.CTLA ? <PropertyField property={'ctla'} value={tree?.ctla} /> : ''}
            {organization.treeValueMethod === TreeValueMethod.NORMA_GRANADA ? <PropertyField property={'normaGranada'} value={tree?.normaGranada} /> : ''}
            {organization.treeValueMethod === TreeValueMethod.VAT19 ? <PropertyField property={'vat19'} value={tree?.vat19} /> : ''}
            {organization.treeValueMethod === TreeValueMethod.ALNARP_MODEL ? <PropertyField property={'alnarpModel'} value={tree?.alnarpModel} /> : ''}
          </PropertyFieldGroup>
        )}
      </StyledExclusiveDetails>
      <StyledExclusiveDetails summary={t('taskManager.currentState.healthAndVitality')}>
        <PropertyFieldGroup title={t('taskManager.currentState.conditionAndVigor')}>
          <PropertyField property={DisplayableTreeProperty.Dieback} value={tree?.dieback} />
          <PropertyField property={'basalAreaIncrement' as DisplayableTreeProperty} value={undefined} />
          <PropertyField property={DisplayableTreeProperty.Condition} value={tree?.condition} />
          <PropertyField property={DisplayableTreeProperty.NDVI} value={tree?.ndvi} />
          <PropertyField
            property={DisplayableTreeProperty.CrownTransparency}
            value={tree?.crownTransparency ? t('details.properties.crownTransparencyTypes.' + tree?.crownTransparency).toString() : null}
          />
        </PropertyFieldGroup>
        <PropertyFieldGroup title={t('taskManager.currentState.foliage')}>
          <PropertyField property={DisplayableTreeProperty.LeafArea} value={tree?.leafArea} />
          <PropertyField property={DisplayableTreeProperty.LeafAreaIndex} value={tree?.leafAreaIndex} />
          <PropertyField property={DisplayableTreeProperty.CrownVolume} value={tree?.crownVolume} />
          <PropertyField property={DisplayableTreeProperty.LeafBiomass} value={tree?.leafBiomass} />
        </PropertyFieldGroup>
      </StyledExclusiveDetails>
      <StyledExclusiveDetails
        summary={t('taskManager.currentState.dimensions')}
        summaryActions={getDimensionsAction()}
      >
        <PropertyFieldGroup>
          <PropertyField
            property={DisplayableTreeProperty.Height}
            value={tree?.height}
            action={<RulerToggle
              property={DisplayableTreeProperty.Height}
              toggleProperty={toggleProperty}
              selected={rulers.includes(DisplayableTreeProperty.Height)}
            />}
          />
          <PropertyField
            property={DisplayableTreeProperty.TrunkHeight}
            value={tree?.trunkHeight}
            action={<RulerToggle
              property={DisplayableTreeProperty.TrunkHeight}
              toggleProperty={toggleProperty}
              selected={rulers.includes(DisplayableTreeProperty.TrunkHeight)}
            />}
          />
          <PropertyField
            property={DisplayableTreeProperty.TrunkDiameter}
            value={tree?.trunkDiameter}
            action={<RulerToggle
              property={DisplayableTreeProperty.TrunkDiameter}
              toggleProperty={toggleProperty}
              selected={rulers.includes(DisplayableTreeProperty.TrunkDiameter)}
            />}
          />
          <PropertyField
            property={DisplayableTreeProperty.CanopyHeight}
            value={tree?.canopyHeight}
            action={<RulerToggle
              property={DisplayableTreeProperty.CanopyHeight}
              toggleProperty={toggleProperty}
              selected={rulers.includes(DisplayableTreeProperty.CanopyHeight)}
            />}
          />
          <PropertyField
            property={DisplayableTreeProperty.CanopyWidth}
            value={tree?.canopyWidth}
            action={<RulerToggle
              property={DisplayableTreeProperty.CanopyWidth}
              toggleProperty={toggleProperty}
              selected={rulers.includes(DisplayableTreeProperty.CanopyWidth)}
            />}
          />
          <PropertyField
            property={DisplayableTreeProperty.CriticalRootZone}
            value={tree?.criticalRootZone}
            action={urlContext.getDetailsTreeDisplay() === TreeDisplays.STREET_VIEW ? <RulerToggle
              property={DisplayableTreeProperty.CriticalRootZone}
              toggleProperty={() => setCRZVisibility(!showCRZ)}
              selected={showCRZ}
            /> : null}
          />
          <PropertyField
            property={DisplayableTreeProperty.StructuralCriticalRootZone}
            value={tree?.structuralCriticalRootZone}
            action={urlContext.getDetailsTreeDisplay() === TreeDisplays.STREET_VIEW ? <RulerToggle
              property={DisplayableTreeProperty.StructuralCriticalRootZone}
              toggleProperty={() => setSCRZVisibility(!showSCRZ)}
              selected={showSCRZ}
            /> : null}
          />
          <PropertyField
            property={DisplayableTreeProperty.NumberOfStems}
            value={tree?.numberOfStems}
            action={<div className="size-6"/>}
          />
        </PropertyFieldGroup>
      </StyledExclusiveDetails>
      <StyledExclusiveDetails summary={t('taskManager.currentState.structuralAssessment')}>
        <PropertyFieldGroup title={t('taskManager.currentState.stability')}>
          <PropertyField property={DisplayableTreeProperty.Slenderness} value={tree?.slenderness} />
          <PropertyField property={DisplayableTreeProperty.SafetyFactorAt80Kmh} value={tree?.safetyFactorAt80Kmh} />
          <PropertyField property={'criticalWindSpeed'} value={tree?.criticalWindSpeed} />
          <PropertyField
            property={DisplayableTreeProperty.AbsoluteWeakestPoint}
            value={tree?.safetyFactors?.at(13)?.weakestPoint}
          />
        </PropertyFieldGroup>
        <PropertyFieldGroup title={t('taskManager.currentState.limbs')}>
          <div className="flex items-center gap-2 px-3 py-2">
            <div className="w-1/3 text-outer-space-200">{t('details.properties.numberOfLimbs')}</div>
            <div className="w-2/3 font-medium">
              {tree.limbs.length ?
                tree.limbs.map((limb, index) => (
                  <div className="flex items-end gap-1" key={`limb-${index + limb.diameter}`}>
                    <div>Limb #{index + 1}: {limb.diameter}</div>
                    <div className="text-[13px] text-outer-space-200 font-normal">
                      {Tree.getUnit(DisplayableTreeProperty.LimbDiameter, organization)}
                    </div>
                  </div>
                ))
                :
                <span className="text-outer-space-500">-</span>
              }
            </div>
          </div>
          <PropertyField property={DisplayableTreeProperty.CoDominantStems} value={tree?.coDominantStems} />
          <PropertyField property={DisplayableTreeProperty.IncludedBark} value={tree?.includedBark} />
          <PropertyField property={'fork'} value={tree?.fork} />
          <PropertyField property={'crossSectionalShape'} value={tree?.crossSectionalShape} />
        </PropertyFieldGroup>
      </StyledExclusiveDetails>
      <StyledExclusiveDetails summary={t('taskManager.currentState.conclusions')}>
        <PropertyField
          property={DisplayableTreeProperty.FurtherInspectionNeeded}
          value={tree.furtherInspectionNeeded ? new Date(tree.furtherInspectionNeeded).toLocaleDateString() : null}
        />
        <PropertyField
          property={DisplayableTreeProperty.ViStatus}
          value={t('virtualInspection.status.' + tree?.viStatus).toString()}
        />
      </StyledExclusiveDetails>
      <StyledExclusiveDetails summary={t('taskManager.currentState.riskCategorization')}>
        <div className="flex flex-col gap-8 py-3">
          {tree.potentialTargets && tree.potentialTargets.map((it, index) =>
            <div className="flex flex-col gap-1 divide-y divide-outer-space-600" key={it.id || index}>
              <div className="flex items-center gap-2">
                <div className="w-1/3 text-outer-space-200">Target number</div>
                <div className="w-2/3 font-medium py-3">
                  {index + 1}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-1/3 text-outer-space-200">Target description</div>
                <div className="w-2/3 font-medium py-3">
                  {it.targetDescription}
                </div>
              </div>
              <div className="flex items-center gap-2 pt-1">
                <div className="w-1/3 text-outer-space-200">Tree part</div>
                <input
                  name={'treePart'}
                  className={'w-2/3 bg-outer-space-950 rounded-[4px] px-2 py-2 text-outer-space-200'}
                  type="text"
                  value={it.treePart || ''}
                  onChange={e => potentialTargetsChange(index, 'treePart', e.target.value)}
                  onFocus={() => setFocusedElement(`${index}-treePart`)}
                  onBlur={() => setFocusedElement(null)}
                  autoFocus={focusedElement === `${index}-treePart`}
                />
              </div>
              <div className="flex items-center gap-2 pt-1">
                <div className="w-1/3 text-outer-space-200">Conditions of concern</div>
                <input
                  name={'conditionOfConcern'}
                  className={'w-2/3 bg-outer-space-950 rounded-[4px] px-2 py-2 text-outer-space-200'}
                  type="text"
                  value={it.conditionOfConcern || ''}
                  onChange={e => potentialTargetsChange(index, 'conditionOfConcern', e.target.value)}
                  onFocus={() => setFocusedElement(`${index}-conditionOfConcern`)}
                  onBlur={() => setFocusedElement(null)}
                  autoFocus={focusedElement === `${index}-conditionOfConcern`}
                />
              </div>
              <RiskCategorization
                failure={it.likelihoodOfFailure ? Number(LikelihoodOfFailure[it.likelihoodOfFailure]) : 0}
                setFailure={value => potentialTargetsChange(index, 'likelihoodOfFailure', LikelihoodOfFailure[value])}
                impact={it.likelihoodOfImpact ? Number(LikelihoodOfImpact[it.likelihoodOfImpact]) : 0}
                setImpact={value => potentialTargetsChange(index, 'likelihoodOfImpact', LikelihoodOfImpact[value])}
                consequences={it.consequences ? Number(ConsequencesOfFailure[it.consequences]) : 0}
                setConsequences={value => potentialTargetsChange(index, 'consequences', ConsequencesOfFailure[value])}
              />
            </div>)}
        </div>
      </StyledExclusiveDetails>
      <StyledExclusiveDetails summary={t('taskManager.currentState.notesAndMitigations')}>
        <PropertyFieldGroup>
          <div className="flex items-center gap-2 px-3 py-2">
            <div className="w-1/3 text-outer-space-200">
              {t('details.properties.overallTreeRiskRating')}
            </div>
            <div className="w-2/3 font-medium flex items-center gap-2">
              <div className={`size-3 rounded-full ${riskColorMap.get(tree?.overallTreeRiskRating || '')}`} />
              {tree?.overallTreeRiskRating ? tree?.overallTreeRiskRating : <span className="text-outer-space-500">-</span>}
            </div>
          </div>
          <div className="flex items-center gap-2 px-3 py-2">
            <div className="w-1/3 text-outer-space-200">
              {t('details.properties.overallResidualRisk')}
            </div>
            <div className="w-2/3 font-medium flex items-center gap-2">
              <div className={`size-3 rounded-full ${riskColorMap.get(tree?.overallResidualRisk || '')}`} />
              {tree?.overallResidualRisk ? tree?.overallResidualRisk : <span className="text-outer-space-500">-</span>}
            </div>
          </div>
          <div className="flex items-center gap-2 px-3 py-2">
            <div className="w-1/3 text-outer-space-200">
              {t('tree.dataStatus')}
            </div>
            <div className="w-2/3 font-medium">
              <div className="w-full">
                <Dropdown
                  value={{ id: tree?.dataStatus, translationKey: tree?.dataStatus }}
                  items={Object.values(DataStatus).map(status => ({ id: status, translationKey: status }))}
                  className="bg-outer-space-950 text-outer-space-200 rounded-[4px]"
                  fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                  menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                  onSelect={item => handleChange('dataStatus', item.id as string)}
                  disabled={disableEdit}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2 px-3 py-2">
            <div className="w-1/3 text-outer-space-200">
              {t('tree.recommendedInspectionInterval')}
            </div>
            <div className="w-2/3 font-medium">
              <div className="w-full">
                <input
                  name={'recommendedInspectionInterval'}
                  className={'w-[calc(100%-1rem)] bg-outer-space-950 rounded-[4px] px-2 py-2 text-outer-space-200'}
                  type="text"
                  defaultValue={tree?.recommendedInspectionInterval || ''}
                  onBlur={event => handleChange('recommendedInspectionInterval', event.target.value)}
                  disabled={disableEdit}
                />
              </div>
            </div>
          </div>
        </PropertyFieldGroup>
        <div>
          <div className="pl-3 py-1 uppercase text-outer-space-200 text-xs border-b border-b-outer-space-500">
            {t('taskManager.currentState.mitigationOptions')}
          </div>
          {(tree.mitigations || []).map((mitigation, index) => (
            <div className={`p-3 ${index === 0 ? '' : 'border-t border-t-outer-space-500'}`} key={`${mitigation.name} + ${index}`}>
              <div className="flex justify-between items-center text-outer-space-200">
                <div>
                  {t('taskManager.currentState.name')}
                </div>
                <div>
                  <Trash
                    className="text-danger-500 size-4 fill-none cursor-pointer p-2 rounded hover:bg-greehill-00/10"
                    onClick={() => {
                      handleChange('mitigations', tree?.mitigations.filter((_, idx) => idx !== index));
                      handleChange('observations', tree?.observations
                        .map(observation => ({ ...observation, mitigations: observation.mitigations.filter(mit => mit.taskTemplateId !== mitigation.taskTemplateId) })));
                    }}
                  />
                </div>
              </div>
              <Dropdown
                value={mitigationOptions.find(it => it.id === mitigation.taskTemplateId)}
                items={remainingMitigationOptions}
                className="w-full bg-outer-space-950 text-outer-space-200 rounded-[4px]"
                fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                disabled={!!mitigation.taskTemplateId}
                onSelect={item => {
                  handleChange('mitigations', tree?.mitigations.map((it, i) => index === i ? {
                    ...it,
                    name: item.translationKey,
                    taskTemplateId: item.id,
                    taskId
                  } : it));
                }}
              />
              <div className="mt-4 mb-2 text-outer-space-200">{t('taskManager.currentState.residualRisk')}</div>
              <Dropdown
                value={{ id: mitigation?.residualRisk, translationKey: mitigation?.residualRisk }}
                items={Object.values(RiskRating).map(rating => ({ id: rating, translationKey: rating }))}
                className="w-full bg-outer-space-950 text-outer-space-200 rounded-[4px]"
                fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                valueRenderer={item => (<div className="flex items-center gap-2"><span
                  className={`size-3 inline-block rounded-full ${riskColorMap.get(item.id || '')}`} /><span>{item.translationKey}</span>
                </div>)}
                onSelect={item => {
                  handleChange('mitigations', tree?.mitigations.map((it, i) => index === i ? {
                    ...it,
                    residualRisk: item.id
                  } : it));
                }}
              />
            </div>
          ))}
          <div>
            <button
              className="flex gap-2 items-center m-auto rounded-full border border-outer-space-400 py-1 px-3 text-sm mt-2"
              disabled={disableEdit || tree.mitigations?.length === 3}
              onClick={() => {
                handleChange('mitigations', [...tree.mitigations || [], { name: '', residualRisk: RiskRating.LOW }]);
              }}>
              <PlusCircle /> {t('taskManager.add')}
            </button>
          </div>
        </div>
        <div className="my-8">
          <div className="pl-3 py-1 uppercase text-outer-space-200 text-xs border-b border-b-outer-space-500">
            {t('taskManager.currentState.advancedAssessment')}
          </div>
          <div className="flex flex-col rounded-xl overflow-hidden divide-y divide-outer-space-500">
            <div className="px-3 py-3 text-outer-space-200">
              <Toggle
                checked={!!tree?.advancedAssessmentNeeded}
                onChange={() => handleChange('advancedAssessmentNeeded', !tree.advancedAssessmentNeeded)}
                disabled={disableEdit}
              >
                {t('taskManager.currentState.needed')}
              </Toggle>
            </div>
            <div className="px-3 py-2 flex flex-col gap-2 text-outer-space-200">
              <div>{t('taskManager.currentState.typeOrReason')}</div>
              <textarea
                className="bg-outer-space-950 rounded-[4px] px-2 py-2 text-outer-space-200"
                defaultValue={tree?.advancedAssessmentReason}
                placeholder={t('treeDetails.dataCollection.notePlaceholder')}
                onBlur={event => handleChange('advancedAssessmentReason', event.target.value)}
                disabled={!tree?.advancedAssessmentNeeded || disableEdit}
              />
            </div>
          </div>
        </div>
        <div className="my-8">
          <div className="pl-3 py-1 uppercase text-outer-space-200 text-xs border-b border-b-outer-space-500">
            {t('taskManager.currentState.inspectionLimitation')}
          </div>
          <div className="flex flex-col gap-1 divide-y divide-outer-space-500">
            <div className="w-full px-3 py-2">
              <Dropdown
                value={{ id: tree?.inspectionLimitation, translationKey: tree?.inspectionLimitation }}
                items={
                  Object.values(InspectionLimitation)
                    .map(status => ({
                      id: status,
                      translationKey: status === InspectionLimitation.ROOT_COLLAR_BURIED ? 'Root collar buried' : status
                    }))
                }
                className="w-[calc(100%-1.5rem)] bg-outer-space-950 text-outer-space-200 rounded-[4px]"
                fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                onSelect={item => handleChange('inspectionLimitation', item.id as string)}
                disabled={disableEdit}
              />
            </div>
            <div className="px-3 py-2 flex flex-col gap-2 text-outer-space-200">
              <div>{t('taskManager.currentState.description')}</div>
              <textarea
                className="bg-outer-space-950 rounded-[4px] px-2 py-2 text-outer-space-200"
                defaultValue={tree?.inspectionLimitationDescription}
                placeholder={t('treeDetails.dataCollection.notePlaceholder')}
                onBlur={event => handleChange('inspectionLimitationDescription', event.target.value)}
                disabled={tree?.inspectionLimitation === InspectionLimitation.NONE || disableEdit}
              />
            </div>
          </div>
        </div>
        <div className="my-8 text-outer-space-200">
          <div className="pl-3 py-1 uppercase text-xs border-b border-b-outer-space-500">
            {t('taskManager.currentState.notesTitle')}
          </div>
          <div className="flex flex-col gap-1 overflow-hidden px-3 py-2">
            <textarea
              className="bg-outer-space-950 rounded-[4px] px-2 py-2 text-outer-space-200"
              defaultValue={tree?.note}
              placeholder={t('treeDetails.dataCollection.notePlaceholder')}
              onBlur={event => handleChange('note', event.target.value)}
              disabled={disableEdit}
            />
          </div>
        </div>
      </StyledExclusiveDetails>
    </DetailsGroup>
  );
}

interface ValuationProps {
  title: string,
  scoreText: string,
  children: ReactNode | ReactNode[]
}

function Valuation(props: ValuationProps) {
  return (
    <DetailsGroup
      className="[&>li:last-of-type]:rounded-b-lg [&>*]:bg-outer-space-600 [&>*]:py-3 [&>*]:px-4 flex flex-col gap-0.5 w-2/3 mx-auto mb-6">
      <li className="rounded-t-lg text-outer-space-200 flex gap-2">
        {props.title}
        <span className="text-greehill-00">{props.scoreText}</span>
      </li>
      {props.children}
    </DetailsGroup>
  );
}

interface PropertyFieldProps {
  property: DisplayableTreeProperty | keyof DetailedTree,
  value: number | string | boolean | null | undefined,
  action?: ReactNode | ReactNode[]
}

function PropertyField(props: PropertyFieldProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  let value;
  if (typeof props.value === 'boolean') {
    value = t(`details.properties.booleanLabels.${props.value}`);
  } else if (props.property === DisplayableTreeProperty.Dieback && typeof props.value === 'number') {
    value = props.value.toFixed(2);
  } else if (typeof props.value === 'number') {
    value = props.value.toLocaleString('en-US', { maximumFractionDigits: 0 });
  } else {
    value = props.value;
  }
  const unit = Tree.getUnit(props.property, organization);

  return (
    <div className={`flex items-center gap-2 ${props.action ? '' : 'px-3'} py-2`}>
      <div className="w-1/3 flex gap-2 items-center text-outer-space-200">
        {props.action}
        {t('tree.' + props.property, { defaultValue: t('details.properties.' + props.property) })}
      </div>
      <div className="w-2/3 font-medium">
        {value ?
          <div className="flex gap-1 items-baseline">
            {value}
            {unit && <span className="text-[13px] text-outer-space-200 font-normal">{t(`units.${unit}`)}</span>}
          </div>
          :
          <span className="text-outer-space-500">-</span>}
      </div>
    </div>
  );
}

function PropertyFieldGroup({ children, title }: { children: ReactNode | ReactNode[], title?: string }) {
  return (
    <div>
      {title && (
        <h3 className="uppercase font-bold mt-8 text-outer-space-300">
          {title}
        </h3>
      )}
      <div className="flex flex-col mb-4 mt-2 divide-y divide-outer-space-500">
        {children}
      </div>
    </div>
  );
}

interface RulerToggleProps {
  property: ExtraObject,
  toggleProperty: (property: ExtraObject) => void,
  selected: boolean
}

function RulerToggle(props: RulerToggleProps) {
  const { urlContext } = useContext(DependencyInjectionContext);

  if (urlContext.getDetailsTreeDisplay() === TreeDisplays.IMAGES) return null;

  return (
    <div onClick={() => props.toggleProperty(props.property)} className="cursor-pointer">
      {props.selected ? <EyeSolid /> : <Eye />}
    </div>
  );
}
