import styles from '../TableViewer.module.scss';
import { AdvancedFilterPredicateTypeSelector } from './AdvancedFilterPredicateTypeSelector';
import AdvancedFilterValueSelector from './AdvancedFilterValueSelector';
import { DisplayableTreeProperty } from '../../../../tree/Tree';
import AdvancedFilterEnumSelector from './AdvancedFilterEnumSelector';
import {
  AdvancedFilterByProperty,
  AdvancedFilterPredicateType
} from '../advanced-filter/AdvancedFilterConfigurationBuilder';
import { useMemo, useState } from 'react';
import { AdvancedFilterType } from '../advanced-filter/AdvancedFilter';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import { useTranslation } from 'react-i18next';
import AdvancedFilterRangeSelector from './AdvancedFilterRangeSelector';
import AdvancedFilterCohortSelector from './AdvancedFilterCohortSelector';

export const LegacyAdvancedFilterModal = (props: AdvancedFilterModalProps) => {
  const { t } = useTranslation();
  const initiatePredicateType = () => {
    if (props.currentAdvancedFilter !== null) {
      return props.currentAdvancedFilter.getType();
    }

    if (props.advancedProperty === 'species' || props.advancedProperty === 'managedAreaId') return AdvancedFilterType.INCLUDE_ONLY;
    return AdvancedFilterType.MIN;
  };

  const [predicateType, setPredicateType] = useState<AdvancedFilterType>(initiatePredicateType);

  const enumOptionsList = useMemo<string[]>(() => {
    if (props.advancedProperty === 'managedAreaId') {
      return props.managedAreas.map(it => it.code);
    }

    if (props.advancedProperty === 'species') {
      return props.species;
    }
    if (props.advancedProperty === DisplayableTreeProperty.Status) {
      return props.statuses;
    }
    if (props.advancedProperty === DisplayableTreeProperty.VitalityVigor) {
      return props.vitalityVigorOptions;
    }
    return [];
  }, [props.advancedProperty, props.managedAreas, props.species, props.statuses, props.vitalityVigorOptions]);

  const includeOnly = useMemo(() => {
    const currentIncludeOnlys = props.currentAdvancedFilter?.includeOnly || [];
    if (props.advancedProperty === 'managedAreaId') {
      return currentIncludeOnlys.map(area => props.managedAreas.find(it => it.id === area)?.code || '');
    }
    return currentIncludeOnlys;
  }, [props.advancedProperty, props.currentAdvancedFilter, props.managedAreas]);

  if (props.advancedProperty === null) return <></>;

  let filter;

  if (AdvancedFilterByProperty.getPredicateType(predicateType) === AdvancedFilterPredicateType.NUMERIC) {
    filter = <div className={styles.valueSelector}>
      <AdvancedFilterPredicateTypeSelector
        predicateType={predicateType}
        onChange={setPredicateType} />
      <AdvancedFilterValueSelector
        min={props.currentAdvancedFilter?.min || null}
        max={props.currentAdvancedFilter?.max || null}
        onChange={(min, max) => {
          props.onNumericFilterChange(props.advancedProperty, min, max);
        }}
        predicateType={predicateType}
        propertyConfig={props.currentPropertyConfig!} />
    </div>;
  } else if (AdvancedFilterByProperty.getPredicateType(predicateType) === AdvancedFilterPredicateType.ENUM) {
    filter = <div>
      <div className={styles.enumSelector}>
        <AdvancedFilterEnumSelector
          property={props.advancedProperty as DisplayableTreeProperty}
          options={enumOptionsList}
          selection={includeOnly}
          onChange={values => {
            props.onEnumFilterChange(props.advancedProperty, values);
          }} />
      </div>
    </div>;
  } else if (AdvancedFilterByProperty.getPredicateType(predicateType) === AdvancedFilterPredicateType.COHORT) {
    filter = <div>
      <div>
        <AdvancedFilterPredicateTypeSelector
          predicateType={predicateType}
          onChange={setPredicateType} />
        <AdvancedFilterCohortSelector
          onChange={sigmaBoundaries => props.onCohortFilterChange(props.advancedProperty, sigmaBoundaries)}
          sigmaBoundaries={props.currentAdvancedFilter?.sigmaBoundaries || []}
        />
      </div>
    </div>;
  } else {
    filter = <div className={styles.rangeSelector}>
      <AdvancedFilterPredicateTypeSelector
        predicateType={predicateType}
        onChange={setPredicateType} />
      <AdvancedFilterRangeSelector
        onChange={rangeIndices => props.onRangeFilterChange(props.advancedProperty, rangeIndices)}
        propertyConfiguration={props.currentPropertyConfig}
        rangeIndices={props.currentAdvancedFilter?.ranges || []} />
    </div>;
  }

  return (
    <div className={styles.multiSelectContainer}>
      <div className={styles.greenTitle}>{t('treeList.advancedFiltering.filterBy')}</div>
      {filter}
    </div>
  );
};

interface AdvancedFilterModalProps {
  advancedProperty: DisplayableTreeProperty | 'species' | 'managedAreaId' | 'externalId',
  currentAdvancedFilter: AdvancedFilterByProperty | null,
  currentPropertyConfig: PropertyConfiguration | null,
  onNumericFilterChange: (property, min, max) => unknown,
  onEnumFilterChange: (property, values) => unknown,
  onRangeFilterChange: (property, rangeIndices) => unknown,
  onCohortFilterChange: (property, sigmaBoundaries) => unknown,
  species: string[],
  managedAreas: { id: string, code: string }[],
  statuses: string[],
  vitalityVigorOptions: string[]
}
