import styles from './BallGridLoader.module.scss';
import { useTranslation } from 'react-i18next';

export default function BallGridLoader({ size = 1 }) {
  const { t } = useTranslation();
  const ballSize = 16 * size;
  const gap = 2 * size;

  return (
    <div className={styles.container}>
      <div className={styles.ballGridPulse} style={{ width: 3 * ballSize + 6 * gap }}>
        {new Array(9).fill(1).map((_, idx) => (
          <div
            className={styles.ball}
            style={{
              background: 'white',
              width: ballSize,
              height: ballSize,
              margin: gap,
              animationDelay: `${(Math.random() || 0.1) - 0.2}s`,
              animationDuration: `${(Math.random() || 0.1) + 0.8}s`
            }}
            key={idx}
          />
        ))}
      </div>
      <div className={styles.loadingText}>{t('map.loading')}</div>
    </div>
  );
}
