import { DisplayableTreeProperty } from '../../../tree/Tree';
import styles from './LegacyTableViewer.module.scss';
import { TFunction, useTranslation } from 'react-i18next';
import { ManagedArea } from '../../../managed-area/ManagedArea';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDown, ArrowUp, Filter } from 'iconoir-react';
import { DetailsBackUrl } from '../../../utils/DetailsBackUrl';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import TableRow from './components/TableRow';
import { TreeFilter } from '../../../tree-filter/TreeFilter';
import { JumperNavigation } from './JumperNavigation';
import { usePropertyConfigurations } from '../../../properties/usePropertyConfigurations';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import TableHeader from './components/TableHeader';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useFetchTreeList } from '../useFetchTreeList';
import { PanelLayout } from '../PanelLayout';
import { useTracking } from '../../../analytics/useTracking';
import { FilteredPartialTree } from '../../../tree/FilteredPartialTree';
import LegacyTableDashboard from './components/LegacyTableDashboard';
import { TreeStatus } from '../../../property-enums/TreeStatus';
import { VitalityVigor } from '../../../property-enums/VitalityVigor';

const MIN_DRAG_DISTANCE = 10;
const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 60;

export default function LegacyTableViewer(props: TableViewerProps) {
  const { t } = useTranslation();
  const { urlContext, treeService } = useContext(DependencyInjectionContext);
  const match = useMatch('/organizations/:organizationId/*');
  const propertyConfigs = usePropertyConfigurations();
  const location = useLocation();
  const navigate = useNavigate();
  const account = useCurrentAccount();
  const { track, events } = useTracking();
  const dragStartPos = useRef<number | null>(null);
  const treeListTableRef = useRef<HTMLDivElement | null>(null);
  const tableBodyContainerRef = useRef<HTMLDivElement | null>(null);
  const fullSizedContainerRef = useRef<HTMLDivElement | null>(null);

  const [hidePlaceholderColumn, setHidePlaceholderColumn] = useState(false);
  const [visibleTrees, setVisibleTrees] = useState<{ tree: FilteredPartialTree, pinned: boolean }[]>([]);
  const [currentView, setCurrentView] = useState({ from: 0, to: 50 });
  const [sort, setSort] = useState<string | null>(null);
  const [dragDistance, setDragDistance] = useState(0);
  const [treesLastLoadedAt, setTreesLastLoadedAt] = useState<number>(0);
  const { treeList } = useFetchTreeList(treesLastLoadedAt, sort, props.filters, props.properties);
  const [tableAnimations, setTableAnimations] = useState<{ treeId: string, promise: Promise<void> }[]>([]);
  const [columnSettings, setColumnSettings] = useState(
    new Map<Property, ColumnSettings>(props.properties.map((property): [Property, ColumnSettings] => [
      property,
      { sort: null, aggregateFunction: getAggregationOptions(t, getColumnConfiguration(property))[0]?.value ?? null }
    ]))
  );

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();
  const organizationId = match?.params?.organizationId || '';
  const header = treeList?.getHeader() || [];
  const total = treeList?.getTotal() ?? 0;
  const pinnedTreesCount = treeList?.getPinnedTreesCount() || 0;
  const properties: Property[] = ['externalId', 'species', 'managedAreaId', ...props.properties];

  const advancedFilterCountWithIcon = useMemo(() => {
    if (treeList && treeList.getFilteredCount() > 0) {
      return <><span
        className={styles.highlighted}><Filter />{treeList!.getTotal() - treeList!.getFilteredCount()}</span> </>;
    }
    return null;
  }, [treeList]);

  const dashboardDescription = useMemo(() => {
    const prefix = treeList && treeList.getFilteredCount() > 0 ? 'of ' : '';
    if (props.managedAreas.length === 0 && props.filters.length === 1) {
      return t('treeList.titleForSingleFilter', { total: prefix + total, filterName: props.filters[0].getName() });
    }
    if (props.managedAreas.length === 0 && props.filters.length > 1) {
      return t('treeList.titleForMultipleFilters', { total: prefix + total, filterCount: props.filters.length });
    }
    if (props.managedAreas.length === 1 && props.filters) {
      return t('treeList.titleForSingleManagedArea', { total: prefix + total, managedAreaName: props.managedAreas[0].code });
    }
    return t('treeList.titleForMultipleManagedAreas', {
      total: prefix + total,
      managedAreaCount: props.managedAreas.length
    });
  }, [t, props.managedAreas, JSON.stringify(props.filters), total, treeList]);

  const jumperNavigation = useRef(new JumperNavigation(pinnedTreesCount));
  useEffect(() => {
    jumperNavigation.current = new JumperNavigation(pinnedTreesCount);
  }, [pinnedTreesCount]);

  useEffect(() => {
    if (
      !treeListTableRef.current || !tableBodyContainerRef.current || !fullSizedContainerRef.current || total === 0
    ) return;

    const scrollContainer = treeListTableRef.current!;

    fullSizedContainerRef.current.style.height = (total! * ROW_HEIGHT).toString() + 'px';
    const maxVisibleRows = Math.floor((scrollContainer.offsetHeight - HEADER_HEIGHT) / ROW_HEIGHT);
    setCurrentView({ from: 0, to: maxVisibleRows });
    jumperNavigation.current.setDisplayedRange(0, maxVisibleRows);

    scrollContainer.scrollTo({ top: 0 });

    scrollContainer.addEventListener('scroll', () => {
      const from = Math.floor((scrollContainer.scrollTop) / ROW_HEIGHT);
      const delta = Math.floor((scrollContainer.offsetHeight - HEADER_HEIGHT) / ROW_HEIGHT);
      const to = from + delta;
      jumperNavigation.current.setDisplayedRange(from, to);
      setCurrentView({ from, to });
    });
  }, [treeListTableRef, total, props.open]);

  useEffect(() => {
    const from = Math.max(currentView.from - 10, 0);
    const to = currentView.to + 10;
    const delta = from - pinnedTreesCount;
    Promise.all(tableAnimations.map(it => it.promise)).then(() => {
      if (!treeList || !tableBodyContainerRef.current || !treeListTableRef.current) return;
      treeList.getRange(from, to).then(it => {
        setVisibleTrees(it.map((it, index) =>
          ({ tree: it, pinned: delta < 0 ? index < Math.abs(delta) : false })));
      });
      const offset = tableAnimations.length;
      if (treeListTableRef.current?.scrollTop > pinnedTreesCount * ROW_HEIGHT) {
        treeListTableRef.current.scrollTop = (treeListTableRef.current?.scrollTop) + offset * 40;
      }
      tableBodyContainerRef.current.style.top = (from * ROW_HEIGHT) + 'px';
      setTableAnimations([]);
    });
  }, [treeList, currentView]);

  useEffect(() => {
    (async () => {
      if (!treeList) return;
      if (props.selectedTreeId && props.open) {
        const index = await treeList.findIndexById(props.selectedTreeId);
        const numberOfVisibleRows = (treeListTableRef.current!.offsetHeight - HEADER_HEIGHT) / ROW_HEIGHT;
        if (tableAnimations.length === 0) {
          treeListTableRef.current!.scrollTop = Math.max(index - (numberOfVisibleRows - 0.5) / 2, 0) * ROW_HEIGHT;
        }
      }
    })();
  }, [treeList, props.selectedTreeId, props.open]);

  const useEffectDependencyForColumnSettingInitialization = props.properties.join('');
  useEffect(() => {
    setColumnSettings(
      columnSettings =>
        new Map<Property, ColumnSettings>([
          ['externalId', { sort: null, aggregateFunction: null }],
          ...props.properties.map((property): [Property, ColumnSettings] => [
            property,
            columnSettings.get(property) ?? {
              sort: null,
              aggregateFunction: getAggregationOptions(t, getColumnConfiguration(property))[0]?.value ?? null
            }
          ])
        ])
    );
  }, [useEffectDependencyForColumnSettingInitialization]);

  useEffect(() => {
    if (!treeListTableRef.current) return;
    setHidePlaceholderColumn(treeListTableRef.current.scrollWidth > window.innerWidth - 50);
  }, [useEffectDependencyForColumnSettingInitialization]);

  const onToggle = useCallback(() => {
    if (urlContext.getSidebarOpen() && !urlContext.getTreeTableOpen()) {
      urlContext.setSidebarOpen(false);
    }

    const wasOpened = urlContext.getTreeTableOpen();
    urlContext.setTreeTableOpen(!wasOpened);
    props.panelLayoutRef.toggleTable();
    track(events.TABLE_TOGGLE_CHANGE, { opened: !wasOpened });
  }, []);

  const onTableDragStart = downEvent => {
    const nativeDownEvent = (downEvent.nativeEvent as MouseEvent);
    dragStartPos.current = nativeDownEvent.screenY;
    const mouseMoveListener = e => onMouseMove(e);
    const mouseUpListener = e => {
      const ev = e as MouseEvent;
      if (Math.abs(ev.screenY - (dragStartPos.current || ev.screenY)) < MIN_DRAG_DISTANCE) {
        onToggle();
      }

      props.setTableResizeTrigger(Math.random());
      window.removeEventListener('mousemove', mouseMoveListener);
      window.removeEventListener('mouseup', mouseUpListener);
    };

    window.addEventListener('mousemove', mouseMoveListener);
    window.addEventListener('mouseup', mouseUpListener);
  };

  const onMouseMove = useCallback((e: Event) => {
    const onTableDrag = height => {
      props.panelLayoutRef.setTableDragDistance(height);
      props.setTableDragDistance(height);
    };

    const evt = e as MouseEvent;
    const distance = window.innerHeight - evt.clientY;
    if (distance >= window.innerHeight * 0.7 && distance > dragDistance) {
      onTableDrag(window.innerHeight - 87);
      setDragDistance(distance);
      return;
    }

    if (distance < window.innerHeight - 87) {
      onTableDrag(distance);
    }

    setDragDistance(distance);
  }, [setDragDistance, dragDistance, props.panelLayoutRef]);

  const navigateToDetails = (treeId: string) => {
    DetailsBackUrl.store(treeId, location);
    let url = `/organizations/${organizationId}/trees/${treeId}/`;
    url += account.organization.isDemo ? 'analytics/general' : 'details';
    navigate(url);
  };

  const deselectPinned = async (treeId, animationPromise) => {
    setTableAnimations(prevState => ([...prevState, { treeId, promise: animationPromise }]));
    await unpinTree(treeId);
    track(events.TABLE_UNPIN_TREE, { treeId });
  };

  const unpinTree = async treeId => {
    const deleted = await treeService.deletePin(organizationId, treeId);
    if (!deleted) return;
    triggerTreeFetching();
  };

  const selectPinned = async (treeId, animationPromise) => {
    setTableAnimations(prevState => ([...prevState, { treeId, promise: animationPromise }]));
    await pinTree(treeId);
    track(events.TABLE_PIN_TREE, { treeId });
  };

  const pinTree = async treeId => {
    const created = await treeService.pinTree(organizationId, treeId);
    if (!created) return;
    triggerTreeFetching();
  };

  function triggerTreeFetching() {
    setTreesLastLoadedAt(Date.now());
  }

  const scrollToTableTop = () => treeListTableRef.current?.scrollTo({ top: 0, behavior: 'auto' });
  const scrollToNotPinnedTop = () => treeListTableRef.current?.scrollTo({
    top: pinnedTreesCount * ROW_HEIGHT,
    behavior: 'auto'
  });

  const handleJump = () => {
    if (jumperNavigation.current.shouldScrollUpToTableTop()) scrollToTableTop();
    if (jumperNavigation.current.shouldScrollDownToNotPinnedTop()) scrollToNotPinnedTop();
  };

  if (propertyConfigs.isLoading) return <></>;
  return (
    <div className={styles.container}>
      <div className={styles.toggleContainer}>
        <button
          type="button"
          className={`${styles.toggle} ${props.open ? styles.open : ''}`}
          onMouseDown={onTableDragStart}
          data-testid={'table-toggle-button'}
        >
          •••
        </button>

        <div className={styles.toggleMetadata} hidden={props.open}>
          {advancedFilterCountWithIcon}
          {dashboardDescription}
        </div>
      </div>

      {props.open && (
        <div className={styles.treeListTableContainer}>
          <LegacyTableDashboard
            properties={properties}
            displayableTreeProperties={props.properties}
            treeList={treeList!}
            windSpeed={props.windSpeed || account.getDefaultWindSpeed()}
            metaDataDescription={dashboardDescription}
            advancedFilterCountWithIcon={advancedFilterCountWithIcon}
            propertyConfigs={propertyConfigs.data!} />

          <div
            className={`${styles.treeListTable} ${hidePlaceholderColumn ? styles.fullWidth : ''}`}
            ref={treeListTableRef}>
            <TableHeader
              columnSettings={columnSettings}
              properties={properties}
              onSortDirectionChange={setSort}
              hidePlaceholderColumn={hidePlaceholderColumn}
              pinnedTreesCount={pinnedTreesCount}
              scrollToTableTop={scrollToTableTop}
              setColumnSettings={setColumnSettings}
              managedAreas={treeList?.getManagedAreas() || []}
              species={treeList?.getSpecies() || []}
              propertyConfigs={propertyConfigs.data!}
              header={header}
              windSpeed={props.windSpeed}
              statuses={Object.values(TreeStatus)}
              vitalityVigorOptions={Object.values(VitalityVigor)}
            />
            <div ref={fullSizedContainerRef}>
              <div ref={tableBodyContainerRef} style={{ position: 'relative', width: '100%' }}>
                <div className={styles.body}>
                  {visibleTrees.map((it, index) => {
                    const treeId = it.tree.id;
                    return <TableRow
                      tree={it.tree}
                      hidePlaceholderColumn={hidePlaceholderColumn}
                      selectedTreeId={props.selectedTreeId}
                      index={index}
                      key={`tree-${index}${it.pinned ? it.tree.externalId + '-pinned' : ''}`}
                      pinned={it.pinned}
                      properties={properties}
                      onSelect={() => props.onSelect(treeId === props.selectedTreeId ? '' : treeId)}
                      windSpeed={props.windSpeed}
                      onPin={promise => it.pinned ? deselectPinned(treeId, promise) : selectPinned(treeId, promise)}
                      navigateToDetails={() => navigateToDetails(treeId)}
                      selectedTreeProperty={selectedTreeProperty}
                      propertyConfigs={propertyConfigs.data!}
                    />;
                  })}
                </div>
              </div>
            </div>
          </div>
          {jumperNavigation.current.isVisible() && <button className={styles.jumperButton} onClick={() => handleJump()}>
            {jumperNavigation.current.shouldScrollUpToTableTop() ? <ArrowUp /> : <ArrowDown />}</button>}
        </div>
      )}
    </div>
  );
}

function getAggregationOptions(t: TFunction, configuration: ColumnConfiguration) {
  return [
    { label: t('treeList.min'), value: 'min' as const },
    { label: t('treeList.max'), value: 'max' as const },
    { label: t('treeList.sum'), value: 'sum' as const },
    { label: t('treeList.avg'), value: 'avg' as const },
    { label: t('treeList.med'), value: 'med' as const }
  ].filter(it => configuration[it.value]);
}

export type Property = DisplayableTreeProperty | 'externalId' | 'species' | 'managedAreaId';

export interface ColumnConfiguration {
  min: boolean,
  max: boolean,
  avg: boolean,
  med: boolean,
  sort: boolean,
  sum: boolean
}

export const defaultColumnConfiguration: ColumnConfiguration = {
  min: false,
  max: false,
  avg: false,
  med: false,
  sort: false,
  sum: false
};

export const columnConfiguration = new Map<Property, ColumnConfiguration>([
  ['externalId', defaultColumnConfiguration],
  ['species', { min: false, max: false, avg: false, med: false, sort: true, sum: false }],
  ['managedAreaId', { min: false, max: false, avg: false, med: false, sort: true, sum: false }],
  [DisplayableTreeProperty.Height, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.TrunkHeight, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.CanopyHeight, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.CanopyWidth, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.TrunkCircumference, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.TrunkDiameter, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.CanopyCircumference, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.SafetyFactorAt80Kmh, { min: true, max: true, avg: true, med: true, sort: true, sum: true }],
  [DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed, { min: true, max: true, avg: true, med: true, sort: true, sum: true }],
  [DisplayableTreeProperty.SafetyFactors, { min: true, max: true, avg: true, med: true, sort: true, sum: true }],
  [DisplayableTreeProperty.LeafArea, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.LeafBiomass, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.LeafAreaIndex, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.CarbonStorage, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.GrossCarbonSequestration, {
    min: true,
    max: true,
    avg: true,
    med: true,
    sort: true,
    sum: false
  }],
  [DisplayableTreeProperty.NO2, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.SO2, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.PM25, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.CO, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.O3, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.NDVI, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.TreeHealth, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.PotentialEvapotranspiration, {
    min: true,
    max: true,
    avg: true,
    med: true,
    sort: true,
    sum: false
  }],
  [DisplayableTreeProperty.Transpiration, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.OxygenProduction, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.ThermalComfort, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.TreeValueRado, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.TreeValueCavat, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.TreeValueKoch, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.LeaningAngle, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.WaterIntercepted, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.Evaporation, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.AvoidedRunoff, { min: true, max: true, avg: true, med: true, sort: true, sum: false }],
  [DisplayableTreeProperty.Dieback, { min: true, max: true, avg: true, med: true, sort: true, sum: false }]
]);

function getColumnConfiguration(property: Property) {
  return columnConfiguration.get(property) ?? defaultColumnConfiguration;
}

export interface ColumnSettings {
  sort: null | 'asc' | 'desc',
  aggregateFunction: null | 'min' | 'max' | 'avg' | 'med' | 'sum'
}

interface TableViewerProps {
  properties: DisplayableTreeProperty[],
  open: boolean,
  managedAreas: ManagedArea[],
  filters: TreeFilter[],
  onSelect: (treeId: string) => unknown,
  selectedTreeId: string | null,
  windSpeed: number,
  panelLayoutRef: PanelLayout,
  setTableResizeTrigger: Dispatch<SetStateAction<number>>,
  setTableDragDistance: (height: number) => void
}
