import styles from './MapSettingsButton.module.scss';
import { Settings } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import Toggle from '../../../components/UI/Toggle/Toggle';
import { useTracking } from '../../../analytics/useTracking';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { DisplayableTreeProperty } from '../../../tree/Tree';

export enum DisplayTreesOptions {
  markers= 'markers',
  canopy= 'canopy'
}

export default function MapSettingsButton(props: MapSettingsButtonProps) {
  const { t } = useTranslation();

  const { events, track } = useTracking();

  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const [displayTrees, setDisplayTrees] = useState<DisplayTreesOptions>(urlContext.getDisplayMode());
  const [displayISO, setDisplayISO] = useState(urlContext.isIsoMapEnabled());
  const [displayValues, setDisplayValues] = useState(urlContext.areTreeMarkersVisible());

  const displayTreeDropdownOptions = [
    { id: 'markers', translationKey: 'displayModes.markers' },
    { id: 'canopy', translationKey: 'displayModes.canopy' }
  ];

  const handleDisplayTreesChange = (item: DisplayTreesOptions) => {
    track(events.DISPLAY_MODES_DISPLAY_TREES_AS_CHANGE, { displayTreesAs: item });
    setDisplayTrees(item);
    urlContext.setDisplayMode(item);
  };

  const handleISOChange = () => {
    const enabled = !displayISO;
    track(events.DISPLAY_MODES_DISPLAY_ISO_MAP, { enabled });
    setDisplayISO(enabled);
    urlContext.setIsoMapEnabled(enabled);
  };

  const handleDisplayValueChange = () => {
    const enabled = !displayValues;
    track(events.DISPLAY_MODES_DISPLAY_VALUES, { enabled });
    setDisplayValues(enabled);
    urlContext.setDisplayValuesDisabled(!enabled);
  };

  const isMapAnalyticsEnabled = !!urlContext.getSelectedTreeProperty();

  const toggleMapAnalytics = () => isMapAnalyticsEnabled
    ? urlContext.setSelectedTreeProperty(null)
    : urlContext.setSelectedTreeProperty(DisplayableTreeProperty.TrunkDiameter);

  useEffect(() => {
    const onClick = () => {
      if (props.isOpen) props.toggleOpen();
    };

    window.addEventListener('mousedown', onClick);

    return () => {
      window.removeEventListener('mousedown', onClick);
    };
  }, [props.toggleOpen, props.isOpen]);

  const handleSelect = item => {
    if (props.treeMarkersEnabled && DisplayTreesOptions.canopy) props.onTreeMarkerSwitch();
    handleDisplayTreesChange(item.id as DisplayTreesOptions);
  };

  return (
    <div
      className={styles.mapSettingsContainer}
      onMouseDown={e => { e.stopPropagation(); }}
    >
      <button
        onClick={() => props.toggleOpen()}
        className={styles.settingsButton}
      >
        <Settings />
      </button>
      {props.isOpen &&
        <>
          <div className={styles.dropupContainer}>
            <div className={styles.greenTitle}>{t('mapSettings.title')}</div>
            <div className={styles.rowContainer}>
              <Toggle
                onChange={toggleMapAnalytics}
                checked={isMapAnalyticsEnabled}
                className={styles.viewOption}
              >
                {t('displayModes.mapAnalytics')}
              </Toggle>
              <div className={styles.displayTreeContainer}>
                <div className={styles.displayTreesAs}>{t('displayModes.displayTreesAs')}</div>
                <Dropdown
                  value={displayTreeDropdownOptions.find(it => it.id === displayTrees)}
                  items={displayTreeDropdownOptions.filter(it => it.id !== displayTrees)}
                  className={styles.dropdown}
                  fieldClassName={styles.dropdownField}
                  menuClassname={styles.dropdownMenu}
                  onSelect={handleSelect}/>
              </div>
              <Toggle
                onChange={handleISOChange}
                checked={displayISO}
                className={styles.viewOption}
              >
                {t('displayModes.displayIso')}
              </Toggle>
              <Toggle
                onChange={handleDisplayValueChange}
                checked={displayValues}
                className={styles.viewOption}
              >
                {t('displayModes.displayValues')}
              </Toggle>
              <Toggle
                onChange={() => props.onTreeMarkerSwitch()}
                checked={props.treeMarkersEnabled}
                className={styles.viewOption}
                disabled={urlContext.getDisplayMode() !== DisplayTreesOptions.markers}
              >
                {t('displayModes.hideMarkers')}
              </Toggle>
            </div>
          </div>
        </>
      }
    </div>
  );
}

interface MapSettingsButtonProps {
  isOpen: boolean,
  toggleOpen: () => void,
  onTreeMarkerSwitch: () => void,
  treeMarkersEnabled: boolean
}
