import styles from '../Inventory/Inventory.module.scss';

export default function EyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      className={styles.eyeIcon}
    >
      <path d="M1.19869 5.84053C1.22127 5.78934 1.77377 4.57856 3.0076 3.35925C4.15244 2.22919 6.11939 0.881248 9.0001 0.881248C11.8808 0.881248 13.8478 2.22919 14.9926 3.35925C16.2264 4.57856 16.7789 5.78737 16.8015 5.84053C16.8242 5.89093 16.8359 5.94549 16.8359 6.00065C16.8359 6.05582 16.8242 6.11038 16.8015 6.16078C16.7789 6.21131 16.2264 7.42209 14.9926 8.64141C13.8478 9.77147 11.8808 11.1187 9.0001 11.1187C6.11939 11.1187 4.15244 9.77147 3.0076 8.64141C1.77377 7.42209 1.22127 6.21328 1.19869 6.16078C1.17599 6.11038 1.16426 6.05582 1.16426 6.00065C1.16426 5.94549 1.17599 5.89093 1.19869 5.84053ZM9.0001 10.3312C11.0839 10.3312 12.9035 9.58181 14.4089 8.10459C15.04 7.48479 15.574 6.77546 15.9933 6C15.5741 5.22466 15.0401 4.51552 14.4089 3.89606C12.9035 2.41819 11.0839 1.66875 9.0001 1.66875C6.91627 1.66875 5.09674 2.41819 3.59131 3.89606C2.96013 4.51552 2.42609 5.22466 2.00686 6C2.42986 6.80128 4.55088 10.3312 9.0001 10.3312ZM9.0001 2.98125C9.60426 2.98125 10.1948 3.1583 10.6972 3.49C11.1995 3.8217 11.5911 4.29317 11.8223 4.84477C12.0535 5.39638 12.114 6.00335 11.9961 6.58893C11.8782 7.17451 11.5873 7.7124 11.1601 8.13458C10.7329 8.55676 10.1886 8.84426 9.59604 8.96074C9.00349 9.07722 8.38929 9.01744 7.83112 8.78896C7.27295 8.56048 6.79587 8.17356 6.46022 7.67713C6.12456 7.1807 5.94541 6.59705 5.94541 6C5.94646 5.1997 6.26863 4.43247 6.84127 3.86657C7.41391 3.30067 8.19027 2.98229 9.0001 2.98125ZM9.0001 8.23125C9.44665 8.23125 9.88317 8.10039 10.2545 7.85522C10.6258 7.61004 10.9152 7.26157 11.086 6.85386C11.2569 6.44615 11.3016 5.99752 11.2145 5.5647C11.1274 5.13188 10.9124 4.73431 10.5966 4.42227C10.2809 4.11022 9.87855 3.89771 9.44057 3.81162C9.0026 3.72553 8.54863 3.76971 8.13607 3.93859C7.72351 4.10747 7.37089 4.39346 7.12279 4.76038C6.8747 5.12731 6.74228 5.5587 6.74228 6C6.74228 6.59176 6.98016 7.15929 7.40358 7.57773C7.82701 7.99617 8.40129 8.23125 9.0001 8.23125Z" fill="#ADB1B3"/>
    </svg>
  );
}
