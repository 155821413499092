export class MeterPerPixelScale {
  static calculateFor(scale: number): MeterPerPixelScale | null {
    const values = [
      5, 10, 20, 50, 100, 200, 500, 1_000, 2_000, 5_000, 10_000, 20_000, 50_000, 100_000, 200_000, 500_000, 1_000_000
    ];

    const maxStep = Math.max(...values.map((it, i) => (values[i + 1] ?? it) / it));

    const minWidth = 90;
    const maxWidth = maxStep * minWidth;

    const possibilities = values
      .map(it => ({ value: it, width: it / scale }))
      .filter(({ width }) => minWidth <= width && width < maxWidth);

    if (!possibilities.length) return null;

    const width = possibilities[0].width;
    const [value, unit] = {
      5: [5, 'm'],
      10: [10, 'm'],
      20: [20, 'm'],
      50: [50, 'm'],
      100: [100, 'm'],
      200: [200, 'm'],
      500: [500, 'm'],
      1_000: [1, 'km'],
      2_000: [2, 'km'],
      5_000: [5, 'km'],
      10_000: [10, 'km'],
      20_000: [20, 'km'],
      50_000: [50, 'km'],
      100_000: [100, 'km'],
      200_000: [200, 'km'],
      500_000: [500, 'km'],
      1_000_000: [1000, 'km']
    }[possibilities[0].value]!;

    return new MeterPerPixelScale(width, value as number, unit as string);
  }

  constructor(readonly width: number, readonly value: number, readonly unit: string) {}
}
