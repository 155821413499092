import PropertyConfiguration from './PropertyConfiguration';

export default class PropertyColorConfiguration {
  static COLOR_GREEN = '0, 119, 82';
  static COLOR_MEDIUM_GREEN = '17, 160, 107';
  static COLOR_LIGHT_GREEN = '78, 186, 94';
  static COLOR_NEUTRAL_GREEN = '181, 186, 47';
  static COLOR_LIGHT_ORANGE = '236, 164, 17';
  static COLOR_ORANGE = '244, 118, 14';
  static COLOR_LIGHT_RED = '234, 69, 29';
  static COLOR_RED = '207, 0, 18';

  static SIZE_2 = [
    PropertyColorConfiguration.COLOR_LIGHT_RED,
    PropertyColorConfiguration.COLOR_MEDIUM_GREEN
  ];

  static SIZE_3 = [
    PropertyColorConfiguration.COLOR_LIGHT_RED,
    PropertyColorConfiguration.COLOR_NEUTRAL_GREEN,
    PropertyColorConfiguration.COLOR_MEDIUM_GREEN
  ];

  static SIZE_4 = [
    PropertyColorConfiguration.COLOR_LIGHT_RED,
    PropertyColorConfiguration.COLOR_LIGHT_ORANGE,
    PropertyColorConfiguration.COLOR_NEUTRAL_GREEN,
    PropertyColorConfiguration.COLOR_MEDIUM_GREEN
  ];

  static SIZE_5 = [
    PropertyColorConfiguration.COLOR_LIGHT_RED,
    PropertyColorConfiguration.COLOR_ORANGE,
    PropertyColorConfiguration.COLOR_LIGHT_ORANGE,
    PropertyColorConfiguration.COLOR_NEUTRAL_GREEN,
    PropertyColorConfiguration.COLOR_MEDIUM_GREEN
  ];

  static SIZE_6 = [
    PropertyColorConfiguration.COLOR_LIGHT_RED,
    PropertyColorConfiguration.COLOR_ORANGE,
    PropertyColorConfiguration.COLOR_LIGHT_ORANGE,
    PropertyColorConfiguration.COLOR_NEUTRAL_GREEN,
    PropertyColorConfiguration.COLOR_LIGHT_GREEN,
    PropertyColorConfiguration.COLOR_MEDIUM_GREEN
  ];

  static SIZE_7 = [
    PropertyColorConfiguration.COLOR_RED,
    PropertyColorConfiguration.COLOR_ORANGE,
    PropertyColorConfiguration.COLOR_LIGHT_ORANGE,
    PropertyColorConfiguration.COLOR_NEUTRAL_GREEN,
    PropertyColorConfiguration.COLOR_LIGHT_GREEN,
    PropertyColorConfiguration.COLOR_MEDIUM_GREEN,
    PropertyColorConfiguration.COLOR_GREEN
  ];

  static SIZE_8 = [
    PropertyColorConfiguration.COLOR_RED,
    PropertyColorConfiguration.COLOR_LIGHT_RED,
    PropertyColorConfiguration.COLOR_ORANGE,
    PropertyColorConfiguration.COLOR_LIGHT_ORANGE,
    PropertyColorConfiguration.COLOR_NEUTRAL_GREEN,
    PropertyColorConfiguration.COLOR_LIGHT_GREEN,
    PropertyColorConfiguration.COLOR_MEDIUM_GREEN,
    PropertyColorConfiguration.COLOR_GREEN
  ];

  static COLOR_CONFIGURATIONS = [
    PropertyColorConfiguration.SIZE_2,
    PropertyColorConfiguration.SIZE_3,
    PropertyColorConfiguration.SIZE_4,
    PropertyColorConfiguration.SIZE_5,
    PropertyColorConfiguration.SIZE_6,
    PropertyColorConfiguration.SIZE_7,
    PropertyColorConfiguration.SIZE_8
  ];

  static getColorsForConfig(config: PropertyConfiguration) {
    const colorsForSize = [...this.getColorsForSize(config.getSize())];
    return config.reversedOrder ? colorsForSize.reverse() : colorsForSize;
  }

  private static getColorsForSize(size: number) {
    return this.COLOR_CONFIGURATIONS[size - 2] || this.COLOR_CONFIGURATIONS[2];
  }
}
