import styles from '../ReportDialog.module.scss';
import { ReactNode } from 'react';

export default function ReportSection({ children, containerId, printable }: { children: ReactNode, containerId: string, printable?: boolean }) {
  return (
    <div className={`${styles.reportSection} ${printable ? styles.printable : ''}`} id={containerId}>
      {children}
    </div>
  );
}
