import { StreetViewProps } from './useStreetViewProps';
import { TileButton } from '../TileLayout';
import { Ruler } from 'iconoir-react';
import { ReactComponent as DigitalTwinToggle } from '../../../Explore/images/gh-icon-twin-tree.svg';

export default function StreetViewBottomCenterButtons(props: StreetViewProps) {
  return <>
    <TileButton
      onClick={() => props.setPointcloudVisibility(state => !state)}
      icon={<DigitalTwinToggle />}
      active={props.showPointcloud}
    />
    <TileButton
      onClick={() => props.setLineMeasurementEnabled(state => !state)}
      icon={<Ruler />}
      active={props.lineMeasurementEnabled}
    />
  </>;
}
