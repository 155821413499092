import { useTranslation } from 'react-i18next';
import styles from './OrganizationScreen.module.scss';
import { Fragment, useContext, useEffect, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { ManagedArea } from '../../../managed-area/ManagedArea';
import Dropdown from '../../UI/Dropdown/Dropdown';
import LegacyButton from '../../UI/Button/LegacyButton';
import LegacyCheckbox from '../../UI/Checkbox/LegacyCheckbox';
import { JobStatus } from './Job';
import { CheckCircle, Circle, Timer, WarningTriangle } from 'iconoir-react';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import { useQuery, useQueryClient } from 'react-query';
import { StaticQueryKeys } from '../../../StaticQueryKeys';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Flippers } from '../../../switches/Flippers';

interface ManagedAreaWithTranslationKey extends ManagedArea {
  translationKey: string
}

const ALL_AREAS_ID = 'all';
const SELECT_ALL_OPTION = { id: ALL_AREAS_ID, translationKey: 'sidebarFilter.allSelected' };

const getAreaSelectorValue = (isAllSelected, numberOfSelectedAreas, t) => {
  if (isAllSelected) {
    return t('settings.organization.allSelected');
  }
  if (numberOfSelectedAreas) {
    return t('sidebarFilter.selected', { count: numberOfSelectedAreas });
  }
  return t('settings.organization.managedAreaSelectorPlaceholder');
};

export default function DataMigration(props: DataMigrationInterface) {
  const { jobService } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const { managedAreaList, isLoadingManagedAreaList } = useManagedAreas(props.organizationId);
  const [loadBestImages, setLoadBestImages] = useState(true);
  const [loadAllScanIntervals, setLoadAllScanIntervals] = useState(false);
  const [managedAreas, setManagedAreas] = useState<ManagedAreaWithTranslationKey[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<{ [key: string]: boolean }>({});
  const isAllSelected = selectedAreas['all'];
  const numberOfSelectedAreas = Object.keys(selectedAreas).length;
  const areaSelectorValue = {
    id: '',
    translationKey: getAreaSelectorValue(isAllSelected, numberOfSelectedAreas, t)
  };
  const { data: jobs, isError: jobsLoadingError } = useQuery(StaticQueryKeys.TREE_LOADING_JOBS, () =>
    jobService.listJobsForOrganization(props.organizationId),
  { refetchInterval: 5000 });
  const queryClient = useQueryClient();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (isLoadingManagedAreaList) return;
    const managedAreasWithTranslationKeys = managedAreaList.map(area => ({
      ...area,
      translationKey: area.code
    } as ManagedAreaWithTranslationKey));

    managedAreasWithTranslationKeys.unshift(SELECT_ALL_OPTION as ManagedAreaWithTranslationKey);
    setManagedAreas(managedAreasWithTranslationKeys);
  }, [props.organizationId, isLoadingManagedAreaList, managedAreaList]);

  const handleMigration = async () => {
    delete selectedAreas[ALL_AREAS_ID];
    await jobService.sendJobsToQueue(props.organizationId, {
      managedAreas: Object.keys(selectedAreas),
      loadAllScanIntervals,
      loadBestImages,
      newTaxonomyIsEnabled: organization.isEnabled(Flippers.release24q3)
    });
    await queryClient.invalidateQueries(StaticQueryKeys.TREE_LOADING_JOBS);
    setSelectedAreas({});
  };

  const onSelectChange = areaId => {
    setSelectedAreas(prevState => {
      if (prevState[areaId as string]) {
        const copy = { ...prevState };
        delete copy[areaId as string];
        delete selectedAreas[ALL_AREAS_ID];
        return copy;
      }
      return {
        ...prevState,
        [areaId as string]: true
      };
    });
  };

  const onSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedAreas({});
    } else {
      const allAreas = {};
      managedAreas.forEach(area => allAreas[area.id] = true);
      setSelectedAreas(allAreas);
    }
  };

  useEffect(() => {
    const handlePasteEvent = event => {
      const clipBoardData = event.clipboardData?.getData('text/plain');
      const parsedMAList = clipBoardData.split(/[\n,;]/).filter(it => it !== '');
      const areaIDs = parsedMAList.map(code => managedAreas.find(it => it.code === code)?.id).filter(it => it !== undefined);
      const newValue = {};
      for (const key of areaIDs) {
        newValue[key] = true;
      }
      setSelectedAreas(newValue);
    };

    if (dropdownOpen) {
      document.addEventListener('paste', handlePasteEvent);
    }
    return () => {
      document.removeEventListener('paste', handlePasteEvent);
    };
  }, [managedAreas, dropdownOpen]);

  const getJobIcon = (status: JobStatus) => {
    if (status === JobStatus.TODO) return <Timer />;
    if (status === JobStatus.IN_PROGRESS) return <Circle />;
    if (status === JobStatus.FAILED) return <WarningTriangle color={'var(--text-error)'} />;
    if (status === JobStatus.FINISHED) return <CheckCircle color={'green'} />;
  };

  return (
    <>
      <h5>{t('settings.organization.loadTreesTitle')}</h5>
      <Dropdown
        value={areaSelectorValue}
        selected={selectedAreas}
        items={managedAreas}
        fieldClassName={styles.dropdown}
        className={styles.dropdownContainer}
        menuClassname={styles.dropdownMenu}
        onSelect={area => area.id === ALL_AREAS_ID ? onSelectAllChange() : onSelectChange(area.id)}
        isMultiSelect={true}
        setOpen={setDropdownOpen}
      />
      <div className={styles.checkbox}>
        <LegacyCheckbox
          checked={loadBestImages}
          onValueChange={() => setLoadBestImages(prevState => !prevState)}
        >
          <p className={styles.label}>{t('settings.organization.loadBestImages')}</p>
        </LegacyCheckbox>
      </div>
      <div className={styles.checkbox}>
        <LegacyCheckbox
          checked={loadAllScanIntervals}
          onValueChange={() => setLoadAllScanIntervals(prevState => !prevState)}
        >
          <p className={styles.label}>{t('settings.organization.loadAllScanIntervals')}</p>
        </LegacyCheckbox>
      </div>
      <LegacyButton
        className={`${styles.scriptButton} ${styles.migration}`}
        onClick={handleMigration}
        disabled={!numberOfSelectedAreas || props.disabled}
        variant="primary"
      >
        {t('settings.organization.launchMigration')}
      </LegacyButton>

      <h4>Managed area loading jobs in the last 24 hours:</h4>
      <table className={styles.jobsTable}>
        <thead>
          <tr>
            <th></th>
            <th>MA</th>
            <th>Status</th>
            <th>Triggered at</th>
          </tr>
        </thead>
        <tbody>
          {!jobsLoadingError && jobs && jobs.map(job => {
            return (
              <Fragment key={job.id}>
                <tr>
                  <td>{getJobIcon(job.status)}</td>
                  <td>{managedAreas.find(ma => ma.id === job.payload.managedAreaId)?.code}</td>
                  <td>{job.status}</td>
                  <td>{new Date(job.createdAt).toLocaleString()}</td>
                </tr>
                {(job.status === JobStatus.FAILED) &&
                  <tr>
                    <td></td>
                    <td colSpan={3} className={styles.error}>{job.errorMessage}</td>
                  </tr>}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

interface DataMigrationInterface {
  organizationId: string,
  disabled: boolean
}
