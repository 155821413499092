import { NavArrowDown, NavArrowRight } from 'iconoir-react';
import styles from './Accordion.module.scss';
import { ReactNode, useState } from 'react';

export default function Accordion(props: AccordionProps) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={styles.accordionWrapper}>
      <section className={`${isOpen ? styles.open : ''}`}>
        <button
          className={`${styles.accordion} ${isOpen ? styles.open : ''}`}
          onClick={() => setOpen( prev => !prev)}
        >
          <div>
            <div className={styles.toggleIcon}>{isOpen ? <NavArrowDown/> : <NavArrowRight/>}</div>
            <h3 className={styles.accordionTitle}>{props.title}</h3>
          </div>
          <span className={styles.redDot}/>
        </button>
        <div className={styles.panel}>
          {props.children}
        </div>
      </section>
    </div>
  );
}

export interface AccordionProps {
  children?: ReactNode,
  title: string
}
