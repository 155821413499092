import styles from './Inventory.module.scss';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../../account/useAccounts';
import MetricsDataGroup from './DataGroups/MetricsDataGroup';
import IdentificationDataGroup from './DataGroups/IdentificationDataGroup';
import SiteFactorsDataGroup from './DataGroups/SiteFactorsDataGroup';
import DetailedTree from '../../../../tree/DetailedTree';
import InlineMap from '../../../../components/InlineMap/InlineMap';
import { NavArrowDown, NavArrowRight } from 'iconoir-react';
import { Tab } from '../DataTabs/TabSelector';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';

export enum DataGroup {
  identification = 'IDENTIFICATION',
  siteFactors = 'SITEFACTORS'
}

export default function Inventory(props: InventoryProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const [editing, setEditing] = useState<undefined | DataGroup>(undefined);
  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const selectedTab = useMemo(() => {
    return urlContext.getSelectedDataPanelTab();
  }, [JSON.stringify(urlContext.getSelectedDataPanelTab())]);

  useEffect(() => {
    if (props.isOpen) setEditing(undefined);
  }, [props.isOpen]);

  const toggleOpen = () => {
    if (selectedTab) urlContext.setSelectedDataPanelTab(null);
    else {
      urlContext.setSelectedDataPanelTab(Tab.Risk);
      urlContext.setHistoryTrackingOpen(false);
    }
  };

  return (
    <div className={`${styles.container} ${!selectedTab ? styles.overFlowHidden : ''}`}>
      <h2 onClick={toggleOpen}>
        <span className={styles.arrowIcon}>
          {!selectedTab ? <NavArrowDown fontSize={18} /> : <NavArrowRight fontSize={18} />}
        </span>
        <span className={`${!selectedTab ? styles.activeHeader : ''}`}>
          {t('treeDetails.inventory.title')}
        </span>
      </h2>
      <div className={`${!selectedTab ? styles.open : styles.closed} ${styles.contentContainer}`}>
        <div>
          <div className={`${styles.header} ${props.isExpanded ? '' : styles.collapsedView}`}>
            <div>
              <h4>{t('treeDetails.inventory.genus')}</h4>
              <p>{props.tree.genus || <NoData />}</p>
            </div>
            <div>
              <h4>{t('treeDetails.inventory.species')}</h4>
              <p>{props.tree.species?.toLowerCase() || <NoData />}</p>
            </div>
            <div>
              <h4>{t('details.properties.commonName')}</h4>
              <p>{props.tree.commonName || <NoData />}</p>
            </div>
            <div>
              <h4>{t('treeDetails.inventory.street')}</h4>
              <p>{props.tree.streetAddress || <NoData />}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.inventoryContent} ${props.isOpen ? styles.openContent : ''}`}>
          <InlineMap
            tree={props.tree}
            isExpanded={props.isExpanded}
          />
          <MetricsDataGroup
            tree={props.tree}
            singleColumn={!props.isExpanded}
            organization={account.organization}
          />
          <IdentificationDataGroup
            tree={props.tree}
            singleColumn={!props.isExpanded}
            organizationId={account.organization.id}
            editing={editing}
            setEditing={setEditing}
          />
          <SiteFactorsDataGroup
            tree={props.tree}
            singleColumn={!props.isExpanded}
            organizationId={account.organization.id}
            editing={editing}
            setEditing={setEditing}
          />
        </div>
      </div>
    </div>
  );
}

interface InventoryProps {
  tree: DetailedTree,
  isOpen: boolean,
  isExpanded: boolean
}

export function NoData() {
  const { t } = useTranslation();
  return <span className={styles.noData}>{t('treeDetails.noData')}</span>;
}
