import FullscreenModal from '../../../components/Modal/fullscreen-modal/FullscreenModal';
import styles from './ReportDialog.module.scss';
import { Label, Map, PrintingPage, Xmark } from 'iconoir-react';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ManagedArea } from '../../../managed-area/ManagedArea';
import { TreeFilter } from '../../../tree-filter/TreeFilter';
import { ReportData } from './ReportData';
import GeneralInsightsSection from './sections/GeneralInsightsSection';
import SpeciesDistributionSection from './sections/SpeciesDistributionSection';
import CarbonStorageSection from './sections/CarbonStorageSection';
import AirPollutionSection from './sections/AirPollutionSection';
import AvoidedWaterSection from './sections/AvoidedWaterSection';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import DBHDistributionSection from './sections/DBHDistributionSection';
import CanopyCoverage from './sections/CanopyCoverageSection';
import { useMatch, useNavigate } from 'react-router-dom';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { Tree } from '../../../tree/Tree';
import { Flippers } from '../../../switches/Flippers';
import { getProperties } from '../../TaskManager/create/FilterConfig';

export default function ReportDialog(props: ReportDialogProps) {
  const { treeService, urlContext } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const { t } = useTranslation();

  const reportsContainerRef = useRef<HTMLDivElement | null>(null);
  const bulletPointListRef = useRef<HTMLUListElement | null>(null);

  const [reportData, setReportData] = useState<ReportData | null>(null);
  const navigate = useNavigate();

  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';

  useEffect(() => {
    const reverseMASelection = urlContext.getReverseMASelection();
    const managedAreaIds = urlContext.getManagedAreaIds();
    const filterConfiguration = urlContext.getFilterConfiguration();
    const fields = urlContext.getVisibleTableProperties();

    const fieldsToFetch = Array.from(new Set([
      ...(fields || []),
      ...getProperties(filterConfiguration)
    ])) as (keyof Tree)[];

    if (account.organization.isEnabled(Flippers.workspace)) {
      treeService.fetchWorkspaceReportData(
        account.organization.id,
        managedAreaIds,
        reverseMASelection,
        fieldsToFetch,
        props.filters.map(it => it.id),
        filterConfiguration,
        props.taskTemplateId
      ).then(setReportData);
    } else {
      treeService.fetchReportData(
        account.organization.id,
        managedAreaIds,
        reverseMASelection,
        fieldsToFetch,
        props.filters.map(it => it.id)
      ).then(setReportData);
    }
  }, [
    account.organization.id,
    props.filters,
    treeService,
    JSON.stringify(urlContext.getReverseMASelection()),
    JSON.stringify(urlContext.getManagedAreaIds()),
    JSON.stringify(urlContext.getFilterConfiguration())
  ]);

  const scrollToItem = itemId => {
    if (!reportsContainerRef.current) return;

    reportsContainerRef.current?.scrollTo({
      top: (reportsContainerRef.current?.querySelector(`${itemId}`) as HTMLDivElement).offsetTop - 50,
      behavior: 'smooth'
    });
  };

  const onListItemClick = e => {
    e.preventDefault();
    e.stopPropagation();
    scrollToItem((e.target as HTMLAnchorElement).getAttribute('href'));
  };

  const isInside = (container: DOMRect, element: DOMRect) => {
    return element.top > container.top && element.top < container.bottom;
  };

  useEffect(() => {
    if (!reportsContainerRef.current || !bulletPointListRef.current) return;
    reportsContainerRef.current.addEventListener('scroll', () => {
      const container = reportsContainerRef.current!;
      const rect = container.getBoundingClientRect();
      const children = Array.from(container.children);
      const visibleElement = children.find(it => isInside(rect, it.getBoundingClientRect()));
      const id = visibleElement?.getAttribute('id');
      const bulletPoints = Array.from(bulletPointListRef.current!.querySelectorAll('a'));
      bulletPoints.forEach(it => it.setAttribute('active', 'false'));
      bulletPoints.find(it => it.getAttribute('href') === `#${id}`)?.setAttribute('active', 'true');
    });
  }, [reportsContainerRef.current, bulletPointListRef.current]);

  const managedAreaNames = (props.managedAreas || []).map(it => it.getName());
  const managedAreaNamesDisplay = useMemo(() => {
    if (managedAreaNames.length === 0) {
      return t('reporting.allAreas');
    }

    if (managedAreaNames.length > 3) {
      return managedAreaNames.slice(0, 3).join(', ').concat('...');
    }

    return managedAreaNames.join(', ');
  }, [managedAreaNames, t]);

  const filterNames = useMemo(() => {
    if (props.filters.length === 0) {
      return t('reporting.allTrees');
    }

    return props.filters.map(it => it.name).join(', ');
  }, [props.filters, t]);

  const managedAreaNamesContainer = <div>
    <Map />
    <strong>{managedAreaNamesDisplay}</strong>
  </div>;

  const managedAreaNamesPossiblyWithTooltips = props.managedAreas.length > 3
    ? managedAreaNamesContainer
    : <Tooltip
      placement="bottom"
      overlay={<span>{managedAreaNames.join('\n')}</span>}
      overlayStyle={{ zIndex: 99999, whiteSpace: 'pre-wrap' }}>
      {managedAreaNamesContainer}
    </Tooltip>;

  return (
    <FullscreenModal isVisible={true} onHide={props.onHide}>
      <div className={styles.header}>
        <FunctionButton
          icon={<Xmark></Xmark>}
          onClick={props.onHide}
          className={styles.exit}
        />
        <div className={styles.reportDescription}>
          <div className={styles.organization}>
            <img src={account.getOrganizationLogoUrl()} alt="" />
            <strong>{t('reporting.reportName', { organizationName: account.getOrganizationName() })}</strong>
          </div>
          <div className={styles.description}>
            <div className={styles.namesContainer}>
              <div className={styles.names}>
                {managedAreaNamesPossiblyWithTooltips}
              </div>
              <div className={styles.names}>
                <Label></Label>
                {filterNames}
              </div>
            </div>
            <div className={styles.numOfTrees}>
              <strong>{t('reporting.numberOfTrees')}</strong>
              <span>{reportData?.total || '-'}</span>
            </div>
            {
              <FunctionButton
                icon={<PrintingPage />}
                onClick={() => {
                  navigate(`/organizations/${organizationId}/map/report`, {
                    state: {
                      reportData,
                      managedAreaNamesDisplay,
                      filterNames
                    }
                  });
                }}
                className={styles.printButton}
              />
            }
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {
          reportData ? <>
            <div className={styles.bulletPointContainer}>
              <ul className={styles.bulletPointList} ref={bulletPointListRef}>
                <li>
                  <a onClick={onListItemClick} href={'#generalInsights'}>
                    {t('reporting.generalInsights.title')}
                  </a>
                </li>
                {
                  (reportData.speciesDistribution.length > 1) && (
                    <li>
                      <a onClick={onListItemClick} href={'#speciesDistribution'}>
                        {t('reporting.speciesDistribution.title')}
                      </a>
                    </li>
                  )
                }
                <li>
                  <a onClick={onListItemClick} href={'#DBHDistribution'}>
                    {t('reporting.DBHDistribution.title')}
                  </a>
                </li>
                {
                  (reportData.speciesDistribution.length > 1) && (
                    <li>
                      <a onClick={onListItemClick} href={'#canopyCoverage'}>
                        {t('reporting.canopyCoverage.title')}
                      </a>
                    </li>
                  )
                }
                {
                  (reportData.speciesDistribution.length > 1) && (
                    <li>
                      <a onClick={onListItemClick} href={'#carbonStorage'}>
                        {t('reporting.carbonStorage.title')}
                      </a>
                    </li>
                  )
                }
                <li>
                  <a onClick={onListItemClick} href={'#airPollution'}>
                    {t('reporting.airPollution.title')}
                  </a>
                </li>
                {
                  (reportData.speciesDistribution.length > 1) && (
                    <li>
                      <a onClick={onListItemClick} href={'#avoidedWater'}>
                        {t('reporting.avoidedWater.title')}
                      </a>
                    </li>
                  )
                }
              </ul>
            </div>

            <div className={styles.reportsContainer} ref={reportsContainerRef}>
              <GeneralInsightsSection
                data={reportData.generalInsights}
                containerId={'generalInsights'}
              />
              {
                (reportData.speciesDistribution.length > 1) && (
                  <SpeciesDistributionSection
                    data={reportData.speciesDistribution}
                    containerId={'speciesDistribution'}
                  />
                )
              }
              <DBHDistributionSection data={reportData.dbhDistribution} containerId={'DBHDistribution'} />
              {
                (reportData.speciesDistribution.length > 1) && (
                  <CanopyCoverage
                    data={reportData.leafAreaAndCanopyCoverage}
                    speciesDistribution={reportData.speciesDistribution}
                    containerId={'canopyCoverage'}
                  />
                )
              }
              {
                (reportData.speciesDistribution.length > 1) && (
                  <CarbonStorageSection
                    data={reportData.carbonStorageAndSequestration}
                    speciesDistribution={reportData.speciesDistribution}
                    containerId={'carbonStorage'}
                  />
                )
              }
              <AirPollutionSection
                data={reportData.airPollutionRemoval}
                containerId={'airPollution'}
              />
              {
                (reportData.speciesDistribution.length > 1) && (
                  <AvoidedWaterSection
                    data={reportData.avoidedWaterRunoff}
                    speciesDistribution={reportData.speciesDistribution}
                    containerId={'avoidedWater'}
                  />
                )
              }
            </div>
          </>
            : <Spinner />
        }
      </div>
    </FullscreenModal>
  );
}

interface ReportDialogProps {
  managedAreas: ManagedArea[],
  filters: TreeFilter[],
  onHide: () => void,
  taskTemplateId?: string
}
