import { AccountType } from './AccountType';
import { AccountDto, AccountStatus } from './AccountDto';
import { Organization } from '../organization/Organization';
import { Flippers } from '../switches/Flippers';

export class Account {
  static fromDto(dto: AccountDto) {
    return new Account(dto.id, dto.type, Organization.fromDto(dto.organization), dto.status);
  }

  static empty(
    id?: string,
    accountType?: AccountType,
    organization?: Organization,
    status?: AccountStatus
  ) {
    return new EmptyAccount(id, accountType, organization, status);
  }

  protected constructor(
    readonly id: string,
    readonly type: AccountType,
    readonly organization: Organization,
    readonly status: AccountStatus
  ) {}

  isEnabled(flipper: Flippers): boolean {
    return this.organization.flippers.includes(flipper);
  }

  hasAccessTo(cached: Organization) {
    return this.organization.id === cached.id;
  }

  canManage(accountType: AccountType) {
    if (this.isOwner()) {
      return true;
    }

    if (this.isAdmin()) {
      return accountType === AccountType.Admin || accountType === AccountType.Employee || accountType === AccountType.Manager;
    }

    return false;
  }

  canEditDataStandards() {
    return this.isAdmin() || this.isOwner();
  }

  canEditOrganizationSettings() {
    return this.isOwner();
  }

  exists() {
    return true;
  }

  canManageMembers() {
    return this.isOwner() || this.isAdmin();
  }

  canCreateTaskManagerJobs() {
    return this.isAdmin() || this.isOwner() || this.isManager();
  }

  getOrganizationName(): string {
    return this.organization.name;
  }

  linkToOrganization() {
    return `/organizations/${this.organization.id}/home`;
  }

  linkToMap() {
    return `/organizations/${this.organization.id}/map`;
  }

  getOrganizationLogoUrl() {
    return this.organization.logoUrl;
  }

  refreshOrganization(organization: Organization) {
    return Account.fromDto({ ...this.toDto(), organization: organization.toDto() });
  }

  getDefaultWindSpeed() {
    return this.organization.defaultWindSpeed;
  }

  private isAdmin() {
    return this.type === AccountType.Admin;
  }

  private isEmployee() {
    return this.type === AccountType.Employee;
  }

  isOwner() {
    return this.type === AccountType.Owner;
  }

  private isManager() {
    return this.type === AccountType.Manager;
  }

  isManagerOrAbove() {
    return this.isManager() || this.isAdmin() || this.isOwner();
  }

  isAdminOrAbove() {
    return this.isAdmin() || this.isOwner();
  }

  private toDto(): AccountDto {
    return {
      id: this.id,
      type: this.type,
      organization: this.organization,
      status: this.status
    };
  }
}

class EmptyAccount extends Account {
  constructor(
    id = '',
    accountType = AccountType.Employee,
    organization = Organization.empty(),
    status = AccountStatus.Inactive
  ) {
    super(id, accountType, organization, status);
  }

  override isEnabled(flipper: Flippers): boolean {
    return false;
  }

  override exists() {
    return false;
  }

  override canManageMembers() {
    return false;
  }

  override canEditDataStandards() {
    return false;
  }

  override canEditOrganizationSettings() {
    return false;
  }

  override getOrganizationName(): string {
    return '';
  }
}
