import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import getRuntimeConfig from './RuntimeConfig';

i18n
  .use(detector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: `${getRuntimeConfig().apiUrl}/v1/translations/{{lng}}`
    },
    fallbackLng: 'en',
    detection: {
      caches: [],
      order: ['localStorage']
    }
  });

export default i18n;
