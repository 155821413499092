import { Tree } from '../../../tree/Tree';

export default interface FilterConfig {
  filters: Filter[],
  filterGroups: FilterGroup[],
  topLevelOperator: Operator
}

export interface Filter {
  property: keyof Tree,
  condition: NumericCondition | EnumCondition,
  value: number | string[]
}

export interface FilterGroup {
  filters: Filter[],
  filterGroupOperator: Operator
}

export enum Operator {
  AND = 'AND',
  OR = 'OR'
}

export enum NumericCondition {
  EQUALS = 'equals',
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan'
}

export enum EnumCondition {
  IN = 'in'
}

export function isValid(filter: Filter): boolean {
  return filter.property as string !== '' && filter.condition as string !== '' && (filter.value instanceof Array || !isNaN(Number(filter.value)));
}

export function getProperties(filterConfig: FilterConfig): string[] {
  return Array.from(new Set([
    ...filterConfig.filters.map(it => it.property as string),
    ...filterConfig.filterGroups.flatMap(it => it.filters.map(filter => filter.property as string))
  ]));
}
