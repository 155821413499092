import InvitationDto from '../../../registration/InvitationDto';
import { useTranslation } from 'react-i18next';
import { ModalContent, ModalControls, ModalFooter, ModalTitle } from '../../Modal/Modal';
import { AccountType } from '../../../account/AccountType';
import FormGroup from '../FormGroup';
import LegacyButton, { ButtonWithIcon, SecondaryButton } from '../../UI/Button/LegacyButton';
import Input from '../../UI/Input/Input';
import { ReactComponent as MailIcon } from '../images/mail-icon.svg';
import Select, { MultiSelect } from '../Select/Select';
import { FormEvent, useCallback, useRef, useState } from 'react';
import { Interest } from '../../../account/Interest';
import { Account } from '../../../account/Account';

export default function InvitationModal(props: InvitationModalProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const accountTypes = Object.values(AccountType).map(it => ({ value: it, label: t(`settings.members.${it}`) }));

  const [isInProgress, setProgressState] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [accountType, setAccountType] = useState<AccountType | null>(AccountType.Employee);
  const [interests, setInterests] = useState<Interest[]>([]);

  const { onEmail, showName, onInvite, showDetails } = props;
  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      if (!showDetails && emailInputRef.current && emailInputRef.current.checkValidity()) {
        setProgressState(true);
        return onEmail(email).finally(() => setProgressState(false));
      }

      if (!formRef.current || !formRef.current.checkValidity() || !accountType || isInProgress) {
        return;
      }

      setProgressState(true);
      const dto: InvitationDto = {
        email,
        accountType,
        interests,
        ...(!showName ? {} : { firstName, lastName })
      };
      onInvite(dto).finally(() => setProgressState(false));
    },
    [showDetails, accountType, isInProgress, email, interests, showName, firstName, lastName, onInvite, onEmail]
  );

  const availableInterests = [
    { value: Interest.QA, label: t(`interests.${Interest.QA}`) },
    { value: Interest.Business, label: t(`interests.${Interest.Business}`) },
    { value: Interest.TreeInspector, label: t(`interests.${Interest.TreeInspector}`) },
    { value: Interest.GreeneryOfficer, label: t(`interests.${Interest.GreeneryOfficer}`) },
    { value: Interest.TreeHealthExpert, label: t(`interests.${Interest.TreeHealthExpert}`) }
  ];

  return (
    <form ref={formRef} onSubmit={onSubmit}>
      <ModalTitle>
        {t('settings.members.invitation.modalTitle', { organizationName: props.organizationName })}
      </ModalTitle>

      <ModalContent>
        <FormGroup>
          <Input
            inputRef={emailInputRef}
            type="email"
            label={t('settings.members.invitation.emailLabel')}
            placeholder={t('settings.members.invitation.emailPlaceholder')}
            value={email}
            onValueChange={setEmail}
            required
            legacyStyle={true}
          />

          {props.isInvitedAlreadyMember && (
            <p style={{ color: 'var(--text-error)' }}>{t('settings.members.invitation.alreadyMember')}</p>
          )}

          {!props.showName && props.showDetails && (
            <p style={{ color: 'var(--text-faded)' }}>
              <i>{t('settings.members.invitation.alreadyUser')}</i>
            </p>
          )}
        </FormGroup>

        {props.showDetails && (
          <>
            <FormGroup>
              <Select
                value={accountType}
                options={accountTypes.filter(it => props.account.canManage(it.value))}
                label={t('settings.members.invitation.accountTypeLabel')}
                onChange={setAccountType}
              />
            </FormGroup>

            {props.showName && (
              <FormGroup layout="col-2">
                <Input
                  label={t('settings.members.invitation.firstNameLabel')}
                  placeholder={t('settings.members.invitation.firstNamePlaceholder')}
                  required={props.showName}
                  value={firstName}
                  onValueChange={setFirstName}
                  legacyStyle={true}
                />

                <Input
                  label={t('settings.members.invitation.lastNameLabel')}
                  placeholder={t('settings.members.invitation.lastNamePlaceholder')}
                  required={props.showName}
                  value={lastName}
                  onValueChange={setLastName}
                  legacyStyle={true}
                />
              </FormGroup>
            )}

            <FormGroup>
              <MultiSelect
                value={interests}
                options={availableInterests}
                label={t('settings.members.invitation.interestsLabel')}
                placeholder={t('settings.members.invitation.interestsPlaceholder')}
                onChange={setInterests}
              />
            </FormGroup>
          </>
        )}
      </ModalContent>

      <ModalFooter>
        <ModalControls>
          <SecondaryButton onClick={props.onCancel} type="button">
            {t('settings.members.invitation.cancel')}
          </SecondaryButton>

          {props.showDetails && (
            <ButtonWithIcon disabled={isInProgress} icon={<MailIcon />}>
              {t('settings.members.invitation.send')}
            </ButtonWithIcon>
          )}

          {!props.showDetails && <LegacyButton disabled={isInProgress}>{t('settings.members.invitation.next')}</LegacyButton>}
        </ModalControls>
      </ModalFooter>
    </form>
  );
}

interface InvitationModalProps {
  onInvite: (invitation: InvitationDto) => Promise<unknown>,
  onEmail: (email: string) => Promise<unknown>,
  showName: boolean,
  organizationName: string,
  showDetails: boolean,
  onCancel: () => unknown,
  account: Account,
  isInvitedAlreadyMember: boolean
}
