import styles from './Gallery.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactChild, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Xmark, NavArrowLeft, NavArrowRight } from 'iconoir-react';

export default function Gallery(props: GalleryProps) {
  const { t } = useTranslation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFullScreen, setFullScreen] = useState(false);
  const [isZoomedIn, setZoom] = useState(false);

  const goToNext = useCallback(() => {
    if (currentImageIndex === props.images.length - 1) {
      setCurrentImageIndex(0);
    } else {
      setCurrentImageIndex(index => index + 1);
    }
  }, [currentImageIndex, props.images.length]);
  const goToPrevious = useCallback(() => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(props.images.length - 1);
    } else {
      setCurrentImageIndex(index => index - 1);
    }
  }, [currentImageIndex, props.images.length]);

  useEffect(() => {
    if (!isFullScreen) return;

    const listener = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') goToPrevious();
      if (event.key === 'ArrowRight') goToNext();
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [goToNext, goToPrevious, isFullScreen]);

  useEffect(() => {
    setZoom(false);
  }, [currentImageIndex]);

  const image = props.images[currentImageIndex];
  if (!image) return null;

  const zoom = () => setZoom(isOn => !isOn);
  const close = () => setFullScreen(false);
  const open = (index: number) => {
    setCurrentImageIndex(index);
    setFullScreen(true);
  };
  const groups: GroupedImage[][] = Object.values(
    props.images.reduce(
      (acc: Record<string, GroupedImage[]>, image, index) => ({
        ...acc,
        [image.group]: acc[image.group].concat({ image, index })
      }),
      Object.fromEntries(
        props.images
          .map(it => it.group)
          .sort()
          .map(it => [it, []])
      )
    )
  ).reverse();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <strong>{t('analytics.photos')}</strong>
        </div>

        <div className={styles.previews}>
          {props.images.length <= 3 &&
            props.images.map((it, i) => <Preview
              index={i}
              onClick={open}
              key={`preview-${i}`}
              image={it} />)}

          {props.images.length > 3 && (
            <>
              {props.images.slice(0, 2).map((it, i) => (
                <Preview
                  index={i}
                  onClick={open}
                  key={`preview-${i}`}
                  image={it} />
              ))}

              <div className={styles.additionals} onClick={() => setFullScreen(true)}>
                +{props.images.length - 2}
              </div>
            </>
          )}
        </div>
      </div>

      {isFullScreen && (
        <FullScreenContainer onEsc={close}>
          <div className={styles.fullContainer}>
            <div className={styles.closeContainer}>
              <button
                className={styles.close}
                type="button"
                onClick={close}>
                <Xmark strokeWidth={2} />
              </button>
            </div>

            <div className={styles.content}>
              <div className={styles.imageAndControlsContainer}>
                <div className={styles.controlContainer}>
                  <button
                    type="button"
                    className={styles.control}
                    onClick={goToPrevious}>
                    <NavArrowLeft />
                  </button>
                </div>

                <div className={styles.imageContainer.concat(' ', isZoomedIn ? styles.zoomed : '')}>
                  <img
                    onClick={zoom}
                    className={styles.image.concat(' ', isZoomedIn ? styles.zoomed : '')}
                    src={image.url}
                    alt=""
                  />
                </div>

                <div className={styles.controlContainer}>
                  <button
                    type="button"
                    className={styles.control}
                    onClick={goToNext}>
                    <NavArrowRight />
                  </button>
                </div>
              </div>
            </div>

            <div className={styles.caption}>
              {image.capturedAt.toLocaleDateString()} {image.capturedAt.toLocaleTimeString()}
            </div>

            <div className={styles.groups}>
              {groups.map((group, i) => (
                <div key={`group-${i}`} className={styles.group}>
                  <div className={styles.groupTitle}>{group[0].image.group}</div>

                  <div className={styles.groupedPreviews}>
                    {group.map((grouped, imageIndex) => (
                      <div
                        key={`group-${i}-image-${imageIndex}`}
                        className={styles.groupedPreviewContainer.concat(
                          ' ',
                          currentImageIndex === grouped.index ? styles.active : ''
                        )}
                      >
                        <Preview
                          image={grouped.image}
                          onClick={open}
                          index={grouped.index} />

                        <div className={styles.groupedPreviewCaption}>
                          {grouped.image.capturedAt.getDate().toString().padStart(2, '0')}/
                          {grouped.image.capturedAt.getMonth().toString().padStart(2, '0')}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </FullScreenContainer>
      )}
    </>
  );
}

export function EmbededGallery(props: GalleryProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToNext = useCallback(() => {
    if (currentImageIndex === props.images.length - 1) {
      setCurrentImageIndex(0);
    } else {
      setCurrentImageIndex(index => index + 1);
    }
  }, [currentImageIndex, props.images.length]);
  const goToPrevious = useCallback(() => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(props.images.length - 1);
    } else {
      setCurrentImageIndex(index => index - 1);
    }
  }, [currentImageIndex, props.images.length]);

  const image = props.images[currentImageIndex];
  if (!image) return null;

  return (
    <div className={styles.embededContainer}>
      <div className={styles.content}>
        <div className={styles.imageAndControlsContainer}>
          <div className={styles.controlContainer}>
            <button
              type="button"
              className={styles.control}
              onClick={goToPrevious}>
              <NavArrowLeft />
            </button>
          </div>

          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              src={image.url}
              alt="" />
          </div>

          <div className={styles.controlContainer}>
            <button
              type="button"
              className={styles.control}
              onClick={goToNext}>
              <NavArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.caption}>
        {image.capturedAt.toLocaleDateString()} {image.capturedAt.toLocaleTimeString()}
      </div>

      <div className={styles.previews}>
        {props.images.map((image, index) => (
          <div
            key={`embeded-preview-${index}`}
            className={styles.embededPreviewContainer.concat(' ', currentImageIndex === index ? styles.active : '')}
          >
            <Preview
              image={image}
              onClick={setCurrentImageIndex}
              index={index} />

            <div className={styles.embededPreviewCaption}>
              {image.capturedAt.getDate().toString().padStart(2, '0')}/
              {image.capturedAt.getMonth().toString().padStart(2, '0')}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

type GroupedImage = { image: GalleryImage, index: number };

function Preview(props: { image: GalleryImage, onClick: (index: number) => unknown, index: number }) {
  const className = props.image.isActive ? `${styles.preview} ${styles.active}` : styles.preview;
  const onClick = () => props.onClick(props.index);
  return (
    <div
      className={className}
      style={{ backgroundImage: `url("${props.image.previewUrl}")` }}
      onClick={onClick} />
  );
}

function FullScreenContainer({ children, onEsc }: { children: ReactChild, onEsc: () => unknown }) {
  const [container] = useState(() => document.createElement('div'));
  container.classList.add(styles.full);

  useEffect(() => {
    const escListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onEsc();
    };

    window.addEventListener('keydown', escListener);
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
      window.removeEventListener('keydown', escListener);
    };
  }, [onEsc, container]);

  return createPortal(children, container);
}

export interface GalleryImage {
  previewUrl: string,
  url: string,
  isActive: boolean,
  capturedAt: Date,
  group: string
}

interface GalleryProps {
  images: GalleryImage[]
}
