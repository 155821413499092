import PropertyConfiguration, { PropertyRange } from '../../../properties/PropertyConfiguration';
import { EditablePropertyRange } from './PropertyConfigEditModal';

export class EditableConfigRepository {
  constructor(
        private config: PropertyConfiguration | null
  ) {
  }

  addNewRange(range: PropertyRange | null) {
    const config = this.config;
    if (!range || !config) return;
    if (config.ranges.length === 8) return;
    const newRanges = this.setRangeInRanges(config.ranges, range);
    this.config = new PropertyConfiguration(config.id, config.property, config.isStandard, newRanges as PropertyRange[], config.reversedOrder);
    return this.config;
  }

  deleteRange(rangeToDelete: PropertyRange) {
    const config = this.config;
    if (!rangeToDelete || !config) return;
    const index = config.ranges.indexOf(rangeToDelete);
    if (index === 0 || index === config.ranges.length - 1 || index === -1) return;
    const newRanges = [...config.ranges] as PropertyRange[];
    newRanges[index + 1].from = rangeToDelete.from;
    newRanges.splice(index, 1);
    this.config = new PropertyConfiguration(config.id, config.property, config.isStandard, newRanges, config.reversedOrder);
    return this.config;
  }

  updateRange(range: PropertyRange, index: number) {
    const config = this.config;
    if (!range || !config) return;
    const min = config.ranges[0].from;
    let newRanges = [...config.ranges.filter((it, i) => i !== index)];
    newRanges = this.setRangeInRanges(newRanges, range, min) as PropertyRange[];
    this.config = new PropertyConfiguration(config.id, config.property, config.isStandard, newRanges, config.reversedOrder);
    return this.config;
  }

  changeRangeDirection(direction: 'reversed' | 'default') {
    const config = this.config;
    if (!config) return;
    this.config = new PropertyConfiguration(config.id, config.property, config.isStandard, config.ranges, direction === 'reversed');
    return this.config;
  }

  private setRangeInRanges = (existingRanges: EditablePropertyRange[], newRange: EditablePropertyRange, min = existingRanges[0].from) => {
    return existingRanges.concat(newRange)
      .sort((r1, r2) => Number(r1.to) - Number(r2.to))
      .map((it, i, newRanges) => {
        const topOfPreviousRange = newRanges[i - 1]?.to ?? min;
        return {
          ...it,
          from: topOfPreviousRange
        };
      });
  };
}
