import * as THREE from 'three';

export default class SphereControls {
  private callbacks: (() => void)[] = [];
  private onPointerDownMouseX = 0;
  private onPointerDownMouseY = 0;
  private lon = 0;
  private onPointerDownLon = 0;
  private lat = 0;
  private onPointerDownLat = 0;
  private phi = 0;
  private theta = 0;

  readonly MIN_FOV = 10;
  readonly MAX_FOV = 90;
  readonly ANGLE_AT_MIN_FOV = -25;
  readonly ANGLE_AT_MAX_FOV = 14.3;

  private initialState: { lat: number, lon: number, fov: number } = { lat: 0, lon: 0, fov: this.MAX_FOV };

  private cameras: THREE.PerspectiveCamera[] = [];
  private enabled = false;

  listen(camera: THREE.PerspectiveCamera, canvas: HTMLCanvasElement, onChangeCallbacks: Array<() => void>) {
    if (this.enabled) return;
    this.enabled = true;
    this.cameras.push(camera);
    this.callbacks.push(...onChangeCallbacks);

    this.synchronizeTo(camera);
    this.initialState = { lat: this.lat, lon: this.lon, fov: this.MAX_FOV };

    canvas.addEventListener('pointerdown', this.onPointerDown);
    canvas.addEventListener('wheel', this.onDocumentMouseWheel);
  }

  synchronizeTo(camera: THREE.PerspectiveCamera) {
    this.lat = THREE.MathUtils.radToDeg(camera.rotation.x);
    this.lon = -90 - THREE.MathUtils.radToDeg(camera.rotation.y);
    this.update(camera);
  }

  reset() {
    this.lat = this.initialState.lat;
    this.lon = this.initialState.lon;
    this.cameras.forEach(it => {
      this.setCameraFov(it, this.initialState.fov);
      this.update(it);
    });
    this.callbacks.forEach(it => it());
  }

  dispose(camera: THREE.PerspectiveCamera, canvas: HTMLCanvasElement, onChangeCallbacks: Array<() => void>) {
    if (!this.enabled) return;
    this.enabled = false;
    canvas.removeEventListener('pointerdown', this.onPointerDown);
    canvas.removeEventListener('wheel', this.onDocumentMouseWheel);

    this.cameras.splice(this.cameras.indexOf(camera));

    onChangeCallbacks.forEach(onChangeCallback => this.callbacks.splice(this.callbacks.indexOf(onChangeCallback)));
  }

  zoomIn() {
    this.cameras.forEach(camera => {
      const fov = camera.fov - 10;
      this.setCameraFov(camera, fov);
      this.update(camera);
    });

    this.callbacks.forEach(it => it());
  }

  zoomOut() {
    this.cameras.forEach(camera => {
      const fov = camera.fov + 10;
      this.setCameraFov(camera, fov);
      this.update(camera);
    });

    this.callbacks.forEach(it => it());
  }

  private readonly onPointerDown = event => {
    if (event.isPrimary === false) {
      return;
    }

    this.onPointerDownMouseX = event.clientX;
    this.onPointerDownMouseY = event.clientY;

    this.onPointerDownLon = this.lon;
    this.onPointerDownLat = this.lat;

    document.addEventListener('pointermove', this.onPointerMove);
    document.addEventListener('pointerup', this.onPointerUp);
  };

  private readonly onPointerMove = event => {
    if (event.isPrimary === false || !this.enabled) {
      return;
    }

    this.lon = (this.onPointerDownMouseX - event.clientX) * 0.1 * this.cameras[0].fov / 53.3 + this.onPointerDownLon;
    this.lat = (event.clientY - this.onPointerDownMouseY) * 0.1 * this.cameras[0].fov / 53.3 + this.onPointerDownLat;
    this.cameras.forEach(it => this.update(it));
    this.callbacks.forEach(it => it());
  };

  private readonly onPointerUp = event => {
    if (event.isPrimary === false) {
      return;
    }

    document.removeEventListener('pointermove', this.onPointerMove);
    document.removeEventListener('pointerup', this.onPointerUp);
    this.cameras.forEach(it => this.update(it));
    this.callbacks.forEach(it => it());
  };

  private readonly onDocumentMouseWheel = event => {
    event.preventDefault();
    this.cameras.forEach(camera => {
      const fov = camera.fov + event.deltaY * 0.05;
      this.setCameraFov(camera, fov);
      this.update(camera);
    });

    this.callbacks.forEach(it => it());
  };

  private setCameraFov(camera: THREE.PerspectiveCamera, fov: number) {
    camera.fov = THREE.MathUtils.clamp(fov, 10, this.MAX_FOV);
    camera.updateProjectionMatrix();
  }

  private update(camera: THREE.PerspectiveCamera) {
    this.lat = THREE.MathUtils.clamp(this.lat, (camera.fov - this.MIN_FOV) * (this.ANGLE_AT_MAX_FOV - this.ANGLE_AT_MIN_FOV) / (this.MAX_FOV - this.MIN_FOV) + this.ANGLE_AT_MIN_FOV, 85);
    this.phi = THREE.MathUtils.degToRad(90 - this.lat);
    this.theta = THREE.MathUtils.degToRad(this.lon);

    const x = 500 * Math.sin(this.phi) * Math.cos(this.theta) + (camera.position.x || 0);
    const y = 500 * Math.cos(this.phi) + (camera.position.y || 0);
    const z = 500 * Math.sin(this.phi) * Math.sin(this.theta) + (camera.position.z || 0);

    camera.lookAt(x, y, z);
  }
}
