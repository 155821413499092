import { useContext, useMemo, useState } from 'react';
import { Flippers } from '../../../switches/Flippers';
import styles from './OrganizationScreen.module.scss';
import Toggle from '../../UI/Toggle/Toggle';
import { useTranslation } from 'react-i18next';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { Organization } from '../../../organization/Organization';
import { useCurrentAccount, useEditAccount } from '../../../account/useAccounts';
import { dismissibleSuccessToast } from '../../UI/Toast/DismissibleToast';

export function FlipperEditor(props: FlipperEditorProps) {
  const { organizationService } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const editAccount = useEditAccount(account.id);

  const { t } = useTranslation();
  const [enabledFlippers, setEnabledFlippers] = useState(props.initialFlippers.filter(it => Object.values(Flippers).includes(it)));

  const flippersUnchanged = useMemo(() => {
    return JSON.stringify(enabledFlippers.sort()) === JSON.stringify(props.initialFlippers);
  }, [enabledFlippers, props.initialFlippers]);

  function toggleFlipper(flipper: Flippers) {
    if (!enabledFlippers.includes(flipper)) {
      setEnabledFlippers(prev => Array.from(new Set([...prev, flipper])));
    } else {
      setEnabledFlippers(prev => prev.filter(it => it !== flipper));
    }
  }

  async function saveFlippers() {
    if (flippersUnchanged) return;
    await organizationService.setFlippers(props.organization.id, enabledFlippers);
    editAccount(account.refreshOrganization(account.organization.refresh({ flippers: enabledFlippers })));
    dismissibleSuccessToast(t('settings.organization.flippersSaved'));
  }

  return <>
    <h3>{t('settings.organization.flipperConfig')}</h3>
    {
      props.availableFlippers.map(it => {
        return <div className={styles.flipperContainer} key={it}>
          <Toggle
            checked={enabledFlippers.includes(it)}
            onChange={() => toggleFlipper(it)}
          >
            {it}
          </Toggle>
        </div>;
      })
    }
    <button
      className={`${styles.scriptButton} ${flippersUnchanged ? styles.disabled : ''}`}
      onClick={saveFlippers}
      disabled={flippersUnchanged}
    >
      {t('settings.organization.saveFlippers')}
    </button>
  </>;
}

type FlipperEditorProps = {
  initialFlippers: Flippers[],
  availableFlippers: Flippers[],
  organization: Organization
};
