export default function ScientificNameInItalic (props: { scientificName: string }) {
  if (props.scientificName.includes(' x ')) {
    const genus = props.scientificName.split(' x ')[0];
    const species = props.scientificName.split(' x ')[1];
    return (<>
      <i>{genus}</i>
      <span> x </span>
      <i>{species}</i>
    </>);
  } else {
    const index = props.scientificName.indexOf(" '");
    const cultivar = props.scientificName.slice(index + 1);
    const rest = props.scientificName.slice(0, index + 1);
    return (<>
      <i>{rest}</i>
      <span>{cultivar}</span>
    </>);
  }
}
