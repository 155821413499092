import styles from './Checkbox.module.scss';
import { ChangeEvent, ReactNode, useCallback } from 'react';
import { Check } from 'iconoir-react';

export default function Checkbox(props: CheckboxProps) {
  const valueChangeCb = props.onValueChange;
  const onChangeCb = props.onChange;

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (valueChangeCb) {
      valueChangeCb(event.target.checked);
    }
    if (onChangeCb) {
      onChangeCb(event);
    }
  }, [onChangeCb, valueChangeCb]);

  return (
    <label className={`${styles.checkboxContainer} ${props.disabled ? styles.disabled : ''}`}>
      <div className={`${styles.checkbox} ${props.checked && styles.checked} ${props.className ?? ''}`}>
        {props.checked && <Check />}
      </div>

      {props.children}

      <input
        disabled={props.disabled}
        type="checkbox"
        checked={props.checked}
        onChange={onChange}
        className={styles.hidden} />
    </label>
  );
}

interface CheckboxProps {
  checked: boolean,
  children?: ReactNode,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown,
  onValueChange?: (isChecked: boolean) => unknown,
  disabled?: boolean,
  className?: string
}
