import { ReactNode, useContext, useEffect, useRef } from 'react';
import { ContextMenuContext } from '../../../components/UI/ContextMenu/ContextMenuContext';
import { TooltipForEllipsis } from '../../../components/UI/Tooltip/Tooltip';

export function AssigneeSelectorTrigger(props: { children: ReactNode | ReactNode[], unassigned: boolean }) {
  return (
    <TooltipForEllipsis overlay={props.children}>
      <span className={`inline-block py-2 px-5 border border-outer-space-400 rounded-3xl max-w-48 truncate ${props.unassigned ? 'border-warn-chardonnay-400 text-outer-space-400' : ''}`}>
        {props.children}
      </span>
    </TooltipForEllipsis>
  );
}

export function AssigneeSelectorButton(props: {
  children: ReactNode | ReactNode[],
  active: boolean,
  onClick?: () => unknown
}) {
  const ref = useRef<HTMLButtonElement>(null);
  const ctx = useContext(ContextMenuContext);

  useEffect(() => {
    if (props.active) ref.current?.scrollIntoView({ block: 'center' });
  }, [props.active, ref]);

  return (
    <TooltipForEllipsis overlay={props.children}>
      <button
        ref={ref}
        className={`px-4 py-1.5 truncate w-full text-left leading-6 text-greehill-00 focus:outline-none focus:bg-outer-space-500/60 hover:bg-outer-space-500 ${props.active ? 'outline outline-outer-space-500 font-semibold' : ''}`.trimEnd()}
        type="button"
        onClick={() => {
          props.onClick?.();
          ctx.toggle();
        }}
      >
        {props.children}
      </button>
    </TooltipForEllipsis>
  );
}
