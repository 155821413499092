import { ComponentType, ReactNode, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './SettingsTabBar.module.scss';
import { Account } from '../../account/Account';
import { Building, CardShield, GridPlus, Group, ProfileCircle, StatsUpSquare } from 'iconoir-react';
import { AuthContext } from '../../auth/AuthContext';
import { Flippers } from '../../switches/Flippers';

export default function SettingsTabBar({ currentAccount }: { currentAccount: Account }) {
  const { t } = useTranslation();
  const user = useContext(AuthContext).user;
  return (
    <aside>
      <nav>
        <ul className={styles.ul}>
          <SettingsTabBarLink to="user" icon={ProfileCircle}>
            <span title={t('settings.userPreferences')}>{t('settings.userPreferences')}</span>
          </SettingsTabBarLink>
          {currentAccount.organization.isEnabled(Flippers.release24q3) && <SettingsTabBarLink to="roles" icon={CardShield}>
            <span title={t('settings.rolesAndPermissions')}>{t('settings.rolesAndPermissions')}</span>
          </SettingsTabBarLink>}

          {currentAccount.canManageMembers() && (
            <SettingsTabBarLink to="members" icon={Group}>
              {(currentAccount.canManageMembers() &&
                <span title={t('settings.manageMembers')}>{t('settings.manageMembers')}</span>)
                || (<span title={t('settings.organizationMembers')}>{t('settings.organizationMembers')}</span>)}
            </SettingsTabBarLink>
          )}

          {currentAccount.canEditOrganizationSettings() && (
            <SettingsTabBarLink to="organization" icon={Building}>
              <span title={t('settings.organizationSettings')}>{t('settings.organizationSettings')}</span>
            </SettingsTabBarLink>
          )}
          {currentAccount.canEditDataStandards() && (
            <SettingsTabBarLink to="data-standards" icon={StatsUpSquare}>
              <span title={t('settings.dataStandards.label')}>{t('settings.dataStandards.label')}</span>
            </SettingsTabBarLink>
          )}
          {user.isSuperOwner() && (
            <SettingsTabBarLink to="create-organization" icon={GridPlus}>
              Create new organization
            </SettingsTabBarLink>
          )}
        </ul>
      </nav>
    </aside>
  );
}

function SettingsTabBarLink({ children, icon: IconComponent, to }: SettingsTabBarLinkProps) {
  return (
    <li>
      <NavLink to={to} className={({ isActive }) => getNavLinkClassNames(isActive)}>
        <span className={styles.icon}>
          <IconComponent />
        </span>

        <span>{children}</span>
      </NavLink>
    </li>
  );
}

interface SettingsTabBarLinkProps {
  to: string,
  icon: ComponentType,
  children: ReactNode
}

function getNavLinkClassNames(isActive: boolean) {
  return isActive ? [styles.navLink, styles.navLinkActive].join(' ') : styles.navLink;
}
