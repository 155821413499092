import { Location } from 'react-router-dom';
import { Tree } from '../tree/Tree';

export class DetailsBackUrl {
  static store(treeId: string, location: Location) {
    localStorage.setItem(`mapUrlBeforeAnalytics::${treeId}`, location.pathname + location.search);
  }

  static restore(tree: Tree, organizationId: string) {
    const [lat, lng] = tree.getWorldCoordinates();
    const defaultUrl = `/organizations/${organizationId}/map?i=${tree.id}`;

    const url = localStorage.getItem(`mapUrlBeforeAnalytics::${tree.id}`) ?? defaultUrl;

    if (url.includes('?')) return `${url}&lat=${lat}&lng=${lng}&zoom=18`;
    return `${url}?lat=${lat}&lng=${lng}&zoom=18`;
  }
}
