import Input from '../../../components/UI/Input/Input';
import { FormEvent, useContext, useState } from 'react';
import Toggle from '../../../components/UI/Toggle/Toggle';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import { GridPlus, Plus } from 'iconoir-react';
import { CreateOrganizationDto } from '../../../organization/CreateOrganizationDto';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import styles from './CreateOrganization.module.scss';
import { useTranslation } from 'react-i18next';
import Modal, { ModalContent, ModalTitle } from '../../../components/Modal/Modal';
import LegacyButton, { ButtonWithIcon } from '../../../components/UI/Button/LegacyButton';
import { useQueryClient } from 'react-query';
import { StaticQueryKeys } from '../../../StaticQueryKeys';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../../components/UI/Toast/DismissibleToast';

const currencyItems = [
  {
    id: 'EUR',
    translationKey: 'EUR'
  },
  {
    id: 'USD',
    translationKey: 'USD'
  },
  {
    id: 'HUF',
    translationKey: 'HUF'
  }
];

export default function CreateOrganization() {
  const [isModalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { organizationService } = useContext(DependencyInjectionContext);
  const initialFormState = {
    name: '',
    tseProjectName: '',
    isDemo: false,
    isMetric: false,
    currency: 'EUR',
    defaultWindSpeed: 80,
    serviceDeskUrl: ''
  };
  const [organization, setOrganization] = useState<Partial<CreateOrganizationDto>>(initialFormState);
  const [image, setImage] = useState<File | null>(null);

  const fileReader = new FileReader();

  fileReader.onload = () => {
    const file = JSON.parse(fileReader.result!.toString());
    const geojson = file.type === 'MultiPolygon' ? file : file.geometries[0];

    setOrganization(prev => ({
      ...prev,
      boundaries: JSON.stringify(geojson)
    }));
  };

  const createOrganization = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
      const orgFormData = new FormData();
      for (const key in organization) {
        orgFormData.append(key, organization[key]);
      }
      orgFormData.append('image', image!);
      try {
        await organizationService.create(orgFormData);
        await queryClient.invalidateQueries(StaticQueryKeys.ACCOUNTS);
        dismissibleSuccessToast('Organization created successfully');
      } catch (e: unknown) {
        dismissibleErrorToast('Operation failed: ' + (e as Error).message);
      } finally {
        await queryClient.invalidateQueries(StaticQueryKeys.ORGANIZATIONS);
        setModalOpen(false);
      }
    }
  };

  const handleCancel = () => {
    setOrganization(initialFormState);
    setModalOpen(false);
  };

  return (<>
    <div className={styles.createNewOrgButton}>
      <LegacyButton variant={'primary'} onClick={() => setModalOpen(true)}>
        <GridPlus />
        <span>Create new organization</span>
      </LegacyButton>
    </div>
    <Modal isVisible={isModalOpen} onHide={() => setModalOpen(false)}>
      <ModalTitle>
        <h1>Create new organization</h1>
      </ModalTitle>
      <ModalContent>
        <form onSubmit={createOrganization} className={styles.screenContainer}>
          <Input
            className={styles.inputContainer}
            label={t('settings.createOrganization.name')}
            value={organization.name}
            onValueChange={value => setOrganization(prev => ({ ...prev, name: value }))}
            required={true}
          />

          <div>
            <div>{t('settings.createOrganization.selectLogo')}</div>
            <input
              type={'file'}
              name="image"
              onChange={event => setImage(event.target.files![0])} />
          </div>

          <Input
            className={styles.inputContainer}
            label={t('settings.createOrganization.tseProjectName')}
            value={organization.tseProjectName}
            onValueChange={value => setOrganization(prev => ({ ...prev, tseProjectName: value }))}
            required={true}
          />

          <div>
            <div>{t('settings.createOrganization.selectGeojson')}</div>
            <input
              type={'file'}
              required={true}
              onChange={e => fileReader.readAsText(e.target.files![0])} />
          </div>

          <div className={styles.toggleContainer}>
            <span>{t('settings.createOrganization.metric')}</span>
            <Toggle
              checked={organization.isMetric!}
              onChange={() => setOrganization(prev => ({ ...prev, isMetric: !prev.isMetric }))} />
          </div>
          <div className={styles.toggleContainer}>
            <span>{t('settings.createOrganization.demo')}</span>
            <Toggle
              checked={organization.isDemo!}
              onChange={() => setOrganization(prev => ({ ...prev, isDemo: !prev.isDemo }))} />
          </div>

          <div>
            <div>{t('settings.createOrganization.currency')}</div>
            <Dropdown
              value={currencyItems.find(it => it.id === organization.currency)}
              onSelect={value => setOrganization(prev => ({ ...prev, currency: value.id }))}
              items={currencyItems} />
          </div>

          <Input
            className={styles.inputContainer}
            label={t('settings.createOrganization.windspeed')}
            value={organization.defaultWindSpeed}
            onValueChange={value => setOrganization(prev => ({ ...prev, defaultWindSpeed: Number(value) }))}
            type={'number'}
            required={true}
          />

          <Input
            className={styles.inputContainer}
            label={t('settings.createOrganization.serviceDeskUrl')}
            value={organization.serviceDeskUrl}
            onValueChange={value => setOrganization(prev => ({ ...prev, serviceDeskUrl: value }))}
            required={true}
          />
          <div className={styles.buttonsContainer}>
            <ButtonWithIcon
              variant={'primary'}
              type={'submit'}
              icon={<Plus />}>
              {t('settings.createOrganization.cta')}
            </ButtonWithIcon>
            <LegacyButton
              variant={'secondary'}
              type={'reset'}
              onClick={handleCancel}
            >
              Cancel
            </LegacyButton>
          </div>
        </form>
      </ModalContent>
    </Modal>
  </>);
}
