import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';
import { FullLogoIcon } from '../../components/UI/Icon/Icon';
import Text from '../../components/UI/Text/Text';
import styles from './Home.module.scss';
import CityPlanning from './images/city_planning.png';
import Mapping from './images/mapping.png';
import Manage from './images/manage.png';
import getRuntimeConfig from '../../RuntimeConfig';

export default function Home() {
  const { t } = useTranslation();
  const match = useMatch('/organizations/:organizationId/*');
  const withBasePath = path => (match?.pathnameBase ?? '') + path;

  const isCityPlanningEnabled = getRuntimeConfig().projectName === 'lilleb_v2';
  return (
    <div className={styles.home}>
      <FullLogoIcon />
      <div className={styles.main}>
        <p className={styles.title}>
          greehill{` ${t('home.insights')}`}
        </p>
        <div className={styles.features}>
          <Link to={withBasePath('/map')}>
            <div className={styles.featureOption}>
              <img alt={t('home.features.inventory')} src={Mapping} />
              <Text
                weight={600}
                className={styles.featureLabel}
                translationKey={'home.features.inventory'} />
            </div>
          </Link>

          <Link
            to={withBasePath('/map?s=true')}
            className={styles.disabled}
          >
            <div className={styles.featureOption}>
              <img alt={t('home.features.inspection')} src={Manage} />
              <Text
                weight={600}
                className={styles.featureLabel}
                translationKey={'home.features.inspection'} />
            </div>
          </Link>

          <Link to={withBasePath('/city-planning')} className={isCityPlanningEnabled ? '' : styles.disabled}>
            <div className={styles.featureOption}>
              <img alt={t('home.features.management')} src={CityPlanning} />
              <Text
                weight={600}
                className={styles.featureLabel}
                translationKey={'home.features.management'} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
