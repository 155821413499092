import React, { ReactPortal, useEffect, useState } from 'react';
import styles from './ConfirmationModal.module.scss';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';

export default function useConfirmationModal() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [confirmText, setConfirmText] = useState<string>(t('yes'));
  const [cancelText, setCancelText] = useState<string>(t('cancel'));
  const [resolveFn, setResolveFn] = useState<((value: boolean) => void) | undefined>(undefined);
  const [modal, setModal] = useState<ReactPortal | null>(null);

  useEffect(() => {
    const handleConfirm = () => {
      if (resolveFn) {
        resolveFn(true);
      }
      setIsVisible(false);
    };

    const handleCancel = () => {
      if (resolveFn) {
        resolveFn(false);
      }
      setIsVisible(false);
    };

    if (isVisible) {
      const modalContainer = document.createElement('div');
      document.body.appendChild(modalContainer);
      modalContainer.classList.add(styles.container);

      const modalContent = (
        <>
          <div className={styles.overlay} onClick={handleCancel}/>
          <div className={styles.content}>
            <p>{message}</p>
            <div className={styles.controls}>
              <button onClick={handleConfirm}>
                {confirmText}
              </button>
              <button onClick={handleCancel}>
                {cancelText}
              </button>
            </div>
          </div>
        </>
      );

      setModal(createPortal(modalContent, modalContainer));

      return () => {
        setModal(null);
        document.body.removeChild(modalContainer);
      };
    }
  }, [cancelText, confirmText, isVisible, message, resolveFn]);

  const confirm = async (config: {message: string, confirmText?: string, cancelText?: string}) => {
    const { message, confirmText, cancelText } = config;
    setMessage(message);
    if (confirmText) setConfirmText(confirmText);
    if (cancelText) setCancelText(cancelText);
    setIsVisible(true);

    return await new Promise<boolean>(resolve => {
      setResolveFn(() => resolve);
    });
  };

  return { confirm, modal };
}
