import { useContext, useEffect, useRef, useState } from 'react';
import styles from './PropertySelectorDropdownMenu.module.scss';
import treeImage from './images/tree.png';
import { useTranslation } from 'react-i18next';
import { Filter, NavArrowDown, NavArrowUp, Reports } from 'iconoir-react';
import { PanelLayout } from '../../routes/Explore/PanelLayout';
import { useAvailableProperties } from '../../properties/usePropertyConfigurations';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { AvailableProperties } from '../../properties/AvailableProperties';
import { useTracking } from '../../analytics/useTracking';
import DropdownList from './DropdownList';

export default function PropertySelectorDropdownMenu() {
  const [propertySelectorVisible, setPropertySelectorVisible] = useState(false);
  const { t } = useTranslation();
  const { events, track } = useTracking();

  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const canGenerateReport = urlContext.getReverseMASelection() || urlContext.getManagedAreaIds().length > 0 || urlContext.getTreeFilterIds().length > 0;

  useEffect(() => {
    const clickListener = (ev: MouseEvent) => {
      if ((ev.target as HTMLElement).closest('#propertySelector') === null) {
        setPropertySelectorVisible(false);
      }
    };
    window.addEventListener('click', clickListener);
    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, []);

  const handleReportGeneration = () => {
    if (canGenerateReport) {
      setPropertySelectorVisible(false);
      urlContext.setReportOpen(true);
      track(events.REPORT_GENERATED_IN_TREE_DROPDOWN);
    }
  };

  const panelLayout = useRef(new PanelLayout());

  const handleShowFilter = () => {
    urlContext.setSidebarOpen(true);
    if (urlContext.getTreeTableOpen()) urlContext.setTreeTableOpen(false);
    setPropertySelectorVisible(false);
    panelLayout.current.openSidebar();
  };

  const properties = useAvailableProperties();

  return (
    <div id="propertySelector" className={styles.propertySelector}>
      <button className={styles.treeButton} onClick={() => setPropertySelectorVisible(prevState => !prevState)}>
        <img src={treeImage} alt="small-tree-logo" />
        <span>{t('treePropertySelector.trees')}</span>
        <span className={styles.treeIcon}>{propertySelectorVisible ? <NavArrowUp /> : <NavArrowDown />}</span>
      </button>
      {propertySelectorVisible &&
        <div className={styles.dropDownContainer}>
          {new AvailableProperties(properties).map((name, properties) => <DropdownList
            name={name}
            list={properties}
            close={() => setPropertySelectorVisible(false)} />)}

          <div className={styles.propertyListTitle} onClick={handleShowFilter}>
            <Filter />
            <div>{t('treePropertySelector.showFilters')}</div>
          </div>
          <div
            className={`${styles.propertyListTitle} ${!canGenerateReport && styles.disabled}`}
            onClick={handleReportGeneration}
          >
            <Reports />
            <div>{t('reporting.generateReport')}</div>
          </div>
        </div>
      }
    </div>
  );
}
