import styles from './Spinner.module.scss';

export default function Spinner({ size = 1, withOverlay = false, inLine = false }) {
  const spinnerSize = 52 * size;

  const spinner = <div
    className={styles.spinner}
    style={{
      width: spinnerSize,
      height: spinnerSize,
      marginTop: inLine ? '' : -(spinnerSize / 2),
      marginLeft: inLine ? '' : -(spinnerSize / 2),
      borderWidth: (spinnerSize / 8),
      position: inLine ? 'static' : 'absolute'
    }}
  />;

  return withOverlay ? <div className={styles.overlay}>{spinner}</div> : spinner;
}
