import { plainToInstance } from 'class-transformer';

export class RuntimeConfig {
  version = '';

  apiUrl = '';

  mapboxApiKey = '';
  projectName = '';
  isNewInfrastructure = false;
}

export default function getRuntimeConfig(): RuntimeConfig {
  return plainToInstance(RuntimeConfig, window['RUNTIME_CONFIG']);
}
