import PropertyConfiguration from '../../../properties/PropertyConfiguration';
import { useTranslation } from 'react-i18next';
import { Tree } from '../../../tree/Tree';
import PropertyColorConfiguration from '../../../properties/PropertyColorConfiguration';
import styles from './DataStandards.module.scss';
import { Edit } from 'iconoir-react';
import { Organization } from '../../../organization/Organization';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';

type DataStandardsRowProps = { config: PropertyConfiguration, handleEditClick: () => void, organization: Organization };

export function DataStandardsRow({ config, handleEditClick, organization }: DataStandardsRowProps) {
  const { t } = useTranslation();
  const unit = Tree.getUnit(config.property, organization);
  const colorMap = PropertyColorConfiguration.getColorsForConfig(config);

  return (
    <li>
      <div className={styles.row}>
        <div className={styles.rowTitle}>
          <span>{t(`tree.${config.property}`) + (unit ? ` [${t(`units.${unit}`)}]` : '')}</span>
          {config.isStandard && <span style={{ opacity: '.3' }}>{t('settings.dataStandards.standard')}</span>}
        </div>
        <div className={styles.colorContainer}>
          <div className={styles.colorContainer}>
            {colorMap.map((color, idx) =>
              (<Tooltip
                key={idx}
                overlay={config.getTooltip(idx)}
              >
                <div className={styles.circle} style={{ backgroundColor: `rgb(${color})` }}></div>
              </Tooltip>))}
          </div>
          <div onClick={handleEditClick} className={styles.edit}><Edit/></div>
        </div>
      </div>
    </li>
  );
}
