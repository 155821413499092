import styles from './TreePropertyFilterEditor.module.scss';
import { Check } from 'iconoir-react';

export function SpeciesRow({
  species,
  onSelect,
  onRemove,
  isSelected
}: { species: string, onSelect: (value: string | null) => unknown, onRemove: (value: string | null) => unknown, isSelected: boolean }) {
  const handleSelect = () => {
    isSelected ? onRemove(species) : onSelect(species);
  };

  return (
    <li
      className={`${styles.speciesItem} ${isSelected && styles.selectedSpecies}`}
      onClick={handleSelect}>
      {isSelected ? <Check/> : <span className={styles.placeholder}/>}
      {species}
    </li>
  );
}
