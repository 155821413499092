import { ReactChild, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './FullscreenModal.module.scss';

export default function FullscreenModal({ children, isVisible, onHide, size = 'fullScreen' }: FullscreenModalProps) {
  if (!isVisible) {
    return <></>;
  }

  const modalClassNames = `${styles.modal} ${styles['size-' + size]}`;

  return (
    <ModalContainer onHide={onHide}>
      <>
        <div className={styles.overlay} onClick={onHide} />

        <div
          role="dialog"
          aria-labelledby="modal-title"
          className={modalClassNames}
          data-testid="modal"
        >
          {children}
        </div>
      </>
    </ModalContainer>
  );
}

function ModalContainer({ children, onHide }: { children: ReactChild, onHide: () => unknown }) {
  const [container] = useState(() => document.createElement('div'));
  container.classList.add(styles.container);

  const closeOnEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onHide();
  };

  useEffect(() => {
    document.body.appendChild(container);
    container.tabIndex = 0;
    container.focus();
    container.addEventListener('keyup', closeOnEscapeKey);

    return () => {
      container.removeEventListener('keyup', closeOnEscapeKey);
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
}

interface FullscreenModalProps {
  children: ReactNode,
  isVisible: boolean,
  onHide: () => unknown,
  size?: 'fullScreen'
}
