import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import { FunctionButton } from '../../../../components/UI/Button/LegacyButton';
import { Trash } from 'iconoir-react';
import React from 'react';
import DetailedTree from '../../../../tree/DetailedTree';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../../account/useAccounts';

export default function NumberOfLimbs ({ tree, editing, handleChange }: NumberOfLimbsProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();

  return (<li key={DisplayableTreeProperty.NumberOfLimbs} className="w-full px-3 pt-4 pb-2 flex flex-col gap-2">
    <div className="flex gap-2 items-center">
      <span className="w-1/3 font-normal text-outer-space-200">
        {t('details.properties.' + DisplayableTreeProperty.NumberOfLimbs)}
      </span><span className="w-2/3 font-medium">
        {tree?.limbs.length || <span className="text-outer-space-500">-</span>}
      </span>
    </div>
    <ul className="flex flex-col gap-2">
      {tree?.limbs.map((limb, i) => (
        <li key={i} className="flex gap-2 items-center">
          <span className="w-1/3 font-normal flex items-center justify-between">
            <span className="text-outer-space-200">
              <span className="capitalize">{t('details.properties.limb')}</span>
              <span> #{i + 1}</span>
            </span>
            {editing && <FunctionButton
              className="px-2 py-2 m-0"
              onClick={() => handleChange('limbs', tree?.limbs.filter((_, index) => index !== i))}
            >
              <Trash fontSize={12} />
            </FunctionButton>}
          </span>
          <span className="w-2/3 font-medium flex gap-2 items-baseline">
            {editing ? <input
              name={'limb' + (i + 1)}
              className={'w-7/12 bg-outer-space-950 rounded-[4px] px-2 py-2 text-outer-space-200'}
              type="number"
              step={1}
              min={0}
              value={tree?.limbs[i].diameter}
              onChange={event => handleChange('limbs', tree?.limbs.map((limb, index) => index === i ? { diameter: Number(event.target.value) } : limb))}
            />
              : <>{limb.diameter}</>}
            <span className="text-[13px] text-outer-space-200 font-normal">
              {Tree.getUnit(DisplayableTreeProperty.LimbDiameter, organization)}
            </span>
          </span>
        </li>
      ))}
    </ul>
    {editing && <div className="flex justify-end">
      <div className="w-2/3 justify-self-end">
        <FunctionButton
          className="px-2 py-2 m-0"
          onClick={() => handleChange('limbs', [...(tree?.limbs || []), { diameter: undefined }])}>
          {t('treeDetails.dataCollection.add')}
        </FunctionButton>
      </div>
    </div>}
  </li>);
}

interface NumberOfLimbsProps {
  tree: DetailedTree | null,
  editing: boolean,
  handleChange: <T>(property: string, value: T) => void
}
