import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import styles from './PointCloudViewer.module.scss';
import * as THREE from 'three';
import { Tree } from '../../tree/Tree';
import { PointCloudView } from './PointCloudView';
import { LoadablePointCloud } from '../../point-cloud/LoadablePointCloud';
import { MultiOrbitControl } from './MultiOrbitControl';
import { useCurrentAccount } from '../../account/useAccounts';
import { METER_TO_FEET } from './unitConstants';

export default function LegacyPointCloudViewer(props: PointCloudViewerProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const viewRef = useRef(new PointCloudView());
  const treePointCloud = useRef<THREE.Group>(new THREE.Group());
  const environmentPointCloud = useRef<THREE.Group>(new THREE.Group());
  const account = useCurrentAccount();

  const [isViewInitialized, setIsViewInitialized] = useState(false);

  useEffect(() => {
    if (canvasRef.current === null || props.multiControlsRef.current === null) return;

    const view = viewRef.current;
    view.init(canvasRef.current, props.multiControlsRef.current);

    setIsViewInitialized(true);

    return () => {
      view.dispose();
    };
  }, [canvasRef.current, props.multiControlsRef.current]);

  useEffect(() => {
    if (!props.tree) return;
    const halfHeight = account.organization.getIsMetrical()
      ? props.tree.height / 2
      : props.tree.height / METER_TO_FEET / 2;
    viewRef.current.resetTo(halfHeight, props.tree.canopyDirection);
  }, [isViewInitialized, props.tree, props.seed, account.organization]);

  const [isPointCloudLoading, setIsPointCloudLoading] = useState(true);
  const [tree, setTree] = useState<Tree | null>(null);
  useEffect(() => {
    if (props.tree === null) return;

    setTree(props.tree);

    const environmentPointSize = 1.6;
    const pos = props.tree!.localizedLocation;
    Promise.all([
      new LoadablePointCloud(props.tree!.getPointCloudUrl(account.organization))
        .loadInto(pos, false)
        .then(pc => treePointCloud.current.add(pc))
        .then(() => setIsPointCloudLoading(false)),
      new LoadablePointCloud(props.tree!.getEnvironmentPointCloudUrl(account.organization), environmentPointSize)
        .loadInto(pos, true)
        .then(pc => environmentPointCloud.current.add(pc))
        .then(viewRef.current?.render)
    ]).then();
  }, [props.tree, account.organization]);

  useEffect(() => {
    if (tree === null || isPointCloudLoading || !isViewInitialized) return;

    viewRef.current.addEventListeners();
    viewRef.current.clear();
    viewRef.current.addPointClouds(
      props.showEnvironment ? [treePointCloud.current, environmentPointCloud.current] : [treePointCloud.current]
    );
    viewRef.current.addGrid();
    viewRef.current.setCanvasSize();

    return () => {
      viewRef.current.removeEventListeners();
    };
  }, [isPointCloudLoading, isViewInitialized, tree, props.showEnvironment]);

  return (
    <div className={styles.container}>
      <canvas ref={canvasRef} className={styles.canvas} />

      {isPointCloudLoading && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
}

interface PointCloudViewerProps {
  tree: Tree | null,
  showEnvironment: boolean,
  multiControlsRef: MutableRefObject<MultiOrbitControl>,
  seed: string
}
