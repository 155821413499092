import FilterPredicate, { PredicateType } from './FilterPredicate';
import PropertyConfigurationPredicateDto from './dto/PropertyConfigurationPredicateDto';
import { DisplayableTreeProperty, Tree } from '../tree/Tree';
import PropertyConfiguration from '../properties/PropertyConfiguration';
import { Account } from '../account/Account';

export default class PropertyConfigurationPredicate implements FilterPredicate {
  static fromDto(dto: PropertyConfigurationPredicateDto) {
    return new PropertyConfigurationPredicate(dto.rangeIndices, PropertyConfiguration.fromDto(dto.propertyConfiguration));
  }

  constructor(
    readonly rangeIndices: number[],
    readonly propertyConfiguration: PropertyConfiguration
  ) {}

  get property() {
    return this.propertyConfiguration.property;
  }

  apply(tree: Tree, account?: Account): boolean {
    const property = this.property === DisplayableTreeProperty.SafetyFactors
      ? Tree.getDefaultSafetyFactorProperty(account)
      : this.property;
    const value = tree[property];
    if (typeof value !== 'number') return false;

    const ranges = this.rangeIndices.map(index => this.propertyConfiguration.ranges[index]);
    if (ranges.some(range => range === undefined)) return false;

    return ranges.some(range => range.from <= value && range.to > value);
  }

  toDto(): PropertyConfigurationPredicateDto {
    return {
      propertyConfiguration: this.propertyConfiguration,
      rangeIndices: this.rangeIndices
    };
  }

  get type(): PredicateType {
    return PredicateType.PROPERTY_CONFIGURATION;
  }
}
