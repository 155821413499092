import { createContext, useMemo } from 'react';
import TreeService from './tree/TreeService';
import api from './services/api';
import TreeFilterService from './tree-filter/TreeFilterService';
import { AccountService } from './account/AccountService';
import { PropertyConfigurationService } from './properties/PropertyConfigurationService';
import GenusService from './tree/GenusService';
import { UrlContext } from './routes/Explore/UrlContext';
import { useSearchParams } from 'react-router-dom';
import TestUrlContext from './routes/Explore/TestUrlContext';
import { ManagedAreaService } from './managed-area/ManagedAreaService';
import OrganizationService from './organization/OrganizationService';
import CapturePointService from './capture-point/CapturePointService';
import AddressService from './services/AddressService';
import CohortService from './tree/CohortService';
import TranslationService from './services/TranslationService';
import JobService from './components/Settings/organizations/JobService';
import { AuditLogService } from './routes/SuperOwnerPage/AuditLogService';
import { TaskManagerService } from './task-manager/TaskManagerService';
import { useQueryClient } from 'react-query';

const DependencyInjectionContext = createContext({} as Injectables);

export const DependencyInjectionContextProvider = ({ children }) => {
  const [, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const value = useMemo(() => ({
    treeService: new TreeService(api, queryClient),
    organizationService: new OrganizationService(api),
    capturePointService: new CapturePointService(api),
    treeFilterService: new TreeFilterService(api),
    accountService: new AccountService(api),
    propertyConfigurationService: new PropertyConfigurationService(api),
    genusService: new GenusService(api),
    cohortService: new CohortService(api),
    managedAreaService: new ManagedAreaService(api),
    addressService: new AddressService(api),
    translationService: new TranslationService(api),
    urlContext: new UrlContext(setSearchParams),
    jobService: new JobService(api),
    auditLogService: new AuditLogService(api),
    taskManagerService: new TaskManagerService(api, queryClient)
  }), [setSearchParams]);

  return (
    <DependencyInjectionContext.Provider value={value}>
      { children }
    </DependencyInjectionContext.Provider>
  );
};

export const DependencyInjectionContextTestProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = {
    treeService: {} as TreeService,
    organizationService: {} as OrganizationService,
    capturePointService: {} as CapturePointService,
    treeFilterService: {} as TreeFilterService,
    accountService: {} as AccountService,
    propertyConfigurationService: {} as PropertyConfigurationService,
    genusService: {} as GenusService,
    cohortService: {} as CohortService,
    managedAreaService: {} as ManagedAreaService,
    addressService: {} as AddressService,
    translationService: {} as TranslationService,
    urlContext: new TestUrlContext(searchParams, setSearchParams),
    jobService: {} as JobService,
    auditLogService: {} as AuditLogService,
    taskManagerService: {} as TaskManagerService
  };

  return (
    <DependencyInjectionContext.Provider value={value}>
      { children }
    </DependencyInjectionContext.Provider>
  );
};

export type Injectables = {
  treeService: TreeService,
  organizationService: OrganizationService,
  capturePointService: CapturePointService,
  treeFilterService: TreeFilterService,
  accountService: AccountService,
  propertyConfigurationService: PropertyConfigurationService,
  genusService: GenusService,
  cohortService: CohortService,
  managedAreaService: ManagedAreaService,
  addressService: AddressService,
  translationService: TranslationService,
  urlContext: UrlContext,
  jobService: JobService,
  auditLogService: AuditLogService,
  taskManagerService: TaskManagerService
};

export default DependencyInjectionContext;
