import { AirPollutionRemoval, Quantity } from '../ReportData';
import ReportSection from './ReportSection';
import { useTranslation } from 'react-i18next';
import styles from './AirPollutionSection.module.scss';
import reportStyles from '../ReportDialog.module.scss';
import { NumericFormat } from '../../../../numeric-format/NumericFormat';

type AirPollutionSectionParams = { data: AirPollutionRemoval, containerId: string, printable?: boolean };
export default function AirPollutionSection(props: AirPollutionSectionParams) {
  const { t } = useTranslation();
  const tt = postfix => t(`reporting.airPollution.${postfix}`);

  return (
    <ReportSection containerId={props.containerId} printable={props.printable}>
      <h2 className={styles.header}>{tt('title')}</h2>

      <table className={`${styles.table} ${styles.benefit} ${props.printable ? styles.printableTable : ''}`}>
        <tbody>
          <BenefitRow
            label={tt('co')}
            data={props.data.co}
          />
          <BenefitRow
            label={tt('no2')}
            data={props.data.no2}
          />
          <BenefitRow
            label={tt('o3')}
            data={props.data.o3}
          />
          <BenefitRow
            label={tt('pm25')}
            data={props.data.pm25}
          />
          <BenefitRow
            label={tt('so2')}
            data={props.data.so2}
          />
        </tbody>
      </table>
    </ReportSection>
  );
}

function BenefitRow(props: { label: string, data: { quantity: Quantity, monetaryValue: Quantity } }) {
  return (
    <tr>
      <td>{props.label}</td>
      <Cell data={props.data.quantity} />
    </tr>
  );
}

function Cell(props: { currency?: string, data: Quantity }) {
  const { t } = useTranslation();
  return (
    <>
      { props.currency ?
        <td className={styles.numeric}>
          {NumericFormat.currency(props.data.unit).format(props.data.value)}
        </td> :
        <>
          <td className={reportStyles.cellValue}>{NumericFormat.iso(props.data.unit, navigator.language, t).getFormattedValue(props.data.value)}</td>
          <td className={reportStyles.cellUnit}>{NumericFormat.iso(props.data.unit, navigator.language, t).getUnit()}</td>
        </>}
    </>
  );
}
