import { AdvancedFilterConfiguration, AdvancedFilterType, SigmaBoundary } from './AdvancedFilter';
import { PropertyRange } from '../../../../properties/PropertyConfiguration';
import { TrackableTreeProperty } from '../../../../tree/TrackableTreeProperty';
import { DisplayableTreeProperty } from '../../../../tree/Tree';

export enum AdvancedFilterPredicateType {
  ENUM = 'enum',
  PROPERTYCONFIGS = 'propertyConfigs',
  NUMERIC = 'numeric',
  COHORT = 'cohort'
}

export class AdvancedFilterConfigurationBuilder {
  static from(config: AdvancedFilterConfiguration | null = null): AdvancedFilterConfigurationBuilder {
    if (!config) return new AdvancedFilterConfigurationBuilder({
      includeOnly: [],
      min: [],
      max: [],
      propertyConfigs: [],
      cohort: []
    });
    return new AdvancedFilterConfigurationBuilder(config);
  }

  private readonly includeOnlyMap: Map<string, string[]> = new Map();
  private readonly minMap: Map<string, number> = new Map();
  private readonly maxMap: Map<string, number> = new Map();
  private readonly propertyConfigMap: Map<string, {
    ranges: { from: number, to: number }[],
    indices: number[]
  }> = new Map();
  private readonly cohortMap: Map<string, { property: string, sigmas: SigmaBoundary[] }> = new Map();

  private constructor(config: AdvancedFilterConfiguration) {
    for (const [property, values] of config.includeOnly) {
      this.includeOnlyMap.set(property, values);
    }

    for (const [property, value] of config.min) {
      this.minMap.set(property, value);
    }

    for (const [property, value] of config.max) {
      this.maxMap.set(property, value);
    }

    for (const propertyConfig of config.propertyConfigs) {
      this.propertyConfigMap.set(propertyConfig.property, {
        ranges: propertyConfig.ranges,
        indices: propertyConfig.rangeIndices
      });
    }

    for (const { property, sigmas } of config.cohort) {
      this.cohortMap.set(property, { property, sigmas });
    }
  }

  addPropertyConfig(property: string, ranges: PropertyRange[], rangeIndices: number[]): this {
    this.propertyConfigMap.set(property, {
      ranges: ranges.map(it => ({ from: it.from, to: it.to })),
      indices: Array.from(new Set(rangeIndices))
    });

    return this;
  }

  editPropertyConfig(property: string, idx: number): this {
    const selectedPropertyIndices = this.propertyConfigMap.get(property)?.indices || [];
    const index = selectedPropertyIndices.indexOf(idx) ?? -1;

    if (index > -1) {
      selectedPropertyIndices.splice(index, 1);
      if (selectedPropertyIndices.length === 0) {
        this.propertyConfigMap.delete(property);
      }
    } else {
      selectedPropertyIndices.push(idx);
    }

    return this;
  }

  removePropertyConfig(property: string): this {
    this.propertyConfigMap.delete(property);
    return this;
  }

  addCohort(property: TrackableTreeProperty, sigmas: SigmaBoundary[]): this {
    this.cohortMap.set(property, { property, sigmas });
    return this;
  }

  editCohort(property: TrackableTreeProperty, boundary: SigmaBoundary): this {
    const selectedBoundaries = Array.from(this.cohortMap.values()).map(it => it.sigmas).flat();

    if (selectedBoundaries.includes(boundary)) {
      const newBoundaries = selectedBoundaries.filter(it => it !== boundary) as SigmaBoundary[];
      this.removeCohort(property);
      this.removeProperty(property);
      if (newBoundaries.length) {
        this.addCohort(property, newBoundaries);
      } else {
        this.cohortMap.delete(property);
      }
    } else {
      this.addCohort(property, [...selectedBoundaries, boundary]);
    }

    return this;
  }

  removeCohort(property: string): this {
    this.cohortMap.delete(property);
    return this;
  }

  addIncludeOnly(property: string, values: string[]): this {
    this.includeOnlyMap.set(property, Array.from(new Set(values)));
    return this;
  }

  removeIncludeOnly(property: string): this {
    this.includeOnlyMap.delete(property);
    return this;
  }

  addMin(property: string, value: number): this {
    this.minMap.set(property, value);
    return this;
  }

  removeMin(property: string): this {
    this.minMap.delete(property);
    return this;
  }

  addMax(property: string, value: number): this {
    this.maxMap.set(property, value);
    return this;
  }

  removeMax(property: string): this {
    this.maxMap.delete(property);
    return this;
  }

  removeProperty(property: string): this {
    return this.removeMin(property).removeMax(property).removeIncludeOnly(property).removePropertyConfig(property).removeCohort(property);
  }

  deleteAll(): this {
    this.includeOnlyMap.clear();
    this.minMap.clear();
    this.maxMap.clear();
    this.propertyConfigMap.clear();
    this.cohortMap.clear();

    return this;
  }

  listAllActiveProperties(): string[] {
    return Array.from(this.minMap.keys())
      .concat(Array.from(this.maxMap.keys()))
      .concat(Array.from(this.includeOnlyMap.keys()))
      .concat(Array.from(this.propertyConfigMap.keys()))
      .concat(Array.from(this.cohortMap.keys()));
  }

  build(): AdvancedFilterConfiguration {
    return {
      includeOnly: Array.from(this.includeOnlyMap.entries()),
      min: Array.from(this.minMap.entries()),
      max: Array.from(this.maxMap.entries()),
      propertyConfigs: Array.from(this.propertyConfigMap.entries())
        .map(([key, value]) => ({ property: key, ranges: value.ranges, rangeIndices: value.indices })),
      cohort: Array.from(this.cohortMap.entries()).map(([key, value]) => value)
    };
  }
}

export class AdvancedFilterByProperty {
  static fromAdvancedFilterConfiguration(config: AdvancedFilterConfiguration): AdvancedFilterByProperty[] {
    const obj = {};
    config.min.forEach(filter => {
      if (obj[filter[0]] === undefined) obj[filter[0]] = {
        min: null,
        max: null,
        includeOnly: [],
        ranges: [],
        sigmaBoundaries: []
      };

      obj[filter[0]].min = filter[1];
    });
    config.max.forEach(filter => {
      if (obj[filter[0]] === undefined) obj[filter[0]] = {
        min: null,
        max: null,
        includeOnly: [],
        ranges: [],
        sigmaBoundaries: []
      };

      obj[filter[0]].max = filter[1];
    });

    config.includeOnly.forEach(filter => {
      if (obj[filter[0]] === undefined) obj[filter[0]] = {
        min: null,
        max: null,
        includeOnly: [],
        ranges: [],
        sigmaBoundaries: []
      };

      obj[filter[0]].includeOnly = filter[1];
    });

    config.propertyConfigs.forEach(filter => {
      if (obj[filter.property] === undefined) obj[filter.property] = {
        min: null,
        max: null,
        includeOnly: [],
        ranges: [],
        sigmaBoundaries: []
      };

      obj[filter.property].ranges = filter.rangeIndices;
    });

    config.cohort.forEach(filter => {
      if (obj[filter.property] === undefined) obj[filter.property] = {
        min: null,
        max: null,
        includeOnly: [],
        ranges: [],
        sigmaBoundaries: []
      };

      obj[filter.property].sigmaBoundaries = filter.sigmas;
    });

    return Object.keys(obj).map(key => new AdvancedFilterByProperty(
      key,
      obj[key].min,
      obj[key].max,
      obj[key].includeOnly,
      obj[key].ranges,
      obj[key].sigmaBoundaries
    ));
  }

  static getPredicateType(type: AdvancedFilterType): AdvancedFilterPredicateType {
    if ([AdvancedFilterType.RANGE, AdvancedFilterType.MIN, AdvancedFilterType.MAX].includes(type)) {
      return AdvancedFilterPredicateType.NUMERIC;
    }

    if (type === AdvancedFilterType.INCLUDE_ONLY) return AdvancedFilterPredicateType.ENUM;
    if (type === AdvancedFilterType.COHORT) return AdvancedFilterPredicateType.COHORT;

    return AdvancedFilterPredicateType.PROPERTYCONFIGS;
  }

  constructor(
    readonly property: string,
    readonly min: number | null,
    readonly max: number | null,
    readonly includeOnly: string[],
    readonly ranges: number[],
    readonly sigmaBoundaries: SigmaBoundary[]
  ) {
  }

  getType(): AdvancedFilterType {
    if (this.ranges.length > 0) {
      return AdvancedFilterType.PROPERTYCONFIGS;
    }
    if (this.sigmaBoundaries.length > 0) {
      return AdvancedFilterType.COHORT;
    }
    if (this.isNumeric()) {
      if (this.min !== null && this.max !== null) return AdvancedFilterType.RANGE;
      if (this.min !== null) return AdvancedFilterType.MIN;
      if (this.max !== null) return AdvancedFilterType.MAX;
    }
    return AdvancedFilterType.INCLUDE_ONLY;
  }

  isNumeric() {
    return !['species', 'managedAreaId', DisplayableTreeProperty.Status, DisplayableTreeProperty.VitalityVigor].includes(this.property);
  }
}
