import styles from '../../Inventory/BaseAttributes.module.scss';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import { Fork, TreeDto } from '../../../../../tree/Tree';
import DetailedTree from '../../../../../tree/DetailedTree';
import DataGroupHeader from '../../components/DataGroupHeader/DataGroupHeader';
import Dropdown, { Item } from '../../../../../components/UI/Dropdown/Dropdown';
import EditingFieldWrapper from '../../components/EditingFieldWrapper/EditingFieldWrapper';
import { nullableBooleanItems } from '../../DaveyDataPanel';

export default function LimbsDataGroup(props: LimbsDataGroupProps) {
  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);
  const [editing, setEditing] = useState(false);

  const initialLimbs = {
    limbs: props.tree.limbs,
    coDominantLimbs: props.tree.coDominantLimbs,
    includedBark: props.tree.includedBark,
    fork: props.tree.fork
  };

  const [limbs, setLimbs] = useState(initialLimbs);

  const forkOptions: Item[] = Object.values(Fork).map(option => {
    return { id: option, translationKey: 'details.properties.forkTypes.' + option };
  });
  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(limbs).forEach(key => {
      if (limbs[key] !== props.tree[key] && limbs[key] !== '') {
        updatedValues[key] = limbs[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(props.organizationId, props.tree.id, updatedValues);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setLimbs(initialLimbs);
  };

  return (
    <div className={styles.dataGroup}>
      <DataGroupHeader
        editing={editing}
        setEditing={() => setEditing(true)}
        handleCancel={handleCancel}
        handleSave={handleSave}
        title={t('treeDetails.tabs.subTitles.limbs')}
      />
      <div className={`${styles.flexContainer}`}>
        <div className={props.singleColumn ? styles.singleColumn : styles.accordionPairsContainer}>
          <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
            <EditingFieldWrapper
              title={t('details.properties.numberOfLimbs')}
              value={limbs.limbs.length || '-'}
              editing={editing}
            />
            <EditingFieldWrapper
              title={t('details.properties.coDominantLimbs')}
              value={t(`${nullableBooleanItems.find(it => it.id === limbs.coDominantLimbs?.toString() || '')?.translationKey || ''}`) as string}
              editing={editing}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={nullableBooleanItems}
                value={nullableBooleanItems.find(it => it.id === limbs.coDominantLimbs?.toString() || '')}
                onSelect={item => setLimbs(prev =>
                  ({ ...prev, coDominantLimbs: item.id === '' ? null : item.id === 'true' }))
                }
              />
            </EditingFieldWrapper>
          </div>
          <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
            <EditingFieldWrapper
              title={t('details.properties.fork')}
              value={t(`${forkOptions.find(it => it.id === limbs.fork || '')?.translationKey || ''}`) as string}
              editing={editing}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={forkOptions}
                value={forkOptions.find(it => it.id === limbs.fork || '')}
                onSelect={item => setLimbs(prev =>
                  ({ ...prev, fork: item.id as Fork }))
                }
              />
            </EditingFieldWrapper>
            <EditingFieldWrapper
              title={t('details.properties.includedBark')}
              value={t(`details.properties.booleanLabels.${props.tree.includedBark ? 'true' : 'false'}`) as string}
              editing={editing}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={nullableBooleanItems}
                value={{ translationKey: `details.properties.booleanLabels.${props.tree.includedBark ? 'true' : 'false'}` }}
              />
            </EditingFieldWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

interface LimbsDataGroupProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organizationId: string
}
