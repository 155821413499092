import { AxiosInstance } from 'axios';

export type LanguageInfo = {
  code: string,
  statistics: {
    progress: number
  }
};
export default class TranslationService {
  constructor(private readonly http: AxiosInstance) {}

  async getLanguagesForTranslator() {
    const response = await this.http.get('/v1/translations/translator/languages');
    return response.data;
  }

  async updateTranslation(language: string, dto: { key: string, value: string }) {
    return await this.http.patch(`/v1/translations/${language}`, dto);
  }

  async getAvailableLanguages(): Promise<LanguageInfo[]> {
    const response = await this.http.get('/v1/translations/languages');
    return response.data;
  }

  async getLanguage(language: string) {
    const response = await this.http.get(`/v1/translations/${language}`);
    return response.data;
  }
}
