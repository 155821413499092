import { TreeFilter } from '../../../../tree-filter/TreeFilter';
import styles from './TreeFilterCard.module.scss';
import { Xmark, Edit, Group, Label, Trash } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import Modal from '../../../Modal/Modal';
import { useState } from 'react';
import { FunctionButton } from '../../../UI/Button/LegacyButton';
import { usePropertyConfigurations } from '../../../../properties/usePropertyConfigurations';
import PropertyColorConfiguration from '../../../../properties/PropertyColorConfiguration';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { EntityKey, EntityStorage } from '../../../../EntityStorage';
import { User } from '../../../../users/User';
import { AccountType } from '../../../../account/AccountType';

export default function TreeFilterCard({ treeFilter, unselect, select, deleteFilter, inSelection, setFilterToEdit }: TreeFilterCardProps) {
  const { t } = useTranslation();
  const propertyConfigurations = usePropertyConfigurations();
  const { organization, type: accountType } = useCurrentAccount();

  const openSection = () => !inSelection ? select() : unselect();

  const propertyTitle = property => `${t(`tree.${property}`)} [${Tree.getUnit(property as DisplayableTreeProperty, organization)}]`;

  const [deleting, setDeleting] = useState(false);

  const getRange = (min, max) => {
    if (min && max) return `${min}-${max}`;
    if (min) return min;
    if (max) return max;
  };
  const userCache = EntityStorage.local(EntityKey.LoggedInUser, User);
  const user = userCache.getOrElse(User.anonymous());
  const canEdit = accountType === AccountType.Admin || accountType === AccountType.Owner || treeFilter.userId === user.id;

  return (<li
    className={`${styles.filterContainer} ${inSelection && styles.open}`}
    onClick={openSection}>
    <div className={styles.filterRow}>
      <div className={styles.filterLabel}>
        {treeFilter.isPrivate ? <Label /> : <Group />}
        {treeFilter.getName()}
      </div>
      {canEdit && <div className={styles.functionButtonContainer}>
        <Edit onClick={() => setFilterToEdit()} />
        <Trash onClick={() => setDeleting(true)} />
      </div>}
    </div>
    <section className={styles.selectedFilter}>
      <div className={styles.speciesContainer}>
        <span className={styles.speciesTitle}>{t('sidebarFilter.species')}</span>
        {treeFilter.enumPredicates.length > 0 ? <ul>
          {treeFilter.enumPredicates.map(enumPredicate => (
            enumPredicate.toDto().values.map(it => <li key={it} className={styles.species}>{it}</li>)
          ))}
        </ul> : <div className={styles.species}>{t('sidebarFilter.allSpecies')}</div>
        }
      </div>
      {treeFilter.numericPredicates.length > 0 &&
        <div className={styles.propertiesContainer}>
          <span className={styles.propertiesTitle}>{t('sidebarFilter.properties')}</span>
          {treeFilter.numericPredicates.map(numericPredicate => {
            return (
              <ul key={`numeric-${numericPredicate.property}`}>
                <li className={styles.propertyTitle}>{propertyTitle(numericPredicate.property)}</li>
                <li className={styles.propertyValueContainer}>
                  <div className={styles.propertyScaleType}>{t(`sidebarFilter.${numericPredicate.getType()}`)}</div>
                  <div>{getRange(numericPredicate.min?.toFixed(2), numericPredicate.max?.toFixed(2))}</div>
                </li>
              </ul>
            );
          })}
        </div>
      }
      <div className={styles.propertiesContainer}>
        {propertyConfigurations.isLoading ? <></> : treeFilter.propertyConfigurationPredicates.map(rangePredicate => {
          return (
            <ul key={`range-${rangePredicate.property}`}>
              <li className={styles.propertyTitle}>{propertyTitle(rangePredicate.property)}</li>

              <ul>
                {rangePredicate.rangeIndices.map((range, idx) => {
                  const propertyConfig = propertyConfigurations.data!.find(config => config.property === rangePredicate.property);
                  if (!propertyConfig) return <></>;
                  const colorMap = PropertyColorConfiguration.getColorsForConfig(propertyConfig);
                  const currentRange = propertyConfig.ranges[range];
                  if (!currentRange) return <></>;
                  return (
                    <li className={styles.rangeRow} key={`range-row-${idx}`}>
                      <div className={styles.circleAndLabel}>
                        <div className={styles.circle} style={{ backgroundColor: `rgb(${colorMap[range]})` }} />
                        <div className={styles.capitalizeFirstLetter}>{currentRange.name}</div>
                      </div>
                      <div className={styles.minMaxContainer}>
                        {currentRange.from.toFixed(2)}
                        -
                        {currentRange.to.toFixed(2)}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </ul>
          );
        })}
      </div>
    </section>
    <Modal
      className={styles.modal}
      isVisible={deleting}
      onHide={() => setDeleting(false)}>
      <div className={styles.modalTitle}>
        {t('sidebarFilter.deleteTreeFilter')}
        <FunctionButton icon={<Xmark/>} onClick={() => setDeleting(false)}/>
      </div>
      <div className={styles.modalDescription}>{t('sidebarFilter.deleteDescription', { name: treeFilter.getName() })}</div>
      <div className={styles.deleteModalControls}>
        <button onClick={() => setDeleting(false)}>{t('sidebarFilter.cancel')}</button>
        <button onClick={() => deleteFilter()} className={styles.deleteButton}>{t('sidebarFilter.deleteTreeFilter')}<Trash/></button>
      </div>
    </Modal>
  </li>);
}

interface TreeFilterCardProps {
  treeFilter: TreeFilter,
  unselect: () => void,
  select: () => void,
  deleteFilter: () => void,
  inSelection: boolean,
  setFilterToEdit: () => void
}
