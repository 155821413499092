import LegacyPageLayout from '../../components/UI/Page/LegacyPageLayout';
import useAccounts, { useCurrentAccount } from '../../account/useAccounts';
import { Link, Navigate, Route, Routes, useMatch, useSearchParams } from 'react-router-dom';
import Spinner from '../../components/UI/Spinner/Spinner';
import useTree, { useTreeStatistics } from '../../tree/useTree';
import styles from './Analytics.module.scss';
import { useTranslation } from 'react-i18next';
import {
  ArrowLeft,
  CloudXmark,
  Divide,
  DropletHalf,
  EuroSquare,
  Farm,
  GraphUp,
  InfoCircle,
  RulerArrows,
  PanoramaReduce,
  PharmacyCrossCircle,
  ShieldCheck,
  ViewColumns2
} from 'iconoir-react';
import GeneralTreeAnalytics from './General';
import DimensionAnalytics from './Dimensions';
import SafetyAnalytics from './Safety';
import HealthAnalytics from './Health';
import EcologicalAnalytics from './EcologicalBenefits';
import EconomicalAnalytics from './EconomicalValue';
import Select from '../../components/Settings/Select/Select';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import Switch from '../../components/Switch/Switch';
import Gallery from './components/Gallery';
import { AVAILABLE_DATES } from './fakes';
import { DisplayableTreeProperty } from '../../tree/Tree';
import { TreeStatistics } from '../../tree/TreeStatistics';
import { DetailsBackUrl } from '../../utils/DetailsBackUrl';
import { CreateJobButton } from './components/Page';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { AnalyticsView } from './AnalyticsView';

export type DetailsButtonHelpers = {
  showDetails: boolean,
  showExtraDetails: boolean,
  setDetailsVisibility: Dispatch<SetStateAction<boolean>>,
  setExtraDetailsVisibility: Dispatch<SetStateAction<boolean>>,
  toggleDetails: () => unknown,
  toggleExtraDetails: () => unknown
};

enum Page {
  General = 'general',
  Dimensions = 'dimensions',
  Safety = 'safety',
  Health = 'health',
  EcologicalBenefits = 'ecological-benefits',
  EconomicalValue = 'economical-value'
}

export default function TreeAnalytics() {
  const { treeFilterService } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const match = useMatch('/organizations/:organizationId/trees/:treeId/analytics/:page');
  const organizationId = match?.params.organizationId ?? '';
  const treeId = match?.params.treeId ?? '';
  const page = Object.values(Page).find(it => it === match?.params.page ?? Page.General) ?? Page.General;

  const { isLoadingAccounts } = useAccounts();
  const currentAccount = useCurrentAccount();
  const { tree, isTreeLoading } = useTree(organizationId, treeId);
  const { statistics, isLoadingStatistics } = useTreeStatistics(organizationId, treeId);

  const [showDetails, setDetailsVisibility] = useState(false);
  const [showExtraDetails, setExtraDetailsVisibility] = useState(false);
  const toggleDetails = () => setDetailsVisibility(state => !state);
  const toggleExtraDetails = () => setExtraDetailsVisibility(state => !state);

  const analyticsView =
    Object.values(AnalyticsView).find(it => it === searchParams.get('view') ?? AnalyticsView.Twin) ??
    AnalyticsView.Twin;
  const setAnalyticsView = (view: AnalyticsView) => {
    const updated = new URLSearchParams(searchParams);
    updated.set('view', view);
    setSearchParams(updated);
  };
  const [reference, setReference] = useState('all');
  const onReferenceChange = (reference: string | null) => setReference(reference ?? 'all');
  const [treeFilters, setTreeFilters] = useState<TreeFilter[]>([]);
  useEffect(() => {
    treeFilterService.list(organizationId).then(setTreeFilters);
  }, [organizationId]);
  const referenceOptions = [{ label: t('analytics.references.allTrees'), value: 'all' }].concat(
    treeFilters.map(it => ({ label: it.name, value: it.id }))
  );

  useEffect(() => {
    setDetailsVisibility(analyticsView === AnalyticsView.Panorama);
  }, [analyticsView]);

  if (!isLoadingAccounts && !currentAccount.exists()) {
    return <Navigate to="/"/>;
  }

  if (isLoadingAccounts || isTreeLoading || isLoadingStatistics) {
    return <Loader/>;
  }

  if (tree === null || statistics === null) {
    return <EmptyState/>;
  }

  const backUrl = DetailsBackUrl.restore(tree, organizationId);

  const isInComparisonMode = searchParams.has('comparison');
  const toggleComparisonMode = (isOn: boolean) => {
    if (isOn) {
      const updated = new URLSearchParams(searchParams);
      updated.set('comparison', '1');
      setSearchParams(updated);
    } else {
      const updated = new URLSearchParams(searchParams);
      updated.delete('comparison');
      setSearchParams(updated);
    }
  };
  const galleryItems = (() => {
    return (tree?.images ?? []).slice(0, AVAILABLE_DATES.length).map((it, i) => ({
      url: it.getRotatedUrl(),
      previewUrl: it.getThumbnailUrl(),
      isActive: i === 0,
      capturedAt: AVAILABLE_DATES.at(i)!,
      group: AVAILABLE_DATES.at(i)!.getFullYear().toString()
    }));
  })();

  const detailsButtonHelpers = {
    showDetails,
    showExtraDetails,
    setDetailsVisibility,
    setExtraDetailsVisibility,
    toggleDetails,
    toggleExtraDetails
  };

  function getNavigation() {
    return <LegacyPageLayout.LeftNavExtensions>
      <div className={styles.backButtonWrapper}>
        <div className={styles.backButtonContainer}>
          <Link to={backUrl} className={styles.backButton}>
            <ArrowLeft />
          </Link>

          <div>
            <strong>{tree!.externalId}</strong>
          </div>
        </div>
      </div>
    </LegacyPageLayout.LeftNavExtensions>;
  }

  return (
    <LegacyPageLayout>
      {getNavigation()}

      <LegacyPageLayout.Content>
        <div className={styles.wrapper}>
          <div className={styles.analytics}>
            <aside className={styles.aside}>
              <div>
                <AnalyticsPageNavigation
                  onViewChange={setAnalyticsView}
                  view={analyticsView}
                  page={page}
                  showSafetyAlert={tree.isUnsafe()}
                  isSafetyFactorAvailable={Boolean(tree.safetyFactorAtDefaultWindSpeed)}
                />
              </div>

              <div className={styles.galleryContainer}>
                <Gallery images={galleryItems} />
              </div>

              <div>
                <Switch
                  value={isInComparisonMode}
                  onChange={toggleComparisonMode}
                  disabled={page === Page.Health}>
                  <ViewColumns2 />
                  &nbsp;Comparison
                </Switch>
              </div>

              <div>
                <Select
                  upwards
                  value={reference}
                  onChange={onReferenceChange}
                  label={t('analytics.reference')}
                  placeholder={t('analytics.reference')}
                  options={referenceOptions}
                />
              </div>
            </aside>

            <section className={styles.section}>
              <CreateJobButton />
              <Routes>
                <Route
                  path="general"
                  element={
                    <GeneralTreeAnalytics
                      tree={tree}
                      view={analyticsView}
                      organizationId={organizationId}
                      compare={isInComparisonMode}
                      detailsHelper={detailsButtonHelpers}
                    />
                  }
                />

                <Route
                  path="dimensions"
                  element={
                    <DimensionAnalytics
                      tree={tree}
                      view={analyticsView}
                      statistics={statistics}
                      compare={isInComparisonMode}
                      organizationId={organizationId}
                      detailsHelper={detailsButtonHelpers}
                    />
                  }
                />

                <Route
                  path="safety"
                  element={
                    <SafetyAnalytics
                      tree={tree}
                      view={analyticsView}
                      compare={isInComparisonMode}
                      organization={currentAccount.organization}
                      detailsHelper={detailsButtonHelpers}
                    />
                  }
                />

                <Route
                  path="health"
                  element={
                    <HealthAnalytics
                      tree={tree}
                      laiStatistics={
                        statistics.find(it => it.isForProperty(DisplayableTreeProperty.LeafAreaIndex)) ??
                        TreeStatistics.empty()
                      }
                      gallery={galleryItems}
                    />
                  }
                />

                <Route
                  path="ecological-benefits"
                  element={
                    <EcologicalAnalytics
                      tree={tree}
                      view={analyticsView}
                      statistics={statistics}
                      compare={isInComparisonMode}
                    />
                  }
                />

                <Route
                  path="economical-value"
                  element={
                    <EconomicalAnalytics
                      tree={tree}
                      compare={isInComparisonMode}
                      view={analyticsView}
                      organization={currentAccount.organization}
                    />
                  }
                />

                <Route path="*" element={<Navigate replace to="general"/>}/>
              </Routes>
            </section>
          </div>
        </div>
      </LegacyPageLayout.Content>
    </LegacyPageLayout>
  );
}

function EmptyState() {
  const { t } = useTranslation();
  return (
    <LegacyPageLayout>
      <LegacyPageLayout.Content>
        <div className={styles.emptyState}>
          <CloudXmark strokeWidth={0.5} fontSize="4rem"/>

          <p>{t('tree.notFound')}</p>
        </div>
      </LegacyPageLayout.Content>
    </LegacyPageLayout>
  );
}

function Loader() {
  return (
    <LegacyPageLayout>
      <LegacyPageLayout.Content>
        <div
          style={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'var(--panel-background)'
          }}>
          <Spinner />
        </div>
      </LegacyPageLayout.Content>
    </LegacyPageLayout>
  );
}

const pageViewMap = new Map<Page, AnalyticsView[]>([
  [Page.General, [AnalyticsView.Twin, AnalyticsView.Panorama]],
  [Page.Dimensions, [AnalyticsView.Twin, AnalyticsView.Panorama, AnalyticsView.Data]],
  [Page.Safety, [AnalyticsView.Twin, AnalyticsView.Panorama]],
  [Page.Health, [AnalyticsView.Mixed]],
  [Page.EcologicalBenefits, [AnalyticsView.Data]],
  [Page.EconomicalValue, [AnalyticsView.Data]]
]);

function AnalyticsPageNavigation(props: AnalyticsPageNavigationProps) {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const setView = (view: AnalyticsView) => () => props.onViewChange(view);
  const activate = (on: Page) => (props.page === on ? styles.active : '');
  const enabledViews = pageViewMap.get(props.page) ?? [];
  const isEnabled = (view: AnalyticsView) => enabledViews.includes(view);
  const search = searchParams.toString();

  useEffect(() => {
    if (!isEnabled(props.view)) {
      props.onViewChange(enabledViews[0]);
    }
  }, [props.page]);

  return (
    <nav>
      <ul className={styles.navigation}>
        <li>
          <Link className={activate(Page.General)} to={{ pathname: 'general', search }}>
            <InfoCircle/> {t('analytics.navigation.general')}
          </Link>
        </li>

        <li>
          <Link className={activate(Page.Dimensions)} to={{ pathname: 'dimensions', search }}>
            <RulerArrows/> {t('analytics.navigation.dimensions')}
          </Link>
        </li>

        <li>
          {
            props.isSafetyFactorAvailable
              ? (
                <Link className={activate(Page.Safety)} to={{ pathname: 'safety', search }}>
                  <ShieldCheck /> {t('analytics.navigation.safety')}
                  {props.showSafetyAlert && <span className={styles.safetyAlert}/>}
                </Link>
              )
              : (
                <a href="/#" className={styles.inactive}>
                  <ShieldCheck /> {t('analytics.navigation.safety')}
                </a>
              )
          }
        </li>

        <li>
          <Link className={activate(Page.Health)} to={{ pathname: 'health', search }}>
            <PharmacyCrossCircle /> {t('analytics.navigation.health')}
          </Link>
        </li>

        <li>
          <a href="/#" className={styles.inactive}>
            <Farm/> {t('analytics.navigation.infrastructure')}
          </a>
        </li>

        <li>
          <Link className={activate(Page.EcologicalBenefits)} to={{ pathname: 'ecological-benefits', search }}>
            <DropletHalf/> {t('analytics.navigation.ecologicalBenefits')}
          </Link>
        </li>

        <li>
          <Link className={activate(Page.EconomicalValue)} to={{ pathname: 'economical-value', search }}>
            <EuroSquare/> {t('analytics.navigation.economicalValue')}
          </Link>
        </li>
      </ul>

      <div className={styles.viewSelector}>
        <button
          type="button"
          disabled={!isEnabled(AnalyticsView.Twin)}
          className={`${styles.viewSelectorButton} ${props.view === AnalyticsView.Twin ? styles.active : ''}`}
          onClick={setView(AnalyticsView.Twin)}
        >
          <Divide className={styles.rotatedIcon}/>
          {t(`analytics.views.${AnalyticsView.Twin}`)}
        </button>

        <button
          type="button"
          disabled={!isEnabled(AnalyticsView.Panorama)}
          className={`${styles.viewSelectorButton} ${props.view === AnalyticsView.Panorama ? styles.active : ''}`}
          onClick={setView(AnalyticsView.Panorama)}
        >
          <PanoramaReduce/>
          {t(`analytics.views.${AnalyticsView.Panorama}`)}
        </button>

        <button
          type="button"
          disabled={!isEnabled(AnalyticsView.Data)}
          className={`${styles.viewSelectorButton} ${props.view === AnalyticsView.Data ? styles.active : ''}`}
          onClick={setView(AnalyticsView.Data)}
        >
          <GraphUp/>
          {t(`analytics.views.${AnalyticsView.Data}`)}
        </button>
      </div>
    </nav>
  );
}

interface AnalyticsPageNavigationProps {
  page: Page,
  onViewChange: (view: AnalyticsView) => unknown,
  view: AnalyticsView,
  showSafetyAlert: boolean,
  isSafetyFactorAvailable: boolean
}
