import * as THREE from 'three';
import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import styles from './PointCloudViewer.module.scss';
import { Line2, LineGeometry, LineMaterial } from 'three-fatline';
import MatrixUtils from '../../utils/MatrixUtils';
import { FEET_TO_INCHES, METER_TO_FEET } from './unitConstants';
import { ExtraObject } from '../../routes/LegacyDetails/LegacyDetails';
import { Organization } from '../../organization/Organization';

export class RulerGroup extends THREE.Group {
  static forHeight(tree: Tree, displayName: string, triggerRepaint: () => void, isMetric = true, depthTest = false): RulerGroup {
    const length = RulerGroup.conditionalMeterToFeet(isMetric, tree.height);

    return new RulerGroup(
      DisplayableTreeProperty.Height,
      length,
      new THREE.Vector3(-tree.canopyWidth / 2, tree.height / 2)
        .multiplyScalar(RulerGroup.conditionalMeterToFeet(isMetric, 1))
        .applyMatrix3(MatrixUtils.degToYRotationMatrix(tree.canopyDirection - 90))
        .add(new THREE.Vector3(tree.canopyOffset[0], 0, -tree.canopyOffset[1])),
      `${displayName} ${tree.height?.toFixed(1) ?? 0} ${isMetric ? 'm' : 'ft.'}`,
      depthTest,
      length
    );
  }

  static forFirstBifurcation(tree: Tree, displayName: string, triggerRepaint: () => void, isMetric = true, depthTest = false): RulerGroup {
    const length = RulerGroup.conditionalMeterToFeet(isMetric, tree.firstBifurcation);
    const labelToLineDistanceBase = RulerGroup.conditionalMeterToFeet(isMetric, tree.height);

    return new RulerGroup(
      DisplayableTreeProperty.TrunkHeight,
      length,
      new THREE.Vector3(tree.canopyWidth / 4, tree.firstBifurcation / 2)
        .multiplyScalar(RulerGroup.conditionalMeterToFeet(isMetric, 1))
        .applyMatrix3(MatrixUtils.degToYRotationMatrix(tree.canopyDirection - 90))
        .add(new THREE.Vector3(tree.canopyOffset[0], 0, -tree.canopyOffset[1])),
      `${displayName} ${tree.firstBifurcation?.toFixed(1) ?? 0} ${isMetric ? 'm' : 'ft.'}`,
      depthTest,
      labelToLineDistanceBase
    );
  }

  static forCanopyWidth(tree: Tree, displayName: string, triggerRepaint: () => void, isMetric = true, depthTest = false): RulerGroup {
    const length = RulerGroup.conditionalMeterToFeet(isMetric, tree.canopyWidth);
    const labelToLineDistanceBase = RulerGroup.conditionalMeterToFeet(isMetric, tree.height);

    return new RulerGroup(
      DisplayableTreeProperty.CanopyWidth,
      length,
      new THREE.Vector3()
        .setY((tree.height - tree.firstBifurcation) / 2 + tree.firstBifurcation)
        .multiplyScalar(RulerGroup.conditionalMeterToFeet(isMetric, 1))
        .add(new THREE.Vector3(tree.canopyOffset[0], 0, -tree.canopyOffset[1])),
      `${displayName} ${tree.canopyWidth?.toFixed(1) ?? 0} ${isMetric ? 'm' : 'ft.'}`,
      depthTest,
      labelToLineDistanceBase,
      true
    ).rotateZ(THREE.MathUtils.degToRad(90 - tree.canopyDirection));
  }

  static forCanopyHeight(tree: Tree, displayName: string, triggerRepaint: () => void, isMetric = true, depthTest = false): RulerGroup {
    const length = RulerGroup.conditionalMeterToFeet(isMetric, tree.canopyHeight);
    const labelToLineDistanceBase = RulerGroup.conditionalMeterToFeet(isMetric, tree.height);

    return new RulerGroup(
      DisplayableTreeProperty.CanopyHeight,
      length,
      new THREE.Vector3(tree.canopyWidth / 2, tree.height - tree.canopyHeight / 2)
        .multiplyScalar(RulerGroup.conditionalMeterToFeet(isMetric, 1))
        .applyMatrix3(MatrixUtils.degToYRotationMatrix(tree.canopyDirection - 90))
        .add(new THREE.Vector3(tree.canopyOffset[0], 0, -tree.canopyOffset[1])),
      `${displayName} ${tree.canopyHeight?.toFixed(1) ?? 0} ${isMetric ? 'm' : 'ft.'}`,
      depthTest,
      labelToLineDistanceBase
    );
  }

  static forDBH(organization: Organization, tree: Tree, displayName: string, triggerRepaint: () => void, depthTest = false): RulerGroup {
    const length = RulerGroup.conditionalMeterToInches(organization.getIsMetrical(), tree.trunkDiameter);
    const labelToLineDistanceBase = RulerGroup.conditionalMeterToFeet(organization.getIsMetrical(), tree.height);

    const y = organization.getDbhMeasurementHeight();

    return new RulerGroup(
      DisplayableTreeProperty.TrunkDiameter,
      length,
      new THREE.Vector3().setY(y),
      `${displayName} ${tree.trunkDiameter?.toFixed(2) ?? 0} ${organization.getIsMetrical() ? 'm' : 'in.'}`,
      depthTest,
      labelToLineDistanceBase,
      true
    ).rotateZ(THREE.MathUtils.degToRad(90 - tree.canopyDirection));
  }

  private static conditionalMeterToFeet(isMetric: boolean, value: number): number {
    return isMetric ? value : value / METER_TO_FEET;
  }

  private static conditionalMeterToInches(isMetric: boolean, value: number): number {
    return isMetric ? value : value / (METER_TO_FEET * FEET_TO_INCHES);
  }

  constructor(
    readonly propertyName: ExtraObject,
    length: number,
    private readonly center: THREE.Vector3,
    nameLabel: string,
    private readonly depthTest: boolean,
    labelToLineDistanceBase: number,
    isHorizontal = false
  ) {
    super();
    this.name = `rulerGroup-${propertyName}`;

    const container = document.createElement('div');
    container.style.borderRadius = '50%';
    container.style.width = '24px';
    container.style.height = '24px';
    container.style.border = '2px solid white';

    const innerContainer = document.createElement('div');
    innerContainer.style.borderRadius = '50%';
    innerContainer.style.width = '10px';
    innerContainer.style.height = '10px';
    innerContainer.style.backgroundColor = 'white';
    innerContainer.style.position = 'absolute';
    innerContainer.style.top = 'calc(50% - 5px)';
    innerContainer.style.left = 'calc(50% - 5px)';

    container.appendChild(innerContainer);
    const dot = new CSS2DObject(container);

    const lineStartDot = dot.clone();
    const lineEndDot = dot.clone();

    const labelElement = document.createElement('div');
    labelElement.innerText = nameLabel;
    labelElement.classList.add(styles.pointCloudLabel);
    const label = new CSS2DObject(labelElement);
    label.renderOrder = 100;

    label.position.set(0, 0, 0);

    lineStartDot.position.copy(new THREE.Vector3().setY(length / 2));
    lineEndDot.position.copy(new THREE.Vector3().setY(-length / 2));

    const mesh = new Line2(
      new LineGeometry().setPositions([0, length / 2, 0, 0, -(length / 2), 0]),
      new LineMaterial({
        color: 0xffffff,
        linewidth: 1,
        resolution: new THREE.Vector2(640, 480),
        dashed: true,
        dashScale: 8,
        depthTest: this.depthTest
      })
    ).computeLineDistances();
    mesh.renderOrder = 0;

    this.add(mesh, lineStartDot, lineEndDot, label);

    this.position.copy(center);
    const labelToLineDistance = labelToLineDistanceBase * 0.07759197069;
    if (isHorizontal) {
      this.rotateX(Math.PI / 2);
      this.rotateZ(Math.PI / 2);
      label.translateZ(-labelToLineDistance);
    } else {
      label.translateY(lineStartDot.position.y + labelToLineDistance);
    }
  }

  displayedInTheMiddle() {
    this.position.setX(0).setZ(0);
    return this;
  }

  displayedInOriginalPlace() {
    this.position.setX(this.center.x).setZ(this.center.z);
    return this;
  }

  setVisibility(visible: boolean) {
    this.traverse(object => {
      object.visible = visible;
    });
  }
}
