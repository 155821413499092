import { CohortHistoryChart } from './CohortHistoryChart';
import { TrackableTreeProperty } from '../../../../../tree/TrackableTreeProperty';
import { useTranslation } from 'react-i18next';
import styles from './TreeHistoryReport.module.scss';
import { Tree } from '../../../../../tree/Tree';
import { useCurrentAccount } from '../../../../../account/useAccounts';
import { useRef } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import { FunctionButton } from '../../../../../components/UI/Button/LegacyButton';
import { Xmark } from 'iconoir-react';

export function TreeHistoryReportModal({ highlightedProperties, treeHistoryData, isVisible, onHide }) {
  const { t } = useTranslation();
  const account = useCurrentAccount();

  return (
    <Modal
      className={styles.modal}
      isVisible={isVisible}
      onHide={onHide}
    >
      <div className={styles.modalHeader}>
        <h3>{t('treeDetails.historyTracking.title')}</h3>
        <FunctionButton
          className={styles.closeButton}
          icon={<Xmark />}
          onClick={onHide}
        />
      </div>
      <ul className={styles.chartsWrapper}>
        {highlightedProperties.map((prop: TrackableTreeProperty) => {
          const unit = Tree.getUnit(prop, account.organization);
          const translatedUnit = unit ? `[${t('units.' + unit)}]` : '';

          return (<HistoryChartWithLastValue
            key={'rep-ch-' + prop}
            prop={prop}
            t={t}
            treeHistoryData={treeHistoryData}
            translatedUnit={translatedUnit}
          />);
        })}
      </ul>
    </Modal>
  );
}

function HistoryChartWithLastValue({ prop, t, treeHistoryData, translatedUnit }) {
  const labelRef = useRef<HTMLDivElement | null>(null);
  return (
    <li>
      <h4>
        <div>{t('details.properties.' + prop)}</div>
        <div>{translatedUnit}</div>
      </h4>
      <div style={{ position: 'relative' }}>
        <div className={styles.chartContainer}>
          <CohortHistoryChart
            key={'chart-' + prop}
            data={treeHistoryData[prop]}
            setLastValue={(value: number) => {
              labelRef.current!.style.bottom = value + 20 + 'px';
            }}
          />
        </div>
        <div ref={labelRef} className={styles.currentValue}>
          {treeHistoryData[prop].at(-1).value.toFixed(2)}
        </div>
      </div>
    </li>
  );
}
