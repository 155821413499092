import React, { createRef, type ReactNode, useEffect, useMemo, useState } from 'react';
import { ContextMenuContext } from './ContextMenuContext';

/**
 * @example
 * ```tsx
 *  <ContextMenu>
 *    <ContextMenuTrigger>
 *      See the actions
 *    </ContextMenuTrigger>
 *
 *    <ContextMenuContent>
 *      <ul>
 *        <li>
 *          ABC
 *        </li>
 *      </ul>
 *    </ContextMenuContent>
 *  </ContextMenu>
 * ```
 */
export function ContextMenu(props: { children: ReactNode | ReactNode[], className?: string, onClose?: () => void }) {
  const [isOpen, setIsOpen] = useState(false);
  const id = useMemo(() => Math.random().toString(36).slice(2), []);
  const containerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const contentEl = document.querySelector<HTMLDivElement>(`[data-context-menu-content="${id}"]`);
    if (!isOpen || !containerRef.current || !contentEl) return;

    let currentInput = '';
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') return setIsOpen(false);

      const focusables = contentEl.querySelectorAll<HTMLElement | HTMLInputElement>('button, input');
      if (!focusables.length) return;
      const focusedEl = document.activeElement as HTMLElement | null;
      const focusedElIndex = focusedEl ? Array.from(focusables).indexOf(focusedEl) : -1;

      if (e.key === 'ArrowDown' && focusedElIndex + 1 < focusables.length) {
        e.preventDefault();
        focusables[focusedElIndex + 1]?.focus();
        return;
      }

      if (e.key === 'ArrowUp' && focusedElIndex - 1 >= 0) {
        e.preventDefault();
        focusables[focusedElIndex - 1]?.focus();
        return;
      }

      const isTextKey = e.key.length === 1;
      if (isTextKey) {
        currentInput += e.key;
        const match = Array.from(focusables).find(el => el.textContent?.toLowerCase().includes(currentInput));
        if (match) match.focus();

        setTimeout(() => currentInput = '', 1_000);
      }
    };

    const onClick = (e: MouseEvent) => {
      if (!contentEl.contains(e.target as Node)) {
        setIsOpen(false);
        if (props.onClose) props.onClose();
      }
    };

    const contentBounds = contentEl.getBoundingClientRect();
    const contentHeight = contentBounds.height || 0;

    const setBounds = afDebounce(() => {
      if (!containerRef.current || !contentEl) return;
      const containerBounds = containerRef.current.getBoundingClientRect();

      const bottomSpace = window.innerHeight - containerBounds.y - containerBounds.height;
      const positionToTop = contentHeight > bottomSpace && contentHeight <= containerBounds.y;
      const shiftToLeft = containerBounds.x + contentBounds.width - window.innerWidth;

      const x = shiftToLeft > 0 ? containerBounds.x - shiftToLeft : containerBounds.x;
      const y = positionToTop ? containerBounds.y - contentBounds.height : containerBounds.y + containerBounds.height;

      contentEl.style.transform = `translate(${x}px, ${y}px)`;
    });

    setBounds.execute();

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('click', onClick, true);
    document.addEventListener('scroll', setBounds.execute, true);
    window.addEventListener('resize', setBounds.execute);

    return () => {
      setBounds.cancel();

      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('click', onClick, true);
      document.removeEventListener('scroll', setBounds.execute, true);
      window.removeEventListener('resize', setBounds.execute);
    };
  }, [isOpen, containerRef, id]);

  return (
    <ContextMenuContext.Provider value={{ id, isOpen, toggle: () => setIsOpen(open => !open) }}>
      <div className={`inline ${props.className ?? ''}`.trim()} ref={containerRef}>
        {props.children}
      </div>
    </ContextMenuContext.Provider>
  );
}

function afDebounce<F extends (...args: Parameters<F>) => ReturnType<F>>(func: F) {
  let af: number | null = null;
  return {
    cancel() {
      if (af !== null) cancelAnimationFrame(af);
    },
    execute(...args: Parameters<F>) {
      if (af !== null) return;
      af = requestAnimationFrame(() => {
        func(...args);
        af = null;
      });
    }
  };
}

