import { useCallback, useState } from 'react';

import ForgotPasswordScreen from '../../components/Auth/ForgotPasswordScreen';
import { useTranslation } from 'react-i18next';
import { PasswordService } from '../../password/PasswordService';

export default function ForgotPassword() {
  const {
    i18n: { language: lang }
  } = useTranslation();
  const [isLoading, setLoadingState] = useState(false);
  const [isCompleted, setCompletionState] = useState(false);
  const onForgotPassword = useCallback(
    async (email: string) => {
      setLoadingState(true);
      try {
        await PasswordService.make().forgot(email, lang);
        setCompletionState(true);
      } catch (error) {}
      setLoadingState(false);
    },
    [lang]
  );

  return (
    <ForgotPasswordScreen
      onForgotPassword={onForgotPassword}
      isCompleted={isCompleted}
      isLoading={isLoading}
    />
  );
}
