import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';

import styles from './CanopyCoverageSection.module.scss';
import { ReportData, SpeciesDistribution } from '../ReportData';
import ReportSection from './ReportSection';

const chartAreaBorder = {
  id: 'chartAreaBorder',
  beforeDraw(chart, args, options) {
    const { ctx, chartArea: { left, top, width, height } } = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.setLineDash(options.borderDash || []);
    ctx.lineDashOffset = options.borderDashOffset;
    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  }
};

type leafAreaAndCanopyCoverageParams = {
  data: ReportData['leafAreaAndCanopyCoverage'],
  speciesDistribution: SpeciesDistribution,
  containerId: string,
  animationDisabled?: boolean,
  printable?: boolean,
  onLoaded?: () => void
};

export default function CanopyCoverage(props: leafAreaAndCanopyCoverageParams) {
  const { t } = useTranslation();
  const onLoadedPlugin = {
    id: 'onLoaded',
    afterRender: props.onLoaded
  };

  const chartData = props.data.map((it, i) => ({ ...it, speciesPercentage: props.speciesDistribution[i].percentage }));
  const labels = chartData.map(it => it.key);

  const options = {
    responsive: true,
    animation: props.animationDisabled ? { duration: 0 } : undefined,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      datalabels: {
        display: false
      },
      chartAreaBorder: {
        borderColor: 'rgba(91, 113, 121, 0.3)',
        borderWidth: 1
      }
    },
    scales: {
      percentageAxes: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        grid: {
          color: 'rgba(91, 113, 121, 0.3)',
          drawTicks: false
        },
        ticks: {
          padding: 16,
          color: 'rgba(141, 149, 150, 1)',
          callback: value => value + ' %'
        }
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          color: props.printable ? 'rgba(34, 45, 49, 1)' : 'white'
        },
        grid: {
          display: false
        }
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: t('reporting.canopyCoverage.treePopulation'),
        data: chartData.map(it => Math.round(100 * it.speciesPercentage)),
        backgroundColor: '#205151',
        minBarLength: 5,
        barPercentage: 1,
        maxBarThickness: 8,
        categoryPercentage: .5,
        yAxisID: 'percentageAxes'
      },
      {
        label: t('reporting.canopyCoverage.leafArea'),
        data: chartData.map(it => Math.round(100 * it.coverage) ),
        backgroundColor: '#01A657',
        minBarLength: 5,
        barPercentage: 1,
        maxBarThickness: 8,
        categoryPercentage: .5,
        yAxisID: 'percentageAxes'
      },
      {
        label: t('reporting.canopyCoverage.dominance'),
        data: chartData.map(it => Math.round(100 * it.dominance)),
        backgroundColor: '#FFBA0A',
        minBarLength: 5,
        barPercentage: 1,
        maxBarThickness: 8,
        categoryPercentage: .5,
        yAxisID: 'percentageAxes'
      }
    ]
  };

  const legend = (
    <div className={`${styles.legend} ${props.printable ? styles.printableLegend : ''}`}>
      <div className={styles.percentage}>
        {data.datasets.filter(it => it.yAxisID === 'percentageAxes').map((it, index) => {
          return (
            <div className={styles.legendItem} key={index}>
              <div className={styles.coloredMarker} style={{ backgroundColor: it.backgroundColor }} />
              <span>{it.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <ReportSection containerId={props.containerId} printable={props.printable}>
      <h2>{t('reporting.canopyCoverage.title')}</h2>
      {legend}
      <Bar
        options={options}
        data={data}
        plugins={[chartAreaBorder, onLoadedPlugin]} />
    </ReportSection>
  );
}
