import { Chart } from 'react-chartjs-2';
import { useLoadedImage } from './useLoadedImage';
import TreePoint from '../images/tree-point.svg';
import * as ChartJS from 'chart.js';
import { Chart as ChartJSChart, Plugin, Point } from 'chart.js';
import * as ChartJSHelpers from 'chart.js/helpers';
import { ChartjsFullHeightSelectionPlugin } from './ChartjsFullHeightSelectionPlugin';

export default function GrowthAnalyticsChart(props: GrowthAnalyticsChartProps) {
  const treePointImage = useLoadedImage(TreePoint);

  if (!treePointImage) {
    return null;
  }

  const emptyPoint = new Image();
  const cssVariable = (name: string) => window.getComputedStyle(document.body).getPropertyValue(name);
  const labels = props.labels.map(it => it.getFullYear());
  const rowSize = 100 / props.values.length;
  const rowSpacing = 10;
  const rightPadding = 48;

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <Chart
        type="line"
        plugins={[
          {
            id: 'row-background',
            beforeDraw: chart => {
              const { width: canvasWidth } = chart.canvas.getBoundingClientRect();
              const tickHeight = chart.chartArea.height / (chart.scales.y.ticks.length - 1);
              for (let i = 0; i < chart.scales.y.ticks.length - 1; i++) {
                if (i % 2 === 0) {
                  chart.scales.y.ctx.fillStyle = 'rgba(255, 255, 255, 0.02)';
                  chart.scales.y.ctx.fillRect(
                    0,
                    tickHeight * i + chart.chartArea.top,
                    canvasWidth - rightPadding,
                    tickHeight
                  );
                }
              }
            }
          },
          new ChartjsMostRightPointLabelPlugin({
            backgroundColor: cssVariable('--chart-positive-value'),
            textColor: cssVariable('--text-primary'),
            label: (datasetIndex: number) => {
              const property = props.values.at(datasetIndex);
              if (!property) return '';
              return property.values.at(-1)?.toFixed(2) ?? '';
            }
          }),
          new ChartjsFullHeightSelectionPlugin({
            yAxisPadding: 10,
            width: 60,
            onClick: index => {
              if (props.labels[index]) props.onSelectLabel(props.labels[index]);
            }
          }),
          {
            id: 'y-axis-label',
            afterDraw: chart => {
              const fontSize = 14;
              chart.ctx.font = ChartJSHelpers.fontString(fontSize, 'normal', ChartJS.defaults.font.family ?? '');
              chart.ctx.textAlign = 'left';
              chart.ctx.textBaseline = 'middle';
              const leftMargin = 10;
              const stepSize = chart.chartArea.height / (chart.scales.y.ticks.length - 1);
              for (let i = 0; i < chart.scales.y.ticks.length; i++) {
                const label = chart.scales.y.ticks[i].label?.toString() ?? '';
                const tickMinY = stepSize * i + chart.chartArea.top;
                chart.ctx.fillText(label, leftMargin, tickMinY + stepSize / 2);
              }
            }
          }
        ]}
        data={{
          labels,
          datasets: props.values.map((it, index) => {
            const rowMin = index * rowSize + rowSpacing / 2;
            const maxValue = Math.max(...it.values);
            const minValue = Math.min(...it.values);
            return {
              type: 'line',
              pointStyle: ctx => (ctx.dataIndex === ctx.dataset.data.length - 1 ? treePointImage : emptyPoint),
              borderColor: cssVariable('--chart-positive-value'),
              data: it.values.map(
                value => ((value - minValue) / (maxValue - minValue)) * (rowSize - rowSpacing) + rowMin
              ),
              label: it.label
            };
          })
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          layout: { padding: { top: 48, left: 24, bottom: 24, right: rightPadding } },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              display: false
            }
          },
          scales: {
            x: {
              grid: { color: cssVariable('--panel-border_transparent'), borderDash: [4, 4], drawBorder: false },
              ticks: { autoSkip: false },
              max: props.values.length + 1,
              offset: true
            },
            y: {
              min: 0,
              max: 100,
              grid: { display: false, drawBorder: false },
              ticks: {
                stepSize: props.values.length - 1,
                autoSkip: false,
                maxTicksLimit: props.values.length + 1,
                callback: (value, index) => props.values.at(index)?.label ?? '',
                color: 'rgba(0, 0, 0, 0)'
              },
              beginAtZero: true
            }
          }
        }}
      />
    </div>
  );
}

interface Property {
  label: string,
  values: number[]
}

interface GrowthAnalyticsChartProps {
  values: Property[],
  labels: Date[],
  onSelectLabel: (label: Date) => unknown
}

class ChartjsMostRightPointLabelPlugin implements Plugin {
  private static readonly xOffset = 15;
  private static readonly height = 20;
  private static readonly horizontalPadding = 20;
  private static readonly fontSize = 14;

  id = 'right-label';

  constructor(
    private readonly options: {
      backgroundColor: string,
      textColor: string,
      label: (datasetIndex: number) => string
    }
  ) {}

  afterDraw(chart: ChartJSChart) {
    chart.ctx.font = ChartJSHelpers.fontString(
      ChartjsMostRightPointLabelPlugin.fontSize,
      ChartJS.defaults.font.style ?? '',
      ChartJS.defaults.font.family ?? ''
    );
    chart.ctx.textAlign = 'left';
    chart.ctx.textBaseline = 'middle';

    for (let datasetIndex = 0; datasetIndex < chart.data.datasets.length; datasetIndex++) {
      const lastPoint = chart.getDatasetMeta(datasetIndex).data.at(-1);
      if (lastPoint) {
        this.drawLabel(datasetIndex, chart, lastPoint);
      }
    }
  }

  private drawLabel(datasetIndex: number, chart: ChartJSChart, point: Point) {
    const label = this.options.label(datasetIndex);
    const backgroundHeight = ChartjsMostRightPointLabelPlugin.height;
    const backgroundWidth = chart.ctx.measureText(label).width + ChartjsMostRightPointLabelPlugin.horizontalPadding;
    const backgroundX = point.x + ChartjsMostRightPointLabelPlugin.xOffset;
    const backgroundY = point.y - backgroundHeight / 2;
    const radius = backgroundHeight / 2;

    this.drawRoundedRect(chart.ctx, backgroundX, backgroundY, backgroundWidth, backgroundHeight, radius);
    chart.ctx.fillStyle = this.options.backgroundColor;
    chart.ctx.fill();

    chart.ctx.fillStyle = this.options.textColor;

    chart.ctx.fillText(label, backgroundX + ChartjsMostRightPointLabelPlugin.horizontalPadding / 2, point.y);
  }

  private drawRoundedRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number
  ) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
  }
}
