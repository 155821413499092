import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { useTranslation } from 'react-i18next';
import { Filter, NavArrowDown, ViewGrid } from 'iconoir-react';
import TaskTable from './TaskTable';
import React, { useEffect, useState } from 'react';
import { Task } from '../../../task-manager/Task';
import { useMatch } from 'react-router-dom';
import { useTasks } from '../../../task-manager/useTasks';
import TaskSlideIn from './TaskSlideIn';

export default function ManageTasks() {
  const { t } = useTranslation();
  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';
  const { tasks } = useTasks();

  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  useEffect(() => {
    if (selectedTask) setSelectedTask(tasks.find(task => task.id === selectedTask.id) || null);
  }, [tasks]);

  return (
    <section className="w-full">
      <div className="twp">
        <div className="flex my-4">
          <h2 className="text-2xl font-semibold">{t('taskManager.manageTasks')}</h2>
        </div>
        <div className="flex items-center gap-4">
          <FunctionButton
            variant={'rounded'}
            icon={<ViewGrid />}
            disabled={true}
          >
            <span className="flex items-center">{t('taskManager.mainView')} <NavArrowDown /></span>
          </FunctionButton>
          <FunctionButton
            variant={'rounded'}
            icon={<Filter />}
            disabled={true}
          >
            <span className="flex items-center">{t('taskManager.filters')} <NavArrowDown /></span>
          </FunctionButton>
        </div>
      </div>
      <div className="my-4 text-greehill-00">
        <TaskTable
          tasks={tasks}
          setSelectedTask={setSelectedTask}
        />
      </div>
      {selectedTask &&
        <TaskSlideIn
          task={selectedTask}
          organizationId={organizationId}
          setSlideInOpen={() => setSelectedTask(null)}
        />}
    </section>
  );
}
