import { ReactNode } from 'react';
import styles from '../AnalyticsPage.module.scss';
import pageStyles from '../AnalyticsPage.module.scss';
import { useTranslation } from 'react-i18next';
import { ButtonWithIcon, PrimaryButton } from '../../../components/UI/Button/LegacyButton';
import { AntennaSignal, CenterAlign, Clock, RefreshDouble } from 'iconoir-react';

export function PageContainer({ children }: { children: ReactNode }) {
  return <div className={styles.container}>{children}</div>;
}

export function PageContent({ children, tree }: { children: ReactNode, tree?: boolean }) {
  return <div className={styles.content.concat(' ', tree ? styles.showsTree : '')}>{children}</div>;
}

export function TreeComparisonView({ children }: { children: ReactNode }) {
  return <div className={styles.treeComparison}>{children}</div>;
}

export function TreeView({ children, pointCloud }: { children: ReactNode, pointCloud?: true }) {
  return <div className={styles.tree.concat(' ', pointCloud ? styles.pointCloud : '')}>{children}</div>;
}

export function PageReferenceChartContainer({ children }: { children: ReactNode }) {
  return <div className={styles.referenceChartContainer}>{children}</div>;
}

export function PagePropertyChartContainer({ children, compare }: { children: ReactNode, compare: boolean }) {
  return <div className={styles.propertyChartContainer.concat(' ', compare ? styles.compare : '')}>{children}</div>;
}

export function PageHistoryControls(props: PageHistoryControlsProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.historyButtonContainer}>
      <div className={styles.historyControlsTitle}>{props.children}</div>

      {
        props.showHistoryButton ?
          <ButtonWithIcon
            variant="secondary"
            icon={<Clock/>}
            onClick={props.toggleHistory}>
            {props.isInHistoricalView ? t('analytics.current') : t('analytics.history')}
          </ButtonWithIcon>
          : null
      }
    </div>
  );
}

PageHistoryControls.defaultProps = { showHistoryButton: true };

interface PageHistoryControlsProps {
  toggleHistory: () => unknown,
  isInHistoricalView: boolean,
  showHistoryButton: boolean,
  children?: ReactNode
}

export function CreateJobButton() {
  const { t } = useTranslation();
  const openPrototype = () =>
    window.open(
      'https://www.figma.com/proto/o8OJEJes6t5qwNUdu0T92c/Field-Action-Demo?page-id=0%3A1&node-id=18%3A2018&viewport=242%2C48%2C0.24&scaling=contain&starting-point-node-id=18%3A2018&hide-ui=1',
      '_blank'
    );
  return (
    <div className={styles.headerControls}>
      <PrimaryButton onClick={openPrototype}>{t('analytics.createJob')}</PrimaryButton>
    </div>
  );
}

export function PageHeader({ children, title }: { children: ReactNode, title: string }) {
  return (
    <div className={styles.header}>
      <h1 className={styles.title}>{title}</h1>

      {children}
    </div>
  );
}

export function TreePropertyList({ children }: { children?: ReactNode }) {
  return <ul className={pageStyles.propertyList}>{children}</ul>;
}

export function PropertyListItem(props: PropertyListItemProps | DisabledPropertyListItemProps) {
  const className = pageStyles.propertyListItemButton.concat(
    ' ',
    'active' in props && props.active ? pageStyles.active : '',
    ' ',
    props.disabled ? pageStyles.disabled : ''
  );

  const onClick = () => {
    if ('onClick' in props) {
      props.onClick();
    }
  };

  return (
    <li className={pageStyles.propertyListItem}>
      <button onClick={onClick} className={className}>
        {props.children}
      </button>
    </li>
  );
}

export function DetailsButton({ showDetails, toggleDetails }: { showDetails: boolean, toggleDetails: () => unknown }) {
  return (
    <button
      className={pageStyles.detailsButton.concat(' ', showDetails ? pageStyles.active : '')}
      onClick={toggleDetails}
    >
      <CenterAlign/>
    </button>
  );
}

export function ExtraDetailsButton({ isOn, toggle }: { isOn: boolean, toggle: () => unknown }) {
  return (
    <button className={pageStyles.extraDetailsButton.concat(' ', isOn ? pageStyles.active : '')} onClick={toggle}>
      <AntennaSignal/>
    </button>
  );
}

export function ResetViewButton({ onClick }: { onClick: () => void }) {
  return (
    <button className={pageStyles.resetViewButton} onClick={onClick}>
      <RefreshDouble/>
    </button>
  );
}

interface PropertyListItemProps {
  children: ReactNode,
  onClick: () => unknown,
  active: boolean,
  disabled?: true
}

interface DisabledPropertyListItemProps {
  children: ReactNode,
  disabled: true
}
