import { AxiosInstance } from 'axios';
import { Member } from './Member';
import { MemberDto } from './MemberDto';
import { OrganizationDto } from './OrganizationDto';
import { Organization, StreetAddressOrder } from './Organization';
import { CardinalDirection } from '../utils/getCardinalDirectionFromAngle';
import { TreeValueMethod } from '../property-enums/TreeValueMethod';

export default class OrganizationService {
  constructor(private readonly http: AxiosInstance) {}

  async listMembers(organizationId: string): Promise<Member[]> {
    const response = await this.http.get<MemberDto[]>(`/v1/organizations/${organizationId}/members`);

    return response.data.map(Member.fromDto);
  }

  async update(organizationId: string, payload: Partial<OrganizationDto>): Promise<void> {
    await this.http.put(`/v1/organizations/${organizationId}`, payload);
  }

  async toggleUnitOfMeasure(organizationId: string): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/is-metric`);
  }

  async setCurrency(organizationId: string, currency: string): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/currency`, { currency });
  }

  async setDefaultWindSpeed(organizationId: string, defaultWindSpeed: number): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/default-wind-speed`, { defaultWindSpeed });
  }

  async setPrevailingWindDirection(organizationId: string, prevailingWindDirection: CardinalDirection): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/prevailing-wind-direction`, { prevailingWindDirection });
  }

  async setTreeValueMethod(organizationId: string, treeValueMethod: TreeValueMethod): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/tree-value-method`, { treeValueMethod });
  }

  async setDbhMeasurementHeight(organizationId: string, dbhMeasurementHeight: number): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/dbh-measurement-height`, { dbhMeasurementHeight });
  }

  async setFlippers(organizationId: string, flippers: string[]): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/flippers`, { flippers });
  }

  async setFeatures(organizationId: string, features: string[]): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/features`, { features });
  }

  async create(organization: FormData): Promise<number> {
    return await this.http.post('/v1/organizations', organization).then(res => res.status);
  }

  async listAll(): Promise<Organization[]> {
    const response = await this.http.get('/v1/organizations');

    return response.data.map(Organization.fromDto);
  }

  async setStreetAddressOrder(organizationId: string, streetAddressOrder: StreetAddressOrder) {
    await this.http.patch(`/v1/organizations/${organizationId}/streetAddressOrder`, { streetAddressOrder });
  }
}
