import { forwardRef, type ReactNode, useContext } from 'react';
import { ContextMenuContext } from './ContextMenuContext';

export const ContextMenuTrigger = forwardRef<HTMLButtonElement, {
  children: ReactNode | ReactNode[],
  className?: string,
  disabled?: boolean
}>((props, ref) => {
  const ctx = useContext(ContextMenuContext);
  return (
    <button
      className={`twp text-greehill-00 ${props.className ?? ''}`.trimEnd()}
      type="button"
      ref={ref}
      disabled={props.disabled}
      onClick={ctx.toggle}
    >
      {props.children}
    </button>
  );
});
