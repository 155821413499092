import { ReactNode, useState } from 'react';
import styles from './ObservationGallery.module.scss';
import FullScreenImageViewer from '../../../../../components/FullScreenImageViewer/FullScreenImageViewer';
import Spinner from '../../../../../components/UI/Spinner/Spinner';
import { Trash } from 'iconoir-react';

interface ObservationGalleryProps {
  images: { url: string, saving: boolean }[],
  deleteImage: (url: string) => void,
  defaultItem?: ReactNode,
  editing?: boolean
}

export function ObservationGallery(props: ObservationGalleryProps) {
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const openImageViewer = (url: string) => setSelectedImageUrl(url);
  const closeImageViewer = () => setSelectedImageUrl('');

  return <div className={styles.imageGallery}>
    {props.images.length > 0
      && props.images.map((image, index) => <GalleryImage
        key={index}
        image={image}
        onClick={openImageViewer}
        deleteImage={() => props.deleteImage(image.url)}
        editing={!!props.editing}
      />)
    }
    {props.defaultItem}

    {selectedImageUrl &&
      <FullScreenImageViewer
        setImgUrl={setSelectedImageUrl}
        galleryUrls={props.images.map(it => it.url)}
        url={selectedImageUrl}
        onClose={closeImageViewer}
      />}
  </div>;
}

const GalleryImage = (props: { image: { url: string, saving: boolean }, onClick: any, deleteImage: () => void, editing: boolean }) => {
  return <div key={props.image.url} className="relative group">
    <div
      className={styles.imageGalleryItem}
      style={{ backgroundImage: `url(${props.image.url})` }}
      onClick={() => {
        return props.onClick(props.image.url);
      }}
      data-testid={`gallery-image-${props.image.url}`}
    />
    {props.image.saving ?
      (
        <div className="absolute left-1/2 top-1/2">
          <Spinner />
        </div>
      )
      :
      (
        props.editing && <div
          className="absolute right-1 bottom-1 rounded-full p-1 bg-outer-space-600 cursor-pointer group-hover:block hidden"
          onClick={props.deleteImage}
        >
          <Trash className="text-greehill-00 size-4"/>
        </div>
      )
    }
  </div>;
};
