import { useEffect } from 'react';
import styles from './ContentOverlay.module.scss';

export default function ContentOverlay({ show, onClick }: { show: boolean, onClick: () => unknown }) {
  useEffect(() => {
    if (show) {
      const overlayElement = document.createElement('div');
      overlayElement.classList.add(styles.overlay);
      overlayElement.addEventListener('click', onClick);
      document.body.appendChild(overlayElement);

      return () => {
        overlayElement.removeEventListener('click', onClick);
        overlayElement.remove();
      };
    }
  }, [onClick, show]);

  return <></>;
}
