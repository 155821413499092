import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';

export class MultiOrbitControl {
  controlsMap: Map<string, { controls: OrbitControls, listeners: { type: string, listener: (event: any) => void }[] }> =
    new Map();
  firstControlsId: string | null = null;

  register(id: string, camera: THREE.PerspectiveCamera, domElement: HTMLElement) {
    this.firstControlsId = this.firstControlsId ?? id;

    const controls = new OrbitControls(camera, domElement);
    controls.maxDistance = 80;

    this.controlsMap.set(id, { controls, listeners: [] });
  }

  reset(originalPosition: THREE.Vector3): this {
    Array.from(this.controlsMap.values())![0].controls.reset();
    Array.from(this.controlsMap.values())![0].controls.object.position.copy(originalPosition.clone());

    return this;
  }

  lookAtHeight(height: number) {
    Array.from(this.controlsMap.values())!.forEach(({ controls }) => {
      controls.target.setY(height);
      controls.update();
    });
  }

  zoomIn(camera: THREE.PerspectiveCamera) {
    camera.zoom *= 1.1;
    camera.updateProjectionMatrix();
  }

  zoomOut(camera: THREE.PerspectiveCamera) {
    camera.zoom *= 0.9;
    camera.updateProjectionMatrix();
  }

  addRenderCallback(id: string, renderCallback: () => void) {
    Array.from(this.controlsMap.keys()).forEach(id => {
      this.addListener(id, 'change', renderCallback);
    });
    this.addListener(id, 'change', e => {
      Array.from(this.controlsMap.entries()).forEach(([ID, { controls }]) => {
        if (ID === id) return;
        controls.object.copy(e.target.object.clone());
      });
    });
  }

  removeListeners(id: string) {
    const pointCloudControls = this.controlsMap.get(id);
    if (!pointCloudControls) return;
    pointCloudControls.listeners.forEach(({ type, listener }) => {
      pointCloudControls.controls.removeEventListener(type, listener);
    });
    this.controlsMap.set(id, { controls: pointCloudControls.controls, listeners: [] });
  }

  dispose(id: string) {
    if (!this.controlsMap.get(id)) return;

    const { controls, listeners } = this.controlsMap.get(id)!;
    listeners.forEach(({ type, listener }) => controls.removeEventListener(type, listener));
    this.controlsMap.delete(id);
  }

  private addListener(id: string, type: string, listener) {
    if (!this.controlsMap.get(id)) return;

    this.controlsMap.get(id)!.controls.addEventListener(type, listener);
    this.controlsMap.get(id)!.listeners.push({ type, listener });
  }
}
