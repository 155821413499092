import styles from '../../Inventory/Inventory.module.scss';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Tree, TreeDto } from '../../../../../tree/Tree';
import DetailedTree from '../../../../../tree/DetailedTree';
import { useCurrentAccount } from '../../../../../account/useAccounts';
import Dropdown, { Item } from '../../../../../components/UI/Dropdown/Dropdown';
import { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import DataGroupHeader from '../../components/DataGroupHeader/DataGroupHeader';
import { CardinalDirection } from '../../../../../utils/getCardinalDirectionFromAngle';
import { TrackableTreeProperty } from '../../../../../tree/TrackableTreeProperty';
import { KMH_TO_MPH } from '../../../../../components/PointCloud/unitConstants';
import EditingFieldWrapper from '../../components/EditingFieldWrapper/EditingFieldWrapper';

export default function StructuralAssessmentDataGroup(props: StructuralAssessmentDataGroupProps) {
  const { t } = useTranslation();
  const currentAccount = useCurrentAccount();
  const organization = currentAccount.organization;

  const { treeService } = useContext(DependencyInjectionContext);
  const [editing, setEditing] = useState<boolean>(false);

  const initialAssessments = {
    prevailingWindDirection: props.tree.prevailingWindDirection || currentAccount.organization.prevailingWindDirection
  };

  const [assessments, setAssessments] = useState(initialAssessments);

  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(assessments).forEach(key => {
      if (assessments[key] !== props.tree[key] && assessments[key] !== '') {
        updatedValues[key] = assessments[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(organization.id, props.tree.id, updatedValues);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setAssessments(initialAssessments);
  };

  const maxCriticalWindSpeed = t('treeDetails.risk.above') + ' ' + (currentAccount.organization.isMetric ? '175' : '110');
  let criticalWindSpeedValue;
  if (props.tree.safetyFactors.length > 0) {
    criticalWindSpeedValue = props.tree.criticalWindSpeed === null ? maxCriticalWindSpeed : props.tree.criticalWindSpeed.toFixed(0);
  } else {
    criticalWindSpeedValue = '';
  }

  const prevailingWindDirectionKey = assessments.prevailingWindDirection ? t(`treeDetails.risk.windDirections.${assessments.prevailingWindDirection}`) : '';
  const prevailingWindDirectionOptions: Item[] = Object.values(CardinalDirection).map(direction => {
    return { id: direction, translationKey: 'treeDetails.risk.windDirections.' + direction };
  });

  const speedFactor = currentAccount.organization.isMetric ? 1 : KMH_TO_MPH;

  return (
    <div className={styles.dataGroup}>
      <DataGroupHeader
        editing={editing}
        setEditing={() => setEditing(true)}
        handleCancel={handleCancel}
        handleSave={handleSave}
        title={t('treeDetails.tabs.subTitles.treeStructuralAssessment')}
      />
      <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : ''}`}>
        <EditingFieldWrapper
          title={
            t('details.properties.safetyFactorAtWindSpeed', {
              windSpeed: currentAccount.organization.defaultWindSpeed * speedFactor,
              unit: Tree.getWindSpeedUnit(currentAccount.organization)
            })
          }
          value={props.tree.safetyFactorAtDefaultWindSpeed?.toFixed(2) || ''}
          chartPropertyOnHover={TrackableTreeProperty.SafetyFactorAtDefaultWindSpeed}
        />
        <EditingFieldWrapper
          title={t('details.properties.criticalWindSpeed')}
          value={criticalWindSpeedValue}
          unit={Tree.getWindSpeedUnit(currentAccount.organization)}
        />
        <EditingFieldWrapper
          title={t('details.properties.prevailingWindDirection')}
          value={prevailingWindDirectionKey}
          editing={editing}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={prevailingWindDirectionOptions}
            value={{ translationKey: prevailingWindDirectionKey }}
            onSelect={item => setAssessments(prev => ({
              ...prev,
              prevailingWindDirection: item.id as CardinalDirection
            }))}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.leaningAngle')}
          value={props.tree.leaningAngle}
          unit={Tree.getUnit(DisplayableTreeProperty.LeaningAngle, organization)}
        />
        <EditingFieldWrapper
          title={t('details.properties.slenderness')}
          value={props.tree.slenderness || ''}
          unit={Tree.getUnit(DisplayableTreeProperty.Slenderness, organization)}
        />
      </div>
    </div>
  );
}

interface StructuralAssessmentDataGroupProps {
  tree: DetailedTree,
  singleColumn: boolean
}
