import { ChangeEvent } from 'react';
import styles from './SearchInput.module.scss';
import { Search } from 'iconoir-react';

export default function SearchInput(props: SearchInputProps) {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onValueChange) {
      props.onValueChange(event.target.value.toString());
    }
  };

  return (
    <label className={styles.container}>
      <span>
        <Search />
      </span>

      <input
        aria-label={`Search: ${props.placeholder}`}
        data-testid={props.testId}
        value={props.value}
        placeholder={props.placeholder}
        className={styles.input}
        onChange={onInputChange}
        required={true}
        minLength={10}
      />
    </label>
  );
}

interface SearchInputProps {
  onValueChange: (value: string) => unknown,
  value: string,
  placeholder: string,
  testId?: string
}
