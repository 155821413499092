import { GeneralInsights, Quantity } from '../ReportData';
import ReportSection from './ReportSection';
import { useTranslation } from 'react-i18next';
import styles from './GeneralInsightsSection.module.scss';
import { NumericFormat } from '../../../../numeric-format/NumericFormat';
import { useCurrentAccount } from '../../../../account/useAccounts';
import reportStyles from '../ReportDialog.module.scss';

export default function GeneralInsightsSection(props: { data: GeneralInsights, containerId: string, printable?: boolean }) {
  const { t } = useTranslation();
  const tt = suffix => t(`reporting.generalInsights.${suffix}`);
  const ecological = props.data.ecologicalBenefits;
  const account = useCurrentAccount();

  return (
    <ReportSection containerId={props.containerId} printable={props.printable}>
      <h2 className={styles.header}>{tt('title')}</h2>

      <h3 className={styles.subheader}>{tt('ecologicalBenefits')}</h3>

      <table className={`${styles.table} ${styles.benefit} ${props.printable ? styles.printableTable : ''}`}>
        <tbody>
          <BenefitRow
            label={tt('airPollutionRemoval')}
            data={ecological.airPollutionRemoval}
          />
          <BenefitRow
            label={tt('avoidedRunoff')}
            data={ecological.avoidedRunoff}
          />
          <BenefitRow
            label={tt('grossCarbonSequestration')}
            data={ecological.grossCarbonSequestration}
          />
          <BenefitRow
            label={tt('carbonStorage')}
            data={ecological.carbonStorage}
          />
        </tbody>
      </table>

      <h3 className={styles.subheader}>{tt('economicalValues')}</h3>

      <table className={`${styles.table} ${styles.economical} ${props.printable ? styles.printableTable : ''}`}>
        <tbody>
          <EconomicalRow
            label={tt('treeValueCavat')}
            data={props.data.economicalValues.treeValueCavat}
            currency={account.organization.currency} />
          <EconomicalRow
            label={tt('treeValueKoch')}
            data={props.data.economicalValues.treeValueKoch}
            currency={account.organization.currency} />
          <EconomicalRow
            label={tt('treeValueRado')}
            data={props.data.economicalValues.treeValueRado}
            currency={account.organization.currency} />
        </tbody>
      </table>

    </ReportSection>
  );
}

function BenefitRow(props: { label: string, data: { quantity: Quantity, monetaryValue: Quantity} }) {
  return (
    <tr>
      <td>{props.label}</td>
      <Cell data={props.data.quantity} />
    </tr>
  );
}

function EconomicalRow(props: { label: string, data: Quantity, currency: string }) {
  return (
    <tr>
      <td>{props.label}</td>
      <Cell currency={props.currency} data={props.data} />
    </tr>
  );
}

function Cell(props: { data: Quantity, currency?: string }) {
  const { t } = useTranslation();
  return (
    <>
      { props.currency ?
        <td className={styles.numeric}>
          {NumericFormat.currency(props.data.unit).format(props.data.value)}
        </td> :
        <>
          <td className={reportStyles.cellValue}>{NumericFormat.iso(props.data.unit, navigator.language, t).getFormattedValue(props.data.value)}</td>
          <td className={reportStyles.cellUnit}>{NumericFormat.iso(props.data.unit, navigator.language, t).getUnit()}</td>
        </>}
    </>
  );
}
