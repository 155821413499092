import * as THREE from 'three';
import PointMarker from './images/point-marker.svg';
import styles from './PointCloudViewer.module.scss';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Line2, LineGeometry, LineMaterial } from 'three-fatline';

export class MarkerGroupWithOffset extends THREE.Group {
  constructor(position: THREE.Vector3, private readonly nameLabel: string, offset: THREE.Vector2, triggerRepaint: () => void) {
    super();

    const dot = new THREE.Sprite(
      new THREE.SpriteMaterial({
        map: new THREE.TextureLoader().load(PointMarker, triggerRepaint),
        fog: false,
        depthTest: false
      })
    );
    dot.position.set(0, 0, 0);
    dot.scale.set(0.3, 0.3, 1);

    const labelElement = document.createElement('div');
    labelElement.innerText = nameLabel;
    labelElement.classList.add(styles.pointCloudLabel);
    const label = new CSS2DObject(labelElement);
    label.position.set(offset.x, -offset.y, 0);
    const mesh = new Line2(
      new LineGeometry().setPositions([0, 0, 0, offset.x, -offset.y, 0]),
      new LineMaterial({
        color: 0xffffff,
        linewidth: 1,
        resolution: new THREE.Vector2(640, 480),
        dashed: true,
        dashScale: 8,
        depthTest: false
      })
    ).computeLineDistances();
    mesh.renderOrder = 1;

    this.add(dot, label, mesh);

    this.position.copy(position);
  }
}
