import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import { DisplayableTreeProperty, Tree, TreeDto } from '../../../../../tree/Tree';
import DataGroupHeader from '../DataGroupHeader/DataGroupHeader';
import DetailedTree from '../../../../../tree/DetailedTree';
import styles from '../../Inventory/BaseAttributes.module.scss';
import { useCurrentAccount } from '../../../../../account/useAccounts';
import EditingFieldWrapper from '../EditingFieldWrapper/EditingFieldWrapper';
import Input from '../../../../../components/UI/Input/Input';
import { nullableBooleanItems } from '../../DaveyDataPanel';
import Dropdown, { Item } from '../../../../../components/UI/Dropdown/Dropdown';

export default function FoliageSection(props: FoliageSectionProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const { organization } = account;

  const { treeService } = useContext(DependencyInjectionContext);
  const [editing, setEditing] = useState(false);

  const initialFoliage = {
    liveCrownRatio: props.tree.liveCrownRatio,
    normalFoliage: props.tree.normalFoliage,
    foliageNoneSeasonal: props.tree.foliageNoneSeasonal,
    foliageNoneDead: props.tree.foliageNoneDead
  };

  const [foliage, setFoliage] = useState(initialFoliage);

  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(foliage).forEach(key => {
      if (foliage[key] !== props.tree[key] && foliage[key] !== '') {
        updatedValues[key] = foliage[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(organization.id, props.tree.id, updatedValues);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setFoliage(initialFoliage);
  };

  const handleRatioChange = (event: ChangeEvent<HTMLInputElement>, propertyName: DisplayableTreeProperty) => {
    let newValue = Number(event.target.value);
    if (newValue < 0) newValue = 0;
    if (newValue > 100) newValue = 100;
    if (newValue > 0) setFoliage(prev => ({
      ...prev,
      foliageNoneDead: false,
      foliageNoneSeasonal: false
    }));
    setFoliage(prev => ({ ...prev, [propertyName]: newValue }));
  };

  const handleFoliageNoneSeasonalChange = (item: Item) => {
    setFoliage(prev => ({
      ...prev,
      foliageNoneSeasonal: item.id === '' ? null : item.id === 'true',
      foliageNoneDead: item.id === 'true' ? false : prev.foliageNoneDead,
      normalFoliage: item.id === 'true' ? 0 : prev.normalFoliage
    }));
  };

  const handleFoliageNoneDeadChange = (item: Item) => {
    setFoliage(prev => ({
      ...prev,
      foliageNoneDead: item.id === '' ? null : item.id === 'true',
      foliageNoneSeasonal: item.id === 'true' ? false : prev.foliageNoneSeasonal,
      normalFoliage: item.id === 'true' ? 0 : prev.normalFoliage
    }));
  };
  const getDisplayableBooleanValue = value => {
    if (typeof value === 'boolean') {
      return t('details.properties.booleanLabels.' + value);
    } else {
      return '';
    }
  };

  return (
    <div className={styles.dataGroup}>
      <DataGroupHeader
        editing={editing}
        setEditing={() => setEditing(true)}
        handleCancel={handleCancel}
        handleSave={handleSave}
        title={t('treeDetails.tabs.subTitles.foliage')}
      />
      <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : ''}`}>
        <EditingFieldWrapper
          title={t('tree.leafArea')}
          value={props.tree.leafArea}
          unit={Tree.getUnit(DisplayableTreeProperty.LeafArea, organization)}
        />
        <EditingFieldWrapper
          title={t('tree.leafAreaIndex')}
          value={props.tree.leafAreaIndex}
          unit={Tree.getUnit(DisplayableTreeProperty.LeafAreaIndex, organization)}
        />
        <EditingFieldWrapper
          title={t('details.properties.leafAreaPercentOfCrownVolume')}
          value={props.tree.leafAreaPerCrownVolume}
          unit={Tree.getUnit(DisplayableTreeProperty.LeafAreaPerCrownVolume, account.organization)}
        />
        <EditingFieldWrapper
          title={t('tree.leafBiomass')}
          value={props.tree.leafBiomass}
        />
        <EditingFieldWrapper
          title={t('details.properties.foliageNoneDead')}
          value={getDisplayableBooleanValue(props.tree.foliageNoneDead)}
          editing={editing}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={nullableBooleanItems}
            value={nullableBooleanItems.find(it => it.id === foliage.foliageNoneDead?.toString() || '')}
            onSelect={handleFoliageNoneDeadChange}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.foliageNoneSeasonal')}
          value={getDisplayableBooleanValue(props.tree.foliageNoneSeasonal)}
          editing={editing}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={nullableBooleanItems}
            value={nullableBooleanItems.find(it => it.id === foliage.foliageNoneSeasonal?.toString() || '')}
            onSelect={handleFoliageNoneSeasonalChange}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.normalFoliage')}
          value={foliage.normalFoliage}
          editing={editing}
          unit={Tree.getUnit(DisplayableTreeProperty.NormalFoliage, organization)}
        >
          <div className={styles.editingNumberInput}>
            <Input
              value={foliage.normalFoliage === null ? '' : foliage.normalFoliage.toFixed(0)}
              label={''}
              onChange={e => handleRatioChange(e, DisplayableTreeProperty.NormalFoliage)}
              type="number"
              className={styles.editingInput}
            />
            <span className={styles.unit}>%</span>
          </div>
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.liveCrownRatio')}
          value={foliage.liveCrownRatio === null ? '' : foliage.liveCrownRatio.toFixed(0)}
          unit={Tree.getUnit(DisplayableTreeProperty.LiveCrownRatio, organization)}
          editing={editing}
        >
          <div className={styles.editingNumberInput}>
            <Input
              value={foliage.liveCrownRatio === null ? '' : foliage.liveCrownRatio.toFixed(0)}
              label={''}
              onChange={e => handleRatioChange(e, DisplayableTreeProperty.LiveCrownRatio)}
              type="number"
              className={styles.editingInput}
            />
            <span className={styles.unit}>%</span>
          </div>
        </EditingFieldWrapper>
      </div>
    </div>
  );
}

interface FoliageSectionProps {
  tree: DetailedTree,
  singleColumn: boolean
}
