import styles from '../../Inventory/BaseAttributes.module.scss';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import { DisplayableTreeProperty, Tree, TreeDto } from '../../../../../tree/Tree';
import DetailedTree from '../../../../../tree/DetailedTree';
import { DateInput } from '../../../../../components/UI/Input/Input';
import DataGroupHeader from '../../components/DataGroupHeader/DataGroupHeader';
import Dropdown, { Item } from '../../../../../components/UI/Dropdown/Dropdown';
import { RiskRating } from '../../../../../property-enums/RiskRating';
import EditingFieldWrapper from '../../components/EditingFieldWrapper/EditingFieldWrapper';

export default function ConclusionsAssessment(props: ConclusionsAssessmentProps) {
  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);
  const [editing, setEditing] = useState(false);

  const initialRisks = {
    furtherInspectionNeeded: props.tree.furtherInspectionNeeded || null,
    overallTreeRiskRating: props.tree.overallTreeRiskRating,
    overallResidualRisk: props.tree.overallResidualRisk
  };

  const [risks, setRisks] = useState(initialRisks);

  const riskRatings: Item[] = Object.values(RiskRating).map(risk => {
    return { id: risk, translationKey: 'details.properties.riskRatingTypes.' + risk };
  });
  const treeRiskValue = risks.overallTreeRiskRating ? t(`details.properties.riskRatingTypes.${risks.overallTreeRiskRating}`) : '';
  const residualRiskValue = risks.overallResidualRisk ? t(`details.properties.riskRatingTypes.${risks.overallResidualRisk}`) : '';
  const furtherInspectionNeededValue = props.tree.furtherInspectionNeeded ? new Date(props.tree.furtherInspectionNeeded).toLocaleDateString() : t('details.properties.booleanLabels.false');

  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(risks).forEach(key => {
      if (risks[key] !== props.tree[key] && risks[key] !== '') {
        updatedValues[key] = risks[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(props.organizationId, props.tree.id, updatedValues);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setRisks(initialRisks);
  };

  return (
    <div className={styles.dataGroup}>
      <DataGroupHeader
        editing={editing}
        setEditing={() => setEditing(true)}
        handleCancel={handleCancel}
        handleSave={handleSave}
        title={t('treeDetails.tabs.subTitles.conclusionsAssessment')}
      />
      <div className={`${styles.flexContainer}`}>
        <div className={props.singleColumn ? styles.singleColumn : styles.accordionPairsContainer}>
          <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
            <EditingFieldWrapper
              title={t('details.properties.furtherInspectionNeeded')}
              value={furtherInspectionNeededValue}
              editing={editing}
            >
              <DateInput
                onValueChange={item => setRisks(prev => ({ ...prev, furtherInspectionNeeded: item }))}
                value={risks.furtherInspectionNeeded ? new Date(risks.furtherInspectionNeeded) : null}
                label=""
                className={styles.lightBackground}
                futureDateOnly={true}
              />
            </EditingFieldWrapper>
            <EditingFieldWrapper
              title={t('details.properties.overallTreeRiskRating')}
              value={treeRiskValue}
              editing={editing}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={riskRatings}
                value={{ translationKey: treeRiskValue }}
                onSelect={item => setRisks(prev => ({
                  ...prev,
                  overallTreeRiskRating: item.id
                }))}
              />
            </EditingFieldWrapper>
          </div>
          <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
            <EditingFieldWrapper
              title={t('details.properties.overallResidualRisk')}
              value={residualRiskValue}
              editing={editing}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={riskRatings}
                value={{ translationKey: residualRiskValue }}
                onSelect={item => setRisks(prev => ({
                  ...prev,
                  overallResidualRisk: item.id
                }))}
              />
            </EditingFieldWrapper>
            <EditingFieldWrapper
              title={t('tree.viStatus')}
              value={t(Tree.getTKeyForProperty(DisplayableTreeProperty.ViStatus, props.tree.viStatus)).toString() || ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

interface ConclusionsAssessmentProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organizationId: string
}
