import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import styles from './PointCloudViewer.module.scss';
import * as THREE from 'three';
import { Tree } from '../../tree/Tree';
import { useTranslation } from 'react-i18next';
import { PointCloudView } from './PointCloudView';
import { RulerGroup } from './RulerGroup';
import { LoadablePointCloud } from '../../point-cloud/LoadablePointCloud';
import { MultiOrbitControl } from './MultiOrbitControl';
import { useCurrentAccount } from '../../account/useAccounts';
import { METER_TO_FEET } from './unitConstants';

export default function PointCloudViewerWithRulers(props: PointCloudViewerWithRulersProps) {
  const { t } = useTranslation();
  const organization = useCurrentAccount().organization;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const viewRef = useRef(new PointCloudView());
  const treePointCloud = useRef<THREE.Group>(new THREE.Group());
  const environmentPointCloud = useRef<THREE.Group>(new THREE.Group());
  const rulersRef = useRef<RulerGroup[]>([]);

  const [isViewInitialized, setIsViewInitialized] = useState(false);

  useEffect(() => {
    if (canvasRef.current === null || props.multiControlsRef.current === null) return;

    const view = viewRef.current;
    view.init(canvasRef.current, props.multiControlsRef.current);

    setIsViewInitialized(true);
    return () => {
      view.dispose();
    };
  }, [canvasRef.current, props.multiControlsRef.current]);

  useEffect(() => {
    if (!props.tree) return;
    const halfHeight = organization.getIsMetrical()
      ? props.tree.height / 2
      : props.tree.height / METER_TO_FEET / 2;
    viewRef.current.resetTo(halfHeight, props.tree.canopyDirection);
  }, [isViewInitialized, props.tree, props.seed, organization]);

  const [isPointCloudLoading, setIsPointCloudLoading] = useState(true);
  const [tree, setTree] = useState<Tree | null>(null);
  useEffect(() => {
    if (props.tree === null) return;

    setTree(props.tree);

    const isMetrical = organization.getIsMetrical();
    rulersRef.current = [
      RulerGroup.forHeight(props.tree, t('analytics.properties.height'), viewRef.current.render, isMetrical),
      RulerGroup.forFirstBifurcation(props.tree, t('analytics.properties.trunkHeight'), viewRef.current.render, isMetrical),
      RulerGroup.forCanopyWidth(props.tree, t('analytics.properties.canopyWidth'), viewRef.current.render, isMetrical),
      RulerGroup.forCanopyHeight(props.tree, t('analytics.properties.canopyHeight'), viewRef.current.render, isMetrical),
      RulerGroup.forDBH(organization, props.tree, t('analytics.properties.trunkDiameter'), viewRef.current.render)
    ];

    const pos = props.tree!.localizedLocation;
    const environmentPointSize = 1.6;
    Promise.all([
      new LoadablePointCloud(props.tree!.getPointCloudUrl(organization)).loadInto(pos, false).then(pc => treePointCloud.current.add(pc)),
      new LoadablePointCloud(props.tree!.getEnvironmentPointCloudUrl(organization), environmentPointSize)
        .loadInto(pos, true)
        .then(pc => environmentPointCloud.current.add(pc))
    ]).then(() => setIsPointCloudLoading(false));
  }, [props.tree, t, organization]);

  useEffect(() => {
    if (tree === null || isPointCloudLoading || !isViewInitialized) return;

    viewRef.current.addEventListeners();
    viewRef.current.clear();
    viewRef.current.addPointClouds(
      props.showEnvironment ? [treePointCloud.current, environmentPointCloud.current] : [treePointCloud.current]
    );

    viewRef.current.addRulers(
      rulersRef.current
        .filter(it => props.rulers.includes(it.propertyName))
        .map(it => (props.rulers.length === 1 && it.propertyName !== 'canopyWidth' ? it.displayedInTheMiddle() : it.displayedInOriginalPlace()))
    );

    viewRef.current.addGrid();
    viewRef.current.setCanvasSize();

    return () => {
      viewRef.current.removeEventListeners();
    };
  }, [isPointCloudLoading, isViewInitialized, tree, props.showEnvironment, JSON.stringify(props.rulers)]);

  return (
    <div className={styles.container}>
      <canvas ref={canvasRef} className={styles.canvas} />

      {isPointCloudLoading && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
}

interface PointCloudViewerWithRulersProps {
  tree: Tree | null,
  rulers: string[],
  showEnvironment: boolean,
  multiControlsRef: MutableRefObject<MultiOrbitControl>,
  seed: string
}
