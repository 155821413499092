import * as THREE from 'three';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { LASFile } = require('./laslaz');

class PointCloud {
  key: any;
  points: any;
  pointCount: any;
  scale: any;
  offset: any;
  mins: number[];
  maxs: any;
  colorSpecified: boolean;
  filterPoints: any;

  constructor(key, points, pointCount, scale, offset, mins, maxs, filterPoints?) {
    this.key = key;
    this.points = points;
    this.pointCount = pointCount;
    this.scale = scale;
    this.offset = offset;
    this.mins = mins;
    this.maxs = maxs;
    this.colorSpecified = false;
    this.filterPoints = filterPoints;
  }
}

abstract class GeometryProcessor {
  static gradient: [number, [number, number, number]][] = [
    [0, [0, 0, 255]],
    [0.25, [0, 255, 0]],
    [0.5, [255, 255, 0]],
    [0.75, [255, 0, 0]],
    [1, [255, 255, 255]]
  ];

  static pickHex(color1, color2, weight) {
    const w1 = weight;
    const w2 = 1 - w1;
    return [
      Math.round(color1[0] * w1 + color2[0] * w2) / 255.0,
      Math.round(color1[1] * w1 + color2[1] * w2) / 255.0,
      Math.round(color1[2] * w1 + color2[2] * w2) / 255.0
    ];
  }

  mx: null | any;
  mn: null | any;
  z_max: null | any;
  z_min: null | any;
  config: Record<string, unknown>;

  constructor() {
    this.mx = null;
    this.mn = null;
    this.z_max = null;
    this.z_min = null;
    this.config = {};
  }

  getColorClassification(p): null | string {
    return null;
  }

  processGeometry(pointCloud, isEnvironment: boolean) {
    const geometry = new THREE.BufferGeometry();
    let count = pointCloud.pointCount;
    let classified = false;

    if (typeof this.config.loadUnclassified !== 'undefined' && !this.config.loadUnclassified) {
      let classifiedCount = 0;
      for (let i = 0; i < count; i++) {
        let p = null;
        if (typeof pointCloud.points === 'function') {
          p = pointCloud.points(i);
        } else {
          p = pointCloud.points[i];
        }

        const colorClass = this.getColorClassification(p);
        if (colorClass) {
          classifiedCount++;
          classified = true;
        }
      }
      count = classifiedCount;
      if (!classified) {
        count = pointCloud.pointCount;
      }
    }

    const positions = new Float32Array(count * 3);
    const colors = new Float32Array(count * 3);
    const intensity = new Float32Array(count);
    const classification = new Float32Array(count);

    pointCloud.colorSpecified = false;

    const corrective = new THREE.Vector3(pointCloud.mins[0], pointCloud.mins[1], pointCloud.mins[2]);

    pointCloud.classificationColor = true;
    pointCloud.colorSpecified = true;

    let i = 0;
    for (let pointIndex = 0; pointIndex < pointCloud.pointCount; pointIndex++) {
      const point =
        typeof pointCloud.points === 'function' ? pointCloud.points(pointIndex) : pointCloud.points[pointIndex];
      const colorClass = this.getColorClassification(point);

      if ((!colorClass || !['canopy', 'branch'].includes(colorClass)) && !isEnvironment) continue;

      if (
        typeof this.config.loadUnclassified !== 'undefined' &&
        !this.config.loadUnclassified &&
        classified &&
        colorClass
      ) {
        continue;
      }

      const x = point.position[0] * pointCloud.scale[0] + pointCloud.offset[0];
      const y = point.position[1] * pointCloud.scale[1] + pointCloud.offset[1];
      const z = point.position[2] * pointCloud.scale[2] + pointCloud.offset[2];

      if (this.mx === null) {
        this.mx = new THREE.Vector3(x, y, z);
      } else {
        this.mx.set(Math.max(this.mx.x, x), Math.max(this.mx.y, y), Math.max(this.mx.z, z));
      }

      if (this.mn === null) {
        this.mn = new THREE.Vector3(x, y, z);
      } else {
        this.mn.set(Math.min(this.mn.x, x), Math.min(this.mn.y, y), Math.min(this.mn.z, z));
      }

      let color;
      if (pointCloud.classificationColor) {
        color = this.getColor(colorClass);
      } else {
        const index = GeometryProcessor.gradient.findIndex(([rangeStart]) => point.intensity / 65536 < rangeStart);
        const minColor = GeometryProcessor.gradient[index - 1];
        const maxColor = GeometryProcessor.gradient[index];
        color = GeometryProcessor.pickHex(
          minColor[1],
          maxColor[1],
          1 - (point.intensity / 65535 - minColor[0]) / (maxColor[0] - minColor[0])
        );
        pointCloud.colorSpecified = true;
      }

      if (this.config.corrective) {
        positions[3 * i] = x - corrective.x;
        positions[3 * i + 1] = y - corrective.y;
        positions[3 * i + 2] = z - corrective.z;
      } else {
        positions[3 * i] = x;
        positions[3 * i + 1] = y;
        positions[3 * i + 2] = z;
      }

      if (colorClass) {
        this.z_max = this.z_max === null ? positions[3 * i + 2] : Math.max(this.z_max, positions[3 * i + 2]);
        this.z_min = this.z_min === null ? positions[3 * i + 2] : Math.min(this.z_min, positions[3 * i + 2]);
      }

      colors[3 * i] = color[0];
      colors[3 * i + 1] = color[1];
      colors[3 * i + 2] = color[2];

      intensity[i] = point.intensity;
      classification[i] = point.classification;

      i++;
    }

    if (positions.length > 0) {
      geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    }
    if (colors.length > 0 && pointCloud.colorSpecified) {
      geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    }
    if (intensity.length > 0) {
      geometry.setAttribute('intensity', new THREE.BufferAttribute(intensity, 1));
    }
    geometry.computeBoundingSphere();

    return geometry;
  }

  getColor(colorClass) {
    return {
      canopy: [0.2823529411764706, 0.7333333333333333, 0.47058823529411764], // #48bb78
      branch: [0.4745098039215686, 0.3333333333333333, 0.2823529411764706], // #795548
      otherCanopy: [0.2196078431372549, 0.4666666666666667, 0.3411764705882353], // #387757
      otherBranch: [0.3176470588235294, 0.26666666666666666, 0.24705882352941178], // #51443f
      roadClearing: [1, 0.27058823529411763, 0.3686274509803922] // #ff455e
    }[colorClass] ?? [0.36470588235294116, 0.49411764705882355, 0.5294117647058824]; // #5d7e87
  }

  setConfig(config) {
    this.config = {
      ...this.config,
      ...config
    };
  }

  abstract parse(data, path, onLoad, onError, skip: number, isEnvironment: boolean): any;
}

class LASProcessor extends GeometryProcessor {
  lasName = '';
  loader: any;

  constructor(loader) {
    super();
    this.loader = loader;
    this.config = {
      ...super['config'],
      corrective: true,
      loadUnclassified: true,
      pointsMaterial: {
        size: 0.05
      }
    };
  }

  /**
   * Merge config for material and color classification
   *
   * @param {*} config The configuration object
   */
  override setConfig(config) {
    super.setConfig(config);
    this.config = {
      ...this.config,
      ...config
    };
  }

  parse(data, path, onLoad, onError, skip = 1, isEnvironment: boolean) {
    const worker = new Worker('/vendor/workers/laz-loader-worker.js');
    let lasFile = new LASFile(data, worker);
    let lasHeader;
    return lasFile
      .open()
      .then(() => {
        lasFile.isOpen = true;
        return lasFile;
      })
      .then(lasFile => {
        return lasFile.getHeader().then(h => {
          return [lasFile, h];
        });
      })
      .then(v => {
        lasFile = v[0];
        lasHeader = v[1];

        if (!lasHeader) {
          throw new Error('las header is undefined');
        }

        return lasFile.readData(lasHeader.pointsCount, 0, skip);
      })
      .then(({ buffer, count }) => {
        const Unpacker = lasFile.getUnpacker();
        const decoder = new Unpacker(buffer, count, lasHeader);

        const pcAndMesh = this.processLAS(decoder, isEnvironment);
        onLoad(pcAndMesh);
      })
      .catch(err => onError(err))
      .finally(() => {
        lasFile.close();
        worker.terminate();
      });
  }

  override getColorClassification(p) {
    if (p.classification === 21) {
      return 'canopy';
    } else if (p.classification === 20) {
      return 'branch';
    } else if (p.classification === 22) {
      return 'otherBranch';
    } else if (p.classification === 23) {
      return 'otherCanopy';
    } else if (p.classification === 30) {
      return 'roadClearing';
    }

    return null;
  }

  processLAS(lasBuffer, isEnvironment: boolean): LASLoaderResult {
    const pc = new PointCloud(
      'pc',
      i => lasBuffer.getPoint(i),
      lasBuffer.pointsCount,
      lasBuffer.scale,
      lasBuffer.offset,
      lasBuffer.mins,
      lasBuffer.maxs
    );
    const geometry = this.processGeometry(pc, isEnvironment);
    const three = THREE;
    const createMaterial = (THREE = three, size = 1, opacity = 1.0) => {
      const material = new THREE.PointsMaterial({
        size: 1, /*todo: consensual temporary value*/
        opacity,
        sizeAttenuation: false
      });

      material.clipIntersection = false;

      if (pc.colorSpecified) {
        material.vertexColors = true;
      } else {
        material.color.setHex(0xf8f8f8);
      }

      material.userData.setSize = newSize => {
        material.size = newSize ? size * newSize : size;
        material.needsUpdate = true;
      };

      material.userData.defaultSize = size;

      material.userData.filterPoints = coords => {
        if (!coords) {
          return (material.clippingPlanes = []);
        }
        const { minZ, maxZ } = coords;
        material.clippingPlanes = [
          new THREE.Plane(new THREE.Vector3(0, 0, 1), -minZ),
          new THREE.Plane(new THREE.Vector3(0, 0, -1), maxZ)
        ];
      };

      return material;
    };

    return {
      pc,
      geometry,
      material: createMaterial(),
      name: this.lasName,
      createMaterial
    };
  }
}

export interface LASLoaderResult {
  pc: PointCloud,
  geometry: THREE.BufferGeometry,
  material: THREE.Material,
  name: string,
  createMaterial: (three?: any, size?: number, opacity?: number) => THREE.PointsMaterial
}

class LoaderBase extends THREE.Loader {
  responseType: any;
  processor?: GeometryProcessor;
  config?: Record<string, unknown>;
  isInProgress = false;
  callAfterDone: any[] = [];

  constructor(manager, responseType) {
    super(manager);
    this.manager = manager;
    this.responseType = responseType;
    this.setWithCredentials(true);
  }

  /**
   * Set GeometryProcessor accessor method
   *
   * @param {*} processor The geometry processor instance
   */
  setProcessor(processor) {
    this.processor = processor;
  }

  /**
   * Get GeometryProcessor accessor method
   *
   * @returns The geometry processor instance
   */
  getProcessor() {
    return this.processor;
  }

  load(url, isEnvironment, onLoad?, onProgress?, onError?, skip = 1, isCalledFromCallback = false) {
    if (this.isInProgress && !isCalledFromCallback) {
      this.callAfterDone.push(() => this.load(url, isEnvironment, onLoad, onProgress, onError, skip, true));
      return;
    }

    let resourcePath;

    this.isInProgress = true;

    if (this.resourcePath !== '') {
      resourcePath = this.resourcePath;
    } else if (this.path !== '') {
      resourcePath = this.path;
    } else {
      resourcePath = THREE.LoaderUtils.extractUrlBase(url);
    }

    // Tells the LoadingManager to track an extra item, which resolves after
    // the model is fully loaded. This means the count of items loaded will
    // be incorrect, but ensures manager.onLoad() does not fire early.
    this.manager.itemStart(url);

    const _onError = e => {
      if (onError) {
        onError(e);
      } else {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      this.manager.itemError(url);
      this.manager.itemEnd(url);

      if (this.callAfterDone.length) {
        setTimeout(this.callAfterDone.pop(), 0);
      } else {
        this.isInProgress = false;
      }
    };

    const loader = new THREE.FileLoader(this.manager);

    loader.setPath(this.path);
    loader.setResponseType(this.responseType);
    loader.setRequestHeader(this.requestHeader);
    loader.setWithCredentials(this.withCredentials);

    loader.load(
      url,
      data => {
        try {
          this.processor?.parse(
            data,
            resourcePath,
            result => {
              onLoad(result);
              this.manager.itemEnd(url);

              if (this.callAfterDone.length) {
                setTimeout(this.callAfterDone.pop(), 0);
              } else {
                this.isInProgress = false;
              }
            },
            _onError,
            skip,
            isEnvironment
          );
        } catch (e) {
          _onError(e);
        }
      },
      onProgress,
      _onError
    );
  }

  setConfig(config) {
    this.config = {
      ...this.config,
      config
    };
  }
}

export default class LASLoader extends LoaderBase {
  constructor(manager) {
    super(manager, 'arraybuffer');
    this.setProcessor(new LASProcessor(this));
    this.manager = manager;
  }
}
