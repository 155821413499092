import { AxiosInstance } from 'axios';
import { Task, TaskDto, TaskUpdateDto } from './Task';
import { User } from '../users/User';
import TaskTemplate, { TaskTemplateWithTreesDto } from '../routes/TaskManager/create/TaskTemplate';
import { TaskTemplateDto } from '../routes/TaskManager/create/CreateTasks';
import { QueryClient } from 'react-query';
import { TreeDto } from '../tree/Tree';
import PartialTree from '../tree/PartialTree';
import { SortingDirection } from '../components/UI/SortButton/SortButton';
import { JobColumnName } from '../routes/TaskManager/create/JobColumnName';

export class TaskManagerService {
  constructor(
    private readonly http: AxiosInstance,
    private readonly queryClient: QueryClient
  ) {}

  async listTaskForUser(organizationId: string): Promise<Task[]> {
    const response = await this.http.get<TaskDto[]>(`/v1/organizations/${organizationId}/task-manager/tasks`);
    return response.data.map(Task.fromDto);
  }

  async updateTask(organizationId: string, taskId: string, task: TaskUpdateDto): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/task-manager/tasks/${taskId}`, task);
    await this.queryClient.invalidateQueries(`${organizationId}-tasks`);
  }

  async clearNotification(organizationId: string, taskId: string): Promise<void> {
    await this.http.delete(`/v1/organizations/${organizationId}/task-manager/tasks/${taskId}/users-to-notify/me`);
    await this.queryClient.invalidateQueries(`${organizationId}-tasks`);
  }

  async addComment(organizationId: string, taskId: string, comment: { content: string }): Promise<void> {
    await this.http.post(`/v1/organizations/${organizationId}/task-manager/tasks/${taskId}/comment`, comment);
    await this.queryClient.invalidateQueries(`${organizationId}-tasks`);
  }

  async listPossibleAssignees(organizationId: string) {
    const response = await this.http.get(`/v1/organizations/${organizationId}/task-manager/possible-assignees`);
    return response.data.map(User.fromDto);
  }

  async deleteTask(organizationId: string, taskId: string): Promise<void> {
    await this.http.delete(`/v1/organizations/${organizationId}/task-manager/tasks/${taskId}`);
    await this.queryClient.invalidateQueries(`${organizationId}-tasks`);
  }

  async listTaskTemplatesWithTrees(organizationId: string, managedAreaIds: string[], reversed?: boolean): Promise<TaskTemplateWithTreesDto[]> {
    const response = await this.http.post<TaskTemplateWithTreesDto[]>(`/v1/organizations/${organizationId}/task-manager/task-templates-with-trees${reversed ? '?reverseMASelection=true' : ''}`, {
      managedAreaIds
    });
    return response.data;
  }

  async listTaskTemplates(organizationId: string): Promise<TaskTemplate[]> {
    const { data } = await this.http.get<TaskTemplate[]>(`/v1/organizations/${organizationId}/task-manager/task-templates`);
    return data;
  }

  async listTreesByArea(
    organizationId: string,
    jobColumnName: string,
    managedAreaIds: string[],
    reverseMASelection: boolean,
    fields: string[],
    sortProperty: string | null,
    sortingDirection: SortingDirection | null
  ) {
    const params = new URLSearchParams();

    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');
    fields.forEach(it => params.append('fields', it));
    if (sortProperty && sortingDirection) {
      params.append('sortProperty', sortProperty);
      params.append('sortingDirection', sortingDirection);
    }

    const response = await this.http.get<TreeDto[]>(
      `/v1/organizations/${organizationId}/task-manager/${jobColumnName}/trees`,
      { params }
    );
    return response.data.map(PartialTree.fromDto);
  }

  async listTreesByIds(
    organizationId: string,
    treeIds: string[],
    fields: string[],
    sortProperty: string | null,
    sortingDirection: SortingDirection | null
  ) {
    const params = new URLSearchParams();
    fields.forEach(it => params.append('fields', it));
    if (sortProperty && sortingDirection) {
      params.append('sortProperty', sortProperty);
      params.append('sortingDirection', sortingDirection);
    }

    const response = await this.http.post<TreeDto[]>(
      `/v1/organizations/${organizationId}/task-manager/trees-by-ids`,
      treeIds,
      { params }
    );
    return response.data.map(PartialTree.fromDto);
  }

  async createTaskTemplate(organizationId: string, taskTemplate: TaskTemplateDto) {
    const response = await this.http.post(`/v1/organizations/${organizationId}/task-manager/task-templates`, { ...taskTemplate });
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates-with-trees`);
    return response.data;
  }

  async updateTaskTemplate(organizationId: string, taskTemplateId: string, taskTemplate: TaskTemplateDto) {
    const response = await this.http.put(`/v1/organizations/${organizationId}/task-manager/task-templates/${taskTemplateId}`, { ...taskTemplate });
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates-with-trees`);
    return response.data;
  }

  async deleteTaskTemplate(organizationId: string, taskTemplateId: string) {
    const response = await this.http.delete(`/v1/organizations/${organizationId}/task-manager/task-templates/${taskTemplateId}`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates-with-trees`);
    return response.data;
  }

  async createTask(dto: CreateTaskDto) {
    const params = new URLSearchParams();

    dto.managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (dto.reverseMASelection) params.append('reverseMASelection', 'true');

    const url = `v1/organizations/${dto.organizationId}/task-manager/tasks?${params.toString()}`;

    const response = await this.http.post(url, {
      type: dto.type,
      treeIds: dto.treeIds,
      connectedModule: dto.connectedModule,
      jobColumnName: dto.jobColumnName
    });
    await this.queryClient.invalidateQueries(`${dto.organizationId}-task-templates`);
    await this.queryClient.invalidateQueries(`${dto.organizationId}-task-templates-with-trees`);

    return response.status;
  }

  async getTask(organizationId: string, taskId: string): Promise<Task> {
    const response = await this.http.get<TaskDto>(`/v1/organizations/${organizationId}/task-manager/tasks/${taskId}`);
    return Task.fromDto(response.data);
  }
}

interface CreateTaskDto {
  organizationId: string,
  managedAreaIds: string[],
  reverseMASelection: boolean,
  type: string,
  treeIds: string[],
  connectedModule?: string,
  jobColumnName: JobColumnName
}
