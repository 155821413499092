import { NavArrowDown, NavArrowRight } from 'iconoir-react';
import styles from './Accordion.module.scss';
import { ReactNode } from 'react';

export default function ControlledAccordion(props: ControlledAccordionProps) {
  return (
    <div className={styles.accordionWrapper}>
      <section className={`${props.isOpen ? styles.open : ''}`}>
        <button
          className={`${styles.accordion} ${props.isOpen ? styles.open : ''}`}
          onClick={() => props.toggleOpen()}
        >
          <div>
            <div className={styles.toggleIcon}>{props.isOpen ? <NavArrowDown/> : <NavArrowRight/>}</div>
            <h3 className={styles.accordionTitle}>{props.title}</h3>
          </div>
          <span className={styles.redDot}/>
        </button>
        <div className={styles.panel}>
          {props.children}
        </div>
      </section>
    </div>
  );
}

export interface ControlledAccordionProps {
  children?: ReactNode,
  title: string,
  isOpen: boolean,
  toggleOpen: () => void
}
