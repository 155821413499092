import { useContext } from 'react';
import { useQuery } from 'react-query';
import DependencyInjectionContext from '../DependencyInjectionContext';
import { Task } from './Task';
import { useLocation } from 'react-router-dom';
import { useCurrentAccount } from '../account/useAccounts';
import { Features } from '../switches/Features';

export function useTasks(): { tasks: Task[], isLoading: boolean } {
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const location = useLocation();
  const isTasksPage = location.pathname.includes('task-manager/manage');

  const cacheKey = `${organization.id}-tasks`;
  const { data, isLoading } = useQuery(
    [cacheKey],
    () => {
      if (!organization.isFeatureEnabled(Features.taskManager)) return [];
      return taskManagerService.listTaskForUser(organization.id);
    },
    {
      retry: 1,
      retryOnMount: false,
      refetchInterval: isTasksPage ? 10000 : false
    }
  );

  return {
    tasks: data ?? [],
    isLoading
  };
}
