export default function AddPageIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <rect
          x="0.65"
          y="0.65"
          width="16.2714"
          height="16.2714"
          rx="3.35"
          stroke="white"
          strokeWidth="1.3" />
        <rect
          x="5.07857"
          y="5.07859"
          width="16.2714"
          height="16.2714"
          rx="3.35"
          fill="#222D31"
          stroke="white"
          strokeWidth="1.3" />
        <path
          d="M13.2143 10L13.2143 16.4286"
          stroke="white"
          strokeWidth="1.3"
          strokeLinecap="round" />
        <path
          d="M10 13.2143L16.4286 13.2143"
          stroke="white"
          strokeWidth="1.3"
          strokeLinecap="round" />
      </g>
    </svg>
  );
}
