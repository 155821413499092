import { AccountType } from '../../../account/AccountType';
import { Member } from '../../../organization/Member';
import { Interest } from '../../../account/Interest';
import { MemberStatus } from '../../../organization/MemberStatus';

export class MemberFilters {
  static fromQuery(params: URLSearchParams) {
    const validAccountTypes: string[] = Object.values(AccountType);
    const accountTypes = params.getAll('accountType').filter((it): it is AccountType => validAccountTypes.includes(it));

    const validInterests: string[] = Object.values(Interest);
    const interests = params.getAll('interest').filter((it): it is Interest => validInterests.includes(it));

    const validStatuses: string[] = Object.values(MemberStatus);
    const hiddenStatuses = params
      .getAll('hiddenStatuses')
      .filter((it): it is MemberStatus => validStatuses.includes(it));

    return new MemberFilters(
      params,
      accountTypes,
      interests,
      MemberFilters.parseDate(params.get('lastLoginSince')),
      MemberFilters.parseDate(params.get('lastLoginUntil')),
      hiddenStatuses,
      params.get('q') ?? ''
    );
  }

  private static parseDate(raw: string | null) {
    if (raw === null) {
      return null;
    }
    const date = new Date(raw);
    return isNaN(date.getTime()) ? null : date;
  }

  private constructor(
    private readonly initialQueryParams: URLSearchParams,
    public accountTypes: AccountType[],
    public interests: Interest[],
    public lastLoginSince: Date | null,
    public lastLoginUntil: Date | null,
    public hiddenStatuses: MemberStatus[],
    public query: string
  ) {}

  toSearchParams(): URLSearchParams {
    const params = new URLSearchParams();

    Array.from(this.initialQueryParams.entries()).forEach(([key, value]) => params.append(key, value));

    params.delete('accountType');
    this.accountTypes.forEach(it => params.append('accountType', it));

    params.delete('interest');
    this.interests.forEach(it => params.append('interest', it));

    params.delete('lastLoginSince');
    if (this.lastLoginSince) {
      params.append('lastLoginSince', this.lastLoginSince.toISOString().slice(0, 10));
    }

    params.delete('lastLoginUntil');
    if (this.lastLoginUntil) {
      params.append('lastLoginUntil', this.lastLoginUntil.toISOString().slice(0, 10));
    }

    params.delete('hiddenStatuses');
    this.hiddenStatuses.forEach(it => params.append('hiddenStatuses', it));

    params.delete('q');
    if (this.query.length > 0) {
      params.append('q', this.query);
    }

    return params;
  }

  setQuery(query: string) {
    this.query = query;

    return this;
  }

  filter(members: Member[]) {
    return members
      .filter(it => (this.accountTypes.length > 0 ? it.hasAnyAccountType(this.accountTypes) : true))
      .filter(it => (this.interests.length > 0 ? it.isInterestedInAny(this.interests) : true))
      .filter(it => (this.lastLoginSince ? it.hasLoggedInSince(this.lastLoginSince) : true))
      .filter(it => (this.lastLoginUntil ? it.hasLoggedInUntilEoD(this.lastLoginUntil) : true))
      .filter(it => (this.hiddenStatuses.length > 0 ? !it.hasAnyStatus(this.hiddenStatuses) : true))
      .filter(it => (this.query.length > 0 ? it.matchesSearchQuery(this.query.trim().toLowerCase()) : true));
  }

  reset() {
    this.interests = [];
    this.accountTypes = [];
    this.lastLoginSince = null;
    this.lastLoginUntil = null;
    this.hiddenStatuses = [];
    this.query = '';

    return this;
  }

  setLastLoginSince(lastLoginSince: Date | null) {
    this.lastLoginSince = lastLoginSince;

    return this;
  }

  setLastLoginUntil(lastLoginUntil: Date | null) {
    this.lastLoginUntil = lastLoginUntil;

    return this;
  }

  toggleAccountType(accountType: AccountType) {
    if (this.accountTypes.includes(accountType)) {
      return this.removeAccountType(accountType);
    }

    return this.addAccountType(accountType);
  }

  toggleInterest(interest: Interest) {
    if (this.interests.includes(interest)) {
      return this.removeInterest(interest);
    }
    return this.addInterest(interest);
  }

  toggleStatus(status: MemberStatus) {
    if (this.hiddenStatuses.includes(status)) {
      return this.removeStatus(status);
    }
    return this.addStatus(status);
  }

  isActive() {
    return (
      this.interests.length > 0 ||
      this.accountTypes.length > 0 ||
      !!this.lastLoginSince ||
      this.hiddenStatuses.length > 0 ||
      this.query.length > 0
    );
  }

  private removeAccountType(accountType: AccountType) {
    this.accountTypes = this.accountTypes.filter(it => it !== accountType);

    return this;
  }

  private addAccountType(accountType: AccountType) {
    this.accountTypes.push(accountType);

    return this;
  }

  private removeInterest(interest: Interest) {
    this.interests = this.interests.filter(it => it !== interest);

    return this;
  }

  private addInterest(interest: Interest) {
    this.interests.push(interest);

    return this;
  }

  private removeStatus(status: MemberStatus) {
    this.hiddenStatuses = this.hiddenStatuses.filter(it => it !== status);
    return this;
  }

  private addStatus(status: MemberStatus) {
    this.hiddenStatuses.push(status);
    return this;
  }
}
