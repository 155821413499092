import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import CohortColor from '../../../../../components/cohort/CohortColor';

export type CohortStudyValue = { value: number, sigma: number, mu: number, year: string };

interface CohortHistoryChartProps {
  data: CohortStudyValue[],
  setLastValue?: (value: number) => void
}

export function CohortHistoryChart(props: CohortHistoryChartProps) {
  const labelPlugin = {
    id: 'labelPlugin',
    afterDraw: (chart: Chart) => {
      if (!props.setLastValue) return;
      props.setLastValue(
        ((chart.data.datasets[0].data.at(-2) as number) - chart.scales.y.min)
        / (chart.scales.y.max - chart.scales.y.min)
        * chart.scales.y.height
      );
    }
  };

  Chart.defaults.font.family = "'Inter', sans-serif";
  const options = {
    animation: { duration: 0 },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        grid: { color: 'rgba(255, 255, 255, 0.5)' },
        ticks: { color: 'rgba(255, 255, 255, 0.5)' }
      },
      x: {
        grid: { color: 'rgba(255, 255, 255, 0.5)' },
        ticks: { color: 'rgba(255, 255, 255, 0.5)' }
      }
    }
  };
  const extendedData = [props.data[0], ...props.data, props.data.at(-1)!];

  const data = {
    labels: extendedData.map((it, index) => index === 0 || index === extendedData.length - 1 ? '' : it.year),
    datasets: [
      {
        data: extendedData.map((it, index) => index === 0 || index === extendedData.length - 1 ? undefined : it.value),
        fill: false,
        tension: 0.1
      },
      {
        data: extendedData.map((it, index) => index === 0 || index === extendedData.length - 1 ? undefined : it.value),
        fill: false,
        borderColor: 'rgb(255, 183, 0)',
        pointBackgroundColor: 'rgb(255, 183, 0)',
        tension: 0.1
      },
      {
        data: extendedData.map((it, index) => index === 0 || index === extendedData.length - 1 ? undefined : it.mu),
        fill: false,
        borderColor: 'rgba(255, 255, 255, 0.7)',
        borderDash: [10, 5],
        borderWidth: 2,
        tension: 0.1,
        pointRadius: 0
      },
      {
        data: extendedData.map(it => it.mu + it.sigma * 3),
        backgroundColor: `rgba(${CohortColor.rgbs.aboveTwoSigma}, 0.7)`,
        borderColor: 'transparent',
        fill: '+1',
        tension: 0.4,
        pointRadius: 0
      },
      {
        data: extendedData.map(it => it.mu + it.sigma * 2),
        fill: '+1',
        backgroundColor: `rgba(${CohortColor.rgbs.betweenOneAndTwoSigma}, 0.7)`,
        borderColor: 'transparent',
        tension: 0.4,
        pointRadius: 0
      },
      {
        data: extendedData.map(it => it.mu + it.sigma),
        fill: '+1',
        backgroundColor: `rgba(${CohortColor.rgbs.withinOneSigma}, 0.7)`,
        borderColor: 'transparent',
        tension: 0.4,
        pointRadius: 0
      },
      {
        data: extendedData.map(it => it.mu - it.sigma),
        fill: '+1',
        backgroundColor: `rgba(${CohortColor.rgbs.betweenMinusOneAndTwoSigma}, 0.7)`,
        borderColor: 'transparent',
        tension: 0.4,
        pointRadius: 0
      },
      {
        data: extendedData.map(it => it.mu - it.sigma * 2),
        fill: '+1',
        backgroundColor: `rgba(${CohortColor.rgbs.belowTwoSigma}, 0.7)`,
        borderColor: 'transparent',
        tension: 0.4,
        pointRadius: 0
      },
      {
        data: extendedData.map(it => it.mu - it.sigma * 3),
        fill: false,
        tension: 0.4,
        borderRadius: 0
      }
    ]
  };

  return (
    <Line
      data={data}
      options={options}
      plugins={[labelPlugin]}
    />
  );
}
