import { RefObject, useEffect, useMemo, useState } from 'react';

import GeoJsonLayer from './map-layers/GeoJsonLayer';
import mapboxgl from 'mapbox-gl';
import { DisplayableTreeProperty } from '../../../tree/Tree';
import { useCurrentAccount } from '../../../account/useAccounts';
import { loadLayers } from '../../../iso-lines/ISO_LINES';

const COLORS = [
  { id: 0, value: '215,25,28' },
  { id: 1, value: '221,50,40' },
  { id: 2, value: '228,75,51' },
  { id: 3, value: '234,100,63' },
  { id: 4, value: '240,124,74' },
  { id: 5, value: '247,149,86' },
  { id: 6, value: '253,174,97' },
  { id: 7, value: '253,188,113' },
  { id: 8, value: '254,201,128' },
  { id: 9, value: '254,215,144' },
  { id: 10, value: '254,228,160' },
  { id: 11, value: '255,242,175' },
  { id: 12, value: '255,255,191' },
  { id: 13, value: '241,249,187' },
  { id: 14, value: '227,244,182' },
  { id: 15, value: '213,238,178' },
  { id: 16, value: '199,232,173' },
  { id: 17, value: '185,227,169' },
  { id: 18, value: '171,221,164' },
  { id: 19, value: '150,206,168' },
  { id: 20, value: '128,191,171' },
  { id: 21, value: '107,176,175' },
  { id: 22, value: '86,161,179' },
  { id: 23, value: '64,146,182' }
];

const THERMAL_COMFORT_COLORS = [
  { id: 0, value: '48, 186, 26' },
  { id: 1, value: '48, 186, 26' },
  { id: 2, value: '48, 186, 26' },
  { id: 3, value: '48, 186, 26' },
  { id: 4, value: '48, 186, 26' },
  { id: 5, value: '48, 186, 26' },
  { id: 6, value: '48, 186, 26' },
  { id: 7, value: '48, 186, 26' },
  { id: 8, value: '48, 186, 26' },
  { id: 9, value: '56,188,35' },
  { id: 10, value: '71,193,52' },
  { id: 11, value: '87,197,70' },
  { id: 12, value: '102,201,87' },
  { id: 13, value: '146,217,117' },
  { id: 14, value: '190,232,147' },
  { id: 15, value: '235,248,177' },
  { id: 16, value: '254,230,162' },
  { id: 17, value: '253,183,107' },
  { id: 18, value: '243,134,78' },
  { id: 19, value: '230,84,55' },
  { id: 20, value: '222,53,41' },
  { id: 21, value: '220,46,38' },
  { id: 22, value: '219,39,35' },
  { id: 23, value: '217,32,31' }
];

interface IsoLineProps {
    mapRef: RefObject<mapboxgl.Map>,
    selectedProperty: DisplayableTreeProperty | null,
    organizationId: string,
    treeLayerId: string,
    isTreeLayerLoaded: boolean
}

const Isoline = ({ mapRef, selectedProperty, organizationId, treeLayerId, isTreeLayerLoaded }: IsoLineProps) => {
  const account = useCurrentAccount();
  const [layers, setLayers] = useState<{ [k: string]: { source: any, id: string } } | null>(null);

  useEffect(() => {
    const result = loadLayers(account.organization);
    setLayers(result);
  }, [organizationId, account.organization]);

  const activeLayer = useMemo(() => {
    if (!layers || !selectedProperty || !layers[selectedProperty]) {
      return null;
    }

    return layers[selectedProperty];
  }, [layers, selectedProperty]);

  return (
    <>
      {activeLayer && (
        <GeoJsonLayer
          key={activeLayer.id}
          source={activeLayer.source}
          id={activeLayer.id}
          colors={selectedProperty === 'thermalComfort' ? THERMAL_COMFORT_COLORS : COLORS}
          mapRef={mapRef}
          treeLayerId={treeLayerId}
          isTreeLayerLoaded={isTreeLayerLoaded}
        />
      )}
    </>
  );
};

export default Isoline;
