import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ReactElement, ReactNode, useCallback, useContext } from 'react';
import styles from './ProfileMenu.module.scss';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import { AuthContext } from '../../auth/AuthContext';
import { Account } from '../../account/Account';
import useAccounts from '../../account/useAccounts';
import OrganizationMenu from './OrganizationMenu';
import { ChatBubbleTranslate, Group, LogOut, NavArrowDown, Settings, ProfileCircle, ViewGrid } from 'iconoir-react';

export default function ProfileMenu({ currentAccount }: { currentAccount: Account }) {
  const organization = currentAccount.organization;
  const { t } = useTranslation();
  const { accounts } = useAccounts();
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const currentPath = location?.pathname + location?.search;

  const ProfileMenuItem = ({ icon, children, toPath, currentPath, onClick }: {icon: ReactElement, children: ReactNode, toPath: string, currentPath?: string, onClick?: () => unknown}) => {
    return (
      <Link
        to={toPath}
        state={{ from: currentPath }}
        onClick={onClick}
        className={styles.profileMenuButton}>
        {icon}
        <span className={styles.profileMenuLabel}>{children}</span>
      </Link>
    );
  };

  const profileButtonFactory = useCallback(
    (isOpen: boolean) => (
      <div className="flex gap-5 items-center">
        <span className="text-outer-space-100">{organization.name}</span>
        <button type="button" className={`${styles.profileButton} gap-5`}>
          <span
            className={styles.profileMenuLogo}
            style={{ backgroundImage: `url(${currentAccount.getOrganizationLogoUrl()})` }}
          />
          <span className="text-outer-space-100 flex items-center">
            <NavArrowDown />
          </span>
        </button>
      </div>
    ), [currentAccount]);

  const user = authContext.user;

  return (
    <DropDownMenu bottomPosition={10} triggerFactory={profileButtonFactory}>
      <div className={styles.userProfile}>
        <div className={styles.userName}>
          <div>
            <strong>{user.getName()}</strong>
          </div>

          <div className={styles.userIcon}>
            <ProfileCircle />
          </div>
        </div>

        <div className={styles.userEmail}>{user.email}</div>
      </div>

      <OrganizationMenu accounts={accounts || []} />

      <ul className={styles.profileMenu}>
        {organization.isDemo ?
          <li>
            <ProfileMenuItem toPath={`/organizations/${organization.id}`} icon={<ViewGrid/>}>
              {t('navbar.home')}
            </ProfileMenuItem>
          </li> : null
        }

        <li>
          <ProfileMenuItem
            toPath={`/organizations/${organization.id}/settings`}
            currentPath={currentPath}
            icon={<Settings />}>
            {t('navbar.settings')}
          </ProfileMenuItem>
        </li>

        {currentAccount.canManageMembers() && (
          <>
            <li>
              <ProfileMenuItem
                toPath={`/organizations/${organization.id}/settings/members`}
                currentPath={currentPath}
                icon={<Group />}>
                {t('navbar.manageMembers')}
              </ProfileMenuItem>
            </li>
            <li className={styles.divider} />
          </>
        )}

        {user.superOwner && (
          <>
            <li>
              <ProfileMenuItem
                toPath={'/super-owner'}
                currentPath={currentPath}
                icon={<ViewGrid />}>
                {t('navbar.superOwnerPanel')}
              </ProfileMenuItem>
            </li>
            <li className={styles.divider} />
          </>
        )}

        {user.isTranslator() && (
          <>
            <li>
              <ProfileMenuItem
                toPath={'/translation-tool'}
                currentPath={currentPath}
                icon={<ChatBubbleTranslate />}
              >
                Translation Tool
              </ProfileMenuItem>
            </li>
            <li className={styles.divider} />
          </>
        )}

        <li>
          <ProfileMenuItem
            toPath={'/'}
            onClick={() => authContext.logout()}
            icon={<LogOut />}>
            {t('navbar.logout')}
          </ProfileMenuItem>
        </li>
      </ul>
    </DropDownMenu>
  );
}
