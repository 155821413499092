import { DisplayableTreeProperty } from './Tree';

export class TreePropertyDisplayOptionsService {
  static readonly DEFAULT_THRESHOLD_SETUP: TreePropertyDisplayOptions = {
    min: 0,
    max: 100,
    initial: 50
  };

  private readonly thresholdSetup = new Map<DisplayableTreeProperty, TreePropertyDisplayOptions>([
    [DisplayableTreeProperty.Height, { min: 0.1, max: 33, initial: 12 }],
    [DisplayableTreeProperty.TrunkHeight, { min: 0.1, max: 15, initial: 12 }],
    [DisplayableTreeProperty.CanopyHeight, { min: 0.1, max: 35, initial: 3 }],
    [DisplayableTreeProperty.CanopyWidth, { min: 0.1, max: 35, initial: 3 }],
    [DisplayableTreeProperty.TrunkCircumference, { min: 0.1, max: 20, initial: 3 }],
    [DisplayableTreeProperty.CanopyCircumference, { min: 0.1, max: 100, initial: 20 }],
    [DisplayableTreeProperty.LeafArea, { min: 0.1, max: 3500, initial: 200 }],
    [DisplayableTreeProperty.LeafBiomass, { min: 0.1, max: 140, initial: 70 }],
    [DisplayableTreeProperty.LeafAreaIndex, { min: 0.1, max: 27, initial: 13 }],
    [DisplayableTreeProperty.CarbonStorage, { min: 0.1, max: 8000, initial: 500 }],
    [DisplayableTreeProperty.GrossCarbonSequestration, { min: 0.1, max: 180, initial: 50 }],
    [DisplayableTreeProperty.NO2, { min: 0.1, max: 500, initial: 200 }],
    [DisplayableTreeProperty.PM25, { min: 0.1, max: 150, initial: 50 }],
    [DisplayableTreeProperty.CO, { min: 0.1, max: 40, initial: 10 }],
    [DisplayableTreeProperty.O3, { min: 0.1, max: 1500, initial: 500 }],
    [DisplayableTreeProperty.NDVI, { min: 0, max: 1, initial: 0.5 }],
    [DisplayableTreeProperty.TreeHealth, { min: 1, max: 4, initial: 3 }],
    [DisplayableTreeProperty.PotentialEvapotranspiration, { min: 0.1, max: 110, initial: 20 }],
    [DisplayableTreeProperty.Transpiration, { min: 0.1, max: 20, initial: 5 }],
    [DisplayableTreeProperty.OxygenProduction, { min: 0.1, max: 500, initial: 150 }],
    [DisplayableTreeProperty.Evaporation, { min: 0.1, max: 25, initial: 10 }],
    [DisplayableTreeProperty.WaterIntercepted, { min: 0.1, max: 25, initial: 10 }],
    [DisplayableTreeProperty.AvoidedRunoff, { min: 0.1, max: 5, initial: 2 }],

    [DisplayableTreeProperty.SafetyFactorAt80Kmh, { min: 0.1, max: 10, initial: 2 }],
    [DisplayableTreeProperty.SafetyFactors, { min: 20, max: 175, initial: 80 }],
    [DisplayableTreeProperty.ThermalComfort, { min: -30, max: 30, initial: 0 }],
    [DisplayableTreeProperty.TreeValueKoch, { min: 0, max: 20000, initial: 3500 }],
    [DisplayableTreeProperty.TreeValueCavat, { min: 0, max: 20000, initial: 3500 }],
    [DisplayableTreeProperty.TreeValueRado, { min: 0, max: 20000, initial: 3500 }],
    [DisplayableTreeProperty.LeaningAngle, { min: 0, max: 45, initial: 0 }]
  ]);

  list(): Map<DisplayableTreeProperty, TreePropertyDisplayOptions> {
    return this.thresholdSetup;
  }
}

export interface TreePropertyDisplayOptions {
  min: number,
  max: number,
  initial: number
}
