import styles from './DataPanel.module.scss';
import { Organization } from '../../../organization/Organization';
import { useContext, useMemo } from 'react';
import DetailedTree from '../../../tree/DetailedTree';
import TabSelector, { Tab } from './DataTabs/TabSelector';
import RiskTab from './DataTabs/RiskTab/RiskTab';
import HealthAndVitalityTab from './DataTabs/HealthAndVitalityTab';
import BaseAttributes from './Inventory/BaseAttributes';
import ClearancesTab from './DataTabs/ClearancesTab';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { Item } from '../../../components/UI/Dropdown/Dropdown';
import BenefitsTab from './DataTabs/BenefitsTab';

export const booleanItems: Item[] = [
  { id: 'true', translationKey: 'details.properties.booleanLabels.true' },
  { id: 'false', translationKey: 'details.properties.booleanLabels.false' }
];

export const nullableBooleanItems: Item[] = [
  { id: 'true', translationKey: 'details.properties.booleanLabels.true' },
  { id: 'false', translationKey: 'details.properties.booleanLabels.false' },
  { id: '', translationKey: 'treeDetails.noData' }
];
export default function DataPanel(props: DataPanelProps) {
  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const selectedTab = useMemo(() => {
    return urlContext.getSelectedDataPanelTab();
  }, [JSON.stringify(urlContext.getSelectedDataPanelTab())]);

  return (
    <section className={styles.dataPanelContainer}>
      <BaseAttributes
        tree={props.tree}
        isOpen={!selectedTab}
        isExpanded={props.isExpanded}
      />
      <div className={`${styles.content} ${!selectedTab ? '' : styles.open}`}>
        <TabSelector />
        <div className={styles.dataPanelBottomSection}>
          {
            selectedTab === Tab.Risk &&
              <RiskTab
                tree={props.tree}
                organizationId={props.organization.id}
                singleColumn={!props.isExpanded}
              />
          }
          {
            selectedTab === Tab.HealthAndVitality &&
              <HealthAndVitalityTab
                tree={props.tree}
                organizationId={props.organization.id}
                singleColumn={!props.isExpanded}
              />
          }
          {
            selectedTab === Tab.Clearances &&
            <ClearancesTab
              tree={props.tree}
              singleColumn={!props.isExpanded}
              isMetrical={props.organization.getIsMetrical()}
            />
          }
          {
            selectedTab === Tab.Benefits &&
            <BenefitsTab
              tree={props.tree}
              singleColumn={!props.isExpanded}
              organization={props.organization}
            />
          }
        </div>
      </div>
    </section>
  );
}

interface DataPanelProps {
  tree: DetailedTree,
  organization: Organization,
  isExpanded: boolean
}
