import styles from './ViewSelector.module.scss';
import { useRef, useState } from 'react';
import { t } from 'i18next';
import { Camera } from 'iconoir-react';
import { ReactComponent as Scope } from '../../../Explore/components/images/street-view.svg';
import { ReactComponent as DigitalTwinToggle } from '../../../Explore/images/gh-icon-twin-tree.svg';
import AbsoluteModal from '../../../../components/Modal/absolute-modal/AbsoluteModal';
import ModalDialog from '../../../../components/Modal/absolute-modal/ModalDialog';
import { TreeDisplayView } from '../../constants';

const icons = {
  images: <Camera />,
  streetView: <Scope />,
  twinView: <DigitalTwinToggle />
};

export default function ViewSelector(props: ViewSelectorProps) {
  const [open, setOpen] = useState(false);
  const viewSelectorRef = useRef<HTMLDivElement | null>(null);

  const calculateModalPosition = () => {
    if (!viewSelectorRef.current) return { top: 0, left: 0 };
    const rect = viewSelectorRef.current?.getBoundingClientRect();
    return { top: rect.top - 125, left: rect.left };
  };

  return (
    <>
      <div ref={viewSelectorRef}>
        <button onClick={() => setOpen(true)} className={styles.viewButton}>
          <span className={styles.iconContainer}>{icons[props.view]}</span>
          <span>{t(`details.views.${props.view}`)}</span>
        </button>
      </div>
      <AbsoluteModal
        isVisible={open}
        onHide={() => setOpen(false)}
        hideOverlay={true}
      >
        <ModalDialog
          classNames={styles.optionsModal}
          style={{
            top: calculateModalPosition().top + 'px',
            left: calculateModalPosition().left + 'px'
          }}>
          <ul className={styles.optionsContainer}>
            <div className={styles.viewsTitle}>Select view</div>
            {Object.values(TreeDisplayView).map(it =>
              it !== TreeDisplayView.NULL && <li
                key={it}
                className={it === props.view ? styles.active : ''}
                onClick={() => props.setView(it)}
              >
                {icons[it]}{t(`details.views.${it}`)}
              </li>
            )}
          </ul>
        </ModalDialog>
      </AbsoluteModal>
    </>
  );
}

interface ViewSelectorProps {
  view: TreeDisplayView,
  setView: (view: TreeDisplayView) => unknown
}
