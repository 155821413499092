import { AxiosInstance } from 'axios';
import { TreeFilter } from './TreeFilter';
import TreeFilterDto from './dto/TreeFilterDto';

export default class TreeFilterService {
  constructor(private readonly http: AxiosInstance) {}

  async list(organizationId: string): Promise<TreeFilter[]> {
    const response = await this.http.get<TreeFilterDto[]>(`/v1/organizations/${organizationId}/filters`);
    return response.data.map(TreeFilter.fromDto);
  }

  create(organizationId: string, filter: Partial<TreeFilterDto>): Promise<TreeFilter> {
    return this.http
      .post<TreeFilterDto>(`/v1/organizations/${organizationId}/filters`, filter)
      .then(response => TreeFilter.fromDto(response.data));
  }

  update(organizationId: string, id: string, filter: Partial<TreeFilterDto>) {
    return this.http.put(`/v1/organizations/${organizationId}/filters/${id}`, filter);
  }

  delete(organizationId: string, id: string) {
    return this.http.delete(`/v1/organizations/${organizationId}/filters/${id}`);
  }
}
