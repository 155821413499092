export const allOf = (...predicates: MapboxPredicate[]): MapboxPredicate => {
  return ['all', ...predicates];
};

export const anyOf = (...predicates: MapboxPredicate[]) => {
  return ['any', ...predicates];
};

export const not = (predicate: MapboxPredicate) => {
  return ['!', predicate];
};

export const lessThan = (property, value) => {
  return ['<', ['get', property], value];
};

export const lessThanOrEqual = (property, value) => {
  return ['<=', ['get', property], value];
};

export const greaterThan = (property, value): MapboxPredicate => {
  return ['>', ['get', property], value];
};

export const containsLiteral = (property, values: string[]) => {
  return ['in', ['get', property], ['literal', values]];
};

export const range = (property: string, range: { from: number, to: number }) => {
  return allOf(greaterThan(property, range.from), lessThan(property, range.to));
};

type MapboxPredicate = any[];
