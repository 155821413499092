import React, { createContext, Dispatch, type ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { EditPencil, InfoCircle, NavArrowDown, NavArrowLeft } from 'iconoir-react';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import Dropdown, { DropdownProps } from '../../../components/UI/Dropdown/Dropdown';
import { MultiSelect } from '../../../components/Settings/Select/Select';
import { DataCollectionSections } from './DataCollection';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import DetailedTree from '../../../tree/DetailedTree';
import { useMatch } from 'react-router-dom';
import {
  CrownObservationOptionEnum,
  RootObservationOptionEnum,
  TrunkObservationOptionEnum
} from './observationOptions';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';

type AccordionGroupCtxType = {
  openAccordion: string | null,
  setOpenAccordion: Dispatch<SetStateAction<string | null>>,
  editingSection: string | null,
  setEditingSection: Dispatch<SetStateAction<string | null>>,
  handleChange: (property: DisplayableTreeProperty, value: string | string[] | number | null) => void,
  tree: DetailedTree | null
};

export const AccordionGroupCtx = createContext<AccordionGroupCtxType>({ openAccordion: null, setOpenAccordion: () => {}, editingSection: null, setEditingSection: () => {}, handleChange: () => {}, tree: null });
const AccordionCtx = createContext({ section: '' });

interface AccordionGroupProps {
  children: ReactNode,
  tree: DetailedTree | null,
  handleChange: (property: DisplayableTreeProperty, value: string | string[] | number | null) => void,
  editingSection: string | null,
  setEditingSection: Dispatch<SetStateAction<string | null>>,
  onOpenSectionChange?: (openSection: string | null) => void
}

export function AccordionGroup({ children, tree, handleChange, editingSection, setEditingSection, onOpenSectionChange }: AccordionGroupProps) {
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);
  useEffect(() => {
    if (!onOpenSectionChange) return;
    onOpenSectionChange(openAccordion);
  }, [openAccordion]);
  return <AccordionGroupCtx.Provider value={{ openAccordion, setOpenAccordion, editingSection, setEditingSection, handleChange, tree }}>{children}</AccordionGroupCtx.Provider>;
}

export function Accordion({ children, section, disableEdit }: { children: ReactNode, section: DataCollectionSections, disableEdit?: boolean }) {
  const { t } = useTranslation();
  const { openAccordion, setOpenAccordion, editingSection, setEditingSection } = useContext(AccordionGroupCtx);
  const { urlContext } = useContext(DependencyInjectionContext);
  const taskUrl = useMatch('/organizations/:organizationId/remote-inspections/:taskId/*');
  const taskId = taskUrl?.params?.taskId || '';

  const observationSections = [DataCollectionSections.CrownAndBranches, DataCollectionSections.RootsAndRootCollar, DataCollectionSections.Trunk, DataCollectionSections.TargetAssessment] as string[];
  const handleEditClick = event => {
    event.stopPropagation();
    setEditingSection(prev => prev === section ? null : section);
  };

  const handleSectionToggle = () => {
    setOpenAccordion(prev => prev === section ? null : section);
    if (editingSection !== section) setEditingSection(null);
  };

  const isEditable = !!urlContext.getTreeId() && openAccordion === section && taskId && !disableEdit;

  useEffect(() => {
    if (openAccordion && observationSections.includes(openAccordion) && taskId) {
      if (openAccordion) setEditingSection(openAccordion);
      else setEditingSection(null);
    }
  }, [section, openAccordion, taskId]);

  return (
    <section className={`pr-4 ${openAccordion === section ? 'border-t border-b border-b-greehill-500 border-t-greehill-500' : ''}`}>
      <div
        onClick={handleSectionToggle}
        className={`text-outer-space-200 uppercase font-bold w-full items-center justify-between cursor-pointer text-[13px] flex items-center' ${openAccordion === section ? 'text-greehill-400 pt-[6px]' : 'h-12'}`}
      >
        {t(`treeDetails.dataCollection.${section}`)}
        <div className="flex gap-3 items-center min-h-9">
          {!(openAccordion && observationSections.includes(openAccordion) && taskId) && isEditable &&
            <button
              onClick={handleEditClick}
              className={`hover:bg-outer-space-500 p-2 text-greehill-00 rounded-full ${editingSection === section ? 'bg-greehill-400' : ''}`}
            >
              <EditPencil fontSize={12}/>
            </button>}
          {openAccordion === section ? <NavArrowDown /> : <NavArrowLeft />}
        </div>
      </div>
      {openAccordion === section &&
        <>
          {observationSections.includes(section)
            ? <ul>
              <AccordionCtx.Provider value={{ section }}>
                {children}
              </AccordionCtx.Provider>
            </ul>
            : <>
              <ul className="divide-y divide-outer-space-600 mb-4">
                <AccordionCtx.Provider value={{ section }}>
                  {children}
                </AccordionCtx.Provider>
              </ul>
            </>}
        </>}
    </section>
  );
}

export function ObservationAccordion({ children, section }: { children: ReactNode, section: CrownObservationOptionEnum | TrunkObservationOptionEnum | RootObservationOptionEnum }) {
  const { t } = useTranslation();
  const { openAccordion, setOpenAccordion } = useContext(AccordionGroupCtx);

  const handleSectionToggle = e => {
    e.preventDefault();
    setOpenAccordion(prev => prev === section ? null : section);
  };

  return (
    <section className={'p-2 border-b last:border-0 text-base text-outer-space-200 border-outer-space-500'}>
      <div
        onClick={handleSectionToggle}
        className="w-full flex items-center justify-between cursor-pointer"
      >
        <Checkbox checked={true} className="bg-greehill-400 text-outer-space-700 border-0">
          {t(`virtualInspection.observationOptions.${section}`)}
        </Checkbox>
        <div className="flex gap-3 items-center">
          {openAccordion === section ? <NavArrowDown className="h-5" /> : <NavArrowLeft className="h-5" />}
        </div>
      </div>
      {openAccordion === section &&
        <AccordionCtx.Provider value={{ section }}>
          {children}
        </AccordionCtx.Provider>
      }
    </section>
  );
}

export function AccordionDropdownField(props: DropdownProps & { property: DisplayableTreeProperty }) {
  const { t } = useTranslation();
  const { editingSection, handleChange } = useContext(AccordionGroupCtx);
  const { section } = useContext(AccordionCtx);

  const editing = editingSection === section;

  return (
    <li key={props.property} className={`px-3 w-full ${editing ? 'py-1' : 'py-2'} flex gap-2 items-center`}>
      <span className="w-1/3 font-normal text-outer-space-200">
        {props.label ?? t('details.properties.' + props.property)}
      </span>
      {
        editing
          ?
          <>
            <Dropdown
              value={props.value}
              items={props.items.filter(item => item.id !== props.value?.id)}
              className="w-7/12 bg-outer-space-950 text-outer-space-200 rounded-[4px] h-10 flex items-center"
              fieldClassName="rounded-[4px] px-2 py-2 border-none [&>svg]:w-5 [&>svg]:h-5"
              menuClassname="bg-outer-space-700 p-1 border-none shadow-dropdown"
              onSelect={item => handleChange(props.property, item.id as string)}
              valueRenderer={item => (<span>{t(item?.translationKey || item?.id || '')}</span>)}
            />
            <span className="w-1/12"></span>
          </>
          :
          <span className="w-7/12 font-medium">{t(props.value?.translationKey || props.value?.id || '') ||
            <span className="text-outer-space-500">-</span>}</span>
      }
    </li>
  );
}

export function AccordionMultiSelectField(props: { property: DisplayableTreeProperty, value: string[], options: { label: string, value: string }[], label?: string }) {
  const { t } = useTranslation();
  const { editingSection, handleChange } = useContext(AccordionGroupCtx);
  const { section } = useContext(AccordionCtx);

  const editing = editingSection === section;

  return (
    <li key={props.property} className={`w-full px-3 ${editing ? 'py-1' : 'py-2'} flex gap-2 items-center`}>
      <span className="w-1/3 font-normal text-outer-space-200">
        {props.label ?? t('details.properties.' + props.property)}
      </span>
      {
        editing
          ?
          <>
            <MultiSelect
              value={props.value}
              options={props.options}
              onChange={value => handleChange(props.property, value)}
              label={''}
              containerStyle={{
                width: '58.333333%',
                backgroundColor: '#22272A',
                borderRadius: '4px',
                border: 'none'
              }}
              stylesOverride={{
                control: { borderRadius: '4px', border: 'none' },
                menu: { background: '#475257', top: '80%', border: 'none', boxShadow: '0px 6px 16px 0px rgba(34, 39, 42, 0.8)' },
                dropdownIndicator: { color: '#CBD4D6', ':hover': { color: '#CBD4D6' }, 'svg': { width: '20px', height: '20px', strokeWidth: '0.5px' } }
              }}
              placeholder={' '}
            />
            <span className="w-1/12"></span>
          </>
          :
          <span className="w-2/3 font-medium">
            {props.value.length ?
              props.value.map(selectedItem => props.options.find(option => option.value === selectedItem)?.label).join(', ')
              :
              <span className="text-outer-space-500">-</span>
            }
          </span>
      }
    </li>
  );
}

export function AccordionTextInputField(props: TextFieldProps) {
  const { t } = useTranslation();
  const { editingSection, handleChange } = useContext(AccordionGroupCtx);
  const { section } = useContext(AccordionCtx);

  const editing = props.editable && editingSection === section;

  return (
    <li key={props.property} className={`w-full px-3 flex gap-2 items-center ${editing ? 'py-1' : 'py-3'}`}>
      <span className="w-1/3 font-normal font-['Inter'] text-outer-space-200">
        {props.label ?? t('details.properties.' + props.property)}
      </span>
      {editing
        ? <>
          <input
            name={props.property}
            className={'w-7/12 bg-outer-space-950 rounded-[4px] px-2 py-2 text-outer-space-200'}
            type="text"
            value={props.value || ''}
            onChange={event => handleChange(props.property, event.target.value)}
          />
          <span className="w-1/12"></span>
        </>
        : <span className={'w-2/3 font-medium flex items-center justify-between' + (props.italic ? ' italic' : '')}>
          {props.value ?? <span className="text-outer-space-500">-</span>}
          {props.userUpdatedProperties?.includes(props.property) && (
            <span className="bg-warn-chardonnay-600 flex items-center rounded-full">
              <Tooltip overlay={t('details.manuallyOverwrittenData')} placement="left">
                <InfoCircle/>
              </Tooltip>
            </span>
          )}
        </span>}
    </li>
  );
}

export function AccordionNumericInputField(props: NumericFieldProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const { editingSection, handleChange } = useContext(AccordionGroupCtx);
  const { section } = useContext(AccordionCtx);

  const editing = props.editable && editingSection === section;
  const unit = props.customUnit ? `${props.customUnit}` : (props.defaultUnit ? t('units.' + Tree.getUnit(props.property, organization)) : '');

  const formattedValue = props.integer
    ? Number(props.value).toFixed(0)
    : (Number(props.value).toLocaleString('en-US', { maximumFractionDigits: 2 }));

  return (
    <li key={props.property} className={`w-full px-3 ${editing ? 'py-1' : 'py-3'} flex gap-2 items-center`}>
      <span className="w-1/3 font-normal text-outer-space-200">
        {props.label ?? t('details.properties.' + props.property)}
      </span>
      {editing
        ? <>
          <input
            name={props.property}
            className={'w-7/12 bg-outer-space-950 rounded-[4px] px-2 py-2 text-outer-space-200'}
            type="number"
            step={props.integer ? 1 : 0.01}
            value={props.value ?? ''}
            onChange={event => handleChange(props.property, event.target.value.length > 0 ? Number(event.target.value) : null)}
            onKeyDown={e => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
              }
            }}
            onWheel={event => event.currentTarget.blur()}
          />
          <span className="w-1/12">
            {unit && (<span className="text-[13px] text-outer-space-200 font-normal">{unit}</span>)}
          </span>
        </>
        : <span className="w-2/3 font-medium">
          {(props.value !== undefined && props.value !== null) ?
            <div className="flex gap-1 items-baseline">
              {formattedValue}
              {unit && <span className="text-[13px] text-outer-space-200 font-normal">{t(`units.${unit}`)}</span>}
            </div>
            :
            <span className="text-outer-space-500">-</span>}
        </span>}
    </li>
  );
}

interface NumericFieldProps {
  property: DisplayableTreeProperty,
  value: string | number | null | undefined,
  label?: string | undefined,
  defaultUnit?: boolean,
  customUnit?: string,
  integer?: boolean,
  editable?: boolean
}

interface TextFieldProps {
  property: DisplayableTreeProperty,
  value: string | null | undefined,
  label?: string | undefined,
  italic?: boolean,
  editable?: boolean,
  userUpdatedProperties?: string[]
}
