import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import { useTranslation } from 'react-i18next';
import styles from './DataStandards.module.scss';
import { useContext, useEffect, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import PropertyConfiguration, { PropertyRange } from '../../../properties/PropertyConfiguration';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import LegacyButton, { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { Xmark, Plus, Refresh } from 'iconoir-react';
import PropertyColorConfiguration from '../../../properties/PropertyColorConfiguration';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import { useCurrentAccount } from '../../../account/useAccounts';
import RangeInput from './RangeInput';
import { EditableConfigRepository } from './EditableConfigRepository';
import Confirmation from '../../../components/UI/Confirmation/Confirmation';
import { useTracking } from '../../../analytics/useTracking';
import { getPropertyConfigCacheKey } from '../../../properties/usePropertyConfigurations';
import { useQueryClient } from 'react-query';

export type EditablePropertyRange = {
  from: number | string,
  to: number | string,
  name: string
};

type PropertyConfigEditModalProps = {
  selectedConfig: PropertyConfiguration | null,
  closeModal: () => unknown,
  organizationId: string | undefined
};

export default function PropertyConfigEditModal({
  selectedConfig,
  closeModal,
  organizationId
}: PropertyConfigEditModalProps) {
  const { propertyConfigurationService } = useContext(DependencyInjectionContext);
  const { events, track } = useTracking();
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const account = useCurrentAccount();

  const [editableConfig, setEditableConfig] = useState<PropertyConfiguration | null>(selectedConfig);
  const [editingNewRange, setEditingNewRange] = useState<EditablePropertyRange | null>(null);
  const [colorMap, setColorMap] = useState<string[]>([]);
  const [errors, setErrors] = useState<number[]>([]);
  const [overlappingRanges, setOverlappingRanges] = useState<boolean>(false);
  const [touched, setTouched] = useState(false);
  const [resetInProgress, setResetInProgress] = useState(false);

  const editableConfigRepository = new EditableConfigRepository(editableConfig);

  useEffect(() => {
    if (!editableConfig) return;
    setColorMap(PropertyColorConfiguration.getColorsForConfig(editableConfig));
    setOverlappingRanges(editableConfig?.hasOverlappingRanges());
  }, [editableConfig, JSON.stringify(editableConfig?.hasOverlappingRanges())]);

  const saveChanges = async () => {
    if (!organizationId || !editableConfig) return;
    if (hasError()) return;
    track(events.PROPERTY_CONFIG_INDIVIDUAL_SAVE, { selectedConfig });
    await propertyConfigurationService.update(organizationId, editableConfig.property, editableConfig);
    await queryClient.invalidateQueries({ queryKey: getPropertyConfigCacheKey(organizationId) });
    closeModal();
  };

  const resetPropertyToDefault = async () => {
    if (!organizationId) return;
    const { property } = editableConfig!;
    track(events.PROPERTY_CONFIG_INDIVIDUAL_RESET, property);
    await propertyConfigurationService.setPropertyToDefault(organizationId, property);
    await queryClient.invalidateQueries({ queryKey: getPropertyConfigCacheKey(organizationId) });
    setResetInProgress(false);
    closeModal();
  };

  const hasError = () => errors.length !== 0;

  const getPropertyTitle = (property: DisplayableTreeProperty) => {
    const unit = Tree.getUnit(property, account.organization);
    const columnTitle = t(`tree.${property}`) + (unit ? ` [${unit}]` : '');
    return (
      <div
        key={`property-selector-modal-${property}`}
      >
        {t(`${columnTitle}`)}
      </div>);
  };

  const rangeDirections = [{
    id: 'default',
    translationKey: 'settings.dataStandards.orderDefault'
  }, { id: 'reversed', translationKey: 'settings.dataStandards.orderReversed' }];

  const handleRangeDelete = rangeToDelete => {
    const newConfig = editableConfigRepository.deleteRange(rangeToDelete);
    if (!newConfig) return;
    setEditableConfig(newConfig);
  };

  const handleRangeDirectionChange = (direction: 'reversed' | 'default') => {
    const newConfig = editableConfigRepository.changeRangeDirection(direction);
    if (!newConfig) return;
    setTouched(true);
    setEditableConfig(newConfig);
  };

  const updateRangeConfig = (range: PropertyRange, index) => {
    const newConfig = editableConfigRepository.updateRange(range, index);
    if (!newConfig) return;
    setEditableConfig(newConfig);
  };

  const addNewRow = (range: PropertyRange | null) => {
    const newConfig = editableConfigRepository.addNewRange(range);
    if (!newConfig) return;
    setEditableConfig(newConfig);
    setEditingNewRange(null);
  };

  const hideEditingModal = () => {
    closeModal();
    setEditingNewRange(null);
    setErrors([]);
  };

  const handleErrors = (hasError: boolean, index: number) => {
    if (!hasError) {
      setErrors(prev => prev.filter(it => it !== index));
    }
    if (hasError) {
      setErrors(prev => prev.includes(index) ? prev : [...prev, index]);
    }
  };

  const isSaveButtonDisabled = () => {
    return (!touched && JSON.stringify(editableConfig?.ranges) === JSON.stringify(selectedConfig?.ranges));
  };

  const translationKey = 'settings.dataStandards.propertyDescription.' + editableConfig?.property;
  const translatedDescription = t(translationKey);

  return (
    <Modal
      className={styles.editingModal}
      containerClassName={styles.editingModalContainer}
      isVisible={true}
      onHide={hideEditingModal}>
      {editableConfig &&
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            {getPropertyTitle(editableConfig.property)}
            <FunctionButton icon={<Xmark />} onClick={() => hideEditingModal()}></FunctionButton>
          </div>
          <p className={styles.propertyDescription}>
            {translatedDescription === translationKey ? '' : translatedDescription}
          </p>
          {!selectedConfig?.isStandard && (
            <div className={styles.scaleOrderContainer}>
              <div className={styles.dropdownPrefix}>{t('settings.dataStandards.colorOrder')}</div>
              <Dropdown
                value={rangeDirections.find(it => it.id === (editableConfig.reversedOrder ? 'reversed' : 'default'))}
                items={rangeDirections.filter(it => it.id !== (editableConfig.reversedOrder ? 'reversed' : 'default'))}
                fieldClassName={styles.dropdown}
                menuClassname={styles.dropdownMenu}
                onSelect={item => handleRangeDirectionChange(item.id as 'reversed' | 'default')} />
            </div>
          )}

          <div className={styles.minimum}>
            {t('settings.dataStandards.minimum')}: {editableConfig.getMinMaxValues().min.toFixed(2)}
          </div>

          <div className={selectedConfig?.isStandard ? styles.extraBottomPadding : ''}>
            {editableConfig.ranges.map((it, idx) =>
              <RangeInput
                range={it}
                containerClassName={styles.editingRowContainer}
                className={styles.editingRow}
                min={editableConfig?.getMinMaxValues().min}
                max={editableConfig?.getMinMaxValues().max}
                onChange={range => updateRangeConfig(range, idx)}
                onError={hasError => handleErrors(hasError, idx)}
                color={colorMap[idx]}
                onDelete={idx === editableConfig.ranges.length - 1 || idx === 0 || selectedConfig?.isStandard ? undefined : handleRangeDelete}
                valueEditingDisabled={it.to === editableConfig?.getMinMaxValues().max || selectedConfig?.isStandard}
                setTouched={setTouched}
                key={`row-${it.name}-${idx}`} />
            )}
            <RangeInput
              range={editingNewRange || { from: '', to: '', name: '' }}
              containerClassName={styles.newRowContainer}
              className={`${styles.newRow} ${editingNewRange && styles.visible}`}
              onError={hasError => handleErrors(hasError, 9)}
              min={editableConfig?.getMinMaxValues().min}
              max={editableConfig?.getMinMaxValues().max}
              onChange={range => addNewRow(range)}
              setTouched={setTouched}
            />
            {overlappingRanges && <div className={styles.error}>
              {t('settings.dataStandards.errorOverlappingRanges')}
            </div>}
            {!selectedConfig?.isStandard && (
              <FunctionButton
                onClick={() => setEditingNewRange({ from: '', to: '', name: '' })}
                className={styles.addNewButton}
                icon={<Plus />}
              >
                {t('settings.dataStandards.addNew')}
              </FunctionButton>
            )}
          </div>

          <div className={styles.modalFooter}>
            {
              resetInProgress ?
                <Confirmation
                  onConfirm={resetPropertyToDefault}
                  onCancel={() => setResetInProgress(false)}
                  confirmText={t('settings.dataStandards.reset')}
                  cancelText={t('settings.dataStandards.cancel')}
                /> :
                <FunctionButton
                  className={styles.resetToDefault}
                  icon={<Refresh />}
                  onClick={() => setResetInProgress(true)}>
                  {t('settings.dataStandards.resetToDefault')}
                </FunctionButton>
            }
            <LegacyButton
              className={styles.confirmButton}
              variant={'primary'}
              onClick={saveChanges}
              disabled={overlappingRanges || isSaveButtonDisabled()}
            >
              {t('settings.dataStandards.confirmChange')}
            </LegacyButton>
          </div>
        </div>
      }
    </Modal>
  );
}
