import { ClassConstructor, plainToInstance } from 'class-transformer';

export enum EntityKey {
  LoggedInUser = 'guf::logged_in_user',
  CurrentOrganization = 'guf::current_organization'
}

export class EntityStorage<Entity = unknown> {
  static local<T>(key: EntityKey, entity: ClassConstructor<T>) {
    return new EntityStorage<T>(localStorage, key, entity);
  }

  protected constructor(
    private readonly storage: Storage,
    private readonly key: EntityKey,
    private readonly entity: ClassConstructor<Entity>
  ) {}

  getOrElse(defaultValue: Entity): Entity {
    const serialized = this.storage.getItem(this.key);
    if (serialized) {
      return plainToInstance(this.entity, JSON.parse(serialized));
    }

    return defaultValue;
  }

  set(data: Entity) {
    this.storage.setItem(this.key, JSON.stringify(data));
  }

  delete() {
    this.storage.removeItem(this.key);
  }
}
