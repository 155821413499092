import { Download, PlusCircleSolid } from 'iconoir-react';
import { Task, TaskComment, TaskLog, TaskStatus } from '../../../task-manager/Task';
import React, { useContext, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import Button, { ButtonVariant } from '../../../components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
import TaskStatusPill from './TaskStatusPill';

export default function TaskEvents(props: { task: Task, organizationId: string }) {
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  const [comment, setComment] = useState('');
  const handleAddComment = () => {
    taskManagerService.addComment(props.organizationId, props.task.id, { content: comment });
    setComment('');
  };
  enum TaskEventType {
    COMMENT = 'comment',
    LOG = 'log'
  }
  const taskEvents: ((TaskLog | TaskComment) & { eventType: TaskEventType })[] = useMemo(() => ([
    ...props.task.logs.map(it => ({
      ...it,
      eventType: TaskEventType.LOG
    })),
    ...props.task.comments.map(it => ({
      ...it,
      eventType: TaskEventType.COMMENT
    }))
  ].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
  ), [props.task.comments, props.task.logs]);

  const getDateTime = (date: Date) => {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toLocaleString();
  };

  const downloadEventsInCsv = () => {
    const header = 'User; Datestamp; Event\n';
    const csvContent = [
      ...taskEvents.map(it => {
        if (it.eventType === TaskEventType.COMMENT) {
          return `${it.user.firstName} ${it.user.lastName}; ${getDateTime(it.createdAt)}; ${t('taskManager.addedComment')}: '${(it as TaskComment).content}'`;
        } else if (it.eventType === TaskEventType.LOG) {
          let event = `${it.user.firstName} ${it.user.lastName}; ${getDateTime(it.createdAt)}; ${t('taskManager.changedStatusFrom')} ${(it as TaskLog).prevValue.status} ${t('taskManager.to')} ${(it as TaskLog).nextValue.status}.`;
          if ((it as TaskLog).nextValue.status === TaskStatus.ASSIGNED)
            event += ` ${t('taskManager.assignee')}: ${(it as TaskLog).nextValue.assignee?.getName()}. ${t('taskManager.deadline')}: ${(it as TaskLog).nextValue.deadline ? getDateTime((it as TaskLog).nextValue.deadline!) : ''}.`;
          return event;
        } else return '';
      }),
      `${props.task.createdBy?.firstName} ${props.task.createdBy?.lastName}; ${props.task.createdAt ? getDateTime(props.task.createdAt) : ''}; ${t('taskManager.createdTheTask')}`
    ].join('\n');
    const csv = header + csvContent;
    const blob = new Blob([csv], { type: 'text/csv' });
    const blobUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = 'task_events.csv';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(blobUrl);
  };

  return (
    <section className="text-greehill-00 mx-14 pb-8">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-normal text-greehill-00">{t('taskManager.loggedEvents')}</h3>
        <div className="w-fit">
          <Button variant={ButtonVariant.Secondary} onClick={downloadEventsInCsv} className={'flex items-center'}>
            <Download />{t('taskManager.downloadLog')}
          </Button>
        </div>
      </div>
      <div className="flex gap-2 py-2 border-b border-outer-space-500">
        <input
          className="bg-outer-space-700 w-full active:outline-none focus:outline-none h-10"
          type="text"
          value={comment}
          onChange={e => setComment(e.target.value)}
          placeholder={t('taskManager.addCommentPlaceholder')}
        />
        <div className="w-24">
          {comment && <Button variant={ButtonVariant.Primary} onClick={handleAddComment}>
            <span className="flex justify-center gap-2 items-center">
              <PlusCircleSolid /> {t('taskManager.add')}
            </span>
          </Button>}
        </div>
      </div>
      <ul>
        {taskEvents.map(it => {
          if (it.eventType === TaskEventType.COMMENT) {
            return (<li key={it.id}>
              <div className="py-2 border-b border-outer-space-500">
                <div className="text-sm font-medium text-outer-space-200 flex justify-between mb-2">
                  <span className="break-words">
                    <span>{it.user.firstName} {it.user.lastName}</span>
                    <div className="break-all">{(it as TaskComment).content}</div>
                  </span>
                  <span
                    className="w-24 text-end">{getDateTime(it.createdAt)}</span>
                </div>
              </div>
            </li>);
          } else if (it.eventType === TaskEventType.LOG) {
            return (<li key={it.id}>
              <div className="py-2 border-b border-outer-space-500">
                <div className="text-sm font-medium text-outer-space-200 flex justify-between mb-2">
                  <span className="break-all">
                    {`${it.user.firstName} ${it.user.lastName} ${t('taskManager.changedStatusFrom')} `}
                    <span className="break-keep"><TaskStatusPill status={(it as TaskLog).prevValue.status} /></span>
                    <span className="mx-1">{t('taskManager.to')}</span>
                    <span className="break-keep"><TaskStatusPill status={(it as TaskLog).nextValue.status} /></span>
                    {(it as TaskLog).nextValue.status === TaskStatus.ASSIGNED
                      ? <>
                        <div>{`${t('taskManager.assignee')}: ${(it as TaskLog).nextValue.assignee?.getName()}`}.</div>
                        <div>{`${t('taskManager.deadline')}: ${(it as TaskLog).nextValue.deadline ? getDateTime((it as TaskLog).nextValue.deadline!) : ''}.`}</div>
                      </>
                      : ''
                    }
                  </span>
                  <span
                    className="w-24 text-end">{getDateTime(it.createdAt)}</span>
                </div>
              </div>
            </li>);
          } else return <></>;
        })}
        <li>
          <div className="py-2 border-b border-outer-space-500">
            <div className="text-sm font-medium text-outer-space-200 flex justify-between mb-2">
              {props.task.createdBy && <span className="break-words">
                {`${props.task.createdBy.firstName} ${props.task.createdBy.lastName} ${t('taskManager.createdTheTask')} `}
              </span>}
              {props.task.createdAt && <span
                className="w-24 text-end">{getDateTime(props.task.createdAt)}
              </span>}
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
}
