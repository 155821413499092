import { useEffect, useRef } from 'react';

const useMountedEffect = (cb, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    let cleanup: void | (() => void);
    if (didMount.current) {
      cleanup = cb();
    } else {
      didMount.current = true;
    }
    if (cleanup && typeof cleanup === 'function') {
      return cleanup;
    }
    return;
  }, deps);
};

export default useMountedEffect;
