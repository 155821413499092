import { QueryClient, QueryClientProvider } from 'react-query';
import AppRouter from '../../routes/AppRouter';
import mapboxgl from 'mapbox-gl';
import analytics from '../../AnalyticsProvider';
import { AnalyticsProvider } from 'use-analytics';
import ToastProvider from '../../ToastProvider';
// eslint-disable-next-line @typescript-eslint/no-var-requires,import/no-webpack-loader-syntax
mapboxgl['workerClass'] = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App = () => (
  <AnalyticsProvider instance={analytics}>
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <ToastProvider/>
    </QueryClientProvider>
  </AnalyticsProvider>
);

export default App;
