import PartialTree from './PartialTree';
import { DisplayableViItem, GH_DEFAULT, Latitude, Longitude, SafetyFactor, TreeDto } from './Tree';
import { Cohort } from '../routes/LegacyDetails/LegacyDetails';
import { ViStatus } from './DetailedTree';

export class FilteredPartialTree extends PartialTree {
  static override fromDto(dto: Partial<TreeDto> & { filtered: boolean }): FilteredPartialTree {
    return new FilteredPartialTree(
      dto.filtered,
      dto.id ?? '',
      dto.managedAreaId ?? null,
      ((dto.managedArea?.name?.length ?? 0) > 0 ? dto.managedArea?.name : dto.managedArea?.code) ?? null,
      !dto.genus && !dto.species ? GH_DEFAULT : dto.genus!,
      !dto.genus && !dto.species ? GH_DEFAULT : dto.species!,
      !dto.scientificName ? GH_DEFAULT : dto.scientificName,
      dto.height ?? null,
      dto.trunkHeight ?? null,
      dto.trunkWidth ?? null,
      dto.trunkEllipseRadiusA ?? null,
      dto.trunkEllipseRadiusB ?? null,
      dto.trunkCircumference ?? null,
      dto.trunkDiameter ?? null,
      dto.canopyHeight ?? null,
      dto.canopyWidth ?? null,
      dto.canopyEllipseRadiusA ?? null,
      dto.canopyEllipseRadiusB ?? null,
      dto.canopyCircumference ?? null,
      dto.leafArea ?? null,
      dto.leafBiomass ?? null,
      dto.leafAreaIndex ?? null,
      dto.carbonStorage ?? null,
      dto.grossCarbonSequestration ?? null,
      dto.no2 ?? null,
      dto.so2 ?? null,
      dto.pm25 ?? null,
      dto.co ?? null,
      dto.o3 ?? null,
      dto.ndvi ?? null,
      dto.treeHealth ?? null,
      dto.carbonStorageEcoValue ?? null,
      dto.grossCarbonSequestrationEcoValue ?? null,
      dto.no2EcoValue ?? null,
      dto.so2EcoValue ?? null,
      dto.pm25EcoValue ?? null,
      dto.coEcoValue ?? null,
      dto.o3EcoValue ?? null,
      dto.avoidedRunoffEcoValue ?? null,
      dto.potentialEvapotranspiration ?? null,
      dto.transpiration ?? null,
      dto.oxygenProduction ?? null,

      dto.safetyFactorAt80Kmh ?? null,
      dto.safetyFactorAtDefaultWindSpeed ?? null,
      dto.externalId ?? null,
      dto.safetyFactors ?? [],
      dto.thermalComfort ?? null,
      dto.treeValueCavat ?? null,
      dto.treeValueRado ?? null,
      dto.treeValueKoch ?? null,
      dto.leaningAngle ?? null,
      dto.evaporation ?? null,
      dto.waterIntercepted ?? null,
      dto.avoidedRunoff ?? null,
      dto.leaningVector ?? null,
      dto.location ?? null,
      dto.localizedLocation ?? null,
      dto.cohort ?? null,
      dto.dieback ?? null,
      dto.status ?? null,
      dto.vitalityVigor ?? null,
      dto.crownLightExposure ?? null,
      dto.leafAreaPerCrownVolume ?? null,
      dto.liveCrownRatio ?? null,
      dto.slenderness ?? null,
      dto.viStatus ?? null,
      dto.hasViObservation ?? null,
      dto.hasMitigation ?? null,
      dto.hasAssessmentRequest ?? null,
      dto.mitigations ?? []
    );
  }

  constructor(
    readonly filtered: boolean,
    id: string,
    managedAreaId: string | null,
    managedAreaName: string | null,
    genus: string | null,
    species: string | null,
    scientificName: string | null,
    height: number | null,
    trunkHeight: number | null,
    trunkWidth: number | null,
    trunkEllipseRadiusA: number | null,
    trunkEllipseRadiusB: number | null,
    trunkCircumference: number | null,
    trunkDiameter: number | null,
    canopyHeight: number | null,
    canopyWidth: number | null,
    canopyEllipseRadiusA: number | null,
    canopyEllipseRadiusB: number | null,
    canopyCircumference: number | null,
    leafArea: number | null,
    leafBiomass: number | null,
    leafAreaIndex: number | null,
    carbonStorage: number | null,
    grossCarbonSequestration: number | null,
    no2: number | null,
    so2: number | null,
    pm25: number | null,
    co: number | null,
    o3: number | null,
    ndvi: number | null,
    treeHealth: number | null,
    carbonStorageEcoValue: number | null,
    grossCarbonSequestrationEcoValue: number | null,
    no2EcoValue: number | null,
    so2EcoValue: number | null,
    pm25EcoValue: number | null,
    coEcoValue: number | null,
    o3EcoValue: number | null,
    avoidedRunoffEcoValue: number | null,
    potentialEvapotranspiration: number | null,
    transpiration: number | null,
    oxygenProduction: number | null,
    safetyFactorAt80Kmh: number | null,
    safetyFactorAtDefaultWindSpeed: number | null,
    externalId: string | null,
    safetyFactors: SafetyFactor[],
    thermalComfort: number | null,
    treeValueCavat: number | null,
    treeValueRado: number | null,
    treeValueKoch: number | null,
    leaningAngle: number | null,
    evaporation: number | null,
    waterIntercepted: number | null,
    avoidedRunoff: number | null,
    leaningVector: { coordinates: [number, number, number] } | null,
    location: { coordinates: [Longitude, Latitude, number] } | null,
    localizedLocation: { coordinates: [Longitude, Latitude, number] } | null,
    cohort: Cohort | null,
    dieback: number | null,
    status: string | null,
    vitalityVigor: string | null,
    crownLightExposure: number | null,
    leafAreaPerCrownVolume: number | null,
    liveCrownRatio: number | null,
    slenderness: number | null,
    viStatus: ViStatus | null,
    hasViObservation: boolean | null,
    hasMitigation: boolean | null,
    hasAssessmentRequest: boolean | null,
    mitigations: DisplayableViItem[]
  ) {
    super(
      id,
      managedAreaId,
      managedAreaName,
      genus,
      species,
      scientificName,
      height,
      trunkHeight,
      trunkWidth,
      trunkEllipseRadiusA,
      trunkEllipseRadiusB,
      trunkCircumference,
      trunkDiameter,
      canopyHeight,
      canopyWidth,
      canopyEllipseRadiusA,
      canopyEllipseRadiusB,
      canopyCircumference,
      leafArea,
      leafBiomass,
      leafAreaIndex,
      carbonStorage,
      grossCarbonSequestration,
      no2,
      so2,
      pm25,
      co,
      o3,
      ndvi,
      treeHealth,
      carbonStorageEcoValue,
      grossCarbonSequestrationEcoValue,
      no2EcoValue,
      so2EcoValue,
      pm25EcoValue,
      coEcoValue,
      o3EcoValue,
      avoidedRunoffEcoValue,
      potentialEvapotranspiration,
      transpiration,
      oxygenProduction,
      safetyFactorAt80Kmh,
      safetyFactorAtDefaultWindSpeed,
      externalId,
      safetyFactors,
      thermalComfort,
      treeValueCavat,
      treeValueRado,
      treeValueKoch,
      leaningAngle,
      evaporation,
      waterIntercepted,
      avoidedRunoff,
      leaningVector,
      location,
      localizedLocation,
      cohort,
      dieback,
      status,
      vitalityVigor,
      crownLightExposure,
      leafAreaPerCrownVolume,
      liveCrownRatio,
      slenderness,
      viStatus,
      hasViObservation,
      hasMitigation,
      hasAssessmentRequest,
      mitigations
    );
  }
}
