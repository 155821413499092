import { AxiosInstance } from 'axios';

export class AuditLogService {
  constructor(private readonly http: AxiosInstance) {}

  async getFlipperChangeLogs(organizationId: string): Promise<FlipperChangeLog[]> {
    const res = await this.http.get(`v1/organizations/${organizationId}/audit-logs/flippers`);
    return res.data;
  }
}

export type FlipperChangeLog = {
  user: string,
  createdAt: string,
  flipper: string,
  newValue: string
};
