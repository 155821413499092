import { useEffect, useRef, useState } from 'react';
import styles from './PropertyLegendSelectorDropdownMenu.module.scss';
import { NavArrowDown, NavArrowUp } from 'iconoir-react';
import { useAvailableProperties } from '../../properties/usePropertyConfigurations';
import { AvailableProperties } from '../../properties/AvailableProperties';
import DropdownList from './DropdownList';
import { DisplayableTreeProperty } from '../../tree/Tree';
import { useTranslation } from 'react-i18next';
import Tooltip from '../UI/Tooltip/Tooltip';

export default function PropertyLegendSelectorDropdownMenu(props: PropertyLegendSelectorDropdownMenuProps) {
  const { t } = useTranslation();
  const [propertySelectorVisible, setPropertySelectorVisible] = useState(false);
  const propertyLabelRef = useRef<HTMLDivElement | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const onTooltipVisibleChange = visible => {
    const truncatedLabel = propertyLabelRef.current ? propertyLabelRef.current.getBoundingClientRect().width === 90 : false;
    if (!truncatedLabel) {
      setTooltipVisible(false);
      return;
    }
    setTooltipVisible(visible);
  };

  useEffect(() => {
    const clickListener = (ev: MouseEvent) => {
      if ((ev.target as HTMLElement).closest('#propertySelector') === null) {
        setPropertySelectorVisible(false);
      }
    };
    window.addEventListener('click', clickListener);
    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, []);

  const properties = useAvailableProperties();

  return (
    <div id="propertySelector" className={styles.propertySelector}>
      <Tooltip
        overlay={t(`tree.${props.selectedProperty}`) + props.translatedUnit}
        visible={tooltipVisible}
        onVisibleChange={visible => onTooltipVisibleChange(visible)}
      >
        <button className={styles.treeButton} onClick={() => setPropertySelectorVisible(prevState => !prevState)}>
          <div className={styles.selectedPropertyContainer}>
            <div
              ref={propertyLabelRef}
              className={styles.selectedProperty}
            >
              {t(`tree.${props.selectedProperty}`)}
            </div>
            <div>{props.translatedUnit}</div>
          </div>
          <span className={styles.treeIcon}>{propertySelectorVisible ? <NavArrowUp /> : <NavArrowDown />}</span>
        </button>
      </Tooltip>
      {propertySelectorVisible &&
        <div className={styles.dropDownContainer}>
          {new AvailableProperties(properties).map((name, properties) => <DropdownList
            name={name}
            list={properties}
            close={() => setPropertySelectorVisible(false)} />)}
        </div>
      }
    </div>
  );
}

interface PropertyLegendSelectorDropdownMenuProps {
  selectedProperty: DisplayableTreeProperty,
  translatedUnit: string
}
