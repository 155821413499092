import React from 'react';

import { createRoot } from 'react-dom/client';
import App from './components/App/App';
import 'normalize.css/normalize.css';
import './styles/main.scss';
import './i18n';

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  RadialLinearScale,
  ScatterController,
  Title,
  Tooltip
} from 'chart.js';

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  RadialLinearScale,
  Filler,
  Tooltip,
  Legend,
  ScatterController,
  BarElement,
  ArcElement
);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
