import axios, { AxiosInstance } from 'axios';

import api from '../services/api';
import { User } from '../users/User';
import { UserDto } from '../users/UserDto';

export class AuthService {
  static make() {
    return new AuthService(api);
  }

  constructor(private readonly http: AxiosInstance) {}

  async login(email: string, password: string): Promise<User> {
    try {
      const response = await api.post<UserDto>('/v1/login', { email, password });
      return User.fromDto(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = Array.isArray(error.response?.data.message)
          ? error.response?.data.message[0]
          : error.response?.data.message;

        throw new Error(message);
      }

      throw error;
    }
  }

  async loginExternal(accessToken): Promise<User> {
    try {
      const response = await api.get<UserDto>('/v1/external/verify', {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      return User.fromDto(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = Array.isArray(error.response?.data.message)
          ? error.response?.data.message[0]
          : error.response?.data.message;

        throw new Error(message);
      }

      throw error;
    }
  }

  async logout() {
    await this.http.post('/v1/logout');
  }
}
