import styles from './AdvancedFilterRangeSelector.module.scss';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import PropertyColorConfiguration from '../../../../properties/PropertyColorConfiguration';
import { Check } from 'iconoir-react';
import { useState } from 'react';

export default function AdvancedFilterRangeSelector(props: AdvancedFilterRangeSelectorProps) {
  const [rangeIndices, setRangeIndices] = useState<number[]>(props.rangeIndices);

  if (!(props.propertyConfiguration)) return <></>;
  const colorMap = PropertyColorConfiguration.getColorsForConfig(props.propertyConfiguration!);
  const handleSelect = idx => {
    const selectedRanges = [...rangeIndices];
    if (rangeIndices.includes(idx)) {
      const indexToDelete = selectedRanges.findIndex(it => it === idx);
      selectedRanges.splice(indexToDelete, 1);
    } else {
      selectedRanges.push(idx);
    }
    setRangeIndices(selectedRanges);
    props.onChange(selectedRanges);
  };

  return (
    <div className={styles.rangeSelectorContainer} data-testid={'advanced-filter-range-selector'}>
      {props.propertyConfiguration?.ranges.map((range, idx) =>
        <button
          data-testid={range.name}
          className={`${styles.rangeRowContainer} ${rangeIndices.includes(idx) && styles.selectedRow}`}
          onClick={() => handleSelect(idx)}
          key={`${range.name}-${idx}`}>
          <div className={styles.circleAndLabel}>
            <div className={styles.circle} style={{ backgroundColor: `rgb(${colorMap[idx]})` }}></div>
            <div className={styles.capitalizeFirstLetter} key={`${range.name}-${idx}`}>{range.name}</div>
          </div>
          <div style={{ visibility: rangeIndices.includes(idx) ? 'visible' : 'hidden' }}>
            <Check />
          </div>
        </button>
      )}
    </div>
  );
}

interface AdvancedFilterRangeSelectorProps {
  onChange: (ranges: number[]) => unknown,
  propertyConfiguration: PropertyConfiguration | null,
  rangeIndices: number[]
}
