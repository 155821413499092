import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import styles from './PropertyLegendColoringTypeSelector.module.scss';
import { useTranslation } from 'react-i18next';
import { NavArrowDown, NavArrowUp } from 'iconoir-react';
import { ColoringType } from './PropertyLegend';
import DependencyInjectionContext from '../../DependencyInjectionContext';

export default function PropertyLegendColoringTypeSelector(props: PropertyLegendColoringTypeSelectorProps) {
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const [typeSelectorVisible, setTypeSelectorVisible] = useState(false);
  const selectableColoringType = Object.values(ColoringType).filter(it => it !== props.coloringType)[0];

  useEffect(() => {
    const clickListener = (ev: MouseEvent) => {
      if ((ev.target as HTMLElement).closest('#coloringTypeSelector') === null) {
        setTypeSelectorVisible(false);
      }
    };
    window.addEventListener('click', clickListener);
    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, []);

  const onSelect = (type: ColoringType) => {
    props.setColoringType(type);
    urlContext.setColoringType(type);
    setTypeSelectorVisible(false);
  };

  return (
    <div id="coloringTypeSelector" className={styles.typeSelector}>
      <button className={styles.selectedType} onClick={() => setTypeSelectorVisible(prevState => !prevState)}>
        <div>{t('propertyLegend.' + props.coloringType)}</div>
        <span>{typeSelectorVisible ? <NavArrowUp /> : <NavArrowDown />}</span>
      </button>
      {typeSelectorVisible &&
        <div
          className={styles.dropDown}
          onClick={() => onSelect(selectableColoringType)}
        >
          {t('propertyLegend.' + selectableColoringType)}
        </div>
      }
    </div>
  );
}

interface PropertyLegendColoringTypeSelectorProps {
  coloringType: ColoringType,
  setColoringType: Dispatch<SetStateAction<ColoringType>>
}
