import styles from '../Inventory.module.scss';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SideLocation } from '../../../../../property-enums/SideLocation';
import { LandUse } from '../../../../../property-enums/LandUse';
import { OverheadUtilities } from '../../../../../property-enums/OverheadUtilities';
import { GrowSpace } from '../../../../../property-enums/GrowSpace';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import Dropdown, { Item } from '../../../../../components/UI/Dropdown/Dropdown';
import { TreeDto } from '../../../../../tree/Tree';
import { DataGroup } from '../Inventory';
import Input from '../../../../../components/UI/Input/Input';
import DetailedTree from '../../../../../tree/DetailedTree';
import { MultiSelect } from '../../../../../components/Settings/Select/Select';
import DataGroupHeader from '../../components/DataGroupHeader/DataGroupHeader';
import lightMultiSelectStyle from '../../components/lightMultiSelectStyle';
import EditingFieldWrapper from '../../components/EditingFieldWrapper/EditingFieldWrapper';

interface Option<T> {
  label: string,
  value: T
}

export default function SiteFactorsDataGroup(props: SiteFactorsDataGroupProps) {
  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);

  const initialSiteFactors = {
    onStreetName: props.tree.onStreetName || '',
    sideLocation: props.tree.sideLocation,
    landUse: props.tree.landUse || [],
    parkName: props.tree.parkName || '',
    overheadUtilities: props.tree.overheadUtilities || [],
    growSpaceSize: props.tree.growSpaceSize || '',
    growSpace: props.tree.growSpace
  };

  const sideLocationOptions: Item[] = Object.values(SideLocation).map(location => {
    return { id: location, translationKey: 'details.properties.sideLocationTypes.' + location };
  });
  const landUseOptions: Option<string>[] = Object.values(LandUse).map(land => {
    return { value: land, label: t('details.properties.landUseTypes.' + land) };
  });
  const overheadUtilitiesOptions: Option<string>[] = Object.values(OverheadUtilities).map(utility => {
    return { value: utility, label: t('details.properties.overheadUtilitiesTypes.' + utility) };
  });
  const growSpaceOptions: Item[] = Object.values(GrowSpace).map(space => {
    return { id: space, translationKey: 'details.properties.growSpaceTypes.' + space };
  });

  const [siteFactors, setSiteFactors] = useState(initialSiteFactors);

  const sideLocationKey = siteFactors.sideLocation ? t(`details.properties.sideLocationTypes.${siteFactors.sideLocation}`) : '';
  const landUseKey = siteFactors.landUse?.length ? siteFactors.landUse.map(it => t(`details.properties.landUseTypes.${it}`)).join(', ') : '';
  const overheadUtilitiesKey = siteFactors.overheadUtilities?.length ? siteFactors.overheadUtilities.map(it => t(`details.properties.overheadUtilitiesTypes.${it}`)).join(', ') : '';
  const growSpaceKey = siteFactors.growSpace ? t(`details.properties.growSpaceTypes.${siteFactors.growSpace}`) : '';

  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(siteFactors).forEach(key => {
      if (siteFactors[key] !== props.tree[key] && siteFactors[key] !== '') {
        updatedValues[key] = siteFactors[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(props.organizationId, props.tree.id, updatedValues);
    }
    props.setEditing(undefined);
  };

  const handleCancel = () => {
    props.setEditing(undefined);
    setSiteFactors(initialSiteFactors);
  };

  useEffect(() => {
    setSiteFactors(initialSiteFactors);
  }, [props.editing]);

  return (
    <div className={styles.dataGroup}>
      <DataGroupHeader
        title={t('treeDetails.inventory.siteFactors')}
        editing={props.editing === DataGroup.siteFactors}
        setEditing={() => props.setEditing(DataGroup.siteFactors)}
        handleCancel={handleCancel}
        handleSave={handleSave}
      />
      <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : ''}`}>
        <EditingFieldWrapper
          title={t('details.properties.onStreetName')}
          value={siteFactors.onStreetName}
          editing={props.editing === DataGroup.siteFactors}
        >
          <Input
            label={''}
            value={siteFactors.onStreetName}
            onChange={event => setSiteFactors(prev => ({ ...prev, onStreetName: event.target.value }))}
            autoFocus={true}
            className={styles.editingInput}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.parkName')}
          value={siteFactors.parkName}
          editing={props.editing === DataGroup.siteFactors}
        >
          <Input
            label={''}
            value={siteFactors.parkName}
            onChange={event => setSiteFactors(prev => ({ ...prev, parkName: event.target.value }))}
            autoFocus={true}
            className={styles.editingInput}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.sideLocation')}
          value={sideLocationKey}
          editing={props.editing === DataGroup.siteFactors}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={sideLocationOptions}
            value={{ id: siteFactors.sideLocation, translationKey: sideLocationKey }}
            onSelect={item => setSiteFactors(prev => ({ ...prev, sideLocation: item.id }))}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.growSpace')}
          value={growSpaceKey}
          editing={props.editing === DataGroup.siteFactors}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={growSpaceOptions}
            value={{ id: siteFactors.growSpace, translationKey: growSpaceKey }}
            onSelect={item => setSiteFactors(prev => ({ ...prev, growSpace: item.id }))}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.growSpaceSize')}
          value={siteFactors.growSpaceSize}
          editing={props.editing === DataGroup.siteFactors}
        >
          <Input
            label={''}
            value={siteFactors.growSpaceSize}
            onChange={event => setSiteFactors(prev => ({ ...prev, growSpaceSize: event.target.value }))}
            autoFocus={true}
            className={styles.editingInput}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.landUse')}
          value={landUseKey}
          editing={props.editing === DataGroup.siteFactors}
        >
          <MultiSelect
            value={siteFactors.landUse}
            options={landUseOptions}
            onChange={it => setSiteFactors(prev => ({ ...prev, landUse: it }))}
            label={''}
            upwards={true}
            containerStyle={{
              width: '100%',
              backgroundColor: 'var(--panel-light)'
            }}
            stylesOverride={lightMultiSelectStyle}
            placeholder={' '}
            darkBackgroundOnFocus={true}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.overheadUtilities')}
          value={overheadUtilitiesKey}
          editing={props.editing === DataGroup.siteFactors}
        >
          <MultiSelect
            value={siteFactors.overheadUtilities}
            options={overheadUtilitiesOptions}
            onChange={it => setSiteFactors(prev => ({ ...prev, overheadUtilities: it }))}
            label={''}
            upwards={true}
            containerStyle={{
              width: '100%',
              backgroundColor: 'var(--panel-light)'
            }}
            stylesOverride={lightMultiSelectStyle}
            placeholder={' '}
            darkBackgroundOnFocus={true}
          />
        </EditingFieldWrapper>
      </div>
    </div>
  );
}

interface SiteFactorsDataGroupProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organizationId: string,
  editing: undefined | DataGroup,
  setEditing: Dispatch<SetStateAction<DataGroup | undefined>>
}
