import DetailedTree from '../../../../../tree/DetailedTree';
import styles from './HistoryTrackingTable.module.scss';
import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { TrackableTreeProperty } from '../../../../../tree/TrackableTreeProperty';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../../../account/useAccounts';
import { Tree } from '../../../../../tree/Tree';
import { CohortStudyValue } from './CohortHistoryChart';
import EyeIcon from '../../../DataPanel/components/EyeIcon';
import SelectedEyeIcon from '../../../DataPanel/components/SelectedEyeIcon';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';

const MAX_NUMBER_OF_HIGHLIGHTED_PROPS = 5;

function conditionalClasses(...args: (boolean | string)[]): string {
  if (args.length % 2) throw new Error('The length of the arguments must be even');
  const classNames: string[] = [];
  for (let i = 0; i < args.length / 2; i++) {
    if (args[2 * i]) classNames.push(args[2 * i + 1] as string);
  }

  return classNames.join(' ');
}

export default function HistoryTrackingTable(props: HistoryTrackingTableProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const { urlContext } = useContext(DependencyInjectionContext);

  const [hoveredColumn, setHoveredColumn] = useState<TrackableTreeProperty | null>(null);

  const selectedYear = useMemo(() => {
    return urlContext.getSelectedYear();
  }, [urlContext.serialize()]);

  const highlightedPropertiesAreSettled = props.highlightedProperties.length >= MAX_NUMBER_OF_HIGHLIGHTED_PROPS;

  const onColumnClicked = useCallback((propertyName: TrackableTreeProperty) => {
    props.setHighlightedProperties((prev: TrackableTreeProperty[]) => {
      if (prev.includes(propertyName)) {
        const copy = [...prev];
        copy.splice(copy.indexOf(propertyName), 1);
        return copy;
      }
      if (highlightedPropertiesAreSettled) return prev;
      return [...prev, propertyName];
    });
  }, [props.setHighlightedProperties, highlightedPropertiesAreSettled]);

  const isSelected = (property: TrackableTreeProperty) => props.highlightedProperties.includes(property);

  const selectYear = (year:string) => {
    if (selectedYear === year) {
      urlContext.setSelectedYear(null);
      return;
    }
    urlContext.setSelectedYear(year);
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.tableWrapper}>
        <table
          className={styles.table}
          onMouseLeave={() => setHoveredColumn(null)}
        >
          <colgroup>
            <col />
            {DetailedTree.TRACKABLE_TREE_PROPERTIES.map(it => {
              return <col key={'col' + it} className={conditionalClasses(props.highlightedProperties.includes(it), styles.selectedColumn)}/>;
            })}
          </colgroup>
          <thead>
            <tr>
              <th className={styles.firstColumn}><br/><br/></th>
              {
                DetailedTree.TRACKABLE_TREE_PROPERTIES.map(it => {
                  const unit = Tree.getUnit(it, account.organization);
                  const translatedUnit = unit ? `[${t('units.' + unit)}]` : '';
                  const title = t('details.properties.' + it);
                  return (
                    <th
                      className={conditionalClasses(
                        isSelected(it) || (it === hoveredColumn && !highlightedPropertiesAreSettled), styles.hoveredColumn,
                        !isSelected(it) && highlightedPropertiesAreSettled, styles.settled
                      )}
                      key={it}
                      onClick={() => onColumnClicked(it)}
                      title={title}
                      onMouseOver={() => setHoveredColumn(it)}
                    >
                      <div className={styles.ellipsis}>{title}</div>
                      <div>{translatedUnit}</div>
                    </th>
                  );
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              props.treeHistoryData.height.map(({ year }, index) => (<tr key={year}>
                <td className={styles.firstColumn}>
                  {
                    props.treeHistoryData.height.length - 1 !== index && (
                      <div className={styles.eyeSwitch} onClick={() => selectYear(year)}>
                        {selectedYear === year ? <SelectedEyeIcon /> : <EyeIcon />}
                      </div>
                    )
                  }
                  {year}
                </td>
                {DetailedTree.TRACKABLE_TREE_PROPERTIES.map(prop => {
                  const data = props.treeHistoryData[prop].find(it => it.year === year);
                  return (
                    <td
                      className={conditionalClasses(
                        isSelected(prop) || (prop === hoveredColumn && !highlightedPropertiesAreSettled), styles.hoveredColumn,
                        !isSelected(prop) && highlightedPropertiesAreSettled, styles.settled
                      )}
                      key={prop}
                      onClick={() => onColumnClicked(prop)}
                      onMouseEnter={() => setHoveredColumn(prop)}
                    >
                      {(data ? data.value?.toFixed(2) : '-')}
                    </td>
                  );
                })}
              </tr>))
            }
          </tbody>
        </table>
      </div>
    </section>
  );
}

interface HistoryTrackingTableProps {
  highlightedProperties: TrackableTreeProperty[],
  setHighlightedProperties: Dispatch<SetStateAction<TrackableTreeProperty[]>>,
  treeHistoryData: Record<TrackableTreeProperty, CohortStudyValue[]>
}
