import * as THREE from 'three';
import { DisplayableTreeProperty, Tree, TreeDisplayConfiguration } from '../../../tree/Tree';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { TFunction } from 'react-i18next';
import TreeService from '../../../tree/TreeService';
import TreeMarkerVariations from './html-tree-marker/TreeMarkerVariations';
import { PanoramicTreeNamecard } from '../tree-namecard/PanoramicTreeNamecard';
import PropertyConfiguration from '../../../properties/PropertyConfiguration';
import PropertyColorConfiguration from '../../../properties/PropertyColorConfiguration';
import styles from './html-tree-marker/TreeMarkerVariations.module.scss';
import { AdvancedFilter, AdvancedFilterConfiguration } from '../table-view/advanced-filter/AdvancedFilter';
import { Account } from '../../../account/Account';
import { Organization } from '../../../organization/Organization';
import { ColoringType } from '../../../components/Navbar/PropertyLegend';
import CohortConfig from '../../../components/cohort/CohortConfig';
import FilterCompound from '../workspace/filter-compound/FilterCompound';

export enum PanoramicTreeMarkerState {
  default = 'default',
  selected = 'selected',
  hidden = 'hidden',
  disabled = 'disabled'
}

class PanoramicTreeMarkerStateManager {
  private disabled = false;
  private state: PanoramicTreeMarkerState = PanoramicTreeMarkerState.default;

  set(state: PanoramicTreeMarkerState) {
    if (state === PanoramicTreeMarkerState.hidden) {
      this.disabled = true;
      return;
    }
    this.state = state;
  }

  enable() {
    this.disabled = false;
  }

  get(): PanoramicTreeMarkerState {
    if (this.disabled) {
      return PanoramicTreeMarkerState.hidden;
    }

    return this.state;
  }
}

export default class PanoramicTreeMarker {
  static create(
    treeService: TreeService,
    tree: Tree,
    organization: Organization,
    t: TFunction,
    onDetailsButtonClick: (tree: Tree) => void,
    coordinates: THREE.Vector3
  ) {
    return new PanoramicTreeMarker(treeService, tree, organization, t, onDetailsButtonClick, coordinates);
  }

  get treeId(): string {
    return this.tree.id;
  }

  public group = new THREE.Group();
  private stateManager: PanoramicTreeMarkerStateManager = new PanoramicTreeMarkerStateManager();
  private readonly element: HTMLDivElement = document.createElement('div');
  private readonly markerElement: HTMLDivElement = document.createElement('div');
  private readonly namecard: PanoramicTreeNamecard = new PanoramicTreeNamecard(
    this.treeService,
    this.t,
    this.onDetailsButtonClick,
    this.organization,
    0
  );
  private readonly listeners: [HTMLElement, string, (MouseEvent) => void][] = [];
  private readonly treeMarkerVariations: TreeMarkerVariations = new TreeMarkerVariations(this.markerElement);
  constructor(
    private readonly treeService: TreeService,
    private readonly tree: Tree,
    private readonly organization: Organization,
    private readonly t: TFunction,
    private readonly onDetailsButtonClick: (tree: Tree) => void,
    private readonly position: THREE.Vector3
  ) {
    this.markerElement.classList.add(styles.mapMarkerContainer);
    this.treeMarkerVariations.setDefaultContent();
    this.element.appendChild(this.markerElement);
    const circle = new CSS2DObject(this.element);
    this.group.add(circle);
    this.group.renderOrder = 1;
    this.group.name = 'marker-' + this.tree.id;
    this.group.position.copy(position);
  }

  onHover(cb: (tree: Tree | null) => void) {
    const onEnter = () => {
      cb(this.tree);
    };

    const onLeave = () => {
      this.hideTreeNamecard();
      cb(null);
    };

    this.listeners.push([this.markerElement, 'mousemove', onEnter]);
    this.markerElement.addEventListener('mousemove', onEnter);

    this.listeners.push([this.markerElement, 'mouseleave', onLeave]);
    this.element.addEventListener('mouseleave', onLeave);

    this.listeners.push([this.markerElement, 'popupclose', onLeave]);
    this.element.addEventListener('popupclose', onLeave);
  }

  onClick(cb: (tree: Tree) => void) {
    const listener = () => cb(this.tree);

    this.listeners.push([this.markerElement, 'click', listener]);
    this.markerElement.addEventListener('click', listener);
  }

  removeEventListeners() {
    this.listeners.forEach(([element, event, listener]) => element.removeEventListener(event, listener));
  }

  belongsTo(tree: Tree | null) {
    return this.tree.id === tree?.id;
  }

  select() {
    this.setTreeMarkerState(PanoramicTreeMarkerState.selected);
  }

  deselect() {
    this.setTreeMarkerState(PanoramicTreeMarkerState.default);
  }

  disable() {
    this.setTreeMarkerState(PanoramicTreeMarkerState.disabled);
  }

  hideTreeNamecard() {
    this.namecard.hide();
  }

  showTreeNamecard(fullTree, propertyConfig: PropertyConfiguration | null) {
    if (!this.element) return;

    this.namecard.show(this.element, fullTree, propertyConfig);
  }

  isHidden() {
    return this.stateManager.get() === PanoramicTreeMarkerState.hidden;
  }

  setMarkerVisibility(visible: boolean) {
    visible ? this.treeMarkerVariations.show() : this.treeMarkerVariations.hide();
  }

  applyDisplayConfiguration(
    displayConfiguration: TreeDisplayConfiguration,
    selectedPropertyConfig: PropertyConfiguration | null = null,
    advancedFilterConfig: AdvancedFilterConfiguration,
    filter: FilterCompound,
    coloringType: ColoringType,
    hideLabel = false,
    account: Account
  ) {
    if (!(this.isTreeInSelectedManagedArea(displayConfiguration) && filter.apply(this.tree) && this.isTreeInAnyFilter(displayConfiguration, account))) {
      this.hide();
    } else {
      this.show();
    }

    if (new AdvancedFilter(advancedFilterConfig).isExcluded(this.tree, displayConfiguration.windSpeed)) {
      return this.treeMarkerVariations.setContentDisabled();
    }

    const property = displayConfiguration.property ?? null;
    this.namecard.showProperties(property, selectedPropertyConfig).setWindSpeed(displayConfiguration.windSpeed);

    if (!selectedPropertyConfig) {
      this.treeMarkerVariations.removeColor();
      return;
    }

    const value = this.getPropertyValue(selectedPropertyConfig, displayConfiguration);
    if (value === null) return this;

    let color = '';
    if (coloringType === ColoringType.LABEL) {
      color = this.getColorOfValue(selectedPropertyConfig, value);
    } else if (property && coloringType === ColoringType.COHORT) {
      const cohort = this.tree.cohort?.cohortValues[property];
      color = new CohortConfig(cohort).getColorOfValue(value);
    }
    if (!color) return this;

    this.treeMarkerVariations.setColor(color);
    if (hideLabel) {
      this.treeMarkerVariations.removeLabelContent();
    } else {
      this.treeMarkerVariations.setLabel(value);
    }
  }

  private getPropertyValue(selectedPropertyConfig: PropertyConfiguration, displayConfiguration: TreeDisplayConfiguration): number {
    return selectedPropertyConfig.property === DisplayableTreeProperty.SafetyFactors
      ? this.tree.getSafetyFactor(displayConfiguration.windSpeed)
      : this.tree[selectedPropertyConfig.property];
  }

  private getColorOfValue(selectedPropertyConfig: PropertyConfiguration, value: number) {
    const index = selectedPropertyConfig.getRangeIndexForValue(value);
    const colors = PropertyColorConfiguration.getColorsForConfig(selectedPropertyConfig);
    return colors[index];
  }

  changeOpacity(selectedPropertyConfig: PropertyConfiguration | null, index: number, windSpeed: number) {
    this.markerElement.classList.remove(styles.decreasedOpacity);

    if (selectedPropertyConfig?.isOutsideRange(index, this.tree, windSpeed)) {
      this.markerElement.classList.add(styles.decreasedOpacity);
    }
  }

  private setTreeMarkerState(newState: PanoramicTreeMarkerState) {
    this.stateManager.set(newState);

    const state = this.stateManager.get();

    if (state === PanoramicTreeMarkerState.hidden) {
      this.treeMarkerVariations.hideMarker();
    }

    if (state === PanoramicTreeMarkerState.disabled) {
      this.treeMarkerVariations.setContentDisabled();
    }

    if (state === PanoramicTreeMarkerState.selected) {
      this.treeMarkerVariations.setContentSelected();
    }

    if (state === PanoramicTreeMarkerState.default) {
      this.treeMarkerVariations.setDefaultContent();
    }
  }

  private hide() {
    this.setTreeMarkerState(PanoramicTreeMarkerState.hidden);
  }

  private show() {
    this.stateManager.enable();
    this.setTreeMarkerState(this.stateManager.get());
  }

  private isTreeInSelectedManagedArea(displayConfiguration: TreeDisplayConfiguration) {
    return (
      displayConfiguration.managedAreaIds.length === 0 ||
      (displayConfiguration.managedAreaIds.includes(this.tree.managedAreaId) !== displayConfiguration.isManagedAreaSelectionReversed)
    );
  }

  private isTreeInAnyFilter(displayConfiguration: TreeDisplayConfiguration, account: Account) {
    return (
      displayConfiguration.filters.length === 0 ||
      displayConfiguration.filters.some(filter => filter.apply(this.tree, account))
    );
  }

  isFilteredBy(advancedFilterConfig: AdvancedFilterConfiguration, treeDisplayConfiguration: TreeDisplayConfiguration) {
    return new AdvancedFilter(advancedFilterConfig).isExcluded(this.tree, treeDisplayConfiguration.windSpeed);
  }
}
