import { useCallback, useMemo, useState } from 'react';

import { ResetPasswordScreen } from '../../components/Auth/ResetPasswordScreen';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PasswordService } from '../../password/PasswordService';

const TranslatedErrorMessage = {
  Fallback: 'resetPassword.errors.fallback',
  'The given access token has expired': 'resetPassword.errors.expired',
  'accessToken should not be empty': 'resetPassword.errors.expired'
};

export default function ResetPassword() {
  const location = useLocation();
  const {
    t,
    i18n: { language: lang }
  } = useTranslation();
  const [error, setError] = useState<keyof typeof TranslatedErrorMessage | ''>('');
  const [isCompleted, setCompletionState] = useState(false);
  const [isLoading, setLoadingState] = useState(false);

  const errorMessage = (error && t(TranslatedErrorMessage[error])) || '';
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const accessToken = searchParams.get('accessToken') || '';

  const onReset = useCallback(
    async (password: string) => {
      setError('');
      setLoadingState(true);
      try {
        await PasswordService.make().reset(accessToken, password, lang);
        setCompletionState(true);
      } catch (error) {
        setError((error as Error).message as unknown as '');
      }
      setLoadingState(false);
    },
    [accessToken, lang]
  );

  return (
    <ResetPasswordScreen
      error={errorMessage}
      isCompleted={isCompleted}
      onReset={onReset}
      isLoading={isLoading}
    />
  );
}
