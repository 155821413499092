import { useContext } from 'react';
import { useQuery } from 'react-query';
import DependencyInjectionContext from '../DependencyInjectionContext';
import { User } from '../users/User';

export function usePossibleAssignees(organizationId: string): { possibleAssignees: User[]} {
  const { taskManagerService } = useContext(DependencyInjectionContext);

  const cacheKey = `possible-assignees-${organizationId}`;
  const { data } = useQuery(
    [cacheKey],
    () => {
      return taskManagerService.listPossibleAssignees(organizationId);
    },
    { retry: 1, retryOnMount: false }
  );

  return { possibleAssignees: data ?? [] };
}
