import { useEffect, useRef, useState } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import styles from './PointCloudViewer.module.scss';
import * as THREE from 'three';
import { Tree } from '../../tree/Tree';
import { PointCloudView } from './PointCloudView';
import { LoadablePointCloud } from '../../point-cloud/LoadablePointCloud';
import { MultiOrbitControl } from './MultiOrbitControl';
import { useCurrentAccount } from '../../account/useAccounts';
import { METER_TO_FEET } from './unitConstants';

export default function PointCloudViewerWithFakeHistory(props: { tree: Tree | null, scale: number }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const viewRef = useRef(new PointCloudView());
  const treePointCloudRef = useRef<THREE.Group>(new THREE.Group());
  const historyPointCloudRef = useRef<THREE.Group>(new THREE.Group());
  const account = useCurrentAccount();

  const [isViewInitialized, setIsViewInitialized] = useState(false);

  useEffect(() => {
    if (canvasRef.current === null) return;

    const view = viewRef.current;
    view.init(canvasRef.current, new MultiOrbitControl());

    setIsViewInitialized(true);

    return () => {
      view.dispose();
    };
  }, [canvasRef.current]);

  useEffect(() => {
    if (!props.tree) return;
    const halfHeight = account.organization.getIsMetrical()
      ? props.tree.height / 2
      : props.tree.height / METER_TO_FEET / 2;
    viewRef.current.lookAtTree(halfHeight, props.tree.canopyDirection);
  }, [isViewInitialized, props.tree, account.organization]);

  const [isPointCloudLoading, setIsPointCloudLoading] = useState(true);
  const [tree, setTree] = useState<Tree | null>(null);
  useEffect(() => {
    if (props.tree === null) return;

    setIsPointCloudLoading(true);
    historyPointCloudRef.current.clear();
    treePointCloudRef.current.clear();

    setTree(props.tree);

    const pointSize = 0.2;

    const url = props.tree!.getPointCloudUrl(account.organization);
    const pos = props.tree!.localizedLocation;
    const historyMaterial = new THREE.PointsMaterial({ size: pointSize, color: '#C96DD8', sizeAttenuation: false });
    Promise.all([
      new LoadablePointCloud(url).loadInto(pos, false).then(pc => treePointCloudRef.current.add(pc)),
      props.scale !== 1
        ? new LoadablePointCloud(url).loadInto(pos, false, historyMaterial).then(history => {
          history.scale.multiplyScalar(props.scale);
          history.position.multiplyScalar(props.scale);
          historyPointCloudRef.current.add(history);
        })
        : Promise.resolve()
    ]).then(() => setIsPointCloudLoading(false));
  }, [props.scale, props.tree, account.organization]);

  useEffect(() => {
    if (tree === null || isPointCloudLoading || !isViewInitialized) return;

    viewRef.current.addEventListeners();
    viewRef.current.clear();
    viewRef.current.addPointClouds([treePointCloudRef.current, historyPointCloudRef.current]);
    viewRef.current.addGrid();
    viewRef.current.setCanvasSize();

    return () => {
      viewRef.current.removeEventListeners();
    };
  }, [isPointCloudLoading, isViewInitialized, tree]);

  return (
    <div className={styles.container}>
      <canvas ref={canvasRef} className={styles.container} />

      {isPointCloudLoading && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
}
