import api from '../services/api';
import axios, { AxiosInstance } from 'axios';
import InvitationDto from './InvitationDto';
import { SignUpDto } from './SignUpDto';

export class AlreadyMemberError extends Error {}

export class RegistrationService {
  static make() {
    return new RegistrationService(api);
  }

  protected constructor(private readonly http: AxiosInstance) {}

  async invite(organizationId: string, payload: InvitationDto): Promise<void> {
    try {
      await this.http.post(`/v1/organizations/${organizationId}/invitations`, payload);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400 && error.response?.data) {
        const responseData = error.response.data;
        if (this.isAlreadyMemberError(responseData)) {
          throw new AlreadyMemberError();
        }
      }

      throw error;
    }
  }

  private isAlreadyMemberError(responseData: { message: string[] }) {
    return (
      Array.isArray(responseData.message) &&
      responseData.message.includes('This user is already a member of the organization')
    );
  }

  async signUp(accessToken: string, dto: SignUpDto): Promise<void> {
    await this.http.post('/v1/signup', dto, { params: { accessToken } });
  }

  async isTaken(email: string): Promise<boolean> {
    try {
      await this.http.get(`/v1/emails/${encodeURIComponent(email)}`);

      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return false;
      }
      throw error;
    }
  }
}
