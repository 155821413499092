import { SettingsPageTitle } from '../../../routes/Settings/SettingsPageTitle';
import { Trans, useTranslation } from 'react-i18next';
import { Organization } from '../../../organization/Organization';
import styles from './OrganizationScreen.module.scss';
import { useContext, useEffect, useState } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { Flippers } from '../../../switches/Flippers';
import { FlipperEditor } from './FlipperEditor';
import LegacyCheckbox from '../../UI/Checkbox/LegacyCheckbox';
import LegacyButton from '../../UI/Button/LegacyButton';
import Toggle from '../../UI/Toggle/Toggle';
import Input from '../../UI/Input/Input';
import DataMigration from './DataMigration';
import { useQueryClient } from 'react-query';

export default function OrganizationScreen(props: OrganizationScreenProps) {
  const { treeService, cohortService } = useContext(DependencyInjectionContext);
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const [name, setName] = useState(props.organization.name);
  const [serviceDeskUrl, setServiceDeskUrl] = useState(props.organization.serviceDeskUrl || '');
  const [isDemo, setIsDemo] = useState(props.organization.isDemo);
  const [managedAreaLoading, setManagedAreaLoading] = useState(false);
  const [managedAreaResult, setManagedAreaResult] = useState('');
  const [loadIncompleteMAs, setLoadIncompleteMAs] = useState(false);
  const [cohortCalculationLoading, setCohortCalculationLoading] = useState(false);
  const [cohortCalculationResult, setCohortCalculationResult] = useState('');

  useEffect(() => {
    props.onNameEdit(name);
  }, [name]);

  useEffect(() => {
    props.onDemoEdit(isDemo);
  }, [isDemo]);

  useEffect(() => {
    props.onServiceDeskUrlEdit(serviceDeskUrl);
  }, [serviceDeskUrl]);

  const handleManagedAreaLoading = () => {
    setManagedAreaLoading(true);
    treeService.loadManagedAreas(props.organization.id, loadIncompleteMAs)
      .then(() => {
        setManagedAreaResult('Managed area loading was successful!');
      })
      .catch(() => {
        setManagedAreaResult('Managed area loading failed!');
      })
      .finally(async () => {
        await queryClient.invalidateQueries(`managed-area-list-${props.organization.id}`);
        setManagedAreaLoading(false);
      });
  };

  const handleCalculateCohort = () => {
    setCohortCalculationLoading(true);
    cohortService.calculateCohort(props.organization.id)
      .then(() => {
        setCohortCalculationResult('Cohort calculation was successful!');
      })
      .catch(() => {
        setCohortCalculationResult('Cohort calculation failed!');
      })
      .finally(() => {
        setCohortCalculationLoading(false);
      });
  };

  return (
    <div>
      <SettingsPageTitle>{t('settings.organization.title')}</SettingsPageTitle>

      <div className={styles.logoContainer}>
        <div className={styles.logo} style={{ backgroundImage: `url('${props.organization.logoUrl}')` }} />

        <Input
          label={t('settings.organization.nameLabel')}
          placeholder={t('settings.organization.namePlaceholder')}
          value={name}
          onValueChange={setName}
          legacyStyle={true}
        />
      </div>

      <div className={styles.urlEditContainer}>
        <h3>{t('settings.organization.serviceDeskTitle')}</h3>
        <Input
          label={t('settings.organization.serviceDeskUrlLabel')}
          placeholder={t('settings.organization.serviceDeskUrlPlaceholder')}
          testId={'service-desk-url-input'}
          value={serviceDeskUrl}
          onValueChange={setServiceDeskUrl}
          legacyStyle={true}
        />
      </div>

      <div className={styles.scriptContainer}>
        <h3>{t('settings.organization.dataMigrationTitle')}</h3>
        <h5>{t('settings.organization.loadMAsTitle')}</h5>
        <Trans
          i18nKey="settings.organization.loadMAsDescription"
          components={{ p: <p className={styles.description} />, code: <code /> }}
        />
        <i className={styles.description}>{t('settings.organization.loadMAsWarning')}</i>
        {!managedAreaLoading
          ? <>
            <LegacyCheckbox
              checked={loadIncompleteMAs}
              onValueChange={() => setLoadIncompleteMAs(prevState => !prevState)}
            >
              <p className={styles.label}>{t('settings.organization.loadIncompleteMAsLabel')}</p>
            </LegacyCheckbox>
            <LegacyButton
              className={`${styles.scriptButton} ${styles.migration}`}
              onClick={handleManagedAreaLoading}
              disabled={cohortCalculationLoading || managedAreaLoading}
              variant="primary"
            >
              {managedAreaLoading ? `${t('settings.organization.loading')}...` : t('settings.organization.loadMAsButton')}
            </LegacyButton>
          </>
          : <Spinner />
        }
        <div className={styles.managedAreaResult}>{managedAreaResult}</div>
        <DataMigration
          organizationId={props.organization.id}
          disabled={cohortCalculationLoading || managedAreaLoading}
        />
        <div>
          <h5>{t('settings.organization.cohortTitle')}</h5>
          <LegacyButton
            className={styles.scriptButton}
            onClick={handleCalculateCohort}
            disabled={cohortCalculationLoading || managedAreaLoading}
            variant="primary"
          >
            {t('settings.organization.calculateCohort')}
          </LegacyButton>
          {cohortCalculationLoading && <Spinner />}
          <div>{cohortCalculationResult}</div>
        </div>
      </div>

      <div className={styles.scriptContainer}>
        <h3>{t('settings.organization.makeDemo')}</h3>
        <Toggle
          checked={isDemo}
          onChange={() => setIsDemo(!isDemo)}
          className={styles.toggleContainer}
        >
          {t('settings.organization.isDemo')}
        </Toggle>
      </div>

      <div className={styles.scriptContainer}>
        <FlipperEditor
          initialFlippers={props.organization.flippers}
          availableFlippers={props.availableFlippers}
          organization={props.organization}
        />
      </div>
    </div>
  );
}

interface OrganizationScreenProps {
  organization: Organization,
  onNameEdit: (name: string) => unknown,
  onDemoEdit: (isDemo: boolean) => unknown,
  onServiceDeskUrlEdit: (serviceDeskUrl: string) => unknown,
  availableFlippers: Flippers[]
}
