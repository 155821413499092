export enum TrackableTreeProperty {
  CanopyHeight = 'canopyHeight',
  CanopyWidth = 'canopyWidth',
  TrunkDiameter = 'trunkDiameter',
  Height = 'height',
  TrunkHeight = 'trunkHeight',
  LeafAreaIndex = 'leafAreaIndex',
  SafetyFactorAtDefaultWindSpeed = 'safetyFactorAtDefaultWindSpeed',
  AvoidedRunoff = 'avoidedRunoff',
  CO = 'co',
  GrossCarbonSequestration = 'grossCarbonSequestration',
  OxygenProduction = 'oxygenProduction',
  PM25 = 'pm25',
  SO2 = 'so2',
  WaterIntercepted = 'waterIntercepted',
  CarbonStorage = 'carbonStorage',
  Evaporation = 'evaporation',
  NO2 = 'no2',
  O3 = 'o3',
  PotentialEvapotranspiration = 'potentialEvapotranspiration',
  Transpiration = 'transpiration'
}
