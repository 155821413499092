import { useTranslation } from 'react-i18next';
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SettingsPageTitle } from '../../../routes/Settings/SettingsPageTitle';
import Input from '../../UI/Input/Input';
import FormTitle from '../FormTitle';
import FormGroup from '../FormGroup';
import LegacyButton from '../../UI/Button/LegacyButton';
import FormControls from '../FormControls';
import { Password } from '../../../password/Password';
import styles from './UserPreferencesScreen.module.scss';
import { User } from '../../../users/User';
import { UserUpdateDto } from '../../../users/UserUpdateDto';
import { PasswordValidationErrorView } from '../../PasswordValidationErrorView/PasswordValidationErrorView';
import LanguageSelector from '../../../account/LanguageSelector';

export default function UserPreferencesScreen(props: UserPreferencesScreenProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVerification, setNewPasswordVerification] = useState('');

  const [firstName, setFirstName] = useState(props.user.firstName);
  const [lastName, setLastName] = useState(props.user.lastName);

  const passwordErrors = useMemo(() => {
    if (!newPassword) {
      return [];
    }
    return new Password(newPassword).getErrors();
  }, [newPassword]);

  const onPasswordChange = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (newPassword !== newPasswordVerification) {
        return;
      }

      if (passwordErrors.length !== 0) {
        return;
      }

      props.onPasswordChange(currentPassword, newPassword);
    },
    [currentPassword, passwordErrors, newPassword, newPasswordVerification, props]
  );

  useEffect(() => {
    if (!formRef.current || !props.isPasswordChangeCompleted) {
      return;
    }
    formRef.current.reset();
  }, [formRef, props]);

  const changeName = event => {
    event.preventDefault();
    if (firstName === props.user.firstName && lastName === props.user.lastName) return;
    props.onUserChange({ firstName, lastName });
  };

  return (
    <div className={styles.container}>
      <SettingsPageTitle>{t('userPreferences.title')}</SettingsPageTitle>

      <LanguageSelector onLanguageChange={props.onUserChange} initialLanguage={props.user.language}/>

      <form data-testid="user-preferences-general-details-form" className={styles.form}>
        <FormTitle>{t('userPreferences.generalDetails')}</FormTitle>

        <FormGroup layout="col-2">
          <Input
            placeholder={t('userPreferences.firstNamePlaceholder')}
            label={t('userPreferences.firstNameLabel')}
            testId="user-preferences-first-name"
            value={firstName}
            onValueChange={setFirstName}
            legacyStyle={true}
          />

          <Input
            placeholder={t('userPreferences.lastNamePlaceholder')}
            label={t('userPreferences.lastNameLabel')}
            testId="user-preferences-last-name"
            value={lastName}
            onValueChange={setLastName}
            legacyStyle={true}
          />
        </FormGroup>
        <FormControls>
          <LegacyButton
            disabled={firstName === props.user.firstName && lastName === props.user.lastName}
            onClick={e => changeName(e)}
            testId={'user-preferences-name-change'}
          >
            {t('userPreferences.saveName')}
          </LegacyButton>
        </FormControls>

      </form>

      <form
        data-testid="user-preferences-password-form"
        onSubmit={onPasswordChange}
        ref={formRef}
        className={styles.form}
      >
        <FormTitle>{t('userPreferences.changePassword')}</FormTitle>

        <FormGroup>
          <Input
            placeholder={t('userPreferences.currentPasswordPlaceholder')}
            label={t('userPreferences.currentPasswordLabel')}
            testId="user-preferences-current-password"
            type="password"
            onValueChange={setCurrentPassword}
            value={currentPassword}
            inputClassName={
              props.hasEnteredInvalidCurrentPassword && currentPassword ? styles.inputError : ''
            }
            legacyStyle={true}
          />

          {props.hasEnteredInvalidCurrentPassword && currentPassword && (
            <p className={styles.error} data-testid="user-preferences-password-error">
              {t('userPreferences.passwordNotMatchingCurrent')}
            </p>
          )}

          <Input
            placeholder={t('userPreferences.newPasswordPlaceholder')}
            label={t('userPreferences.newPasswordLabel')}
            testId="user-preferences-new-password"
            type="password"
            onValueChange={setNewPassword}
            value={newPassword}
            legacyStyle={true}
          />

          <Input
            placeholder={t('userPreferences.newPasswordVerifyPlaceholder')}
            label={t('userPreferences.newPasswordVerifyLabel')}
            testId="user-preferences-new-password-verify"
            type="password"
            onValueChange={setNewPasswordVerification}
            value={newPasswordVerification}
            legacyStyle={true}
          />

          <PasswordValidationErrorView password={newPassword} errors={passwordErrors} />
        </FormGroup>

        <FormControls>
          <LegacyButton
            disabled={
              passwordErrors.length !== 0 ||
              newPasswordVerification !== newPassword ||
              !currentPassword ||
              !newPassword
            }
          >
            {t('userPreferences.savePassword')}
          </LegacyButton>
          {props.isPasswordChangeCompleted && (
            <p className={styles.success} data-testid="user-preferences-password-success">
              {t('userPreferences.passwordSuccess')}
            </p>
          )}
        </FormControls>
      </form>
    </div>
  );
}

interface UserPreferencesScreenProps {
  onPasswordChange: (currentPassword: string, newPassword: string) => unknown,
  isPasswordChangeCompleted: boolean,
  hasEnteredInvalidCurrentPassword: boolean,
  user: User,
  onUserChange: (changes: UserUpdateDto) => unknown
}
