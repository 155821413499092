import { AxiosInstance } from 'axios';

export default class CohortService {
  constructor(private readonly http: AxiosInstance) {}

  async calculateCohort(organizationId: string) {
    await this.http.post(`/v1/organizations/${organizationId}/cohort`);
  }

  async getCohortById(organizationId: string, cohortId: string) {
    const raw = await this.http.get(`/v1/organizations/${organizationId}/cohort/${cohortId}`);
    return raw.data;
  }
}
