import { ReactChild, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './AbsoluteModal.module.scss';

export default function AbsoluteModal({ children, isVisible, onHide, hideOverlay = false, zIndex }: AbsoluteModalProps) {
  if (!isVisible) {
    return <></>;
  }

  return (
    <ModalContainer onHide={onHide}>
      <>
        <div
          className={`${styles.overlay} ${hideOverlay ? styles.hideOverlay : ''}`}
          onClick={onHide}
          style={zIndex ? { zIndex } : {}}
        />
        <div style={{ zIndex: zIndex || 99999 }}>
          {children}
        </div>
      </>
    </ModalContainer>
  );
}

function ModalContainer({ children, onHide }: { children: ReactChild, onHide: () => unknown }) {
  const [container] = useState(() => document.createElement('div'));
  container.classList.add(styles.container);
  container.classList.add(styles.overflowAuto);

  const closeOnEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onHide();
  };

  useEffect(() => {
    document.body.appendChild(container);
    container.tabIndex = 0;
    container.focus();
    container.addEventListener('keyup', closeOnEscapeKey);

    return () => {
      container.removeEventListener('keyup', closeOnEscapeKey);
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
}

interface AbsoluteModalProps {
  children: ReactNode,
  isVisible: boolean,
  onHide: () => unknown,
  hideOverlay?: boolean,
  zIndex?: number
}
