import styles from './Confirmation.module.scss';

export default function Confirmation({
  onConfirm,
  onCancel,
  confirmText,
  cancelText
}: ConfirmationProps) {
  return (
    <div className={styles.confirmationControls}>
      <button onClick={onConfirm} className={styles.confirmButton + ' ' + styles.red}>
        {confirmText}
      </button>
      <button
        onClick={onCancel}>
        {cancelText}
      </button>
    </div>
  );
}

interface ConfirmationProps {
    onConfirm: () => unknown,
    onCancel: () => unknown,
    confirmText: string,
    cancelText: string
}
