import { ReactNode } from 'react';

import styles from './FormGroup.module.scss';

export default function FormGroup({ children, layout = 'default' }: FormGroupProps) {
  const classNames = [styles.formGroup, layout === 'col-2' ? styles.col2 : ''].filter(Boolean);

  return <fieldset className={classNames.join(' ')}>{children}</fieldset>;
}

interface FormGroupProps {
  children: ReactNode,
  layout?: 'default' | 'col-2'
}
