import { SigmaBoundary } from '../../routes/Explore/table-view/advanced-filter/AdvancedFilter';
import CohortColor from './CohortColor';

export default class CohortConfig {
  private readonly mu: number;
  private readonly sigma: number;

  constructor(cohortValue: { mu: number, sigma: number }) {
    this.mu = cohortValue.mu;
    this.sigma = cohortValue.sigma;
  }

  isInBoundary(propertyValue: number, sigmaBoundary: SigmaBoundary) {
    if (sigmaBoundary === SigmaBoundary.WITHIN_ONE_SIGMA) {
      return (propertyValue >= ( this.mu - this.sigma ) && propertyValue <= ( this.mu + this.sigma ));
    }
    if (sigmaBoundary === SigmaBoundary.BETWEEN_MINUS_ONE_AND_TWO_SIGMA) {
      return (propertyValue < ( this.mu - this.sigma ) && propertyValue >= ( this.mu - this.sigma * 2 ));
    }
    if (sigmaBoundary === SigmaBoundary.BELOW_TWO_SIGMA) {
      return (propertyValue < ( this.mu - this.sigma * 2 ));
    }
    if (sigmaBoundary === SigmaBoundary.BETWEEN_ONE_AND_TWO_SIGMA) {
      return (propertyValue > ( this.mu + this.sigma ) && propertyValue <= ( this.mu + this.sigma * 2 ));
    }
    if (sigmaBoundary === SigmaBoundary.ABOVE_TWO_SIGMA) {
      return (propertyValue > ( this.mu + this.sigma * 2 ));
    }

    return false;
  }

  isOutsideBoundary(propertyValue: number, sigmaBoundary: SigmaBoundary | null) {
    if (!sigmaBoundary) return false;
    return !this.isInBoundary(propertyValue, sigmaBoundary);
  }

  getColorOfValue(value: number): string {
    const cohortColors = Object.entries(CohortColor.rgbs);
    for (const cohortColor of cohortColors) {
      const [sigmaBoundary, color] = cohortColor;
      if (this.isInBoundary(value, sigmaBoundary as SigmaBoundary)) return color;
    }

    return '255, 255, 255';
  }
}
