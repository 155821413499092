import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Text from '../Text/Text';
import styles from './Dropdown.module.scss';
import { NavArrowDown, NavArrowUp } from 'iconoir-react';

export interface Item {
  id?: string,
  translationKey?: string,
  disabled?: boolean
}

export interface DropdownProps {
  value?: Item,
  placeholder?: string,
  label?: string,
  items: Item[],
  onSelect?: (item: Item) => unknown,
  className?: string,
  fieldClassName?: string,
  dropup?: boolean,
  disabled?: boolean,
  menuClassname?: string,
  openClassname?: string,
  dropDownItemClassName?: string,
  valueRenderer?: (item: Item) => ReactNode,
  testId?: string,
  selected?: { [key: string]: boolean },
  isMultiSelect?: boolean,
  setOpen?: Dispatch<SetStateAction<boolean>>
}

const Dropdown = (props: DropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [shouldDropup, setShouldDropup] = useState(props.dropup);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSelect = (ev, item) => {
    ev.stopPropagation();
    if (item.disabled) return;
    props.onSelect?.(item);
    !props.isMultiSelect && setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const onClick = useCallback(() => {
    if (props.disabled) {
      return;
    }
    setIsDropdownOpen(state => (props.items.length > 0 ? !state : false));
  }, [props.disabled, props.items.length]);

  useEffect(() => {
    const dropdown = containerRef.current?.children.item(props.label ? 2 : 1);

    if (!isDropdownOpen || !dropdown) return;

    const isInModal = dropdown?.closest('[role=dialog]');

    const observer = new IntersectionObserver(entries => {
      if (entries[0] && entries[0].intersectionRatio < 1 && !isInModal) {
        if (props.dropup !== false) setShouldDropup(true);
      }
    });

    observer.observe(dropdown);
    dropdown.classList.add(styles.opacityFull);

    return (() => {
      observer.unobserve(dropdown);
      dropdown.classList.remove(styles.opacityFull);
    });
  }, [props.dropup, shouldDropup, isDropdownOpen, props.label]);

  useEffect(() => {
    if (props.setOpen) props.setOpen(isDropdownOpen);
  }, [props.setOpen, isDropdownOpen]);

  return (
    <div
      ref={containerRef}
      className={`${styles.container} ${props.className ?? ''} ${props.disabled ? styles.disabled : ''}`}
      onClick={onClick}
      data-testid={props.testId}
    >
      {props.label && <Text
        className={styles.label}
        weight={600}
        translationKey={props.label} />}
      <div
        className={`${styles.field} ${props.fieldClassName ?? ''} ${isDropdownOpen ? props.openClassname : ''}`}
        data-testid={`${props.testId}-value`}>
        {props.value ?
          (props.valueRenderer ? props.valueRenderer(props.value) : <Text translationKey={props.value.translationKey!} />)
          : <Text color="ternary" translationKey={props.placeholder!} />}
        {props.items.length > 0 && (isDropdownOpen ? <NavArrowUp /> : <NavArrowDown />)}
      </div>
      {isDropdownOpen && (
        <div className={`${styles.dropdown} ${shouldDropup && styles.dropup} ${props.menuClassname ?? ''}`}>
          {props.items.map((item, idx) => (
            props.valueRenderer ? (
              <div
                className={`${styles.dropdownItem} ${props.dropDownItemClassName ?? ''}`}
                key={item.id || idx}
                onClick={ev => handleSelect(ev, item)}>{ props.valueRenderer(item) }</div>
            ) :
              <Text
                className={`${styles.dropdownItem} ${props.dropDownItemClassName ?? ''} ${props.selected?.[item.id as string] ? styles.selected : ''} ${item.disabled ? styles.disabled : ''}`}
                key={item.id || idx}
                color="secondary"
                onClick={ev => handleSelect(ev, item)}
                translationKey={item.translationKey!}
              />
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
