import NumericPredicateDto from './dto/NumericPredicateDto';
import FilterPredicate, { PredicateType } from './FilterPredicate';
import { DisplayableTreeProperty, Tree } from '../tree/Tree';
import { Account } from '../account/Account';

export enum NumericPredicateType {
  IN_BETWEEN= 'in_between',
  IS_BELOW= 'is_below',
  IS_ABOVE= 'is_above'
}

export default class NumericPredicate implements FilterPredicate {
  static fromDto(dto: NumericPredicateDto) {
    return new NumericPredicate(dto.property, dto.min, dto.max);
  }

  constructor(
    readonly property: keyof Tree,
    readonly min: number | null,
    readonly max: number | null
  ) {}

  apply(tree: Tree, account?: Account): boolean {
    const property = this.property === DisplayableTreeProperty.SafetyFactors
      ? Tree.getDefaultSafetyFactorProperty(account)
      : this.property;
    const value = tree[property];
    if (value === null || typeof value !== 'number') {
      return false;
    }

    return this.isMatching(value);
  }

  getType(): NumericPredicateType {
    if (this.min !== null && this.max !== null) return NumericPredicateType.IN_BETWEEN;
    if (this.min !== null) return NumericPredicateType.IS_ABOVE;
    return NumericPredicateType.IS_BELOW;
  }

  private isMatching(value: number) {
    const isAboveMin = this.min === null || value >= this.min;
    const isBelowMax = this.max === null || value <= this.max;

    return isAboveMin && isBelowMax;
  }

  toDto(): NumericPredicateDto {
    return {
      property: this.property,
      min: this.min,
      max: this.max
    };
  }

  get type(): PredicateType {
    return PredicateType.NUMERIC;
  }
}
