import styles from './TreeMarkerVariations.module.scss';

export default class TreeMarkerVariations {
  constructor(private readonly div: HTMLDivElement) {
    this.setContent();
  }

  setContentSelected() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    marker.classList.remove(styles.markerDefault, styles.markerHidden, styles.markerDisabled);
    marker.classList.add(styles.markerSelected, styles.markerColorWhite, styles.markerShadow);
    marker.dataset.testid = 'selectedMarker';
  }

  disable() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    marker.classList.remove(styles.markerDefault, styles.markerHidden, styles.markerSelected);
    marker.classList.add(styles.markerDisabled);
  }

  private getMarkerElement(): HTMLDivElement | null {
    return this.div.querySelector('.marker');
  }

  private getLabelElement(): HTMLDivElement | null {
    return this.div.querySelector(`.${styles.label}`);
  }

  private setContent() {
    this.div.innerHTML = `<div style="position: relative;">
          <div class="marker ${styles.markerDefault} ${styles.markerColorWhite}"></div>
          <div class="${styles.label}"></div>
        </div>`;
  }

  hide() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    const label = this.getLabelElement();
    if (!label) return;
    marker.style.display = 'none';
    label.style.display = 'none';
  }

  show() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    const label = this.getLabelElement();
    if (!label) return;
    marker.style.display = 'block';
    label.style.display = 'block';
  }
}
