import { TaskTemplateWithTrees } from './TaskTemplate';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import hashString from '../../../utils/hashString';
import { JobColumnName } from './JobColumnName';

export function useTaskTemplatesWithTrees(organizationId: string): {
  categories: {
    name: string,
    taskTemplates: TaskTemplateWithTrees[],
    notAssignedCount: number,
    totalTreeCountByArea: number
  }[],
  isLoading: boolean,
  isError: boolean
} {
  const { taskManagerService, urlContext } = useContext(DependencyInjectionContext);

  const managedAreaIds = urlContext.getManagedAreaIds();
  const reversed = urlContext.getReverseMASelection();
  const cacheKey = `${organizationId}-task-templates-with-trees`;
  const managedAreaIdsCacheKey = hashString([...managedAreaIds, reversed].join(''));
  const { data, isLoading, isPreviousData, isError } = useQuery(
    [cacheKey, managedAreaIdsCacheKey],
    () => {
      if (organizationId) {
        return taskManagerService.listTaskTemplatesWithTrees(organizationId, managedAreaIds, reversed);
      }
    },
    { retry: 1, retryOnMount: false, keepPreviousData: true }
  );

  return {
    categories: data ?? [{ name: JobColumnName.Inspection, taskTemplates: [], notAssignedCount: 0, totalTreeCountByArea: 0 }],
    isLoading: isPreviousData || isLoading,
    isError
  };
}
