import { useAnalytics } from 'use-analytics';
import { useCurrentAccount } from '../account/useAccounts';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ReverseContextFieldsMap } from '../routes/Explore/UrlContext';

export function useTracking() {
  const analytics = useAnalytics();
  const { id: accountId, organization: { id: organizationId, isDemo, flippers } } = useCurrentAccount();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  enum Events {
    SIDEBAR_OPENED = 'sidebar-opened',
    SIDEBAR_CLOSED = 'sidebar-closed',
    SIDEBAR_MANAGED_AREA_SELECTED = 'sidebar-managed-area-selected',
    SIDEBAR_MANAGED_AREA_DELETED = 'sidebar-managed-area-deleted',
    SIDEBAR_ALL_MANAGED_AREA_SELECTED = 'sidebar-all-managed-area-selected',
    SIDEBAR_ALL_MANAGED_AREA_DELETED = 'sidebar-all-managed-area-deleted',

    SIDEBAR_FILTER_ADD_NEW_FILTER = 'sidebar-filter-add-new-filter',
    SIDEBAR_FILTER_ADD_SPECIES_PREDICATE = 'sidebar-filter-add-species-predicate',
    SIDEBAR_FILTER_SELECT_ALL_SPECIES = 'sidebar-filter-select-all-species',
    SIDEBAR_FILTER_REMOVE_SPECIES_PREDICATE = 'sidebar-filter-remove-species-predicate',
    SIDEBAR_FILTER_ADD_PROPERTY_PREDICATE = 'sidebar-filter-add-property-predicate',
    SIDEBAR_FILTER_CHANGE_PROPERTY_PREDICATE_TYPE = 'sidebar-filter-change-property-predicate-type',
    SIDEBAR_FILTER_NUMERIC_PREDICATE_CHANGE = 'sidebar-filter-numeric-predicate-change',
    SIDEBAR_FILTER_LABELS_PREDICATE_CHANGE = 'sidebar-filter-labels-predicate-change',
    SIDEBAR_FILTER_DELETE_FILTER_PREDICATE = 'sidebar-filter-delete-predicate-property',
    SIDEBAR_FILTER_SAVE_FILTER = 'sidebar-filter-save-filter',
    SIDEBAR_FILTER_OVERWRITE_EXISTING_FILTER = 'sidebar-filter-overwrite-existing-filter',
    SIDEBAR_FILTER_SAVE_A_COPY_OF_FILTER = 'sidebar-filter-save-a-copy-of-filter',
    SIDEBAR_FILTER_EDIT_FILTER = 'sidebar-filter-edit-filter',
    SIDEBAR_FILTER_DELETE_FILTER = 'sidebar-filter-delete-filter',
    SIDEBAR_FILTER_SELECT_FILTER_CARD = 'sidebar-filter-select-filter-card',

    TABLE_TOGGLE_CHANGE = 'table-toggle-change',
    TABLE_COLUMN_SELECTOR_OPEN = 'table-column-selector-open',
    TABLE_COLUMN_SELECTOR_CLOSE = 'table-column-selector-close',
    TABLE_COLUMN_SELECTOR_ADD_NEW_COLUMN = 'table-column-selector-add-new-column',
    TABLE_COLUMN_SELECTOR_REMOVE_COLUMN = 'table-column-selector-remove-column',
    TABLE_DOWNLOAD_ALL = 'table-download-all',
    TABLE_DOWNLOAD_PINNED = 'table-download-pinned',
    TABLE_DOWNLOAD_UNPINNED = 'table-download-unpinned',
    TABLE_DOWNLOAD_SINGLE_TREE = 'table-download-single-tree',
    TABLE_NAVIGATE_TO_TREE_DETAILS = 'table-navigate-to-tree-details',
    TABLE_PIN_TREE = 'table-pin-tree',
    TABLE_UNPIN_TREE = 'table-unpin-tree',
    TABLE_SORT_COLUMN = 'table-sort-column',
    TABLE_AGGREGATE_CONTROL_CHANGE = 'table-aggregate-control-change',

    SAFETY_FACTORY_WIND_SPEED_CHANGED_IN_LEGEND = 'safety-factory-wind-speed-changed-in-legend',
    SAFETY_FACTORY_WIND_SPEED_CHANGED_IN_DETAILS = 'safety-factory-wind-speed-changed-in-details',
    SAFETY_FACTORY_WIND_SPEED_CHANGED_IN_ANALYTICS = 'safety-factory-wind-speed-changed-in-analytics',
    SAFETY_FACTORY_MARKERS_CHANGED_IN_DETAILS = 'safety-factory-markers-changed-in-details',
    SAFETY_FACTORY_MARKERS_CHANGED_IN_ANALYTICS = 'safety-factory-markers-changed-in-analytics',

    PROPERTY_LEGEND_SELECTED = 'property-legend-selected',
    PROPERTY_LEGEND_CLOSED = 'property-legend-closed',
    PROPERTY_LEGEND_RANGE_SELECTED = 'property-legend-range-selected',
    PROPERTY_LEGEND_RANGE_SELECTED_WITHOUT_TABLE = 'property-legend-range-selected-without-table',

    REPORT_GENERATED_IN_TREE_DROPDOWN = 'report-generated-in-tree-dropdown',
    REPORT_GENERATED_IN_TABLE = 'report-generated-in-table',

    LINE_MEASUREMENT_CHANGE_IN_PANORAMIC_VIEW = 'line-measurement-change-in-panoramic-view',
    LINE_MEASUREMENT_CHANGE_IN_DETAILS = 'line-measurement-change-in-details',

    ADVANCED_FILTER_FROM_COLUMN_HEADER = 'advanced-filter-from-column-header',
    ADVANCED_FILTER_FROM_SUMMARY_MODAL = 'advanced-filter-from-summary-modal',
    ADVANCED_FILTER_FROM_SUMMARY_MODAL_APPLY = 'advanced-filter-from-summary-modal-apply',
    ADVANCED_FILTER_FROM_SUMMARY_MODAL_DELETE = 'advanced-filter-from-summary-modal-delete',
    ADVANCED_FILTER_FROM_PROPERTY_LEGEND = 'advanced-filter-from-property-legend',
    ADVANCED_FILTER_PROPERTY_CHANGED = 'advanced-filter-property-changed',
    ADVANCED_FILTER_PREDICATE_TYPE_CHANGED = 'advanced-filter-predicate-type-changed',

    PROPERTY_CONFIG_INDIVIDUAL_SAVE = 'property-config-individual-save',
    PROPERTY_CONFIG_INDIVIDUAL_RESET = 'property-config-individual-reset',
    PROPERTY_CONFIG_ALL_RESET = 'property-config-all-reset',

    MAP_STYLE_CHANGE = 'map-style-change',

    DISPLAY_MODES_DISPLAY_TREES_AS_CHANGE = 'display-modes-display-trees-as-change',
    DISPLAY_MODES_DISPLAY_ISO_MAP = 'display-modes-display-iso_map',
    DISPLAY_MODES_DISPLAY_VALUES = 'display-modes-display-values',

    CAPTURE_POINT_CHANGE_FROM_PANORAMIC_VIEW = 'capture-point-change-from-panoramic-view',
    CAPTURE_POINT_CHANGE_FROM_DETAILS_VIEW = 'capture-point-change-from-details-view',
    TREE_SELECT_FROM_TABLE = 'tree-select-from-table',
    TREE_SELECT_FROM_MAP_2D = 'tree-select-from-map-2d',
    TREE_SELECT_FROM_MAP_PANO = 'tree-select-from-map-pano',

    TREE_NAME_CARD_SHOWN_FROM_MAP = 'tree-name-card-shown-from-map',
    TREE_NAME_CARD_GO_TO_DETAILS_FROM_MAP = 'tree-name-card-go-to-details-from-map',
    TREE_NAME_CARD_SHOWN_FROM_PANO = 'tree-name-card-shown-from-pano',
    TREE_NAME_CARD_GO_TO_DETAILS_FROM_PANO = 'tree-name-card-go-to-details-from-pano',

    TREE_DETAILS_VIEW_STYLE_CHANGE = 'tree-details-view-style-change',
    TREE_DETAILS_SUBPAGE_CHANGE = 'tree-details-subpage-change',
    TREE_DETAILS_TOGGLE_ENVIRONMENT = 'tree-details-toggle-environment',
    TREE_DETAILS_TOGGLE_DETAILS = 'tree-details-toggle-details',
    TREE_DETAILS_RESET_VIEW = 'tree-details-reset-view',
    TREE_DETAILS_DIMENSIONS_PROPERTY_SELECT = 'tree-details-dimensions-property-select',

    SETTINGS_IS_DEMO_UPDATE = 'settings-is-demo-update',
    SETTINGS_DATA_MIGRATION_BY_MA = 'settings-data-migration-by-ma'
  }

  const parsedSearchParams = Array.from(searchParams.entries() || []).reduce((acc, [key, value]) => {
    const humanReadableParam = ReverseContextFieldsMap.get(key);
    if (humanReadableParam) {
      acc[humanReadableParam] = value;
    }
    return acc;
  }, {});

  return {
    events: Events,
    track: function track(event: Events, options = {}) {
      analytics.track(event, {
        ...options,
        organizationId,
        accountId,
        url: location.pathname,
        params: parsedSearchParams,
        organizationSettings: {
          isDemo,
          flippers
        }
      });
    }
  };
}
