import api from '../services/api';
import axios, { AxiosInstance } from 'axios';

export enum PasswordUpdateError {
  NotMatching = "The current password does not match the user's password"
}

export class PasswordService {
  static make() {
    return new PasswordService(api);
  }

  constructor(private readonly http: AxiosInstance) {}

  async forgot(email: string, lang: string) {
    await this.http.post('/v1/passwords/forgot', { email }, { params: { lang } });
  }

  async reset(accessToken: string, password: string, lang: string) {
    try {
      await this.http.post('/v1/passwords/reset', { password }, { params: { accessToken, lang } });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400 && error.response?.data) {
        const message = Array.isArray(error.response.data.message)
          ? error.response.data.message[0]
          : error.response.data.message;
        throw new Error(message);
      }

      throw error;
    }
  }

  async update(currentPassword: string, newPassword: string, lang: string) {
    try {
      await this.http.patch(
        '/v1/passwords',
        { currentPassword, newPassword },
        { params: { lang } }
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const message = Array.isArray(error.response.data.message)
          ? error.response.data.message[0]
          : error.response.data.message;
        throw new Error(message);
      }

      throw error;
    }
  }
}
