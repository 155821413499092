import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TreePropertyFilterEditor.module.scss';
import { SearchInput } from '../../../UI/Input/Input';
import { SpeciesRow } from './SpeciesRow';

export function SpeciesSearch({
  speciesList,
  selectedSpecies,
  onSelect,
  onRemove
}: {
  speciesList: string[], selectedSpecies: string[],
  onSelect: (value: string | null) => unknown, onRemove: (value: string | null) => unknown
}) {
  const [filteredSpeciesList, setFilteredSpeciesList] = useState(speciesList);
  const [species, setSpecies] = useState('');

  const filter = (value: string) => {
    setFilteredSpeciesList([...speciesList.filter(it => it.toLowerCase().includes(value.toLowerCase()))]);
    setSpecies(value);
  };

  const { t } = useTranslation();

  return (
    <div className={styles.speciesModalSearchContainer}>
      <SearchInput
        placeholder={t('sidebarFilter.searchSpecies')}
        className={styles.speciesModalSearchInput}
        value={species}
        onValueChange={filter}></SearchInput>
      <ul>
        {filteredSpeciesList.slice(0, 50).map(it => (
          <SpeciesRow
            isSelected={selectedSpecies.includes(it)}
            key={it}
            onSelect={onSelect}
            onRemove={onRemove}
            species={it}/>))}
      </ul>
    </div>
  );
}
