import TreeFilterDto from '../../../../tree-filter/dto/TreeFilterDto';
import { useContext, useEffect, useState } from 'react';
import EnumPredicateDto from '../../../../tree-filter/dto/EnumPredicateDto';
import { ArrowLeft, Xmark, FloppyDisk } from 'iconoir-react';
import { FunctionButton } from '../../../UI/Button/LegacyButton';
import Input from '../../../UI/Input/Input';
import styles from './TreePropertyFilterEditor.module.scss';
import { SpeciesSelector } from './SpeciesSelector';
import { PropertiesSelector } from './PropertiesSelector';
import { Tree } from '../../../../tree/Tree';
import NumericPredicate from '../../../../tree-filter/NumericPredicate';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Modal/Modal';
import PropertyConfigurationPredicate from '../../../../tree-filter/PropertyConfigurationPredicate';
import { usePropertyConfigurations } from '../../../../properties/usePropertyConfigurations';
import { TreeFilter } from '../../../../tree-filter/TreeFilter';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { useTracking } from '../../../../analytics/useTracking';
import Toggle from '../../../UI/Toggle/Toggle';
import { EntityKey, EntityStorage } from '../../../../EntityStorage';
import { User } from '../../../../users/User';

export default function TreePropertyFilterEditor(props: TreePropertyFilterEditorProps) {
  const { t } = useTranslation();
  const [modifiedFilter, setModifiedFilter] = useState<Partial<TreeFilterDto>>(props.filter);
  const propertyConfigurations = usePropertyConfigurations();
  const { urlContext, treeFilterService } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const userCache = EntityStorage.local(EntityKey.LoggedInUser, User);
  const user = userCache.getOrElse(User.anonymous());
  const { events, track } = useTracking();

  useEffect(() => {
    setModifiedFilter(props.filter);
  }, [props.filter]);

  const updateSpecies = (species: string[]) => {
    const editor = new TreeFilterEditor(modifiedFilter);
    if (species.length > 0) {
      editor.addSpecies(species);
    } else {
      editor.setAllSpecies();
    }
    setModifiedFilter({ ...editor.getFilter() });
  };

  const updateProperties = (predicate: NumericPredicate) => {
    const editor = new TreeFilterEditor(modifiedFilter);
    editor.updateNumericPredicate(predicate);
    setModifiedFilter({ ...editor.getFilter() });
  };

  const onPropertyConfigurationPredicateChange = (predicate: PropertyConfigurationPredicate) => {
    const editor = new TreeFilterEditor(modifiedFilter);
    editor.updatePropertyConfigurationPredicate(predicate);
    setModifiedFilter({ ...editor.getFilter() });
  };

  const onDeletePredicate = (predicate: NumericPredicate | PropertyConfigurationPredicate) => {
    const editor = new TreeFilterEditor(modifiedFilter);
    editor.removeNumericPredicate(predicate.property as keyof Tree);
    editor.removePropertyConfigurationPredicate(predicate.property as keyof Tree);
    setModifiedFilter({ ...editor.getFilter() });
  };

  const saveTemporary = () => {
    props.onSaveTemporary(modifiedFilter);
  };

  const save = () => {
    props.onSave(modifiedFilter);
  };

  const saveCopy = () => {
    props.onSaveCopy(modifiedFilter, copyName);
    track(events.SIDEBAR_FILTER_SAVE_A_COPY_OF_FILTER, { filter: modifiedFilter });
  };

  const [overwriting, setOverwriting] = useState(false);
  const [savingCopy, setSavingCopy] = useState(false);
  const [copyName, setCopyName] = useState('');
  const [copyNameValid, setCopyNameValid] = useState<boolean>(false);

  useEffect(() => {
    setCopyNameValid(copyName !== '' && !props.treeFilters.map(it => it.name).includes(copyName));
  }, [copyName, props.treeFilters]);

  const handleSave = () => {
    const id = TreeFilter.fromDto(modifiedFilter as TreeFilterDto).findIdByName(props.treeFilters);
    if (id && id !== urlContext.getSavedTreeFilterId()) {
      setOverwriting(true);
    } else {
      save();
      track(events.SIDEBAR_FILTER_SAVE_FILTER, { filter: modifiedFilter });
    }
  };

  const handleOverwrite = async () => {
    const id = TreeFilter.fromDto(modifiedFilter as TreeFilterDto).findIdByName(props.treeFilters);
    if (!id) return;
    const savedFilterId = urlContext.getSavedTreeFilterId();
    if (modifiedFilter.organizationId && savedFilterId) {
      await treeFilterService.delete(modifiedFilter.organizationId, savedFilterId);
    }
    urlContext.setSavedTreeFilterId(id);
    save();
    track(events.SIDEBAR_FILTER_OVERWRITE_EXISTING_FILTER, { filter: modifiedFilter });
  };

  const toggleIsPrivate = () => {
    const editor = new TreeFilterEditor(modifiedFilter);
    editor.toggleIsPrivate();
    setModifiedFilter({ ...editor.getFilter() });
  };

  if (propertyConfigurations.isLoading) return <></>;
  return (
    <>
      <div className={styles.filterSearchBar}>
        <FunctionButton
          className={styles.backButton}
          icon={<ArrowLeft />}
          onClick={() => props.onCancel()} />
        <Input
          className={styles.input}
          label={''}
          placeholder={t('filter.placeholder')}
          value={modifiedFilter.name}
          onValueChange={val => setModifiedFilter(prev => ({ ...prev, name: val }))}
          autoFocus={true}
        />
        <FunctionButton
          className={styles.saveIcon}
          icon={<FloppyDisk />}
          onClick={handleSave}
          disabled={modifiedFilter.name?.length === 0}/>
      </div>

      {(props.filter.userId === user.id || account.canManageMembers()) &&
        <>
          <div className={styles.privateToggleContainer}>
            <div>{t('sidebarFilter.publishFilter')}</div>
            <div>
              <Toggle checked={!modifiedFilter.isPrivate} onChange={toggleIsPrivate}/>
            </div>
          </div>
          <div className={styles.dividerLine} />
        </>
      }
      <SpeciesSelector
        selectedSpecies={TreeFilterEditor.getSpecies(modifiedFilter)}
        onSelect={updateSpecies}
        onSaveTemporary={saveTemporary} />
      <div className={styles.dividerLine} />
      <PropertiesSelector
        account={account}
        predicates={[...TreeFilterEditor.getNumericPredicates(modifiedFilter), ...TreeFilterEditor.getPropertyConfigurationPredicates(modifiedFilter)]}
        onChange={updateProperties}
        onDelete={onDeletePredicate}
        onSaveTemporary={saveTemporary}
        onPropertyConfigurationPredicateChange={onPropertyConfigurationPredicateChange}
        propertyConfigurations={propertyConfigurations.data!}
      />
      <Modal
        className={styles.modal}
        isVisible={overwriting}
        onHide={() => setOverwriting(false)}>
        <div className={styles.modalTitle}>
          {t('sidebarFilter.overwriteFilter')}
          <FunctionButton icon={<Xmark/>} onClick={() => setOverwriting(false)}/>
        </div>
        <div className={styles.modalDescription}>{t('sidebarFilter.overwriteDescription', { name: modifiedFilter.name })}</div>
        <div className={styles.modalControls}>
          <button onClick={handleOverwrite} className={styles.overwriteButton}>
            {t('sidebarFilter.overwriteFilter')}
          </button>
          <button
            onClick={() => { setOverwriting(false); setSavingCopy(true); }}
            className={styles.saveCopyButton}>
            {t('sidebarFilter.saveACopy')}
          </button>
        </div>
      </Modal>
      <Modal
        className={styles.modal}
        isVisible={savingCopy}
        onHide={() => setSavingCopy(false)}>
        <div className={styles.modalTitle}>
          {t('sidebarFilter.saveNewFilter')}
          <FunctionButton icon={<Xmark/>} onClick={() => setSavingCopy(false)}/>
        </div>
        <div className={styles.modalDescription}>
          <Input
            value={copyName}
            onValueChange={val => setCopyName(val)}
            placeholder={t('sidebarFilter.filterName')}
            label={''}/>
        </div>
        <div className={styles.modalControls}>
          <button
            onClick={() => setSavingCopy(false)}
            className={styles.saveCopyButton}>
            {t('sidebarFilter.cancel')}
          </button>
          <button
            onClick={() => saveCopy()}
            disabled={!copyNameValid}
            className={`${styles.saveCopyButton} ${!copyNameValid && styles.disabled}`}>
            {t('sidebarFilter.saveACopy')}
            <FloppyDisk/>
          </button>
        </div>
      </Modal>
    </>
  );
}

interface TreePropertyFilterEditorProps {
  filter: Partial<TreeFilterDto>,
  onCancel: () => unknown,
  onSaveTemporary: (filter: Partial<TreeFilterDto>) => unknown,
  onSave: (filter: Partial<TreeFilterDto>) => unknown,
  onSaveCopy: (filter: Partial<TreeFilterDto>, name: string) => unknown,
  treeFilters: TreeFilter[]
}

class TreeFilterEditor {
  static getSpecies(filter) {
    return filter.enumPredicates?.find(it => it.property === 'species')?.values || [];
  }

  static getNumericPredicates(filter: Partial<TreeFilterDto>) {
    return filter.numericPredicates?.map(it => NumericPredicate.fromDto(it)) || [];
  }

  static getPropertyConfigurationPredicates(filter: Partial<TreeFilterDto>) {
    return filter.propertyConfigurationPredicates?.map(it => PropertyConfigurationPredicate.fromDto(it)) || [];
  }

  private readonly filter: Partial<TreeFilterDto>;

  constructor(filter: Partial<TreeFilterDto>) {
    this.filter = filter;
  }

  addSpecies(species) {
    let speciesPredicate = this.filter.enumPredicates?.find(it => it.property === 'species' as keyof Tree);
    if (!speciesPredicate) {
      speciesPredicate = { property: 'species' as keyof Tree, values: [] as string[] };
    }

    speciesPredicate.values = species;

    this.updateEnumPredicates(speciesPredicate);
  }

  setAllSpecies() {
    this.removeEnumPredicate('scientificName');
  }

  getFilter() {
    return this.filter;
  }

  toggleIsPrivate() {
    return this.filter.isPrivate = !this.filter.isPrivate;
  }

  updateNumericPredicate(predicate: NumericPredicate) {
    if (!this.filter.numericPredicates) {
      return;
    }
    const index = this.filter.numericPredicates?.findIndex(it => it.property === predicate.property);
    if (index === -1) {
      this.filter.numericPredicates.push(predicate.toDto());
      return;
    }
    this.filter.numericPredicates![index] = predicate.toDto();
  }

  removeNumericPredicate(property: keyof Tree) {
    this.filter.numericPredicates = this.filter.numericPredicates?.filter(it => it.property !== property);
  }

  removePropertyConfigurationPredicate(property: keyof Tree) {
    this.filter.propertyConfigurationPredicates = this.filter.propertyConfigurationPredicates?.filter(it => it.propertyConfiguration.property !== property);
  }

  updatePropertyConfigurationPredicate(predicate: PropertyConfigurationPredicate) {
    if (!this.filter.propertyConfigurationPredicates) return;
    this.filter.numericPredicates = this.filter.numericPredicates?.filter(it => predicate.property !== it.property);
    const index = this.filter.propertyConfigurationPredicates?.findIndex(it => it.propertyConfiguration.property === predicate.property);
    if (index === -1) {
      this.filter.propertyConfigurationPredicates.push(predicate.toDto());
      return;
    }
    this.filter.propertyConfigurationPredicates![index] = predicate.toDto();
  }

  private updateEnumPredicates(predicate: EnumPredicateDto) {
    this.filter.enumPredicates = this.filter.enumPredicates?.filter(it => it.property !== predicate.property);
    this.filter.enumPredicates = [...(this.filter.enumPredicates || []), predicate];
  }

  private removeEnumPredicate(property: keyof Tree) {
    this.filter.enumPredicates = this.filter.enumPredicates?.filter(it => it.property !== property);
  }
}
