import { AriaRole, LegacyRef, MouseEvent, ReactNode, useMemo } from 'react';

import styles from './LegacyButton.module.scss';
import Tooltip from '../Tooltip/Tooltip';

export default function LegacyButton({
  children,
  onClick,
  className = '',
  variant,
  disabled = false,
  type = 'submit',
  ref,
  role = 'button',
  testId,
  size = 'm',
  fullWidth = false,
  tooltip = ''
}: ButtonProps) {
  const classNames = useMemo(
    () =>
      [
        styles.button,
        className,
        variant && styles[variant],
        fullWidth && styles.fullWidth,
        styles[`size${size.toUpperCase()}`]
      ]
        .filter(Boolean)
        .join(' '),
    [className, variant, fullWidth, size]
  );

  const button = <button
    role={role}
    ref={ref}
    onClick={event => onClick?.(event)}
    className={classNames}
    disabled={disabled}
    type={type}
    data-testid={testId}
  >
    {children}
  </button>;

  return (<>
    {tooltip
      ? <Tooltip overlay={tooltip}>
        {button}
      </Tooltip>
      : button}
  </>);
}

export const ButtonWithIcon = (props: ButtonProps & { icon: ReactNode }) => {
  return (
    <LegacyButton {...props} className={(props.className || '').concat(' ', styles.buttonWithIcon)}>
      <span data-style="icon">{props.icon}</span>

      {props.children && <span>{props.children}</span>}
    </LegacyButton>
  );
};

export const FunctionButton = (props: ButtonProps & { icon?: ReactNode }) => {
  return (
    <LegacyButton {...props} className={(props.className || '').concat(' ', styles.functionButton)}>
      {props.icon && <span data-style="icon">{props.icon}</span>}

      {props.children && <span>{props.children}</span>}
    </LegacyButton>
  );
};

export const SecondaryButton = (props: Omit<ButtonProps, 'variant'>) => (
  <LegacyButton {...props} variant="secondary">
    {props.children}
  </LegacyButton>
);

export const PrimaryButton = (props: Omit<ButtonProps, 'variant'>) => (
  <LegacyButton {...props} variant="primary">
    {props.children}
  </LegacyButton>
);

interface ButtonProps {
    children?: ReactNode,
    onClick?: (event: MouseEvent) => unknown,
    className?: string,
    variant?: 'primary' | 'success' | 'secondary' | 'tertiary' | 'secondaryFilled' | 'rounded',
    disabled?: boolean,
    type?: 'button' | 'submit' | 'reset',
    ref?: LegacyRef<HTMLButtonElement>,
    role?: AriaRole,
    testId?: string,
    size?: 's' | 'm' | 'l',
    fullWidth?: boolean,
    tooltip?: string
}
