import PropertyColorConfiguration from '../../../properties/PropertyColorConfiguration';
import { Expression, StyleFunction } from 'mapbox-gl';
import PropertyConfiguration, { PropertyRange } from '../../../properties/PropertyConfiguration';
import { AdvancedFilterConfiguration, SigmaBoundary } from '../table-view/advanced-filter/AdvancedFilter';
import { AdvancedFilterColor } from './AdvancedFilterColor';
import MapboxColor from './MapboxColor';
import { DisplayableTreeProperty } from '../../../tree/Tree';
import { ColoringType } from '../../../components/Navbar/PropertyLegend';
import CohortColor from '../../../components/cohort/CohortColor';
import { TrackableTreeProperty } from '../../../tree/TrackableTreeProperty';

export default class CanopyLayerColorCalculator {
  private static readonly fillOpacity = 0.3;
  private static readonly fadedFillOpacity = 0.1;
  private static readonly selectedFillOpacity = 0.95;
  private static readonly lineOpacity = 1;
  private static readonly fadedLineOpacity = 0.5;
  private static readonly selectedLineOpacity = 1;

  private static readonly defaultFillColor = `rgba(206, 215, 212, ${CanopyLayerColorCalculator.fillOpacity})`;

  private windSpeed: number | null = null;
  calculate(
    selectedPropertyRangeIndex: number,
    selectedPropertyCohortBoundary: SigmaBoundary | null,
    selectedPropertyConfig: PropertyConfiguration | null,
    coloringType: ColoringType,
    advancedFilterConfig: AdvancedFilterConfiguration,
    selectedTreeId: string | null,
    windSpeed: number | null = null
  ): { fillColor: string | StyleFunction | Expression, lineColor: string | StyleFunction | Expression } {
    this.windSpeed = windSpeed;
    const advancedFilterColor = new AdvancedFilterColor(advancedFilterConfig, windSpeed || 80);

    if (!selectedPropertyConfig) {
      return this.getMonochromeColorConfig(selectedTreeId, advancedFilterColor);
    }

    const configMap = PropertyColorConfiguration.getColorsForConfig(selectedPropertyConfig);

    return {
      fillColor: [
        'case',
        ...this.getFillColorForNullValues(selectedPropertyConfig, selectedTreeId),
        ...advancedFilterColor.getFillColorCase(),
        ...this.getSelectedFillColorCases(selectedPropertyConfig, coloringType, configMap, selectedPropertyRangeIndex, selectedTreeId, selectedPropertyCohortBoundary),
        ...this.getFillColorCases(selectedPropertyConfig, coloringType, configMap, selectedPropertyRangeIndex, selectedPropertyCohortBoundary)
      ],
      lineColor: [
        'case',
        ...this.getLineColorForNullValues(selectedPropertyConfig),
        ...advancedFilterColor.getLineColorCase(),
        ...this.getSelectedLineColorCases(selectedPropertyConfig, coloringType, configMap, selectedPropertyRangeIndex, selectedTreeId, selectedPropertyCohortBoundary),
        ...this.getLineColorCases(selectedPropertyConfig, coloringType, configMap, selectedPropertyRangeIndex, selectedPropertyCohortBoundary)
      ]
    };
  }

  private getFillColorForNullValues(propertyConfig: PropertyConfiguration, selectedTreeId: string | null): (Expression | string)[] {
    return [
      [
        'all',
        ['==', ['get', 'id'], selectedTreeId],
        ['==', ['get', propertyConfig!.property], null]
      ],
      `rgba(206, 215, 212, ${CanopyLayerColorCalculator.selectedFillOpacity})`,
      ['==', ['get', propertyConfig!.property], null],
      CanopyLayerColorCalculator.defaultFillColor
    ];
  }

  private getLineColorForNullValues(propertyConfig: PropertyConfiguration): (Expression | string)[] {
    return [
      ['==', ['get', propertyConfig!.property], null],
      `rgba(206, 215, 212, ${CanopyLayerColorCalculator.lineOpacity})`
    ];
  }

  private getFillColorCases(
    propertyConfig: PropertyConfiguration,
    coloringType: ColoringType,
    configMap: string[],
    highlightedRangeIndex: number,
    highlightedCohortBoundary: SigmaBoundary | null
  ): (Expression | string)[] {
    const conditionColorPairs = coloringType === ColoringType.COHORT ?
      new CohortColor(propertyConfig.property as unknown as TrackableTreeProperty).getMapboxColorConditions(CanopyLayerColorCalculator.fillOpacity, highlightedCohortBoundary, CanopyLayerColorCalculator.fadedFillOpacity) :
      propertyConfig.ranges.flatMap((range, i) => [
        this.propertyRangeToCondition(range, propertyConfig!.property),
        `rgba(${configMap[i]}, ${this.calculateFillOpacity(i, highlightedRangeIndex)})`
      ]);

    return [
      ...conditionColorPairs,
      `rgba(206, 215, 212, ${highlightedRangeIndex > -1 ? CanopyLayerColorCalculator.fadedFillOpacity : CanopyLayerColorCalculator.fillOpacity})`
    ];
  }

  private getSelectedFillColorCases(
    propertyConfig: PropertyConfiguration,
    coloringType: ColoringType,
    configMap: string[],
    highlightedRangeIndex: number,
    selectedTreeId: string | null,
    highlightedCohortBoundary: SigmaBoundary | null
  ): (Expression | string)[] {
    if (!selectedTreeId) {
      return [];
    }

    const conditionColorPairs = coloringType === ColoringType.COHORT ?
      new CohortColor(propertyConfig.property as unknown as TrackableTreeProperty).getMapboxColorConditionsWithSelectedTree(selectedTreeId, CanopyLayerColorCalculator.selectedFillOpacity, highlightedCohortBoundary, CanopyLayerColorCalculator.fadedFillOpacity) :
      propertyConfig.ranges.flatMap((range, i) => [
        this.toSelectedCondition(range, propertyConfig!.property, selectedTreeId),
        `rgba(${configMap[i]}, ${this.calculateSelectedFillOpacity(i, highlightedRangeIndex)})`
      ]);

    return [
      ...conditionColorPairs,
      ['==', ['get', 'id'], selectedTreeId], `rgba(206, 215, 212, ${CanopyLayerColorCalculator.selectedFillOpacity})`
    ];
  }

  private getLineColorCases(
    propertyConfig: PropertyConfiguration,
    coloringType: ColoringType,
    configMap: string[],
    highlightedRangeIndex: number,
    highlightedCohortBoundary: SigmaBoundary | null
  ): (Expression | string)[] {
    const conditionColorPairs = coloringType === ColoringType.COHORT ?
      new CohortColor(propertyConfig.property as unknown as TrackableTreeProperty).getMapboxColorConditions(
        CanopyLayerColorCalculator.lineOpacity,
        highlightedCohortBoundary,
        CanopyLayerColorCalculator.fadedLineOpacity
      ) :
      propertyConfig.ranges.flatMap((range, i) => [
        this.propertyRangeToCondition(range, propertyConfig!.property),
        `rgba(${configMap[i]}, ${this.calculateLineOpacity(i, highlightedRangeIndex)})`
      ]);

    return [
      ...conditionColorPairs,
      `rgba(206, 215, 212, ${highlightedRangeIndex > -1 ? CanopyLayerColorCalculator.fadedLineOpacity : CanopyLayerColorCalculator.lineOpacity})`
    ];
  }

  private getSelectedLineColorCases(
    propertyConfig: PropertyConfiguration,
    coloringType: ColoringType,
    configMap: string[],
    highlightedRangeIndex: number,
    selectedTreeId: string | null,
    highlightedCohortBoundary: SigmaBoundary | null
  ): (Expression | string)[] {
    if (!selectedTreeId) {
      return [];
    }

    const conditionColorPairs = coloringType === ColoringType.COHORT ?
      new CohortColor(propertyConfig.property as unknown as TrackableTreeProperty).getMapboxColorConditionsWithSelectedTree(
        selectedTreeId,
        CanopyLayerColorCalculator.selectedLineOpacity,
        highlightedCohortBoundary,
        CanopyLayerColorCalculator.fadedLineOpacity
      ) :
      propertyConfig.ranges.flatMap((range, i) => [
        this.toSelectedCondition(range, propertyConfig!.property, selectedTreeId),
        `rgba(${configMap[i]}, ${this.calculateSelectedLineOpacity(i, highlightedRangeIndex)})`
      ]);
    return [
      ...conditionColorPairs,
      ['==', ['get', 'id'], selectedTreeId], `rgba(206, 215, 212, ${CanopyLayerColorCalculator.selectedLineOpacity})`
    ];
  }

  private calculateFillOpacity(i: number, highlightedRangeIndex: number): number {
    return this.calculateOpacity(i, highlightedRangeIndex, CanopyLayerColorCalculator.fillOpacity, CanopyLayerColorCalculator.fadedFillOpacity);
  }

  private calculateLineOpacity(i: number, highlightedRangeIndex: number): number {
    return this.calculateOpacity(i, highlightedRangeIndex, CanopyLayerColorCalculator.lineOpacity, CanopyLayerColorCalculator.fadedLineOpacity);
  }

  private calculateSelectedFillOpacity(i: number, highlightedRangeIndex: number): number {
    return this.calculateOpacity(i, highlightedRangeIndex, CanopyLayerColorCalculator.selectedFillOpacity, CanopyLayerColorCalculator.fadedFillOpacity);
  }

  private calculateSelectedLineOpacity(i: number, highlightedRangeIndex: number): number {
    return this.calculateOpacity(i, highlightedRangeIndex, CanopyLayerColorCalculator.selectedLineOpacity, CanopyLayerColorCalculator.fadedLineOpacity);
  }

  private calculateOpacity(i: number, highlightedRangeIndex: number, opacity: number, fadedOpacity: number): number {
    return (highlightedRangeIndex === -1 || i === highlightedRangeIndex) ? opacity : fadedOpacity;
  }

  private propertyRangeToCondition(range: PropertyRange, property: string): Expression {
    return [
      'all',
      ['<', range.from, this.getProperty(property)],
      ['<=', this.getProperty(property), range.to]
    ];
  }

  private toSelectedCondition(range: PropertyRange, property: string, selectedTreeId: string): Expression {
    return [
      'all',
      ['<', range.from, this.getProperty(property)],
      ['<=', this.getProperty(property), range.to],
      ['==', ['get', 'id'], selectedTreeId]
    ];
  }

  private getProperty(property) {
    if (property !== DisplayableTreeProperty.SafetyFactors) return ['get', property];
    const windSpeed = this.windSpeed || 80;
    const propertyName = `${DisplayableTreeProperty.SafetyFactors}-${windSpeed}`;
    return ['get', propertyName];
  }

  private getMonochromeColorConfig(selectedTreeId: string | null, advancedConfigColor: MapboxColor): { fillColor: string | StyleFunction | Expression, lineColor: string | StyleFunction | Expression } {
    let fillColor, lineColor;

    if (selectedTreeId || advancedConfigColor.getFillColorCase().length > 0) {
      fillColor = [
        'case',
        ...advancedConfigColor.getFillColorCase(),
        ...selectedTreeId ? [['==', ['get', 'id'], selectedTreeId], `rgba(206, 215, 212, ${CanopyLayerColorCalculator.selectedFillOpacity})`] : [],
        CanopyLayerColorCalculator.defaultFillColor
      ];
    } else {
      fillColor = CanopyLayerColorCalculator.defaultFillColor;
    }

    if (advancedConfigColor.getFillColorCase().length > 0) {
      lineColor = [
        'case',
        ...advancedConfigColor.getLineColorCase(),
        `rgba(206, 215, 212, ${CanopyLayerColorCalculator.lineOpacity})`
      ];
    } else {
      lineColor = `rgba(206, 215, 212, ${CanopyLayerColorCalculator.lineOpacity})`;
    }

    return { fillColor, lineColor };
  }
}
