export class PanelLayout {
  private state: 'DEFAULT' | 'TABLE' = 'DEFAULT';
  private tableHeight = 0;

  constructor(treeTableOpen: boolean) {
    this.state = treeTableOpen ? 'TABLE' : 'DEFAULT';
  }

  setTableDragDistance(height: number) {
    this.tableHeight = height;
  }

  toggleTable() {
    this.tableHeight = 0;
    if (this.isTableVisible()) return this.state = 'DEFAULT';
    this.state = 'TABLE';
  }

  getTableContainerStyle() {
    if (this.isTableVisible()) return { height: `${this.getTableHeight()}`, bottom: 0 };
    return { height: `${this.getTableHeight()}`, bottom: `-${this.getTableHeight()}` };
  }

  getControlsContainerStyle() {
    if (this.tableShouldSnapToTop()) return { display: 'none' };
    if (this.state === 'TABLE') return { bottom: `${this.getTableHeight()}` };
    return {};
  }

  getPropertyLegendContainerStyle(isTreeInfoOpen: boolean) {
    if (this.tableShouldSnapToTop()) return { display: 'none' };
    if (isTreeInfoOpen) return { left: 'calc(40% + 150px)' };
    return {};
  }

  getMapContainerStyle() {
    if (this.isTableVisible()) {
      const opacity = this.tableShouldSnapToTop() ? { opacity: 0.2 } : {};
      return { bottom: `${this.getTableHeight()}`, ...opacity };
    }

    return {};
  }

  private getTableHeight() {
    if (!this.isTableVisible()) return 0;
    if (this.tableHeight === 0) return '50%';
    return this.tableHeight + 'px';
  }

  getTreeInfoStyle() {
    if (this.tableShouldSnapToTop()) {
      return { display: 'none' } ;
    }
    return {};
  }

  isTableVisible() {
    return this.state === 'TABLE';
  }

  tableShouldSnapToTop() {
    return this.isTableVisible() && this.tableHeight >= window.innerHeight * 0.7;
  }
}
