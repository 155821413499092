import styles from './TabSelector.module.scss';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useMemo } from 'react';
import { NavArrowDown, NavArrowRight } from 'iconoir-react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import useMountedEffect from '../../../../hooks/useMountedEffect';

export enum Tab {
  Risk = 'risk',
  HealthAndVitality = 'healthAndVitality',
  Environment = 'environment',
  Benefits = 'benefits'
}

export default function TabSelector() {
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const selectedTab = useMemo(() => {
    return urlContext.getSelectedDataPanelTab();
  }, [JSON.stringify(urlContext.getSelectedDataPanelTab())]);

  const handleTabClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, key) => {
    event.stopPropagation();
    urlContext.setSelectedDataPanelTab(key);
    urlContext.setHistoryTrackingOpen(false);
  };

  useEffect(() => {
    const tab = document.getElementById(`${selectedTab}-tab`);
    const tabBackground = document.getElementById('activeTabBackground');
    if (tab && tabBackground) {
      tabBackground.style.left = tab.offsetLeft + 'px';
      tabBackground.style.width = tab.offsetWidth + 'px';
    }
  }, [selectedTab]);

  useMountedEffect(() => {
    urlContext.setSelectedEnvAccordion(null);
  }, [selectedTab]);

  const toggleOpen = () => {
    if (selectedTab) urlContext.setSelectedDataPanelTab(null);
    else {
      urlContext.setSelectedDataPanelTab(Tab.Risk);
      urlContext.setHistoryTrackingOpen(false);
    }
  };

  return (
    <nav className={`${styles.dataPanelTabSelector} ${selectedTab ? styles.open : ''}`}>
      <ul>
        <span className={styles.chevronButton} onClick={toggleOpen}>
          {
            selectedTab ? <NavArrowDown fontSize={18} /> : <NavArrowRight fontSize={18} />
          }
        </span>
        {Object.values(Tab).map(key =>
          <div
            className={styles.navItemWrapper}
            key={key}
          >
            <li
              id={`${key}-tab`}
              onClick={event => handleTabClick(event, key)}
              className={selectedTab === key ? styles.selectedTab : ''}
            >
              <span>{t(`treeDetails.tabs.${key}`)}</span>
            </li>
          </div>
        )}
        <div id="activeTabBackground" className={styles.activeTabBackground} />
      </ul>
    </nav>
  );
}
