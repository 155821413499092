import { useContext, useEffect, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import TreeFilterDto from '../../../../tree-filter/dto/TreeFilterDto';
import { TreeFilter } from '../../../../tree-filter/TreeFilter';
import { FunctionButton } from '../../../UI/Button/LegacyButton';
import { Filter, Plus } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import styles from './TreePropertyFilter.module.scss';
import TreePropertyFilterEditor from './TreePropertyFilterEditor';
import TreeFilterCard from './TreeFilterCard';
import { useTracking } from '../../../../analytics/useTracking';

export default function TreePropertyFilter({
  organizationId,
  treeFilters,
  setTreeFilters
}: TreePropertyFilterProps) {
  const { treeFilterService, urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();
  const { events, track } = useTracking();

  useEffect(() => {
    setSelection(urlContext.getTreeFilterIds());
  }, [urlContext.serialize()]);

  useEffect(() => {
    const privateFilterId = urlContext.getTreeFilterIds()[0];
    if (!urlContext.getSavedTreeFilterId() || !privateFilterId) return;

    const privateFilter = treeFilters.find(it => it.id === privateFilterId);
    if (!privateFilter) return;
    setFilterToEdit(privateFilter.toDto());
    setFirstPage(false);
  }, [treeFilters.length]);

  const [filterToEdit, setFilterToEdit] = useState<Partial<TreeFilterDto> | null>(null);

  const [selection, setSelection] = useState<string[]>([]);
  const [firstPage, setFirstPage] = useState(true);

  const navigateToEdit = async (filter: Partial<TreeFilterDto>) => {
    const createdId = await createTemporaryFilterWithData(filter);
    const createdTemporaryFilter = await fetchListAndFindFilterById(createdId);
    if (!createdTemporaryFilter) return;
    urlContext.addTreeFilter(createdTemporaryFilter);
    urlContext.setSavedTreeFilterId(filter.id!);
    setFilterToEdit(createdTemporaryFilter.toDto());
    setFirstPage(false);
    track(events.SIDEBAR_FILTER_EDIT_FILTER, { filter });
  };

  const navigateToCreateNew = async () => {
    const privateFilterId = await createTemporaryFilterWithData({});
    const createdTemporaryFilter = await fetchListAndFindFilterById(privateFilterId);
    if (!createdTemporaryFilter) return;
    urlContext.deleteTreeFilterIds();
    urlContext.setTreeFilterIds([createdTemporaryFilter.id]);
    setFilterToEdit(createdTemporaryFilter.toDto());
    track(events.SIDEBAR_FILTER_ADD_NEW_FILTER);
    setFirstPage(false);
  };

  const saveTemporary = async (dto: Partial<TreeFilterDto>) => {
    await treeFilterService.update(organizationId, dto.id!, dto);
    const updatedFilter = await fetchListAndFindFilterById(dto.id!);
    if (!updatedFilter) return;
    urlContext.deleteTreeFilterIds();
    urlContext.setTreeFilterIds([updatedFilter.id]);
  };

  const saveFilter = async (temporaryFilterDto: Partial<TreeFilterDto>) => {
    if (!temporaryFilterDto.id) return;
    const savedId = urlContext.getSavedTreeFilterId();
    let updatedFilter;
    if (savedId) {
      const savedFilter = treeFilters.find(it => it.id === savedId);
      if (!savedFilter) return;
      updatedFilter = { ...temporaryFilterDto, id: savedFilter.id, isSaved: true };
      await treeFilterService.update(organizationId, updatedFilter.id!, updatedFilter);
    } else {
      updatedFilter = await treeFilterService.create(organizationId, { ...temporaryFilterDto, isSaved: true });
    }

    await treeFilterService.delete(organizationId, temporaryFilterDto.id);
    const savedFilter = await fetchListAndFindFilterById(updatedFilter.id);
    if (!savedFilter) return;
    urlContext.deleteTreeFilterIds();
    urlContext.setTreeFilterIds([savedFilter.id]);
    urlContext.setSavedTreeFilterId('');
    setFirstPage(true);
  };

  const saveCopy = async (temporaryFilterDto: Partial<TreeFilterDto>, name: string) => {
    if (!temporaryFilterDto.id) return;
    const copy = { ...temporaryFilterDto, name, isSaved: true };
    const newFilter = await treeFilterService.create(organizationId, copy);

    await treeFilterService.delete(organizationId, temporaryFilterDto.id);
    const savedFilter = await fetchListAndFindFilterById(newFilter.id);
    if (!savedFilter) return;
    urlContext.deleteTreeFilterIds();
    urlContext.setTreeFilterIds([savedFilter.id]);
    urlContext.setSavedTreeFilterId('');
    setFirstPage(true);
  };

  const createTemporaryFilterWithData = async (filter: Partial<TreeFilterDto>): Promise<string> => {
    const existingTemporaryFilter: Partial<TreeFilterDto> = treeFilters.find(it => !it.isSaved)?.toDto() || {};
    const updatedTemporaryFilter = {
      ...filter,
      name: filter.name || '',
      numericPredicates: filter.numericPredicates || [],
      enumPredicates: filter.enumPredicates || [],
      propertyConfigurationPredicates: filter.propertyConfigurationPredicates || [],
      id: existingTemporaryFilter.id,
      isSaved: false
    };
    let createdId;
    if (!updatedTemporaryFilter.id) {
      createdId = (await treeFilterService.create(organizationId, updatedTemporaryFilter)).id;
    } else {
      await treeFilterService.update(organizationId, updatedTemporaryFilter.id, updatedTemporaryFilter);
      createdId = updatedTemporaryFilter.id;
    }
    return createdId;
  };

  const fetchListAndFindFilterById = async (filterId: string) => {
    const list = (await treeFilterService.list(organizationId));
    setTreeFilters(list);
    return list.find(it => it.id === filterId);
  };

  const filterCards = useMemo(() => {
    const select = (treeFilter: TreeFilter) => {
      if (firstPage) {
        urlContext.setTreeFilterIds([treeFilter.id]);
      }
      setSelection([treeFilter.id]);
      track(events.SIDEBAR_FILTER_SELECT_FILTER_CARD, { filter: treeFilter.toDto() });
    };

    const unselect = () => {
      if (firstPage) {
        urlContext.setTreeFilterIds([]);
      }
      setSelection([]);
    };

    const listFilters = () => {
      treeFilterService.list(organizationId)
        .then(setTreeFilters);
    };

    const deleteFilter = (treeFilter: TreeFilter) => {
      treeFilterService.delete(organizationId, treeFilter.id)
        .then(() => {
          unselect();
          listFilters();
        });
      track(events.SIDEBAR_FILTER_DELETE_FILTER, { filter: treeFilter.toDto() });
    };

    return treeFilters.filter(it => it.isSaved).sort((a, b) => a.getName().localeCompare(b.getName()))
      .map((treeFilter, idx) => (
        <TreeFilterCard
          key={`${treeFilter.id}-${idx}`}
          treeFilter={treeFilter}
          unselect={() => unselect()}
          select={() => select(treeFilter)}
          deleteFilter={() => deleteFilter(treeFilter)}
          inSelection={selection.includes(treeFilter.id)}
          setFilterToEdit={() => navigateToEdit(treeFilter.toDto())}/>
      ));
  }, [
    firstPage, urlContext.serialize(), navigateToEdit, organizationId, selection,
    JSON.stringify(setTreeFilters), treeFilterService, JSON.stringify(treeFilters)
  ]);

  return (
    <div className={styles.filterContainer}>
      {firstPage ?
        <div className={styles.firstPageContainer}>
          <div className={styles.firstPage}>
            <div className={styles.labelContainer}>
              <Filter/>
              <div className={styles.label}>
                <span>{t('sidebarFilter.selectTreeFilters')}</span>
                <span>{t('sidebarFilter.trees')}</span>
              </div>
            </div>
            <FunctionButton
              icon={<Plus/>}
              className={styles.toSecondPage}
              onClick={() => {
                navigateToCreateNew();
              }}/>
          </div>

          <ul className={styles.filterList}>
            {filterCards}
          </ul>
        </div>
        :
        <TreePropertyFilterEditor
          filter={filterToEdit!}
          onCancel={() => setFirstPage(true)}
          onSaveTemporary={saveTemporary}
          onSaveCopy={saveCopy}
          onSave={saveFilter}
          treeFilters={treeFilters.filter(it => it.isSaved)}
        />
      }
    </div>
  );
}

interface TreePropertyFilterProps {
  organizationId: string,
  treeFilters: TreeFilter[],
  setTreeFilters: (treeFilter: TreeFilter[]) => void
}
