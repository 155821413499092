import styles from '../../Inventory/Inventory.module.scss';
import DetailedTree from '../../../../../tree/DetailedTree';
import ObservationsDataGroup from './ObservationsDataGroup';
import StructuralAssessmentDataGroup from './StructuralAssessmentDataGroup';
import Conclusion from './Conclusion';

export default function RiskTab(props: RiskTabProps) {
  return (
    <div className={styles.content}>
      <ObservationsDataGroup
        tree={props.tree}
        singleColumn={props.singleColumn}
        organizationId={props.organizationId}
      />
      <StructuralAssessmentDataGroup
        tree={props.tree}
        singleColumn={props.singleColumn}
      />
      <Conclusion
        tree={props.tree}
        singleColumn={props.singleColumn}
        organizationId={props.organizationId}
      />
    </div>
  );
}

interface RiskTabProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organizationId: string
}
