import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import { useTranslation } from 'react-i18next';
import { DetailsButtonHelpers } from './Analytics';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TreeStatistics } from '../../tree/TreeStatistics';
import ReferenceChart, { ReferenceData } from './components/ReferenceChart';
import TreePropertyHistoryChart from './components/TreePropertyHistoryChart';
import TreePropertyStateChart from './components/TreePropertyStateChart';
import StatisticsEmptyState from './components/StatisticsEmptyState';
import {
  DetailsButton,
  ExtraDetailsButton,
  PageContainer,
  PageContent,
  PageHeader,
  PageHistoryControls,
  PagePropertyChartContainer,
  PageReferenceChartContainer,
  PropertyListItem,
  ResetViewButton,
  TreeComparisonView,
  TreePropertyList,
  TreeView
} from './components/Page';
import {
  ChartControls,
  DateSelector,
  DateSelectorContainer,
  Legend,
  LegendContainer
} from './components/ChartControls';
import { AVAILABLE_DATES, NOW } from './fakes';
import PointCloudViewerWithRulers from '../../components/PointCloud/PointCloudViewerWithRulers';
import PanoramaView from '../../components/Panorama/PanoramaView';
import SphereControls from '../../components/Panorama/SphereControls';
import { MultiOrbitControl } from '../../components/PointCloud/MultiOrbitControl';
import { useCurrentAccount } from '../../account/useAccounts';
import { AnalyticsView } from './AnalyticsView';

const displayableTreeProperties = [
  DisplayableTreeProperty.CanopyHeight,
  DisplayableTreeProperty.CanopyWidth,
  DisplayableTreeProperty.TrunkDiameter,
  DisplayableTreeProperty.TrunkHeight,
  DisplayableTreeProperty.Height
];
const properties = ['all', ...displayableTreeProperties];

export default function DimensionAnalytics(props: DimensionAnalyticsProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const organization = account.organization;
  const [selectedProperty, setSelectedProperty] = useState(properties[1]);
  const [showHistory, setHistoryVisibility] = useState(false);
  const toggleHistory = () => setHistoryVisibility(state => !state);
  const [dateA, setDateA] = useState(NOW);
  const [dateB, setDateB] = useState(AVAILABLE_DATES.at(-1)!);
  const [seed, setSeed] = useState(Math.random().toString(16).slice(-7));
  const updateSeed = () => setSeed(Math.random().toString(16).slice(-7));

  const sphereControls = useMemo(() => new SphereControls(), []);

  const resetView = () => {
    updateSeed();
    sphereControls.reset();
  };

  const multiControlsRef = useRef(new MultiOrbitControl());

  useEffect(() => {
    if (props.compare) {
      setHistoryVisibility(false);
    }
  }, [props.compare]);

  const header = (
    <PageHeader title={t('analytics.navigation.dimensions')}>
      <TreePropertyList>
        {properties.map(it => {
          return <PropertyListItem
            key={it}
            onClick={() => setSelectedProperty(it)}
            active={selectedProperty === it}>
            {t('analytics.properties.' + it)}
          </PropertyListItem>;
        })}
      </TreePropertyList>
    </PageHeader>
  );

  if (props.view === AnalyticsView.Data) {
    if (selectedProperty !== 'all' && !showHistory) {
      const property = selectedProperty as DisplayableTreeProperty;
      const statistics = props.statistics.find(it => it.isForProperty(property)) ?? null;
      const current = props.tree[property] as any ?? 0;
      const historyMultiplierA =
        dateA.getTime() === NOW.getTime() ? 1 : 1 - Math.abs(NOW.getFullYear() - dateA.getFullYear()) / 10;
      const historyMultiplierB =
        dateB.getTime() === NOW.getTime() ? 1 : 1 - Math.abs(NOW.getFullYear() - dateB.getFullYear()) / 10;

      if (statistics === null) {
        return <StatisticsEmptyState />;
      }

      return (
        <PageContainer>
          {header}

          <PageContent>
            <PagePropertyChartContainer compare={props.compare}>
              <div>
                {!props.compare && (
                  <PageHistoryControls toggleHistory={toggleHistory} isInHistoricalView={showHistory}>
                    <strong>
                      {[
                        `${t('analytics.properties.' + property)}: `,
                        current.toFixed(2),
                        Tree.getUnit(property, organization)
                      ].join(' ')}
                    </strong>
                  </PageHistoryControls>
                )}

                <div>
                  <TreePropertyStateChart
                    statistics={statistics.fake(historyMultiplierA)}
                    current={current * historyMultiplierA}
                  />
                </div>
              </div>

              {props.compare && (
                <div>
                  <div>
                    <TreePropertyStateChart
                      compared
                      statistics={statistics.fake(historyMultiplierB)}
                      current={current * historyMultiplierB}
                    />
                  </div>
                </div>
              )}

              <ChartControls>
                {props.compare && (
                  <DateSelectorContainer>
                    <DateSelector
                      variant="current"
                      selected={dateA}
                      options={AVAILABLE_DATES}
                      onSelect={setDateA} />

                    <DateSelector
                      variant="reference"
                      selected={dateB}
                      options={AVAILABLE_DATES}
                      onSelect={setDateB} />
                  </DateSelectorContainer>
                )}

                <LegendContainer>
                  <Legend variant={props.compare ? 'positive-compared' : 'positive'}>{t('analytics.value')}</Legend>

                  <Legend variant="negative">{t('analytics.median')}</Legend>
                </LegendContainer>
              </ChartControls>
            </PagePropertyChartContainer>
          </PageContent>
        </PageContainer>
      );
    }

    if (selectedProperty !== 'all' && showHistory) {
      const property = selectedProperty as DisplayableTreeProperty;
      const statistics = props.statistics.find(it => it.isForProperty(property)) ?? null;
      const value = props.tree[property] as any ?? 0;

      if (statistics === null) {
        return <StatisticsEmptyState />;
      }

      return (
        <PageContainer>
          {header}

          <PageContent>
            <PagePropertyChartContainer compare={props.compare}>
              <div>
                <PageHistoryControls toggleHistory={toggleHistory} isInHistoricalView={showHistory}>
                  <strong>
                    {[`${t('analytics.properties.' + property)}: `, value.toFixed(2), Tree.getUnit(property, organization)].join(' ')}
                  </strong>
                </PageHistoryControls>

                <div>
                  <TreePropertyHistoryChart
                    history={[
                      { date: NOW, value, statistics },
                      { date: AVAILABLE_DATES[1], value: value * 0.98, statistics: statistics.fake(0.98) },
                      { date: AVAILABLE_DATES[2], value: value * 0.96, statistics: statistics.fake(0.96) },
                      { date: AVAILABLE_DATES[3], value: value * 0.95, statistics: statistics.fake(0.95) },
                      { date: AVAILABLE_DATES[4], value: value * 0.91, statistics: statistics.fake(0.91) }
                    ]}
                  />
                </div>
              </div>

              <ChartControls>
                <LegendContainer>
                  <Legend variant={props.compare ? 'positive-compared' : 'positive'}>{t('analytics.value')}</Legend>

                  <Legend variant="negative">{t('analytics.median')}</Legend>
                </LegendContainer>
              </ChartControls>
            </PagePropertyChartContainer>
          </PageContent>
        </PageContainer>
      );
    }

    if (selectedProperty === 'all') {
      return (
        <PageContainer>
          {header}

          <PageContent>
            <PageReferenceChartContainer>
              <ReferenceChart large legendLabel="Median">
                {displayableTreeProperties.map(property => {
                  const statistics = props.statistics.find(it => it.isForProperty(property)) ?? null;
                  const propertyValue = props.tree[property] as any ?? null;
                  if (statistics === null || propertyValue === null) {
                    return null;
                  }
                  const historyMultiplier =
                    dateB.getTime() === NOW.getTime() ? 1 : 1 - Math.abs(NOW.getFullYear() - dateB.getFullYear()) / 10;
                  const historicalValue = propertyValue * historyMultiplier;
                  const historicalChartValue = props.compare
                    ? statistics.getDeltaPercentageComparedToMedian(props.tree) * historyMultiplier
                    : null;

                  return (
                    <ReferenceData
                      key={property}
                      historicalValue={historicalChartValue}
                      value={statistics.getDeltaPercentageComparedToMedian(props.tree)}
                    >
                      <div>
                        <strong>{t('analytics.properties.' + property)}</strong>
                      </div>

                      <div>
                        {propertyValue.toFixed(1)}
                        {Tree.getUnit(property, organization)}
                      </div>

                      {props.compare && (
                        <div>
                          {historicalValue.toFixed(1)}
                          {Tree.getUnit(property, organization)}
                        </div>
                      )}
                    </ReferenceData>
                  );
                })}
              </ReferenceChart>

              <ChartControls>
                {props.compare && (
                  <DateSelectorContainer>
                    <DateSelector
                      variant="current"
                      selected={dateA}
                      options={AVAILABLE_DATES}
                      onSelect={setDateA} />

                    <DateSelector
                      variant="reference"
                      selected={dateB}
                      options={AVAILABLE_DATES}
                      onSelect={setDateB} />
                  </DateSelectorContainer>
                )}

                <LegendContainer>
                  <Legend variant="negative">{t('analytics.valueBelowMedian')}</Legend>
                </LegendContainer>
              </ChartControls>
            </PageReferenceChartContainer>
          </PageContent>
        </PageContainer>
      );
    }
  }

  if (props.view === AnalyticsView.Twin) {
    const rulers = selectedProperty === 'all' ? displayableTreeProperties : [selectedProperty];
    return (
      <PageContainer>
        {header}

        <PageContent tree>
          {!props.compare && (
            <TreeView pointCloud>
              <PointCloudViewerWithRulers
                seed={seed}
                tree={props.tree}
                rulers={rulers}
                showEnvironment={props.detailsHelper.showDetails}
                multiControlsRef={multiControlsRef}
              />
            </TreeView>
          )}

          {props.compare && (
            <TreeComparisonView>
              <TreeView pointCloud>
                <PointCloudViewerWithRulers
                  seed={seed}
                  tree={props.tree}
                  rulers={rulers}
                  showEnvironment={props.detailsHelper.showDetails}
                  multiControlsRef={multiControlsRef}
                />
              </TreeView>

              <TreeView pointCloud>
                <PointCloudViewerWithRulers
                  seed={seed}
                  tree={props.tree}
                  rulers={rulers}
                  showEnvironment={props.detailsHelper.showDetails}
                  multiControlsRef={multiControlsRef}
                />
              </TreeView>
            </TreeComparisonView>
          )}

          {props.compare && (
            <DateSelectorContainer floating>
              <DateSelector
                variant="current"
                options={AVAILABLE_DATES}
                onSelect={setDateA}
                selected={dateA} />

              <DateSelector
                variant="reference"
                options={AVAILABLE_DATES}
                onSelect={setDateB}
                selected={dateB} />
            </DateSelectorContainer>
          )}

          <DetailsButton showDetails={props.detailsHelper.showDetails} toggleDetails={props.detailsHelper.toggleDetails} />
          <ResetViewButton onClick={resetView} />
        </PageContent>
      </PageContainer>
    );
  }

  if (props.view === AnalyticsView.Panorama) {
    const rulers = selectedProperty === 'all' ? displayableTreeProperties : [selectedProperty];
    return (
      <PageContainer>
        {header}

        <PageContent tree>
          {!props.compare && (
            <TreeView>
              <PanoramaView
                tree={props.tree}
                organizationId={props.organizationId}
                controls={sphereControls}
                showPointCloud={props.detailsHelper.showDetails}
                showEnvironmentPointCloud={props.detailsHelper.showExtraDetails}
                rulers={rulers}
                showSafetyFactor={false}
                windSpeed={account.getDefaultWindSpeed()}
                showLeaningAngle={false}
              />
            </TreeView>
          )}

          {props.compare && (
            <TreeComparisonView>
              <TreeView>
                <PanoramaView
                  tree={props.tree}
                  organizationId={props.organizationId}
                  controls={sphereControls}
                  showPointCloud={props.detailsHelper.showDetails}
                  showEnvironmentPointCloud={props.detailsHelper.showExtraDetails}
                  rulers={rulers}
                  showSafetyFactor={false}
                  windSpeed={account.getDefaultWindSpeed()}
                  showLeaningAngle={false}
                />
              </TreeView>

              <TreeView>
                <PanoramaView
                  tree={props.tree}
                  organizationId={props.organizationId}
                  controls={sphereControls}
                  showPointCloud={props.detailsHelper.showDetails}
                  showEnvironmentPointCloud={props.detailsHelper.showExtraDetails}
                  rulers={rulers}
                  showSafetyFactor={false}
                  windSpeed={account.getDefaultWindSpeed()}
                  showLeaningAngle={false}
                />
              </TreeView>
            </TreeComparisonView>
          )}

          {props.compare && (
            <DateSelectorContainer floating>
              <DateSelector
                variant="current"
                options={AVAILABLE_DATES}
                onSelect={setDateA}
                selected={dateA} />

              <DateSelector
                variant="reference"
                options={AVAILABLE_DATES}
                onSelect={setDateB}
                selected={dateB} />
            </DateSelectorContainer>
          )}

          <DetailsButton showDetails={props.detailsHelper.showDetails} toggleDetails={props.detailsHelper.toggleDetails} />
          <ExtraDetailsButton isOn={props.detailsHelper.showExtraDetails} toggle={props.detailsHelper.toggleExtraDetails} />
          <ResetViewButton onClick={resetView} />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {header}

      <PageContent>
        <p>{props.view}</p>

        <DetailsButton showDetails={props.detailsHelper.showDetails} toggleDetails={props.detailsHelper.toggleDetails} />
        <ResetViewButton onClick={resetView} />
      </PageContent>
    </PageContainer>
  );
}

interface DimensionAnalyticsProps {
  tree: Tree,
  view: AnalyticsView,
  statistics: TreeStatistics[],
  compare: boolean,
  organizationId: string,
  detailsHelper: DetailsButtonHelpers
}
