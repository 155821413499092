import styles from './Switch.module.scss';
import { ReactNode, ChangeEvent } from 'react';

export default function Switch(props: SwitchProps) {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.checked);

  return (
    <div className={[props.value ? styles.active : '', props.disabled ? styles.disabled : ''].join(' ')}>
      <label className={styles.label}>
        <span className={styles.text}>{props.children}</span>

        <span className={styles.track}>
          <span className={styles.handle} />
        </span>

        <input
          type="checkbox"
          className={styles.checkbox}
          checked={props.value}
          onChange={onChange} />
      </label>
    </div>
  );
}

interface SwitchProps {
  children?: ReactNode,
  onChange: (isOn: boolean) => unknown,
  value: boolean,
  disabled?: boolean
}
