import { useMemo, useRef, useState } from 'react';
import AbsoluteModal from '../../../components/Modal/absolute-modal/AbsoluteModal';
import ModalDialog from '../../../components/Modal/absolute-modal/ModalDialog';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { Map, NavArrowDown } from 'iconoir-react';
import ManagedAreaList from './ManagedAreaList';
import { useTranslation } from 'react-i18next';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import { useCurrentAccount } from '../../../account/useAccounts';

export default function ManagedAreaSelector(props: ManagedAreaSelectorProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const [maSelectorOpen, setMaSelectorOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { managedAreaList, isLoadingManagedAreaList } = useManagedAreas(organization.id);

  const calculateModalPosition = () => {
    if (!containerRef.current) return { top: 0, left: 0 };
    const rect = containerRef.current?.getBoundingClientRect();
    return { top: rect.bottom - 10, left: rect.left - 20 };
  };

  const buttonLabel = useMemo(() => {
    if (isLoadingManagedAreaList) return t('taskManager.selectArea');
    if (props.reverseMASelection) {
      return props.selectedManagedAreaIds?.length < managedAreaList.length ? `${managedAreaList.length - props.selectedManagedAreaIds.length} areas selected` : 'All areas selected';
    }
    else {
      return props.selectedManagedAreaIds?.length > 0 ? `${props.selectedManagedAreaIds?.length} areas selected` : t('taskManager.selectArea');
    }
  }, [props.selectedManagedAreaIds, props.reverseMASelection, managedAreaList, isLoadingManagedAreaList]);

  return (
    <div ref={containerRef}>
      <FunctionButton
        variant={'rounded'}
        onClick={() => setMaSelectorOpen(prev => !prev)}
        icon={<Map />}
      >
        <span className="flex items-center">{buttonLabel}<NavArrowDown /></span>
      </FunctionButton>
      <AbsoluteModal
        isVisible={maSelectorOpen}
        onHide={() => setMaSelectorOpen(false)}
        hideOverlay
      >
        <ModalDialog
          classNames={''}
          style={{
            top: calculateModalPosition().top + 'px',
            left: calculateModalPosition().left + 'px',
            background: 'transparent'
          }}>
          <ManagedAreaList
            selectedManagedAreaIds={props.selectedManagedAreaIds}
            setSelectedManagedAreaIds={props.setSelectedManagedAreaIds}
            toggleSelection={props.toggleSelection}
            reverseMASelection={props.reverseMASelection}
            toggleReverseMASelection={props.toggleReverseMASelection}
            isLoadingManagedAreaList={isLoadingManagedAreaList}
            managedAreaList={managedAreaList}
          />
        </ModalDialog>
      </AbsoluteModal>
    </div>
  );
}

interface ManagedAreaSelectorProps {
  selectedManagedAreaIds: string[],
  setSelectedManagedAreaIds: (ids: string[]) => void,
  toggleSelection: (id: string) => void,
  reverseMASelection: boolean,
  toggleReverseMASelection: () => void
}
