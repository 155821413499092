import * as THREE from 'three';
import PointMarker from './images/point-marker.svg';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import styles from './PointCloudViewer.module.scss';
import { Line2, LineGeometry, LineMaterial } from 'three-fatline';

// -90º
const rotationMatrix = new THREE.Matrix3().set(1, 0, 0, 0, 0, 1, 0, -1, 0);

export class LeaningAngleGroup extends THREE.Group {
  labelElement: HTMLDivElement;
  constructor(
    private readonly length: number,
    private readonly startingPoint: THREE.Vector3,
    private readonly leaningVector: THREE.Vector3,
    private readonly nameLabel: string,
    private readonly triggerRepaint: () => void,
    private readonly depthTest: boolean
  ) {
    super();

    const scaledLeaningVector = leaningVector
      .normalize().multiplyScalar(length)
      .applyMatrix3(rotationMatrix);

    const dot = new THREE.Sprite(
      new THREE.SpriteMaterial({
        map: new THREE.TextureLoader().load(PointMarker, triggerRepaint),
        fog: false,
        depthTest: this.depthTest
      })
    );
    dot.position.set(0, 0, 0);
    dot.scale.set(0.3, 0.3, 1);

    const lineStartDot = dot.clone();
    const lineEndDot = dot.clone();

    const leaningAngle = THREE.MathUtils.radToDeg(new THREE.Vector3(0, 1, 0).angleTo(leaningVector));
    this.labelElement = document.createElement('div');
    this.labelElement.innerText = `${nameLabel} ${leaningAngle.toFixed(2)}º`;
    this.labelElement.classList.add(styles.pointCloudLabel);
    const label = new CSS2DObject(this.labelElement);

    label.position.copy(scaledLeaningVector);
    label.translateY(0.4);

    lineStartDot.position.copy(startingPoint);
    lineEndDot.position.copy(scaledLeaningVector);

    const verticalLine = new Line2(
      new LineGeometry().setPositions([0, length, 0, 0, 0, 0]),
      new LineMaterial({
        color: 0x1cd2c7,
        linewidth: 4,
        resolution: new THREE.Vector2(640, 480),
        depthTest: this.depthTest
      })
    ).computeLineDistances();
    verticalLine.renderOrder = 1;

    const mesh = new Line2(
      new LineGeometry().setPositions([0, 0, 0, scaledLeaningVector.x, scaledLeaningVector.y, scaledLeaningVector.z]),
      new LineMaterial({
        color: 0xffffff,
        linewidth: 1,
        resolution: new THREE.Vector2(640, 480),
        dashed: true,
        dashScale: 8,
        depthTest: this.depthTest
      })
    ).computeLineDistances();
    mesh.renderOrder = 1;

    this.add(verticalLine, mesh, lineStartDot, lineEndDot, label);
  }

  setVisibility(visible: boolean) {
    this.traverse(object => {
      object.visible = visible;
    });
  }

  removeLabel() {
    this.labelElement.remove();
  }
}
