import { MemberDto } from './MemberDto';
import { AccountType } from '../account/AccountType';
import { MemberStatus } from './MemberStatus';
import { Interest } from '../account/Interest';
import { Account } from '../account/Account';

export enum MemberSortingDirection {
  DESCENDING = 'dsc',
  ASCENDING = 'asc'
}

export class Member {
  static fromDto(dto: MemberDto) {
    if (dto.status === MemberStatus.Pending) {
      return new InvitedMember(
        dto.id,
        dto.firstName,
        dto.lastName,
        dto.email,
        dto.accountType,
        new Date(dto.memberSince),
        dto.status,
        dto.interests
      );
    }

    return new Member(
      dto.id,
      dto.firstName,
      dto.lastName,
      dto.email,
      dto.accountType,
      dto.lastLogin ? new Date(dto.lastLogin) : null,
      new Date(dto.memberSince),
      dto.status,
      dto.interests
    );
  }

  protected constructor(
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly email: string,
    readonly accountType: AccountType,
    readonly lastLogin: Date | null,
    readonly memberSince: Date,
    readonly status: MemberStatus,
    readonly interests: Interest[]
  ) {}

  compareForSorting(other: Member, sortBy: ComparableMemberProperty, direction: MemberSortingDirection): SortingResult {
    if (sortBy === 'name') {
      return this.compareNameForSorting(direction, other);
    }

    if (sortBy === 'accountType') {
      return this.compareAccountTypeForSorting(other, direction);
    }

    if (sortBy === 'lastLogin') {
      return this.compareLastLoginForSorting(other, direction);
    }

    if (sortBy === 'memberSince') {
      return this.compareMemberSinceForSorting(other, direction);
    }

    if (sortBy === 'status') {
      return this.compareStatusForSorting(other, direction);
    }

    return 0;
  }

  private compareMemberSinceForSorting(other: Member, direction: MemberSortingDirection) {
    return this.compare(this.memberSince.getTime(), other.memberSince.getTime(), direction);
  }

  private compareLastLoginForSorting(other: Member, direction: MemberSortingDirection) {
    if (!this.lastLogin) {
      return 1;
    }

    if (!other.lastLogin) {
      return -1;
    }

    return this.compare(this.lastLogin.getTime(), other.lastLogin.getTime(), direction);
  }

  private compareNameForSorting(direction: MemberSortingDirection, other: Member) {
    if (direction === MemberSortingDirection.ASCENDING) {
      return -this.name.localeCompare(other.name) as SortingResult;
    }

    return this.name.localeCompare(other.name) as SortingResult;
  }

  private compareAccountTypeForSorting(other: Member, direction: MemberSortingDirection) {
    const typeHierarchy = [AccountType.Employee, AccountType.Admin, AccountType.Owner];
    return this.compare(typeHierarchy.indexOf(this.accountType), typeHierarchy.indexOf(other.accountType), direction);
  }

  private compareStatusForSorting(other: Member, direction: MemberSortingDirection) {
    const statusHierarchy = [MemberStatus.Pending, MemberStatus.Inactive, MemberStatus.Active];
    return this.compare(statusHierarchy.indexOf(this.status), statusHierarchy.indexOf(other.status), direction);
  }

  private compare(a: number, b: number, direction: MemberSortingDirection): SortingResult {
    if (direction === MemberSortingDirection.ASCENDING) {
      return Math.sign(a - b) as SortingResult;
    }

    return Math.sign(b - a) as SortingResult;
  }

  refresh(payload: Partial<MemberDto>) {
    return Member.fromDto({ ...this.toDto(), ...payload });
  }

  private toDto(): MemberDto {
    return {
      id: this.id,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      lastLogin: this.lastLogin?.toISOString() ?? null,
      memberSince: this.memberSince.toISOString(),
      status: this.status,
      accountType: this.accountType,
      interests: this.interests
    };
  }

  get name() {
    return [this.firstName, this.lastName].join(' ').trim();
  }

  isSameById(member: Member) {
    return this.id === member.id;
  }

  isModifiableByAdminsAndOwners() {
    return true;
  }

  isSameAs(account: Account) {
    return this.id === account.id;
  }

  hasAnyAccountType(accountTypes: AccountType[]) {
    return accountTypes.includes(this.accountType);
  }

  isInterestedInAny(interests: Interest[]) {
    return this.interests.some(it => interests.includes(it));
  }

  hasLoggedInSince(since: Date) {
    if (!this.lastLogin) {
      return false;
    }

    return this.lastLogin >= since;
  }

  hasLoggedInUntilEoD(until: Date) {
    if (!this.lastLogin) {
      return false;
    }

    const copy = new Date(until);
    copy.setHours(23, 59, 59, 999);

    return this.lastLogin <= copy;
  }

  hasAnyStatus(statuses: MemberStatus[]) {
    return statuses.includes(this.status);
  }

  matchesSearchQuery(query: string) {
    return this.name.concat(this.email).toLowerCase().includes(query);
  }

  isInactive() {
    return this.status === MemberStatus.Inactive;
  }
}

class InvitedMember extends Member {
  constructor(
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    accountType: AccountType,
    memberSince: Date,
    status: MemberStatus,
    interests: Interest[]
  ) {
    super(id, firstName, lastName, email, accountType, null, memberSince, status, interests);
  }

  override isModifiableByAdminsAndOwners() {
    return false;
  }
}

export type ComparableMemberProperty = 'name' | 'accountType' | 'lastLogin' | 'memberSince' | 'status';

type SortingResult = 0 | 1 | -1;
