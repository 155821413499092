import { DisplayableTreeProperty, Tree } from './Tree';

export class TreeStatistics {
  static fromDto(dto: TreeStatisticsDto) {
    return new TreeStatistics(dto.property, dto.min, dto.max, dto.median, dto.buckets);
  }

  static base() {
    return new TreeStatistics(
      DisplayableTreeProperty.Height,
      0,
      2,
      1,
      new Array(20).fill(null).map((v, i) => ({
        min: i / 10 - 0.05,
        middle: i / 10,
        max: i / 10 + 0.05,
        count: Math.abs(Math.cos(Math.PI / 2 + i / 6))
      }))
    );
  }

  static empty() {
    return new TreeStatistics(DisplayableTreeProperty.Height, 0, 0, 0, []);
  }

  private constructor(
    private readonly property: DisplayableTreeProperty,
    readonly min: number,
    readonly max: number,
    readonly median: number,
    readonly buckets: { min: number, max: number, middle: number, count: number }[]
  ) {}

  isForProperty(property: DisplayableTreeProperty) {
    return this.property === property;
  }

  getDeltaPercentageComparedToMedian(tree: Tree) {
    const value = tree[this.property] as any ?? 0;

    if (value === this.median && (value === this.min || value === this.max)) {
      return 0;
    }

    if (value > this.median) {
      const min = this.median;
      const max = this.max - min;
      const adjustedValue = value - min;
      return (adjustedValue / max) * 100;
    }

    const min = this.min;
    const max = this.median - min;
    const adjustedValue = value - min;
    return -(adjustedValue / max) * 100;
  }

  getMinBucketCount() {
    return Math.max(...this.buckets.map(it => it.count));
  }

  getMaxBucketCount() {
    return Math.min(...this.buckets.map(it => it.count));
  }

  fake(multiplier: number) {
    return new TreeStatistics(
      this.property,
      this.min * multiplier,
      this.max * multiplier,
      this.median * multiplier,
      this.buckets.map(it => ({
        min: it.min * multiplier,
        max: it.max * multiplier,
        middle: it.middle * multiplier,
        count: it.count * multiplier
      }))
    );
  }
}

export interface TreeStatisticsDto {
  property: DisplayableTreeProperty,
  min: number,
  max: number,
  median: number,
  buckets: { min: number, max: number, middle: number, count: number }[]
}
