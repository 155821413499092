import styles from './TabSelector.module.scss';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FastArrowLeft, FastArrowRight, NavArrowDown, NavArrowRight } from 'iconoir-react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import useMountedEffect from '../../../../hooks/useMountedEffect';
import { FunctionButton } from '../../../../components/UI/Button/LegacyButton';

export enum Tab {
  Risk = 'structuralAssessment',
  HealthAndVitality = 'healthAndVitality',
  Clearances = 'clearances',
  Benefits = 'benefits'
}

export default function TabSelector() {
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const tabSelectorRef = useRef<HTMLUListElement>(null);
  const [showScrollArrow, setShowScrollArrow] = useState(false);

  const handleScroll = () => {
    if (tabSelectorRef.current === null) return;
    tabSelectorRef.current.scrollLeft = isLastMenuItemVisible ? 0 : tabSelectorRef.current.scrollWidth;
    setIsLastMenuItemVisible(prev => !prev);
  };
  const [isLastMenuItemVisible, setIsLastMenuItemVisible] = useState(false);

  const selectedTab = useMemo(() => {
    return urlContext.getSelectedDataPanelTab();
  }, [JSON.stringify(urlContext.getSelectedDataPanelTab())]);

  const calculateHorizontalScroll = (selectedTab: Tab) => {
    if (!tabSelectorRef.current) return 0;
    let offSetLeft = 0;
    const tabs = tabSelectorRef.current.getElementsByTagName('li');
    const selectedTabIndex = Object.values(Tab).indexOf(selectedTab);
    for (let i = 0; i < selectedTabIndex; i++) {
      const tab = tabs[i];
      offSetLeft = offSetLeft + (i + 1 === selectedTabIndex ? tab.clientWidth / 2 : tab.clientWidth);
    }
    return offSetLeft;
  };
  const handleTabClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, key) => {
    event.stopPropagation();
    urlContext.setSelectedDataPanelTab(key);
    if (tabSelectorRef.current) {
      tabSelectorRef.current.scrollLeft = calculateHorizontalScroll(key);
    }
    urlContext.setHistoryTrackingOpen(false);
  };

  useEffect(() => {
    const tab = document.getElementById(`${selectedTab}-tab`);
    const tabBackground = document.getElementById('activeTabBackground');
    if (tab && tabBackground) {
      setTimeout(() => {
        tabBackground.style.left = tab.offsetLeft + 'px';
        tabBackground.style.width = tab.offsetWidth + 'px';
      }, 100);
    }
    if (tabSelectorRef.current && !selectedTab) {
      tabSelectorRef.current.scrollLeft = 0;
    }
  }, [selectedTab]);

  useMountedEffect(() => {
    urlContext.setSelectedEnvAccordion(null);
  }, [selectedTab]);

  const toggleOpen = () => {
    if (selectedTab) urlContext.setSelectedDataPanelTab(null);
    else {
      urlContext.setSelectedDataPanelTab(Tab.Risk);
      urlContext.setHistoryTrackingOpen(false);
    }
  };

  useEffect(() => {
    const onResize = () => {
      if (!tabSelectorRef.current) return;
      setShowScrollArrow(tabSelectorRef.current.scrollWidth > tabSelectorRef.current.clientWidth);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [JSON.stringify(urlContext.getDetailsSecondary())]);

  return (
    <div className={`${styles.tabSelectorContainer} ${selectedTab ? styles.open : ''}`}>
      <div className={`${styles.chevronButtonContainer} ${selectedTab ? styles.open : ''} ${selectedTab !== Tab.Risk ? styles.darkBackground : ''}`}>
        <span className={`${styles.chevronButton} ${selectedTab ? styles.open : ''}`} onClick={toggleOpen}>
          {
            selectedTab ? <NavArrowDown fontSize={18} /> : <NavArrowRight fontSize={18} />
          }
        </span>
      </div>
      <nav className={`${styles.dataPanelTabSelector} ${selectedTab ? styles.open : ''}`}>
        <ul ref={tabSelectorRef}>
          {Object.values(Tab).map((key, index) =>
            <div
              className={styles.navItemWrapper}
              key={key}
            >
              <li
                id={`${key}-tab`}
                onClick={event => handleTabClick(event, key)}
                className={`${selectedTab === key ? styles.selectedTab : ''} ${index === Object.values(Tab).length - 1 ? styles.lastTab : ''}`}
              >
                <span>{t(`treeDetails.tabs.${key}`)}</span>
              </li>
            </div>
          )}
          <div id="activeTabBackground" className={styles.activeTabBackground} />
        </ul>
      </nav>
      {showScrollArrow && <div className={styles.scrollingArrow}>
        <FunctionButton icon={isLastMenuItemVisible ? <FastArrowLeft /> : <FastArrowRight />} onClick={handleScroll} />
      </div>}
    </div>
  );
}
