import styles from './LegacySearchHintsSection.module.scss';
import { ReactElement } from 'react';
import { Map } from 'iconoir-react';
import { useTranslation } from 'react-i18next';

export interface SearchHint {
  label: string,
  description?: string
}

export interface TreeByIdHint extends SearchHint {
  id: string
}

export interface TreeByCustomerIdHint extends SearchHint {
  id: string
}

export interface ManagedAreaHint extends SearchHint {
  id: string
}

export interface AddressHint extends SearchHint {
  coordinates: number[]
}

export default function LegacySearchHintsSection<T>(props: SearchHintsSectionProps<T>) {
  const { t } = useTranslation();

  return (
    <div className={styles.searchHints}>
      <div className={styles.titleRow}>
        <div className={styles.iconContainer}>{props.icon}</div>
        <div>{t(props.title)}</div>
      </div>
      <div className={styles.hintList}>
        {props.hints.map(hint => {
          const isHighlighted = props.highlightedHint && hint.label === props.highlightedHint.label;
          return (
            <div
              key={hint.label}
              onClick={() => props.onSelect(hint as T)}
              className={`${styles.hintItem} ${isHighlighted ? styles.highlighted : ''}`}
            >
              {hint.label}
              {hint.description &&
                <div className={styles.managedAreaCode}>
                  ⎯
                  <Map className={styles.inlineIcon} fontSize={10}/>
                  {hint.description}
                </div>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
}

interface SearchHintsSectionProps<T> {
  onSelect: (hint: T) => void,
  hints: TreeByIdHint[] | TreeByCustomerIdHint[] | ManagedAreaHint[] | AddressHint[],
  title: string,
  icon: ReactElement,
  highlightedHint: TreeByIdHint | TreeByCustomerIdHint | ManagedAreaHint | AddressHint | null
}
