import { DisplayableTreeProperty } from '../../../../tree/Tree';
import { PlusCircle, Trash } from 'iconoir-react';
import React from 'react';
import DetailedTree from '../../../../tree/DetailedTree';
import { ConsequencesOfFailure, LikelihoodOfFailure, LikelihoodOfImpact } from '../../CurrentState/RiskCategorization';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';

const targetZoneOptions = [
  { id: 'crown', translationKey: 'Crown' },
  { id: '1x', translationKey: '1X' },
  { id: '1.5x', translationKey: '1.5X' }
];
export default function PotentialTargets({ tree, editing, handleChange }: PotentialTargetsProps) {
  const { t } = useTranslation();

  return (<li key={DisplayableTreeProperty.PotentialTargets} className="mt-4 mb-1">
    <div className="my-2 w-1/3 uppercase text-outer-space-200 text-sm">
      {t('details.properties.potentialTargets')}
    </div>
    <div className="divide-y divide-outer-space-500 border-t border-b border-outer-space-500">
      {tree?.potentialTargets?.map((potentialTarget, i) => (
        <div key={`potentialTarget-${i}`} className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <div className="text-outer-space-200 mt-3">
              {t('taskManager.description')}
            </div>
            {editing && <div>
              <Trash
                className="text-danger-500 size-8 fill-none cursor-pointer p-2 mt-1 rounded hover:bg-greehill-00/10"
                onClick={() => handleChange('potentialTargets', tree?.potentialTargets?.filter(((_, index) => index !== i)))}
              />
            </div>}
          </div>
          <div>
            <span className="font-medium">
              {editing ? <input
                name={'target' + (i + 1)}
                className={'w-full bg-outer-space-950 rounded-[4px] p-2 text-outer-space-200 h-10'}
                type="text"
                value={potentialTarget.targetDescription}
                onChange={event => handleChange('potentialTargets', tree?.potentialTargets?.map((target, index) => index === i ? {
                  ...target,
                  targetDescription: event.target.value
                } : target))}
              />
                : <>{potentialTarget.targetDescription}</>}
            </span>
          </div>
          <div>
            <div className="text-outer-space-200 mt-3">
              {t('taskManager.targetZone')}
            </div>
            <Dropdown
              value={targetZoneOptions.find(it => it.id === potentialTarget.targetZone) || targetZoneOptions[0]}
              items={targetZoneOptions}
              className="my-2 w-full bg-outer-space-950 text-outer-space-200 rounded-[4px] h-10 flex items-center"
              fieldClassName="rounded-[4px] p-2 border-none [&>svg]:w-5 [&>svg]:h-5"
              menuClassname="bg-outer-space-700 p-1 border-none shadow-dropdown"
              onSelect={item => handleChange('potentialTargets', tree?.potentialTargets?.map((target, index) => index === i ? {
                ...target,
                targetZone: item.id
              } : target))}
              disabled={!editing}
            />
          </div>
        </div>
      ))}
    </div>
    {editing && <div
      className={`p-4 ${tree?.potentialTargets && tree.potentialTargets.length > 0 ? 'rounded-b-xl' : 'rounded-xl'}`}>
      <button
        className="flex gap-2 items-center m-auto rounded-full border border-outer-space-400 py-1 px-3 text-sm"
        onClick={() => handleChange('potentialTargets', [
          ...(tree?.potentialTargets || []),
          {
            targetDescription: '',
            likelihoodOfFailure: LikelihoodOfFailure[0],
            consequencesOfFailure: ConsequencesOfFailure[0],
            likelihoodOfImpact: LikelihoodOfImpact[0]
          }])}
      >
        <PlusCircle /> {t('taskManager.add')}
      </button>
    </div>}
  </li>);
}

interface PotentialTargetsProps {
  tree: DetailedTree | null,
  editing: boolean,
  handleChange: <T>(property: string, value: T) => void
}
