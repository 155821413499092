import styles from './LegacyPropertyLegend.module.scss';
import { FunctionButton } from '../UI/Button/LegacyButton';
import { PlusCircle, Xmark } from 'iconoir-react';
import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import PropertyColorConfiguration from '../../properties/PropertyColorConfiguration';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePropertyConfigurations } from '../../properties/usePropertyConfigurations';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { useCurrentAccount } from '../../account/useAccounts';
import {
  AdvancedFilterConfigurationBuilder
} from '../../routes/Explore/table-view/advanced-filter/AdvancedFilterConfigurationBuilder';
import { useTracking } from '../../analytics/useTracking';
import { KMH_TO_MPH } from '../PointCloud/unitConstants';
import Tooltip from '../UI/Tooltip/Tooltip';

export default function LegacyPropertyLegend(props: PropertyLegendProps) {
  const propertyConfigs = usePropertyConfigurations();
  const selectedConfig = propertyConfigs.isLoading ? undefined : propertyConfigs.data?.find(it => it.property === props.selectedProperty);
  const account = useCurrentAccount();
  const organization = account.organization;
  const { events, track } = useTracking();

  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const [windSpeed, setWindSpeed] = useState<number>(urlContext.getWindSpeed() || account.getDefaultWindSpeed());

  const changeWindSpeed = (speed: number) => {
    if (speed < 20 || speed > 170) return;
    setWindSpeed(speed);
    urlContext.setWindSpeed(speed);
    track(events.SAFETY_FACTORY_WIND_SPEED_CHANGED_IN_LEGEND, { windSpeed: speed });
  };

  if (!(selectedConfig)) return <></>;
  const colorMap = PropertyColorConfiguration.getColorsForConfig(selectedConfig!);

  const unit = Tree.getUnit(selectedConfig.property, organization);

  const legendName = () => {
    return t(`tree.${selectedConfig.property}`) + (unit ? ` [${unit}]` : '');
  };

  const getOpacity = (idx: number) => {
    if (props.selectedTreePropertyRangeIndex === -1) return 1;
    return idx !== props.selectedTreePropertyRangeIndex ? 0.5 : 1;
  };

  function createPropertyConfigAdvancedFilterFromRange(idx: number) {
    if (!selectedConfig) return;

    if (!props.hasTableData) {
      track(events.PROPERTY_LEGEND_RANGE_SELECTED_WITHOUT_TABLE, { property: selectedConfig.property });
      return;
    }
    track(events.PROPERTY_LEGEND_RANGE_SELECTED, { property: selectedConfig.property });
    track(events.ADVANCED_FILTER_FROM_PROPERTY_LEGEND, { property: selectedConfig.property });

    const builder = AdvancedFilterConfigurationBuilder.from(urlContext.getAdvancedFilterConfiguration());
    if (builder.listAllActiveProperties().includes(selectedConfig.property)) {
      builder.editPropertyConfig(selectedConfig.property, idx);
    } else {
      builder.addPropertyConfig(selectedConfig.property, selectedConfig.ranges, [idx]);
    }

    urlContext.setAdvancedFilterConfiguration(builder.build());
  }

  const onClose = () => {
    track(events.PROPERTY_LEGEND_CLOSED, { property: selectedConfig.property });
    urlContext.setSelectedTreeProperty(null);
    const builder = AdvancedFilterConfigurationBuilder.from(urlContext.getAdvancedFilterConfiguration());
    builder.removeProperty(selectedConfig.property);
    urlContext.setAdvancedFilterConfiguration(builder.build());
  };

  const speedFactor = organization.isMetric ? 1 : KMH_TO_MPH;

  return (<div className={styles.container}>
    <div className={styles.centerContainer}>
      <div className={styles.legendLabel}>{legendName()}</div>
      <div
        className={`${styles.rangesContainer} ${selectedConfig.reversedOrder && styles.reversedOrder}`}
        onMouseLeave={() => props.setSelectedTreePropertyRangeIndex(-1)}
      >
        {selectedConfig.ranges.map((range, idx) =>
          (<Tooltip
            key={idx}
            overlay={<strong>{legendName()}&nbsp;{selectedConfig.getFormattedRange(idx)}</strong>}
          >
            <div
              className={styles.rangeLabel}
              style={{ opacity: getOpacity(idx) }}
              onMouseOver={() => props.setSelectedTreePropertyRangeIndex(idx)}
              onClick={() => createPropertyConfigAdvancedFilterFromRange(idx)}
            >
              <div className={styles.circle} style={{ backgroundColor: `rgb(${colorMap[idx]})` }}></div>
              <div className={styles.capitalizeFirstLetter}>{range.name}</div>
            </div>
          </Tooltip>))}
      </div>
      <div className={`${styles.safetyFactor}`}>
        <div className={selectedConfig.property === DisplayableTreeProperty.SafetyFactors ? styles.visible : ''}>
          <span>{t('propertyLegend.windSpeed')}</span>
          <FunctionButton icon={<Xmark />} onClick={() => changeWindSpeed(windSpeed - 10)}></FunctionButton>
          <span>{t('propertyLegend.currentWindSpeed', { value: (windSpeed * speedFactor).toFixed(0), unit: Tree.getWindSpeedUnit(organization), interpolation: { escapeValue: false } })}</span>
          <FunctionButton icon={<PlusCircle />} onClick={() => changeWindSpeed(windSpeed + 10)}></FunctionButton>
        </div>
      </div>
    </div>
    <FunctionButton
      className={styles.backButton}
      onClick={onClose}
      icon={<Xmark />} />
  </div>);
}

interface PropertyLegendProps {
  hasTableData: boolean,
  selectedProperty: DisplayableTreeProperty,
  selectedTreePropertyRangeIndex: number,
  setSelectedTreePropertyRangeIndex: (index: number) => void
}
