import { AdvancedFilterType } from '../advanced-filter/AdvancedFilter';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import styles from './AdvancedFilterValueSelector.module.scss';
import { useState } from 'react';
import { useTracking } from '../../../../analytics/useTracking';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { Flippers } from '../../../../switches/Flippers';

export const AdvancedFilterPredicateTypeSelector = (props: AdvancedFilterPredicateTypeSelectorProps) => {
  const [predicateType, setPredicateType] = useState<AdvancedFilterType>(props.predicateType);
  const { events, track } = useTracking();
  const organization = useCurrentAccount().organization;

  const oldDropdownItems = [
    {
      id: AdvancedFilterType.MIN,
      translationKey: 'treeList.advancedFiltering.is_above'
    },
    {
      id: AdvancedFilterType.MAX,
      translationKey: 'treeList.advancedFiltering.is_below'
    },
    {
      id: AdvancedFilterType.RANGE,
      translationKey: 'treeList.advancedFiltering.in_between'
    },
    {
      id: AdvancedFilterType.PROPERTYCONFIGS,
      translationKey: 'treeList.advancedFiltering.propertyConfigs'
    }
  ];
  const newDropdownItems = [
    {
      id: AdvancedFilterType.RANGE,
      translationKey: 'treeList.advancedFiltering.numeric'
    },
    {
      id: AdvancedFilterType.MAX,
      translationKey: 'treeList.advancedFiltering.numeric'
    },
    {
      id: AdvancedFilterType.MIN,
      translationKey: 'treeList.advancedFiltering.numeric'
    },
    {
      id: AdvancedFilterType.PROPERTYCONFIGS,
      translationKey: 'treeList.advancedFiltering.propertyConfigs'
    },
    {
      id: AdvancedFilterType.COHORT,
      translationKey: 'treeList.advancedFiltering.compareToCohort'
    }
  ];

  const dropdownItems = organization.isEnabled(Flippers.dashboardRedesign) ? newDropdownItems : oldDropdownItems;
  const filteredDropDownItems = organization.isEnabled(Flippers.dashboardRedesign)
    ? dropdownItems.filter(it => it.id !== predicateType && it.id !== AdvancedFilterType.MAX && it.id !== AdvancedFilterType.RANGE)
    : dropdownItems.filter(it => it.id !== predicateType);

  const handlePredicateChange = (type: AdvancedFilterType) => {
    track(events.ADVANCED_FILTER_PREDICATE_TYPE_CHANGED, { type });
    setPredicateType(type);
    props.onChange(type);
  };

  return (
    <Dropdown
      value={dropdownItems.find(it => it.id === predicateType)}
      items={filteredDropDownItems}
      fieldClassName={styles.dropdown}
      menuClassname={styles.dropdownMenu}
      onSelect={item => handlePredicateChange(item.id as AdvancedFilterType)}
      testId='advanced-filter-dropdown' />
  );
};

interface AdvancedFilterPredicateTypeSelectorProps {
  onChange: (type: AdvancedFilterType) => unknown,
  predicateType: AdvancedFilterType
}
