import styles from '../Inventory/Inventory.module.scss';
import { useTranslation } from 'react-i18next';
import DetailedTree from '../../../../tree/DetailedTree';
import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import { Organization } from '../../../../organization/Organization';
import { TrackableTreeProperty } from '../../../../tree/TrackableTreeProperty';
import EditingFieldWrapper from '../components/EditingFieldWrapper/EditingFieldWrapper';

export default function BenefitsTab(props: BenefitsTabProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <div className={styles.dataGroup}>
        <h4 className={styles.benefitsSubtitle}>{t('treeDetails.tabs.subTitles.waterManagement')}</h4>
        <div
          className={`
            ${styles.dataGroupGrid}
            ${props.singleColumn ? styles.singleColumn : ''}
            ${styles.benefitsData} 
            ${props.organization.isMetric ? styles.isMetric : ''}
          `}
        >
          <EditingFieldWrapper
            title={t('details.properties.avoidedRunoff')}
            value={props.tree.avoidedRunoff}
            unit={Tree.getUnit(DisplayableTreeProperty.AvoidedRunoff, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.AvoidedRunoff}
          />
          <EditingFieldWrapper
            title={t('details.properties.evaporation')}
            value={props.tree.evaporation}
            unit={Tree.getUnit(DisplayableTreeProperty.Evaporation, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.Evaporation}
          />
          <EditingFieldWrapper
            title={t('details.properties.potentialEvapotranspiration')}
            value={props.tree.potentialEvapotranspiration}
            unit={Tree.getUnit(DisplayableTreeProperty.PotentialEvapotranspiration, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.PotentialEvapotranspiration}
          />
          <EditingFieldWrapper
            title={t('details.properties.transpiration')}
            value={props.tree.transpiration}
            unit={Tree.getUnit(DisplayableTreeProperty.Transpiration, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.Transpiration}
          />
          <EditingFieldWrapper
            title={t('details.properties.waterIntercepted')}
            value={props.tree.waterIntercepted}
            unit={Tree.getUnit(DisplayableTreeProperty.WaterIntercepted, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.WaterIntercepted}
          />
        </div>
      </div>
      <div className={styles.dataGroup}>
        <h4 className={styles.benefitsSubtitle}>{t('treeDetails.tabs.subTitles.ecosystemServices')}</h4>
        <div
          className={`
            ${styles.dataGroupGrid}
            ${props.singleColumn ? styles.singleColumn : ''}
            ${styles.benefitsData} 
            ${props.organization.isMetric ? styles.isMetric : ''}
          `}
        >
          <EditingFieldWrapper
            title={t('details.properties.carbonStorage')}
            value={props.tree.carbonStorage}
            unit={Tree.getUnit(DisplayableTreeProperty.CarbonStorage, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.CarbonStorage}
          />
          <EditingFieldWrapper
            title={t('details.properties.co')}
            value={props.tree.co}
            unit={Tree.getUnit(DisplayableTreeProperty.CO, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.CO}
          />
          <EditingFieldWrapper
            title={t('details.properties.grossCarbonSequestration')}
            value={props.tree.grossCarbonSequestration}
            unit={Tree.getUnit(DisplayableTreeProperty.GrossCarbonSequestration, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.GrossCarbonSequestration}
          />
          <EditingFieldWrapper
            title={t('details.properties.no2')}
            value={props.tree.no2}
            unit={Tree.getUnit(DisplayableTreeProperty.NO2, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.NO2}
          />
          <EditingFieldWrapper
            title={t('details.properties.oxygenProduction')}
            value={props.tree.oxygenProduction}
            unit={Tree.getUnit(DisplayableTreeProperty.OxygenProduction, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.OxygenProduction}
          />
          <EditingFieldWrapper
            title={t('details.properties.o3')}
            value={props.tree.o3}
            unit={Tree.getUnit(DisplayableTreeProperty.O3, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.O3}
          />
          <EditingFieldWrapper
            title={t('details.properties.pm25')}
            value={props.tree.pm25}
            unit={Tree.getUnit(DisplayableTreeProperty.PM25, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.PM25}
          />
          <EditingFieldWrapper
            title={t('details.properties.so2')}
            value={props.tree.so2}
            unit={Tree.getUnit(DisplayableTreeProperty.SO2, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.SO2}
          />
        </div>
      </div>
      <div className={styles.dataGroup}>
        <h4 className={styles.benefitsSubtitle}>{t('treeDetails.tabs.subTitles.economicalValue')}</h4>
        <div
          className={`
            ${styles.dataGroupGrid}
            ${props.singleColumn ? styles.singleColumn : ''}
            ${styles.benefitsData} 
            ${props.organization.isMetric ? styles.isMetric : ''}
          `}
        >
          <EditingFieldWrapper
            title={t('tree.treeValueCavat')}
            value={props.tree.treeValueCavat}
            unit={Tree.getUnit(DisplayableTreeProperty.TreeValueCavat, props.organization)}
          />
          <EditingFieldWrapper
            title={t('tree.treeValueKoch')}
            value={props.tree.treeValueKoch}
            unit={Tree.getUnit(DisplayableTreeProperty.TreeValueKoch, props.organization)}
          />
          <EditingFieldWrapper
            title={t('tree.treeValueRado')}
            value={props.tree.treeValueRado}
            unit={Tree.getUnit(DisplayableTreeProperty.TreeValueRado, props.organization)}
          />
        </div>
      </div>
    </div>
  );
}

interface BenefitsTabProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organization: Organization
}
