import { type ReactNode, useState } from 'react';
import { NavArrowLeft, Xmark } from 'iconoir-react';

export default function FloatingBar(props: FloatingBarProps) {
  const [isVisible, setIsVisible] = useState(!props.hideByDefault);

  const floatingBarClasses = `fixed bottom-20 left-1/2 transform ${isVisible ? '-translate-x-1/2 opacity-100' : 'left-full opacity-0'} transition-all duration-500 ease-in-out bg-outer-space-600 shadow-lg p-3 rounded-lg flex items-center justify-between gap-4 text-greehill-00 z-10`;

  return (
    <>
      {
        isVisible && <div className={floatingBarClasses}>
          {props.children}
          <button
            className="twp p-1 text-greehill-00 rounded-full bg-greehill-00/0 hover:bg-greehill-00/10 bg-tra border-0 cursor-pointer"
          >
            <Xmark className="w-6 h-6" onClick={() => setIsVisible(false)} />
          </button>
        </div>
      }

      {!isVisible && (
        <div className="fixed bottom-20 right-0 transform z-10">
          <button
            onClick={() => setIsVisible(true)}
            className="py-2 bg-outer-space-600 text-greehill-00 border-0 cursor-pointer shadow-lg rounded-l-lg"
          >
            <NavArrowLeft className="w-6 h-6" />
          </button>
        </div>
      )}
    </>
  );
}

interface FloatingBarProps {
  children: ReactNode,
  hideByDefault?: boolean
}
