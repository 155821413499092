import styles from './TreeMarkerVariations.module.scss';

export default class TreeMarkerVariations {
  constructor(private readonly div: HTMLDivElement) {
    this.setContent();
  }

  setDefaultContent() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    marker.classList.remove(styles.markerSelected, styles.markerColorWhite, styles.markerShadow, styles.markerDisabled, styles.markerHidden);
    marker.classList.add(styles.markerDefault, styles.markerColorWhite);
  }

  setContentSelected() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    marker.classList.remove(styles.markerDefault, styles.markerHidden, styles.markerDisabled);
    marker.classList.add(styles.markerSelected, styles.markerColorWhite, styles.markerShadow);
    marker.dataset.testid = 'selectedMarker';
  }

  hideMarker() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    const label = this.getLabelElement();
    if (!label) return;
    marker.classList.remove(styles.markerSelected, styles.markerDefault, styles.markerColorWhite, styles.markerShadow, styles.markerDisabled);
    marker.classList.add(styles.markerHidden);
    label.innerHTML = '';
  }

  setContentDisabled() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    this.removeColor();
    marker.classList.remove(styles.markerSelected, styles.markerDefault, styles.coloredMarker, styles.markerColorWhite, styles.markerShadow, styles.markerHidden);
    marker.classList.add(styles.markerDisabled);
  }

  private getMarkerElement(): HTMLDivElement | null {
    return this.div.querySelector('.marker');
  }

  private getLabelElement(): HTMLDivElement | null {
    return this.div.querySelector(`.${styles.label}`);
  }

  private setContent() {
    this.div.innerHTML = `<div style="position: relative;">
          <div class="marker ${styles.markerDefault} ${styles.markerColorWhite}"></div>
          <div class="${styles.label}"></div>
        </div>`;
  }

  hide() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    const label = this.getLabelElement();
    if (!label) return;
    marker.style.display = 'none';
    label.style.display = 'none';
  }

  show() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    const label = this.getLabelElement();
    if (!label) return;
    marker.style.display = 'block';
    label.style.display = 'block';
  }

  removeLabelContent() {
    const label = this.getLabelElement();
    if (!label) return;
    label.innerHTML = '';
  }

  setColor(color: string) {
    const marker = this.getMarkerElement();
    if (!marker) return;
    marker.style.setProperty('--current-background', color);
    marker.style.backgroundColor = `rgb(${color})`;
    marker.classList.add(`${styles.coloredMarker}`);

    const label = this.getLabelElement();
    if (!label) return;
    label.style.color = `rgb(${color})`;
  }

  removeColor() {
    const marker = this.getMarkerElement();
    if (!marker) return;
    marker.style.removeProperty('--current-background');
    marker.style.removeProperty('background-color');
    marker.classList.remove(`${styles.coloredMarker}`);

    const label = this.getLabelElement();
    if (!label) return;
    label.style.display = 'none';
    label.innerHTML = '';
  }

  setLabel(value: number) {
    const label = this.getLabelElement();
    if (!label) return;
    label.innerHTML = value?.toFixed(1) ?? '';
  }
}
