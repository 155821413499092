import styles from './ViewSelector.module.scss';
import { useContext, useRef, useState } from 'react';
import { t } from 'i18next';
import { Camera, City } from 'iconoir-react';
import { ReactComponent as DigitalTwinToggle } from '../../../Explore/images/gh-icon-twin-tree.svg';
import AbsoluteModal from '../../../../components/Modal/absolute-modal/AbsoluteModal';
import ModalDialog from '../../../../components/Modal/absolute-modal/ModalDialog';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { TreeDisplays } from '../TreeDisplays';

const icons = {
  images: <Camera />,
  streetView: <City />,
  twinView: <DigitalTwinToggle />
};

export default function ViewSelector() {
  const { urlContext } = useContext(DependencyInjectionContext);
  const [open, setOpen] = useState(false);
  const viewSelectorRef = useRef<HTMLDivElement | null>(null);
  const view = urlContext.getDetailsTreeDisplay();

  const calculateModalPosition = () => {
    if (!viewSelectorRef.current) return { top: 0, left: 0 };
    const rect = viewSelectorRef.current?.getBoundingClientRect();
    return { top: rect.top - 125, left: rect.left };
  };

  return (
    <>
      <div ref={viewSelectorRef}>
        <button onClick={() => setOpen(true)} className={styles.viewButton}>
          <span className={styles.iconContainer}>{icons[view]}</span>
          <span>{t(`details.views.${view}`)}</span>
        </button>
      </div>
      <AbsoluteModal
        isVisible={open}
        onHide={() => setOpen(false)}
        hideOverlay={true}
      >
        <ModalDialog
          classNames={styles.optionsModal}
          style={{
            top: calculateModalPosition().top + 'px',
            left: calculateModalPosition().left + 'px'
          }}>
          <ul className={styles.optionsContainer}>
            <div className={styles.viewsTitle}>Select view</div>
            {Object.values(TreeDisplays).map(it =>
              <li
                key={it}
                className={it === view ? styles.active : ''}
                onClick={() => urlContext.setDetailsTreeDisplay(it)}
              >
                {icons[it]}{t(`details.views.${it}`)}
              </li>
            )}
          </ul>
        </ModalDialog>
      </AbsoluteModal>
    </>
  );
}
