import styles from './MapStyleSelector.module.scss';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { Eye, Language, Map } from 'iconoir-react';
import { ReactComponent as Scope } from './images/street-view.svg';
import AbsoluteModal from '../../../components/Modal/absolute-modal/AbsoluteModal';
import DisplayModes from '../../../components/Navbar/DisplayModes';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Flippers } from '../../../switches/Flippers';
import { useTracking } from '../../../analytics/useTracking';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';

export enum MapStyle {
  Default = 'default',
  Satellite = 'satellite',
  Panoramic = 'panoramic'
}

export default function MapStyleSelector(props: MapStyleSelectorProps) {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const { events, track } = useTracking();
  const [isDisplayModesOpen, setDisplayModesOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const onTooltipVisibleChange = (disabled, visible) => {
    if (!disabled) {
      setTooltipVisible(false);
      return;
    }
    setTooltipVisible(visible);
  };

  const isPanoDisabled = () => {
    return urlContext.getTreeId() === null || urlContext.getTreeId() === '';
  };

  const views = [
    { title: MapStyle.Default, icon: <Map />, isDisabled: false },
    { title: MapStyle.Satellite, icon: <Language />, isDisabled: false },
    { title: MapStyle.Panoramic, icon: <Scope />, isDisabled: isPanoDisabled() }];

  const currentView = useMemo(() => {
    const style = urlContext.getStyle() || MapStyle.Default;
    return views.find(view => view.title === style)!;
  }, [urlContext.getStyle()]);

  const handleViewSelect = view => {
    props.toggleOpen();
    track(events.MAP_STYLE_CHANGE, { style: view.title });
    if (view.title === MapStyle.Default || view.title === MapStyle.Satellite) {
      urlContext.setCapturePointId('');
    }
    urlContext.setStyle(view.title);
  };

  const toggleDisplayModes = () => {
    setDisplayModesOpen(prev => !prev);
    props.toggleOpen();
  };

  const viewSelectButton = view => {
    return (
      <button
        onClick={() => handleViewSelect(view)}
        className={`${styles.viewOption} ${view.isDisabled && styles.disabled}`}
        disabled={view.isDisabled === null || view.isDisabled}
        key={view.title}>
        {view.icon}{t(`viewSelector.${view.title}`)}
      </button>
    );
  };

  return (
    <div className={styles.mapStyleSelectorContainer}>
      <button
        onClick={() => props.toggleOpen()}
        className={styles.currentView}>{currentView.icon}{t(`viewSelector.${currentView.title}`)}</button>
      {props.isOpen &&
        <div className={styles.dropupContainer}>
          <div className={styles.greenTitle}>{t('viewSelector.title')}</div>
          {views.map(view => {
            if (view.title === MapStyle.Panoramic) {
              return (
                <Tooltip
                  overlay={<span>{t('viewSelector.panoramicViewDisabledTooltip')}</span>}
                  visible={tooltipVisible}
                  key={view.title}
                  onVisibleChange={visible => onTooltipVisibleChange(view.isDisabled, visible)}>
                  <div className={styles.tooltipTriggerContainer}>
                    {viewSelectButton(view)}
                  </div>
                </Tooltip>
              );
            }
            return viewSelectButton(view);
          }
          )}
          {!account.isEnabled(Flippers.dashboardRedesign) && <div
            className={styles.viewOption}
            onClick={toggleDisplayModes}>
            <Eye />{t('viewSelector.displayMode')}
          </div>}
        </div>
      }
      <AbsoluteModal isVisible={isDisplayModesOpen} onHide={() => setDisplayModesOpen(false)}>
        <DisplayModes close={() => setDisplayModesOpen(false)} />
      </AbsoluteModal>
    </div>
  );
}

interface MapStyleSelectorProps {
  isOpen: boolean,
  toggleOpen: () => void
}
