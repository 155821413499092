import { MouseEventHandler, ReactNode, useMemo } from 'react';

import styles from './RawText.module.scss';

const RawText = ({
  children,
  className,
  title = false,
  uppercase = false,
  capitalize = false,
  small,
  medium,
  large,
  weight,
  color = 'primary',
  onClick,
  clickable = !!onClick,
  inline,
  disabled
}: RawTextProps) => {
  const classNames = useMemo(() => {
    return [
      styles.text,
      className,
      uppercase && styles.uppercase,
      clickable && styles.clickable,
      capitalize && styles.capitalize,
      small && styles.small,
      medium && styles.medium,
      large && styles.large,
      inline && styles.inline,
      title && styles.title,
      color && styles[color],
      weight && styles[`weight-${weight}`],
      disabled && styles.disabled
    ]
      .filter(Boolean)
      .join(' ');
  }, [capitalize, className, clickable, color, disabled, inline, large, small, title, uppercase, weight]);

  const handleClick = ev => {
    onClick?.(ev);
  };

  return (
    <div className={classNames} onClick={handleClick}>
      {children}
    </div>
  );
};

interface RawTextProps {
  children?: ReactNode,
  className?: string,
  title?: boolean,
  uppercase?: boolean,
  capitalize?: boolean,
  small?: boolean,
  medium?: boolean,
  large?: boolean,
  weight?: 400 | 500 | 600,
  color?: 'primary' | 'secondary' | 'ternary' | 'alternate' | 'highlight' | 'error',
  onClick?: MouseEventHandler<HTMLDivElement>,
  clickable?: boolean,
  inline?: boolean,
  disabled?: boolean
}

export default RawText;
