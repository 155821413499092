export enum CardinalDirection {
  NORTH = 'north',
  NORTHEAST = 'northEast',
  EAST = 'east',
  SOUTHEAST = 'southEast',
  SOUTH = 'south',
  SOUTHWEST = 'southWest',
  WEST = 'west',
  NORTHWEST = 'northWest'
}

const getCardinalDirectionFromAngle = (angle: number) => {
  if (angle < 0 || angle > 360) return '';
  if (angle <= 22.5 || angle > 315 + 22.5) return CardinalDirection.NORTH;
  if (angle > 270 + 22.5) return CardinalDirection.NORTHWEST;
  if (angle > 225 + 22.5) return CardinalDirection.WEST;
  if (angle > 180 + 22.5) return CardinalDirection.SOUTHWEST;
  if (angle > 135 + 22.5) return CardinalDirection.SOUTH;
  if (angle > 90 + 22.5) return CardinalDirection.SOUTHEAST;
  if (angle > 45 + 22.5) return CardinalDirection.EAST;
  if (angle > 22.5) return CardinalDirection.NORTHEAST;
};
export default getCardinalDirectionFromAngle;

const cardinalDirectionToAngleMap = {
  [CardinalDirection.NORTH]: 0,
  [CardinalDirection.NORTHWEST]: 315,
  [CardinalDirection.WEST]: 270,
  [CardinalDirection.SOUTHWEST]: 225,
  [CardinalDirection.SOUTH]: 180,
  [CardinalDirection.SOUTHEAST]: 135,
  [CardinalDirection.EAST]: 90,
  [CardinalDirection.NORTHEAST]: 45
};

export function cardinalDirectionToAngle(direction: CardinalDirection | null): number | null {
  if (!direction) return null;
  return cardinalDirectionToAngleMap[direction];
}
