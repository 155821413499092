import { useTranslation } from 'react-i18next';
import styles from './DataStandards.module.scss';
import { DataStandardsRow } from './DataStandardsRow';
import {
  getPropertyConfigCacheKey,
  useAvailableProperties,
  usePropertyConfigurations
} from '../../../properties/usePropertyConfigurations';
import { useContext, useEffect, useState } from 'react';
import PropertyConfiguration from '../../../properties/PropertyConfiguration';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { Refresh } from 'iconoir-react';
import { useParams } from 'react-router-dom';
import { AvailableProperties } from '../../../properties/AvailableProperties';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useQueryClient } from 'react-query';
import { useTracking } from '../../../analytics/useTracking';
import PropertyConfigEditModal from './PropertyConfigEditModal';
import Confirmation from '../../../components/UI/Confirmation/Confirmation';

export default function DataStandards() {
  const queryClient = useQueryClient();
  const availableProperties = useAvailableProperties();
  const { organizationId } = useParams();
  const { propertyConfigurationService } = useContext(DependencyInjectionContext);
  const { events, track } = useTracking();

  const { t } = useTranslation();
  const account = useCurrentAccount();

  const [standardsEditing, setStandardsEditing] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<PropertyConfiguration | null>(null);
  const [resetInProgress, setResetInProgress] = useState(false);

  const { data: configs } = usePropertyConfigurations();

  useEffect(() => {
    setResetInProgress(false);
  }, [selectedConfig, configs, standardsEditing]);

  const resetAllToDefault = async () => {
    if (!organizationId) return;
    track(events.PROPERTY_CONFIG_ALL_RESET);
    await propertyConfigurationService.setAllToDefault(organizationId);
    await queryClient.invalidateQueries({ queryKey: getPropertyConfigCacheKey(organizationId) });
  };

  const handleEditClick = (cfg: PropertyConfiguration) => {
    if (!cfg) return;
    setSelectedConfig(cfg);
    setStandardsEditing(true);
  };

  const handleConfirmReset = async () => {
    await resetAllToDefault();
    setResetInProgress(false);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('settings.dataStandards.title')}</h2>
      <p className={styles.description}>{t('settings.dataStandards.standardsDescription')}</p>
      {configs.length > 0 && new AvailableProperties(availableProperties).map((name, properties) => {
        return (
          <div key={`properties-${name}`} className={styles.propertyGroup}>
            <h3 className={styles.groupTitle}>{t(name)}</h3>
            <ul>
              {properties.map(it => {
                const cfg = configs!.find(cfg => cfg.property === it);
                if (!cfg) return '';
                return <DataStandardsRow
                  key={cfg.property}
                  config={cfg}
                  organization={account.organization}
                  handleEditClick={() => handleEditClick(cfg)}
                />;
              })}
            </ul>
          </div>
        );
      })}
      {resetInProgress ?
        <Confirmation
          onConfirm={handleConfirmReset}
          onCancel={() => setResetInProgress(false)}
          confirmText={t('settings.dataStandards.reset')}
          cancelText={t('settings.dataStandards.cancel')}
        /> :
        <FunctionButton
          className={styles.resetToDefault}
          icon={<Refresh/>}
          onClick={() => setResetInProgress(true)}
        >
          {t('settings.dataStandards.resetToDefault')}
        </FunctionButton>}
      {standardsEditing &&
                <PropertyConfigEditModal
                  selectedConfig={selectedConfig}
                  closeModal={() => setStandardsEditing(false)}
                  organizationId={organizationId}
                />}
    </div>
  );
}
