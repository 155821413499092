import * as THREE from 'three';

export const removeObjectFromScene = (object: THREE.Object3D | null, scene) => {
  const obj = object as unknown as THREE.Mesh;
  if (obj) {
    obj.children.forEach(obj => removeObjectFromScene(obj, scene));
    (obj.material as THREE.MeshBasicMaterial)?.map?.dispose();
    (obj.material as THREE.Material)?.dispose();
    (obj as THREE.Mesh).geometry?.dispose();
    obj.clear();
    scene.remove(obj);
  }
};

export const newVector3FromLocalCoords = (localCoords: number[]): THREE.Vector3 => {
  return new THREE.Vector3(localCoords[0], localCoords[2], -localCoords[1]);
};

export const vector3ToLocalCoords = (position: THREE.Vector3): number[] => {
  return [position.x, -position.z, position.y];
};
