import { Trans, useTranslation } from 'react-i18next';
import { FormEvent, useCallback, useMemo, useRef, useState } from 'react';

import styles from './ResetPasswordScreen.module.scss';
import { PasswordInput } from '../UI/Input/Input';
import LegacyButton from '../UI/Button/LegacyButton';
import { FullLogoIcon } from '../UI/Icon/Icon';
import { Link } from 'react-router-dom';
import { Password } from '../../password/Password';
import { PasswordValidationErrorView } from '../PasswordValidationErrorView/PasswordValidationErrorView';

export function ResetPasswordScreen(props: ResetPasswordScreenProps) {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const passwordErrors = useMemo(() => {
    if (!password) {
      return [];
    }
    return new Password(password).getErrors();
  }, [password]);
  const isValid = password && passwordConfirmation && password === passwordConfirmation && passwordErrors.length === 0;
  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      if (!formRef.current || !formRef.current.checkValidity() || !isValid || props.isLoading) {
        return;
      }

      props.onReset(password);
      return false;
    },
    [isValid, password, props]
  );

  return (
    <div className={styles.resetPassword}>
      <FullLogoIcon />

      <div className={styles.container}>
        {props.isCompleted && (
          <div className={styles.success}>
            <Trans i18nKey="resetPassword.success" components={[<Link to="/" />]} />
          </div>
        )}
        {props.error && <div className={styles.error}>{props.error}</div>}

        {!props.isCompleted && (
          <form
            className={styles.form}
            ref={formRef}
            onSubmit={onSubmit}>
            <PasswordInput
              value={password}
              placeholder={t('resetPassword.placeholder')}
              onValueChange={setPassword}
              testId="reset-password-password"
            />

            <PasswordInput
              value={passwordConfirmation}
              placeholder={t('resetPassword.confirmationPlaceholder')}
              onValueChange={setPasswordConfirmation}
              testId="reset-password-password-confirmation"
            />

            <PasswordValidationErrorView password={password} errors={passwordErrors} />

            <div className={styles.controls}>
              <LegacyButton disabled={!isValid || props.isLoading}>{t('resetPassword.resetPassword')}</LegacyButton>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

interface ResetPasswordScreenProps {
  error: string,
  onReset: (password: string) => unknown,
  isCompleted: boolean,
  isLoading: boolean
}
