import { Children, ReactElement } from 'react';

import LegacyNavbar from '../../Navbar/LegacyNavbar';
import styles from './LegacyPageLayout.module.scss';

export default function LegacyPageLayout(props: PageLayoutProps) {
  const leftNavExtensions = Children.map(props.children, child => (child.type === LeftNavExtensions ? child : null));
  const centerNavExtensions = Children.map(props.children, child => (child.type === CenterNavExtensions ? child : null));
  const rightNavExtensions = Children.map(props.children, child => (child.type === RightNavExtensions ? child : null));
  const content = Children.map(props.children, child => (child.type === Content ? child : null));

  return (
    <div className={styles.container} data-testid='page-layout'>
      {<LegacyNavbar details={!!props.detailsNavbar} borderless={!!props.borderless}>
        <LegacyNavbar.Left>
          {leftNavExtensions}
        </LegacyNavbar.Left>

        <LegacyNavbar.Center>
          {centerNavExtensions}
        </LegacyNavbar.Center>

        <LegacyNavbar.Right>
          {rightNavExtensions}
        </LegacyNavbar.Right>
      </LegacyNavbar>
      }
      <div className={styles.content}>{content}</div>
    </div>
  );
}

function LeftNavExtensions({ children }) {
  return children;
}

function CenterNavExtensions({ children }) {
  return children;
}

function RightNavExtensions({ children }) {
  return children;
}

LegacyPageLayout.LeftNavExtensions = LeftNavExtensions;
LegacyPageLayout.CenterNavExtensions = CenterNavExtensions;
LegacyPageLayout.RightNavExtensions = RightNavExtensions;

function Content({ children }) {
  return children;
}

LegacyPageLayout.Content = Content;

interface PageLayoutProps {
  children: ReactElement | ReactElement[],
  detailsNavbar?: boolean,
  borderless?: boolean
}
