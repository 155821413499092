import EnumPredicateDto from './dto/EnumPredicateDto';
import FilterPredicate, { PredicateType } from './FilterPredicate';
import { Tree } from '../tree/Tree';

export default class EnumPredicate implements FilterPredicate {
  static fromDto(dto: EnumPredicateDto) {
    return new EnumPredicate(dto.property, dto.values);
  }

  constructor(
    readonly property: keyof Tree,
    readonly values: string[]
  ) {}

  apply(tree: Tree): boolean {
    if (this.property === ('species' as keyof Tree)) return this.values.includes(tree.scientificName);
    return this.values.includes(tree[this.property] as string);
  }

  toDto(): EnumPredicateDto {
    return {
      property: this.property,
      values: this.values
    };
  }

  get type(): PredicateType.ENUM {
    return PredicateType.ENUM;
  }
}
