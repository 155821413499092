import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PropertiesSelector.module.scss';
import { FunctionButton } from '../../../UI/Button/LegacyButton';
import { ArrowSeparateVertical, Plus, Trash } from 'iconoir-react';
import AbsoluteModal from '../../../Modal/absolute-modal/AbsoluteModal';
import ModalDialog from '../../../Modal/absolute-modal/ModalDialog';
import NumericPredicate, { NumericPredicateType } from '../../../../tree-filter/NumericPredicate';
import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import { TreePropertyDisplayOptionsService } from '../../../../tree/TreePropertyDisplayOptionsService';
import TreePropertySlider from '../../../UI/Slider/TreePropertySlider';
import { useAvailableProperties } from '../../../../properties/usePropertyConfigurations';
import { AvailableProperties } from '../../../../properties/AvailableProperties';
import PropertyConfigurationPredicate from '../../../../tree-filter/PropertyConfigurationPredicate';
import PropertyConfigurationRangeSelector from './PropertyConfigurationRangeSelector';
import { PredicateType } from '../../../../tree-filter/FilterPredicate';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import { Account } from '../../../../account/Account';
import { useTracking } from '../../../../analytics/useTracking';

export function PropertiesSelector(props: PropertiesSelectorProps) {
  const editButtonRef = useRef<HTMLDivElement | null>(null);
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation();
  const [sortedPredicates, setSortedPredicates] = useState<(NumericPredicate | PropertyConfigurationPredicate)[]>([]);
  const { track, events } = useTracking();

  useEffect(() => {
    if (sortedPredicates.length === 0) {
      const sorted = props.predicates.sort((a, b) => a.property < b.property ? -1 : (a.property > b.property ? 1 : 0));
      setSortedPredicates(sorted);
      return;
    }
    const result = [...sortedPredicates].filter(existing => props.predicates.some(it => it.property === existing.property));
    const existingPredicatesByProperty = result.reduce((acc, it, index) => {
      acc[it.property] = { index };
      return acc;
    }, {});
    props.predicates.forEach(it => {
      if (!existingPredicatesByProperty[it.property]) {
        result.push(it);
      } else {
        result[existingPredicatesByProperty[it.property].index] = it;
      }
    });
    setSortedPredicates(result);
  }, [props.predicates]);

  const calculateModalPosition = () => {
    if (!editButtonRef.current) return { top: 0, left: 0 };
    const rect = editButtonRef.current?.getBoundingClientRect();
    if (rect.bottom > window.innerHeight - 400) {
      return { top: Math.max((rect.top - 400), 5), left: 25 };
    }

    return { top: rect.bottom, left: 25 };
  };

  const setPredicateValues = (predicate: NumericPredicate, value: number[] | number) => {
    let min, max;
    if (predicate.getType() === NumericPredicateType.IN_BETWEEN) {
      [min, max] = [value[0], value[1]];
    } else if (predicate.getType() === NumericPredicateType.IS_ABOVE) {
      min = value;
      max = null;
    } else {
      max = value;
      min = null;
    }

    props.onChange(NumericPredicate.fromDto({ ...predicate.toDto(), min, max }));
  };

  const setPropertyRanges = (index: number, predicate: PropertyConfigurationPredicate) => {
    let newRangeIndices: number[];
    if (predicate.rangeIndices.includes(index)) {
      newRangeIndices = predicate.rangeIndices.filter(it => it !== index);
    } else {
      newRangeIndices = [...predicate.rangeIndices, index];
    }
    props.onPropertyConfigurationPredicateChange(PropertyConfigurationPredicate.fromDto({ ...predicate, rangeIndices: [...newRangeIndices] }));
    track(events.SIDEBAR_FILTER_LABELS_PREDICATE_CHANGE,
      { from: { name: predicate.property, values: predicate.rangeIndices },
        to: { name: predicate.property, values: newRangeIndices } });
  };

  const saveTemporary = () => {
    props.onSaveTemporary();
    track(events.SIDEBAR_FILTER_NUMERIC_PREDICATE_CHANGE);
  };

  const [scaleSelectorOpen, setScaleSelectorOpen] = useState<string | null>(null);

  const getPropertyConfigMinMax = property => {
    const propertyConfiguration = props.propertyConfigurations?.find(it => it.property === property);
    if (!propertyConfiguration) return;
    return propertyConfiguration.getMinMaxValues();
  };

  const handleScaleChange = (predicate: NumericPredicate | PropertyConfigurationPredicate, type: NumericPredicateType) => {
    if (predicate.type === PredicateType.PROPERTY_CONFIGURATION) {
      props.onDelete(predicate);
    }
    predicate = (predicate as NumericPredicate);
    const displayOptions = new TreePropertyDisplayOptionsService().list().get(predicate.property as DisplayableTreeProperty);
    const propertyMinMax = getPropertyConfigMinMax(predicate.property) || displayOptions;
    let min: number | null = predicate.min || propertyMinMax?.min || 0;
    let max = predicate.max || propertyMinMax?.initial || null;
    min = type === NumericPredicateType.IS_BELOW ? null : min;
    max = type === NumericPredicateType.IS_ABOVE ? null : max;
    props.onChange(NumericPredicate.fromDto({ property: predicate.property, min, max }));
    setScaleSelectorOpen(null);
  };

  const handlePropertySelect = property => {
    const propertyThreshold = TreePropertyDisplayOptionsService.DEFAULT_THRESHOLD_SETUP;
    const propertyMinMax = getPropertyConfigMinMax(property);
    props.onChange(NumericPredicate.fromDto({
      property,
      min: propertyMinMax?.min || propertyThreshold.min,
      max: propertyMinMax?.max || propertyThreshold.max
    }));
    setEditing(false);
    track(events.SIDEBAR_FILTER_ADD_PROPERTY_PREDICATE, { value: property });
  };

  const getPropertyTitle = (property: DisplayableTreeProperty) => {
    const unit = Tree.getUnit(property, props.account.organization);
    const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
    const columnTitle = t(`tree.${property}`) + translatedUnit;

    return (
      <li
        className={`${styles.propertyListItem} ${props.predicates.find(it => it.property === property) && styles.activeProperty}`}
        key={`property-selector-modal-${property}`}
        onClick={() => handlePropertySelect(property)}
      >
        <span>{t(`${columnTitle}`)}</span>
      </li>);
  };

  const handlePropertyDelete = predicate => {
    props.onDelete(predicate);
    props.onSaveTemporary();
    track(events.SIDEBAR_FILTER_DELETE_FILTER_PREDICATE, { value: predicate.property });
  };

  const properties = useAvailableProperties();

  const handlePropertyConfigurationPredicateSelect = property => {
    setScaleSelectorOpen(null);

    const propertyConfiguration = props.propertyConfigurations.find(it => it.property === property);
    if (!propertyConfiguration) return;
    const predicate = PropertyConfigurationPredicate.fromDto({ propertyConfiguration, rangeIndices: [] });
    props.onPropertyConfigurationPredicateChange(predicate);
    track(events.SIDEBAR_FILTER_CHANGE_PROPERTY_PREDICATE_TYPE, { value: 'labels' });
  };

  const handlePropertyConfigurationPredicateChange = (predicate, rangeIndex) => {
    const propertyConfiguration = props.propertyConfigurations?.find(config => config.property === predicate.property)?.toDto();
    if (!propertyConfiguration) return;
    setPropertyRanges(rangeIndex, PropertyConfigurationPredicate.fromDto({
      ...(predicate as PropertyConfigurationPredicate),
      propertyConfiguration
    }));
    saveTemporary();
  };

  const getSelectedScaleLabel = predicate => {
    if (predicate.type === PredicateType.PROPERTY_CONFIGURATION) {
      return 'ranges';
    }
    return predicate.getType();
  };

  const topElement = (it: NumericPredicate | PropertyConfigurationPredicate) => (
    <>
      <div className={styles.listItemHeader} >
        <div className={styles.propertyLabel} data-testid={`listed-filter-${it.property}`}>{t(`tree.${it.property}`)}</div>
        <FunctionButton
          className={styles.deleteButton}
          icon={<Trash />}
          testId={`delete-listed-filter-${it.property}`}
          onClick={() => handlePropertyDelete(it)} />
      </div>
      <div className={styles.scaleSelectorContainer}>
        <FunctionButton
          testId={`scale-selector-${it.property}-dropdown`}
          onClick={() => setScaleSelectorOpen(it.property)}
          className={styles.scaleSelectorLabel}
          icon={<ArrowSeparateVertical />}>{t(`sidebarFilter.property.${getSelectedScaleLabel(it)}`).toUpperCase()}
        </FunctionButton>
        <div className={`${styles.scaleSelector} ${(scaleSelectorOpen === it.property) && styles.open}`}>
          <FunctionButton
            testId={`scale-selector-${it.property}-in-between`}
            onClick={() => handleScaleChange(it, NumericPredicateType.IN_BETWEEN)}>{t('sidebarFilter.property.in_between')}</FunctionButton>
          <FunctionButton
            testId={`scale-selector-${it.property}-is-above`}
            onClick={() => handleScaleChange(it, NumericPredicateType.IS_ABOVE)}>{t('sidebarFilter.property.is_above')}</FunctionButton>
          <FunctionButton
            testId={`scale-selector-${it.property}-is-below`}
            onClick={() => handleScaleChange(it, NumericPredicateType.IS_BELOW)}>{t('sidebarFilter.property.is_below')}</FunctionButton>
          <FunctionButton
            testId={`scale-selector-${it.property}-ranges`}
            onClick={() => handlePropertyConfigurationPredicateSelect(it.property)}>{t('sidebarFilter.property.ranges')}</FunctionButton>
        </div>
      </div>
    </>
  );

  return (
    <div className={styles.propertySelector}>
      <div className={styles.propertySelectorHeader}>
        <span className={styles.propertyTitle}>{t('sidebarFilter.properties')}</span>
        <div ref={editButtonRef}>
          <FunctionButton onClick={() => setEditing(true)} icon={<Plus />} />
        </div>
      </div>
      <ul data-testid="listed-filters">
        {sortedPredicates.map(predicate => {
          if (predicate.type === PredicateType.NUMERIC) {
            predicate = (predicate as NumericPredicate);
            const propertyDisplayOptions = new TreePropertyDisplayOptionsService().list().get(predicate.property as DisplayableTreeProperty) ?? TreePropertyDisplayOptionsService.DEFAULT_THRESHOLD_SETUP;
            const propertyMinMax = getPropertyConfigMinMax(predicate.property) || propertyDisplayOptions;
            const value: [number, number] | number = predicate.getType() === NumericPredicateType.IN_BETWEEN ? [
              predicate.min ?? (propertyMinMax.min),
              predicate.max ?? (propertyMinMax?.initial)
            ] : (predicate.getType() === NumericPredicateType.IS_ABOVE ? predicate.min ?? propertyMinMax.min : predicate.max ?? propertyMinMax.initial);
            return (
              <li className={styles.propertyListItemContainer} key={`${predicate.property}-${predicate.type}`}>
                {topElement(predicate)}
                <TreePropertySlider
                  name={predicate.property}
                  min={propertyMinMax.min}
                  max={propertyMinMax.max}
                  value={value}
                  isInBetweenType={predicate.getType() === NumericPredicateType.IN_BETWEEN}
                  inverted={predicate.getType() === NumericPredicateType.IS_ABOVE}
                  labelLeft={propertyMinMax.min.toFixed(1)}
                  labelRight={propertyMinMax.max.toFixed(1)}
                  onAfterChange={() => saveTemporary()}
                  onChange={val => setPredicateValues(predicate as NumericPredicate, val.value as [min: number, max: number])}
                  testId={'tree-property-slider'}
                />
              </li>);
          }
          predicate = (predicate as PropertyConfigurationPredicate);
          return (
            <li className={styles.propertyListItemContainer} key={`${predicate.property}-${predicate.type}`}>
              {topElement(predicate)}
              <PropertyConfigurationRangeSelector
                propertyConfigurationPredicate={predicate}
                propertyConfig={props.propertyConfigurations?.find(config => config.property === predicate.property)}
                onSelect={rangeIndex => handlePropertyConfigurationPredicateChange(predicate, rangeIndex)} />
            </li>);
        })
        }
      </ul>
      <AbsoluteModal isVisible={editing} onHide={() => setEditing(false)}>
        <ModalDialog
          classNames={styles.propertiesModal}
          style={{
            top: calculateModalPosition().top + 'px',
            left: calculateModalPosition().left + 'px'
          }}>
          <div className={styles.propertyModalHeader}>
            {t('sidebarFilter.addNewProperty')}
          </div>
          <div className={styles.propertyListScrollContainer}>
            {
              new AvailableProperties(properties).map((name, properties) => {
                if (name === 'treePropertySelector.inspections') return <></>;
                return <div className={styles.propertyListContainer}>
                  <div className={styles.propertyListTitle}>{t(name)}</div>

                  <ul className={styles.propertyList}>
                    {properties.map(property => (getPropertyTitle(property)))}
                  </ul>
                </div>;
              })
            }
          </div>
        </ModalDialog>
      </AbsoluteModal>
    </div>
  );
}

type PropertiesSelectorProps = {
  account: Account,
  predicates: (NumericPredicate | PropertyConfigurationPredicate)[],
  onChange: (predicate: NumericPredicate) => unknown,
  onSaveTemporary: () => unknown,
  onDelete: (predicate: NumericPredicate | PropertyConfigurationPredicate) => unknown,
  onPropertyConfigurationPredicateChange: (predicate: PropertyConfigurationPredicate) => unknown,
  propertyConfigurations: PropertyConfiguration[]
};
