import { RefObject, useEffect } from 'react';
import mapboxgl, { Expression, GeoJSONSourceRaw } from 'mapbox-gl';

interface GeoJsonLayerProps {
    mapRef: RefObject<mapboxgl.Map | null>,
    id: string,
    source: GeoJSONSourceRaw['data'],
    colors: { id: number, value: string }[],
    treeLayerId: string,
    isTreeLayerLoaded: boolean
}

const GeoJsonLayer = ({ mapRef, id, source, colors, treeLayerId, isTreeLayerLoaded }: GeoJsonLayerProps) => {
  useEffect(() => {
    const map = mapRef.current;
    if (!map || map.getSource(id) || map.getLayer(id) || !isTreeLayerLoaded) {
      return;
    }

    const addSourceAndLayer = () => {
      const generateColorFilter: (() => Expression) = () => {
        const idColorFilterCases = colors.map(({ id, value }) => [
          ['==', ['get', 'ID'], id],
          `rgb(${value})`
        ]);

        return ['case', ...idColorFilterCases.flat(), 'white'];
      };

      map.addSource(id, { type: 'geojson', data: source });

      map.addLayer({
        id,
        type: 'fill',
        source: id,
        paint: {
          'fill-color': generateColorFilter(),
          'fill-outline-color': generateColorFilter()
        }
      }, treeLayerId);
    };

    addSourceAndLayer();

    return () => {
      if (!map.getStyle()) return;
      map.getLayer(id) && map.removeLayer(id);
      map.getSource(id) && map.removeSource(id);
    };
  }, [colors, id, mapRef, source, isTreeLayerLoaded]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map || !isTreeLayerLoaded) return;

    map.moveLayer(id, treeLayerId);
  }, [treeLayerId, isTreeLayerLoaded]);

  return null;
};

export default GeoJsonLayer;
