import * as THREE from 'three';
import styles from './PointCloudViewer.module.scss';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Line2, LineGeometry, LineMaterial } from 'three-fatline';

export class LabelWithPointingLine extends THREE.Group {
  constructor(position: THREE.Vector3, private readonly nameLabel: string, offset: THREE.Vector2) {
    super();

    const labelElement = document.createElement('div');
    const [labelText, boldText] = nameLabel.split(':', 2);
    labelElement.innerHTML = `${labelText}: <b>${boldText}</b>`;
    labelElement.classList.add(styles.pointCloudLabel);
    const label = new CSS2DObject(labelElement);
    label.position.set(-offset.x, -offset.y, 0);
    const mesh = new Line2(
      new LineGeometry().setPositions([0, 0, 0, -offset.x, -offset.y, 0]),
      new LineMaterial({
        color: 0xffffff,
        linewidth: 1,
        resolution: new THREE.Vector2(640, 480),
        dashed: true,
        dashScale: 40,
        depthTest: false
      })
    ).computeLineDistances();
    mesh.renderOrder = 1;

    this.add(label, mesh);

    this.position.copy(position);
  }
}
