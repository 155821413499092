import { NavArrowRight, Plus, Trash } from 'iconoir-react';
import {
  ChangeEvent,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext, useEffect,
  useState
} from 'react';
import styles from './EditableAccordion.module.scss';
import Input from '../../../../../components/UI/Input/Input';

type EditableAccordionContextData = {
  isOpen: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  editing: boolean
};

const EditableAccordionContext = createContext<EditableAccordionContextData>({
  isOpen: false,
  setOpen: () => {},
  editing: false
});

interface EditableAccordionProps {
  editing: boolean,
  children: ReactNode
}

export default function EditableAccordion({ children, editing }: EditableAccordionProps) {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (editing) setOpen(true);
  }, [editing]);

  return (
    <EditableAccordionContext.Provider value={{ isOpen, setOpen, editing }}>
      <div className={styles.editableAccordion}>
        {children}
      </div>
    </EditableAccordionContext.Provider>
  );
}

function Header({ children, onAdd }: { children: ReactNode, onAdd: () => void }) {
  const { editing } = useContext(EditableAccordionContext);

  return (
    <div className={styles.header}>
      {children}
      {editing && <Plus onClick={onAdd} cursor={'pointer'}/>}
    </div>
  );
}

function HeaderLabel({ children }) {
  const { isOpen, setOpen, editing } = useContext(EditableAccordionContext);
  return (
    <div className={styles.headerLabel} onClick={() => setOpen(prev => editing || !prev)}>
      <NavArrowRight className={isOpen ? styles.rotated : ''}/>
      <span style={{ margin: '8px 0' }}>{children}</span>
    </div>
  );
}

function HeaderValue({ children }) {
  return <div className={styles.eyeAndValue}>{children}</div>;
}

function Body({ children }) {
  const { isOpen } = useContext(EditableAccordionContext);
  return <div className={styles.body} style={{ maxHeight: isOpen ? '135px' : '0px' }}>{children}</div>;
}

interface RowProps {
  label: string,
  value: string,
  unit: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onDelete: () => void
}

function Row({ label, value, unit, onChange, onDelete }: RowProps) {
  const { editing } = useContext(EditableAccordionContext);
  return <div className={styles.row}>
    <div className={styles.rowContent}>
      <span className={styles.label}>{label}</span>
      { !editing &&
        <span className={styles.highlighted}>
          {value} {unit}
        </span>
      }
      { editing &&
        <Input
          type="number"
          label={''}
          value={value}
          onChange={onChange}
          autoFocus={true}
          className={styles.editingInput}
        />
      }
    </div>
    {editing && <Trash onPointerUp={onDelete} cursor={'pointer'}/>}
  </div>;
}

EditableAccordion.Header = Header;
EditableAccordion.HeaderLabel = HeaderLabel;
EditableAccordion.HeaderValue = HeaderValue;
EditableAccordion.Body = Body;
EditableAccordion.Row = Row;
