import styles from './DisplayModes.module.scss';
import { FunctionButton } from '../UI/Button/LegacyButton';
import { Xmark } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import Dropdown from '../UI/Dropdown/Dropdown';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { useTracking } from '../../analytics/useTracking';
import Toggle from '../UI/Toggle/Toggle';

export enum DisplayTreesOptions {
  markers= 'markers',
  canopy= 'canopy'
}

export default function DisplayModes({ close }: DisplayModesProps) {
  const { t } = useTranslation();
  const { events, track } = useTracking();

  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const [displayTrees, setDisplayTrees] = useState<DisplayTreesOptions>(urlContext.getDisplayMode());
  const [displayISO, setDisplayISO] = useState(urlContext.isIsoMapEnabled());
  const [displayValues, setDisplayValues] = useState(urlContext.areTreeMarkersVisible());

  const displayTreeDropdownOptions = [
    { id: 'markers', translationKey: 'displayModes.markers' },
    { id: 'canopy', translationKey: 'displayModes.canopy' }
  ];

  const handleDisplayTreesChange = (item: DisplayTreesOptions) => {
    track(events.DISPLAY_MODES_DISPLAY_TREES_AS_CHANGE, { displayTreesAs: item });
    setDisplayTrees(item);
    urlContext.setDisplayMode(item);
  };

  const handleISOChange = () => {
    const enabled = !displayISO;
    track(events.DISPLAY_MODES_DISPLAY_ISO_MAP, { enabled });
    setDisplayISO(enabled);
    urlContext.setIsoMapEnabled(enabled);
  };

  const handleDisplayValueChange = () => {
    const enabled = !displayValues;
    track(events.DISPLAY_MODES_DISPLAY_VALUES, { enabled });
    setDisplayValues(enabled);
    urlContext.setDisplayValuesDisabled(!enabled);
  };

  return (
    <div className={styles.displayModes}>
      <div className={styles.modalTitle}>
        {t('displayModes.title')}
        <FunctionButton icon={<Xmark/>} onClick={() => close()}/>
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.displayTreeContainer}>
          <div className={styles.dropdownPrefix}>{t('displayModes.displayTreesAs')}</div>
          <Dropdown
            value={displayTreeDropdownOptions.find(it => it.id === displayTrees)}
            items={displayTreeDropdownOptions.filter(it => it.id !== displayTrees)}
            className={styles.dropdown}
            fieldClassName={styles.dropdownField}
            menuClassname={styles.dropdownMenu}
            onSelect={item => handleDisplayTreesChange(item.id as DisplayTreesOptions)}/>
        </div>
        <Toggle
          onChange={handleISOChange}
          checked={displayISO}
          className={styles.displayModesToggle}
        >
          {t('displayModes.displayIso')}
        </Toggle>
        <Toggle
          onChange={handleDisplayValueChange}
          checked={displayValues}
          className={styles.displayModesToggle}
        >
          {t('displayModes.displayValues')}
        </Toggle>
      </div>
    </div>);
}

interface DisplayModesProps {
  close: () => void
}
