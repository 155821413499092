import { WeakPasswordError } from '../../password/Password';
import { useTranslation } from 'react-i18next';

import styles from './PasswordValidationErrorView.module.scss';
import { ReactComponent as CrossIcon } from './images/cross-icon.svg';
import { ReactComponent as CheckIcon } from './images/check-icon.svg';

export function PasswordValidationErrorView(props: PasswordValidationErrorViewProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.passwordErrors}>
      <p>
        <b>{t('password.title')}</b>
      </p>

      <ul className={styles.passwordErrorList}>
        <li>
          <PasswordErrorIconView
            password={props.password}
            errors={props.errors}
            error={WeakPasswordError.TooShort}
          />
          <span>{t('password.tooShort')}</span>
        </li>

        <li>
          <PasswordErrorIconView
            password={props.password}
            errors={props.errors}
            error={WeakPasswordError.NoSmallCaps}
          />
          <span>{t('password.noSmallCaps')}</span>
        </li>

        <li>
          <PasswordErrorIconView
            password={props.password}
            errors={props.errors}
            error={WeakPasswordError.NoUpperCase}
          />
          <span>{t('password.noUpperCase')}</span>
        </li>

        <li>
          <PasswordErrorIconView
            password={props.password}
            errors={props.errors}
            error={WeakPasswordError.NoSpecialCharacters}
          />
          <span>{t('password.noSpecialCharacters')}</span>
        </li>
      </ul>
    </div>
  );
}

function PasswordErrorIconView(props: PasswordErrorIconViewProps) {
  if (!!props.password && props.errors.includes(props.error)) {
    return (
      <span className={`${styles.passwordErrorListIconError} ${styles.passwordErrorListIcon}`}>
        <CrossIcon />
      </span>
    );
  }

  if (!!props.password && !props.errors.includes(props.error)) {
    return (
      <span className={styles.passwordErrorListIcon}>
        <CheckIcon />
      </span>
    );
  }

  return (
    <span className={styles.passwordErrorListIcon}>
      <CrossIcon />
    </span>
  );
}

interface PasswordErrorIconViewProps {
  password: string,
  errors: WeakPasswordError[],
  error: WeakPasswordError
}

interface PasswordValidationErrorViewProps {
  password: string,
  errors: WeakPasswordError[]
}
