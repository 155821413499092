import { DisplayableTreeProperty } from '../../../../tree/Tree';
import { Check } from 'iconoir-react';
import AbsoluteModal from '../../../../components/Modal/absolute-modal/AbsoluteModal';
import ModalDialog from '../../../../components/Modal/absolute-modal/ModalDialog';
import styles from './PropertySelector.module.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import PartialTree from '../../../../tree/PartialTree';
import Tooltip from '../../../../components/UI/Tooltip/Tooltip';

export const VI_CREATION_MODAL_PROPERTY_OPTIONS = [
  DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed,
  DisplayableTreeProperty.LeaningAngle,
  DisplayableTreeProperty.Dieback,
  DisplayableTreeProperty.LeafArea,
  DisplayableTreeProperty.LeafAreaIndex,
  DisplayableTreeProperty.LeafAreaPerCrownVolume,
  DisplayableTreeProperty.CrownLightExposure,
  DisplayableTreeProperty.LiveCrownRatio,
  DisplayableTreeProperty.Slenderness,
  DisplayableTreeProperty.Status,
  DisplayableTreeProperty.VitalityVigor
];

export default function PropertySelector(props: PropertySelectorProps) {
  const { t } = useTranslation();

  const isModalVisible = props.isModalOpen;

  const disabledProperties = useMemo(() => {
    return VI_CREATION_MODAL_PROPERTY_OPTIONS.filter(it => !props.trees.some(tree => tree[it] !== null));
  }, [props.trees]);

  return (
    <AbsoluteModal
      isVisible={isModalVisible}
      onHide={props.onModalClose}
      zIndex={999999}
    >
      <ModalDialog
        style={{ left: `${props.left}px`, top: `${props.top + 20}px` }}
        classNames={styles.columnSelectorModal}
      >
        <section className={styles.propertyListContainer}>
          <div className={styles.listTitle}>{t('sidebarFilter.properties')}</div>
          <ul>
            {
              VI_CREATION_MODAL_PROPERTY_OPTIONS.sort().map(it => {
                const disabled = disabledProperties.includes(it);
                const item = <li
                  className={`${props.properties.includes(it) ? styles.activeProperty : ''} ${styles.propertyListItem} ${disabled ? styles.disabledProperty : ''}`}
                  onClick={() => disabledProperties.includes(it) ? '' : props.onChange(it)}
                  key={`property-selector-modal-${it}`}
                >
                  {props.properties.includes(it) ? <Check /> : <span className={styles.placeholder}></span>}
                  <span>{t('virtualInspection.observationOptions.' + it)}</span>
                </li>;
                return disabled ? <Tooltip
                  zIndex={999999}
                  overlayStyle={{ opacity: 1 }}
                  overlay={t('treeDetails.noData')}
                  key={`property-selector-modal-${it}`}
                >
                  {item}
                </Tooltip> : item;
              })
            }
          </ul>
        </section>
      </ModalDialog>
    </AbsoluteModal>
  );
}

type PropertySelectorProps = {
  isModalOpen: boolean,
  onModalClose: () => unknown,
  properties: DisplayableTreeProperty[],
  onChange: (property: DisplayableTreeProperty) => unknown,
  left: number,
  top: number,
  trees: PartialTree[]
};
