import styles from './LabelWithDropdown.module.scss';
import { useTranslation } from 'react-i18next';
import Dropdown, { Item } from '../Dropdown/Dropdown';

export default function LabelWithDropdown(props: LabelWithDropdownProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p className={`${styles.label} ${props.labelClassName && props.labelClassName}`}>{t(`${props.label}`)}</p>
      <Dropdown
        value={props.dropdownValue}
        items={props.items.filter(item => item.id !== props.dropdownValue?.id)}
        onSelect={it => props.onSelect(it)}
        fieldClassName={styles.dropdown}
        className={styles.dropdownContainer}
        menuClassname={styles.dropdownMenu}
        placeholder={'—'}
      />
    </div>
  );
}

interface LabelWithDropdownProps {
  label: string,
  items: Item[],
  dropdownValue?: { id: string, translationKey: string },
  onSelect,
  labelClassName?: string
}
