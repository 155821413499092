import { NoData } from '../LegacyDetails/DataPanel/Inventory/BaseAttributes';
import SpeciesInItalic from '../Explore/workspace/components/SpeciesInItalic';
import { useTranslation } from 'react-i18next';
import DetailedTree, { ViStatus } from '../../tree/DetailedTree';
import jobColumnColorMap from '../TaskManager/jobColumnColorMap';
import React from 'react';
import { Task } from '../../task-manager/Task';
import { Flippers } from '../../switches/Flippers';
import { useCurrentAccount } from '../../account/useAccounts';
import { GH_DEFAULT } from '../../tree/Tree';

export default function Header({ tree, task, isTreeEdited }: {
  tree: DetailedTree | null,
  task: Task | null,
  isTreeEdited: boolean
}) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();

  function renderTreeTitle() {
    if (isTreeKeeperLink()) {
      return <h2
        onClick={() => (window.open(`${organization?.metaData?.treeKeeperCredentials?.treeKeeperUrl}?siteID=${tree?.customerSiteId}`))}
        className="cursor-pointer"
      >
        {t(organization.isEnabled(Flippers.davey) ? 'treeDetails.smartTree' : 'treeDetails.tree') + ' ' + tree?.externalId}
      </h2>;
    }
    return <h2>
      {t(organization.isEnabled(Flippers.davey) ? 'treeDetails.smartTree' : 'treeDetails.tree') + ' ' + tree?.externalId}
    </h2>;
  }

  const isTreeKeeperLink = () => {
    return organization.isEnabled(Flippers.davey) && !!tree?.customerSiteId && !!organization?.metaData?.treeKeeperCredentials?.treeKeeperUrl;
  };

  return (
    <div className="flex justify-between w-full text-greehill-00 twp">
      <div className="flex gap-2 items-center text-2xl font-bold my-3">
        {renderTreeTitle()}
        {task && <span className={`bg-${jobColumnColorMap[task.jobColumnName].primary} px-2 flex items-center font-normal text-base text-greehill-00 rounded-md`}>{task.code}</span>}
        {task && tree?.viStatus === ViStatus.TO_BE_SCHEDULED && !isTreeEdited && <span className="bg-success-600 px-2 flex items-center font-normal text-base text-greehill-00 rounded-md">{t('remoteInspection.signed')}</span>}
        {(tree?.userUpdatedProperties && tree.userUpdatedProperties.length > 0) && <span className="bg-warn-chardonnay-100 px-2 flex items-center font-normal text-base text-warn-chardonnay-600 rounded-md">{t('details.manuallyUpdated')}</span>}
      </div>
      <div className="flex gap-2 items-center">
        <div className="grid grid-cols-4 gap-5">
          <>
            <div>
              {organization.isEnabled(Flippers.davey) && tree?.genus === GH_DEFAULT
                ? <>
                  <Label label={t('treeDetails.inventory.genus')} />
                  <p><i>Unknown</i></p>
                </>
                : <>
                  <Label label={t('treeDetails.inventory.genus')} />
                  <p><i>{tree?.genus || <NoData />}</i></p>
                </>}
            </div>
            <div>
              {organization.isEnabled(Flippers.davey) && tree?.species === GH_DEFAULT
                ? <>
                  <Label label={t('treeDetails.inventory.species')} />
                  <p><i>Unknown</i></p>
                </>
                : <>
                  <Label label={t('treeDetails.inventory.species')} />
                  <p>
                    {tree?.species ?
                      <SpeciesInItalic species={tree.species} />
                      : <NoData />}
                  </p>
                </>}
            </div>
          </>
          <div>
            <Label label={t('details.properties.commonName')} />
            <p>{tree?.commonName || <NoData />}</p>
          </div>
          <div>
            <Label label={t('details.properties.address')} />
            <p>{tree?.streetAddress || <NoData />}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const Label = ({ label }: { label: string }) => {
  return (
    <span className="uppercase text-outer-space-300 text-sm">{label}</span>
  );
};
