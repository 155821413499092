import IsoLineUrlGenerator, { IsoMapCategories } from './IsoLineUrlGenerator';
import { Organization } from '../organization/Organization';

function loadIsomapsForOrganization(organization: Organization): Record<string, { source: string, id: string }> {
  const urlGenerator = new IsoLineUrlGenerator();
  const isomapCategories = Object.keys(IsoLineUrlGenerator.categoryToFilenameMap) as IsoMapCategories[];

  return Object.fromEntries(isomapCategories.map(category => {
    const sourceUrl = urlGenerator.generateSourceUrl(organization, category);

    return [category, { source: sourceUrl, id: `${organization.tseBlobContainer}_${category}` }];
  }));
}

export const loadLayers = (organization: Organization) => loadIsomapsForOrganization(organization);
