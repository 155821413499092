import styles from './FlippersTab.module.scss';
import { Organization } from '../../../organization/Organization';
import { Flippers } from '../../../switches/Flippers';
import Toggle from '../../../components/UI/Toggle/Toggle';
import { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { SuperOwnerPageContext } from '../SuperOwnerPage';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import AbsoluteModal from '../../../components/Modal/absolute-modal/AbsoluteModal';
import { FlipperChangeLog } from '../AuditLogService';
import { useQueryClient } from 'react-query';
import { StaticQueryKeys } from '../../../StaticQueryKeys';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../../components/UI/Toast/DismissibleToast';

export default function FlippersTab() {
  const { organizationService, auditLogService } = useContext(DependencyInjectionContext);
  const queryClient = useQueryClient();

  const [highlightedColumnIndex, setHighlightColumnIndex] = useState<number | null>(null);
  const { sortedOrganizations } = useContext(SuperOwnerPageContext);
  const [flipperChangeLogs, setFlipperChangeLogs] = useState<FlipperChangeLog[] | null>(null);

  const updateFlipper = async (organization: Organization, flipper: Flippers) => {
    const orgFlippers = organization.flippers.filter(it => Object.values(Flippers).includes(it)); // Removes legacy flippers
    let newFlippers: Flippers[];
    if (orgFlippers.includes(flipper)) {
      newFlippers = orgFlippers.filter(it => it !== flipper);
    } else {
      newFlippers = [...orgFlippers, flipper];
    }
    try {
      await organizationService.setFlippers(organization.id, newFlippers);
      if (newFlippers.length > orgFlippers.length) {
        dismissibleSuccessToast(flipper + ' enabled in ' + organization.name, { className: styles.toastNotification });
      } else {
        dismissibleSuccessToast(flipper + ' disabled in ' + organization.name, { className: styles.toastNotification });
      }
      await queryClient.invalidateQueries(StaticQueryKeys.ORGANIZATIONS);
    } catch (e) {
      dismissibleErrorToast(`${e}`);
    }
  };

  const updateFlipperInAllOrgs = async (flipper: Flippers, newValue: boolean) => {
    if (window.confirm(`Do you want to ${newValue ? 'enable' : 'disable'} ${flipper} in all organizations?`)) {
      try {
        for (const organization of sortedOrganizations) {
          const orgFlippers = organization.flippers;

          if ((orgFlippers.includes(flipper) && newValue) || (!orgFlippers.includes(flipper) && !newValue)) continue;
          const newFlippers = newValue ? [...orgFlippers, flipper] : orgFlippers.filter(it => it !== flipper);
          await organizationService.setFlippers(organization.id, newFlippers);
        }

        dismissibleSuccessToast(`${flipper} ${newValue ? 'enabled' : 'disabled'} in all organizations`, { className: styles.toastNotification });
        await queryClient.invalidateQueries(StaticQueryKeys.ORGANIZATIONS);
      } catch (e) {
        dismissibleErrorToast(`${e}`);
      }
    }
  };

  const isFlipperInUse = (flipper: Flippers): boolean => {
    const flipperStatuses = sortedOrganizations.map(org => org.isEnabled(flipper));
    return Array.from(new Set(flipperStatuses)).length > 1;
  };

  const fetchFlipperChangeLogsForOrg = async (organizationId: string) => {
    const logs = await auditLogService.getFlipperChangeLogs(organizationId);
    setFlipperChangeLogs(logs);
  };

  const hideChangeLogsModal = () => {
    setFlipperChangeLogs(null);
  };

  return (
    <div className={styles.flippersTableContainer}>
      <table className={styles.flippersTable}>
        <thead>
          <tr>
            {Object.values(Flippers).map((flipper, idx) => {
              return <th
                key={flipper + idx}
                className={`${styles.rotatedHeader} ${isFlipperInUse(flipper) ? '' : styles.unusedFlipper}`}
                style={highlightedColumnIndex === idx ? { color: '#408F71' } : {}}
              >
                {flipper.length > 20 ? (
                  <Tooltip
                    overlay={flipper}
                    placement="bottom"
                  >
                    <div>{flipper.slice(0, 20)}...</div>
                  </Tooltip>
                ) : (
                  <div>{flipper}</div>
                )}
              </th>;
            })}
          </tr>
        </thead>
        <tbody>
          {sortedOrganizations.map(organization =>
            <tr key={organization.id}>
              <td
                className={styles.orgNameCell}
                onClick={() => fetchFlipperChangeLogsForOrg(organization.id)}
              >
                {organization.name}
              </td>
              {Object.values(Flippers).map((flipper, idx) => {
                return <td
                  key={organization.id + flipper + idx}
                  style={highlightedColumnIndex === idx ? { background: 'rgba(255, 255, 255, 0.1)' } : {}}
                  onMouseEnter={() => setHighlightColumnIndex(idx)}
                  onMouseLeave={() => setHighlightColumnIndex(null)}
                >
                  <Toggle
                    checked={organization.isEnabled(flipper)}
                    onChange={() => updateFlipper(organization, flipper)}
                  />
                </td>;
              })}

            </tr>
          )}
          <tr>
            <td>ALL</td>
            {Object.values(Flippers).map(flipper => {
              const allOn = sortedOrganizations.every(org => org.isEnabled(flipper));
              const allOff = sortedOrganizations.every(org => !org.isEnabled(flipper));
              return (
                <td className={styles.allOrgSwitchContainer} key={'all-org-switch' + flipper}>
                  <div>
                    <FunctionButton
                      disabled={allOn}
                      onClick={() => updateFlipperInAllOrgs(flipper, true)}
                    >
                      ON
                    </FunctionButton>
                    <FunctionButton
                      disabled={allOff}
                      onClick={() => updateFlipperInAllOrgs(flipper, false)}
                    >
                      OFF
                    </FunctionButton>
                  </div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
      <AbsoluteModal isVisible={Boolean(flipperChangeLogs)} onHide={hideChangeLogsModal}>
        <div className={styles.changeLogModal}>
          <h2>Flipper Change Logs</h2>
          <div className={styles.changeLogTableContainer}>
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Time</th>
                  <th>Flipper</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                {flipperChangeLogs?.map(log =>
                  <tr key={log.createdAt}>
                    <td>{log.user}</td>
                    <td>{new Date(log.createdAt).toLocaleString()}</td>
                    <td>{log.flipper}</td>
                    <td>{log.newValue === 'true' ? 'enabled' : 'disabled'}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </AbsoluteModal>
    </div>
  );
}
