import { Tree } from '../../../../tree/Tree';

export default interface FilterConfig {
  filters: Filter[],
  filterGroups: FilterGroup[],
  topLevelOperator: Operator
}

export interface Filter {
  property: keyof Tree,
  condition: NumericCondition | EnumCondition,
  value: number | string[]
}

export interface FilterGroup {
  filters: Filter[],
  filterGroupOperator: Operator
}

export enum Operator {
  AND = 'AND',
  OR = 'OR'
}

export enum NumericCondition {
  EQUALS = 'equals',
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan'
}

export enum EnumCondition {
  IN = 'in'
}
