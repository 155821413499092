import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  PageContainer,
  PageHeader
} from './components/Page';
import { AVAILABLE_DATES, NOW } from './fakes';
import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import {
  TreePropertyDateSelectorTableRow,
  TreePropertyTable,
  TreePropertyTableRow
} from './components/TreePropertyTable';
import { NumericFormat } from '../../numeric-format/NumericFormat';
import { AnalyticsView } from './AnalyticsView';
import { Organization } from '../../organization/Organization';

const properties = ['cavat', 'koch', 'rado'];

const propToTreeProp = {
  cavat: DisplayableTreeProperty.TreeValueCavat,
  koch: DisplayableTreeProperty.TreeValueKoch,
  rado: DisplayableTreeProperty.TreeValueRado
};

export default function EconomicalAnalytics(props: EconomicalAnalyticsProps) {
  const FAKES = AVAILABLE_DATES.map((date, i) => ({
    date,
    cavat:
      date.getTime() === NOW.getTime()
        ? props.tree.treeValueCavat
        : props.tree.treeValueCavat - (i * 100 + Math.random() * 100),
    koch:
      date.getTime() === NOW.getTime()
        ? props.tree.treeValueKoch
        : props.tree.treeValueKoch - (i * 100 + Math.random() * 100),
    rado:
      date.getTime() === NOW.getTime()
        ? props.tree.treeValueRado
        : props.tree.treeValueRado - (i * 100 + Math.random() * 100)
  }));

  const FAKE_OFFSETS = {
    cavat: props.tree.treeValueCavat * (Math.random() - 0.5),
    koch: props.tree.treeValueKoch * (Math.random() - 0.5),
    rado: props.tree.treeValueRado * (Math.random() - 0.5)
  };

  const { t } = useTranslation();
  const [dateA, setDateA] = useState(NOW);
  const [dateB, setDateB] = useState(AVAILABLE_DATES.at(-1)!);

  useEffect(() => {
    if (!props.compare) {
      setDateA(NOW);
      setDateB(AVAILABLE_DATES.at(-1)!);
    }
  }, [props.compare]);

  const header = (
    <PageHeader title={t('analytics.navigation.economicalValue')}>
    </PageHeader>
  );

  const monetaryValueFormatter = (value: number, unit: string) =>
    NumericFormat.currency(NumericFormat.getCurrencyCode(unit)).getFormattedValue(value, { maximumFractionDigits: 2 });

  if (props.view === AnalyticsView.Data) {
    const current =
      dateA.getTime() === NOW.getTime()
        ? {
          cavat: props.tree.treeValueCavat,
          koch: props.tree.treeValueKoch,
          rado: props.tree.treeValueRado
        }
        : FAKES.find(it => it.date.getTime() === dateA.getTime())!;
    const historical = FAKES.find(it => it.date.getTime() === dateB.getTime())!;
    return (
      <PageContainer>
        <TreePropertyTable compare={props.compare} header={header}>
          { properties.map(property => {
            const differenceAInYears = NOW.getFullYear() - dateA.getFullYear();
            const differenceBInYears = NOW.getFullYear() - dateB.getFullYear();
            const propertyValue = current[property]! - FAKE_OFFSETS[property] * differenceAInYears;
            const comparisonValue = historical[property]! - FAKE_OFFSETS[property] * differenceBInYears;

            return (
              <TreePropertyTableRow
                property={property}
                key={property}
                unitStyles={{ width: '40%', textAlign: 'right' }}
                unit={Tree.getUnit(propToTreeProp[property], props.organization)}
                value={propertyValue}
                showComparisonValue={props.compare}
                valueFormatter={monetaryValueFormatter}
                comparisonValue={comparisonValue}></TreePropertyTableRow>
            );
          })}
          { props.compare ?
            <TreePropertyDateSelectorTableRow
              dateA={dateA}
              dateB={dateB}
              setDateA={setDateA}
              setDateB={setDateB}
              availableDates={AVAILABLE_DATES}></TreePropertyDateSelectorTableRow>
            : null
          }
        </TreePropertyTable>
      </PageContainer>
    );
  }

  return (
    <div>
      {header}

      <p>{props.view}</p>
    </div>
  );
}

interface EconomicalAnalyticsProps {
  view: AnalyticsView,
  compare: boolean,
  organization: Organization,
  tree: Tree
}
