import { ReactNode } from 'react';
import { useCurrentAccount } from '../../account/useAccounts';
import { Features } from '../../switches/Features';
import { useNavigate } from 'react-router-dom';

export default function TaskManagerGuard({ children }: { children: ReactNode }) {
  const organization = useCurrentAccount().organization;
  const navigate = useNavigate();

  if (!organization.id) return null;
  if (!organization.isFeatureEnabled(Features.taskManager)) {
    navigate('/');
  }

  return (<>{children}</>);
}
