import * as THREE from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Line2, LineGeometry, LineMaterial } from 'three-fatline';
import { METER_TO_FEET } from '../components/PointCloud/unitConstants';

export class Measurement {
  static outOfScreenPosition = new THREE.Vector3(100000, 100000, 100000);

  private a = new CSS2DObject(document.createElement('div'));
  private b = new CSS2DObject(document.createElement('div'));

  private line = new Line2(
    new LineGeometry(),
    new LineMaterial({
      color: 0xffba0a,
      linewidth: 1,
      resolution: new THREE.Vector2(640, 480)
    })
  );

  private label = new CSS2DObject(document.createElement('div'));

  private fixedPointCounter = 0;

  constructor(styles: Record<string, string>, private readonly isMetric: boolean) {
    this.reset();

    this.a.visible = true;

    this.label.scale.multiplyScalar(0.04);
    this.label.element.classList.add(styles.measurementLabel);

    this.a.element.classList.add(styles.measurementMarker);
    this.b.element.classList.add(styles.measurementMarker);
  }

  setOrigin(position: THREE.Vector3) {
    this.line.position.copy(position);
  }

  setCursor(point: THREE.Vector3) {
    if (this.fixedPointCounter === 0) {
      return this.a.position.copy(point);
    }

    if (this.fixedPointCounter === 1) {
      this.b.position.copy(point);

      const distance = this.a.position.distanceTo(point) * (this.isMetric ? 1 : METER_TO_FEET);
      const unit = this.isMetric ? 'm' : 'ft';
      this.label.element.innerText = `${distance.toFixed(1)}${unit}`;
      this.label.position.copy(point.clone().add(this.a.position).multiplyScalar(0.5));
      this.label.visible = true;
      this.line.visible = true;

      this.line.geometry.setPositions([...this.a.position.clone().sub(this.line.position).toArray(), ...this.b.position.clone().sub(this.line.position).toArray()]);
    }
  }

  handleClick() {
    if (this.fixedPointCounter === 0 && this.isASet()) {
      this.fixedPointCounter++;
      this.b.visible = true;

      return;
    }

    if (this.fixedPointCounter === 1 && this.isBSet()) {
      this.fixedPointCounter++;
      return;
    }

    this.reset();
  }

  private isASet() {
    return !this.a.position.equals(Measurement.outOfScreenPosition);
  }

  private isBSet() {
    return !this.b.position.equals(Measurement.outOfScreenPosition);
  }

  getElements() {
    return [this.a, this.b, this.label, this.line];
  }

  reset() {
    this.fixedPointCounter = 0;

    this.a.position.copy(Measurement.outOfScreenPosition);

    this.b.position.copy(Measurement.outOfScreenPosition);
    this.b.visible = false;

    this.label.visible = false;
    this.line.visible = false;
  }
}
