import { Tree } from '../../../../tree/Tree';
import { ReactNode } from 'react';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { useTranslation } from 'react-i18next';
import PartialTree from '../../../../tree/PartialTree';
import proj4 from 'proj4';
import { Property } from '../TreeTable';

type ExtendedProperty = Property | 'x' | 'y';

interface FileDownloadButtonProps {
  header: ExtendedProperty[],
  treesProvider: () => Promise<PartialTree[]>,
  children: ReactNode,
  windSpeed: number
}

export default function FileDownloadButton(props: FileDownloadButtonProps) {
  const account = useCurrentAccount();
  const { t } = useTranslation();

  return <div
    onClick={e => {
      e.stopPropagation();

      props.treesProvider().then(trees => {
        const header = [...props.header];
        header.splice(3, 0, 'x', 'y');
        const alternativeExportEPSGConvertParams = account.organization?.metaData?.alternativeExportEPSGConvertParams;
        const csv = [
          header.map(it => {
            let title = t(`tree.${it}`);
            if (it === 'safetyFactors') {
              title = title.concat(t('treeList.windSpeedValue', {
                value: props.windSpeed,
                unit: Tree.getWindSpeedUnit(account.organization),
                interpolation: { escapeValue: false }
              }));
            }
            if (it === 'x' || it === 'y') title = it;
            return title;
          }),
          ...trees.map(tree => header.map(it => {
            if (tree.localizedLocation && alternativeExportEPSGConvertParams?.from && alternativeExportEPSGConvertParams?.to){
              const x = tree.localizedLocation ? tree.localizedLocation.coordinates[0] : -1;
              const y = tree.localizedLocation ? tree.localizedLocation.coordinates[1] : -1;
              const newCoordinates = proj4(alternativeExportEPSGConvertParams?.from, alternativeExportEPSGConvertParams?.to, [x, y]);
              if (it === 'x' as Property) {
                return tree.localizedLocation ? newCoordinates[0] : -1;
              }
              if (it === 'y' as Property) {
                return tree.localizedLocation ? newCoordinates[1] : -1;
              }
            }
            if (it === 'safetyFactors') {
              return tree.getSafetyFactor(props.windSpeed)?.toFixed(3);
            }
            if (it === 'managedAreaId') {
              return tree.managedAreaName;
            }
            if (it === 'x' as Property) {
              return tree.localizedLocation ? tree.localizedLocation.coordinates[0] : -1;
            }
            if (it === 'y' as Property) {
              return tree.localizedLocation ? tree.localizedLocation.coordinates[1] : -1;
            }
            if (tree[it] instanceof Array) {
              return tree[it].map(valueItem => t(Tree.getTKeyForProperty(it, valueItem.name))).join(', ');
            }
            if (typeof tree[it] === 'number') return tree[it].toFixed(3);
            return t(Tree.getTKeyForProperty(it, tree[it]));
          }))
        ]
          .map(row => row.map(it => JSON.stringify(it || '')).join(','))
          .join('\r\n');

        const filename = trees.length === 1
          ? `${account.organization.name}_${trees[0].externalId}_export.csv`
          : `${account.organization.name}_export.csv`;

        downloadBlob(csv, 'data:text/csv;charset=utf-8', filename);
      });
    }}>
    {props.children}
  </div>;
}

export function downloadBlob(data, contentType, fileName) {
  const blob = new Blob([data], { type: contentType });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
