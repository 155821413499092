import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import React, { useContext, useEffect, useState } from 'react';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import Input from '../../../components/UI/Input/Input';
import { TaskTemplateDto } from './CreateTasks';
import { useTranslation } from 'react-i18next';
import { FloppyDisk, Trash, Xmark } from 'iconoir-react';
import TaskTemplate from './TaskTemplate';
import { JobColumnName } from './JobColumnName';
import FilterConfig, { Operator } from './FilterConfig';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { ConnectedModule } from '../ConnectedModule';
import FilterConfigEditor from './FilterConfigEditor';

const connectOptions = [
  { id: ConnectedModule.remoteInspection, translationKey: 'taskManager.moduleConnection.' + ConnectedModule.remoteInspection },
  { id: 'none', translationKey: 'taskManager.moduleConnection.none' }
];

const jobColumnOptions = Object.values(JobColumnName).map(it => ({
  translationKey: 'taskManager.jobColumnTitles.' + it,
  id: it
}));

export default function EditTaskTemplate(props: EditTaskTemplateProps) {
  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const [speciesLists, setSpeciesLists] = useState({ scientificNames: [], genus: [], species: [] });

  const rankOptions = new Array(props.taskTemplate ? props.taskTemplatesLength : props.taskTemplatesLength + 1)
    .fill(0).map((_, i) => ({ id: (i + 1).toString(), translationKey: (i + 1).toString() }));

  useEffect(() => {
    (async () => {
      const list = await treeService.getSpeciesListsByManagedAreas(organization.id, props.selectedManagedAreaIds, props.reverseMASelection);
      setSpeciesLists(list);
    })();
  }, [organization.id, props.selectedManagedAreaIds, treeService]);

  const initialTemplateData: Partial<TaskTemplate> = props.taskTemplate ? {
    ...props.taskTemplate,
    filterConfig: props.taskTemplate.filterConfig || { filters: [], filterGroups: [], topLevelOperator: Operator.AND }
  } : {
    name: '',
    rank: Number(rankOptions[0].id),
    connectedModule: connectOptions[0].id,
    jobColumnName: JobColumnName.Inspection,
    filterConfig: { filters: [], filterGroups: [], topLevelOperator: Operator.AND }
  };
  const [templateData, setTemplateData] = useState(initialTemplateData);

  const handleChange = (newValue: string, property: string) => {
    setTemplateData(prev => ({
      ...prev,
      [property]: newValue
    }));
  };

  const saveTemplate = () => {
    const allFiltersDeleted = templateData.filterConfig?.filters.length === 0 && templateData.filterConfig?.filterGroups.length === 0;

    const removeInvalidFilters = (filterConfig: FilterConfig | undefined) => {
      if (!filterConfig) return undefined;
      return {
        ...filterConfig,
        filters: filterConfig.filters.filter(filter => filter.property && filter.condition && (filter.value !== undefined && filter.value !== null))
      };
    };

    props.save({
      name: templateData.name,
      jobColumnName: templateData.jobColumnName,
      rank: Number(templateData.rank),
      connectedModule: templateData.connectedModule,
      filterConfig: allFiltersDeleted ? undefined : removeInvalidFilters(templateData.filterConfig)
    }, props.taskTemplate?.id);

    setTemplateData(initialTemplateData);
  };

  const updateJobColumnName = (jobColumnName: JobColumnName) => {
    setTemplateData(prev => ({
      ...prev,
      jobColumnName,
      connectedModule: 'none'
    }));
  };

  return (
    <div>
      <div className="m-6 flex justify-between">
        <div className="flex gap-5 items-center">
          <Xmark
            className="w-5 h-5 cursor-pointer"
            onClick={props.closeSlideInPanel}
            color='white'
          />
          <h2 className="text-2xl font-semibold">
            {t(`taskManager.${props.taskTemplate?.id ? 'edit' : 'create'}TaskTemplate`)}
          </h2>
        </div>
        <div className="flex gap-2 items-center">
          <FunctionButton
            icon={<FloppyDisk />}
            variant={'rounded'}
            onClick={saveTemplate}
            disabled={!templateData.name?.trim()}
          >
            {t('taskManager.saveTaskTemplate')}
          </FunctionButton>
          {props.taskTemplate && (
            <FunctionButton
              icon={<Trash color="#FF6971" fillOpacity={0}/>}
              variant={'rounded'}
              onClick={() => props.deleteTaskTemplate(props.taskTemplate!.id)}
              className="p-2.5"
            />
          )}
        </div>
      </div>
      <div className="px-6">
        <div className="text-greehill-00 pt-4 rounded-lg justify-start items-start flex gap-10">
          <div className="w-1/2">
            <Input
              label={t('taskManager.taskTemplateName')}
              value={templateData.name}
              onChange={e => handleChange(e.target.value, 'name')}
              legacyStyle={true}
            />
          </div>
          <div className="w-1/4">
            <Dropdown
              label={t('taskManager.ranking')}
              value={rankOptions.find(it => (it.id === templateData.rank?.toString()) ?? rankOptions[0])}
              onSelect={value => handleChange(value.id!, 'rank')}
              items={rankOptions}
              fieldClassName="rounded h-11 mt-2 flex items-center"
              menuClassname="bg-outer-space-600 mx-0 px-0"
              dropDownItemClassName="hover:bg-outer-space-500 px-3 py-2"
            />
          </div>
          <div className="w-1/4">
            <Dropdown
              label={t('taskManager.connect')}
              value={connectOptions.find(it => it.id === templateData.connectedModule)}
              onSelect={value => handleChange(value.id!, 'connectedModule')}
              items={connectOptions}
              disabled={templateData.jobColumnName !== JobColumnName.Inspection}
              fieldClassName="rounded h-11 mt-2 flex items-center"
              menuClassname="bg-outer-space-600 mx-0 px-0"
              dropDownItemClassName="hover:bg-outer-space-500 px-3 py-2"
            />
          </div>
        </div>
        <div className="text-greehill-00 pt-4 rounded-lg justify-start items-start flex gap-10">
          <div className="w-1/2">
            <Input
              label={''}
              value={'Job'}
              disabled={true}
              legacyStyle={true}
            />
          </div>
          <div className="w-1/4">
            <Input
              label={''}
              value={'is'}
              disabled={true}
              legacyStyle={true}
            />
          </div>
          <div className="w-1/4">
            <Dropdown
              value={jobColumnOptions.find(it => it.id === templateData.jobColumnName)}
              onSelect={value => updateJobColumnName(value.id as JobColumnName)}
              items={jobColumnOptions}
              fieldClassName="rounded h-11 flex items-center"
              menuClassname="bg-outer-space-600 mx-0 px-0"
              dropDownItemClassName="hover:bg-outer-space-500 px-3 py-2"
            />
          </div>
        </div>
        <hr className="border-outer-space-400 my-8"/>
        <FilterConfigEditor
          filterConfig={templateData.filterConfig!}
          speciesLists={speciesLists}
          setFilterConfig={(filterConfig: FilterConfig) => setTemplateData(prevTemplateData => ({
            ...prevTemplateData,
            filterConfig
          }))}
        />
      </div>
    </div>
  );
}

interface EditTaskTemplateProps {
  closeSlideInPanel: () => void,
  save: (templateData: TaskTemplateDto, templateId?: string) => void,
  deleteTaskTemplate: (templateId: string) => void,
  taskTemplate: TaskTemplate | null,
  selectedManagedAreaIds: string[],
  reverseMASelection: boolean,
  taskTemplatesLength: number
}
