import { TreeStatistics } from '../../../tree/TreeStatistics';
import { useLoadedImage } from './useLoadedImage';
import AlternateTreePoint from '../images/alternate-tree-point.svg';
import TreePoint from '../images/tree-point.svg';
import ReferencePoint from '../images/reference-point.svg';
import DiagonalPattern from '../images/diagonal-pattern.svg';
import { Chart } from 'react-chartjs-2';
import * as ChartJSHelpers from 'chart.js/helpers';
import * as ChartJS from 'chart.js';

export default function TreePropertyStateChart(props: {
  statistics: TreeStatistics,
  current: number,
  compared?: boolean,
  hideDecimals?: true
}) {
  const decimals = props.hideDecimals ? 0 : 2;
  const treePointImage = useLoadedImage(props.compared ? AlternateTreePoint : TreePoint);
  const referencePointImage = useLoadedImage(ReferencePoint);
  const diagonalPatternImage = useLoadedImage(DiagonalPattern);

  if (!treePointImage || !referencePointImage || !diagonalPatternImage) {
    return null;
  }

  const cssVariable = (name: string) => window.getComputedStyle(document.body).getPropertyValue(name);
  const dataSize = props.statistics.buckets.length;
  const labels = new Array(dataSize)
    .fill(null)
    .map((v, index) => props.statistics.buckets.at(index)?.middle?.toFixed(decimals) ?? 0);
  const data = new Array(dataSize).fill(null).map((v, index) => props.statistics.buckets.at(index)?.count ?? 0);
  const currentColor = props.compared ? cssVariable('--chart-alternate-value') : cssVariable('--chart-positive-value');
  const defaultTextColor = '#fff';
  const currentTextColor = props.compared ? '#000' : defaultTextColor;

  return (
    <Chart
      type="line"
      data={{
        labels,
        datasets: [
          {
            type: 'line',
            label: 'current',
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0)',
            tension: 0.4,
            data: props.statistics.buckets.map((bucket, index, buckets) => {
              const isFirst =
                buckets.findIndex(bucket => props.current >= bucket.min && props.current <= bucket.max) === index;
              if (props.current >= bucket.min && props.current <= bucket.max && isFirst) {
                return bucket.count;
              }

              return null;
            })
          },
          {
            type: 'line',
            label: 'reference',
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0)',
            tension: 0.4,
            data: props.statistics.buckets.map((bucket, index, buckets) => {
              const isFirst =
                buckets.findIndex(
                  bucket => props.statistics.median >= bucket.min && props.statistics.median <= bucket.max
                ) === index;
              if (props.statistics.median >= bucket.min && props.statistics.median <= bucket.max && isFirst) {
                const threshold = 10;
                const medianPercentageToMax = props.statistics.median / props.statistics.max;
                const currentPercentageToMax = props.current / props.statistics.max;
                if (Math.abs(medianPercentageToMax - currentPercentageToMax) < threshold) {
                  return Math.max(...props.statistics.buckets.map(it => it.count)) / 6;
                }

                return Math.max(...props.statistics.buckets.map(it => it.count)) / 2;
              }

              return null;
            })
          },
          {
            type: 'line',
            label: 'data',
            tension: 0.4,
            data,
            borderColor: cssVariable('--text-primary'),
            borderDash: [4, 4],
            borderWidth: 1,
            pointRadius: 0
          }
        ]
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        layout: { padding: { top: 48, left: 24, bottom: 24, right: 24 } },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: false
          }
        },
        scales: {
          y: {
            display: false,
            grid: { display: false }
          },
          x: {
            grid: { color: cssVariable('--panel-border_transparent'), borderDash: [4, 4], drawBorder: false },
            offset: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 11,
              color: cssVariable('--text-primary'),
              padding: 12
            }
          }
        },
        hover: { mode: null as any },
        animation: {
          duration: 0,
          onComplete() {
            const ignoreThreshold = 0.25;
            const mainDatasetIndex = this.data.datasets.findIndex(it => it.label === 'data')!;
            const mainDatasetData = this.data.datasets.at(mainDatasetIndex)!.data as number[];
            const sum = mainDatasetData.reduce((sum, it) => sum + it, 0);
            const firstThreshold = sum * ignoreThreshold;
            const lastThreshold = sum * (1 - ignoreThreshold);
            const mainMetadata = this.getDatasetMeta(mainDatasetIndex);
            const lastItemOfFirstIgnoredSliceIndex =
              mainDatasetData.findIndex(
                (it, index, array) => array.slice(0, index).reduce((it, sum) => it + sum, 0) >= firstThreshold
              ) - 1;
            const firstItemOfLastIgnoredSliceIndex =
              mainDatasetData.findIndex(
                (it, index, array) => array.slice(0, index).reduce((it, sum) => it + sum, 0) >= lastThreshold
              ) - 1;
            const firstSliceEndPoint = mainMetadata.data.at(lastItemOfFirstIgnoredSliceIndex)!;
            const lastSliceStartPoint = mainMetadata.data.at(firstItemOfLastIgnoredSliceIndex)!;

            const yOffset = 10;
            const yMin = this.scales.y.top;
            const yMax = this.scales.y.bottom + yOffset;
            const xMin = this.scales.x.left;
            const xMax = this.scales.x.right;
            const width = xMax - xMin;
            const height = yMax - yMin;

            this.ctx.fillStyle = this.ctx.createPattern(diagonalPatternImage, 'repeat')!;
            this.ctx.fillRect(lastSliceStartPoint.x, yMin, width - lastSliceStartPoint.x + xMin, height);
            if (lastSliceStartPoint.x !== firstSliceEndPoint.x) {
              this.ctx.fillRect(xMin, yMin, firstSliceEndPoint.x, height);
            }
            this.ctx.fillStyle = '';

            const fontSize = 14;
            this.ctx.font = ChartJSHelpers.fontString(
              fontSize,
              ChartJS.defaults.font.style ?? '',
              ChartJS.defaults.font.family ?? ''
            );
            this.ctx.textAlign = 'center';
            this.ctx.textBaseline = 'bottom';

            this.data.datasets
              .filter(it => it.label === 'current' || it.label === 'reference')
              .forEach((dataset, datasetIndex) => {
                const isCurrentDataset = dataset.label === 'current';
                const color = isCurrentDataset ? currentColor : cssVariable('--chart-negative-value');
                const textColor = isCurrentDataset ? currentTextColor : defaultTextColor;
                const image = isCurrentDataset ? treePointImage : referencePointImage;
                const label = (isCurrentDataset ? props.current : props.statistics.median)?.toFixed(decimals) ?? 0;
                const metadata = this.getDatasetMeta(datasetIndex);

                metadata.data.forEach((point, index) => {
                  if (!point || dataset.data.at(index) === null) {
                    return;
                  }

                  this.ctx.beginPath();
                  this.ctx.moveTo(point.x, yMin);
                  this.ctx.lineTo(point.x, yMax);
                  this.ctx.strokeStyle = color;
                  this.ctx.stroke();
                  this.ctx.strokeStyle = '';
                  this.ctx.closePath();

                  const yOffset = 18;

                  const backgroundHeight = 20;
                  const backgroundHorizontalPadding = 20;
                  const backgroundWidth = this.ctx.measureText(label).width + backgroundHorizontalPadding;
                  const backgroundX = point.x - backgroundWidth / 2;
                  const backgroundY = point.y - backgroundHeight - yOffset;
                  const radius = backgroundHeight / 2;

                  this.ctx.beginPath();
                  this.ctx.moveTo(backgroundX + radius, backgroundY);
                  this.ctx.arcTo(
                    backgroundX + backgroundWidth,
                    backgroundY,
                    backgroundX + backgroundWidth,
                    backgroundY + backgroundHeight,
                    radius
                  );
                  this.ctx.arcTo(
                    backgroundX + backgroundWidth,
                    backgroundY + backgroundHeight,
                    backgroundX,
                    backgroundY + backgroundHeight,
                    radius
                  );
                  this.ctx.arcTo(backgroundX, backgroundY + backgroundHeight, backgroundX, backgroundY, radius);
                  this.ctx.arcTo(backgroundX, backgroundY, backgroundX + backgroundWidth, backgroundY, radius);
                  this.ctx.closePath();
                  this.ctx.fillStyle = color;
                  this.ctx.fill();

                  const textOffset = fontSize / 5;
                  this.ctx.fillStyle = textColor;
                  this.ctx.fillText(label, point.x, point.y - yOffset - textOffset);

                  this.ctx.drawImage(image, point.x - image.width / 2, point.y - image.height / 2);
                });
              });
          }
        }
      }}
    />
  );
}
