import { useTranslation } from 'react-i18next';
import styles from './DataStandards.module.scss';
import { useEffect, useState } from 'react';
import { PropertyRange } from '../../../properties/PropertyConfiguration';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { Trash } from 'iconoir-react';
import { EditablePropertyRange } from './PropertyConfigEditModal';

export default function RangeInput ({
  range,
  color,
  onChange,
  onDelete,
  onError,
  min,
  max,
  containerClassName,
  className,
  valueEditingDisabled,
  setTouched
}: {
    range: EditablePropertyRange,
    color?: string,
    onChange: (range: PropertyRange) => unknown,
    onDelete?: (range: EditablePropertyRange) => unknown,
    onError: (hasError: boolean) => unknown,
    min: number,
    max: number,
    containerClassName: string,
    className: string,
    valueEditingDisabled?: boolean,
    setTouched: (boolean) => unknown
}) {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(range.name);
  const [value, setValue] = useState<string>(range.to.toString());
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setName(range.name);
    setValue(range.to.toString());
  }, [range]);

  const validate = () => {
    let error = '';
    if (!validateNumber(value)) {
      error = t('settings.dataStandards.errorMissingValue');
    } else if (name === '') {
      error = t('settings.dataStandards.errorMissingName');
    } else if (Number(value) < min) {
      error = t('settings.dataStandards.errorGreaterThan', { value: min.toFixed(2) });
    } else if (Number(value) > max) {
      error = t('settings.dataStandards.errorLessThan', { value: max.toFixed(2) });
    }
    if (error !== '') {
      setError(error);
      onError(true);
      return;
    }
    onError(false);
    onChange({ to: Number(value), from: Number(range.from), name });
  };

  const validateNumber = text => text !== '' && !Number.isNaN(Number(text));

  const onInputFocus = () => setError('');

  const handleNameChange = newName => {
    setName(newName);
    setTouched(true);
  };

  const handleValueChange = newValue => {
    setValue(newValue);
    setTouched(true);
  };

  return (
    <div className={containerClassName}>
      <div className={className}>
        <div className={styles.circle} style={color ? { backgroundColor: `rgb(${color})` } : {}}></div>
        <input
          onChange={e => handleNameChange(e.target.value)}
          onBlur={() => validate()}
          onFocus={onInputFocus}
          className={styles.rangeLabelInput}
          type="text"
          value={name}
        />
        <span>{t('settings.dataStandards.to')}</span>
        <input
          onChange={e => handleValueChange(e.target.value)}
          onBlur={() => validate()}
          onFocus={onInputFocus}
          className={styles.rangeValueInput}
          type="text"
          value={value}
          disabled={valueEditingDisabled}
        />
        {
          onDelete
            ? <FunctionButton
              className={styles.deleteButton}
              onClick={() => onDelete(range)}
              icon={<Trash/>}/>
            : <div className={styles.deleteButtonPlaceholder}></div>
        }
      </div>
      <div className={styles.errorMessage}>
        {error}
      </div>
    </div>
  );
}
