import styles from './OrganizationDataStandards.module.scss';
import DataStandards from './DataStandards';
import { useTranslation } from 'react-i18next';
import OrganizationSettings from './OrganizationSettings';

export default function OrganizationDataStandards() {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>{t('settings.dataStandards.label')}</h2>
        <h4 className={styles.headerDescription}>{t('settings.dataStandards.description')}</h4>
      </div>
      <OrganizationSettings/>
      <DataStandards/>
    </>
  );
}
