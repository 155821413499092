import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl, { LngLat } from 'mapbox-gl';
import styles from './InlineMap.module.scss';
import { useTranslation } from 'react-i18next';
import { Language, Map } from 'iconoir-react';
import { useCurrentAccount } from '../../account/useAccounts';
import getRuntimeConfig from '../../RuntimeConfig';
import { MAP_STYLES, MAX_ZOOM_LEVEL } from '../../constants';
import { InlineMapTreeMarkerLayer } from './html-tree-marker/InlineMapTreeMarkerLayer';
import { Tree } from '../../tree/Tree';
import AbsoluteModal from '../Modal/absolute-modal/AbsoluteModal';
import ModalDialog from '../Modal/absolute-modal/ModalDialog';
import ResetButton from './ResetButton';

export default function InlineMap(props: InlineMapProps) {
  const account = useCurrentAccount();
  const organization = account.organization;
  const apiUrl = getRuntimeConfig().apiUrl;

  const [mapStyle, setMapStyle] = useState<keyof typeof MAP_STYLES>('default');
  const [isLoaded, setLoadingState] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const isInitialRender = useRef(true);

  const treeMarkerLayer = useMemo(
    () => {
      return new InlineMapTreeMarkerLayer(
        getRuntimeConfig().apiUrl,
        organization,
        props.tree.id,
        containerRef,
        props.tree.managedAreaId
      );
    }, []
  );

  useEffect(() => {
    if (!containerRef.current || mapRef.current) return;

    mapRef.current = new mapboxgl.Map({
      container: containerRef.current,
      style: MAP_STYLES[mapStyle],
      dragRotate: true,
      touchZoomRotate: false,
      zoom: 19,
      maxZoom: MAX_ZOOM_LEVEL,
      maxPitch: 0,
      touchPitch: false,
      accessToken: getRuntimeConfig().mapboxApiKey,
      transformRequest: url => (url.startsWith(apiUrl) ? { credentials: 'include', url } : { url }),
      logoPosition: 'bottom-left',
      scrollZoom: props.disableZoomOnScroll !== true
    });

    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    mapRef.current.on('style.load', () => {
      setLoadingState(true);
    });
  }, [organization.id, apiUrl, organization, mapStyle, props.disableZoomOnScroll]);

  useEffect(() => {
    if (!containerRef.current || !mapRef.current) return;
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      mapRef.current!.setStyle(MAP_STYLES[mapStyle]);
      setLoadingState(false);
    }
  }, [mapStyle]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded) return;

    mapRef.current?.addLayer(treeMarkerLayer);
    if (treeMarkerLayer) {
      setCenterToSelectedTree();
    }

    return () => {
      mapRef.current?.removeLayer(treeMarkerLayer.id);
    };
  }, [isLoaded, treeMarkerLayer]);

  useEffect(() => {
    if (mapRef.current && isLoaded) {
      mapRef.current?.resize();

      if (mapRef.current?.getLayer(treeMarkerLayer.id)) {
        mapRef.current?.removeLayer(treeMarkerLayer.id);
      }
      mapRef.current?.addLayer(treeMarkerLayer);
      setCenterToSelectedTree();
    }

    return () => {
      if (mapRef.current?.getLayer(treeMarkerLayer.id)) {
        mapRef.current?.removeLayer(treeMarkerLayer.id);
      }
    };
  }, [props.isExpanded]);

  const setCenterToSelectedTree = () => {
    const [lat, lng] = props.tree.getWorldCoordinates();
    mapRef.current?.setCenter(new LngLat(lng, lat));
  };

  return (
    <div className={`${styles.mapWrapper} inlineMapWrapper`}>
      <div ref={containerRef} className={styles.mapContainer} />
      <MapStyleSelector style={mapStyle} setStyle={setMapStyle}/>
      <div className={styles.resetButtonContainer}>
        <ResetButton onClick={setCenterToSelectedTree} />
      </div>
    </div>
  );
}

interface InlineMapProps {
  tree: Tree,
  isExpanded?: boolean,
  disableZoomOnScroll?: boolean
}

const icons = {
  default: <Map />,
  satellite: <Language />
};

function MapStyleSelector(props: ViewSelectorProps) {
  const [open, setOpen] = useState(false);
  const viewSelectorRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const calculateModalPosition = () => {
    if (!viewSelectorRef.current) return { top: 0, left: 0 };
    const rect = viewSelectorRef.current?.getBoundingClientRect();
    return { top: rect.top - rect.height, left: rect.left };
  };

  const selectableStyle = useMemo(() => {
    return props.style === 'default' ? 'satellite' : 'default';
  }, [props.style]);

  const selectStyle = () => {
    props.setStyle(selectableStyle);
    setOpen(false);
  };

  return (
    <div className={styles.mapStyleSelector}>
      <div ref={viewSelectorRef}>
        <button onClick={() => setOpen(true)} className={styles.styleSelectorButton}>
          {icons[props.style]}{t(`details.views.${props.style}`)}
        </button>
      </div>
      <AbsoluteModal
        isVisible={open}
        onHide={() => setOpen(false)}
        hideOverlay={true}
      >
        <ModalDialog
          classNames={''}
          style={{
            top: calculateModalPosition().top + 'px',
            left: calculateModalPosition().left + 'px'
          }}>
          <button
            onClick={selectStyle}
            className={styles.styleSelectorButton}
          >
            {icons[selectableStyle]}{t(`details.views.${selectableStyle}`)}
          </button>
        </ModalDialog>
      </AbsoluteModal>
    </div>
  );
}

interface ViewSelectorProps {
  style: string,
  setStyle: Dispatch<SetStateAction<keyof typeof MAP_STYLES>>
}
