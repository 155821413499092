import { ReactNode, useState } from 'react';
import pageStyles from '../AnalyticsPage.module.scss';
import { Popover } from 'react-tiny-popover';
import { Calendar } from 'iconoir-react';

export function ChartControls({ children }: { children: ReactNode }) {
  return <div className={pageStyles.chartControls}>{children}</div>;
}

export function LegendContainer({ children }: { children: ReactNode }) {
  return <div className={pageStyles.legendContainer}>{children}</div>;
}

export function Legend(props: { children: ReactNode, variant: 'negative' | 'positive' | 'positive-compared' }) {
  if (props.variant === 'negative') {
    return <div className={pageStyles.legend.concat(' ', pageStyles.negativeValue)}>{props.children}</div>;
  }

  if (props.variant === 'positive-compared') {
    return <div className={pageStyles.legend.concat(' ', pageStyles.positiveCompared)}>{props.children}</div>;
  }

  return <div className={pageStyles.legend.concat(' ', pageStyles.positiveValue)}>{props.children}</div>;
}

export function DateSelectorContainer({ children, floating }: { children: ReactNode, floating?: true }) {
  return (
    <div className={pageStyles.dateSelectorContainer.concat(' ', floating ? pageStyles.floating : '')}>{children}</div>
  );
}

export function DateSelector(props: DateSelectorProps) {
  const [isOpen, setVisibility] = useState(false);
  const close = () => setVisibility(false);
  const toggle = () => setVisibility(state => !state);
  const select = (option: Date) => () => {
    close();
    props.onSelect(option);
  };

  const indicatorClassName = pageStyles.dateSelectorIndicator.concat(
    ' ',
    props.variant === 'reference' ? pageStyles.reference : pageStyles.current
  );

  return (
    <div className={pageStyles.dateSelector}>
      <Popover
        onClickOutside={close}
        content={
          <ul className={pageStyles.dateSelectorOptions}>
            {props.options.map((option, i) => (
              <li
                key={i}
                onClick={select(option)}
                className={pageStyles.dateSelectorOption.concat(
                  ' ',
                  props.selected.getTime() === option.getTime() ? pageStyles.selected : ''
                )}
              >
                {option.toLocaleDateString()}
              </li>
            ))}
          </ul>
        }
        positions={['top']}
        isOpen={isOpen}
        containerStyle={{ zIndex: '10' }}
        align="center"
      >
        <div onClick={toggle} className={pageStyles.dateSelectorToggle}>
          <span className={indicatorClassName} />
          <Calendar />
          {props.selected.toLocaleDateString()}
        </div>
      </Popover>
    </div>
  );
}

interface DateSelectorProps {
  options: Date[],
  selected: Date,
  onSelect: (date: Date) => unknown,
  variant: 'current' | 'reference'
}
