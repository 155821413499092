import styles from './FeaturesTab.module.scss';
import { Organization } from '../../../organization/Organization';
import Toggle from '../../../components/UI/Toggle/Toggle';
import { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { SuperOwnerPageContext } from '../SuperOwnerPage';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import { useQueryClient } from 'react-query';
import { StaticQueryKeys } from '../../../StaticQueryKeys';
import { Features } from '../../../switches/Features';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../../components/UI/Toast/DismissibleToast';

export default function FeaturesTab() {
  const { organizationService } = useContext(DependencyInjectionContext);
  const queryClient = useQueryClient();

  const [highlightedColumnIndex, setHighlightColumnIndex] = useState<number | null>(null);
  const { sortedOrganizations } = useContext(SuperOwnerPageContext);

  const updateFeature = async (organization: Organization, feature: Features) => {
    let newFeatures: Features[];
    if (organization.features.includes(feature)) {
      newFeatures = organization.features.filter(it => it !== feature);
    } else {
      newFeatures = [...organization.features, feature];
    }
    try {
      await organizationService.setFeatures(organization.id, newFeatures);
      if (newFeatures.length > organization.features.length) {
        dismissibleSuccessToast(feature + ' enabled in ' + organization.name, { className: styles.toastNotification });
      } else {
        dismissibleSuccessToast(feature + ' disabled in ' + organization.name, { className: styles.toastNotification });
      }
      await queryClient.invalidateQueries(StaticQueryKeys.ORGANIZATIONS);
      await queryClient.invalidateQueries(StaticQueryKeys.ACCOUNTS);
    } catch (e) {
      dismissibleErrorToast(`${e}`);
    }
  };

  const isFeatureInUse = (feature: Features): boolean => {
    const featureStatuses = sortedOrganizations.map(org => org.isFeatureEnabled(feature));
    return Array.from(new Set(featureStatuses)).length > 1;
  };

  return (
    <div className={styles.featuresTableContainer}>
      <table className={styles.featuresTable}>
        <thead>
          <tr>
            {Object.values(Features).map((feature, idx) => {
              return <th
                key={feature + idx}
                className={`${styles.rotatedHeader} ${isFeatureInUse(feature) ? '' : styles.unusedFeature}`}
                style={highlightedColumnIndex === idx ? { color: '#408F71' } : {}}
              >
                {feature.length > 20 ? (
                  <Tooltip
                    overlay={feature}
                    placement="bottom"
                  >
                    <div>{feature.slice(0, 20)}...</div>
                  </Tooltip>
                ) : (
                  <div>{feature}</div>
                )}
              </th>;
            })}
          </tr>
        </thead>
        <tbody>
          {sortedOrganizations.map(organization =>
            <tr key={organization.id}>
              <td
                className={styles.orgNameCell}
              >
                {organization.name}
              </td>
              {Object.values(Features).map((feature, idx) => {
                return <td
                  key={organization.id + feature + idx}
                  style={highlightedColumnIndex === idx ? { background: 'rgba(255, 255, 255, 0.1)' } : {}}
                  onMouseEnter={() => setHighlightColumnIndex(idx)}
                  onMouseLeave={() => setHighlightColumnIndex(null)}
                >
                  <Toggle
                    checked={organization.isFeatureEnabled(feature)}
                    onChange={() => updateFeature(organization, feature)}
                  />
                </td>;
              })}

            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
