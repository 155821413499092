
enum ThresholdState {
  Default,
  Matching,
  Different
}

export class ThresholdMatchingState {
  static default(): ThresholdMatchingState {
    return new ThresholdMatchingState();
  }

  static matching(): ThresholdMatchingState {
    return new ThresholdMatchingState(ThresholdState.Matching);
  }

  static different(): ThresholdMatchingState {
    return new ThresholdMatchingState(ThresholdState.Different);
  }

  private constructor(private readonly value: ThresholdState = ThresholdState.Default) {}

  isDefault() {
    return this.value === ThresholdState.Default;
  }

  isMatching() {
    return this.value === ThresholdState.Matching;
  }

  isDifferent() {
    return this.value === ThresholdState.Different;
  }
}
