import PropertyConfiguration, { PropertyConfigurationDto } from './PropertyConfiguration';
import { AxiosInstance } from 'axios';

export class PropertyConfigurationService {
  constructor(private readonly http: AxiosInstance) {}

  async list(organizationId: string): Promise<PropertyConfiguration[]> {
    const response = await this.http.get<PropertyConfigurationDto[]>(`/v1/organizations/${organizationId}/property-configurations`);
    return response.data.map(it => PropertyConfiguration.fromDto(it));
  }

  async update(organizationId: string, property: string, body) {
    await this.http.put(`/v1/organizations/${organizationId}/property-configurations/${property}`, body);
  }

  async setPropertyToDefault(organizationId: string, property: string) {
    await this.http.delete(`/v1/organizations/${organizationId}/property-configurations/${property}`);
  }

  async setAllToDefault(organizationId: string) {
    await this.http.delete(`/v1/organizations/${organizationId}/property-configurations`);
  }
}
