import { AxiosInstance } from 'axios';
import getRuntimeConfig from '../RuntimeConfig';

export default class AddressService {
  constructor(private readonly http: AxiosInstance) {}

  async searchByAddress(boundingBox: number[], searchString: string, limit: number, language: string) {
    const getUrlForLang = lang => {
      return `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchString}.json?bbox=${boundingBox}&language=${lang}&limit=${limit}&access_token=${getRuntimeConfig().mapboxApiKey}`;
    };

    try {
      const result = await this.http.get(getUrlForLang(language), { withCredentials: false });
      return result.data.features;
    } catch (e) {
      const fallbackResult = await this.http.get(getUrlForLang('en'), { withCredentials: false });
      return fallbackResult.data.features;
    }
  }
}
