import { useTranslation } from 'react-i18next';
import styles from './DataGroupHeader.module.scss';
import { PrimaryButton, SecondaryButton } from '../../../../../components/UI/Button/LegacyButton';
import MoreOptionsSelector from './MoreOptionsSelector';
import { useCurrentAccount } from '../../../../../account/useAccounts';
import { useMemo } from 'react';
import { AccountType } from '../../../../../account/AccountType';

interface DataGroupHeaderProps {
  title?: string,
  editing: boolean,
  setEditing: () => void,
  handleCancel: () => void,
  handleSave: () => void
}

export default function DataGroupHeader(props: DataGroupHeaderProps) {
  const { t } = useTranslation();
  const currentAccount = useCurrentAccount();
  const canEdit = useMemo(() => [AccountType.Owner, AccountType.Admin, AccountType.Manager].includes(currentAccount.type), [currentAccount]);

  return (
    <h3 className={styles.groupHeader}>
      <div className={styles.editableTitle}>
        {props.title}
        {canEdit && !props.editing && <MoreOptionsSelector pencilAction={() => props.setEditing()}/>}
      </div>
      <div className={styles.cancelAndSave}>
        {
          props.editing &&
          <>
            <SecondaryButton onClick={props.handleCancel}>{t('cancel')}</SecondaryButton>
            <PrimaryButton onClick={props.handleSave}>{t('save')}</PrimaryButton>
          </>
        }
      </div>
    </h3>
  );
}
