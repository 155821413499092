import { useTranslation } from 'react-i18next';
import {
  ChangeEvent,
  ChangeEventHandler,
  KeyboardEvent,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import styles from './Input.module.scss';
import Text from '../Text/Text';
import { Calendar, Lock, Search } from 'iconoir-react';

export default function Input({
  placeholderKey,
  onChange,
  value,
  className,
  inputClassName,
  disabled,
  label,
  type = 'text',
  placeholder = '-',
  onValueChange,
  readOnly = !onChange && !onValueChange,
  required = false,
  minLength,
  min = 0,
  testId,
  inputRef,
  onBlur,
  autoFocus = false,
  legacyStyle = false,
  onEnter,
  icon
}: InputProps) {
  const { t } = useTranslation();
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }

    if (onValueChange) {
      onValueChange(event.target.value.toString());
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && onEnter) onEnter(event);
  };

  return (
    <label className={`${legacyStyle ? styles.legacyContainer : styles.container} ${className ?? ''} ${disabled ? styles.disabled : ''}`}>
      {icon}
      {label && <Text
        className={legacyStyle ? styles.legacyLabel : styles.label}
        weight={600}
        translationKey={label} />}
      <input
        ref={inputRef}
        data-testid={testId}
        value={value}
        placeholder={placeholderKey ? t(placeholderKey) : placeholder}
        className={`${legacyStyle ? styles.legacyInput : styles.input} ${inputClassName ?? ''}`}
        type={type}
        onChange={onInputChange}
        readOnly={readOnly}
        required={required}
        minLength={minLength}
        min={min}
        onBlur={onBlur}
        autoFocus={autoFocus}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
    </label>
  );
}

interface InputProps {
  placeholderKey?: string,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  value?: string | number,
  className?: string,
  inputClassName?: string,
  disabled?: boolean,
  label: string,
  readOnly?: boolean,
  type?: 'text' | 'email' | 'number' | 'password',
  placeholder?: string,
  onValueChange?: (value: string) => unknown,
  required?: boolean,
  minLength?: number,
  min?: number,
  testId?: string,
  inputRef?: RefObject<HTMLInputElement>,
  onBlur?: () => void,
  autoFocus?: boolean,
  legacyStyle?: boolean,
  onEnter?: (event: KeyboardEvent) => void,
  icon?: ReactNode
}

export const PasswordInput = (props: PasswordInputProps) => {
  const { t } = useTranslation();
  const [type, setType] = useState<'text' | 'password'>('password');
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onValueChange) {
      props.onValueChange(event.target.value.toString());
    }
  };
  const toggleType = useCallback(() => setType(state => (state === 'password' ? 'text' : 'password')), []);

  return (
    <label className={styles.container}>
      <span>
        <Lock />
      </span>

      <input
        aria-label={`Password: ${props.placeholder}`}
        data-testid={props.testId}
        value={props.value}
        placeholder={props.placeholder}
        className={styles.input}
        type={type}
        onChange={onInputChange}
        required={true}
        minLength={10}
      />

      <button
        className={styles.passwordToggle}
        type="button"
        onClick={toggleType}>
        {type === 'password' ? t('inputs.password.show') : t('inputs.password.hide')}
      </button>
    </label>
  );
};

interface PasswordInputProps {
  onValueChange: (value: string) => unknown,
  value: string,
  placeholder: string,
  testId?: string
}

export const DateInput = (props: DateInputProps) => {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onValueChange) {
      props.onValueChange(event.target.value ? new Date(event.target.value) : null);
    }
  };
  const year = props.value?.getFullYear();
  const month = ((props.value?.getMonth() ?? 0) + 1).toString().padStart(2, '0');
  const date = props.value?.getDate().toString().padStart(2, '0');

  return (
    <label className={`${props.className || ''} ${props.disabled ? styles.disabled : ''}`}>
      {props.label && <span className={styles.label}>{props.label}</span>}

      <span className={`${styles.container} ${styles.dateInputContainer}`}>
        <input
          aria-label={props.label}
          data-testid={props.testId}
          value={props.value ? `${year}-${month}-${date}` : ''}
          className={`${styles.input} ${props.disableKeyboardEdit ? '!cursor-default' : ''}`}
          type="date"
          onChange={onInputChange}
          required={true}
          minLength={10}
          onKeyDown={e => props.disableKeyboardEdit ? e.preventDefault() : {}}
          min={props.futureDateOnly ? new Date().toISOString().split('T')[0] : undefined}
          disabled={props.disabled}
        />

        <span className={styles.calendarIcon}>
          <Calendar />
        </span>
      </span>
    </label>
  );
};

interface DateInputProps {
  onValueChange: (value: Date | null) => unknown,
  value: Date | null,
  testId?: string,
  label?: string,
  className?: string,
  futureDateOnly?: boolean,
  disabled?: boolean,
  disableKeyboardEdit?: boolean
}

export const SearchInput = (props: SearchInputProps) => {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onValueChange) {
      props.onValueChange(event.target.value.toString());
    }
  };

  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (props.onSearchStart) {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        props.onSearchStart((event.target as HTMLInputElement).value.toString());
      }
    }
  };

  const inputReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!props.focus) return;
    setTimeout(() => {
      if (inputReference.current) inputReference.current.focus();
    }, 0);
  }, [props.focus, props.focusTrigger]);

  return (
    <label
      className={`${styles.container} ${props.dense && styles.dense} ${props.showError && styles.error} ${props.className}`}
      style={ props.showError ? { overflow: 'visible' } : { overflow : 'hidden' }}>
      <span>
        <Search />
      </span>

      <input
        ref={inputReference}
        aria-label="Start typing..."
        data-testid={props.testId}
        value={props.value}
        placeholder={props.placeholder}
        className={styles.input}
        onChange={onInputChange}
        onKeyUp={onKeyUp}
        required={true}
      />

      { props.showError ? <span className={styles.errorMessage}> { props.errorMessage } </span> : null }
    </label>
  );
};

interface SearchInputProps {
  onValueChange?: (value: string) => unknown,
  onSearchStart?: (value: string) => unknown,
  showError?: boolean,
  errorMessage?: string,
  value?: string,
  testId?: string,
  placeholder?: string,
  dense?: boolean,
  focus?: boolean,
  focusTrigger?: boolean,
  className?: string
}
