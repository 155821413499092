import { useEffect, useState } from 'react';

export function useLoadedImage(url: string) {
  const [image, setImage] = useState<null | HTMLImageElement>(null);

  useEffect(() => {
    const image = new Image();
    image.src = url;
    image.onload = () => setImage(image);
  }, [url]);

  return image;
}
