import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import { useMemo, useState } from 'react';
import { FunctionButton } from '../../../../components/UI/Button/LegacyButton';
import { Xmark } from 'iconoir-react';
import styles from './AdvancedFilterEnumSelector.module.scss';
import { Tree } from '../../../../tree/Tree';
import { useTranslation } from 'react-i18next';
import { TooltipForEllipsis } from '../../../../components/UI/Tooltip/Tooltip';

export default function AdvancedFilterEnumSelector(props: AdvancedFilterEnumSelectorProps) {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>(props.selection);

  const dropdownItems = useMemo(() => {
    if (Tree.isViBooleanProperty(props.property) && selectedItems.length > 0) {
      return [];
    }
    return props.options.map(it => ({ id: it }))
      .filter(it => !selectedItems.includes(it.id))
      .sort((a, b) => a.id.localeCompare(b.id));
  }, [props.options, selectedItems]);

  const selectItem = item => {
    const items = [...selectedItems, item];
    setSelectedItems(items);
    props.onChange(items);
  };

  const deselectItem = item => {
    const items = selectedItems.filter(it => it !== item);
    setSelectedItems(items);
    props.onChange(items);
  };

  const getDisplayedValue = (value?: string) => {
    if (Tree.isTranslatableProperty(props.property)) return t(Tree.getTKeyForProperty(props.property, value));
    return value;
  };

  return (
    <div data-testid='advanced-filter-enum-selector'>
      <Dropdown
        items={dropdownItems}
        testId={'advanced-filter-enum-selector-dropdown'}
        valueRenderer={item => (<TooltipForEllipsis
          overlay={getDisplayedValue(item.id)}
          overlayStyle={{ zIndex: 99999 }}>
          <div data-testid={`item-${item.id}`} className={styles.dropdownItem}>{getDisplayedValue(item.id)}</div>
        </TooltipForEllipsis>)}
        onSelect={item => selectItem(item.id)}
        placeholder={'treeList.advancedFiltering.select'}
        className={styles.enumSelectorPlaceholder}
        menuClassname={styles.enumSelectorMenu}
        fieldClassName={styles.enumSelectorField} />
      <div
        data-testid='advanced-filter-enum-selector-selected-list'
        className={styles.selectedRowsContainer}>
        {selectedItems.map((item, index) => (
          <div
            key={`item-${item}-${index}`}
            data-testid={`selected-item-${item}`}
            className={styles.selectedRow}>
            <TooltipForEllipsis overlay={getDisplayedValue(item)} overlayStyle={{ zIndex: 99999 }}>
              <div className={styles.selectedRowItem}>{getDisplayedValue(item)}</div>
            </TooltipForEllipsis>
            <FunctionButton
              icon={<Xmark />}
              onClick={() => deselectItem(item)}
              testId={`selected-list-delete-button-${item}`}/>
          </div>
        ))}
      </div>
    </div>
  );
}

interface AdvancedFilterEnumSelectorProps {
  property: string,
  onChange: (values: string[]) => unknown,
  options: string[],
  selection: string[]
}
