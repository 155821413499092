import { ReactNode } from 'react';

import styles from './MemberList.module.scss';

export default function MemberListHeader({ children }: MemberListHeaderProps) {
  return <div className={styles.header}>{children}</div>;
}

interface MemberListHeaderProps {
  children?: ReactNode
}
