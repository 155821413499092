import styles from './PropertyLegendCohortList.module.scss';
import { SigmaBoundary } from '../../routes/Explore/table-view/advanced-filter/AdvancedFilter';
import { useTranslation } from 'react-i18next';
import {
  AdvancedFilterConfigurationBuilder
} from '../../routes/Explore/table-view/advanced-filter/AdvancedFilterConfigurationBuilder';
import { Dispatch, SetStateAction, useContext } from 'react';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { TrackableTreeProperty } from '../../tree/TrackableTreeProperty';
import CohortColor from '../cohort/CohortColor';

export default function PropertyLegendCohortList(props: PropertyLegendCohortListProps) {
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const colorMap = Object.values(CohortColor.rgbs);

  function createAdvancedFilterFromSigmaBoundary(boundary: SigmaBoundary) {
    if (!boundary) return;

    const builder = AdvancedFilterConfigurationBuilder.from(urlContext.getAdvancedFilterConfiguration());
    if (builder.listAllActiveProperties().includes(props.selectedProperty)) {
      builder.editCohort(props.selectedProperty as TrackableTreeProperty, boundary);
    } else {
      builder.addCohort(props.selectedProperty as TrackableTreeProperty, [boundary]);
    }

    urlContext.setAdvancedFilterConfiguration(builder.build());
  }

  const getOpacity = (boundary: SigmaBoundary) => {
    if (!props.selectedPropertyCohortBoundary) return 1;
    return boundary !== props.selectedPropertyCohortBoundary ? 0.5 : 1;
  };

  return (
    <div
      className={styles.boundariesContainer}
      onMouseLeave={() => props.setSelectedPropertyCohortBoundary(null)}
    >
      {Object.values(SigmaBoundary).map((boundary, idx) =>
        (
          <div
            key={idx}
            className={styles.sigmaLabel}
            style={{ opacity: getOpacity(boundary) }}
            onMouseOver={() => props.setSelectedPropertyCohortBoundary(boundary)}
            onClick={() => createAdvancedFilterFromSigmaBoundary(boundary)}
          >
            <div
              className={styles.circle}
              style={{ backgroundColor: `rgb(${colorMap[idx]})` }}
            />
            <div className={styles.capitalizeFirstLetter}>
              {t('treeList.advancedFiltering.sigmaBoundaries.' + boundary)}
            </div>
          </div>
        ))}
    </div>
  );
}

interface PropertyLegendCohortListProps {
  selectedProperty: string,
  selectedPropertyCohortBoundary: SigmaBoundary | null,
  setSelectedPropertyCohortBoundary: Dispatch<SetStateAction<SigmaBoundary | null>>
}
