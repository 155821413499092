import { AccountType } from './AccountType';
import { OrganizationDto } from '../organization/OrganizationDto';

export enum AccountStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export interface AccountDto {
  id: string,
  type: AccountType,
  organization: OrganizationDto,
  status: AccountStatus
}
