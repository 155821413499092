import { NavArrowRight, Tree } from 'iconoir-react';
import TaskTemplate from './TaskTemplate';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { useNavigate } from 'react-router-dom';
import { useAreaFilteringAsSearchParam } from './useAreaFilteringAsSearchParam';
import { JobColumnName } from './JobColumnName';
import jobColumnColorMap from '../jobColumnColorMap';

export default function JobColumn(props: JobColumnProps) {
  const { t } = useTranslation();
  const title = t(`taskManager.jobColumnTitles.${props.columnName}`);
  const headerColor = `bg-${jobColumnColorMap[props.columnName].primary}`;

  return (
    <div className="rounded-xl overflow-hidden w-60">
      <h5 className={`py-2 px-4 text-base font-medium flex justify-between ${headerColor}`}>
        <span>{title}</span>
        <span className="flex items-center gap-2">
          {props.totalTreeCountByArea !== undefined
            ? <span>{props.totalTreeCountByArea}</span>
            : <Spinner inLine={true} size={0.4} />
          } <Tree fontSize={10}/>
        </span>
      </h5>
      {(props.taskTemplates.length > 0 || props.columnName === JobColumnName.Inspection) &&
      <ul className="rounded-b-xl bg-outer-space-900 flex flex-col gap-4 p-4 max-h-[65vh] overflow-y-auto">
        {props.taskTemplates.map((taskTemplate, index) => (
          <li key={index}>
            <Card
              taskTemplate={taskTemplate}
              templatesLoading={props.templatesLoading}
              missingAreaSelection={props.missingAreaSelection}
            />
          </li>
        ))}
        { props.columnName === JobColumnName.Inspection && <li>
          <div className="p-4 bg-outer-space-700 rounded-lg">
            <h6 className="font-medium">{t('taskManager.notAssigned')}</h6>
            <div className="flex items-center justify-between mt-3">
              <span className="flex items-center gap-1 font-bold text-2xl">
                {props.notAssignedCount !== undefined
                  ? <span>{props.notAssignedCount}</span>
                  : <Spinner inLine={true} size={0.4} />
                } <Tree fontSize={14} /></span>
            </div>
          </div>
        </li>}
      </ul>}
    </div>
  );
}

interface JobColumnProps {
  columnName: JobColumnName,
  taskTemplates: TaskTemplate[],
  templatesLoading: boolean,
  notAssignedCount: number,
  totalTreeCountByArea: number,
  missingAreaSelection: boolean
}

function Card(props: CardProps) {
  const navigate = useNavigate();
  const areaFilteringAsSearchParam = useAreaFilteringAsSearchParam();

  return (
    <div className="p-4 bg-outer-space-700 rounded-lg">
      <h6 className="font-medium">{props.taskTemplate.name}</h6>
      <div className="flex items-center justify-between mt-3">
        <span className="flex items-center gap-1 font-bold text-2xl">
          {!props.templatesLoading
            ? <span>{props.taskTemplate.trees.length}</span>
            : <Spinner inLine={true} size={0.4} />
          } <Tree fontSize={14} /></span>
        <FunctionButton
          className="rounded-[50%] border border-outer-space-400 flex items-center justify-center p-1.5"
          variant={'rounded'}
          onClick={() => navigate({
            pathname: `/organizations/${props.taskTemplate.organizationId}/task-manager/create/${props.taskTemplate.id}`,
            search: areaFilteringAsSearchParam
          }
          )}
          disabled={props.missingAreaSelection || props.templatesLoading || props.taskTemplate.trees.length === 0}
        >
          <NavArrowRight className="w-5 h-5" />
        </FunctionButton>
      </div>
    </div>
  );
}

interface CardProps {
  taskTemplate: TaskTemplate,
  templatesLoading: boolean,
  missingAreaSelection: boolean
}
