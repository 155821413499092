import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';

import styles from './Signup.module.scss';
import SignupScreen from '../../components/Signup/SignupScreen';
import { SignUpDto } from '../../registration/SignUpDto';
import { RegistrationService } from '../../registration/RegistrationService';

export function Signup() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [firstName, ...lastName] = (searchParams.get('name') ?? '').split(' ');
  const [hasLinkExpired, setExpiredLinkState] = useState(false);
  const accessToken = searchParams.get('accessToken') ?? '';
  const email = searchParams.get('email') ?? '';
  const onSignup = useCallback(
    (dto: SignUpDto) =>
      RegistrationService.make()
        .signUp(accessToken, dto)
        .then(() => navigate(`/?signedUpUsing=${encodeURIComponent(email)}`))
        .catch(() => setExpiredLinkState(true)),
    [accessToken, navigate, email]
  );

  return (
    <div className={styles.container}>
      <SignupScreen
        organizationName={searchParams.get('organizationName') ?? ''}
        organizationLogoUrl={searchParams.get('organizationLogoUrl') ?? ''}
        firstName={firstName}
        lastName={lastName.join(' ')}
        email={email}
        hasLinkExpired={hasLinkExpired}
        onSignup={onSignup}
      />
    </div>
  );
}
