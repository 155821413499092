import styles from '../../Inventory/BaseAttributes.module.scss';
import DetailedTree from '../../../../../tree/DetailedTree';
import ConclusionsAssessment from './ConclusionsAssessment';
import StructuralAssessmentMetricsDataGroup from './SAMetricsDataGroup';
import LimbsDataGroup from './LimbsDataGroup';
import ObservationsDataGroup from '../../../DataPanel/DataTabs/RiskTab/ObservationsDataGroup';

export default function RiskTab(props: RiskTabProps) {
  return (
    <div className={styles.content}>
      <ObservationsDataGroup
        tree={props.tree}
        singleColumn={props.singleColumn}
      />
      <StructuralAssessmentMetricsDataGroup
        tree={props.tree}
        singleColumn={props.singleColumn}
      />
      <LimbsDataGroup
        tree={props.tree}
        singleColumn={props.singleColumn}
        organizationId={props.organizationId}
      />
      <ConclusionsAssessment
        tree={props.tree}
        singleColumn={props.singleColumn}
        organizationId={props.organizationId}
      />
    </div>
  );
}

interface RiskTabProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organizationId: string
}
