import styles from './TranslationsTab.module.scss';
import { UserService } from '../../../users/UserService';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useContext, useMemo } from 'react';
import { MultiSelect } from '../../../components/Settings/Select/Select';
import { LanguageInfo } from '../../../services/TranslationService';
import { useQuery, useQueryClient } from 'react-query';
import { StaticQueryKeys } from '../../../StaticQueryKeys';

export default function TranslationsTab() {
  const { translationService } = useContext(DependencyInjectionContext);
  const userService = UserService.make();

  const queryClient = useQueryClient();
  const { data: users } = useQuery(StaticQueryKeys.USER_EMAIL_LIST, () => userService.listAllEmails());
  const { data: availableLanguages } = useQuery(StaticQueryKeys.AVAILABLE_LANGUAGES, () => translationService.getAvailableLanguages());

  const languages: (LanguageInfo & { translators: string[] })[] = useMemo(() => {
    if (!availableLanguages) return [];
    const addTranslatorsToLanguage = (it: LanguageInfo) => {
      const translators = users?.filter(user => user.isTranslatorFor!(it.code)).map(user => user.email as string) || [];
      return { code: it.code, translators, statistics: it.statistics };
    };
    return availableLanguages.map(addTranslatorsToLanguage);
  }, [users, availableLanguages]);

  const handleMultiSelectChange = async (languageCode: string, updatedTranslatorList: string[]) => {
    if (!users) return;
    const oldList = languages.find(it => it.code === languageCode)?.translators;
    if (!oldList) return;
    if (oldList.length < updatedTranslatorList.length) {
      const newTranslatorEmail = updatedTranslatorList.find(it => !oldList.includes(it));
      const user = users.find(it => it.email === newTranslatorEmail);
      if (!user?.id || !user?.translatorFor) return;
      await userService.update(user.id, { translatorFor: [ ...user.translatorFor, languageCode] });
    }
    if (oldList.length > updatedTranslatorList.length) {
      const removedTranslatorEmail = oldList.find(it => !updatedTranslatorList.includes(it));
      const user = users.find(it => it.email === removedTranslatorEmail);
      if (!user?.id || !user?.translatorFor) return;
      await userService.update(user.id, { translatorFor: user.translatorFor.filter(it => it !== languageCode) });
    }
    await queryClient.invalidateQueries(StaticQueryKeys.USER_EMAIL_LIST);
  };

  return (
    <div className={styles.container}>
      <div>
        <table>
          <thead>
            <tr>
              <td></td>
              <td>Translators</td>
              <td>Progress</td>
            </tr>
          </thead>
          <tbody>
            {languages.map(language =>
              <tr key={language.code}>
                <td className={styles.languageCode}>
                  <h3>{language.code}:</h3>
                </td>
                <td>
                  <div className={styles.selectContainer}>
                    <MultiSelect
                      value={language.translators}
                      options={users?.map(it => ({ label: it.email!, value: it.email! })) || []}
                      onChange={it => handleMultiSelectChange(language.code, it)}
                      label={''}
                      darkBackgroundOnFocus={true}
                    />
                  </div>
                </td>
                <td>
                  {Math.floor(language.statistics.progress)}%
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
