import Input from '../../components/UI/Input/Input';
import { FormEvent, useContext, useState } from 'react';
import Toggle from '../../components/UI/Toggle/Toggle';
import Dropdown from '../../components/UI/Dropdown/Dropdown';
import { Plus } from 'iconoir-react';
import { CreateOrganizationDto } from '../../organization/CreateOrganizationDto';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import styles from './LegacyCreateOrganization.module.scss';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { StaticQueryKeys } from '../../StaticQueryKeys';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../components/UI/Toast/DismissibleToast';

const currencyItems = [
  {
    id: 'EUR',
    translationKey: 'EUR'
  },
  {
    id: 'USD',
    translationKey: 'USD'
  },
  {
    id: 'HUF',
    translationKey: 'HUF'
  }
];

export default function LegacyCreateOrganization() {
  const { t } = useTranslation();
  const { organizationService } = useContext(DependencyInjectionContext);
  const queryClient = useQueryClient();
  const [organization, setOrganization] = useState<Partial<CreateOrganizationDto>>({
    name: '',
    tseProjectName: '',
    isDemo: false,
    isMetric: false,
    currency: 'EUR',
    defaultWindSpeed: 80,
    serviceDeskUrl: ''
  });
  const [image, setImage] = useState<File | null>(null);

  const fileReader = new FileReader();

  fileReader.onload = () => {
    const file = JSON.parse(fileReader.result!.toString());
    const geojson = file.type === 'MultiPolygon' ? file : file.geometries[0];

    setOrganization(prev => ({
      ...prev,
      boundaries: JSON.stringify(geojson)
    }));
  };

  const createOrganization = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
      const orgFormData = new FormData();
      for (const key in organization) {
        orgFormData.append(key, organization[key]);
      }
      orgFormData.append('image', image!);
      try {
        await organizationService.create(orgFormData);
        await queryClient.invalidateQueries(StaticQueryKeys.ACCOUNTS);
        dismissibleSuccessToast('Organization created successfully');
      } catch (e: unknown) {
        dismissibleErrorToast('Operation failed: ' + (e as Error).message);
      }
    }
  };

  return (
    <form onSubmit={createOrganization} className={styles.screenContainer}>
      <h1>{t('settings.createOrganization.title')}</h1>

      <Input
        className={styles.inputContainer}
        label={t('settings.createOrganization.name')}
        value={organization.name}
        onValueChange={value => setOrganization(prev => ({ ...prev, name: value }))}
        required={true}
      />

      <div>
        <div>{t('settings.createOrganization.selectLogo')}</div>
        <input
          type={'file'}
          name="image"
          onChange={event => setImage(event.target.files![0])} />
      </div>

      <Input
        className={styles.inputContainer}
        label={t('settings.createOrganization.tseProjectName')}
        value={organization.tseProjectName}
        onValueChange={value => setOrganization(prev => ({ ...prev, tseProjectName: value }))}
        required={true}
      />

      <div>
        <div>{t('settings.createOrganization.selectGeojson')}</div>
        <input
          type={'file'}
          required={true}
          onChange={e => fileReader.readAsText(e.target.files![0])} />
      </div>

      <div className={styles.toggleContainer}>
        <span>{t('settings.createOrganization.metric')}</span>
        <Toggle
          checked={organization.isMetric!}
          onChange={() => setOrganization(prev => ({ ...prev, isMetric: !prev.isMetric }))} />
      </div>
      <div className={styles.toggleContainer}>
        <span>{t('settings.createOrganization.demo')}</span>
        <Toggle
          checked={organization.isDemo!}
          onChange={() => setOrganization(prev => ({ ...prev, isDemo: !prev.isDemo }))} />
      </div>

      <div>
        <div>{t('settings.createOrganization.currency')}</div>
        <Dropdown
          value={currencyItems.find(it => it.id === organization.currency)}
          onSelect={value => setOrganization(prev => ({ ...prev, currency: value.id }))}
          items={currencyItems} />
      </div>

      <Input
        className={styles.inputContainer}
        label={t('settings.createOrganization.windspeed')}
        value={organization.defaultWindSpeed}
        onValueChange={value => setOrganization(prev => ({ ...prev, defaultWindSpeed: Number(value) }))}
        type={'number'}
        required={true}
      />

      <Input
        className={styles.inputContainer}
        label={t('settings.createOrganization.serviceDeskUrl')}
        value={organization.serviceDeskUrl}
        onValueChange={value => setOrganization(prev => ({ ...prev, serviceDeskUrl: value }))}
        required={true}
      />

      <button
        type={'submit'}
        className={styles.createButton}>
        <Plus />
        {t('settings.createOrganization.cta')}
      </button>
    </form>
  );
}
