import { useTranslation } from 'react-i18next';

import { Member } from '../../../organization/Member';
import MemberListRow from './MemberListRow';
import MemberListColumn from './MemberListColumn';
import styles from './MemberView.module.scss';
import Tag from '../Tag/Tag';
import Select, { PlainMultiSelect } from '../Select/Select';
import { Interest } from '../../../account/Interest';
import { FloppyDisk, MoreHoriz } from 'iconoir-react';
import { Popover } from 'react-tiny-popover';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { MemberStatus } from '../../../organization/MemberStatus';
import { AccountStatus } from '../../../account/AccountDto';
import Modal, { ModalContent, ModalControls, ModalFooter, ModalTitle } from '../../Modal/Modal';
import { Account } from '../../../account/Account';
import { AccountType } from '../../../account/AccountType';
import LegacyButton, { ButtonWithIcon } from '../../UI/Button/LegacyButton';
import { RegistrationService } from '../../../registration/RegistrationService';
import { useCurrentAccount } from '../../../account/useAccounts';
import InvitationDto from '../../../registration/InvitationDto';
import { toast } from 'react-hot-toast';

export default function MemberView(props: MemberViewProps) {
  const { t } = useTranslation();

  const availableAccountTypes = useMemo(
    () =>
      Object.values(AccountType).map(it => ({ value: it, label: t(`settings.members.${it}`) }))
        .filter(({ value }) => props.currentAccount.canManage(value))
    , [props.currentAccount, t]
  );

  const [isAccountTypeEditorVisible, setAccountTypeEditorVisibility] = useState(false);
  const closeAccountTypeEditor = useCallback(() => setAccountTypeEditorVisibility(false), []);
  const openAccountTypeEditor = useCallback(() => setAccountTypeEditorVisibility(true), []);
  const [modifiedAccountType, setModifiedAccountType] = useState<AccountType | null>(props.member.accountType);

  const onAccountTypeChange = (event: FormEvent) => {
    event.preventDefault();
    props.onTypeChange(modifiedAccountType!);
    closeAccountTypeEditor();
  };

  const availableInterests = [
    { value: Interest.QA, label: t(`interests.${Interest.QA}`) },
    { value: Interest.Business, label: t(`interests.${Interest.Business}`) },
    { value: Interest.TreeInspector, label: t(`interests.${Interest.TreeInspector}`) },
    { value: Interest.GreeneryOfficer, label: t(`interests.${Interest.GreeneryOfficer}`) },
    { value: Interest.TreeHealthExpert, label: t(`interests.${Interest.TreeHealthExpert}`) }
  ];

  const onInterestChange = (updatedInterests: Interest[]) => props.onInterestChange(updatedInterests);
  const onStatusChange = (status: AccountStatus) => props.onStatusChange(status);
  const isCurrentAccount = useMemo(
    () => props.member.isSameAs(props.currentAccount),
    [props.member, props.currentAccount]
  );
  const isEditable = useMemo(
    () => props.currentAccount.canManage(props.member.accountType) && props.member.isModifiableByAdminsAndOwners(),
    [props.member, props.currentAccount]
  );

  return (
    <>
      <MemberListRow isInactive={props.member.isInactive()}>
        <MemberListColumn>
          <div className={styles.firstColumn}>
            <div>
              <strong>
                {props.member.name}
                {isCurrentAccount && ` (${t('settings.members.you')})`}
              </strong>
            </div>

            <div>{props.member.email}</div>

            <div>
              {!isEditable && (
                <ul className={styles.interests}>
                  {props.member.interests.map((interest, index) => (
                    <li key={index}>
                      <Tag variant="secondary">{t(`interests.${interest}`)}</Tag>
                    </li>
                  ))}
                </ul>
              )}

              {isEditable && (
                <PlainMultiSelect
                  onChange={onInterestChange}
                  value={props.member.interests}
                  options={availableInterests}
                  placeholder={t('settings.members.interestEditorPlaceholder')}
                />
              )}
            </div>
          </div>
        </MemberListColumn>

        <MemberListColumn>{props.member.accountType}</MemberListColumn>

        <MemberListColumn>{props.member.lastLogin?.toLocaleDateString() ?? '-'}</MemberListColumn>

        <MemberListColumn>{props.member.memberSince?.toLocaleDateString() ?? '-'}</MemberListColumn>

        <MemberListColumn>{props.member.status}</MemberListColumn>

        {isEditable && !isCurrentAccount ? (
          <MemberListColumn>
            <ContextMenu
              status={props.member.status}
              onStatusChange={onStatusChange}
              triggerAccountTypeEditor={openAccountTypeEditor}
              showAccountTypeEditor={props.currentAccount.canManage(props.member.accountType)}
            />
          </MemberListColumn>
        )
          :
          <MemberListColumn>
            <ResendInvitationMenu
              invitation={props.member}
            />
          </MemberListColumn>
        }
      </MemberListRow>

      <Modal
        isVisible={isAccountTypeEditorVisible}
        onHide={closeAccountTypeEditor}
        className={styles.fixedModal}>
        <form onSubmit={onAccountTypeChange}>
          <ModalTitle>
            {t('settings.members.editAccountTypeModalTitle', {
              name: props.member.name,
              currentAccountType: t(`account.type.${props.member.accountType}`)
            })}
          </ModalTitle>

          <ModalContent>
            <Select
              value={modifiedAccountType}
              options={availableAccountTypes}
              label={t('settings.members.accountTypeLabel')}
              onChange={setModifiedAccountType}
            />
          </ModalContent>

          <ModalFooter>
            <ModalControls>
              <LegacyButton
                type="button"
                onClick={closeAccountTypeEditor}
                variant="secondary">
                {t('settings.members.cancel')}
              </LegacyButton>

              <ButtonWithIcon variant="tertiary" icon={<FloppyDisk />}>
                {t('settings.members.saveAccountType')}
              </ButtonWithIcon>
            </ModalControls>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}

function ContextMenu(props: ContextMenuProps) {
  const { t } = useTranslation();

  const [isOpen, setVisibility] = useState(false);
  const isActive = props.status === MemberStatus.Active;

  const show = () => setVisibility(true);
  const close = () => setVisibility(false);

  const toggleStatus = () => {
    if (isActive) {
      props.onStatusChange(AccountStatus.Inactive);
    } else {
      props.onStatusChange(AccountStatus.Active);
    }
    close();
  };

  const showAccountTypeEditor = () => {
    close();
    props.triggerAccountTypeEditor();
  };

  return (
    <Popover
      isOpen={isOpen}
      onClickOutside={close}
      content={
        <ul className={styles.contextMenu}>
          <li>
            <button
              className={styles.contextMenuButton}
              type="button"
              onClick={toggleStatus}>
              {isActive ? t('settings.members.deactivate') : t('settings.members.activate')}
            </button>
          </li>

          {props.showAccountTypeEditor && (
            <li>
              <button
                className={styles.contextMenuButton}
                type="button"
                onClick={showAccountTypeEditor}>
                {t('settings.members.changeAccountType')}
              </button>
            </li>
          )}
        </ul>
      }
      positions={['bottom']}
      align="end"
      containerStyle={{ zIndex: '99999' }}
    >
      <button
        type="button"
        className={styles.contextMenuTrigger}
        onClick={show}>
        <MoreHoriz />
      </button>
    </Popover>
  );
}

interface ContextMenuProps {
  status: MemberStatus,
  onStatusChange: (status: AccountStatus) => unknown,
  showAccountTypeEditor: boolean,
  triggerAccountTypeEditor: () => unknown
}

function ResendInvitationMenu(props: ResendInvitationMenuProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();

  const [isOpen, setVisibility] = useState(false);

  const show = () => setVisibility(true);
  const close = () => setVisibility(false);

  const resendInvitation = async () => {
    await RegistrationService.make().invite(organization.id, props.invitation);
    toast.success(t('settings.members.invitationResent'));
    close();
  };

  return (
    <Popover
      isOpen={isOpen}
      onClickOutside={close}
      content={
        <ul className={styles.contextMenu}>
          <li>
            <button
              className={styles.contextMenuButton}
              type="button"
              onClick={resendInvitation}
            >
              {t('settings.members.resendInvitation')}
            </button>
          </li>
        </ul>
      }
      positions={['bottom']}
      align="end"
      containerStyle={{ zIndex: '99999' }}
    >
      <button
        type="button"
        className={styles.contextMenuTrigger}
        onClick={show}>
        <MoreHoriz />
      </button>
    </Popover>
  );
}

interface ResendInvitationMenuProps {
  invitation: InvitationDto
}

interface MemberViewProps {
  member: Member,
  onInterestChange: (updatedInterests: Interest[]) => unknown,
  currentAccount: Account,
  onStatusChange: (status: AccountStatus) => unknown,
  onTypeChange: (type: AccountType) => unknown
}
