import mapboxgl from 'mapbox-gl';

export default function calculateMaxBounds (coordinates: [number, number][][][]) {
  const bounds = coordinates
    .flatMap(position => position as [number, number][][])
    .flatMap(position => position as [number, number][])
    .reduce((bounds, position) => bounds.extend(position), new mapboxgl.LngLatBounds());

  return [
    bounds.getSouthWest().lng,
    bounds.getSouthWest().lat,
    bounds.getNorthEast().lng,
    bounds.getNorthEast().lat
  ];
}
