import { ReactNode } from 'react';

import styles from './Tag.module.scss';

export default function Tag({ children, variant = 'primary' }: TagProps) {
  const className = [styles.tag, styles[variant]].join(' ');

  return <span className={className}>{children}</span>;
}

interface TagProps {
  children: ReactNode,
  variant?: 'primary' | 'secondary'
}
