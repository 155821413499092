import SlideInPanel from '../create/SlideInPanel';
import { EditPencil, Trash, Tree, WarningTriangle, Xmark } from 'iconoir-react';
import { ContextMenu } from '../../../components/UI/ContextMenu/ContextMenu';
import { ContextMenuTrigger } from '../../../components/UI/ContextMenu/ContextMenuTrigger';
import { AssigneeSelectorButton, AssigneeSelectorTrigger } from './AssigneeSelector';
import { ContextMenuContent } from '../../../components/UI/ContextMenu/ContextMenuContent';
import Input, { DateInput } from '../../../components/UI/Input/Input';
import { Task, TaskStatus, TaskUpdateDto } from '../../../task-manager/Task';
import React, { useContext, useState } from 'react';
import { usePossibleAssignees } from '../../../task-manager/usePossibleAssignees';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import AbsoluteModal from '../../../components/Modal/absolute-modal/AbsoluteModal';
import Button, { ButtonVariant } from '../../../components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
import TaskStatusPill from './TaskStatusPill';
import TaskEvents from './TaskEvents';
import { TooltipForEllipsis } from '../../../components/UI/Tooltip/Tooltip';
import { useCurrentAccount } from '../../../account/useAccounts';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../../components/UI/Toast/DismissibleToast';

export default function TaskSlideIn(props: TaskSlideInProps) {
  const { possibleAssignees } = usePossibleAssignees(props.task.organizationId);
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();
  const account = useCurrentAccount();

  const [editedTask, setEditedTask] = useState<TaskUpdateDto>({
    name: props.task.name,
    assigneeUserId: props.task.assignee?.id,
    status: props.task.status,
    deadline: props.task.deadline
  });
  const [editing, setEditing] = useState(false);
  const [deletingTask, setDeletingTask] = useState(false);
  const [loading, setLoading] = useState(false);
  const saveTask = async () => {
    setEditedTask(prev => ({
      ...prev,
      name: prev.name?.trim() || undefined
    }));
    await taskManagerService.updateTask(props.task.organizationId, props.task.id, editedTask);
    setEditing(false);
  };

  const handleDeleteTask = async (organizationId: string, taskId: string) => {
    setLoading(true);
    try {
      await taskManagerService.deleteTask(organizationId, taskId);
      dismissibleSuccessToast(t('taskManager.taskDeletedSuccessfully'));
    } catch (e) {
      dismissibleErrorToast(t('genericError'));
    } finally {
      setDeletingTask(false);
      setLoading(false);
    }
  };

  const getProgressBarColor = () => {
    if (props.task.status === TaskStatus.ARCHIVED) return 'bg-success-600';
    const ratio = props.task.progress / props.task.treeIds.length * 100;
    if (ratio < 50) return 'bg-danger-600';
    if (ratio < 75) return 'bg-summer-yellow-600';
    return 'bg-success-600';
  };
  const progressBarColor = getProgressBarColor();

  return (
    <>
      <SlideInPanel isOpen={true} className="w-full max-w-[640px] twp overflow-y-auto">
        <div>
          <section className="p-3 pr-8 min-h-24 flex sticky top-0 bg-outer-space-700 border-b border-outer-space-500 mb-4">
            <div className='flex-none w-10 flex mt-6 justify-center'>
              <Xmark
                className="w-5 h-5 cursor-pointer"
                onClick={() => props.setSlideInOpen(false)}
                color='white'
              />
            </div>
            <div className="flex w-full justify-between items-center">
              <div>
                <h2 className="text-2xl font-semibold max-w-72">
                  {!editing && (<span className="whitespace-nowrap">
                    <TooltipForEllipsis overlay={editedTask.name || 'Unnamed task'}>
                      <div className="w-full truncate">
                        {editedTask.name || 'Unnamed task'}
                      </div>
                    </TooltipForEllipsis>
                  </span>)}
                  {editing && (
                    <Input
                      className="mb-2 rounded-full font-normal"
                      label=""
                      value={editedTask.name || ''}
                      onChange={e => setEditedTask(prev => ({ ...prev, name: e.target.value }))}
                    />
                  )}
                </h2>
                <span className="text-greehill-00">{props.task.code}</span>
              </div>
              <div className="flex items-center gap-4">
                {
                  account.canCreateTaskManagerJobs() ?
                    editing ? (
                      <>
                        <button
                          type="button"
                          className="flex gap-2 items-center rounded-full bg-greehill-600 px-4 py-2 text-sm text-greehill-00 shadow-sm ring-0 ring-inset hover:bg-greehill-500"
                          onClick={saveTask}
                        >
                          {t('save')}
                        </button>
                        <button
                          type="button"
                          className="flex gap-2 items-center rounded-full bg-outer-space-700 px-4 py-2 text-sm text-greehill-00 shadow-sm ring-1 ring-inset ring-outer-space-400 hover:bg-outer-space-600"
                          onClick={() => {
                            setEditing(false);
                            setEditedTask({
                              name: props.task.name,
                              assigneeUserId: props.task.assignee?.id,
                              status: props.task.status,
                              deadline: props.task.deadline
                            });
                          }}
                        >
                          {t('cancel')}
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="flex gap-2 items-center rounded-full bg-outer-space-700 px-4 py-2 text-sm text-greehill-00 shadow-sm ring-1 ring-inset ring-outer-space-400 hover:bg-outer-space-600"
                        onClick={() => setEditing(true)}
                        disabled={![TaskStatus.DRAFT, TaskStatus.REVOKED].includes(props.task.status)}
                      >
                        <EditPencil className="w-4 h-4"/>
                        {t('taskManager.editTask')}
                      </button>
                    )
                    : ''
                }
                <div>
                  <div>
                    {
                      account.canCreateTaskManagerJobs() ?
                        <button
                          type="button"
                          className="flex gap-2 items-center rounded-full bg-outer-space-700 px-2 py-2 text-sm text-danger-500 shadow-sm ring-1 ring-inset ring-outer-space-400 hover:bg-outer-space-600"
                          onClick={() => setDeletingTask(true)}
                        >
                          <Trash className="w-5 h-5"/>
                        </button> : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="text-greehill-00 pb-4 px-14 border-b border-outer-space-500 mb-4">
            <h3 className="text-lg font-normal text-greehill-00">{t('taskManager.details')}</h3>
            <table>
              <tbody className="divide-y divide-outer-space-500">
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-outer-space-200 sm:pl-0">
                    {t('taskManager.numberOfTreesInTask')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-greehill-00 w-full">
                    <div className="flex items-center gap-1">{props.task.treeIds.length} <Tree fontSize={14}/></div>
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-outer-space-200 sm:pl-0">
                    {t('taskManager.taskTable.area')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-greehill-00">
                    <TooltipForEllipsis overlay={props.task.area}>
                      <div className="truncate max-w-72">{props.task.area}</div>
                    </TooltipForEllipsis>
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-outer-space-200 sm:pl-0">
                    {t('taskManager.taskTable.status')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-greehill-00">
                    <TaskStatusPill status={props.task.status}/>
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-outer-space-200 sm:pl-0">
                    {t('taskManager.taskTable.type')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-greehill-00">
                    {props.task.type}
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-outer-space-200 sm:pl-0">
                    {t('taskManager.taskTable.assignee')}
                  </td>
                  <td className="whitespace-nowrap px-3 text-sm text-greehill-00">
                    {!editing && (possibleAssignees.find(it => it.id === editedTask?.assigneeUserId)?.getName() || t('taskManager.taskTable.unassigned'))}
                    {
                      editing &&
                    <ContextMenu className="inline-flex">
                      <ContextMenuTrigger className="inline-flex">
                        <AssigneeSelectorTrigger unassigned={!props.task.assignee?.getName()}>
                          {possibleAssignees.find(it => it.id === editedTask?.assigneeUserId)?.getName() || t('taskManager.taskTable.unassigned')}
                        </AssigneeSelectorTrigger>
                      </ContextMenuTrigger>

                      <ContextMenuContent className="rounded-lg max-w-72 overflow-hidden">
                        <ul className="flex flex-col gap-1.5 py-2 max-h-80 overflow-y-auto">
                          {possibleAssignees.map(it => (
                            <li key={it.id}>
                              <AssigneeSelectorButton
                                active={editedTask?.assigneeUserId === it.id}
                                onClick={() => setEditedTask(prev => ({ ...prev, assigneeUserId: it.id }))}
                              >
                                {it.getName()}
                              </AssigneeSelectorButton>
                            </li>
                          ))}

                          <li>
                            <AssigneeSelectorButton
                              active={!editedTask?.assigneeUserId}
                              onClick={() => setEditedTask(prev => ({
                                ...prev,
                                assigneeUserId: null
                              }))}>
                              {t('taskManager.taskTable.unassigned')}
                            </AssigneeSelectorButton>
                          </li>
                        </ul>
                      </ContextMenuContent>
                    </ContextMenu>
                    }
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-outer-space-200 sm:pl-0">
                    {t('taskManager.taskTable.assignedBy')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-greehill-00">
                    {props.task.createdBy?.getName() || '-'}
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-outer-space-200 sm:pl-0">
                    {t('taskManager.taskTable.deadline')}
                  </td>
                  <td className="whitespace-nowrap px-3 text-sm text-greehill-00">
                    {!editing && (editedTask.deadline?.toLocaleDateString() || '-')}
                    {
                      editing &&
                      <DateInput
                        onValueChange={date => setEditedTask(prev => ({ ...prev, deadline: date }))}
                        value={editedTask.deadline ?? null}
                        futureDateOnly={true}
                        className="[&>span]:rounded-full [&>span]:border-outer-space-400 [&>span]:w-1/2 [&>span]:py-2 [&>span]:px-4"
                      />
                    }
                  </td>
                </tr>

                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-outer-space-200 sm:pl-0">
                    {t('taskManager.taskTable.progress')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-greehill-00">
                    <div className="relative w-36 mr-auto">
                      <progress
                        value={props.task.status === TaskStatus.ARCHIVED ? 100 : props.task.progress}
                        max={props.task.status === TaskStatus.ARCHIVED ? 100 : props.task.treeIds.length}
                        className={`w-full h-5
                        [&::-webkit-progress-bar]:rounded-full
                        [&::-webkit-progress-value]:rounded-full
                        [&::-webkit-progress-bar]:bg-outer-space-500
                        [&::-webkit-progress-value]:${progressBarColor}`}
                      ></progress>
                      <div
                        className="absolute top-[50%] inline left-[50%] translate-x-[-50%] translate-y-[-50%]">
                        {props.task.status === TaskStatus.ARCHIVED ? 100 : (props.task.progress / props.task.treeIds.length * 100).toFixed(0)}%
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <TaskEvents task={props.task} organizationId={props.organizationId} />
        </div>
      </SlideInPanel>
      <AbsoluteModal isVisible={deletingTask} onHide={() => setDeletingTask(false)}>
        <section
          className="bg-outer-space-700 rounded-md p-10 text-greehill-00 flex flex-col gap-5 justify-center items-center">
          <div className="rounded-full bg-danger-600 w-fit p-3 flex justify-center items-center">
            <Trash />
          </div>
          <div className="text-2xl font-semibold">{t('taskManager.deleteTaskTitle')}</div>
          <span>
            {t('taskManager.areYouSureYouWantToDelete')}
          </span>
          <div className="flex gap-5 w-full mt-3">
            <Button
              variant={ButtonVariant.Secondary}
              onClick={() => setDeletingTask(false)}
              disabled={loading}
            >
              {t('taskManager.no')}
            </Button>
            <Button
              variant={ButtonVariant.PrimaryDanger}
              onClick={() => handleDeleteTask(props.organizationId, props.task!.id)}
              disabled={loading}
              className="flex justify-center"
            >
              {loading ? <Spinner inLine={true} size={0.4} /> : t('taskManager.yesDelete')}
            </Button>
          </div>
        </section>
      </AbsoluteModal>
    </>
  );
}

export interface TaskSlideInProps {
  organizationId: string,
  task: Task,
  setSlideInOpen: (value: boolean) => void
}
