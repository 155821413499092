import { ReactChild, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './Modal.module.scss';

export default function Modal({ children, isVisible, onHide, className, containerClassName = '', size = 'default', hideOnBackdropClick = true }: ModalProps) {
  if (!isVisible) {
    return <></>;
  }

  const modalClassNames = `${styles.modal} ${styles['size-' + size]} ${className}`;

  return (
    <ModalContainer containerClassName={containerClassName} onHide={onHide}>
      <>
        <div className={styles.overlay} onClick={hideOnBackdropClick ? onHide : () => {}} />

        <div
          role="dialog"
          aria-labelledby="modal-title"
          className={modalClassNames}
          data-testid="modal"
        >
          {children}
        </div>
      </>
    </ModalContainer>
  );
}

export function ModalTitle({ children }: { children: ReactNode }) {
  return (
    <div
      id="modal-title"
      data-testid="modal-title"
      className={styles.title}>
      {children}
    </div>
  );
}

export function ModalContent({ children }: { children: ReactNode }) {
  return (
    <div className={styles.content} data-testid="modal-content">
      {children}
    </div>
  );
}

export function ModalFooter({ children }: { children: ReactNode }) {
  return (
    <div className={styles.footer} data-testid="modal-footer">
      {children}
    </div>
  );
}

export function ModalControls({ children }: { children: ReactNode }) {
  return <div className={styles.controls}>{children}</div>;
}

function ModalContainer({ children, onHide, containerClassName = '' }: { children: ReactChild, onHide: () => unknown, containerClassName?: string }) {
  const [container] = useState(() => document.createElement('div'));

  container.classList.add(styles.container);
  if (containerClassName) {
    container.classList.add(containerClassName);
  }

  const closeOnEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onHide();
  };

  useEffect(() => {
    document.body.appendChild(container);
    document.body.style.overflow = 'hidden';
    container.tabIndex = 0;
    container.focus();
    container.addEventListener('keyup', closeOnEscapeKey);

    return () => {
      container.removeEventListener('keyup', closeOnEscapeKey);
      document.body.removeChild(container);
      document.body.removeAttribute('style');
    };
  }, [container]);

  return createPortal(children, container);
}

interface ModalProps {
  children: ReactNode,
  isVisible: boolean,
  onHide: () => unknown,
  className?: string,
  containerClassName?: string,
  size?: 'default' | 'wide' | 'extraWide' | 'auto' | 'vwWide',
  hideOnBackdropClick?: boolean
}
