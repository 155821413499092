import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { PlusCircle, Xmark } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import TaskTemplate from './TaskTemplate';
import React, { useState } from 'react';
import jobColumnColorMap from '../jobColumnColorMap';
import { JobColumnName } from './JobColumnName';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';

export default function TaskTemplateList(props: TaskTemplateListProps) {
  const { t } = useTranslation();
  const [jobColumnNameFilter, setJobColumnNameFilter] = useState<JobColumnName[]>(Object.values(JobColumnName));

  const handleFilterClick = (jobColumnName: JobColumnName) => {
    setJobColumnNameFilter(prev => {
      if (prev.includes(jobColumnName)) {
        return prev.filter(it => it !== jobColumnName);
      } else {
        return [...prev, jobColumnName];
      }
    });
  };

  const handleSelectAllClick = () => {
    setJobColumnNameFilter(prev => prev.length === Object.values(JobColumnName).length ? [] : Object.values(JobColumnName));
  };

  return (
    <div className="text-greehill-00 twp">
      <div className="m-6 flex justify-between">
        <div className="flex gap-5 items-center">
          <Xmark
            className="w-5 h-5 cursor-pointer"
            onClick={props.closeSlideInPanel}
            color="white"
          />
          <h2 className="text-2xl font-semibold">
            {t('taskManager.chooseTaskTemplate')}
          </h2>
        </div>

        <div className="flex items-center">
          <FunctionButton onClick={props.goToNewTaskType} variant="rounded" className="py-2 px-4">
            <div className="flex items-center gap-2">
              <PlusCircle height={20} width={20} />
              {t('taskManager.newTaskTemplate')}
            </div>
          </FunctionButton>
        </div>
      </div>
      <div className="m-6 border-b border-b-outer-space-500">
        <ul className="flex gap-4">
          {Object.values(JobColumnName).map(jobColumnName => (
            <li key={jobColumnName} className="my-6">
              <button className="flex gap-2" onClick={() => handleFilterClick(jobColumnName)}>
                <Checkbox checked={jobColumnNameFilter.includes(jobColumnName)} onChange={() => handleFilterClick(jobColumnName)} />
                <span>{t(`taskManager.jobColumnTitles.${jobColumnName}`)}</span>
              </button>
            </li>
          ))}
          <li className="my-6">
            <button className="flex gap-2" onClick={handleSelectAllClick}>
              <Checkbox
                checked={jobColumnNameFilter.length === Object.values(JobColumnName).length}
                onChange={handleSelectAllClick}
              />
              <span>{t('taskManager.jobColumnTitles.all')}</span>
            </button>
          </li>
        </ul>
      </div>
      <ul className="px-6 flex gap-3 flex-wrap">
        {props.taskTemplates.filter(it => jobColumnNameFilter ? jobColumnNameFilter.includes(it.jobColumnName) : true).map(taskTemplate => (
          <li key={taskTemplate.id} className="text-sm text-nowrap">
            <button
              onClick={() => props.editTaskTemplate(taskTemplate)}
              className={`
                twp p-1 rounded-full button py-2 px-4 w-full text-greehill-00
                bg-${jobColumnColorMap[taskTemplate.jobColumnName].secondary}
                border border-${jobColumnColorMap[taskTemplate.jobColumnName].primary}
              `}
            >
              {taskTemplate.rank} - {taskTemplate.name} - {t(`taskManager.jobColumnTitles.${taskTemplate.jobColumnName}`)}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

interface TaskTemplateListProps {
  taskTemplates: TaskTemplate[],
  closeSlideInPanel: () => void,
  goToNewTaskType: () => void,
  editTaskTemplate: (taskTemplate: TaskTemplate) => void
}
