import { useContext, useState } from 'react';

import styles from './TableDownload.module.scss';
import { FunctionButton } from '../../../../components/UI/Button/LegacyButton';
import { Download } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import FileDownloadButton, { downloadBlob } from '../components/FileDownloadButton';
import LegacyLazyTreeList from '../../LegacyLazyTreeList';
import { Property } from '../TableViewer';
import { useTracking } from '../../../../analytics/useTracking';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../../account/useAccounts';
import LazyTreeList from '../../LazyTreeList';

export default function TableDownload({ properties, treeList, windSpeed }: TableDownloadProps) {
  const { t } = useTranslation();
  const { treeService, urlContext } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();

  let selection = 'all';
  const [isOpen, setOpen] = useState(false);
  const { events, track } = useTracking();

  const selectAll = () => {
    selection = 'all';
    track(events.TABLE_DOWNLOAD_ALL);
  };

  const selectPinned = () => {
    selection = 'pinned';
    track(events.TABLE_DOWNLOAD_PINNED);
  };

  const selectUnpinned = () => {
    selection = 'notPinned';
    track(events.TABLE_DOWNLOAD_UNPINNED);
  };

  const handleShpExport = async e => {
    e.stopPropagation();
    setOpen(false);
    const response = await treeService.getShpExport(account.organization.id, urlContext.getReverseMASelection(), urlContext.getManagedAreaIds(), urlContext.getTreeFilterIds(), urlContext.getVisibleTableProperties() || []);
    const contentType = response.headers['content-type'];
    const fileName = response.headers['content-disposition'].match(/filename="([^"]*)"/)?.[1] || 'trees';

    downloadBlob(response.data, contentType, fileName);
  };

  return (
    <>
      <div className={`${isOpen ? styles.overlay : ''} ${styles.defaultOverlay}`} onClick={() => setOpen(false)}></div>
      <div className={styles.downloadContainer}>
        <FunctionButton
          className={styles.functionButton}
          icon={<Download/>}
          onClick={() => setOpen(!isOpen)}
          testId="table-download-button"
        >
          {t('treeList.download').toUpperCase()}
        </FunctionButton>
        {isOpen &&
          <FileDownloadButton
            header={properties}
            windSpeed={windSpeed}
            treesProvider={() => {
              setOpen(false);
              if (selection === 'all') return treeList.getAll();
              if (selection === 'notPinned') return treeList.getNotPinned();
              return treeList.getPinned();
            }}>
            <div className={styles.selectionContainer}>
              <button onClick={() => selectAll()}>{t('treeList.viewDownloadDropdown.downloadAll')}</button>
              <button onClick={() => selectPinned()}>{t('treeList.viewDownloadDropdown.downloadPinned')}</button>
              <button
                onClick={() => selectUnpinned()}>{t('treeList.viewDownloadDropdown.downloadNotPinned')}</button>
              <button onClick={handleShpExport}>{t('treeList.viewDownloadDropdown.downloadAllShp')}</button>
            </div>
          </FileDownloadButton>
        }
      </div>
    </>
  );
}

interface TableDownloadProps {
  properties: Property[],
  treeList: LazyTreeList | LegacyLazyTreeList,
  windSpeed: number
}
