import styles from './Toggle.module.scss';
import { ReactNode, useCallback } from 'react';

export default function Toggle(props: ToggleProps) {
  const toggleValue = useCallback(() => {
    props.onChange();
  }, [props]);

  return (
    <label
      className={`${styles.checkboxContainer} ${props.disabled ? styles.disabled : ''} ${props.className ? props.className : ''}`}
      style={props.reversed ? { flexDirection: 'row-reverse' } : {}}
    >
      {props.children}
      <input
        type='checkbox'
        checked={props.checked}
        onChange={toggleValue}
      />
    </label>
  );
}

interface ToggleProps {
  children?: ReactNode,
  checked: boolean,
  onChange,
  reversed?: boolean,
  disabled?: boolean,
  className?: string
}
