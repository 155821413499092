import { Check, MessageText, Xmark } from 'iconoir-react';
import { useTranslation } from 'react-i18next';

import styles from './SidebarFilter.module.scss';
import { ManagedArea } from '../../../managed-area/ManagedArea';
import { FunctionButton } from '../../UI/Button/LegacyButton';
import { useCurrentAccount } from '../../../account/useAccounts';
import { TreeFilter } from '../../../tree-filter/TreeFilter';
import ManagedAreaFilter from './components/ManagedAreaFilter';
import TreePropertyFilter from './components/TreePropertyFilter';

interface SidebarFilterProps {
  managedAreas: ManagedArea[],
  selectedManagedAreaIds: string[],
  closeSidebar: () => void,
  treeFilters: TreeFilter[],
  setTreeFilters: (treeFilter: TreeFilter[]) => void,
  visible?: boolean
}

export function SidebarFilter(props: SidebarFilterProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();

  return (
    <div className={`${styles.sideBarContainer} ${props.visible ? styles.visible : ''}`}>
      <div>
        <div className={styles.header}>
          <h3 className={styles.selection}>{t('sidebarFilter.selection')}</h3>
          <FunctionButton
            className={styles.cancel}
            icon={<Xmark />}
            testId={'sidebar-close-button'}
            onClick={() => props.closeSidebar()} />
        </div>
        <ManagedAreaFilter managedAreas={props.managedAreas} selectedManagedAreaIds={props.selectedManagedAreaIds} />
        <TreePropertyFilter
          organizationId={account.organization.id}
          treeFilters={props.treeFilters}
          setTreeFilters={props.setTreeFilters} />
      </div>
      {account.organization.serviceDeskUrl &&
        <a
          href={account.organization.serviceDeskUrl}
          target='_blank'
          className={styles.feedbackButton}
          rel='noreferrer'>
          <MessageText/>{t('sidebarFilter.giveFeedback')}
        </a>
      }
    </div>
  );
}

type ManagedAreaRowProps = { isSelected: boolean, managedArea: ManagedArea, onToggle: (id: string) => void };

export function ManagedAreaRow({ isSelected, managedArea, onToggle }: ManagedAreaRowProps) {
  return (
    <li
      onClick={() => onToggle(managedArea.id)}
      className={`${styles.managedAreaRow} ${isSelected && styles.selectedManagedArea}`}>
      <div className={styles.checkContainer}>{isSelected && <Check />}</div>
      <div>{managedArea.getName()}</div>
    </li>
  );
}
