import { ReportData } from '../ReportData';
import ReportSection from './ReportSection';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import styles from './DBHDistributionSection.module.scss';
import { ChartData, ScatterDataPoint } from 'chart.js';

let width, height, gradient;

function getGradient(ctx, chartArea) {
  if (!chartArea) return null;

  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  const isFirstRun = gradient === null;
  const isChartResized = width !== chartWidth || height !== chartHeight;
  if (isFirstRun || isChartResized) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(1, 'rgba(255, 186, 10, 0.2)');
    gradient.addColorStop(0, 'rgba(255, 186, 10, 0)');
  }

  return gradient;
}

type DBHDistributionSectionProps = {
  data: ReportData['dbhDistribution'],
  containerId: string,
  animationDisabled?: boolean,
  printable?: boolean,
  onLoaded?: () => void
};
export default function DBHDistributionSection(props: DBHDistributionSectionProps) {
  const { t } = useTranslation();
  const onLoadedPlugin = {
    id: 'onLoaded',
    afterRender: props.onLoaded
  };

  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart) {
      const fitValue = chart.legend.fit;

      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return this.height += 30;
      };
    }
  };
  const options = {
    responsive: true,
    animation: props.animationDisabled ? { duration: 0 } : undefined,
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false
      },
      title: {
        display: false
      },
      legendMargin
    },
    elements: {
      point: {
        radius: 5
      }
    },
    scales: {
      y: {
        ticks: {
          font: {
            size: 10
          },
          color: props.printable ? 'rgba(34, 45, 49, 1)' : 'rgba(237, 244, 242, 0.6)',
          padding: 16,
          callback: function(value) {
            return value + ' %';
          }
        },
        title: {
          text: t('reporting.DBHDistribution.population'),
          display: true,
          color: props.printable ? 'rgba(34, 45, 49, 1)' : 'rgba(237, 244, 242, 0.6)',
          font: {
            size: 12
          }
        },
        grid: {
          color: 'rgba(91, 113, 121, 0.3)',
          drawTicks: false
        }
      },
      x: {
        ticks: {
          font: {
            size: 10
          },
          color: props.printable ? 'rgba(34, 45, 49, 1)' : 'rgba(237, 244, 242, 0.6)',
          padding: 16,
          maxRotation: 0
        },
        title: {
          text: t('reporting.DBHDistribution.dbhRange'),
          display: true,
          color: props.printable ? 'rgba(34, 45, 49, 1)' : 'rgba(237, 244, 242, 0.6)',
          font: {
            size: 12
          }
        },
        grid: {
          color: 'rgba(91, 113, 121, 0.3)',
          drawTicks: false
        }
      }
    }
  };

  const data = useMemo(() => {
    const first100 = Array(100).fill(null).map((a, i) => i + 1);
    const dist = new Map(props.data.values.map(([x, y]) => [Math.floor(x * 100), y * 100]));

    return {
      labels: first100.map(x => x % 5 === 0 ? `${x}` : ''),
      datasets: [
        {
          type: 'scatter',
          order: 1,
          label: t('reporting.DBHDistribution.currentDBH'),
          data: first100.map(x => dist.get(x) ?? null),
          backgroundColor: '#FFBA0A'
        },
        {
          fill: true,
          type: 'line',
          order: 2,
          label: '',
          pointRadius: 0,
          hitRadius: 0,
          hoverRadius: 0,
          data: first100.map(x => {
            const [a, b] = props.data.exponentialRegressionCoefficients;
            return a * Math.exp(b * x);
          }),
          borderColor: '#E3A910',
          backgroundColor: ctx => getGradient(ctx.chart.ctx, ctx.chart.chartArea)
        },
        {
          type: 'line',
          fill: false,
          order: 2,
          borderDash: [2, 3],
          label: '',
          pointRadius: 0,
          hitRadius: 0,
          hoverRadius: 0,
          data: first100.map(x => 43.28 - x * .43),
          borderColor: 'rgba(39, 226, 252, 0.6)'
        },
        {
          type: 'scatter',
          order: 1,
          label: t('reporting.DBHDistribution.richardsIdeal'),
          data: first100.map(x => {
            if (x === 10) return 40;
            if (x === 30) return 30;
            if (x === 50) return 20;
            if (x === 80) return 10;
            return null;
          }),
          backgroundColor: '#27E2FC'
        },
        {
          type: 'line',
          fill: false,
          order: 2,
          borderDash: [2, 3],
          label: '',
          pointRadius: 0,
          hitRadius: 0,
          hoverRadius: 0,
          data: first100.map(x => -0.003 * x ** 2 - 0.0171 * x + 38.7517),
          borderColor: 'rgba(244, 119, 3, 0.6)'
        },
        {
          type: 'scatter',
          order: 1,
          label: t('reporting.DBHDistribution.millwardIdeal'),
          data: first100.map(x => {
            if (x === 7) return 40;
            if (x === 37) return 30;
            if (x === 75) return 25;
            if (x === 100) return 5;
            return null;
          }),
          backgroundColor: '#F47703'
        }
      ]
    };
  }, [t, props.data.values.flat().concat(props.data.exponentialRegressionCoefficients).join()]);

  const legend = (
    <div className={`${styles.legend} ${props.printable ? styles.printableLegend : ''}`}>
      {data.datasets.filter(it => it.type === 'scatter').map((it, index) => {
        return (
          <div className={styles.legendItem} key={index}>
            <div className={styles.coloredMarker} style={{ backgroundColor: it.backgroundColor as string }} />
            <span>{it.label}</span>
          </div>
        );
      })}
    </div>
  );
  type CustomDataType = ChartData<'line', (number | ScatterDataPoint | null)[], unknown>;
  return (
    <ReportSection containerId={props.containerId} printable={props.printable}>
      <h2 className={styles.header}>{t('reporting.DBHDistribution.title')}</h2>
      {legend}
      <Line
        options={options}
        data={data as unknown as CustomDataType}
        plugins={[onLoadedPlugin, legendMargin]} />
    </ReportSection>
  );
}
