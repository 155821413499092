import Analytics from 'analytics';
import api from './services/api';

const analytics = Analytics({
  app: 'guf',
  plugins: [
    {
      name: 'backend-proxy-plugin',
      track: ({ payload }) => {
        return api.post(`/v1/analytics/${payload.event}`, { anonymousId: payload.anonymousId, ...payload.properties });
      }
    }
  ]
});

export default analytics;
