import { useEffect, useRef, useState } from 'react';

import RawText from '../Text/RawText';
import styles from './TreePropertySlider.module.scss';

interface TreePropertySliderProps {
  isInBetweenType?: boolean,
  name: string,
  valueFormatter?: (value: number) => string | number,
  onChange?: (value: { name: string, value: [number, number] | number }) => unknown,
  onAfterChange?: (value: { name: string, value: [number, number] | number }) => unknown,
  min?: number,
  max?: number,
  value?: [number, number] | number,
  enableSnapping?: boolean,
  step?: number | null,
  disabled?: boolean,
  colored?: boolean,
  monochrome?: boolean,
  inverted?: boolean,
  labelLeft?: string,
  labelRight?: string,
  testId?: string,
  lowOpacityAtExtremes?: boolean
}

const TreePropertySlider = ({
  name,
  valueFormatter,
  onChange = () => {},
  onAfterChange,
  min = 0,
  max = 1,
  value = 0.5,
  isInBetweenType = false,
  enableSnapping = false,
  step = null,
  disabled = false,
  colored = false,
  inverted = false,
  monochrome = false,
  labelLeft = '',
  labelRight = '',
  testId = '',
  lowOpacityAtExtremes = false
}: TreePropertySliderProps) => {
  const wrapper = useRef(null);
  const [lastChanged, setLastChanged] = useState(0);

  const handleChange = (index: number) => val => {
    if (!isInBetweenType) {
      onChange({ name, value: val });
    } else {
      const updatedValue: [number, number] = [...(value as [number, number])];
      setLastChanged(index);

      if (index === 1 && val <= updatedValue[0]) {
        val = updatedValue[0];
      } else if (index === 0 && val >= updatedValue[1]) {
        val = updatedValue[1];
      }

      updatedValue[index] = val;
      updatedValue.sort((a, b) => a - b);
      onChange({ name, value: updatedValue as any });
    }
  };

  const getValuePercentage = value => (value - min) / (max - min);
  return (
    <div className={`${styles.sliderOuterWrapper} ${disabled && styles.disabled}`} data-testid={testId}>
      <div className={styles.lineWrapper}>
        <div ref={wrapper} className={`${styles.line} ${colored && styles.colored} ${monochrome && styles.monochrome}`}>
          {labelLeft && getValuePercentage(isInBetweenType ? value[0] : value) > 0.15 && (
            <div className={styles.labelLeft}>
              <RawText>
                {labelLeft}
              </RawText>
            </div>
          )}
          {labelRight && getValuePercentage(isInBetweenType ? value[1] : value) < 0.85 && (
            <div className={styles.labelRight}>
              <RawText>
                {labelRight}
              </RawText>
            </div>
          )}
          {inverted ? (
            <>
              <div
                className={`${styles.inactive} ${monochrome && styles.monochrome}`}
                style={{
                  left: '0%',
                  width: `${getValuePercentage(value) * 100}%`
                }}
              />
              <div
                className={styles.highlight}
                style={{
                  left: `${getValuePercentage(value) * 100}%`,
                  right: '0%'
                }}
              />
            </>
          ) : (
            <>
              <div
                className={`${styles.inactive} ${monochrome && styles.monochrome}`}
                style={{
                  left: '0%',
                  width: `${getValuePercentage(isInBetweenType ? Math.min(...(value as number[])) : min) * 100}%`
                }}
              />
              <div
                className={styles.highlight}
                style={{
                  left: `${getValuePercentage(isInBetweenType ? Math.min(...(value as number[])) : min) * 100}%`,
                  right: `${(1 - getValuePercentage(isInBetweenType ? Math.max(...(value as number[])) : value)) * 100}%`
                }}
              />
              <div
                className={`${styles.inactive} ${monochrome && styles.monochrome}`}
                style={{
                  left: `${(getValuePercentage(isInBetweenType ? Math.max(...(value as number[])) : value)) * 100}%`,
                  right: '0%'
                }}
              />
            </>
          )}

          {isInBetweenType ? (<>
            <Knob
              min={min}
              max={max}
              onChange={handleChange(lastChanged === 1 ? 0 : 1)}
              onAfterChange={onAfterChange}
              wrapper={wrapper}
              value={value[lastChanged === 1 ? 0 : 1]}
              valueFormatter={valueFormatter}
              enableSnapping={enableSnapping}
              step={step}
              lowOpacityAtExtremes={lowOpacityAtExtremes}
            />
            <Knob
              min={min}
              max={max}
              onChange={handleChange(lastChanged)}
              onAfterChange={onAfterChange}
              wrapper={wrapper}
              value={value[lastChanged]}
              valueFormatter={valueFormatter}
              enableSnapping={enableSnapping}
              step={step}
              lowOpacityAtExtremes={lowOpacityAtExtremes}
            />
          </>)
            : <Knob
              min={min}
              max={max}
              onChange={handleChange(1)}
              onAfterChange={onAfterChange}
              wrapper={wrapper}
              value={value}
              valueFormatter={valueFormatter}
              enableSnapping={enableSnapping}
              step={step}
              lowOpacityAtExtremes={lowOpacityAtExtremes}
            />}
        </div>
      </div>
    </div>
  );
};

const SNAPPING = 0.05;
const clamp = (x, min, max) => Math.min(max, Math.max(min, x));

const Knob = ({ valueFormatter, value, wrapper, onChange, onAfterChange, min, max, enableSnapping, step, lowOpacityAtExtremes }) => {
  const [moving, setMoving] = useState(false);
  const handleMouseMove = ({ clientX }) => {
    if (!wrapper.current) {
      return;
    }
    // Getting bounding box of parent
    const { x: xOffset, width } = wrapper.current.getBoundingClientRect();

    // Calculating relative positions, clamping values between 0-1, and applying snappoints
    let x = clamp((clientX - xOffset) / width, 0, 1);
    if (enableSnapping && x < SNAPPING) {
      x = 0;
    } else if (enableSnapping && x > 1 - SNAPPING) {
      x = 1;
    }

    const absolutePosition = min + (max - min) * x;

    const newValue =
      step && absolutePosition < max && absolutePosition > min
        ? clamp(Math.round(absolutePosition / step) * step, min, max)
        : absolutePosition;

    onChange(newValue);
  };

  const handleMouseDown = () => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    setMoving(true);
  };
  const handleMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
    setMoving(false);
  };

  useEffect(() => {
    if (!moving) {
      onAfterChange(value);
    }
  }, [moving]);

  const val = ((value ?? min) - min) / (max - min);
  const lowOpacity = (val === 0 || val === 1) && lowOpacityAtExtremes;

  return (
    <div
      onMouseDown={handleMouseDown}
      className={styles.knobWrapper}
      style={{ left: `${val * 100}%` }}>
      {/* eslint-disable-next-line no-constant-condition */}
      <div className={`${styles.knob} ${lowOpacity ? styles.lowOpacity : ''}`}>
        <RawText className={`${styles.knobLabel} ${moving ? styles.moving : ''}`} color="primary">
          {valueFormatter ? valueFormatter(value) : value?.toFixed(1)}
        </RawText>
      </div>
    </div>
  );
};

export default TreePropertySlider;
