import mapboxgl from 'mapbox-gl';
import { TreeNamecard } from './TreeNamecard';

export class MapboxPopupTreeNamecard extends TreeNamecard<mapboxgl.Map> {
  private readonly mapboxPopup: mapboxgl.Popup = new mapboxgl.Popup({
    className: 'tree-namecard',
    closeButton: false,
    maxWidth: '300px'
  });

  protected isOpen() {
    return this.mapboxPopup.isOpen();
  }

  protected openOn(map: mapboxgl.Map) {
    this.mapboxPopup.addTo(map);
  }

  protected setPosition(coordinates: [number, number]) {
    this.mapboxPopup.setLngLat(coordinates);
    this.mapboxPopup.setOffset(-30);
  }

  protected resetUI() {
    this.mapboxPopup.remove();
  }

  protected setContent(content: string) {
    this.mapboxPopup.setHTML(content);
  }

  protected override setImages(imageUrls: string[]) {
    const imageElements = this.mapboxPopup.getElement()?.querySelectorAll('.image') as NodeListOf<HTMLDivElement>;
    if (!imageElements) {
      return;
    }
    imageElements.forEach((imageElement, index) => {
      imageElement.classList.remove('loading');
      imageElement.style.backgroundImage = `url('${imageUrls[index]}')`;
    });
  }

  protected handleDetailsButtonClickEvent() {
    const popupElement = this.mapboxPopup.getElement();
    const detailsButton = popupElement?.querySelector('.detailsButton');
    detailsButton?.removeEventListener('click', this.onOpenButtonClick);
    detailsButton?.addEventListener('click', this.onOpenButtonClick);
  }

  protected handlePopupMouseOverEvent() {
    const popupElement = this.mapboxPopup.getElement();
    popupElement?.removeEventListener('mouseover', this.preventClosingOnHover);
    popupElement?.addEventListener('mouseover', this.preventClosingOnHover);
  }

  protected handlePopupMouseLeave() {
    const popupElement = this.mapboxPopup.getElement();
    popupElement?.removeEventListener('mouseleave', this.closeOnMouseLeave);
    popupElement?.addEventListener('mouseleave', this.closeOnMouseLeave);
  }
}
