import styles from './AbsoluteModal.module.scss';

export default function ModalDialog({ children, style, classNames }){
  return (
    <div
      role="dialog"
      aria-labelledby="modal-title"
      className={`${styles.modal} ${classNames}`}
      data-testid="modal"
      style={style}
    >
      {children}
    </div>
  );
}
