import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MemberListHeaderView from './MemberListHeaderView';
import { ComparableMemberProperty, MemberSortingDirection } from '../../../organization/Member';
import SortableMemberListHeader from './SortableMemberListHeader';
import MemberListHeader from './MemberListHeader';

export default function MemberListHeaders(props: MemberListHeadersProps) {
  const isSortingActiveFor = useMemo(
    () => ({
      name: props.currentSortingProperty === 'name',
      accountType: props.currentSortingProperty === 'accountType',
      lastLogin: props.currentSortingProperty === 'lastLogin',
      memberSince: props.currentSortingProperty === 'memberSince',
      status: props.currentSortingProperty === 'status'
    }),
    [props.currentSortingProperty]
  );
  const { t } = useTranslation();

  const onClick = (property: ComparableMemberProperty) => () => {
    if (isSortingActiveFor[property]) {
      return props.onChange(
        property,
        props.currentSortingDirection === MemberSortingDirection.ASCENDING
          ? MemberSortingDirection.DESCENDING
          : MemberSortingDirection.ASCENDING
      );
    }

    props.onChange(property, MemberSortingDirection.DESCENDING);
  };

  return (
    <MemberListHeaderView>
      <SortableMemberListHeader
        isActive={isSortingActiveFor.name}
        onClick={onClick('name')}
        direction={props.currentSortingDirection}
      >
        {t('settings.members.name')}
      </SortableMemberListHeader>

      <SortableMemberListHeader
        isActive={isSortingActiveFor.accountType}
        onClick={onClick('accountType')}
        direction={props.currentSortingDirection}
      >
        {t('settings.members.accountType')}
      </SortableMemberListHeader>

      <SortableMemberListHeader
        isActive={isSortingActiveFor.lastLogin}
        onClick={onClick('lastLogin')}
        direction={props.currentSortingDirection}
      >
        {t('settings.members.lastLogin')}
      </SortableMemberListHeader>

      <SortableMemberListHeader
        isActive={isSortingActiveFor.memberSince}
        onClick={onClick('memberSince')}
        direction={props.currentSortingDirection}
      >
        {t('settings.members.memberSince')}
      </SortableMemberListHeader>

      <SortableMemberListHeader
        isActive={isSortingActiveFor.status}
        onClick={onClick('status')}
        direction={props.currentSortingDirection}
      >
        {t('settings.members.status')}
      </SortableMemberListHeader>

      <MemberListHeader />
    </MemberListHeaderView>
  );
}

interface MemberListHeadersProps {
  currentSortingDirection: MemberSortingDirection,
  currentSortingProperty: ComparableMemberProperty,
  onChange: (property: ComparableMemberProperty, direction: MemberSortingDirection) => unknown
}
