import * as THREE from 'three';

const MEASURE_PERFORMANCE = false;

class OptimizedPointcloud extends THREE.Group {
  /** Number of groups per edge, 10 means 1000 groups.
   * Too large number slows rendering.
   */
  N = 8;

  /** OptimizedPointcloud groups the points into "boxes" (just like a voxel).
   * The resulting object is a THREE.Group that holds THREE.Points objects.
   *
   * Thanks to this, raycasting is faster, because it first checks every objects
   * (the groups) bounding sphere, and only if the bounding sphere is intersected,
   * checks the points inside.
   *
   * @param {THREE.BufferGeometry} initialGeometry
   * @param {THREE.PointsMaterial} initialMaterial
   */
  constructor(
    private readonly initialGeometry: THREE.BufferGeometry,
    private readonly initialMaterial: THREE.PointsMaterial
  ) {
    super();
    const start = performance.now();

    const numberOfPoints = initialGeometry.attributes.position.count;
    const numberOfBoxes = this.N ** 3;
    const boxSize = this.calculateBoxSize(initialGeometry);

    const { geometries, attributes } = this.initArraysForBufferGeometries(numberOfBoxes);

    for (let i = 0; i < numberOfPoints; i++) {
      const point = this.getPointData(initialGeometry, i);

      const boxIndex = this.getBoxIndexByCoordinate(point.position, boxSize);

      attributes.positions[boxIndex].push(...point.position);
      attributes.intensities[boxIndex].push(point.intensity);
      attributes.colors[boxIndex].push(...point.color);
    }

    for (let i = 0; i < numberOfBoxes; i++) {
      const isBoxNotEmpty = attributes.positions[i].length > 0;

      if (isBoxNotEmpty) {
        const pointsMesh = this.generatePointsMesh(
          geometries[i],
          attributes.positions[i],
          attributes.colors[i],
          attributes.intensities[i],
          initialMaterial
        );

        this.add(pointsMesh);
      }
    }

    if (MEASURE_PERFORMANCE) {
      // eslint-disable-next-line no-console
      console.log(
        `Pointcloud optimization done in ${performance.now() - start} ms, resulting in ${this.children.length} boxes.`
      );
    }
  }

  generatePointsMesh(geometry, position, color, intensity, material) {
    geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(position), 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(color), 3));
    geometry.setAttribute('intensity', new THREE.BufferAttribute(new Float32Array(intensity), 1));

    const pointsMesh = new THREE.Points(geometry, material.clone());
    pointsMesh.matrixAutoUpdate = false;

    return pointsMesh;
  }

  getBoxIndexByCoordinate(position, smallBoxesSize) {
    const xPos = Math.min(Math.floor(position[0] / smallBoxesSize.x), this.N - 1);
    const yPos = Math.min(Math.floor(position[1] / smallBoxesSize.x), this.N - 1);
    const zPos = Math.min(Math.floor(position[2] / smallBoxesSize.x), this.N - 1);

    return xPos * this.N ** 2 + yPos * this.N + zPos;
  }

  getPointData(geometry, i) {
    const position = [
      geometry.attributes.position.array[i * 3 + 0],
      geometry.attributes.position.array[i * 3 + 1],
      geometry.attributes.position.array[i * 3 + 2]
    ];

    const intensity = geometry.attributes.intensity.array[i];

    const color = [
      geometry.attributes.color.array[i * 3 + 0],
      geometry.attributes.color.array[i * 3 + 1],
      geometry.attributes.color.array[i * 3 + 2]
    ];

    return { position, intensity, color };
  }

  initArraysForBufferGeometries(numberOfBoxes) {
    const geometries = new Array(numberOfBoxes);
    const positions = new Array(numberOfBoxes);
    const colors = new Array(numberOfBoxes);
    const intensities = new Array(numberOfBoxes);

    for (let i = 0; i < geometries.length; i++) {
      geometries[i] = new THREE.BufferGeometry();
      positions[i] = [];
      colors[i] = [];
      intensities[i] = [];
    }

    return { attributes: { positions, intensities, colors }, geometries };
  }

  calculateBoxSize(geometry) {
    geometry.computeBoundingBox();
    const boundingBoxSize = new THREE.Vector3();
    geometry.boundingBox.getSize(boundingBoxSize);

    return boundingBoxSize.clone().divideScalar(this.N);
  }

  override clone(recursive?: boolean, material?: THREE.PointsMaterial): this {
    return new OptimizedPointcloud(this.initialGeometry.clone(), material ?? this.initialMaterial).copy(
      this,
      recursive
    ) as this;
  }
}

export default OptimizedPointcloud;
