const lightMultiSelectStyle = {
  menu: {
    background: 'var(--panel-dark-new-details)',
    fontSize: 'var(--font-size-s)',
    color: 'var(--text-grey)',
    fontWeight: '400'
  },
  option: {
    ':hover': {
      backgroundColor: 'var(--panel-border)',
      cursor: 'pointer'
    }
  },
  dropdownIndicator: {
    color: 'var(--text-primary)',
    paddingRight: '4px'
  }
};

export default lightMultiSelectStyle;
