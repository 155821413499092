import { Account } from '../../account/Account';
import { useState } from 'react';
import styles from './OrganizationMenu.module.scss';
import { Link, useParams } from 'react-router-dom';
import { NavArrowDown, NavArrowUp } from 'iconoir-react';
import { EntityKey, EntityStorage } from '../../EntityStorage';
import { Organization } from '../../organization/Organization';

export default function OrganizationMenu({ accounts }: { accounts: Account[] }) {
  const { organizationId } = useParams();
  const currentAccount = accounts.find(it => it.organization.id === organizationId)!;
  const [isAccountsOpen, setAccountsOpen] = useState(false);

  const otherAccounts = accounts.filter(it => it.id !== currentAccount.id);
  const shouldShowDropdownOpenButton = accounts.length > 1;
  const handleOrgOpen = e => {
    e.stopPropagation();
    setAccountsOpen(prevState => !prevState);
  };

  const setCurrentOrganizationInStorage = organization => {
    EntityStorage.local(EntityKey.CurrentOrganization, Organization).set(organization);
  };

  return (
    <div className={styles.orgContainer}>
      <div className={`${styles.currentOrg} ${isAccountsOpen ? styles.activeAccount : ''}`} onClick={handleOrgOpen}>
        <div data-testid="current-account"><AccountInOrganizationMenu account={currentAccount}/></div>
        {shouldShowDropdownOpenButton && <div className={styles.caretBtn} data-testid="org-list-button">
          {isAccountsOpen ? <NavArrowUp /> : <NavArrowDown />}
        </div>}
      </div>
      {isAccountsOpen && <ul className={styles.list}>
        {otherAccounts.sort((a, b) => a.organization.name.localeCompare(b.organization.name)).map(it => (
          <li key={it.id}>
            <Link to={it.linkToMap()} onClick={() => setCurrentOrganizationInStorage(it.organization)}>
              <AccountInOrganizationMenu account={it}/>
            </Link>
          </li>
        ))}
      </ul>}
    </div>
  );
}

function AccountInOrganizationMenu({ account }: { account: Account }) {
  return <div className={styles.organization}><span
    className={styles.selectedOrganizationLogo}
    style={{ backgroundImage: `url(${account.getOrganizationLogoUrl()})` }}
  /><span className={styles.orgName}>{account.getOrganizationName()}</span></div>;
}
