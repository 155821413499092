import { useTranslation } from 'react-i18next';
import pageStyles from '../AnalyticsPage.module.scss';
import { CloudXmark } from 'iconoir-react';
import { PageContainer, PageContent, PageHeader, TreePropertyList } from './Page';

export default function StatisticsEmptyState() {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageHeader title={t('analytics.navigation.dimensions')}>
        <TreePropertyList />
      </PageHeader>

      <PageContent>
        <div className={pageStyles.emptyState}>
          <CloudXmark strokeWidth={0.5} fontSize="4rem" />

          <p>{t('analytics.noStatistics')}</p>
        </div>
      </PageContent>
    </PageContainer>
  );
}
