import { JobColumnName } from './create/JobColumnName';

const jobColumnColorMap = {
  [JobColumnName.Inspection]: {
    primary: 'orchid-500',
    secondary: 'orchid-900'
  },
  [JobColumnName.Pruning]: {
    primary: 'electric-violet-500',
    secondary: 'electric-violet-900'
  },
  [JobColumnName.Watering]: {
    primary: 'cerulean-blue-500',
    secondary: 'cerulean-blue-800'
  },
  [JobColumnName.AdvancedAssessment]: {
    primary: 'cyprus-500',
    secondary: 'cyprus-900'
  },
  [JobColumnName.Spraying]: {
    primary: 'success-500',
    secondary: 'success-800'
  }
};
export default jobColumnColorMap;
