import { useContext } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { ContextFields } from '../../Explore/UrlContext';

export function useAreaFilteringAsSearchParam() {
  const { urlContext } = useContext(DependencyInjectionContext);

  return [
    ...urlContext.getManagedAreaIds().map(id => ContextFields.ManagedAreaId + '=' + id),
    ...urlContext.getReverseMASelection() ? [`${ContextFields.ReverseMASelection}=true`] : []
  ].join('&');
}
