import * as THREE from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import styles from '../components/RiskOverlay.module.scss';

export default class SafetyFactorMarkerGroup extends THREE.Group {
  readonly arrow: THREE.Object3D | null = null;
  private readonly scaledRadius: number;

  constructor(
    radius: number,
    thickness: number,
    numberOfSegments: number,
    windDirection: number | null,
    scaling: number
  ) {
    super();

    this.scaledRadius = scaling * radius;
    const outerRadius = this.scaledRadius + thickness / 2;
    const innerRadius = this.scaledRadius - thickness / 2;
    const resolution = numberOfSegments * 2;
    const angles = [...new Array(resolution).fill(0).map((_, i) => i / resolution * 2 * Math.PI)];
    const dashes: THREE.Shape[] = [];
    for (let i = 0; i < angles.length; i += 2) {
      if (
        !windDirection ||
        (
          !(Math.abs(angles[i] - THREE.MathUtils.degToRad(windDirection)) < 0.08) &&
          !(Math.abs(angles[i + 1] - THREE.MathUtils.degToRad(windDirection)) < 0.08)
        )
      ) {
        dashes.push(this.createDash(outerRadius, innerRadius, angles[i], angles[i + 1]));
      }
    }
    const geometry = new THREE.ShapeGeometry(dashes);
    const material = new THREE.MeshBasicMaterial({ color: '#ffffff', side: THREE.DoubleSide });
    this.add(new THREE.Mesh(geometry, material));

    if (windDirection !== 0) this.add(this.createNorthPointer());

    if (windDirection !== null) {
      this.arrow = this.createArrowPointingIn(windDirection, 'white', 0.6 * scaling);
      this.add(this.arrow);
    }

    this.rotateX(-Math.PI / 2);
  }

  private createArrowPointingIn(direction: number, color: string, scale: number): THREE.Mesh {
    const height = scale * Math.sqrt(3) / 2;
    const width = scale * 0.8;
    const coordinates = new Float32Array([
      -width / 2, -height / 2, 0,
      0, width * 0.3125 - height / 2, 0,
      width / 2, -height / 2, 0,
      0, height / 2, 0
    ]);
    const geom = new THREE.BufferGeometry()
      .setIndex([0, 1, 3, 2, 1, 3])
      .setAttribute('position', new THREE.BufferAttribute(coordinates, 3));
    const mesh = new THREE.Mesh(geom, new THREE.MeshBasicMaterial({ color, side: THREE.DoubleSide }));

    mesh.rotation.set(0, 0, THREE.MathUtils.degToRad(-direction + 180));
    mesh.position.set(
      this.scaledRadius * Math.sin(THREE.MathUtils.degToRad(direction)),
      this.scaledRadius * Math.cos(THREE.MathUtils.degToRad(direction)),
      0
    );

    return mesh;
  }

  private createNorthPointer(): CSS2DObject {
    const container = document.createElement('div');
    container.innerText = 'N';
    container.classList.add(styles.northPointer);
    const pointer = new CSS2DObject(container);
    pointer.position.set(0, this.scaledRadius, 0);
    return pointer;
  }

  private createDash(r1: number, r2: number, startAngle: number, endAngle: number) {
    return new THREE.Shape([
      new THREE.Vector2(r1 * Math.sin(startAngle), r1 * Math.cos(startAngle)),
      new THREE.Vector2(r2 * Math.sin(startAngle), r2 * Math.cos(startAngle)),
      new THREE.Vector2(r2 * Math.sin(endAngle), r2 * Math.cos(endAngle)),
      new THREE.Vector2(r1 * Math.sin(endAngle), r1 * Math.cos(endAngle))
    ]);
  }
}
