import DetailedTree from '../../../tree/DetailedTree';
import { useTranslation } from 'react-i18next';
import { InfoCircle } from 'iconoir-react';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';

export default function Static({ tree }: { tree: DetailedTree | null }) {
  const { t } = useTranslation();

  if (!tree) return null;

  return (
    <div className="twp text-greehill-00 pb-6">
      <h3 className="uppercase text-xs font-bold mt-8 mb-2 mx-3 text-outer-space-300">
        {t('treeDetails.inventory.siteFactors')}
      </h3>
      <ul className="divide-y divide-outer-space-500 [&>li]:px-3 [&>li]:py-2 [&>li]:flex">
        <StaticItem property={'streetAddress'} value={tree.streetAddress} />
        <StaticItem property={'onStreetName'} value={tree.onStreetName} />
        <StaticItem property={'addressFromParcel'} value={tree.addressFromParcel} />
        <StaticItem property={'sideLocation'} value={tree.sideLocation} />
        <StaticItem property={'landUse'} value={tree.landUse?.join(', ')} />
        <StaticItem property={'overheadUtilities'} value={tree.overheadUtilities?.join(', ')} />
        <StaticItem property={'growSpaceSize'} value={tree.growSpaceSize} />
        <StaticItem property={'growSpace'} value={tree.growSpace} />
        <StaticItem property={'parkName'} value={tree.parkName} />
      </ul>
      <h3 className="uppercase text-xs font-bold mt-8 mb-2 mx-3 text-outer-space-300">
        {t('treeDetails.inventory.additionalInformation')}
      </h3>
      <ul className="divide-y divide-outer-space-500 [&>li]:px-3 [&>li]:py-2 [&>li]:flex">
        <StaticItem property={'customerTagId'} value={tree.customerTagId} />
        <StaticItem property={'customerSiteId'} value={tree.customerSiteId} />
        <StaticItem property={'customerTreeId'} value={tree.customerTreeId} />
        <StaticItem property={'cultivarOrVariety'} value={tree.cultivarOrVariety} userUpdatedProperties={tree.userUpdatedProperties} />
        <StaticItem property={'plantingYear'} value={tree.plantingYear?.toString()} />
        <StaticItem property={'owner'} value={tree.owner} />
      </ul>
      <h3 className="uppercase text-xs font-bold mt-8 mb-2 mx-3 text-outer-space-300">
        {t('treeDetails.inventory.latestPruningAction')}
      </h3>
      <ul className="divide-y divide-outer-space-500 [&>li]:px-3 [&>li]:py-2 [&>li]:flex">
        <StaticItem property={'latestPruningAction'} />
      </ul>
    </div>
  );
}

function StaticItem({ property, value, userUpdatedProperties }: { property: string, value?: string, userUpdatedProperties?: string[] }) {
  const { t } = useTranslation();
  return (
    <li className="flex">
      <div className="w-2/5 text-outer-space-200">{t('details.properties.' + property)}</div>
      <div className="w-3/5 font-medium flex items-center justify-between">
        {value ? value : <span className="text-outer-space-500">-</span>}
        {userUpdatedProperties?.includes(property) && (
          <span className="bg-warn-chardonnay-600 flex items-center rounded-full">
            <Tooltip overlay={t('details.manuallyOverwrittenData')} placement="left">
              <InfoCircle/>
            </Tooltip>
          </span>
        )}
      </div>
    </li>
  );
}
