import styles from '../Inventory.module.scss';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TreeStatus } from '../../../../../property-enums/TreeStatus';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import Dropdown, { Item } from '../../../../../components/UI/Dropdown/Dropdown';
import { TreeDto } from '../../../../../tree/Tree';
import { DataGroup } from '../Inventory';
import Input from '../../../../../components/UI/Input/Input';
import DetailedTree from '../../../../../tree/DetailedTree';
import DataGroupHeader from '../../components/DataGroupHeader/DataGroupHeader';
import EditingFieldWrapper from '../../components/EditingFieldWrapper/EditingFieldWrapper';

export default function IdentificationDataGroup(props: IdentificationDataGroupProps) {
  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);

  const initialIdentifications = {
    status: props.tree.status,
    plantingYear: props.tree.plantingYear || '',
    customerTreeId: props.tree.customerTreeId || '',
    owner: props.tree.owner || ''
  };

  const statusArray: Item[] = Object.values(TreeStatus).map(status => {
    return { id: status, translationKey: 'tree.statusTypes.' + status };
  });

  const [identifications, setIdentifications] = useState(initialIdentifications);
  const statusKey = identifications.status ? t(`tree.statusTypes.${identifications.status}`) : '';

  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(identifications).forEach(key => {
      if (identifications[key] !== props.tree[key] && identifications[key] !== '') {
        updatedValues[key] = identifications[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(props.organizationId, props.tree.id, updatedValues);
    }
    props.setEditing(undefined);
  };

  const handleCancel = () => {
    props.setEditing(undefined);
    setIdentifications(initialIdentifications);
  };

  useEffect(() => {
    setIdentifications(initialIdentifications);
  }, [props.editing]);

  return (
    <div className={styles.dataGroup}>
      <DataGroupHeader
        title={t('treeDetails.inventory.identification')}
        editing={props.editing === DataGroup.identification}
        setEditing={() => props.setEditing(DataGroup.identification)}
        handleCancel={handleCancel}
        handleSave={handleSave}
      />
      <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : ''}`}>
        <EditingFieldWrapper
          title={t('details.properties.status')}
          value={statusKey}
          editing={props.editing === DataGroup.identification}
        >
          <Dropdown
            fieldClassName={styles.dropdownField}
            menuClassname={styles.menuClassname}
            openClassname={styles.openClassname}
            items={statusArray}
            value={{ id: identifications.status, translationKey: statusKey }}
            onSelect={item => setIdentifications(prev => ({ ...prev, status: item.id! }))}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.plantingYear')}
          value={identifications.plantingYear}
          editing={props.editing === DataGroup.identification}
        >
          <Input
            label={''}
            type="number"
            value={identifications.plantingYear}
            onChange={event => setIdentifications(prev => ({ ...prev, plantingYear: Number(event.target.value) }))}
            autoFocus={true}
            className={`${styles.editingInput} ${styles.editingNumberInput}`}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.customerTreeId')}
          value={identifications.customerTreeId}
          editing={props.editing === DataGroup.identification}
        >
          <Input
            label={''}
            value={identifications.customerTreeId}
            onChange={event => setIdentifications(prev => ({ ...prev, customerTreeId: event.target.value }))}
            autoFocus={true}
            className={styles.editingInput}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.age')}
          value={props.tree.age}
          editing={props.editing === DataGroup.identification}
        />
        <EditingFieldWrapper
          title={t('details.properties.owner')}
          value={identifications.owner}
          editing={props.editing === DataGroup.identification}
        >
          <Input
            label={''}
            value={identifications.owner}
            onChange={event => setIdentifications(prev => ({ ...prev, owner: event.target.value }))}
            autoFocus={true}
            className={styles.editingInput}
          />
        </EditingFieldWrapper>
        <EditingFieldWrapper
          title={t('details.properties.coordinates')}
          value={props.tree.getWorldCoordinates().map(it => it.toFixed(5)).join(', ')}
          editing={props.editing === DataGroup.identification}
        />
      </div>
    </div>
  );
}

interface IdentificationDataGroupProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organizationId: string,
  editing: undefined | DataGroup,
  setEditing: Dispatch<SetStateAction<DataGroup | undefined>>
}
