import { useCallback, useContext, useState } from 'react';

import UserPreferencesScreen from '../../components/Settings/user/UserPreferencesScreen';
import { PasswordService, PasswordUpdateError } from '../../password/PasswordService';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../auth/AuthContext';
import { UserUpdateDto } from '../../users/UserUpdateDto';
import { UserService } from '../../users/UserService';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';

export function UserPreferences() {
  const authContext = useContext(AuthContext);
  const [isCompleted, setCompletionStatus] = useState(false);
  const [hasEnteredInvalidCurrentPassword, setInvalidCurrentPasswordStatus] = useState(false);
  const {
    i18n: { language }
  } = useTranslation();
  const onPasswordChange = useCallback(
    async (currentPassword: string, newPassword: string) => {
      setCompletionStatus(false);
      setInvalidCurrentPasswordStatus(false);
      try {
        await PasswordService.make().update(currentPassword, newPassword, language);
        setCompletionStatus(true);
      } catch (error: any) {
        if (error.message === PasswordUpdateError.NotMatching) {
          setInvalidCurrentPasswordStatus(true);
        }
      }
    },
    [language]
  );

  const onUserChange = useDebouncedCallback(async (payload: UserUpdateDto) => {
    const updated = await UserService.make().updateSelf(payload);
    authContext.update(updated);
  }, 300);

  return (
    <UserPreferencesScreen
      user={authContext.user}
      hasEnteredInvalidCurrentPassword={hasEnteredInvalidCurrentPassword}
      isPasswordChangeCompleted={isCompleted}
      onPasswordChange={onPasswordChange}
      onUserChange={onUserChange}
    />
  );
}
