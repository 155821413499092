export class PanelLayout {
  private state: 'DEFAULT' | 'TABLE' | 'SIDEBAR' = 'DEFAULT';
  private tableHeight = 0;

  setTableDragDistance(height: number) {
    this.tableHeight = height;
  }

  openSidebar() {
    this.state = 'SIDEBAR';
  }

  closeSidebar() {
    if (this.state === 'SIDEBAR') return this.state = 'DEFAULT';
  }

  toggleTable() {
    this.tableHeight = 0;
    if (this.isTableVisible()) return this.state = 'DEFAULT';
    this.state = 'TABLE';
  }

  openTable() {
    this.state = 'TABLE';
  }

  closeTable() {
    this.tableHeight = 0;
    if (this.state === 'TABLE') return this.state = 'DEFAULT';
  }

  getTableContainerStyle() {
    if (this.isTableVisible()) return { height: `${this.getTableHeight()}`, bottom: 0 };
    return { height: `${this.getTableHeight()}`, bottom: `-${this.getTableHeight()}` };
  }

  getControlsContainerStyle() {
    if (this.state === 'SIDEBAR') return { left: '330px' };
    if (this.tableShouldSnapToTop()) return { display: 'none' };
    if (this.state === 'TABLE') return { bottom: `${this.getTableHeight()}` };
    return {};
  }

  getPropertyLegendContainerStyle(isTreeInfoOpen: boolean) {
    if (this.tableShouldSnapToTop()) return { display: 'none' };
    if (this.state === 'SIDEBAR' && isTreeInfoOpen) return { left: 'calc(45% + 350px)' };
    if (this.state === 'SIDEBAR' || isTreeInfoOpen) return { left: 'calc(40% + 150px)' };
    return {};
  }

  getMapContainerStyle() {
    if (this.isTableVisible()) {
      const opacity = this.tableShouldSnapToTop() ? { opacity: 0.2 } : {};
      return { bottom: `${this.getTableHeight()}`, ...opacity };
    }

    return {};
  }

  private getTableHeight() {
    if (!this.isTableVisible()) return 0;
    if (this.tableHeight === 0) return '50%';
    return this.tableHeight + 'px';
  }

  getTreeInfoStyle(ignoreSidebar?: boolean) {
    if (this.tableShouldSnapToTop()) {
      return { display: 'none' } ;
    }
    if (ignoreSidebar) return {};
    return this.state === 'SIDEBAR' ? { left: '330px' } : {};
  }

  isTableVisible() {
    return this.state === 'TABLE';
  }

  tableShouldSnapToTop() {
    return this.isTableVisible() && this.tableHeight >= window.innerHeight * 0.7;
  }
}
