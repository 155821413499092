import * as THREE from 'three';

export default class MatrixUtils {
  static degToYRotationMatrix(angle: number): THREE.Matrix3 {
    const angleInRad = THREE.MathUtils.degToRad(angle);
    return new THREE.Matrix3().set(
      Math.cos(angleInRad), 0, -Math.sin(angleInRad),
      0, 1, 0,
      -Math.sin(angleInRad), 0, -Math.cos(angleInRad));
  }
}
