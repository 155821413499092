import { Chart as ChartJSChart, Plugin } from 'chart.js';
import styles from './ChartjsFullHeightSelectionPlugin.module.scss';

export class ChartjsFullHeightSelectionPlugin implements Plugin {
  id = 'selector';

  private currentTickIndex = -1;
  private overlay: HTMLDivElement | null = null;
  private tickWidth = 0;
  private minY = 0;
  private maxY = 0;

  constructor(
    private readonly options: {
      width: number,
      yAxisPadding: number,
      onClick: (labelIndex: number) => unknown
    }
  ) {}

  private mouseMoveCallback: (event: MouseEvent) => void = () => {};
  private mouseLeaveCallback: () => void = () => {};
  private overlayClickCallback: () => void = () => {};

  afterDraw(chart: ChartJSChart) {
    const bounds = chart.canvas.getBoundingClientRect();
    this.mouseMoveCallback = (event: MouseEvent) => requestAnimationFrame(() => this.onMouseMove(event, chart, bounds));
    chart.canvas.parentElement?.addEventListener('mousemove', this.mouseMoveCallback);

    this.mouseLeaveCallback = () => {
      if (this.overlay !== null) {
        this.removeOverlay();
      }
    };
    chart.canvas.parentElement?.addEventListener('mouseleave', this.mouseLeaveCallback);

    this.tickWidth = chart.scales.x.width / chart.scales.x.ticks.length;
    this.minY = chart.chartArea.top - this.options.yAxisPadding;
    this.maxY = chart.chartArea.bottom + this.options.yAxisPadding;
  }

  beforeDestroy(chart: ChartJSChart) {
    this.removeOverlay();
    chart.canvas.parentElement?.removeEventListener('mousemove', this.mouseMoveCallback);
    chart.canvas.parentElement?.removeEventListener('mouseleave', this.mouseLeaveCallback);
  }

  private onMouseMove(event: MouseEvent, chart: ChartJSChart, bounds: DOMRect) {
    const x = Math.max(event.clientX - bounds.left, 0);

    const selectedTickIndexOnXAxis = chart.scales.x.getValueForPixel(x) ?? -1;
    if (selectedTickIndexOnXAxis < 0 || (chart.data.labels?.length ?? 0) - 1 < selectedTickIndexOnXAxis) {
      this.overlay?.remove();
      this.overlay = null;
      this.currentTickIndex = -1;
      return;
    }

    if (selectedTickIndexOnXAxis === this.currentTickIndex) {
      return;
    }
    this.removeOverlay();

    this.currentTickIndex = selectedTickIndexOnXAxis;
    this.overlay = document.createElement('div');
    this.overlayClickCallback = () => this.options.onClick(selectedTickIndexOnXAxis);
    this.overlay.addEventListener('click', this.overlayClickCallback);
    const tickX = this.tickWidth * selectedTickIndexOnXAxis + chart.scales.x.left;

    const minX = tickX + this.tickWidth / 2 - this.options.width / 2;

    this.overlay.style.left = minX + 'px';
    this.overlay.style.top = this.minY + 'px';
    this.overlay.style.height = this.maxY + 'px';
    this.overlay.style.width = this.options.width + 'px';
    this.overlay.classList.add(styles.overlay);

    chart.canvas.parentElement?.appendChild(this.overlay);
  }

  private removeOverlay() {
    this.overlay?.removeEventListener('click', this.overlayClickCallback);
    this.overlay?.remove();
    this.overlay = null;
  }
}
