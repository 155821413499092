import { Tree } from '../../../../tree/Tree';
import CohortConfig from '../../../../components/cohort/CohortConfig';

export enum AdvancedFilterType {
  RANGE = 'range',
  MAX = 'max',
  MIN = 'min',
  INCLUDE_ONLY = 'include_only',
  PROPERTYCONFIGS = 'property_configs',
  COHORT = 'cohort'
}

export enum SigmaBoundary {
  BELOW_TWO_SIGMA = 'belowTwoSigma',
  BETWEEN_MINUS_ONE_AND_TWO_SIGMA = 'betweenMinusOneAndTwoSigma',
  WITHIN_ONE_SIGMA = 'withinOneSigma',
  BETWEEN_ONE_AND_TWO_SIGMA = 'betweenOneAndTwoSigma',
  ABOVE_TWO_SIGMA = 'aboveTwoSigma'
}

export type AdvancedFilterConfiguration = {
  includeOnly: Array<[string, string[]]>,
  min: Array<[string, number]>,
  max: Array<[string, number]>,
  propertyConfigs: Array<{ property: string, ranges: { from: number, to: number }[], rangeIndices: number[] }>,
  cohort: Array<{ property: string, sigmas: SigmaBoundary[] }>
};

export class AdvancedFilter {
  constructor(private readonly config: AdvancedFilterConfiguration) {}

  isExcluded(tree: Tree, windSpeed: number): boolean {
    return [
      ...this.config.includeOnly.map(it => new IncludeOnlyFilter(it)),
      ...this.config.min.map(it => new MinFilter(it)),
      ...this.config.max.map(it => new MaxFilter(it)),
      ...this.config.propertyConfigs.map(it => new PropertyConfigFilter(it)),
      ...this.config.cohort.map(it => new CohortFilter(it))
    ].some(it => it.applyTo(tree, windSpeed));
  }

  hasProperty(property: string): boolean {
    return [
      ...this.config.includeOnly.map(it => it[0]),
      ...this.config.min.map(it => it[0]),
      ...this.config.max.map(it => it[0]),
      ...this.config.propertyConfigs.map(it => it.property),
      ...this.config.cohort.map(it => it.property)
    ].includes(property);
  }
}

interface FilterStrategy {
  applyTo(tree: Tree, windSpeed: number): boolean
}

class IncludeOnlyFilter implements FilterStrategy {
  private readonly property: string;
  private readonly includedValues: string[];

  constructor(config: [property: string, includedValues: string[]]) {
    this.property = config[0];
    this.includedValues = config[1];
  }

  applyTo(tree: Tree, windSpeed: number): boolean {
    const propertyValue = tree.getPropertyValue(this.property, windSpeed);
    return propertyValue === null || !this.includedValues.includes(propertyValue);
  }
}

class MinFilter implements FilterStrategy {
  private readonly property: string;
  private readonly threshold: number;

  constructor(config: [property: string, threshold: number]) {
    this.property = config[0];
    this.threshold = config[1];
  }

  applyTo(tree: Tree, windSpeed: number): boolean {
    const propertyValue = tree.getPropertyValue(this.property, windSpeed);
    return propertyValue === null || this.threshold > propertyValue;
  }
}

class MaxFilter implements FilterStrategy {
  private readonly property: string;
  private readonly threshold: number;

  constructor(config: [property: string, threshhold: number]) {
    this.property = config[0];
    this.threshold = config[1];
  }

  applyTo(tree: Tree, windSpeed: number): boolean {
    const propertyValue = tree.getPropertyValue(this.property, windSpeed);
    return propertyValue === null || this.threshold < propertyValue;
  }
}

class PropertyConfigFilter implements FilterStrategy {
  constructor(private readonly config: {
    property: string,
    ranges: { from: number, to: number }[],
    rangeIndices: number[]
  }) {}

  applyTo(tree: Tree, windSpeed: number): boolean {
    const propertyValue = tree.getPropertyValue(this.config.property, windSpeed);
    if (typeof propertyValue !== 'number') return true;
    const ranges = this.config.rangeIndices.map(index => this.config.ranges[index]);
    if (ranges.some(range => range === undefined)) return true;
    return !ranges.some(range => range.from <= propertyValue && range.to > propertyValue);
  }
}

class CohortFilter implements FilterStrategy {
  constructor(private readonly config: {
    property: string,
    sigmas: SigmaBoundary[]
  }) {}

  applyTo(tree: Tree, windSpeed: number): boolean {
    const propertyValue = tree.getPropertyValue(this.config.property, windSpeed);
    const cohort = tree.cohort?.cohortValues[this.config.property];
    if (typeof propertyValue !== 'number' || !cohort) return true;
    const cohortConfig = new CohortConfig(cohort);

    return !this.config.sigmas.some(sigma => cohortConfig.isInBoundary(propertyValue, sigma));
  }
}
