import { useTranslation } from 'react-i18next';
import styles from '../../Inventory/Inventory.module.scss';
import DetailedTree from '../../../../../tree/DetailedTree';
import Accordion from '../../../../../components/UI/Accordion/Accordion';
import {
  CrownObservationOptionEnum,
  RootObservationOptionEnum,
  TrunkObservationOptionEnum
} from '../../../../Details/DataCollection/observationOptions';
import { DisplayableTreeProperty } from '../../../../../tree/Tree';

export default function Observations(props: ObservationsProps) {
  const { t } = useTranslation();
  const rootAndRootCollarDiseases = props.tree.observations.filter(it => Object.values(RootObservationOptionEnum).includes(it.name as RootObservationOptionEnum));
  const trunkDiseases = props.tree.observations.filter(it => Object.values(TrunkObservationOptionEnum).includes(it.name as TrunkObservationOptionEnum) || it.name === DisplayableTreeProperty.LeaningAngle);
  const crownAndBranchDiseases = props.tree.observations.filter(it => Object.values(CrownObservationOptionEnum).includes(it.name as CrownObservationOptionEnum) || it.name === DisplayableTreeProperty.LiveCrownRatio);

  return (
    <div className={`${styles.dataGroup} ${styles.flexContainer}`}>
      <div className={`${styles.header} ${styles.lessPadding}`}>
        <h3>{t('treeDetails.healthAndVitality.observations')}</h3>
      </div>
      <div className={props.singleColumn ? styles.singleColumn : styles.accordionPairsContainer}>
        <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
          <Accordion title={t('treeDetails.healthAndVitality.rootAndRootCollar')}>
            <ul>
              {rootAndRootCollarDiseases.length === 0
                ? <li className={styles.diseases}>-</li>
                : rootAndRootCollarDiseases.map(it =>
                  <li key={it.name} className={styles.diseases}>
                    {t('virtualInspection.observationOptions.' + it.name)}
                  </li>
                )}
            </ul>
          </Accordion>
          <Accordion title={t('treeDetails.healthAndVitality.trunk')}>
            <ul>
              {trunkDiseases.length === 0
                ? <li className={styles.diseases}>-</li>
                : trunkDiseases.map(it =>
                  <li key={it.name} className={styles.diseases}>
                    {t('virtualInspection.observationOptions.' + it.name)}
                  </li>
                )}
            </ul>
          </Accordion>
        </div>
        <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
          <Accordion title={t('treeDetails.healthAndVitality.crownAndBranch')}>
            <ul>
              {crownAndBranchDiseases.length === 0
                ? <li className={styles.diseases}>-</li>
                : crownAndBranchDiseases.map(it =>
                  <li key={it.name} className={styles.diseases}>
                    {t('virtualInspection.observationOptions.' + it.name)}
                  </li>
                )}
            </ul>
          </Accordion>
        </div>
      </div>
    </div>);
}

interface ObservationsProps {
  tree: DetailedTree,
  singleColumn: boolean
}
