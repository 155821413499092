import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ContextMenuContext } from './ContextMenuContext';

export function ContextMenuContent(props: { children: ReactNode | ReactNode[] | ((toggle: () => void) => ReactNode | ReactNode[]), className?: string }) {
  const ctx = useContext(ContextMenuContext);

  return (
    <ContextMenuPortal>
      <div data-context-menu-content={ctx.id} className="twp fixed z-50 left-0 top-0">
        {ctx.isOpen && (
          <div
            className={`bg-outer-space-600 mt-1.5 shadow-lg shadow-outer-space-950/80 ${props.className ?? ''}`.trimEnd()}
          >
            {props.children instanceof Function ? props.children(ctx.toggle) : props.children}
          </div>
        )}
      </div>

      {ctx.isOpen && <div className="twp fixed inset-0 z-10" onClick={ctx.toggle} />}
    </ContextMenuPortal>
  );
}

function ContextMenuPortal(props: { children: ReactNode }) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  if (!isMounted) return null;

  return createPortal(props.children, document.body);
}
