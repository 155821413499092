import TaskTemplate from './TaskTemplate';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import hashString from '../../../utils/hashString';

export function useTaskTemplates(organizationId: string): {
  taskTemplates: TaskTemplate[],
  isLoading: boolean,
  isError: boolean
} {
  const { taskManagerService, urlContext } = useContext(DependencyInjectionContext);

  const managedAreaIds = urlContext.getManagedAreaIds();
  const reversed = urlContext.getReverseMASelection();
  const cacheKey = `${organizationId}-task-templates`;
  const managedAreaIdsCacheKey = hashString([...managedAreaIds, reversed].join(''));
  const { data, isLoading, isPreviousData, isError } = useQuery(
    [cacheKey, managedAreaIdsCacheKey],
    () => {
      if (organizationId) {
        return taskManagerService.listTaskTemplates(organizationId);
      }
    },
    { retry: 1, retryOnMount: false, keepPreviousData: true }
  );

  return {
    taskTemplates: data ?? [],
    isLoading: isPreviousData || isLoading,
    isError
  };
}
