import { AdvancedFilterConfiguration, SigmaBoundary } from '../table-view/advanced-filter/AdvancedFilter';
import { Expression } from 'mapbox-gl';
import MapboxColor from './MapboxColor';
import CohortColor from '../../../components/cohort/CohortColor';
import { TrackableTreeProperty } from '../../../tree/TrackableTreeProperty';

export class AdvancedFilterColor implements MapboxColor {
  constructor(
    private readonly advancedFilterConfiguration: AdvancedFilterConfiguration,
    windSpeed: number
  ) {
    this.advancedFilterConfiguration = this.cleanConfiguration(advancedFilterConfiguration, windSpeed);
  }

  private cleanConfiguration(config: AdvancedFilterConfiguration, windSpeed: number): AdvancedFilterConfiguration {
    return {
      ...config,
      min: config.min.map(it => it[0] === 'safetyFactors' ? [`safetyFactors-${windSpeed}`, it[1]] : it),
      max: config.max.map(it => it[0] === 'safetyFactors' ? [`safetyFactors-${windSpeed}`, it[1]] : it),
      propertyConfigs: config.propertyConfigs.map(it => it.property === 'safetyFactors' ? { ...it, property: `safetyFactors-${windSpeed}` } : it)
    };
  }

  private getCondition(): Expression {
    if (this.getNumberOfRules() === 1) {
      return [
        ...this.advancedFilterConfiguration.includeOnly.flatMap(it => this.getRulesFromIncludeOnly(it)),
        ...this.advancedFilterConfiguration.min.flatMap(it => ['<', ['get', it[0]], it[1]]),
        ...this.advancedFilterConfiguration.max.flatMap(it => ['>', ['get', it[0]], it[1]]),
        ...this.advancedFilterConfiguration.propertyConfigs.flatMap(it => this.getRulesFromPropertyConfig(it)),
        ...this.advancedFilterConfiguration.cohort.flatMap(it => this.getRulesFromCohortConfig(it))
      ] as any;
    }

    return [
      'any',
      ...this.advancedFilterConfiguration.includeOnly.map(it => this.getRulesFromIncludeOnly(it)),
      ...this.advancedFilterConfiguration.min.map(it => ['<', ['get', it[0]], it[1]]),
      ...this.advancedFilterConfiguration.max.map(it => ['>', ['get', it[0]], it[1]]),
      ...this.advancedFilterConfiguration.propertyConfigs.map(it => this.getRulesFromPropertyConfig(it)),
      ...this.advancedFilterConfiguration.cohort.map(it => this.getRulesFromCohortConfig(it))
    ];
  }

  getRulesFromPropertyConfig(config: { property: string, ranges: { from: number, to: number }[], rangeIndices: number[] }): Expression | any[] {
    const propertyRanges = config.rangeIndices.map(i => this.createMapboxRangeConditionForProperty(config.property, config.ranges[i]));
    if (config.rangeIndices.length === 1) {
      return propertyRanges.flat();
    }
    return ['all', ...propertyRanges];
  }

  getRulesFromCohortConfig(config: { property: string, sigmas: SigmaBoundary[] }): Expression | any[] {
    const cohortRanges = config.sigmas.map(sigma => this.createMapboxCohortConditionForProperty(config.property, sigma));

    if (config.sigmas.length === 1) {
      return cohortRanges.flat();
    }
    return ['all', ...cohortRanges];
  }

  getRulesFromIncludeOnly(config: [string, string[]]): Expression | any[] {
    return ['!', ['in', ['get', this.getKey(config)], ['literal', config[1]]]];
  }

  createMapboxRangeConditionForProperty(property: string, range: { from: number, to: number }): Expression {
    return ['any', ['<', ['get', property], range.from], ['>', ['get', property], range.to]];
  }

  createMapboxCohortConditionForProperty(property: string, sigma: SigmaBoundary): Expression {
    return new CohortColor(property as TrackableTreeProperty).getAdvancedFilterConditionForBoundary(sigma);
  }

  getFillColorCase(): (Expression| string)[] {
    if (this.isEmpty()) {
      return [];
    }
    return [this.getCondition(), 'rgba(206, 215, 212, 0.1)'];
  }

  private isEmpty() {
    return this.getNumberOfRules() === 0;
  }

  getLineColorCase(): (Expression| string)[] {
    if (this.isEmpty()) {
      return [];
    }
    return [this.getCondition(), 'rgba(206, 215, 212, 0.5)'];
  }

  private getNumberOfRules() {
    return this.advancedFilterConfiguration.min.length
      + this.advancedFilterConfiguration.max.length
      + this.advancedFilterConfiguration.includeOnly.length
      + this.advancedFilterConfiguration.propertyConfigs.length
      + this.advancedFilterConfiguration.cohort.length;
  }

  private getKey(expression: [string, string[]]): string {
    return expression[0] === 'species' ? 'scientificName' : expression[0];
  }
}
