import { TileButton, TileButtonGroup } from '../TileLayout';
import { Minus, Plus } from 'iconoir-react';
import { StreetViewProps } from './useStreetViewProps';
import ResetButton from '../components/ResetButton';

export default function StreetViewRightBottomButtons(props: StreetViewProps) {
  return <>
    <ResetButton onClick={props.resetView} />
    <TileButtonGroup>
      <TileButton onClick={() => props.viewRef.current?.zoomIn()} icon={<Plus />} />
      <TileButton onClick={() => props.viewRef.current?.zoomOut()} icon={<Minus />} />
    </TileButtonGroup>
  </>;
}
