import { ReactNode } from 'react';

import MemberListHeader from './MemberListHeader';
import { MemberSortingDirection } from '../../../organization/Member';
import styles from './SortableMemberListHeader.module.scss';
import { CaretUpIcon, CaretDownIcon } from '../../UI/Icon/Icon';

export default function SortableMemberListHeader(props: SortableMemberListHeaderProps) {
  return (
    <MemberListHeader>
      <button className={styles.button} onClick={props.onClick}>
        <span>{props.children}</span>

        {!props.isActive && (
          <span className={styles.icons}>
            <CaretUpIcon />
            <CaretDownIcon />
          </span>
        )}

        {props.isActive && props.direction === MemberSortingDirection.DESCENDING && (
          <span className={styles.icons}>
            <CaretDownIcon />
          </span>
        )}

        {props.isActive && props.direction === MemberSortingDirection.ASCENDING && (
          <span className={styles.icons}>
            <CaretUpIcon />
          </span>
        )}
      </button>
    </MemberListHeader>
  );
}

interface SortableMemberListHeaderProps {
  children: ReactNode,
  isActive: boolean,
  direction: MemberSortingDirection,
  onClick: () => unknown
}
