
import { FunctionButton } from '../Button/LegacyButton';
import { SortingDownIcon, SortingIcon, SortingUpIcon } from '../Icon/Icon';

export enum SortingDirection {
  DESCENDING = 'desc',
  ASCENDING = 'asc'
}
export const SortButton = (props: SortButtonProps) => {
  return (
    <FunctionButton
      onClick={props.onClick}
      className="flex items-center p-2"
    >
      {!props.sortingDirection && <SortingIcon />}
      {props.sortingDirection === SortingDirection.DESCENDING && <SortingDownIcon />}
      {props.sortingDirection === SortingDirection.ASCENDING && <SortingUpIcon />}
    </FunctionButton>
  );
};

export interface SortButtonProps {
  sortingDirection: SortingDirection | null | undefined,
  onClick: () => void
}
