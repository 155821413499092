import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { Menu, Search } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import styles from './Explore.module.scss';
import { useCurrentAccount } from '../../account/useAccounts';
import LegacyPageLayout from '../../components/UI/Page/LegacyPageLayout';
import { FunctionButton } from '../../components/UI/Button/LegacyButton';
import MapViewer from './map-view/MapViewer';
import { MapStyle } from './components/MapStyleSelector';
import MapToolbar from './components/MapToolbar';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import { DisplayableTreeProperty, TreeDisplayConfiguration } from '../../tree/Tree';
import useTree from '../../tree/useTree';
import { PanoramicViewer } from './panoramic-view/PanoramicViewer';
import TableViewer from './table-view/TableViewer';
import { PanelLayout } from './PanelLayout';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import ReportDialog from './reports/ReportDialog';
import { SidebarFilter } from '../../components/Navbar/SidebarFilter/SidebarFilter';
import AbsoluteModal from '../../components/Modal/absolute-modal/AbsoluteModal';
import TreeInfo from './components/TreeInfo';
import LegacyPropertyLegend from '../../components/Navbar/LegacyPropertyLegend';
import PropertySelectorDropdownMenu from '../../components/Navbar/PropertySelectorDropdownMenu';
import { usePropertyConfigurations } from '../../properties/usePropertyConfigurations';
import mapboxgl from 'mapbox-gl';
import { useTracking } from '../../analytics/useTracking';
import { Flippers } from '../../switches/Flippers';
import LegacySearchBar from '../../components/Navbar/LegacySearchBar/LegacySearchBar';
import PropertyLegend from '../../components/Navbar/PropertyLegend';
import { SigmaBoundary } from './table-view/advanced-filter/AdvancedFilter';
import LegacyTableViewer from './table-view/LegacyTableViewer';
import BallGridLoader from '../../components/UI/BallGridLoader/BallGridLoader';
import useMountedEffect from '../../hooks/useMountedEffect';
import Workspace from './workspace/Workspace';
import useManagedAreas from '../../managed-area/useManagedAreaList';

export default function Explore() {
  const navigate = useNavigate();
  const panelLayout = useRef(new PanelLayout());
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';
  const treeId = urlContext.getTreeId() ?? '';
  const urlContextFingerPrint = urlContext.serialize();
  const { treeFilterService } = useContext(DependencyInjectionContext);
  const { tree, isTreeLoading } = useTree(organizationId, treeId);
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const propertyConfigs = usePropertyConfigurations();
  const { events, track } = useTracking();
  const { managedAreaList } = useManagedAreas(organizationId);

  // useStates

  const [treeFilters, setTreeFilters] = useState<TreeFilter[] | null>(null);
  const [, setTableDragDistance] = useState<number | null>(null);
  const [selectedTreePropertyRangeIndex, setSelectedTreePropertyRangeIndex] = useState(-1);
  const [selectedPropertyCohortBoundary, setSelectedPropertyCohortBoundary] = useState<SigmaBoundary | null>(null);
  const [hideMarkers, setHideMarkers] = useState(false);
  const [hideTreePointCloud, setHideTreePointCloud] = useState(false);
  const [useLineMeasurement, setUseLineMeasurement] = useState(false);
  const [mapResizeTriggerKey, triggerMapSizeChange] = useState('');
  const [mapResetTriggerKey, triggerMapReset] = useState<{ key?: number, on?: 'tree' | 'managedArea' | 'organization' }>({});
  const [searching, setSearching] = useState(false);
  const [isLoading, setLoadingState] = useState(false);

  // Variables

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();
  const mapStyle = getCurrentMapStyle(urlContext.getStyle());
  const isMapStylePanoramic = mapStyle === MapStyle.Panoramic;
  const selectedTreeFilterIds = urlContext.getTreeFilterIds();

  const managedAreaIdsInURL = urlContext.getManagedAreaIds();
  const selectedManagedAreas = useMemo(() => {
    const ManagedAreasInURL = urlContext.getManagedAreaIds();
    return (managedAreaList ?? []).filter(it => urlContext.getReverseMASelection() ? !ManagedAreasInURL.includes(it.id) : ManagedAreasInURL.includes(it.id));
  }, [JSON.stringify(urlContext.getReverseMASelection()), managedAreaList, JSON.stringify(urlContext.getManagedAreaIds())]);

  const selectedManagedAreaIds = useMemo(() => selectedManagedAreas.map(it => it.id), [selectedManagedAreas]);

  const selectedTreeFilters = useMemo(() => (treeFilters ?? []).filter(it => urlContext.isTreeFilterSelected(it)), [treeFilters, urlContextFingerPrint]);

  const treeDisplayConfiguration: TreeDisplayConfiguration = {
    property: selectedTreeProperty,
    managedAreaIds: urlContext.getManagedAreaIds(),
    isManagedAreaSelectionReversed: urlContext.getReverseMASelection(),
    filters: selectedTreeFilters,
    windSpeed: urlContext.getWindSpeed() || account.getDefaultWindSpeed()
  };

  const selectedTreeFilterProperties: DisplayableTreeProperty[] = useMemo(() => {
    const treeProperties = urlContext.getVisibleTableProperties() || Array.from(new Set(selectedTreeFilters
      .flatMap(it => it.getPropertyNames() as DisplayableTreeProperty[])
      .filter(it => !['species', 'externalId'].includes(it))));

    if (selectedTreeProperty && !treeProperties.includes(selectedTreeProperty)) {
      treeProperties.unshift(selectedTreeProperty);
    }
    return treeProperties;
  }, [selectedTreeFilters, selectedTreeProperty]);

  // useEffects
  useEffect(() => {
    const searchParams = window.location.href.split('?')[1];
    if (account.organization.isEnabled(Flippers.workspace)) {
      navigate(`/organizations/${organizationId}/inventory${searchParams ? '?' + searchParams : ''}`);
    }
  }, [account.organization, organizationId, navigate]);

  useEffect(() => {
    panelLayout.current.closeTable();
  }, [organizationId]);

  useEffect(() => {
    if (urlContext.getTreeTableOpen()) {
      panelLayout.current.openTable();
      urlContext.setSidebarOpen(false);
    }
    if (urlContext.getSidebarOpen()) {
      panelLayout.current.openSidebar();
      urlContext.setTreeTableOpen(false);
    }
  }, [urlContextFingerPrint]);

  useEffect(() => {
    mapboxgl.prewarm();
    return () => {
      mapboxgl.clearPrewarmedResources();
    };
  }, []);

  useEffect(() => {
    treeFilterService.list(organizationId).then(setTreeFilters);
  }, [organizationId]);

  useMountedEffect(() => {
    if (selectedManagedAreaIds.length === 0 && selectedTreeFilterIds.length === 0 && !account.organization.isEnabled(Flippers.workspace)) {
      panelLayout.current.closeTable();
      urlContext.setTreeTableOpen(false);
    } else if (urlContext.getTreeTableOpen()) {
      panelLayout.current.openTable();
    }
  }, [selectedManagedAreaIds.concat(selectedTreeFilterIds).join('')]);

  useEffect(() => {
    if (tree === null) return;
    urlContext.setPositionFromTree(tree);
  }, [tree]);

  useEffect(() => {
    const title = account.organization.name + ' - greehill';
    document.title = title;
    if (tree) document.title = tree?.externalId + ' - ' + title;
  }, [tree, account]);
  const [tableResizeTrigger, setTableResizeTrigger] = useState<number>(0);

  useEffect(() => {
    const gracefulMapResizerTimer = setInterval(
      () => requestAnimationFrame(() => {
        triggerMapSizeChange(Math.random().toString());
      }),
      2
    );
    //Resizing needs more time to occur than 120ms in cleanupTimer
    const cleanupTimer = setTimeout(() => clearInterval(gracefulMapResizerTimer), 250);
    return () => {
      clearInterval(gracefulMapResizerTimer);
      clearTimeout(cleanupTimer);
      triggerMapSizeChange(Math.random().toString());
    };
  }, [JSON.stringify(urlContext.getTreeTableOpen()), tableResizeTrigger]);

  // Functions

  const resetView = () => {
    if (urlContext.getTreeId()) {
      triggerMapReset({ key: Math.random(), on: 'tree' });
      return;
    }

    if (isMapStylePanoramic) return;

    if (managedAreaList && managedAreaList?.some(it => urlContext.isManagedAreaSelected(it))) {
      triggerMapReset({ key: Math.random(), on: 'managedArea' });
      return;
    }

    triggerMapReset({ key: Math.random(), on: 'organization' });
  };

  const closeSidebar = () => {
    urlContext.setSidebarOpen(false);
    urlContext.setTreeTableOpen(false);
    panelLayout.current.closeSidebar();
    track(events.SIDEBAR_CLOSED);
  };

  const openSidebar = () => {
    urlContext.setSidebarOpen(true);
    urlContext.setTreeTableOpen(false);
    panelLayout.current.openSidebar();
    track(events.SIDEBAR_OPENED);
  };

  const handleTableTreeSelect = id => {
    track(events.TREE_SELECT_FROM_TABLE, { treeId: id });
    urlContext.setTreeId(id);
    urlContext.setCapturePointId(null);
    urlContext.setCameraRotation(null);
  };

  return (
    <LegacyPageLayout>
      <LegacyPageLayout.LeftNavExtensions>
        {account.organization.isEnabled(Flippers.workspace)
          ? <>
            <Link to={`/organizations/${organizationId}/map`} className={`${styles.navLink} ${styles.active}`}>
              {t('mainMenu.insights')}
            </Link>
            <Link to={`/organizations/${organizationId}/task-manager`} className={styles.navLink}>
              {t('mainMenu.taskManager')}
            </Link>
          </>
          : <>
            <FunctionButton
              className={styles.navHamburgerMenu}
              icon={<Menu />}
              testId={'sidebar-open-button'}
              onClick={openSidebar} />
            {account.organization.id && !account.organization.isEnabled(Flippers.dashboardRedesign) && <PropertySelectorDropdownMenu />}
          </>
        }
      </LegacyPageLayout.LeftNavExtensions>
      <LegacyPageLayout.CenterNavExtensions>
        {searching || !selectedTreeProperty
          ? <></>
          :
          <div
            className={styles.propertyLegendContainer}
            style={panelLayout.current.getPropertyLegendContainerStyle(Boolean(treeId))}
            data-testid='property-legend'
          >
            {
              account.organization.isEnabled(Flippers.dashboardRedesign) ?
                <PropertyLegend
                  hasTableData={selectedManagedAreaIds.length !== 0 || selectedTreeFilters.length !== 0}
                  selectedProperty={selectedTreeProperty}
                  selectedTreePropertyRangeIndex={selectedTreePropertyRangeIndex}
                  setSelectedTreePropertyRangeIndex={setSelectedTreePropertyRangeIndex}
                  selectedPropertyCohortBoundary={selectedPropertyCohortBoundary}
                  setSelectedPropertyCohortBoundary={setSelectedPropertyCohortBoundary}
                  advancedFilterConfiguration={urlContext.getAdvancedFilterConfiguration()}
                /> :
                <LegacyPropertyLegend
                  hasTableData={selectedManagedAreaIds.length !== 0 || selectedTreeFilters.length !== 0}
                  selectedProperty={selectedTreeProperty}
                  selectedTreePropertyRangeIndex={selectedTreePropertyRangeIndex}
                  setSelectedTreePropertyRangeIndex={setSelectedTreePropertyRangeIndex}
                />
            }
          </div>
        }
      </LegacyPageLayout.CenterNavExtensions>
      <LegacyPageLayout.RightNavExtensions>
        {
          mapStyle !== MapStyle.Panoramic &&
          <FunctionButton
            testId='navbar-search-button'
            className={styles.navSearchButton}
            icon={<Search />}
            onClick={() => setSearching(true)} />
        }

        <AbsoluteModal
          isVisible={searching}
          onHide={() => setSearching(false)}
        >
          <LegacySearchBar
            managedAreas={managedAreaList}
            organization={account.organization}
            visible={searching}
            onResultFound={() => setSearching(false)}
          />
        </AbsoluteModal>
      </LegacyPageLayout.RightNavExtensions>

      <LegacyPageLayout.Content>
        <div className={styles.container}>
          {isLoading && (
            <div className={styles.mapLoader}>
              <BallGridLoader />
            </div>
          )}
          <SidebarFilter
            managedAreas={(managedAreaList ?? [])}
            selectedManagedAreaIds={selectedManagedAreaIds}
            visible={urlContext.getSidebarOpen()}
            closeSidebar={closeSidebar}
            treeFilters={treeFilters ?? []}
            setTreeFilters={setTreeFilters}
          />
          {treeId !== '' && (
            <div
              className={styles.treeInfoContainer}
              style={panelLayout.current.getTreeInfoStyle()}
              data-testid={'tree-info-container'}
            >
              <TreeInfo
                tree={tree}
                isTreeLoading={isTreeLoading}
                isOpen={!panelLayout.current.isTableVisible()} />
            </div>
          )
          }

          <div className={styles.mapAndControlsContainer}>
            <div className={styles.mapControls} style={panelLayout.current.getControlsContainerStyle()}>
              <MapToolbar
                style={mapStyle}
                onResetView={resetView}
                onTreeMarkerSwitch={() => setHideMarkers(prev => !prev)}
                onTreePointCloudSwitch={() => setHideTreePointCloud(prev => !prev)}
                treeMarkersEnabled={hideMarkers}
                treePointCloudEnabled={hideTreePointCloud}
                onLineMeasurementSwitch={() => setUseLineMeasurement(prev => !prev)}
                lineMeasurementEnabled={useLineMeasurement}
                lineMeasurementSwitchDisabled={!urlContext.getTreeId() || !isMapStylePanoramic}
                resetViewSwitchDisabled={!urlContext.getTreeId() && isMapStylePanoramic}
              />
            </div>

            {account.isEnabled(Flippers.workspace)
              ?
              <div className={styles.treeListTableContainer} style={panelLayout.current.getTableContainerStyle()}>
                <Workspace
                  setTableDragDistance={setTableDragDistance}
                  open={urlContext.getTreeTableOpen()}
                  filters={selectedTreeFilters}
                  properties={selectedTreeFilterProperties}
                  onSelect={handleTableTreeSelect}
                  panelLayoutRef={panelLayout.current}
                  selectedTreeId={urlContext.getTreeId()}
                  setTableResizeTrigger={setTableResizeTrigger}
                  windSpeed={urlContext.getWindSpeed() || account.getDefaultWindSpeed()}
                  areaFilterIsSelected={selectedManagedAreaIds.length > 0}
                />
              </div>
              : <div className={styles.treeListTableContainer} style={panelLayout.current.getTableContainerStyle()}>
                {(selectedManagedAreaIds.length !== 0 || selectedTreeFilters.length !== 0)
                  ? account.isEnabled(Flippers.dashboardRedesign) ? <TableViewer
                    setTableDragDistance={setTableDragDistance}
                    open={urlContext.getTreeTableOpen()}
                    managedAreas={selectedManagedAreas}
                    filters={selectedTreeFilters}
                    properties={selectedTreeFilterProperties}
                    onSelect={handleTableTreeSelect}
                    panelLayoutRef={panelLayout.current}
                    selectedTreeId={urlContext.getTreeId()}
                    setTableResizeTrigger={setTableResizeTrigger}
                    windSpeed={urlContext.getWindSpeed() || account.getDefaultWindSpeed()}
                  /> : <LegacyTableViewer
                    setTableDragDistance={setTableDragDistance}
                    open={urlContext.getTreeTableOpen()}
                    managedAreas={selectedManagedAreas}
                    filters={selectedTreeFilters}
                    properties={selectedTreeFilterProperties}
                    onSelect={handleTableTreeSelect}
                    panelLayoutRef={panelLayout.current}
                    selectedTreeId={urlContext.getTreeId()}
                    setTableResizeTrigger={setTableResizeTrigger}
                    windSpeed={urlContext.getWindSpeed() || account.getDefaultWindSpeed()}
                  />
                  : ''}
              </div>
            }

            {!propertyConfigs.isLoading && <div className={styles.mapContainer} style={panelLayout.current.getMapContainerStyle()}>
              {
                !isMapStylePanoramic &&
                <MapViewer
                  tableShouldSnapToTop={panelLayout.current.tableShouldSnapToTop()}
                  resizeTriggerKey={mapResizeTriggerKey}
                  mapResetTriggerKey={mapResetTriggerKey}
                  organization={account.organization}
                  selectedManagedAreas={selectedManagedAreas}
                  managedAreaIdsInURL={managedAreaIdsInURL}
                  treeDisplayConfiguration={treeDisplayConfiguration}
                  selectedPropertyConfig={propertyConfigs.data!.find(it => it.property === selectedTreeProperty) ?? null}
                  selectedTreePropertyRangeIndex={selectedTreePropertyRangeIndex}
                  selectedPropertyCohortBoundary={selectedPropertyCohortBoundary}
                  style={mapStyle}
                  selectedTreeId={treeId}
                  treeClusteringPieChartProperty={selectedTreeProperty}
                  showIsoMap={urlContext.isIsoMapEnabled()}
                  hideMarkers={hideMarkers}
                  onLoading={setLoadingState}
                />
              }
              {
                isMapStylePanoramic &&
                <PanoramicViewer
                  isLineMeasureEnabled={useLineMeasurement}
                  tree={tree}
                  hideLabels={!urlContext.areTreeMarkersVisible()}
                  hideMarkers={hideMarkers}
                  hideTreePointCloud={hideTreePointCloud}
                  viewResetTriggerKey={mapResetTriggerKey}
                  selectedPropertyConfig={propertyConfigs.data!.find(it => it.property === selectedTreeProperty) ?? null}
                  selectedTreePropertyRangeIndex={selectedTreePropertyRangeIndex}
                  treeDisplayConfiguration={treeDisplayConfiguration}
                />
              }
            </div>}
          </div>
        </div>
        {urlContext.getReportOpen() &&
          <ReportDialog
            onHide={() => urlContext.setReportOpen(false)}
            managedAreas={selectedManagedAreas}
            filters={selectedTreeFilters}
          />}
      </LegacyPageLayout.Content>
    </LegacyPageLayout>
  );
}

function getCurrentMapStyle(mapStyleFromContext: MapStyle) {
  return Object.values(MapStyle).includes(mapStyleFromContext) ? mapStyleFromContext : MapStyle.Default;
}
