import { UserDto } from './UserDto';

export class User {
  static anonymous() {
    return new Anonymous();
  }

  static fromDto(dto: UserDto) {
    return new User(dto.id, dto.email, dto.firstName, dto.lastName, dto.language, dto.superOwner, dto.translatorFor);
  }

  constructor(
    readonly id: string,
    readonly email: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly language: string,
    readonly superOwner: boolean,
    readonly translatorFor: string[]
  ) {}

  isLoggedIn() {
    return true;
  }

  getInitials() {
    return `${(this.firstName || ' ')[0]}${(this.lastName || ' ')[0]}`.trim();
  }

  getName() {
    return [this.firstName, this.lastName].join(' ').trim();
  }

  isSuperOwner() {
    return this.superOwner;
  }

  isTranslator() {
    return this.translatorFor?.length > 0;
  }

  isTranslatorFor(language: string) {
    return this.translatorFor.includes(language);
  }
}

class Anonymous extends User {
  constructor() {
    super('', '', '', '', '', false, []);
  }

  override isLoggedIn() {
    return false;
  }
}
