import React, { MouseEvent as ReactMouseEvent, ReactNode } from 'react';
import { CaretDownIcon } from '../Icon/Icon';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { ContextMenuTrigger } from '../ContextMenu/ContextMenuTrigger';
import { ContextMenuContent } from '../ContextMenu/ContextMenuContent';

export default function DropdownButtonGroup({
  children,
  label,
  onClick
}: DropdownButtonGroupProps) {
  return <>
    <ContextMenu className="twp inline-flex bg-greehill-600 rounded-full justify-center w-fit b-none">
      <button
        onClick={onClick}
        className="rounded-tl-full rounded-bl-full button p-2 pl-4 flex justify-center items-center hover:bg-greehill-700 text-greehill-00 w-2/3"
      >
        {label}
      </button>
      <ContextMenuTrigger className="inline-flex w-1/3">
        <span
          className={'rounded-tr-full rounded-br-full p-2 pr-3 flex justify-center items-center hover:bg-greehill-700 text-greehill-00 w-full h-full'}
        >
          <CaretDownIcon />
        </span>
      </ContextMenuTrigger>

      <ContextMenuContent className="rounded-lg max-w-72 overflow-hidden">
        <ul className="flex flex-col gap-1.5 py-2 max-h-80 overflow-y-auto">
          {React.Children.map(children, child => <li>{child}</li>)}
        </ul>
      </ContextMenuContent>
    </ContextMenu>
  </>;
}

export function DropdownButton({
  label,
  onClick
}: DropdownButtonProps) {
  return <button
    onClick={onClick}
    className={'px-4 py-1.5 truncate w-full text-left leading-6 text-greehill-00 focus:outline-none focus:bg-outer-space-500/60 hover:bg-outer-space-500'}
  >
    {label}
  </button>;
}

interface DropdownButtonProps {
  label: string,
  onClick?: (event: ReactMouseEvent) => unknown
}

interface DropdownButtonGroupProps {
  children: ReactNode[] | ReactNode,
  label: string,
  onClick?: (event: ReactMouseEvent) => unknown
}
