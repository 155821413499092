import styles from '../../Explore.module.scss';
import { MeterPerPixelScale } from './MeterPerPixelScale';
import React, { useEffect, useState } from 'react';
import mapboxgl, { MapboxEvent } from 'mapbox-gl';

function Component(props: { currentMapRef: mapboxgl.Map | null } ) {
  const [scale, setScale] = useState<MeterPerPixelScale | null>(null);

  useEffect(() => {
    const calculateScale = (e: MapboxEvent) => {
      const bounds = e.target.getBounds();
      const visibleHorizontalDistance = bounds.getNorthWest().distanceTo(bounds.getNorthEast());
      const canvas = e.target.getCanvas();
      const canvasWidth = canvas.getBoundingClientRect().width;

      const meterPerPixelScale = MeterPerPixelScale.calculateFor(visibleHorizontalDistance / canvasWidth);
      setScale(meterPerPixelScale);
    };
    props.currentMapRef?.on('zoomend', calculateScale);
    
    return (() => {
      props.currentMapRef?.off('zoomend', calculateScale);
    });
  }, [props.currentMapRef]);

  if (!scale) return <></>;

  return (
    <div className={styles.barScale}>
      <div className={styles.barScaleValues} style={{ width: `${scale.width}px` }}>
        <div><span>0</span></div>
        <div><span>{scale.value / 2}</span></div>
        <div><span>{scale.value}{scale.unit}</span></div>
      </div>

      <div className={styles.barScaleIndicator} style={{ width: `${scale.width}px` }}>
        <div className={styles.evenItem}></div>
        <div className={styles.evenItem}></div>
      </div>
    </div>
  );
}

export const BarScale = React.memo(Component);
