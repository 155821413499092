import { useContext } from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import DependencyInjectionContext from '../DependencyInjectionContext';
import { Task } from './Task';
import { useLocation } from 'react-router-dom';

export function useTask(organizationId: string, taskId?: string): { task: Task | null, isLoading: boolean, refetch: () => Promise<QueryObserverResult<Task | null, unknown>> } {
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const location = useLocation();
  const isTasksPage = location.pathname.includes('task-manager/manage');

  const cacheKey = `${organizationId}-task-${taskId}`;
  const { data, isLoading, refetch } = useQuery(
    [cacheKey],
    () => {
      if (!taskId) return Promise.resolve(null);
      return taskManagerService.getTask(organizationId, taskId);
    },
    {
      initialData: null,
      retry: 1,
      retryOnMount: false,
      refetchInterval: isTasksPage ? 10000 : false
    }
  );

  return {
    task: data as (Task | null),
    isLoading,
    refetch
  };
}
