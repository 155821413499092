import { Check, EmptyPage, Group, Plus } from 'iconoir-react';
import styles from './InfoTable.module.scss';
import { Organization } from '../../../organization/Organization';
import LegacyButton, { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CaretDownIcon, CaretUpIcon } from '../../../components/UI/Icon/Icon';
import { SortingDirection, SuperOwnerPageContext } from '../SuperOwnerPage';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';

export default function InfoTable(props: InfoTableProps) {
  const { sortedOrganizations, sortingProperty, sortingDirection, onSort } = useContext(SuperOwnerPageContext);
  const SortableHeaderCell = (props: SortableHeaderCellProps) => {
    return (
      <th>
        <button className={styles.button} onClick={() => onSort(props.property)}>
          <span>{props.children}</span>
          {!(props.property === sortingProperty) && (
            <span className={styles.icons}>
              <CaretUpIcon />
              <CaretDownIcon />
            </span>
          )}
          {(props.property === sortingProperty) && sortingDirection === SortingDirection.DESCENDING && (
            <span className={styles.icons}>
              <CaretDownIcon />
            </span>
          )}
          {(props.property === sortingProperty) && sortingDirection === SortingDirection.ASCENDING && (
            <span className={styles.icons}>
              <CaretUpIcon />
            </span>
          )}
        </button>
      </th>
    );
  };

  const hasAccountInOrganization = (organizationId: string) => {
    return props.orgIdsForUser.includes(organizationId);
  };

  return (
    <div className={styles.infoTable}>
      <table>
        <thead>
          <tr>
            <SortableHeaderCell property={'name'}>NAME</SortableHeaderCell>
            <SortableHeaderCell property={'isDemo'}>DEMO</SortableHeaderCell>
            <SortableHeaderCell property={'currency'}>CURRENCY</SortableHeaderCell>
            <SortableHeaderCell property={'isMetric'}>UNIT</SortableHeaderCell>
            <SortableHeaderCell property={'id'}>ID</SortableHeaderCell>
            <th colSpan={2}>
            MEMBERS
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedOrganizations.map(organization =>
            <tr key={organization.id}>
              <td>
                {hasAccountInOrganization(organization.id)
                  ? (<Link to={`/organizations/${organization.id}/map`}>{organization.name}</Link>)
                  : organization.name}
              </td>
              <td>{organization.isDemo ? <Check /> : ''}</td>
              <td>{organization.currency}</td>
              <td>{organization.isMetric ? 'METRIC' : 'IMPERIAL'}</td>
              <td>
                <div className={styles.orgIdCell}>
                  {organization.id}
                  <Tooltip overlay="Copy">
                    <span>
                      <FunctionButton
                        icon={<EmptyPage />}
                        onClick={() => navigator.clipboard.writeText(organization.id)}
                      />
                    </span>
                  </Tooltip>
                </div>
              </td>
              <td colSpan={hasAccountInOrganization(organization.id) ? 2 : 1} className={styles.buttonCell}>
                <LegacyButton
                  variant={'secondary'}
                  className={styles.manageMembersButton}
                  onClick={() => props.manageMembers(organization)}
                >
                  <Group />
                </LegacyButton>
              </td>
              {!hasAccountInOrganization(organization.id) && (
                <td>
                  <FunctionButton
                    className={styles.addMeButton}
                    icon={<Plus />}
                    onClick={() => props.createAccountInOrganization(organization.id)}
                  >
                  Add me
                  </FunctionButton>
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
    </div>);
}

export interface InfoTableProps {
  manageMembers: (org: Organization) => void,
  orgIdsForUser: string[],
  createAccountInOrganization: (orgId: string) => Promise<void>
}

interface SortableHeaderCellProps {
  children: ReactNode | ReactNode[],
  property: string
}
