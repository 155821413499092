import { Children, Fragment, ReactElement, ReactNode } from 'react';
import styles from './ReferenceChart.module.scss';

export default function ReferenceChart(props: { children: ReactNode, legendLabel: string, large?: true }) {
  const data = Children.map(props.children, it => it)
    ?.filter((it): it is ReactElement => it['type'])
    .filter(it => it.type === ReferenceData);

  return (
    <div className={props.large ? styles.large : ''}>
      <div className={styles.legendLabel}>
        <div>{props.legendLabel}</div>
      </div>

      <div>{data}</div>
    </div>
  );
}

export function ReferenceData(props: { value: number, historicalValue: number | null, children: ReactNode }) {
  const showHistoricalValue = props.historicalValue !== null;

  return (
    <div className={styles.data}>
      <div className={styles.valueContainer.concat(' ', showHistoricalValue ? styles.historical : '')}>
        <Value value={props.value} />
        {showHistoricalValue && <HistoricalValue value={props.historicalValue!} />}
      </div>

      <div className={styles.labelContainer.concat(' ', showHistoricalValue ? styles.historical : '')}>
        <div>{props.children}</div>
      </div>
    </div>
  );
}

function Value({ value }: { value: number }) {
  if (value > 0) {
    return (
      <>
        <div />

        <div className={styles.positiveValue}>
          <span
            className={styles.fill}
            style={{ width: `${value}%` }}
            data-tooltip={value.toFixed(1).concat('%')} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.negativeValue}>
        <span
          className={styles.fill}
          style={{ width: `${-value}%` }}
          data-tooltip={value.toFixed(1).concat('%')} />
      </div>

      <div />
    </>
  );
}

function HistoricalValue({ value }: { value: number }) {
  if (value > 0) {
    return (
      <>
        <div />

        <div className={styles.positiveValue.concat(' ', styles.historical)}>
          <span
            className={styles.fill}
            style={{ width: `${value}%` }}
            data-tooltip={value.toFixed(1).concat('%')} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.negativeValue.concat(' ', styles.historical)}>
        <span
          className={styles.fill}
          style={{ width: `${-value}%` }}
          data-tooltip={value.toFixed(1).concat('%')} />
      </div>

      <div />
    </>
  );
}
