import styles from './LegacyTableDashboard.module.scss';
import Modal from '../../../../components/Modal/Modal';
import AdvancedFilterSummary from './AdvancedFilterSummary';
import { FunctionButton } from '../../../../components/UI/Button/LegacyButton';
import { PagePlus, Reports, ViewColumns3 } from 'iconoir-react';
import TableDownload from '../table-download/TableDownload';
import LegacyLazyTreeList from '../../LegacyLazyTreeList';
import { useContext, useRef, useState } from 'react';
import { useAvailableProperties } from '../../../../properties/usePropertyConfigurations';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useTranslation } from 'react-i18next';
import { Property } from '../TableViewer';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import ColumnSelector from '../../components/ColumnSelector';
import { DisplayableTreeProperty } from '../../../../tree/Tree';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { useTracking } from '../../../../analytics/useTracking';
import LazyTreeList from '../../LazyTreeList';

export default function LegacyTableDashboard(props: LegacyTableDashboardProps) {
  const { t } = useTranslation();
  const { events, track } = useTracking();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const organization = useCurrentAccount().organization;

  const [advancedSummaryOpen, setAdvancedSummaryOpen] = useState(false);
  const [isColumnSelectorOpen, setColumnSelectorOpen] = useState(false);
  const availableProperties = useAvailableProperties();

  const columnSelectorPositionSpanRef = useRef<HTMLSpanElement | null>(null);
  const absoluteModalPosition = columnSelectorPositionSpanRef.current?.getBoundingClientRect() ?? { top: 0, left: 0 };

  const toggleColumnSelector = (isOpened: boolean) => {
    setColumnSelectorOpen(isOpened);
    if (isOpened) {
      track(events.TABLE_COLUMN_SELECTOR_OPEN);
    } else {
      track(events.TABLE_COLUMN_SELECTOR_CLOSE);
    }
  };

  const generateReport = () => {
    urlContext.setReportOpen(true);
    track(events.REPORT_GENERATED_IN_TABLE);
  };

  return (
    <>
      <div className={styles.listDashboardContainer} data-testid="table-dashboard">
        <div className={styles.dashboardMetadata}>
          <span
            onClick={() => props.advancedFilterCountWithIcon && setAdvancedSummaryOpen(true)}
            className={styles.advancedSummaryButton}
          >
            {props.advancedFilterCountWithIcon}
          </span>
          {props.metaDataDescription}
        </div>
        <Modal
          isVisible={advancedSummaryOpen}
          onHide={() => setAdvancedSummaryOpen(false)}
          className={styles.advancedSummaryModal}>
          <AdvancedFilterSummary
            propertyConfigurations={props.propertyConfigs}
            onHide={() => setAdvancedSummaryOpen(false)}
            managedAreas={props.treeList?.getManagedAreas() || []}
            species={props.treeList?.getSpecies() || []}
            properties={availableProperties} />
        </Modal>
        <>
          <span ref={columnSelectorPositionSpanRef} style={{ width: '1px', visibility: 'hidden' }}></span>
          <FunctionButton
            icon={<ViewColumns3></ViewColumns3>}
            className={styles.functionButton}
            onClick={() => toggleColumnSelector(true)}
            testId="select-columns-button"
          >
            {t('treeList.selectColumns').toUpperCase()}
          </FunctionButton>
        </>
        {
          props.treeList ?
            <TableDownload
              properties={props.properties}
              treeList={props.treeList}
              windSpeed={props.windSpeed} />
            : null
        }
        <FunctionButton
          className={styles.functionButton}
          onClick={generateReport}
          icon={<Reports />}
          testId="table-report-button"
        >
          {t('reporting.generateReport').toUpperCase()}
        </FunctionButton>
        { organization.isDemo ?
          <FunctionButton
            icon={<PagePlus></PagePlus>}
            className={styles.functionButton}
            disabled={true}
          >
            {t('treeList.createTask').toUpperCase()}
          </FunctionButton>
          : null
        }
      </div>
      {isColumnSelectorOpen &&
        <ColumnSelector
          isModalOpen={isColumnSelectorOpen}
          onModalClose={() => toggleColumnSelector(false)}
          properties={props.displayableTreeProperties}
          onChange={properties => {
            urlContext.setVisibleTableProperties(properties);
          }}
          left={absoluteModalPosition.left}
          top={absoluteModalPosition.top}
        />}

    </>
  );
}

interface LegacyTableDashboardProps {
  advancedFilterCountWithIcon: JSX.Element | null,
  treeList: LazyTreeList | LegacyLazyTreeList | null,
  windSpeed: number,
  properties: Property[],
  metaDataDescription: string,
  propertyConfigs: PropertyConfiguration[],
  displayableTreeProperties: DisplayableTreeProperty[]
}
