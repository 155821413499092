import styles from './ImageCounter.module.scss';
import { Dispatch, SetStateAction } from 'react';

export default function ImageCounter(props: ImageCounterProps) {
  const calculatedWidth = 100 / props.imagesLength;
  return (
    <div className={`${styles.imageCounter} ${props.oneThirdView ? styles.oneThirdView : ''}`}>
      {[...new Array(props.imagesLength)].map((item, index) =>
        <div
          className={styles.marker}
          style={{ width: `${calculatedWidth}%` }}
          key={`marker-${index}`}
          onClick={() => props.setActiveIndex(index)}
        >
          <div className={`${styles.innerMarker} ${props.activeIndex === index ? styles.activeMarker : ''}`}/>
        </div>
      )}
    </div>
  );
}

interface ImageCounterProps {
  imagesLength: number,
  activeIndex: number,
  setActiveIndex: Dispatch<SetStateAction<number>>,
  oneThirdView: boolean
}
