import * as ChartJS from 'chart.js';
import { Chart as ChartJSChart, Plugin, Point } from 'chart.js';
import * as ChartJSHelpers from 'chart.js/helpers';
import { useLoadedImage } from './useLoadedImage';
import TreePoint from '../images/tree-point.svg';
import HistoricalTreePoint from '../images/historical-tree-point.svg';
import { Chart } from 'react-chartjs-2';
import { TreeStatistics } from '../../../tree/TreeStatistics';
import styles from './TreePropertyHistoryChart.module.scss';
import { ChartjsFullHeightSelectionPlugin } from './ChartjsFullHeightSelectionPlugin';

export default function TreePropertyHistoryChart(
  props: TreePropertyHistoryChartProps | SelectableTreePropertyHistoryChartProps
) {
  const decimals = props.hideDecimals ? 0 : 2;
  const treePointImage = useLoadedImage(TreePoint);
  const pointImage = useLoadedImage(HistoricalTreePoint);
  const labelFormatter = (date: Date) =>
    props.labelFormatter ? props.labelFormatter(date) : date.toLocaleDateString();
  const historicalLabels = props.history.map(it => labelFormatter(it.date)).reverse();
  const historicalData = props.history.map(it => it.value).reverse();
  const statisticalData = props.history.map(it => it.statistics.median).reverse();

  if (!pointImage || !treePointImage) {
    return null;
  }

  const cssVariable = (name: string) => window.getComputedStyle(document.body).getPropertyValue(name);

  const plugins: Plugin[] = [
    {
      id: 'row-background',
      beforeDraw: chart => {
        const tickHeight = chart.scales.y.height / (chart.scales.y.ticks.length - 1);
        chart.scales.y.ticks.forEach((tick, index) => {
          chart.scales.y.ctx.fillStyle = 'rgba(255, 255, 255, 0.02)';
          const leftPadding = 20;
          chart.scales.y.ctx.fillRect(
            chart.scales.y.left - leftPadding,
            tickHeight * index + chart.scales.y.top - tickHeight / 4,
            chart.scales.x.width + chart.scales.y.left + leftPadding,
            tickHeight / 2
          );
        });
      }
    },
    {
      id: 'statistics-line-point',
      afterDraw: chart => {
        const datasetIndex = chart.data.datasets.findIndex(it => it.label === 'statistics');
        if (datasetIndex < 0) {
          return;
        }
        const lineWidth = 20;
        const lineHeight = 3;
        const backgroundColor = cssVariable('--chart-negative-value');
        chart.getDatasetMeta(datasetIndex).data.forEach(point => {
          chart.ctx.fillStyle = backgroundColor;
          chart.ctx.fillRect(point.x - lineWidth / 2, point.y - lineHeight / 2, lineWidth, lineHeight);
        });
      }
    },
    new ChartjsLabelPlugin({
      datasetLabel: 'data',
      backgroundColor: (value, index, data) =>
        index === data.length - 1 ? cssVariable('--chart-positive-value') : cssVariable('--text-alternate'),
      textColor: cssVariable('--text-primary'),
      label: value => value?.toFixed(decimals) ?? 0
    })
  ];

  if ('selectable' in props) {
    plugins.push(
      new ChartjsFullHeightSelectionPlugin({
        yAxisPadding: 50,
        width: 60,
        onClick: index => {
          const historyEntry = props.history.at(-index - 1) ?? null;
          if (historyEntry) props.onSelectLabel(historyEntry.date);
        }
      })
    );
  }

  return (
    <div className={styles.container}>
      <Chart
        type="line"
        plugins={plugins}
        data={{
          labels: historicalLabels,
          datasets: [
            {
              type: 'line',
              label: 'data',
              tension: 0.4,
              data: historicalData,
              borderColor: cssVariable('--chart-positive-value'),
              borderWidth: 3,
              pointStyle: ctx => (ctx.dataIndex === ctx.dataset.data.length - 1 ? treePointImage : pointImage)
            },
            {
              type: 'line',
              label: 'statistics',
              data: statisticalData,
              borderWidth: 0,
              pointRadius: 0
            }
          ]
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          layout: { padding: { top: 48, left: 24, bottom: 24, right: 24 } },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              display: false
            }
          },
          scales: {
            y: {
              display: true,
              grid: { display: false, borderWidth: 0 },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 11,
                color: 'rgba(206, 215, 212, 1)',
                font: { weight: 'bold' },
                padding: 0
              }
            },
            x: {
              offset: true,
              grace: '20%',
              grid: { color: cssVariable('--panel-border_transparent'), borderDash: [4, 4], drawBorder: false },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 11,
                color: cssVariable('--text-primary'),
                padding: 12
              }
            }
          },
          hover: { mode: null as any },
          animation: { duration: 0 }
        }}
      />
    </div>
  );
}

interface TreePropertyHistoryChartProps {
  history: PropertyHistory[],
  labelFormatter?: (date: Date) => string,
  hideDecimals?: true
}

interface SelectableTreePropertyHistoryChartProps extends TreePropertyHistoryChartProps {
  selectable: true,
  onSelectLabel: (label: Date) => unknown
}

interface PropertyHistory {
  date: Date,
  value: number,
  statistics: TreeStatistics
}

class ChartjsLabelPlugin implements Plugin {
  private static readonly yOffset = 18;
  private static readonly height = 20;
  private static readonly horizontalPadding = 20;
  private static readonly fontSize = 14;

  id = 'label';

  constructor(
    private readonly options: {
      datasetLabel: string,
      backgroundColor: (item: number, index: number, data: number[]) => string,
      textColor: string,
      label: (item: number, index: number) => string
    }
  ) {}

  afterDraw(chart: ChartJSChart) {
    const datasetIndex = chart.data.datasets.findIndex(it => it.label === this.options.datasetLabel);
    if (datasetIndex < 0) {
      return;
    }

    chart.ctx.font = ChartJSHelpers.fontString(
      ChartjsLabelPlugin.fontSize,
      ChartJS.defaults.font.style ?? '',
      ChartJS.defaults.font.family ?? ''
    );
    chart.ctx.textAlign = 'center';
    chart.ctx.textBaseline = 'bottom';

    const dataset = chart.data.datasets.at(datasetIndex)!;
    const metadata = chart.getDatasetMeta(datasetIndex);
    const data = dataset.data as number[];

    metadata.data.forEach((point, index) => this.drawLabel(data, index, chart, point));
  }

  private drawLabel(data: number[], index: number, chart: ChartJSChart, point: Point) {
    const value = data.at(index) ?? 0;
    const label = this.options.label(value, index);
    const yOffset = ChartjsLabelPlugin.yOffset;
    const backgroundHeight = ChartjsLabelPlugin.height;
    const backgroundWidth = chart.ctx.measureText(label).width + ChartjsLabelPlugin.horizontalPadding;
    const backgroundX = point.x - backgroundWidth / 2;
    const backgroundY = point.y - backgroundHeight - yOffset;
    const radius = backgroundHeight / 2;

    this.drawRoundedRect(chart.ctx, backgroundX, backgroundY, backgroundWidth, backgroundHeight, radius);
    chart.ctx.fillStyle = this.options.backgroundColor(value, index, data);
    chart.ctx.fill();

    const textYOffset = ChartjsLabelPlugin.fontSize / 5;
    chart.ctx.fillStyle = this.options.textColor;

    chart.ctx.fillText(label, point.x, point.y - yOffset - textYOffset);
  }

  private drawRoundedRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number
  ) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
  }
}
