import { type ReactNode } from 'react';
import { createPortal } from 'react-dom';

export default function SlideInPanel(props: SlideInPanelProps) {
  return (
    createPortal(
      <div
        className={`fixed top-0 right-0 h-full min-w-[300px] max-w-[40%] bg-outer-space-700 rounded-tl-2xl rounded-bl-2xl transition-transform shadow-xl shadow-outer-space-950/80 z-10 ${props.isOpen ? 'translate-x-0' : 'translate-x-full'} ${props.className ?? ''}`.trimEnd()}
      >
        {props.children}
      </div>,
      document.body
    )
  );
}

interface SlideInPanelProps {
  isOpen: boolean,
  children: ReactNode,
  className?: string
}
