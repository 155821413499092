export class JumperNavigation {
  private state: 'EVERY_DISPLAYED_IS_NOT_PINNED' | 'EVERY_DISPLAYED_IS_PINNED' | 'MIXED' = 'MIXED';

  constructor(
    private readonly pinnedCount: number
  ) {}

  isVisible(): boolean {
    return this.state !== 'MIXED';
  }

  shouldScrollUpToTableTop(): boolean {
    return this.state === 'EVERY_DISPLAYED_IS_NOT_PINNED';
  }

  shouldScrollDownToNotPinnedTop() {
    return this.state === 'EVERY_DISPLAYED_IS_PINNED';
  }

  setDisplayedRange(from: number, to: number) {
    const isEveryDisplayedNotPinned = this.pinnedCount <= from;
    const isEveryDisplayedPinned = to < this.pinnedCount;

    if (!isEveryDisplayedNotPinned && !isEveryDisplayedPinned) this.state = 'MIXED';
    if (isEveryDisplayedNotPinned && !isEveryDisplayedPinned) this.state = 'EVERY_DISPLAYED_IS_NOT_PINNED';
    if (!isEveryDisplayedNotPinned && isEveryDisplayedPinned) this.state = 'EVERY_DISPLAYED_IS_PINNED';
  }
}
