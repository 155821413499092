import { useQuery } from 'react-query';
import { useContext } from 'react';
import DependencyInjectionContext from '../DependencyInjectionContext';

export default function useManagedAreas (organizationId: string) {
  const CACHE_KEY = `managed-area-list-${organizationId}`;

  const { managedAreaService } = useContext(DependencyInjectionContext);
  const { data, isLoading } = useQuery([CACHE_KEY],
    () => managedAreaService.list(organizationId), {
      retry: 1,
      retryOnMount: false,
      staleTime: 300000
    });

  return { managedAreaList: data || [], isLoadingManagedAreaList: isLoading };
}

