import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { ButtonWithIcon } from '../../UI/Button/LegacyButton';
import { Filter, RefreshDouble } from 'iconoir-react';
import styles from './FiltersView.module.scss';
import { useSearchParams } from 'react-router-dom';
import { MemberFilters } from './MemberFilters';
import LegacyCheckbox from '../../UI/Checkbox/LegacyCheckbox';
import { AccountType } from '../../../account/AccountType';
import { Interest } from '../../../account/Interest';
import { DateInput } from '../../UI/Input/Input';
import { MemberStatus } from '../../../organization/MemberStatus';
import ContentOverlay from '../ContentOverlay/ContentOverlay';

export function FiltersView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useMemo(() => MemberFilters.fromQuery(searchParams), [searchParams]);
  const { t } = useTranslation();
  const [isOpen, setVisibility] = useState(false);
  const toggle = useCallback(() => setVisibility(state => !state), []);
  const hide = useCallback(() => setVisibility(false), []);

  const reset = () => setSearchParams(filters.reset().toSearchParams());

  const toggleAccountTypes = useCallback(
    (accountType: AccountType) => () => setSearchParams(filters.toggleAccountType(accountType).toSearchParams()),
    [filters, setSearchParams]
  );

  const toggleInterests = useCallback(
    (interest: Interest) => () => setSearchParams(filters.toggleInterest(interest).toSearchParams()),
    [filters, setSearchParams]
  );

  const toggleStatuses = useCallback(
    (status: MemberStatus) => () => setSearchParams(filters.toggleStatus(status).toSearchParams()),
    [filters, setSearchParams]
  );

  const setLastLoginSince = useCallback(
    lastLoginSince => setSearchParams(filters.setLastLoginSince(lastLoginSince).toSearchParams()),
    [filters, setSearchParams]
  );

  const setLastLoginUntil = useCallback(
    lastLoginUntil => setSearchParams(filters.setLastLoginUntil(lastLoginUntil).toSearchParams()),
    [filters, setSearchParams]
  );

  return (
    <>
      <Popover
        content={
          <div className={styles.container}>
            <div className={styles.title}>
              <div className={styles.titleText}>
                <strong>{t('settings.members.filterMembers')}</strong>
              </div>

              <div>
                <ButtonWithIcon
                  variant="secondary"
                  onClick={reset}
                  icon={<RefreshDouble />}>
                  {t('settings.members.resetFilters')}
                </ButtonWithIcon>
              </div>
            </div>

            <div className={styles.fields}>
              <fieldset className={styles.fieldset}>
                <div className={styles.fieldsetTitle}>{t('settings.members.accountType')}</div>

                {Object.values(AccountType).map(accountType => (
                  <LegacyCheckbox
                    key={accountType}
                    checked={filters.accountTypes.includes(accountType)}
                    onValueChange={toggleAccountTypes(accountType)}
                  >
                    {t(`account.type.${accountType}`)}
                  </LegacyCheckbox>
                ))}
              </fieldset>

              <fieldset className={styles.fieldset}>
                <div className={styles.fieldsetTitle}>{t('settings.members.interests')}</div>

                {Object.values(Interest).map(interest => (
                  <LegacyCheckbox
                    key={interest}
                    checked={filters.interests.includes(interest)}
                    onValueChange={toggleInterests(interest)}
                  >
                    {t(`interests.${interest}`)}
                  </LegacyCheckbox>
                ))}
              </fieldset>

              <fieldset className={styles.fieldset}>
                <DateInput
                  label={t('settings.members.lastLoginSince')}
                  onValueChange={setLastLoginSince}
                  value={filters.lastLoginSince}
                />

                <DateInput
                  label={t('settings.members.lastLoginUntil')}
                  onValueChange={setLastLoginUntil}
                  value={filters.lastLoginUntil}
                />

                <div className={styles.hideStatuses}>
                  {Object.values(MemberStatus).map(status => (
                    <LegacyCheckbox
                      key={status}
                      checked={filters.hiddenStatuses.includes(status)}
                      onValueChange={toggleStatuses(status)}
                    >
                      {t(`settings.members.hideStatuses.${status}`)}
                    </LegacyCheckbox>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        }
        isOpen={isOpen}
        onClickOutside={hide}
        positions={['bottom']}
        containerStyle={{ zIndex: '99999' }}
        align="end"
      >
        <div>
          <ButtonWithIcon
            variant={filters.isActive() ? 'tertiary' : 'secondary'}
            onClick={toggle}
            icon={<Filter />}>
            {t('settings.members.filters')}
          </ButtonWithIcon>
        </div>
      </Popover>

      <ContentOverlay onClick={hide} show={isOpen} />
    </>
  );
}
