import api from '../services/api';
import { AxiosInstance } from 'axios';
import { User } from './User';
import { UserUpdateDto } from './UserUpdateDto';
import { UserDto } from './UserDto';

export class UserService {
  static make() {
    return new UserService(api);
  }

  constructor(private readonly http: AxiosInstance) {}

  async updateSelf(payload: UserUpdateDto): Promise<User> {
    const response = await this.http.patch<UserDto>('/v1/users', payload);

    return User.fromDto(response.data);
  }

  async update(userId: string, payload: { translatorFor: string[] }): Promise<User> {
    const response = await this.http.patch<UserDto>(`/v1/users/${userId}`, payload);

    return User.fromDto(response.data);
  }

  async listAllEmails(): Promise<Partial<User>[]> {
    const response = await this.http.get('/v1/users');
    return response.data.map(it => User.fromDto(it));
  }
}
