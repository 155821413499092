export enum WeakPasswordError {
  NoSpecialCharacters = 'noSpecialCharacters',
  NoSmallCaps = 'noSmallCaps',
  NoUpperCase = 'noUpperCase',
  TooShort = 'tooShort'
}

export class Password {
  private static SPECIAL_CHARACTERS = ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
  private static MIN_LENGTH = 10;

  constructor(private readonly raw: string) {}

  getErrors(): WeakPasswordError[] {
    return [
      this.containsSpecialCharacters() ? false : WeakPasswordError.NoSpecialCharacters,
      this.containsSmallCaps() ? false : WeakPasswordError.NoSmallCaps,
      this.containsUpperCase() ? false : WeakPasswordError.NoUpperCase,
      this.isLongEnough() ? false : WeakPasswordError.TooShort
    ].filter((result): result is WeakPasswordError => !!result);
  }

  private containsSpecialCharacters() {
    return this.raw.split('').some(character => Password.SPECIAL_CHARACTERS.includes(character));
  }

  private containsSmallCaps() {
    return this.raw.toUpperCase() !== this.raw;
  }

  private containsUpperCase() {
    return this.raw.toLowerCase() !== this.raw;
  }

  private isLongEnough() {
    return this.raw.length >= Password.MIN_LENGTH;
  }
}
