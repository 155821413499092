import { Navigate } from 'react-router-dom';

import { Organization } from '../organization/Organization';
import { EntityKey, EntityStorage } from '../EntityStorage';
import useAccounts from '../account/useAccounts';
import Spinner from '../components/UI/Spinner/Spinner';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../auth/AuthContext';

export default function DefaultRoute() {
  const authContext = useContext(AuthContext);
  const { accounts, isLoadingAccounts } = useAccounts();

  useEffect(() => {
    if (!isLoadingAccounts && accounts.length === 0) {
      authContext.logout();
    }
  }, [authContext, accounts, isLoadingAccounts]);

  if (isLoadingAccounts) {
    return <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'var(--panel-background)'
      }}>
      <Spinner size={2} />
    </div>;
  }

  const organizationCache = EntityStorage.local(EntityKey.CurrentOrganization, Organization);

  if (!accounts.length) {
    return <Navigate replace to="/" />;
  }

  const cached = organizationCache.getOrElse(Organization.empty());
  const initialOrganization = cached.belongsToAny(accounts) ? cached : accounts[0].organization;
  organizationCache.set(initialOrganization);

  const defaultPath = initialOrganization.isDemo ? 'home' : 'map';
  return <Navigate to={`/organizations/${initialOrganization.id}/${defaultPath}`} />;
}
