export enum Foliage {
  CROWN_CLEANED = 'crownCleaned',
  THINNED = 'thinned',
  RAISED = 'raised',
  REDUCED = 'reduced',
  TOPPED = 'topped',
  LIONS_TAILING = 'lionsTailing',
  FLUSH_CUTS = 'flushCuts',
  OTHER = 'other'
}
