import * as THREE from 'three';

export default class MultiResolutionMesh extends THREE.Mesh {
  private static textureLoader = new THREE.TextureLoader();
  isHighResolutionLoaded = false;

  constructor(
    geometry: THREE.PlaneGeometry,
    private readonly lowResolutionImageUrl: string,
    private readonly highResolutionImageUrl: string
  ) {
    super(geometry, new THREE.MeshBasicMaterial({ color: 0x222c2f }));
  }

  loadLowResolutionImageUrl() {
    return this.loadImage(this.lowResolutionImageUrl);
  }

  loadHighResolutionImageUrl() {
    this.isHighResolutionLoaded = true;
    return this.loadImage(this.highResolutionImageUrl);
  }

  private loadImage(url: string) {
    return new Promise<null>(resolve => {
      MultiResolutionMesh.textureLoader.crossOrigin = 'use-credentials';
      MultiResolutionMesh.textureLoader.load(url, texture => {
        if (this.material && (this.material as THREE.MeshBasicMaterial).map) {
          (this.material as THREE.MeshBasicMaterial).map?.dispose();
        }
        this.material = new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.FrontSide
        });
        resolve(null);
      });
    });
  }
}
