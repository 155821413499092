import { Tree } from '../../tree/Tree';
import styles from './General.module.scss';
import pageStyles from './AnalyticsPage.module.scss';
import { useTranslation } from 'react-i18next';
import { DetailsButtonHelpers } from './Analytics';
import PanoramaView from '../../components/Panorama/PanoramaView';
import SphereControls from '../../components/Panorama/SphereControls';
import { useMemo, useRef, useState } from 'react';
import LegacyPointCloudViewer from '../../components/PointCloud/LegacyPointCloudViewer';
import { DateSelector, DateSelectorContainer } from './components/ChartControls';
import { AVAILABLE_DATES, NOW } from './fakes';
import { DetailsButton, ExtraDetailsButton, ResetViewButton, TreeComparisonView, TreeView } from './components/Page';
import { MultiOrbitControl } from '../../components/PointCloud/MultiOrbitControl';
import { useCurrentAccount } from '../../account/useAccounts';
import { AnalyticsView } from './AnalyticsView';

export default function GeneralTreeAnalytics(props: GeneralTreeAnalyticsProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();

  const [dateA, setDateA] = useState(NOW);
  const [dateB, setDateB] = useState(AVAILABLE_DATES.at(-1)!);
  const [seed, setSeed] = useState(Math.random().toString(16).slice(-7));
  const updateSeed = () => setSeed(Math.random().toString(16).slice(-7));

  const multiControlsRef = useRef(new MultiOrbitControl());

  const sphereControls = useMemo(() => new SphereControls(), []);

  const resetView = () => {
    updateSeed();
    sphereControls.reset();
  };

  return (
    <div className={styles.container}>
      <h1 className={pageStyles.title}>{t('analytics.navigation.general')}</h1>

      <TreeDataView tree={props.tree} />

      <div className={styles.content}>
        {props.view === AnalyticsView.Twin && (
          <>
            {!props.compare && (
              <TreeView pointCloud>
                <LegacyPointCloudViewer
                  seed={seed}
                  tree={props.tree}
                  showEnvironment={props.detailsHelper.showDetails}
                  multiControlsRef={multiControlsRef}
                />
              </TreeView>
            )}

            {props.compare && (
              <TreeComparisonView>
                <TreeView pointCloud>
                  <LegacyPointCloudViewer
                    seed={seed}
                    tree={props.tree}
                    showEnvironment={props.detailsHelper.showDetails}
                    multiControlsRef={multiControlsRef}
                  />
                </TreeView>

                <TreeView pointCloud>
                  <LegacyPointCloudViewer
                    seed={seed}
                    tree={props.tree}
                    showEnvironment={props.detailsHelper.showDetails}
                    multiControlsRef={multiControlsRef}
                  />
                </TreeView>
              </TreeComparisonView>
            )}
          </>
        )}

        {props.view === AnalyticsView.Panorama && (
          <>
            {!props.compare && (
              <TreeView>
                <PanoramaView
                  tree={props.tree}
                  organizationId={props.organizationId}
                  controls={sphereControls}
                  showPointCloud={props.detailsHelper.showDetails}
                  showEnvironmentPointCloud={props.detailsHelper.showExtraDetails}
                  rulers={[]}
                  showSafetyFactor={false}
                  windSpeed={account.getDefaultWindSpeed()}
                  showLeaningAngle={false}
                />
              </TreeView>
            )}

            {props.compare && (
              <TreeComparisonView>
                <TreeView>
                  <PanoramaView
                    tree={props.tree}
                    organizationId={props.organizationId}
                    controls={sphereControls}
                    showPointCloud={props.detailsHelper.showDetails}
                    showEnvironmentPointCloud={props.detailsHelper.showExtraDetails}
                    rulers={[]}
                    showSafetyFactor={false}
                    windSpeed={account.getDefaultWindSpeed()}
                    showLeaningAngle={false}
                  />
                </TreeView>

                <TreeView>
                  <PanoramaView
                    tree={props.tree}
                    organizationId={props.organizationId}
                    controls={sphereControls}
                    showPointCloud={props.detailsHelper.showDetails}
                    showEnvironmentPointCloud={props.detailsHelper.showExtraDetails}
                    rulers={[]}
                    showSafetyFactor={false}
                    windSpeed={account.getDefaultWindSpeed()}
                    showLeaningAngle={false}
                  />
                </TreeView>
              </TreeComparisonView>
            )}

            <ExtraDetailsButton isOn={props.detailsHelper.showExtraDetails} toggle={props.detailsHelper.toggleExtraDetails} />
          </>
        )}

        <DetailsButton showDetails={props.detailsHelper.showDetails} toggleDetails={props.detailsHelper.toggleDetails} />
        <ResetViewButton onClick={resetView} />

        {props.compare && (
          <DateSelectorContainer floating>
            <DateSelector
              variant="current"
              options={AVAILABLE_DATES}
              onSelect={setDateA}
              selected={dateA} />

            <DateSelector
              variant="reference"
              options={AVAILABLE_DATES}
              onSelect={setDateB}
              selected={dateB} />
          </DateSelectorContainer>
        )}
      </div>
    </div>
  );
}

function TreeDataView(props: { tree: Tree }) {
  const { t } = useTranslation();

  const position = props.tree
    .getWorldCoordinates()
    .map(n => n.toFixed(5))
    .join(', ');

  return (
    <div className={styles.data}>
      <div>
        <div className={styles.row}>
          <div>
            <strong>{t('analytics.general.species')}</strong>
          </div>

          <div className={styles.value}>{props.tree.scientificName}</div>
        </div>

        <div className={styles.row}>
          <div>
            <strong>{t('analytics.general.treeId')}</strong>
          </div>

          <div className={styles.value}>{props.tree.externalId}</div>
        </div>

        <div className={styles.row}>
          <div>
            <strong>{t('analytics.general.uniqueId')}</strong>
          </div>

          <div data-tooltip={props.tree.id} className={styles.value}>
            <span className={styles.id} onClick={() => navigator.clipboard?.writeText(props.tree.id)}>
              {props.tree.id}
            </span>
          </div>
        </div>
      </div>

      <div>
        <div className={styles.row}>
          <div>
            <strong>{t('analytics.general.position')}</strong>
          </div>

          <div className={styles.value} onClick={() => navigator.clipboard?.writeText(position)}>
            {position}
          </div>
        </div>

        <div className={styles.row}>
          <div>
            <strong>{t('analytics.general.managedArea')}</strong>
          </div>

          <div className={styles.value}>{props.tree.managedArea.getName()}</div>
        </div>

        <div className={styles.row}>
          <div>
            <strong>{t('analytics.general.lotNumber')}</strong>
          </div>

          <div className={styles.value}>-</div>
        </div>
      </div>
    </div>
  );
}

interface GeneralTreeAnalyticsProps {
  tree: Tree,
  view: AnalyticsView,
  organizationId: string,
  compare: boolean,
  detailsHelper: DetailsButtonHelpers
}
