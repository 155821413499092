export class MercatorCoordinate {
  readonly lng: number;
  readonly lat: number;

  constructor(coordinates: number[]) {
    this.lng = coordinates[0];
    this.lat = coordinates[1];
  }

  get x() {
    return +(180 + this.lng) / 360;
  }

  get y() {
    return +(180 - (180 / Math.PI) * Math.log(Math.tan(Math.PI / 4 + (this.lat * Math.PI) / 360))) / 360;
  }

  get scale() {
    const earthRadius = 6371008.8;
    const earthCircumference = 2 * Math.PI * earthRadius;
    return (1 / earthCircumference) * mercatorScale(this.lat);

    function mercatorScale(lat) {
      return 1 / Math.cos((lat * Math.PI) / 180);
    }
  }
}
