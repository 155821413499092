import styles from './RiskSlider.module.scss';

export default function RiskSlider(props: RiskSliderParams) {
  return (
    <div className={styles.riskSliderContainer}>
      <div className={styles.labelContainer}>
        <label htmlFor={props.label}>{props.label}:</label>
        <span>{props.valueLabel}</span>
      </div>
      <input
        type="range"
        min={0}
        max={3}
        step={1}
        id={props.label}
        value={props.value}
        onChange={event => props.onChange ? props.onChange(Number(event.currentTarget.value)) : undefined}
        onMouseUp={event => props.onMouseUp ? props.onMouseUp(Number(event.currentTarget.value)) : undefined}
        disabled={!props.onMouseUp}
      />
    </div>
  );
}

export interface RiskSliderParams {
  label: string,
  value: number,
  valueLabel: string,
  onChange?: (newValue: number) => void,
  onMouseUp?: (newValue: number) => void
}
