import axios, { AxiosResponseTransformer } from 'axios';
import humps from 'humps';

import getRuntimeConfig from '../RuntimeConfig';

const originalTransformers = Array.isArray(axios.defaults.transformResponse)
  ? axios.defaults.transformResponse
  : [axios.defaults.transformResponse].filter(
    (transformer): transformer is AxiosResponseTransformer => !!transformer
  );

const camelCaseDataFields = data => {
  if (data instanceof ArrayBuffer || data instanceof Blob) {
    return data;
  } else {
    return humps.camelizeKeys(data);
  }
};

export default axios.create({
  baseURL: getRuntimeConfig().apiUrl,
  transformResponse: [...originalTransformers, camelCaseDataFields],
  withCredentials: true
});
