import { useTranslation } from 'react-i18next';
import { CSSProperties, FormEvent, useMemo, useRef, useState } from 'react';

import styles from './SignupScreen.module.scss';
import Input, { PasswordInput } from '../UI/Input/Input';
import { PasswordValidationErrorView } from '../PasswordValidationErrorView/PasswordValidationErrorView';
import { Password } from '../../password/Password';
import { PrimaryButton } from '../UI/Button/LegacyButton';
import { SignUpDto } from '../../registration/SignUpDto';

export default function SignupScreen(props: SignupScreenProps) {
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const [isInProgress, setProgressState] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const passwordErrors = useMemo(() => new Password(password).getErrors(), [password]);

  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);

  const logoStyle: CSSProperties = { backgroundImage: `url("${props.organizationLogoUrl}")` };

  const isValid =
    !isInProgress && passwordErrors.length === 0 && password === passwordConfirmation && firstName && lastName;

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!formRef.current || !formRef.current.reportValidity() || !isValid) {
      return;
    }

    setProgressState(true);
    props.onSignup({ firstName, lastName, password }).finally(() => setProgressState(false));
  };

  return (
    <div className={styles.container}>
      <form
        className={styles.form}
        onSubmit={onSubmit}
        ref={formRef}>
        <div className={styles.organizationLogo} style={logoStyle} />

        <h1 className={styles.title}>{t('signup.title', { organizationName: props.organizationName })}</h1>

        <h2 className={styles.subTitle}>{t('signup.subtitle')}</h2>

        {props.hasLinkExpired && <p className={styles.error}>{t('signup.expired')}</p>}

        <Input
          label={t('signup.email')}
          placeholder={t('signup.emailPlaceholder')}
          type="email"
          className={styles.largeInput}
          value={props.email}
          legacyStyle={true}
        />

        <Input
          label={t('signup.firstName')}
          placeholder={t('signup.firstNamePlaceholder')}
          className={styles.largeInput}
          value={firstName}
          onValueChange={setFirstName}
          required
          legacyStyle={true}
        />

        <Input
          label={t('signup.lastName')}
          placeholder={t('signup.lastNamePlaceholder')}
          className={styles.largeInput}
          value={lastName}
          onValueChange={setLastName}
          required
          legacyStyle={true}
        />

        <PasswordInput
          placeholder={t('signup.passwordPlaceholder')}
          testId="signup-password"
          onValueChange={setPassword}
          value={password}
        />

        <PasswordInput
          placeholder={t('signup.passwordConfirmationPlaceholder')}
          testId="signup-password-confirmation"
          onValueChange={setPasswordConfirmation}
          value={passwordConfirmation}
        />

        <PasswordValidationErrorView errors={passwordErrors} password={password} />

        <PrimaryButton
          fullWidth
          size="l"
          disabled={!isValid}>
          {t('signup.joinButton')}
        </PrimaryButton>
      </form>
    </div>
  );
}

interface SignupScreenProps {
  email: string,
  firstName: string,
  lastName: string,
  organizationLogoUrl: string,
  organizationName: string,
  onSignup: (dto: SignUpDto) => Promise<unknown>,
  hasLinkExpired: boolean
}
