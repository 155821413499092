import { DisplayableViItem, Latitude, Longitude, SafetyFactor, TreeDto } from './Tree';
import { Cohort } from '../routes/LegacyDetails/LegacyDetails';
import { ViStatus } from './DetailedTree';

export default class PartialTree {
  static fromDto(dto: Partial<TreeDto>) {
    return new PartialTree(
      dto.id ?? '',
      dto.managedAreaId ?? null,
      ((dto.managedArea?.name?.length ?? 0) > 0 ? dto.managedArea?.name : dto.managedArea?.code) ?? null,
      dto.genus ?? null,
      dto.species ?? null,
      dto.scientificName ?? null,
      dto.height ?? null,
      dto.trunkHeight ?? null,
      dto.trunkWidth ?? null,
      dto.trunkEllipseRadiusA ?? null,
      dto.trunkEllipseRadiusB ?? null,
      dto.trunkCircumference ?? null,
      dto.trunkDiameter ?? null,
      dto.canopyHeight ?? null,
      dto.canopyWidth ?? null,
      dto.canopyEllipseRadiusA ?? null,
      dto.canopyEllipseRadiusB ?? null,
      dto.canopyCircumference ?? null,
      dto.leafArea ?? null,
      dto.leafBiomass ?? null,
      dto.leafAreaIndex ?? null,
      dto.carbonStorage ?? null,
      dto.grossCarbonSequestration ?? null,
      dto.no2 ?? null,
      dto.so2 ?? null,
      dto.pm25 ?? null,
      dto.co ?? null,
      dto.o3 ?? null,
      dto.ndvi ?? null,
      dto.treeHealth ?? null,
      dto.carbonStorageEcoValue ?? null,
      dto.grossCarbonSequestrationEcoValue ?? null,
      dto.no2EcoValue ?? null,
      dto.so2EcoValue ?? null,
      dto.pm25EcoValue ?? null,
      dto.coEcoValue ?? null,
      dto.o3EcoValue ?? null,
      dto.avoidedRunoffEcoValue ?? null,
      dto.potentialEvapotranspiration ?? null,
      dto.transpiration ?? null,
      dto.oxygenProduction ?? null,

      dto.safetyFactorAt80Kmh ?? null,
      dto.safetyFactorAtDefaultWindSpeed ?? null,
      dto.externalId ?? null,
      dto.safetyFactors ?? [],
      dto.thermalComfort ?? null,
      dto.treeValueCavat ?? null,
      dto.treeValueRado ?? null,
      dto.treeValueKoch ?? null,
      dto.leaningAngle ?? null,
      dto.evaporation ?? null,
      dto.waterIntercepted ?? null,
      dto.avoidedRunoff ?? null,
      dto.leaningVector ?? null,
      dto.location ?? null,
      dto.localizedLocation ?? null,
      dto.cohort ?? null,
      dto.dieback ?? null,
      dto.status ?? null,
      dto.vitalityVigor ?? null,
      dto.crownLightExposure ?? null,
      dto.leafAreaPerCrownVolume ?? null,
      dto.liveCrownRatio ?? null,
      dto.slenderness ?? null,
      dto.viStatus ?? null,
      dto.hasViObservation ?? null,
      dto.hasMitigation ?? null,
      dto.hasAssessmentRequest ?? null,
      dto.mitigations ?? []
    );
  }

  constructor(
    readonly id: string,
    readonly managedAreaId: string | null,
    readonly managedAreaName: string | null,
    readonly genus: string | null,
    readonly species: string | null,
    readonly scientificName: string | null,
    readonly height: number | null,
    readonly trunkHeight: number | null,
    readonly trunkWidth: number | null,
    readonly trunkEllipseRadiusA: number | null,
    readonly trunkEllipseRadiusB: number | null,
    readonly trunkCircumference: number | null,
    readonly trunkDiameter: number | null,
    readonly canopyHeight: number | null,
    readonly canopyWidth: number | null,
    readonly canopyEllipseRadiusA: number | null,
    readonly canopyEllipseRadiusB: number | null,
    readonly canopyCircumference: number | null,
    readonly leafArea: number | null,
    readonly leafBiomass: number | null,
    readonly leafAreaIndex: number | null,
    readonly carbonStorage: number | null,
    readonly grossCarbonSequestration: number | null,
    readonly no2: number | null,
    readonly so2: number | null,
    readonly pm25: number | null,
    readonly co: number | null,
    readonly o3: number | null,
    readonly ndvi: number | null,
    readonly treeHealth: number | null,
    readonly carbonStorageEcoValue: number | null,
    readonly grossCarbonSequestrationEcoValue: number | null,
    readonly no2EcoValue: number | null,
    readonly so2EcoValue: number | null,
    readonly pm25EcoValue: number | null,
    readonly coEcoValue: number | null,
    readonly o3EcoValue: number | null,
    readonly avoidedRunoffEcoValue: number | null,
    readonly potentialEvapotranspiration: number | null,
    readonly transpiration: number | null,
    readonly oxygenProduction: number | null,
    readonly safetyFactorAt80Kmh: number | null,
    readonly safetyFactorAtDefaultWindSpeed: number | null,
    readonly externalId: string | null,
    readonly safetyFactors: SafetyFactor[],
    readonly thermalComfort: number | null,
    readonly treeValueCavat: number | null,
    readonly treeValueRado: number | null,
    readonly treeValueKoch: number | null,
    readonly leaningAngle: number | null,
    readonly evaporation: number | null,
    readonly waterIntercepted: number | null,
    readonly avoidedRunoff: number | null,
    readonly leaningVector: { coordinates: [number, number, number] } | null,
    readonly location: { coordinates: [Longitude, Latitude, number] } | null,
    readonly localizedLocation: { coordinates: [Longitude, Latitude, number] } | null,
    readonly cohort: Cohort | null,
    readonly dieback: number | null,
    readonly status: string | null,
    readonly vitalityVigor: string | null,
    readonly crownLightExposure: number | null,
    readonly leafAreaPerCrownVolume: number | null,
    readonly liveCrownRatio: number | null,
    readonly slenderness: number | null,
    readonly viStatus: ViStatus | null,
    readonly hasViObservation: boolean | null,
    readonly hasMitigation: boolean | null,
    readonly hasAssessmentRequest: boolean | null,
    readonly mitigations: DisplayableViItem[]
  ) {
  }

  getSafetyFactor(windSpeed: number) {
    return this.safetyFactors.find(it => it.windSpeed === windSpeed)?.safetyFactor || null;
  }
}
