import React, { ReactNode } from 'react';
import { NavArrowDown, NavArrowLeft } from 'iconoir-react';

export function DetailsGroup({ children, className }: { children: ReactNode, className?: string }) {
  return (
    <ul className={className}>
      {children}
    </ul>
  );
}
export function ExclusiveDetails({ children, summary, className, summaryClassName }: {
  children: ReactNode,
  summary: ReactNode,
  className?: string,
  summaryClassName?: string
}) {
  const closeAllOtherDetails = (event: React.MouseEvent<HTMLElement>) => {
    const summaryElement = event.target as HTMLElement;
    const detailsElement = summaryElement.parentElement as HTMLDetailsElement;
    const detailsElements = (Array.from(detailsElement.parentElement?.parentElement?.children || []) as HTMLLIElement[])
      .map(element => element.firstChild as HTMLDetailsElement)
      .filter(element => element !== detailsElement && element.tagName === 'DETAILS');

    detailsElements.forEach(otherDetails => {
      if (otherDetails !== detailsElement) {
        otherDetails.removeAttribute('open');
      }
    });
  };

  return (
    <li>
      <details className={`${className} [&[open]>summary>div:first-of-type]:block [&[open]>summary>div:last-of-type]:hidden`}>
        <summary
          onClick={closeAllOtherDetails}
          className={`${summaryClassName} w-full flex items-center justify-between cursor-pointer`}
        >
          {summary}
          <div className="hidden"><NavArrowDown /></div>
          <div className="block"><NavArrowLeft /></div>
        </summary>
        {children}
      </details>
    </li>
  );
}

export function ControlledExclusiveDetails({
  children,
  summary,
  className,
  summaryClassName,
  isOpen,
  toggleOpen,
  summaryControl
}: {
  children: ReactNode,
  summary: string | ReactNode,
  className?: string,
  summaryClassName?: string,
  isOpen: boolean,
  toggleOpen: () => void,
  summaryControl?: React.ReactNode | React.ReactNode[]
}) {
  return (
    <li className={`${isOpen ? 'outline outline-1 outline-greehill-400' : ''}`}>
      <details className={className} open={isOpen}>
        <summary
          onClick={toggleOpen}
          className={`${summaryClassName} w-full flex items-center justify-between cursor-pointer`}
        >
          <div className={`flex items-center gap-2 text-[13px] ${isOpen ? 'text-greehill-400' : ''}`}>
            {summaryControl}
            {summary}
          </div>
          {isOpen
            ? <NavArrowDown className="size-[20px] text-greehill-400"/>
            : <NavArrowLeft className="size-[20px]"/>}
        </summary>
        {children}
      </details>
    </li>
  );
}
