import styles from '../../Inventory/BaseAttributes.module.scss';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useState } from 'react';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import { DisplayableTreeProperty, Fork, Tree, TreeDto } from '../../../../../tree/Tree';
import DetailedTree from '../../../../../tree/DetailedTree';
import DataGroupHeader from '../../components/DataGroupHeader/DataGroupHeader';
import Dropdown, { Item } from '../../../../../components/UI/Dropdown/Dropdown';
import EditingFieldWrapper from '../../components/EditingFieldWrapper/EditingFieldWrapper';
import { useCurrentAccount } from '../../../../../account/useAccounts';
import { nullableBooleanItems } from '../../DataPanel';
import { CrossSectionalShape } from '../../../../../property-enums/CrossSectionalShape';
import EditableAccordion from '../../components/EditableAccordion/EditableAccordion';

export default function LimbsDataGroup(props: LimbsDataGroupProps) {
  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);
  const [editing, setEditing] = useState(false);
  const account = useCurrentAccount();
  const organization = account.organization;

  const initialLimbs = {
    limbs: props.tree.limbs,
    coDominantLimbs: props.tree.coDominantLimbs,
    includedBark: props.tree.includedBark,
    fork: props.tree.fork,
    crossSectionalShape: props.tree.crossSectionalShape
  };

  const [limbs, setLimbs] = useState(initialLimbs);

  const forkOptions: Item[] = Object.values(Fork).map(option => {
    return { id: option, translationKey: 'details.properties.forkTypes.' + option };
  });
  const crossSectionalShapeOptions: Item[] = Object.values(CrossSectionalShape).map(option => {
    return { id: option, translationKey: 'details.properties.crossSectionalShapeOptions.' + option };
  });
  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(limbs).forEach(key => {
      if (limbs[key] !== props.tree[key] && limbs[key] !== '') {
        updatedValues[key] = limbs[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(props.organizationId, props.tree.id, updatedValues);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setLimbs(initialLimbs);
  };

  const handleDeleteLimb = useCallback((i: number) => {
    setLimbs(prevState => {
      const limbs = [...prevState.limbs];
      limbs.splice(i, 1);
      return { ...prevState, limbs };
    });
  }, []);

  return (
    <div className={styles.dataGroup}>
      <DataGroupHeader
        editing={editing}
        setEditing={() => setEditing(true)}
        handleCancel={handleCancel}
        handleSave={handleSave}
        title={t('treeDetails.tabs.subTitles.limbs')}
      />
      <div className={`${styles.flexContainer}`}>
        <div className={props.singleColumn ? styles.singleColumn : styles.accordionPairsContainer}>
          <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
            <EditingFieldWrapper
              title={t('details.properties.numberOfLimbs')}
              value={limbs.limbs.length || '-'}
              editing={editing}
            />
            {(!editing && limbs.limbs.length === 0) && <EditingFieldWrapper title={t('details.properties.limbDiameter')} value={undefined} />}
            {(editing || limbs.limbs.length > 0) && <EditableAccordion editing={editing}>
              <EditableAccordion.Header onAdd={() => setLimbs(prev => ({ ...prev, limbs: [...prev.limbs, { diameter: 0 }] }))}>
                <EditableAccordion.HeaderLabel>{t('details.properties.limbDiameter')}</EditableAccordion.HeaderLabel>
              </EditableAccordion.Header>
              <EditableAccordion.Body>
                {limbs.limbs.map((limb, i) =>
                  <EditableAccordion.Row
                    key={i}
                    label={`${t('details.properties.limb')} #${i + 1}`}
                    value={limb.diameter.toString() || ''}
                    unit={Tree.getUnit(DisplayableTreeProperty.LimbDiameter, organization)}
                    onChange={event => setLimbs(prev => {
                      const limbs = prev.limbs;
                      limbs[i] = { diameter: Number(event.target.value) };
                      return { ...prev, limbs };
                    })}
                    onDelete={() => {
                      handleDeleteLimb(i);
                    }}
                  />
                )}
              </EditableAccordion.Body>
            </EditableAccordion>}
            <EditingFieldWrapper
              title={t('details.properties.coDominantLimbs')}
              value={t(`${nullableBooleanItems.find(it => it.id === limbs.coDominantLimbs?.toString() || '')?.translationKey || ''}`) as string}
              editing={editing}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={nullableBooleanItems}
                value={nullableBooleanItems.find(it => it.id === limbs.coDominantLimbs?.toString() || '')}
                onSelect={item => setLimbs(prev =>
                  ({ ...prev, coDominantLimbs: item.id === '' ? null : item.id === 'true' }))
                }
              />
            </EditingFieldWrapper>
          </div>
          <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : styles.doubleColumns}`}>
            <EditingFieldWrapper
              title={t('details.properties.fork')}
              value={t(`${forkOptions.find(it => it.id === limbs.fork || '')?.translationKey || ''}`) as string}
              editing={editing}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={forkOptions}
                value={forkOptions.find(it => it.id === limbs.fork || '')}
                onSelect={item => setLimbs(prev =>
                  ({ ...prev, fork: item.id as Fork }))
                }
              />
            </EditingFieldWrapper>
            <EditingFieldWrapper
              title={t('details.properties.includedBark')}
              value={t(`details.properties.booleanLabels.${props.tree.includedBark ? 'true' : 'false'}`) as string}
              editing={editing}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={nullableBooleanItems}
                value={{ translationKey: `details.properties.booleanLabels.${props.tree.includedBark ? 'true' : 'false'}` }}
              />
            </EditingFieldWrapper>
            <EditingFieldWrapper
              title={t('details.properties.crossSectionalShape')}
              value={t(`${crossSectionalShapeOptions.find(it => it.id === limbs.crossSectionalShape || '')?.translationKey || ''}`) as string}
              editing={editing}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={crossSectionalShapeOptions}
                value={crossSectionalShapeOptions.find(it => it.id === limbs.crossSectionalShape || '')}
                onSelect={item => setLimbs(prev =>
                  ({ ...prev, crossSectionalShape: item.id as CrossSectionalShape }))
                }
              />
            </EditingFieldWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

interface LimbsDataGroupProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organizationId: string
}
