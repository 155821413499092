import styles from './RiskOverlay.module.scss';
import { Tree } from '../../../../tree/Tree';
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { KMH_TO_MPH } from '../../../../components/PointCloud/unitConstants';

export default function WindSpeedSlider(props: WindSpeedSliderProps) {
  const { t } = useTranslation();

  const maxWindSpeed = props.tree.getMaxWindSpeed();
  const minWindSpeed = props.tree.getMinWindSpeed();
  const currentAccount = useCurrentAccount();
  const speedFactor = currentAccount.organization.isMetric ? 1 : KMH_TO_MPH;
  return (
    <div className={styles.windSpeedSliderContainer}>
      <div className={styles.sliderHeader}>
        <div className={styles.sliderTitle}>
          {t('analytics.setWindSpeed')}
        </div>
        <div className={styles.windSpeedContainer}>
          {(props.windSpeed * speedFactor).toFixed(0)}
        </div>
        <div className={styles.sliderTitle}>
          {Tree.getWindSpeedUnit(currentAccount.organization)}
        </div>
      </div>
      <Slider
        handleStyle={{
          background: 'var(--accent-tertiary)',
          border: 'none',
          opacity: 1,
          boxShadow: 'none'
        }}
        activeDotStyle={{ border: 'none', boxShadow: 'none' }}
        trackStyle={{ background: 'var(--accent-tertiary)' }}
        value={props.windSpeed}
        min={minWindSpeed}
        max={maxWindSpeed}
        step={5}
        onChange={value => props.updateWindSpeed(value as number)}
      />
      <div className={styles.sliderLabels}>
        <div>{(minWindSpeed * speedFactor).toFixed(0)}</div>
        <div>{(maxWindSpeed * speedFactor).toFixed(0)}</div>
      </div>
    </div>
  );
}

interface WindSpeedSliderProps {
  tree: Tree,
  windSpeed: number,
  updateWindSpeed: (windSpeed: number) => void
}
