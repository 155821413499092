import { ReactNode, useEffect, useRef } from 'react';
import { TrackableTreeProperty } from '../../../../../tree/TrackableTreeProperty';
import styles from '../../Inventory/BaseAttributes.module.scss';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../../../account/useAccounts';
import { Flippers } from '../../../../../switches/Flippers';
import NormalDistributionChart from '../NormalDistributionChart/NormalDistributionChart';
import { NoData } from '../../Inventory/BaseAttributes';
import Tooltip, { TooltipForEllipsis } from '../../../../../components/UI/Tooltip/Tooltip';

export default function EditingFieldWrapper(props: {
  title: string,
  value: string | number | null | undefined,
  unit?: string,
  editing?: boolean,
  children?: ReactNode,
  className?: any,
  onClick?: () => void,
  chartPropertyOnHover?: TrackableTreeProperty,
  displayTooltip?: boolean
}) {
  const formatNumber = (number: number) => {
    if (Number.isInteger(number)) {
      return number.toString();
    } else {
      return number.toFixed(2);
    }
  };

  const value: string = typeof props.value === 'number' ? formatNumber(props.value) : props.value || '';

  const onClick = () => {
    if (props.value && props.onClick) props.onClick();
  };

  return (
    <div className={`${styles.field} ${props.className || ''}`}>
      <p className={styles.label}>{props.title}</p>
      <div
        className={`${styles.valueContainer} ${(props.onClick && props.value) ? styles.clickable : ''}`}
        onClick={onClick}
      >
        {(props.editing || props.onClick) && props.children}
        {
          (!props.editing || !props.children) &&
          <Content
            value={value}
            onClick={props.onClick}
            unit={props.unit}
            chartPropertyOnHover={props.chartPropertyOnHover}
            editing={props.editing}
            displayTooltip={props.displayTooltip}
          />
        }
      </div>
    </div>
  );
}

type ContentProps = {
  value: string,
  onClick?: (e: MouseEvent) => void,
  unit?: string,
  chartPropertyOnHover?: TrackableTreeProperty,
  editing?: boolean,
  displayTooltip?: boolean
};

function Content(props: ContentProps) {
  const { t } = useTranslation();
  const organization = useCurrentAccount().organization;
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const valueContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let debounceTimer;
    const debounce = (callback, time) => {
      window.clearTimeout(debounceTimer);
      debounceTimer = window.setTimeout(callback, time);
    };
    const setChartPosition = () => {
      if (!chartContainerRef.current || !valueContainerRef.current) return;
      const valueContainer = valueContainerRef.current.getBoundingClientRect();
      chartContainerRef.current.style.top = (valueContainer.top - valueContainer.height - 145) + 'px';
      chartContainerRef.current.style.left = (valueContainer.left - 230) + 'px';
    };
    setChartPosition();

    window.addEventListener('wheel', () => debounce(setChartPosition, 500));
    window.addEventListener('resize', () => debounce(setChartPosition, 500));

    return () => {
      window.removeEventListener('wheel', setChartPosition);
      window.removeEventListener('resize', setChartPosition);
    };
  }, []);

  if (props.value) {
    if (props.displayTooltip) return (
      <Tooltip
        zIndex={999999}
        overlayStyle={{ opacity: 1 }}
        overlay={props.value}
      >
        <div className={styles.valueContent}>
          <p className={`${styles.value} ${props.onClick ? styles.fixedWith : ''}`}>
            <span ref={valueContainerRef}>{props.value}</span>
            {props.unit && <span>{' ' + t('units.' + props.unit)}</span>}
          </p>
        </div>
      </Tooltip>
    );
    return (
      <div className={styles.valueContent}>
        <TooltipForEllipsis overlay={`${props.value}${props.unit ? (' ' + t('units.' + props.unit)) : ''}`}>
          <p className={`${styles.value} ${props.onClick ? styles.fixedWith : ''}`}>
            <span ref={valueContainerRef}>{props.value}</span>
            {props.unit && <span>{' ' + t('units.' + props.unit)}</span>}
          </p>
        </TooltipForEllipsis>
        {
          (props.chartPropertyOnHover && organization.isEnabled(Flippers.compareToCohort)) &&
          <div className={styles.chartContainer} ref={chartContainerRef}>
            <NormalDistributionChart value={Number(props.value)} property={props.chartPropertyOnHover} />
          </div>
        }
      </div>
    );
  }
  if (!props.editing) return <NoData />;
  return <></>;
}
