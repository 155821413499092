import { TFunction } from 'react-i18next';
import { Tree } from '../../../../../tree/Tree';

export function getTranslation(label: string | string[], t: TFunction, property: string) {
  if (label instanceof Array) return label.map((it: string) => t(Tree.getTKeyForProperty(property, it))).join(', ');
  if (isGraphModalKeyword(label)) return t('workspace.graphModal.' + label);
  return t(Tree.getTKeyForProperty(property, label));
}

function isGraphModalKeyword(label: string): boolean {
  return ['noData', 'other'].includes(label);
}
