import { createContext } from 'react';
import { User } from '../users/User';

export const AuthContext = createContext<{
  user: User,
  logout: () => unknown,
  update: (user: User) => unknown
    }>({
      user: User.anonymous(),
      logout: () => {},
      update: () => {}
    });
