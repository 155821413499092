import styles from '../../Inventory/BaseAttributes.module.scss';
import DetailedTree from '../../../../../tree/DetailedTree';
import Observations from '../../components/HealthAndVitality/Observations';
import { useTranslation } from 'react-i18next';

export default function ObservationsDataGroup(props: ObservationsDataGroupProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.dataGroup}>
      <h3 className={styles.observationHeader}>{t('treeDetails.healthAndVitality.observations')}</h3>
      <Observations
        tree={props.tree}
        singleColumn={props.singleColumn}
      />
    </div>
  );
}

interface ObservationsDataGroupProps {
  tree: DetailedTree,
  singleColumn: boolean
}
