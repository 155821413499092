import * as THREE from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { newVector3FromLocalCoords } from '../../utils/ThreeJsHelpers';
import DetailedTree from '../../tree/DetailedTree';

export class CentroidsGroup extends THREE.Group {
  constructor(tree: DetailedTree) {
    super();

    const canopyCircle = new CSS2DObject(this.createCircle('#7BFC4C'));
    const bifurcationCircle = new CSS2DObject(this.createCircle('#68CBFA'));
    const trunkBaseCircle = new CSS2DObject(this.createCircle('#EA42E3'));

    const canopyCentroidHeight = tree.metricHeight - tree.metricCanopyHeight / 2;

    canopyCircle.position.set(tree.canopyOffset[0], canopyCentroidHeight, -tree.canopyOffset[1]);
    bifurcationCircle.position.copy(
      newVector3FromLocalCoords(tree.firstBifurcationPoint!.coordinates)
        .sub(newVector3FromLocalCoords(tree.localizedLocation))
    );

    this.add(canopyCircle, bifurcationCircle, trunkBaseCircle);
  }

  private createCircle(color: string) {
    const circle = document.createElement('div');
    circle.style.background = color;
    circle.style.width = '16px';
    circle.style.height = '16px';
    circle.style.borderRadius = '50%';

    return circle;
  }
}
