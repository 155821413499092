export enum TreeValueMethod {
  CAVAT = 'treeValueCavat',
  RADO = 'treeValueRado',
  KOCH = 'treeValueKoch',
  VAT19 = 'vat19',
  NORMA_GRANADA = 'normaGranada',
  CTLA = 'ctla',
  ALNARP_MODEL = 'alnarpModel'
}

export const disabledTreeValueMethods = [TreeValueMethod.VAT19, TreeValueMethod.ALNARP_MODEL, TreeValueMethod.CTLA, TreeValueMethod.NORMA_GRANADA];
