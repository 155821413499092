import { Radar } from 'react-chartjs-2';
import { useRef } from 'react';
import { Chart } from 'chart.js';

const plugin = {
  id: 'labelBackground',
  beforeDraw: (chart, args, options) => {
    const padding = 8;
    const { ctx } = chart;
    const labelPosition = chart.scales.r.getPointLabelPosition(options.selectedIndex);
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#FFFFFF3D';
    ctx.beginPath();
    ctx.roundRect(labelPosition.left - padding, labelPosition.top - padding, labelPosition.right - labelPosition.left + 2 * padding, labelPosition.bottom - labelPosition.top + 2 * padding, 4);
    ctx.fill();
    ctx.restore();

    if (options.hoveredIndex !== -1) {
      const hoveredLabelPosition = chart.scales.r.getPointLabelPosition(options.hoveredIndex);
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = '#FFFFFF0F';
      ctx.beginPath();
      ctx.roundRect(hoveredLabelPosition.left - padding, hoveredLabelPosition.top - padding, hoveredLabelPosition.right - hoveredLabelPosition.left + 2 * padding, hoveredLabelPosition.bottom - hoveredLabelPosition.top + 2 * padding, 4);
      ctx.fill();
      ctx.restore();
    }
  },
  defaults: {
    selectedIndex: 0
  }
};

export default function RadarChart(props: RadarChartProps) {
  const radarRef = useRef(null);
  const data = {
    labels: props.data.map(it => it.label + ` ${it.value}/${it.properties.length}`),
    datasets: [{
      data: props.data.map(it => it.value / it.properties.length * 5),
      fill: true,
      backgroundColor: 'rgba(255, 183, 0, 0.4)',
      borderColor: 'rgba(255, 183, 0, 1)',
      pointBackgroundColor: 'rgba(255, 183, 0, 1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255, 183, 0, 1)',
      tension: 0.4
    }]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      },
      labelBackground: {
        selectedIndex: props.selectedSectionIdx,
        hoveredIndex: -1
      }
    },
    elements: {
      line: {
        borderWidth: 3
      },
      point: {
        radius: 0
      }
    },
    scales: {
      r: {
        grid: {
          circular: true,
          color: 'rgba(203, 212, 214, 1)'
        },
        angleLines: {
          color: 'rgba(203, 212, 214, 1)'
        },
        pointLabels: {
          color: 'rgba(203, 212, 214, 1)',
          font: {
            size: 14
          },
          backgroundColor: '#f00',
          padding: 20
        },
        beginAtZero: true,
        max: 5,
        ticks: {
          stepSize: 1,
          display: false
        }
      }
    },
    aspectRatio: 2.5,
    onClick: event => {
      const padding = 8;
      for (let i = 0; i < event.chart.data.labels.length; i++) {
        const label = event.chart.scales.r.getPointLabelPosition(i);
        if (label.left - padding < event.x && event.x < label.right + 2 * padding && label.top - padding < event.y && event.y < label.bottom + 2 * padding) {
          event.chart.config.options.plugins.labelBackground.selectedIndex = i;
          event.chart.update();
          props.setSelectedSectionIdx(i);
        }
      }
    }
  };
  return (
    <Radar
      ref={radarRef}
      data={data}
      options={options}
      plugins={[plugin]}
      onMouseMove={event => {
        if (!radarRef.current) return;
        const chart = Chart.getChart(radarRef.current) as any;
        if (!chart) return;
        const rect = chart.canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const padding = 8;

        let hoveredIndex = -1;
        for (let i = 0; i < chart.data.labels!.length; i++) {
          const label = chart.scales.r.getPointLabelPosition(i);
          if (label.left - padding < x && x < label.right + padding && label.top - padding < y && y < label.bottom + padding) {
            hoveredIndex = i;
            break;
          }
        }
        chart.config.options!.plugins!.labelBackground.hoveredIndex = hoveredIndex;
        chart.update();
      }}
      onMouseLeave={() => {
        if (!radarRef.current) return;
        const chart = Chart.getChart(radarRef.current) as any;
        if (!chart) return;
        chart.config.options!.plugins!.labelBackground.hoveredIndex = -1;
        chart.update();
      }}
    />
  );
}

export interface RadarChartProps {
  data: {
    label: string,
    value: number,
    properties: { label: string, value: boolean }[]
  }[],
  selectedSectionIdx: number,
  setSelectedSectionIdx: (idx: number) => void
}

