import { Tree } from '../tree/Tree';

export enum PredicateType {
  NUMERIC,
  ENUM,
  PROPERTY_CONFIGURATION
}

export default interface FilterPredicate {
  apply(tree: Tree): boolean,
  get type(): PredicateType
}
