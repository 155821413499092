import { ManagedAreaDto } from './ManagedAreaDto';
import { GeoJSONPolygon } from './GeoJSONPolygon';

export class ManagedArea {
  static readonly EMPTY_BOUNDING_BOX = { type: 'Polygon' as const, coordinates: [] };

  static fromDto(dto: ManagedAreaDto) {
    return new ManagedArea(dto.id, dto.code, dto.name, dto.boundingBox);
  }

  static empty() {
    return new ManagedArea('', '', '', ManagedArea.EMPTY_BOUNDING_BOX);
  }

  constructor(
    readonly id: string,
    readonly code: string,
    readonly name: string,
    readonly boundingBox: GeoJSONPolygon
  ) {}

  getName() {
    return this.name || this.code;
  }

  isSameByAnyId(ids: string[]) {
    return ids.includes(this.id);
  }

  getBoundingBoxCoordinates(): [number, number][] {
    return this.boundingBox.coordinates.flat();
  }
}
