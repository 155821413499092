export enum RootObservationOptionEnum {
  CollarBuriedOrNotVisible = 'rootCollarBuriedOrNotVisible',
  StemGirdling = 'rootStemGirdling',
  Dead = 'rootDead',
  Decay = 'rootDecay',
  ConksOrMushrooms = 'rootConksOrMushrooms',
  Ooze = 'rootOoze',
  Cavity = 'rootCavity',
  Cracks = 'rootCracks',
  CutOrDamagedRoots = 'rootCutOrDamagedRoots',
  PlateLifting = 'rootPlateLifting',
  SoilWeakness = 'rootSoilWeakness'
}

export enum TrunkObservationOptionEnum {
  DeadOrMissingBark = 'trunkDeadOrMissingBark',
  AbnormalBarkTextureOrColor = 'trunkAbnormalBarkTextureOrColor',
  CodominantStems = 'trunkCodominantStems',
  IncludedBark = 'trunkIncludedBark',
  Cracks = 'trunkCracks',
  SapwoodDamageOrDecay = 'trunkSapwoodDamageOrDecay',
  CankersOrGallsOrBurls = 'trunkCankersOrGallsOrBurls',
  SapOoze = 'trunkSapOoze',
  LightningDamage = 'trunkLightningDamage',
  HeartwoodDecay = 'trunkHeartwoodDecay',
  ConksOrMushrooms = 'trunkConksOrMushrooms',
  CavityOrNestHole = 'trunkCavityOrNestHole',
  PoorTaper = 'trunkPoorTaper'
}

export enum CrownObservationOptionEnum {
  UnbalancedCrown = 'crownUnbalancedCrown',
  DeadTwigsOrBranches = 'crownDeadTwigsOrBranches',
  BrokenOrHangers = 'crownBrokenOrHangers',
  OverExtendedBranches = 'crownOverExtendedBranches',
  Cracks = 'crownCracks',
  LightningDamage = 'crownLightningDamage',
  Codominant = 'crownCodominant',
  IncludedBark = 'crownIncludedBark',
  WeakAttachments = 'crownWeakAttachments',
  CavityOrNestHole = 'trunkCavityOrNestHole',
  PreviousBranchFailures = 'crownPreviousBranchFailures',
  SimilarBranchesPresent = 'crownSimilarBranchesPresent',
  DeadOrMissingBark = 'crownDeadOrMissingBark',
  CankersOrGallsOrBurls = 'crownCankersOrGallsOrBurls',
  SapwoodDamageOrDecay = 'crownSapwoodDamageOrDecay',
  Conks = 'crownConks',
  HeartwoodDecay = 'crownHeartwoodDecay'
}

const rootObservationOptions = Object.values(RootObservationOptionEnum);
const trunkObservationOptions = Object.values(TrunkObservationOptionEnum);
const crownObservationOptions = Object.values(CrownObservationOptionEnum);

export const rootObservationOptionItems: { id: string, translationKey: string }[] = rootObservationOptions.map(it => ({
  id: it,
  translationKey: `virtualInspection.observationOptions.${it}`
}));

export const trunkObservationOptionItems: { id: string, translationKey: string }[] = trunkObservationOptions.map(it => ({
  id: it,
  translationKey: `virtualInspection.observationOptions.${it}`
}));

export const crownObservationOptionItems: { id: string, translationKey: string }[] = crownObservationOptions.map(it => ({
  id: it,
  translationKey: `virtualInspection.observationOptions.${it}`
}));

export const observationOptions = [
  ...Object.values(RootObservationOptionEnum),
  ...Object.values(TrunkObservationOptionEnum),
  ...Object.values(CrownObservationOptionEnum)
];

export const observationOptionItems: { id: string, translationKey: string }[] = observationOptions.map(it => ({
  id: it,
  translationKey: `virtualInspection.observationOptions.${it}`
}));

export const getObservationNameTKey = (name: string): string => `virtualInspection.observationOptions.${name}`;
