export enum IsaLikelihood {
  'unlikely',
  'somewhatLikely',
  'likely',
  'veryLikely'
}

export enum IsaRiskRating {
  low,
  moderate,
  high,
  extreme
}

export default class IsaRiskStandards {
  private static isaLikelihoodMatrix = [
    [IsaLikelihood.unlikely, IsaLikelihood.unlikely, IsaLikelihood.unlikely, IsaLikelihood.unlikely],
    [IsaLikelihood.unlikely, IsaLikelihood.unlikely, IsaLikelihood.unlikely, IsaLikelihood.somewhatLikely],
    [IsaLikelihood.unlikely, IsaLikelihood.unlikely, IsaLikelihood.somewhatLikely, IsaLikelihood.likely],
    [IsaLikelihood.unlikely, IsaLikelihood.somewhatLikely, IsaLikelihood.likely, IsaLikelihood.veryLikely]
  ];

  private static isaRiskRatingMatrix = [
    [IsaRiskRating.low, IsaRiskRating.low, IsaRiskRating.low, IsaRiskRating.low],
    [IsaRiskRating.low, IsaRiskRating.low, IsaRiskRating.moderate, IsaRiskRating.moderate],
    [IsaRiskRating.low, IsaRiskRating.moderate, IsaRiskRating.high, IsaRiskRating.high],
    [IsaRiskRating.low, IsaRiskRating.moderate, IsaRiskRating.high, IsaRiskRating.extreme]
  ];

  static getRiskRating(failure: number, impact: number, consequences: number): IsaRiskRating {
    return this.isaRiskRatingMatrix[IsaRiskStandards.getLikelihood(failure, impact)][consequences];
  }

  static getLikelihood(failure: number, impact: number): number {
    return this.isaLikelihoodMatrix[failure][impact];
  }
}
