import styles from './SuperOwnerPage.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { createContext, useContext, useMemo, useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import FlippersTab from './tabs/FlippersTab';
import InfoTab from './tabs/InfoTab';
import useOrganizations from './useOrganizations';
import { Organization } from '../../organization/Organization';
import TranslationsTab from './tabs/TranslationsTab';
import { ArrowLeft } from 'iconoir-react';
import DataLoadingTab from './tabs/DataLoadingTab';
import FeaturesTab from './tabs/FeaturesTab';

export enum SortingDirection {
  DESCENDING = 'dsc',
  ASCENDING = 'asc'
}

enum Tabs {
  INFO = 'info',
  DATA_LOADING = 'data loading',
  FLIPPERS = 'flippers',
  TRANSLATIONS = 'translations',
  FEATURES = 'features'
}

type SuperOwnerPageContextType = {
  sortedOrganizations: Organization[],
  sortingProperty: string,
  sortingDirection: SortingDirection,
  onSort: (property: string) => void
};

export const SuperOwnerPageContext = createContext<SuperOwnerPageContextType>({
  sortedOrganizations: [],
  sortingProperty: 'name',
  sortingDirection: SortingDirection.DESCENDING,
  onSort: () => {}
});

export default function SuperOwnerPage() {
  const navigate = useNavigate();
  const user = useContext(AuthContext).user;
  if (!user.superOwner) navigate('/');

  const { organizations } = useOrganizations();
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.INFO);

  const [sortingDirection, setSortingDirection] = useState(SortingDirection.DESCENDING);
  const [sortingProperty, setSortingProperty] = useState('name');

  const onSort = (property: string) => {
    setSortingDirection(sortingDirection === SortingDirection.DESCENDING ? SortingDirection.ASCENDING : SortingDirection.DESCENDING);
    setSortingProperty(property);
  };

  const sortedOrganizations = useMemo(
    () => organizations.sort((a, b) => {
      const result = sortingDirection === SortingDirection.DESCENDING ? 1 : -1;
      if (a[sortingProperty] < b[sortingProperty]) {
        return result * -1;
      } else if (a[sortingProperty] > b[sortingProperty]) {
        return result;
      }
      return 0;
    }), [organizations, sortingProperty, sortingDirection]
  );

  return <div className={styles.superOwnerPage}>
    <SuperOwnerPageContext.Provider value={{ sortedOrganizations, sortingProperty, sortingDirection, onSort }}>
      <Link to={'/'} className={styles.backButton}>
        <ArrowLeft />
      </Link>
      <h1 className={styles.title}>GUF super owner page</h1>
      <nav className={styles.tabSelector}>
        <ul>
          {Object.values(Tabs).map(tab => {
            return <li
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={selectedTab === tab ? styles.selectedTab : ''}
            >
              {tab}
            </li>;
          })}
        </ul>
      </nav>
      <main>
        {selectedTab === Tabs.INFO && <InfoTab />}
        {selectedTab === Tabs.DATA_LOADING && <DataLoadingTab />}
        {selectedTab === Tabs.FLIPPERS && <FlippersTab />}
        {selectedTab === Tabs.FEATURES && <FeaturesTab />}
        {selectedTab === Tabs.TRANSLATIONS && <TranslationsTab />}
      </main>
    </SuperOwnerPageContext.Provider>
  </div>;
}
