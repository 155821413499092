import styles from './DataLoadingTab.module.scss';
import { useContext, useEffect, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { Check } from 'iconoir-react';
import { Job } from '../../../components/Settings/organizations/Job';
import { SuperOwnerPageContext } from '../SuperOwnerPage';

export default function DataLoadingTab() {
  const { jobService } = useContext(DependencyInjectionContext);
  const { sortedOrganizations } = useContext(SuperOwnerPageContext);

  const [jobs, setJobs] = useState<Job[]>([]);

  const twentyFourHoursAgo = new Date();
  twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

  useEffect(() => {
    jobService.listJobs(twentyFourHoursAgo).then(setJobs);
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <td>Organization</td>
              <td>Created at</td>
              <td>Started at</td>
              <td>Finished at</td>
              <td>Status</td>
              <td>Managed area id</td>
              <td>Best images</td>
              <td>Scan intervals</td>
            </tr>
          </thead>
          <tbody>
            {jobs.map(job => (
              <tr>
                <td>{sortedOrganizations.find(org => org.id === job.organizationId)?.name}</td>
                <td>{new Date(job.createdAt).toLocaleString()}</td>
                <td>{job.startedAt ? new Date(job.startedAt).toLocaleString() : ''}</td>
                <td>{job.finishedAt ? new Date(job.finishedAt).toLocaleString() : ''}</td>
                <td>{job.status}</td>
                <td>{job.payload.managedAreaId}</td>
                <td>{job.payload.loadBestImages ? <Check /> : ''}</td>
                <td>{job.payload.loadAllScanIntervals ? <Check /> : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
