import { EnumCondition, Filter, FilterGroup as FilterGroupType, Operator } from './FilterConfig';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircle } from 'iconoir-react';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { Tree } from '../../../tree/Tree';
import FilterRow from './FilterRow';
import Dropdown, { Item } from '../../../components/UI/Dropdown/Dropdown';
import { SpeciesLists } from './SpeciesList';

export default function FilterGroup(props: FilterGroupProps) {
  const { t } = useTranslation();

  const handleDeleteFilter = (index: number) => {
    if (props.group.filters.length === 1) return props.handleDelete();
    props.update(props.groupIndex, {
      ...props.group,
      filters: props.group.filters.filter((_, filterIndex) => filterIndex !== index)
    });
  };

  const handleAddFilter = () => {
    props.update(props.groupIndex, {
      ...props.group,
      filters: [...props.group.filters, { property: '' as keyof Tree, condition: '' as EnumCondition, value: [] }]
    });
  };

  const updateFilter = (filterIndex: number, filter: Filter) => {
    props.update(props.groupIndex, {
      ...props.group,
      filters: props.group.filters.map((f, index) => index === filterIndex ? filter : f)
    });
  };

  const updateOperator = (operator: Operator) => {
    props.update(props.groupIndex, {
      ...props.group,
      filterGroupOperator: operator
    });
  };

  const topLevelOperatorOptions = [
    { id: Operator.AND, translationKey: `taskManager.topLevelOperators.${Operator.AND}` },
    { id: Operator.OR, translationKey: `taskManager.topLevelOperators.${Operator.OR}` }
  ];

  const handleTopLevelOperatorSelect = (item: Item) => {
    props.updateOperator(item.id as Operator);
  };

  return (
    <div className="grid grid-cols-12 items-center text-greehill-00 gap-3 my-3">
      <div className="col-span-2 flex items-center justify-center">
        {props.simpleFiltersCount > 1 ? <span>{t(`taskManager.topLevelOperators.${props.topLevelOperator}`)}</span> : ''}
        {props.simpleFiltersCount === 1 ?
          props.filterGroupIndex === 0 ? (
            <Dropdown
              value={topLevelOperatorOptions.find(it => it.id === props.topLevelOperator)}
              onSelect={handleTopLevelOperatorSelect}
              items={topLevelOperatorOptions}
              className="w-fit"
              fieldClassName="gap-2"
              menuClassname="w-fit"
            />
          ) : <span>{t(`taskManager.topLevelOperators.${props.topLevelOperator}`)}</span>
          : ''}
        {props.simpleFiltersCount === 0 ? (
          props.filterGroupIndex === 0 ? (
            <span>{t('taskManager.where')}</span>
          ) : (
            props.filterGroupIndex === 1 ? (
              <Dropdown
                value={topLevelOperatorOptions.find(it => it.id === props.topLevelOperator)}
                onSelect={handleTopLevelOperatorSelect}
                items={topLevelOperatorOptions}
                className="w-fit"
                fieldClassName="gap-2"
                menuClassname="w-fit"
              />
            ) : <span>{t(`taskManager.topLevelOperators.${props.topLevelOperator}`)}</span>)
        ) : ''}
      </div>
      <div className="col-span-10 rounded-lg bg-outer-space-600 p-2">
        {props.group.filters.map((filter, index) => (
          <FilterRow
            key={`group-${props.groupIndex}-filter-${index}`}
            handleDeleteFilter={() => handleDeleteFilter(index)}
            filter={filter}
            filterIndex={index}
            update={updateFilter}
            updateOperator={updateOperator}
            speciesLists={props.speciesLists}
            topLevelOperator={props.group.filterGroupOperator}
          />
        ))}
        <FunctionButton
          onClick={handleAddFilter}
          className="mt-6 mb-2 mx-4 py-1 px-2"
        >
          <div className="flex items-center gap-2">
            <PlusCircle height={20} width={20}/>
            {t('taskManager.newFilter')}
          </div>
        </FunctionButton>
      </div>
    </div>
  );
}

interface FilterGroupProps {
  handleDelete: () => void,
  group: FilterGroupType,
  groupIndex: number,
  update: (index: number, group: FilterGroupType) => void,
  speciesLists: SpeciesLists,
  topLevelOperator?: Operator,
  filterGroupIndex: number,
  updateOperator: (operator: Operator) => void,
  simpleFiltersCount: number
}
