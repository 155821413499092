import { toast, ToastOptions } from 'react-hot-toast';
import { Xmark } from 'iconoir-react';

function DismissibleToast(props: { message: string, currentToast: ToastOptions }) {
  return (
    <span className="flex items-center">
      {props.message}
      <button
        onClick={() => toast.dismiss(props.currentToast.id)}
        className="twp"
        type="button"
      ><Xmark />
      </button>
    </span>
  );
}

export function dismissibleErrorToast(message: string, options?: ToastOptions) {
  const toastContent = currentToast =>
    <DismissibleToast
      message={message}
      currentToast={currentToast}
    />;
  return toast.error(toastContent, options);
}

export function dismissibleSuccessToast(message: string, options?: ToastOptions) {
  const toastContent = currentToast =>
    <DismissibleToast
      message={message}
      currentToast={currentToast}
    />;
  return toast.success(toastContent, options);
}
