import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsPageTitle } from '../../../routes/Settings/SettingsPageTitle';
import styles from './MembersScreen.module.scss';
import { ButtonWithIcon } from '../../UI/Button/LegacyButton';
import { ReactComponent as PlusIcon } from '../images/plus-icon.svg';
import Modal from '../../Modal/Modal';
import InvitationModal from './InvitationModal';
import InvitationDto from '../../../registration/InvitationDto';
import { Account } from '../../../account/Account';
import { ComparableMemberProperty, Member, MemberSortingDirection } from '../../../organization/Member';
import MemberListView from './MemberListView';
import MemberView from './MemberView';
import MemberListContentView from './MemberListContentView';
import MemberListHeaders from './MemberListHeaders';
import { Interest } from '../../../account/Interest';
import { FiltersView } from './FiltersView';
import SearchInput from './SearchInput';
import { MemberFilters } from './MemberFilters';
import { AccountStatus } from '../../../account/AccountDto';
import { Download } from 'iconoir-react';
import { AccountType } from '../../../account/AccountType';

export default function MembersScreen(props: MembersScreenProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = MemberFilters.fromQuery(searchParams);
  const { t } = useTranslation();

  const [isInvitationModalVisible, setInvitationModalVisibility] = useState(false);
  const showInvitationModal = useCallback(() => setInvitationModalVisibility(true), []);
  const hideInvitationModal = useCallback(() => {
    setInvitationDetailsVisibility(false);
    setInvitationNameVisibility(true);
    setInvitationModalVisibility(false);
  }, []);
  const [showInvitationDetails, setInvitationDetailsVisibility] = useState(false);
  const [showInvitationName, setInvitationNameVisibility] = useState(false);

  const onSort = useCallback(
    (property: ComparableMemberProperty, direction: MemberSortingDirection) => {
      replaceSortingQueryParams(searchParams, property, direction, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (props.isInvitationEmailTaken === null) {
      return;
    }

    if (props.isInvitationEmailTaken) {
      setInvitationDetailsVisibility(true);
      setInvitationNameVisibility(false);
    } else {
      setInvitationDetailsVisibility(true);
      setInvitationNameVisibility(true);
    }
  }, [props.isInvitationEmailTaken]);

  useEffect(() => {
    if (props.isInvitationComplete) {
      setInvitationNameVisibility(false);
      setInvitationDetailsVisibility(false);
      hideInvitationModal();
    }
  }, [hideInvitationModal, props]);

  const setQuery = (query: string) => setSearchParams(filters.setQuery(query).toSearchParams());
  const onInterestChange = (member: Member) => (interests: Interest[]) => props.onInterestChange(member, interests);
  const onStatusChange = (member: Member) => (status: AccountStatus) => props.onStatusChange(member, status);
  const onTypeChange = (member: Member) => (type: AccountType) => props.onTypeChange(member, type);
  const downloadMemberList = () => downloadCsv(toCsv(props.members), 'members');

  return (
    <>
      <div>
        <div className={styles.titleContainer}>
          <SettingsPageTitle>
            {(props.account.canManageMembers() && t('settings.manageMembers')) || t('settings.organizationMembers')}
          </SettingsPageTitle>

          {props.account.canManageMembers() && (
            <div className={styles.titleControls}>
              <ButtonWithIcon
                variant="secondary"
                onClick={downloadMemberList}
                icon={<Download />}>
                {t('settings.members.exportMembers')}
              </ButtonWithIcon>

              <ButtonWithIcon
                variant="primary"
                testId="trigger-invitation-modal"
                onClick={showInvitationModal}
                icon={<PlusIcon />}
              >
                {t('settings.members.invitePeople')}
              </ButtonWithIcon>
            </div>
          )}
        </div>

        <div className={styles.filters}>
          <div className={styles.search}>
            <SearchInput
              onValueChange={setQuery}
              placeholder={t('settings.members.search')}
              value={filters.query} />
          </div>

          <FiltersView />
        </div>

        <div>
          <MemberListView>
            <MemberListHeaders
              currentSortingDirection={props.currentSortingDirection}
              currentSortingProperty={props.currentSortingProperty}
              onChange={onSort}
            />

            <MemberListContentView>
              {props.members.map((member, index) => (
                <MemberView
                  key={index}
                  member={member}
                  onInterestChange={onInterestChange(member)}
                  currentAccount={props.account}
                  onStatusChange={onStatusChange(member)}
                  onTypeChange={onTypeChange(member)}
                />
              ))}
            </MemberListContentView>
          </MemberListView>
        </div>
      </div>

      {props.account.canManageMembers() && (
        <Modal isVisible={isInvitationModalVisible} onHide={hideInvitationModal}>
          <InvitationModal
            organizationName={props.account.organization.name}
            onInvite={props.onInvite}
            showName={showInvitationName}
            showDetails={showInvitationDetails}
            onEmail={props.onInvitationEmail}
            onCancel={hideInvitationModal}
            account={props.account}
            isInvitedAlreadyMember={props.isInvitedAlreadyMember}
          />
        </Modal>
      )}
    </>
  );
}

function replaceSortingQueryParams(
  searchParams: URLSearchParams,
  property: ComparableMemberProperty,
  direction: MemberSortingDirection,
  setSearchParams: (searchParams: URLSearchParams) => unknown
) {
  const newSearchParams = new URLSearchParams(searchParams);

  newSearchParams.delete('sortBy');
  newSearchParams.append('sortBy', property);

  newSearchParams.delete('sortingDirection');
  newSearchParams.append('sortingDirection', direction);

  setSearchParams(newSearchParams);
}

function toCsv(members: Member[]): string {
  return members
    .map(member =>
      [member.id, member.email, member.name, member.status, member.accountType, member.memberSince.toLocaleDateString()]
        .map(data => `"${data.replace(/"/g, '""').replace(/,/g, ',')}"`)
        .join(',')
    )
    .join('\n');
}

function downloadCsv(data: string, name: string) {
  const now = new Date();
  const date = [now.getFullYear(), now.getMonth() + 1, now.getDate()]
    .map(n => n.toString().padStart(2, '0'))
    .join('-');
  const blob = new Blob([data], { type: 'text/csv' });
  const tempLink = document.createElement('a');
  tempLink.href = URL.createObjectURL(blob);
  tempLink.setAttribute('download', `${name}-${date}.csv`);
  tempLink.click();
  tempLink.remove();
}

interface MembersScreenProps {
  onInvite: (invitation: InvitationDto) => Promise<unknown>,
  onInvitationEmail: (email: string) => Promise<unknown>,
  isInvitationEmailTaken: boolean | null,
  isInvitationComplete: boolean,
  isInvitedAlreadyMember: boolean,
  account: Account,
  members: Member[],
  currentSortingProperty: ComparableMemberProperty,
  currentSortingDirection: MemberSortingDirection,
  onInterestChange: (member: Member, updatedInterests: Interest[]) => unknown,
  onStatusChange: (member: Member, status: AccountStatus) => unknown,
  onTypeChange: (member: Member, type: AccountType) => unknown
}
