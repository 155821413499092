import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import { useContext, useState } from 'react';
import styles from './PropertySelectorDropdownMenu.module.scss';
import { NavArrowRight } from 'iconoir-react';
import { useTracking } from '../../analytics/useTracking';
import { useCurrentAccount } from '../../account/useAccounts';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { useTranslation } from 'react-i18next';

export default function DropdownList( props: DropdownListProps) {
  const { organization } = useCurrentAccount();
  const { events, track } = useTracking();
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const [listVisible, setListVisible] = useState(false);

  const getPropertyTitle = (property: DisplayableTreeProperty) => {
    const unit = Tree.getUnit(property, organization);

    const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
    const columnTitle = t(`tree.${property}`) + translatedUnit;

    const onSelect = property => {
      urlContext.setSelectedTreeProperty(property);
      props.close();
      track(events.PROPERTY_LEGEND_SELECTED, { property });
    };

    return (
      <li
        onClick={() => onSelect(property)}
        className={styles.propertyListItem}
        key={`list-item-${property}`}>
        {columnTitle}
      </li>
    );
  };

  return (
    <div
      className={styles.propertyListContainer}
      onMouseEnter={() => setListVisible(true)}
      onMouseLeave={() => setListVisible(false)}
    >
      <div className={styles.propertyListTitle}>
        <div>
          {t(props.name)}
        </div>
        <NavArrowRight />
      </div>

      <ul className={`${styles.propertyList} ${listVisible ? styles.visible : ''}`}>
        {props.list.map(property => (getPropertyTitle(property)))}
      </ul>
    </div>
  );
}

interface DropdownListProps {
  name: string,
  list: DisplayableTreeProperty[],
  close: () => unknown
}
