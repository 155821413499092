import { useContext } from 'react';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { useQuery } from 'react-query';
import { StaticQueryKeys } from '../../StaticQueryKeys';

export default function useOrganizations() {
  const { organizationService } = useContext(DependencyInjectionContext);

  const queryKey = StaticQueryKeys.ORGANIZATIONS;
  const { data } = useQuery(
    queryKey,
    () => {
      return organizationService.listAll();
    },
    { retry: 1, retryOnMount: false }
  );

  return { organizations: data || [] };
}
