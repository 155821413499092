import styles from './AdvancedFilterSummary.module.scss';
import { Xmark, Filter, Plus } from 'iconoir-react';
import { FunctionButton } from '../../../../components/UI/Button/LegacyButton';
import {
  AdvancedFilterByProperty,
  AdvancedFilterConfigurationBuilder,
  AdvancedFilterPredicateType
} from '../advanced-filter/AdvancedFilterConfigurationBuilder';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty } from '../../../../tree/Tree';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import { Property } from '../TableViewer';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { AdvancedFilterType } from '../advanced-filter/AdvancedFilter';
import { AdvancedFilterPredicateTypeSelector } from './AdvancedFilterPredicateTypeSelector';
import AdvancedFilterValueSelector from './AdvancedFilterValueSelector';
import AdvancedFilterEnumSelector from './AdvancedFilterEnumSelector';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import { AvailablePropertiesMap } from '../../../../properties/usePropertyConfigurations';
import AdvancedFilterRangeSelector from './AdvancedFilterRangeSelector';
import { useTracking } from '../../../../analytics/useTracking';

export default function AdvancedFilterSummary(props: AdvancedFilterSummaryProps) {
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const { events, track } = useTracking();

  const [builder, setBuilder] = useState(AdvancedFilterConfigurationBuilder.from(urlContext.getAdvancedFilterConfiguration()));
  const [applyActive, setApplyActive] = useState(false);

  const [filterProperties, setFilterProperties] = useState(AdvancedFilterByProperty.fromAdvancedFilterConfiguration(urlContext.getAdvancedFilterConfiguration())
    .map(it => ({ type: it.getType(), advancedFilter: it })));

  const setAdvFilterPredicateType = (type, index) => {
    setFilterProperties(prev => {
      const filters = [...prev];
      filters[index].type = type;
      return filters;
    });
  };

  const possibleFilterProperties = (Object.values(props.properties).flat() as Property[])
    .concat(['managedAreaId', 'species']).map(it => ({ id: it, translationKey: `tree.${it}` }));

  const deleteFilter = property => {
    builder.removeProperty(property);
    const activeFilters = filterProperties.filter(it => it.advancedFilter.property !== property);
    setFilterProperties(activeFilters);
    if (activeFilters.length === 0) {
      urlContext.setAdvancedFilterConfiguration(builder.build());
    }
    setApplyActive(true);
  };

  const deleteAllFilters = () => {
    track(events.ADVANCED_FILTER_FROM_SUMMARY_MODAL_DELETE);
    setFilterProperties([]);
    const builder = AdvancedFilterConfigurationBuilder.from(null);
    setBuilder(builder);
    urlContext.setAdvancedFilterConfiguration(builder.build());
    props.onHide();
  };

  const applyAllFilters = () => {
    track(events.ADVANCED_FILTER_FROM_SUMMARY_MODAL_APPLY, { filters: builder.build() });
    urlContext.setAdvancedFilterConfiguration(builder.build());
    const activeTableColumns = urlContext.getVisibleTableProperties() || [];
    const activeAdvancedFilters = builder.listAllActiveProperties().filter(it => it !== 'managedAreaId' && it !== 'species') as DisplayableTreeProperty[];
    urlContext.setVisibleTableProperties(Array.from(new Set([...activeTableColumns, ...activeAdvancedFilters])));
    props.onHide();
  };

  const onFilterValueChange = (min, max, filter) => {
    const setOnlyMin = min !== null && max === null;
    const setOnlyMax = min === null && max !== null;

    if (setOnlyMin) builder.removeMax(filter.property);
    if (setOnlyMax) builder.removeMin(filter.property);
    if (min !== null) builder.addMin(filter.property, min);
    if (max !== null) builder.addMax(filter.property, max);
    setApplyActive(true);
    setBuilder(builder);
  };

  const onFilterPropertyChange = (property, index) => {
    track(events.ADVANCED_FILTER_PROPERTY_CHANGED, { property });
    const properties = [...filterProperties];
    const previousProperty = properties[index];
    builder.removeProperty(previousProperty.advancedFilter.property);
    properties[index].advancedFilter = new AdvancedFilterByProperty(property, null, null, [], [], []);
    setFilterProperties(properties);
  };

  const onFilterEnumChange = (property, values) => {
    if (property === 'managedAreaId') {
      values = props.managedAreas.filter(it => values.includes(it.code)).map(it => it.id);
    }

    builder.removeIncludeOnly(property);
    if (values.length > 0) {
      builder.addIncludeOnly(property, values);
    }
    setApplyActive(true);
    setBuilder(builder);
  };

  const onFilterPropertyConfigChange = (property, ranges, rangeIndices) => {
    if (!ranges) {
      return;
    }
    builder.removeProperty(property);
    if (rangeIndices.length > 0) {
      builder.addPropertyConfig(property, ranges, rangeIndices);
    }
    setApplyActive(true);
    setBuilder(builder);
  };

  const addNewRule = () => {
    track(events.ADVANCED_FILTER_FROM_SUMMARY_MODAL);
    const remainingFilterProperties = possibleFilterProperties.filter(prop => filterProperties.findIndex(it => it.advancedFilter.property === prop.id) === -1);
    if (remainingFilterProperties.length === 0) return;
    setFilterProperties([...filterProperties, {
      type: AdvancedFilterType.MIN,
      advancedFilter: new AdvancedFilterByProperty(remainingFilterProperties[0].id, null, null, [], [], [])
    }]);
  };

  const getFilterComponent = (filter: { type: AdvancedFilterType, advancedFilter: AdvancedFilterByProperty }, idx) => {
    const propertyConfiguration = props.propertyConfigurations.find(it => it.property === filter.advancedFilter.property);
    if (AdvancedFilterByProperty.getPredicateType(filter.type) === AdvancedFilterPredicateType.NUMERIC) {
      return (
        <div className={styles.summaryNumeric}>
          <AdvancedFilterPredicateTypeSelector
            predicateType={filter.type}
            onChange={type => setAdvFilterPredicateType(type, idx)} />
          <AdvancedFilterValueSelector
            onChange={(min, max) => onFilterValueChange(min, max, filter.advancedFilter)}
            min={filter.advancedFilter.min}
            max={filter.advancedFilter.max}
            predicateType={filter.type}
            propertyConfig={propertyConfiguration} />
        </div>
      );
    }
    if (AdvancedFilterByProperty.getPredicateType(filter.type) === AdvancedFilterPredicateType.ENUM) {
      return (
        <div className={styles.summaryEnum}>
          <AdvancedFilterEnumSelector
            property={filter.advancedFilter.property}
            onChange={values => onFilterEnumChange(filter.advancedFilter.property, values)}
            options={filter.advancedFilter.property === 'species' ? props.species : props.managedAreas.map(it => it.code)}
            selection={filter.advancedFilter.property === 'managedAreaId' ? filter.advancedFilter.includeOnly
              .map(area => props.managedAreas
                .find(it => it.id === area)?.code || '') : filter.advancedFilter.includeOnly} />
        </div>
      );
    }

    return (
      <AdvancedFilterRangeSelector
        onChange={rangeIndices => onFilterPropertyConfigChange(filter.advancedFilter.property, propertyConfiguration?.ranges, rangeIndices)}
        propertyConfiguration={propertyConfiguration || null}
        rangeIndices={filter.advancedFilter.ranges} />
    );
  };

  return (
    <div data-testid="advanced-filter-summary">
      <div className={styles.advancedSummaryHeader}>
        <div><Filter />{t('treeList.advancedFiltering.advancedFiltering')}</div>
        <FunctionButton onClick={props.onHide} icon={<Xmark />} />
      </div>
      <div className={styles.advancedSummaryMain}>
        {filterProperties.map((filter, idx) => (
          <div key={filter.advancedFilter.property} className={styles.advancedSummaryRowContainer}>
            <div className={styles.inputContainer}>
              <Dropdown
                value={possibleFilterProperties.find(it => it.id === filter.advancedFilter.property)}
                onSelect={item => onFilterPropertyChange(item.id, idx)}
                items={possibleFilterProperties.filter(prop => filterProperties.findIndex(it => it.advancedFilter.property === prop.id) === -1)}
                fieldClassName={styles.rowPropertySelector}
                menuClassname={styles.rowPropertySelectorMenu} />
              {getFilterComponent(filter, idx)}
              {idx !== filterProperties.length - 1 &&
                <div className={styles.andRowSeparator}>{t('treeList.advancedFiltering.and').toUpperCase()}</div>
              }
            </div>
            <FunctionButton
              icon={<Xmark />}
              onClick={() => deleteFilter(filter.advancedFilter.property)}
              testId={`advanced-filter-summary-delete-button-${filter.advancedFilter.property}`}
              className={styles.individualDeleteButton} />
          </div>
        ))
        }
        <FunctionButton
          icon={<Plus />}
          className={styles.addNewRule}
          onClick={addNewRule}>
          {t('treeList.advancedFiltering.newAdvancedRule')}
        </FunctionButton>
      </div>
      <div className={styles.advancedSummaryFooter}>
        <FunctionButton
          onClick={deleteAllFilters}
          testId="advanced-filter-summary-delete-button"
        >
          {t('treeList.advancedFiltering.delete')}
        </FunctionButton>
        <FunctionButton
          onClick={applyAllFilters}
          disabled={!applyActive}
        >
          <strong>{t('treeList.advancedFiltering.apply')}</strong>
        </FunctionButton>
      </div>
    </div>
  );
}

interface AdvancedFilterSummaryProps {
  onHide: () => unknown,
  propertyConfigurations: PropertyConfiguration[],
  managedAreas: { id: string, code: string }[],
  species: string[],
  properties: AvailablePropertiesMap
}
