import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import clamp from '../../../../../utils/clamp';
import { useTranslation } from 'react-i18next';
import { TrackableTreeProperty } from '../../../../../tree/TrackableTreeProperty';
import { DetailsContext } from '../../../LegacyDetails';
import CohortColor from '../../../../../components/cohort/CohortColor';
import { NoData } from '../../Inventory/BaseAttributes';

type NormalDistributionChartProps = {
  value: number,
  property: TrackableTreeProperty
};

const pointRadius = 5;

const drawMiddleLine = {
  id: 'drawMiddleLine',
  afterDraw(chart: Chart) {
    const { ctx, chartArea: { width, height } } = chart;
    ctx.save();
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 1;
    ctx.setLineDash([10, 5]);
    ctx.beginPath();
    ctx.moveTo(width / 2 + pointRadius, pointRadius + 2);
    ctx.lineTo(width / 2 + pointRadius, height + pointRadius + 1);
    ctx.stroke();
    ctx.restore();
  }
};

export default function NormalDistributionChart(props: NormalDistributionChartProps) {
  const { t } = useTranslation();
  const { cohort } = useContext(DetailsContext);
  if (!cohort) {
    return <NoData />;
  }
  const { mu, sigma } = cohort.cohortValues[props.property];
  if (!sigma || !mu) {
    return <NoData />;
  }

  const resolution = 50;
  const width = 3;
  const oneSigma = [2, (2 * width - 2)].map(it => Math.floor(it / (2 * width) * resolution));
  const twoSigma = [1, (2 * width - 1)].map(it => Math.floor(it / (2 * width) * resolution));

  const options = {
    animation: { duration: 0 },
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false
      },
      legend: {
        display: false
      },
      drawValueLine: {
        value: clamp((props.value - (mu - sigma * width)) / (2 * width * sigma))
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    }
  };

  const x = Array(resolution).fill(0).map((_, i) => i / ((resolution - 1) / (2 * width)) - width);

  function gauss(x: number): number {
    return 1 / (Math.sqrt(2 * Math.PI)) * Math.exp(-(x ** 2) / 2);
  }

  const y = x.map(it => gauss(it));

  const indexOfValue = x.reduce((a, b, i) => {
    const normalisedValue = (props.value - mu) / sigma;
    return Math.abs(normalisedValue - b) < Math.abs(normalisedValue - x[a]) ? i : a;
  }, 0);
  const data = {
    labels: x,
    datasets: [
      {
        legend: false,
        label: 'Fill',
        data: y.map((y, i) => (i === indexOfValue) ? y : NaN),
        fill: true,
        borderColor: 'rgba(255, 230, 0, 1)',
        backgroundColor: 'rgba(255, 230, 0, 1)',
        pointRadius,
        pointHoverRadius: pointRadius
      },
      {
        legend: false,
        label: 'Fill',
        data: y.map((y, i) => (i <= twoSigma[0]) ? y : NaN),
        fill: true,
        borderColor: `rgba(${CohortColor.rgbs.belowTwoSigma}, 1)`,
        backgroundColor: `rgba(${CohortColor.rgbs.belowTwoSigma}, 0.5)`,
        pointRadius: 0,
        pointHoverRadius: 0
      },
      {
        label: 'Fill',
        data: y.map((y, i) => (i >= twoSigma[0] && i <= oneSigma[0]) ? y : NaN),
        fill: true,
        borderColor: `rgba(${CohortColor.rgbs.betweenMinusOneAndTwoSigma}, 1)`,
        backgroundColor: `rgba(${CohortColor.rgbs.betweenMinusOneAndTwoSigma}, 0.5)`,
        pointRadius: 0,
        pointHoverRadius: 0
      },
      {
        label: 'My First Dataset',
        data: y.map((y, i) => (i >= oneSigma[0] && i <= oneSigma[1]) ? y : NaN),
        fill: true,
        borderColor: `rgba(${CohortColor.rgbs.withinOneSigma}, 1)`,
        backgroundColor: `rgba(${CohortColor.rgbs.withinOneSigma}, 0.5)`,
        pointRadius: 0,
        pointHoverRadius: 0
      },
      {
        label: 'Fill',
        data: y.map((y, i) => (i <= twoSigma[1] && i >= oneSigma[1]) ? y : NaN),
        fill: true,
        borderColor: `rgba(${CohortColor.rgbs.betweenOneAndTwoSigma}, 1)`,
        backgroundColor: `rgba(${CohortColor.rgbs.betweenOneAndTwoSigma}, 0.5)`,
        pointRadius: 0,
        pointHoverRadius: 0
      },
      {
        label: 'Fill',
        data: y.map((y, i) => (i >= twoSigma[1]) ? y : NaN),
        fill: true,
        borderColor: `rgba(${CohortColor.rgbs.aboveTwoSigma}, 1)`,
        backgroundColor: `rgba(${CohortColor.rgbs.aboveTwoSigma}, 0.5)`,
        pointRadius: 0,
        pointHoverRadius: 0
      }
    ]
  };

  return (
    <>
      <h5>{t('details.properties.' + props.property)}</h5>
      <Line
        options={options}
        data={data}
        plugins={[drawMiddleLine]}
      />
    </>
  );
}
