import { TaskStatus } from '../../../task-manager/Task';
import { useTranslation } from 'react-i18next';

export default function TaskStatusPill({ status }: { status: TaskStatus }) {
  const { t } = useTranslation();

  const getTaskStatusPillColors = () => {
    switch (status) {
    case TaskStatus.ASSIGNED:
      return 'bg-cerulean-blue-100 text-cerulean-blue-600';
    case TaskStatus.ACCEPTED:
      return 'bg-warn-chardonnay-100 text-warn-chardonnay-700';
    case TaskStatus.DRAFT:
      return 'bg-orchid-100 text-orchid-600';
    case TaskStatus.STARTED:
      return 'bg-success-100 text-success-800';
    case TaskStatus.REJECTED || TaskStatus.REVOKED:
      return 'bg-danger-100 text-danger-800';
    case TaskStatus.COMPLETED:
      return 'bg-cyprus-200 text-cyprus-800';
    case TaskStatus.APPROVED:
      return 'bg-greehill-400 text-greehill-900';
    case TaskStatus.ARCHIVED:
      return 'bg-black-haze-300 text-greehill-900';
    case TaskStatus.UNASSIGNED:
      return 'bg-electric-violet-100 text-electric-violet-600';
    default:
      return 'bg-orchid-100 text-orchid-600';
    }
  };

  return <span className={`rounded px-3 py-1 text-xs font-medium ${getTaskStatusPillColors()}`}>
    {t(`taskManager.taskStatus.${status}`)}
  </span>;
}
