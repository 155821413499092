import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import getRuntimeConfig from '../../../../RuntimeConfig';
import { OrganizationLayer } from '../../map-view/map-layers/OrganizationLayer';
import { Organization } from '../../../../organization/Organization';
import { MAP_STYLES } from '../../../../constants';
import CapturePoint from '../../../../capture-point/CapturePoint';
import styles from './Minimap.module.scss';
import { IndicatorLayer } from './IndicatorLayer';

function MinimapRaw(props: MinimapProps, ref: ForwardedRef<unknown>) {
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const apiUrl = getRuntimeConfig().apiUrl;

  const organizationLayer = useMemo(
    () => new OrganizationLayer().setOrganization(props.organization),
    []
  );

  const indicatorLayer = useMemo(
    () => new IndicatorLayer().setIndicator({ position: [0, 0], rotation: 0 }),
    []
  );

  const [isLoaded, setLoadingState] = useState(false);

  useEffect(() => {
    if (!mapRef.current || !indicatorLayer || !isLoaded) return;

    const currentCapturePoint = props.currentCapturePoint;

    if (!currentCapturePoint) return;

    if (mapRef.current.getLayer(indicatorLayer.layerId)) {
      mapRef.current.removeLayer(indicatorLayer.layerId);
    }

    const newPosition = toCoordinates(props.currentCapturePoint);
    indicatorLayer.setIndicator({ position: newPosition, rotation: 0 });
    mapRef.current?.flyTo({ center: newPosition, animate: false, zoom: 14 });

    return () => {
      if (mapRef.current?.getLayer(indicatorLayer.layerId)) {
        mapRef.current?.removeLayer(indicatorLayer.layerId);
      }
    };
  }, [props.currentCapturePoint, isLoaded]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded) {
      return;
    }

    if (mapRef.current.getLayer(organizationLayer.id)) {
      mapRef.current.removeLayer(organizationLayer.id);
    }

    mapRef.current.addLayer(organizationLayer.setOrganization(props.organization));

    return () => {
      mapRef.current?.removeLayer(organizationLayer.id);
      // mapRef.current?.removeLayer(indicatorLayer.id);
    };
  }, [props.organization.id, isLoaded]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded) {
      return;
    }

    if (mapRef.current.getLayer(indicatorLayer.layerId)) {
      mapRef.current.removeLayer(indicatorLayer.layerId);
    }

    mapRef.current.addLayer(indicatorLayer.setIndicator({ position: toCoordinates(props.currentCapturePoint), rotation: 0 }));

    return () => {
      if (mapRef.current?.getLayer(indicatorLayer.layerId)) {
        mapRef.current?.removeLayer(indicatorLayer.layerId);
      }
    };
  }, [isLoaded]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    mapRef.current = new mapboxgl.Map({
      container: containerRef.current,
      style: MAP_STYLES.default,
      dragRotate: true,
      touchZoomRotate: false,
      maxPitch: 0,
      touchPitch: false,
      accessToken: getRuntimeConfig().mapboxApiKey,
      maxZoom: 17,
      transformRequest: url => (url.startsWith(apiUrl) ? { credentials: 'include', url } : { url }),
      logoPosition: 'bottom-right'
    });

    mapRef.current.on('load', () => setLoadingState(true));
    mapRef.current.on('style.load', () => setLoadingState(true));

    return () => {
      if (!mapRef.current) return;
      if (mapRef.current?.getLayer(indicatorLayer.layerId)) {
        mapRef.current?.removeLayer(indicatorLayer.layerId);
      }
      if (mapRef.current.getLayer(organizationLayer.id)) {
        mapRef.current.removeLayer(organizationLayer.id);
      }
      mapRef.current?.remove();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    rotate: angle => {
      indicatorLayer.setRotation(angle);
    }
  }), [indicatorLayer]);

  return (
    <div className={styles.container}>
      <div ref={containerRef}></div>
    </div>
  );
}
interface MinimapProps {
  currentCapturePoint: CapturePoint | null,
  organization: Organization
}

export const Minimap = forwardRef(MinimapRaw);

function toCoordinates(capturePoint: CapturePoint | null): [number, number] {
  return capturePoint ? capturePoint.getWorldCoordinates().reverse() as [number, number] : [0, 0];
}
