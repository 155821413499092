export class Job {
  static fromDto(dto: JobDto) {
    return new Job(
      dto.createdAt,
      dto.errorMessage,
      dto.finishedAt,
      dto.id,
      dto.organizationId,
      dto.payload,
      dto.startedAt,
      dto.status,
      dto.userId
    );
  }
  constructor(
    readonly createdAt: string,
    readonly errorMessage: string,
    readonly finishedAt: string,
    readonly id: string,
    readonly organizationId: string,
    readonly payload: Record<string, unknown>,
    readonly startedAt: null,
    readonly status: JobStatus,
    readonly userId: string
  ) {}
}

export interface JobDto {
  createdAt: string,
  errorMessage: string,
  finishedAt: string,
  id: string,
  organizationId: string,
  payload: Record<string, unknown>,
  startedAt:null,
  status: JobStatus,
  userId: string
}

export enum JobStatus {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED'
}
