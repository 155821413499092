import { useTranslation } from 'react-i18next';
import RiskSlider from './RiskSlider';
import IsaRiskStandards, { IsaLikelihood, IsaRiskRating } from './isaRiskStandards';
import { riskColorMap } from './CurrentState';
import { useState } from 'react';

export enum LikelihoodOfFailure {
  'improbable',
  'possible',
  'probable',
  'imminent'
}

export enum LikelihoodOfImpact {
  'veryLow',
  'low',
  'medium',
  'high'
}

export enum ConsequencesOfFailure {
  'negligible',
  'minor',
  'significant',
  'severe'
}

export default function RiskCategorization(props: RiskCategorizationProps) {
  const { t } = useTranslation();
  const [failure, setFailure] = useState(props.failure);
  const [impact, setImpact] = useState(props.impact);
  const [consequences, setConsequences] = useState(props.consequences);

  const riskRating = IsaRiskRating[IsaRiskStandards.getRiskRating(failure, impact, consequences)];
  return (
    <>
      <div className="flex items-center gap-2 py-3">
        <div className="w-3/5">
          <RiskSlider
            label={t('treeDetails.riskRating.likelihoodOfFailure')}
            value={failure}
            valueLabel={LikelihoodOfFailure[failure]}
            onChange={setFailure}
            onMouseUp={props.setFailure}
          />
          <RiskSlider
            label={t('treeDetails.riskRating.likelihoodOfImpact')}
            value={impact}
            valueLabel={LikelihoodOfImpact[impact]}
            onChange={setImpact}
            onMouseUp={props.setImpact}
          />
        </div>
        <div className="w-2/5 flex flex-col items-center rounded-lg bg-outer-space-700 py-10">
          <span>{t('taskManager.risk.failureAndImpact')}</span>
          <span className="uppercase text-sm font-bold pt-1">{t(`treeDetails.riskRating.isaLikelihood.${IsaLikelihood[IsaRiskStandards.getLikelihood(failure, impact)]}`)}</span>
        </div>
      </div>
      <div className="flex items-center gap-2 py-3">
        <div className="w-3/5">
          <RiskSlider
            label={t('treeDetails.riskRating.consequencesOfFailure')}
            value={consequences}
            valueLabel={ConsequencesOfFailure[consequences]}
            onChange={setConsequences}
            onMouseUp={props.setConsequences}
          />
        </div>
        <div className="w-2/5 flex flex-col items-center rounded-lg bg-outer-space-700 py-6">
          <span >{t('treeDetails.riskRating.riskRating')}:</span>
          <span className="uppercase text-sm font-bold pt-1">
            {t(`treeDetails.riskRating.isaRiskRating.${riskRating}`)}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-2 pt-3">
        <span className="w-1/3 text-outer-space-200">{t('treeDetails.riskRating.riskRating')}</span>
        <span className="w-2/3 flex items-center gap-2">
          <div className={`size-3 rounded-full ${riskColorMap.get(riskRating)}`} />
          {t(`treeDetails.riskRating.isaRiskRating.${riskRating}`)}
        </span>
      </div>
    </>
  );
}

export interface RiskCategorizationProps {
  failure: number,
  setFailure?: (failure: LikelihoodOfFailure) => void,
  impact: number,
  setImpact?: (impact: LikelihoodOfImpact) => void,
  consequences: number,
  setConsequences?: (consequences: ConsequencesOfFailure) => void
}
