import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import styles from './ManagedAreaFilter.module.scss';
import { ArrowLeft, Xmark, Map as MapIcon, Plus } from 'iconoir-react';
import { FunctionButton } from '../../../UI/Button/LegacyButton';
import { SearchInput } from '../../../UI/Input/Input';
import { ManagedAreaRow } from '../SidebarFilter';
import { ManagedArea } from '../../../../managed-area/ManagedArea';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useTracking } from '../../../../analytics/useTracking';

export default function ManagedAreaFilter({ managedAreas, selectedManagedAreaIds }: ManagedAreaFilterProps) {
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const [firstPage, setFirstPage] = useState(true);
  const { events, track } = useTracking();
  const allManagedAreasSelected = urlContext.getReverseMASelection() && urlContext.getManagedAreaIds().length === 0;

  const [query, setQuery] = useState('');
  const [listedManagedAreas, setListedManagedAreas] = useState(managedAreas);

  useEffect(() => {
    const filtered = managedAreas.filter(it => it.getName().toLowerCase().includes(query.toLowerCase()));
    setListedManagedAreas(filtered);
  }, [query, managedAreas.map(it => it.id).join('')]);

  useEffect(() => {
    if (query === '') return;

    const exitFilteringOnEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setQuery('');
      }
    };

    window.addEventListener('keydown', exitFilteringOnEscape);

    return () => {
      window.removeEventListener('keydown', exitFilteringOnEscape);
    };
  }, [query]);

  const handleAllButtonClick = () => {
    if (allManagedAreasSelected) {
      urlContext.setManagedAreaIds([]);
      urlContext.setReverseMASelection(false);
      track(events.SIDEBAR_ALL_MANAGED_AREA_DELETED);
    } else {
      setFirstPage(true);
      urlContext.setManagedAreaIds([]);
      urlContext.setReverseMASelection(true);
      track(events.SIDEBAR_ALL_MANAGED_AREA_SELECTED);
    }
  };

  const toggleSelection = id => {
    const managedAreaIds = urlContext.getManagedAreaIds();
    const reversed = urlContext.getReverseMASelection();
    if (managedAreaIds.includes(id)) {
      urlContext.removeManagedAreaId(id);
      if (reversed) {
        track(events.SIDEBAR_MANAGED_AREA_SELECTED, { managedAreaId: id });
      } else {
        track(events.SIDEBAR_MANAGED_AREA_DELETED, { managedAreaId: id });
      }
    } else {
      urlContext.appendManagedAreaId(id);
      if (reversed) {
        track(events.SIDEBAR_MANAGED_AREA_DELETED, { managedAreaId: id });
      } else {
        track(events.SIDEBAR_MANAGED_AREA_SELECTED, { managedAreaId: id });
      }
    }
  };

  const navigatePages = () => {
    setFirstPage(it => !it);
  };

  const secondPageTitle = selectedManagedAreaIds.length
    ? t('sidebarFilter.selected', { count: selectedManagedAreaIds.length })
    : t('sidebarFilter.selectAreas');

  return (
    <div className={`${styles.areaContainer} ${!firstPage && styles.areaSecondPageContainer}`}>
      { firstPage ?
        <>
          <div className={styles.areaFirstPage}>
            <div className={styles.areaLabelContainer}>
              <MapIcon/>
              <div className={styles.areaLabel}>
                <span>{t('sidebarFilter.selectAreas')}</span>
                <span>{t('sidebarFilter.managedAreas')}</span>
              </div>
            </div>
            <FunctionButton
              icon={<Plus/>}
              className={styles.toSecondPage}
              testId={'sidebar-navigate-to-ma-second-page'}
              onClick={() => navigatePages()}/>
          </div>

          <ul className={styles.selectedAreasList}>
            {!allManagedAreasSelected ?
              managedAreas.filter(it => selectedManagedAreaIds.includes(it.id))
                .map((managedArea, index) => (
                  <li className={styles.selectedArea} key={index}>
                    {managedArea.getName()}
                    <FunctionButton icon={<Xmark/>} onClick={() => toggleSelection(managedArea.id)}/>
                  </li>
                ))
              :
              <li className={styles.selectedArea}>
                <span>{t('sidebarFilter.allSelected')}</span>
                <FunctionButton icon={<Xmark/>} onClick={handleAllButtonClick}/>
              </li>
            }
          </ul>
        </>
        :
        <div className={styles.areaSecondPage}>
          <div className={styles.areaSecondPageHeader}>
            <div className={styles.flexBox}>
              <FunctionButton
                className={styles.toFirstPage}
                icon={<ArrowLeft/>}
                onClick={() => navigatePages()}
              />
              <span>{secondPageTitle}</span>
            </div>
            <button
              className={styles.selectAll}
              onClick={handleAllButtonClick}
              data-testid={'select-all-mas-toggle'}>
              {t(!allManagedAreasSelected ? 'sidebarFilter.selectAll' : 'sidebarFilter.unselectAll')}
            </button>
          </div>

          <div className={styles.searchInputContainer}>
            <SearchInput
              dense
              onValueChange={setQuery}
              value={query}
              placeholder={t('sidebarFilter.searchManagedAreas')}
            />
          </div>

          <ul className={styles.managedAreas}>
            {listedManagedAreas.map(managedArea => {
              return (
                <ManagedAreaRow
                  key={managedArea.getName()}
                  onToggle={toggleSelection}
                  isSelected={urlContext.isManagedAreaSelected(managedArea)}
                  managedArea={managedArea}
                />
              );
            })}
          </ul>
        </div>
      }
    </div>
  );
}

interface ManagedAreaFilterProps {
  managedAreas: ManagedArea[],
  selectedManagedAreaIds: string[]
}
