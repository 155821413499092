import React, { ReactNode } from 'react';
import styles from './TreePropertyTable.module.scss';
import { useTranslation } from 'react-i18next';
import { DateSelector } from './ChartControls';

export function TreePropertyTable(props) {
  return (
    <div>
      <div style={{ marginBottom: '30px' }}>
        { props.header ? props.header : null }
      </div>
      <div className={`${styles.table} ${props.compare ? styles.stretch : ''}`}>
        { props.children }
      </div>
    </div>
  );
}

export function TreePropertyTableRow(props: TreePropertyTableRowProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.tableRow}>
      <div>{ t('analytics.properties.' + props.property) }</div>
      <div data-testid={`${props.property}-row-unit`} style={props.unitStyles}>{ props.unit }</div>
      <div data-testid={`${props.property}-row-value`} className={styles.valueCell}>
        { props.valueFormatter ? props.valueFormatter(props.value, props.unit) : props.value.toFixed(2) }
        <span className={`${styles.indicator} ${props.value > props.comparisonValue ? styles.green : styles.red} ${ !props.showComparisonValue ? styles.shrinkIcon : ''}`}>
          { props.value > props.comparisonValue ? '▲' : '▼'}
        </span>
      </div>
      { props.showComparisonValue ? <div className={styles.valueCell}>
        { props.valueFormatter ? props.valueFormatter(props.comparisonValue, props.unit) : props.comparisonValue.toFixed(2) }
      </div> : null }
    </div>
  );
}

export function TreePropertyDateSelectorTableRow(props: TreePropertyDateSelectorTableRowProps) {
  return (
    <div className={`${styles.tableRow} ${styles.dateTableRow}`}>
      <div></div>
      <div></div>
      <DateSelector
        variant="current"
        selected={props.dateA}
        options={props.availableDates}
        onSelect={props.setDateA} />
      <DateSelector
        variant="reference"
        selected={props.dateB}
        options={props.availableDates}
        onSelect={props.setDateB} />
    </div>
  );
}

export interface TreePropertyTableProps {
  children: ReactNode,
  header: ReactNode
}

export interface TreePropertyTableRowProps {
  property: string,
  unit: string,
  value: number,
  showComparisonValue: boolean,
  comparisonValue: number,
  valueFormatter?: (value: number, unit: string) => string,
  unitStyles?: React.CSSProperties
}

export interface TreePropertyDateSelectorTableRowProps {
  dateA: any,
  dateB: any,
  availableDates: any,
  setDateA: (any) => any,
  setDateB: (any) => any
}
