import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { SearchInput } from '../../../components/UI/Input/Input';
import styles from './ManagedAreaList.module.scss';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import { ManagedArea } from '../../../managed-area/ManagedArea';

export default function ManagedAreaList(props: ManagedAreaListProps) {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [listedManagedAreas, setListedManagedAreas] = useState(props.managedAreaList);

  useEffect(() => {
    if (props.isLoadingManagedAreaList) return;
    const filtered = props.managedAreaList.filter(it => it.getName().toLowerCase().includes(searchQuery.toLowerCase()));
    setListedManagedAreas(filtered);
  }, [searchQuery, props.isLoadingManagedAreaList]);

  const select = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    const isShiftKeyPressed = 'shiftKey' in event.nativeEvent && event.nativeEvent.shiftKey;
    if (isShiftKeyPressed) {
      const lastSelected = props.selectedManagedAreaIds.at(-1);
      const lastSelectedIndex = listedManagedAreas.findIndex(area => area.id === lastSelected);
      const clickedIndex = listedManagedAreas.findIndex(area => area.id === id);

      const start = Math.min(lastSelectedIndex, clickedIndex);
      const end = Math.max(lastSelectedIndex, clickedIndex);

      const selectedAreas = listedManagedAreas.slice(start, end + 1);
      const selectedAreaIds = selectedAreas.map(area => area.id);
      const mergedAreaIds = [...Array.from(new Set([...props.selectedManagedAreaIds, ...selectedAreaIds]))];

      props.setSelectedManagedAreaIds(mergedAreaIds);
    } else {
      props.toggleSelection(id);
    }
  };

  return (
    <div className={styles.container}>
      <div className="flex items-center gap-6">
        <div
          className="text-center text-white text-base font-bold font-['Inter'] leading-normal"
        >{t('taskManager.managedAreas')}</div>
        <div>
          <FunctionButton
            variant="rounded"
            onClick={props.toggleReverseMASelection}
          >
            {t('taskManager.selectAll')}
          </FunctionButton>
        </div>
      </div>
      <div className={styles.searchInputContainer}>
        <SearchInput
          dense
          onValueChange={setSearchQuery}
          value={searchQuery}
          placeholder={t('workspace.areaFilterModal.search')}
        />
      </div>
      <ul className={styles.managedAreaList}>
        {listedManagedAreas.map(managedArea => (
          <ListItem
            key={managedArea.id}
            selected={props.selectedManagedAreaIds.includes(managedArea.id) !== props.reverseMASelection}
            managedArea={managedArea}
            onSelect={select}
          />
        ))}
      </ul>
    </div>
  );
}

function ListItem(props: {
  managedArea: ManagedArea,
  selected: boolean,
  onSelect: (e: ChangeEvent<HTMLInputElement>, id: string) => unknown
}) {
  return (
    <li className={`${styles.managedAreaRow} ${props.selected && styles.selectedManagedArea}`}>
      <Checkbox
        checked={props.selected}
        onChange={e => props.onSelect(e, props.managedArea.id)}
      >
        {props.managedArea.getName()}
      </Checkbox>
    </li>
  );
}

interface ManagedAreaListProps {
  selectedManagedAreaIds: string[],
  setSelectedManagedAreaIds: (ids: string[]) => void,
  toggleSelection: (id: string) => void,
  reverseMASelection: boolean,
  toggleReverseMASelection: () => void,
  managedAreaList: ManagedArea[],
  isLoadingManagedAreaList: boolean
}
