import styles from '../Inventory/Inventory.module.scss';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useContext, useState } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import Dropdown, { Item } from '../../../../components/UI/Dropdown/Dropdown';
import { DisplayableTreeProperty, Tree, TreeDto } from '../../../../tree/Tree';
import DetailedTree from '../../../../tree/DetailedTree';
import Input from '../../../../components/UI/Input/Input';
import { Condition } from '../../../../property-enums/Condition';
import { Foliage } from '../../../../property-enums/Foliage';
import { VitalityVigor } from '../../../../property-enums/VitalityVigor';
import Observations from '../components/HealthAndVitality/Observations';
import DataGroupHeader from '../components/DataGroupHeader/DataGroupHeader';
import { useCurrentAccount } from '../../../../account/useAccounts';
import EditingFieldWrapper from '../components/EditingFieldWrapper/EditingFieldWrapper';

export enum HealthAndVitalityTabGroups {
  HEALTH = 'HEALTH',
  OBSERVATION = 'OBSERVATION'
}

export default function HealthAndVitalityTab(props: HealthAndVitalityTabProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const { treeService } = useContext(DependencyInjectionContext);
  const [editing, setEditing] = useState<HealthAndVitalityTabGroups | undefined>();

  const isBerlin = ['berlin', 'de-ber23-0164'].includes(account.organization.tseBlobContainer);

  const initialHealth = {
    condition: props.tree.condition,
    foliage: props.tree.foliage,
    dieback: props.tree.dieback,
    vitalityBerlin: props.tree.vitalityBerlin,
    vitalityVigor: props.tree.vitalityVigor,
    liveCrownRatio: props.tree.liveCrownRatio,
    leafArea: props.tree.leafArea
  };

  const [health, setHealth] = useState(initialHealth);

  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(health).forEach(key => {
      if (health[key] !== props.tree[key] && health[key] !== '') {
        if ((key === 'vitalityVigor' && isBerlin) || (key === 'vitalityBerlin' && !isBerlin)) return;
        updatedValues[key] = health[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(props.organizationId, props.tree.id, updatedValues);
    }
    setEditing(undefined);
  };

  const handleCancel = () => {
    setEditing(undefined);
    setHealth(initialHealth);
  };

  const conditionItems: Item[] = Object.values(Condition).map(condition => {
    return { id: condition, translationKey: 'details.properties.conditionTypes.' + condition };
  });
  const foliageItems: Item[] = Object.values(Foliage).map(foliage => {
    return { id: foliage, translationKey: 'details.properties.foliageTypes.' + foliage };
  });
  const vitalityVigorItems: Item[] = Object.values(VitalityVigor).map(vitalityVigor => {
    return { id: vitalityVigor, translationKey: 'tree.vitalityVigorTypes.' + vitalityVigor };
  });
  const vitalityBerlinItems: Item[] = ['0', '1', '2', '3', '4'].map(vitalityBerlin => {
    return { id: vitalityBerlin, translationKey: 'details.properties.vitalityBerlinTypes.' + vitalityBerlin };
  });

  const conditionValue = health.condition ? t(`details.properties.conditionTypes.${health.condition}`) : '';
  const foliageValue = health.foliage ? t(`details.properties.foliageTypes.${health.foliage}`) : '';
  const vitalityVigorValue = health.vitalityVigor ? t(`tree.vitalityVigorTypes.${health.vitalityVigor}`) : '';
  const vitalityBerlinValue = health.vitalityBerlin !== null ? t(`details.properties.vitalityBerlinTypes.${health.vitalityBerlin}`) : '';

  const handleRatioChange = (event: ChangeEvent<HTMLInputElement>, propertyName: DisplayableTreeProperty) => {
    let newValue = Number(event.target.value);
    if (newValue < 0) newValue = 0;
    if (newValue > 100) newValue = 100;
    setHealth(prev => ({ ...prev, [propertyName]: newValue }));
  };

  const isEditingHealth = editing === HealthAndVitalityTabGroups.HEALTH;

  return (
    <div className={styles.content}>
      <div className={styles.dataGroup}>
        <DataGroupHeader
          editing={isEditingHealth}
          setEditing={() => setEditing(HealthAndVitalityTabGroups.HEALTH)}
          handleCancel={handleCancel}
          handleSave={handleSave}
          title={t('treeDetails.tabs.subTitles.conditionAndVigor')}
        />
        <div className={`${styles.dataGroupGrid} ${props.singleColumn ? styles.singleColumn : ''}`}>
          {isBerlin ?
            <EditingFieldWrapper
              title={t('details.properties.vitalityBerlin')}
              value={vitalityBerlinValue}
              editing={isEditingHealth}
              className={styles.berlin}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={vitalityBerlinItems}
                value={{ translationKey: vitalityBerlinValue }}
                onSelect={item => setHealth(prev => ({
                  ...prev,
                  vitalityBerlin: item.id
                }))}
              />
            </EditingFieldWrapper> :
            <EditingFieldWrapper
              title={t('details.properties.vitalityVigor')}
              value={vitalityVigorValue}
              editing={isEditingHealth}
            >
              <Dropdown
                fieldClassName={styles.dropdownField}
                menuClassname={styles.menuClassname}
                openClassname={styles.openClassname}
                items={vitalityVigorItems}
                value={{ translationKey: vitalityVigorValue }}
                onSelect={item => setHealth(prev => ({
                  ...prev,
                  vitalityVigor: item.id!
                }))}
              />
            </EditingFieldWrapper>}
          <EditingFieldWrapper
            title={t('details.properties.liveCrownRatio')}
            value={health.liveCrownRatio === null ? '' : health.liveCrownRatio.toFixed(0)}
            unit={Tree.getUnit(DisplayableTreeProperty.LiveCrownRatio, account.organization)}
            editing={isEditingHealth}
          >
            <div className={styles.editingNumberInput}>
              <Input
                value={health.liveCrownRatio === null ? '' : health.liveCrownRatio.toFixed(0)}
                label={''}
                onChange={e => handleRatioChange(e, DisplayableTreeProperty.LiveCrownRatio)}
                type="number"
                className={styles.editingInput}
              />
              <span className={styles.unit}>%</span>
            </div>
          </EditingFieldWrapper>
          <EditingFieldWrapper
            title={t('details.properties.condition')}
            value={conditionValue}
            editing={isEditingHealth}
          >
            <Dropdown
              fieldClassName={styles.dropdownField}
              menuClassname={styles.menuClassname}
              openClassname={styles.openClassname}
              items={conditionItems}
              value={{ translationKey: conditionValue }}
              onSelect={item => setHealth(prev => ({
                ...prev,
                condition: item.id
              }))}
            />
          </EditingFieldWrapper>
          <EditingFieldWrapper
            title={t('details.properties.dieback')}
            value={health.dieback === null ? '' : health.dieback + ' %'}
            editing={isEditingHealth}
          >
            <div className={styles.editingNumberInput}>
              <Input
                value={health.dieback === null ? '' : health.dieback}
                label={''}
                onChange={e => handleRatioChange(e, DisplayableTreeProperty.Dieback)}
                type="number"
                className={styles.editingInput}
              />
              <span className={styles.unit}>%</span>
            </div>
          </EditingFieldWrapper>
          <EditingFieldWrapper
            title={t('details.properties.leafAreaPercentOfCrownVolume')}
            value={props.tree.leafAreaPerCrownVolume || ''}
            unit={Tree.getUnit(DisplayableTreeProperty.LeafAreaPerCrownVolume, account.organization)}
          />
          <EditingFieldWrapper
            title={t('details.properties.foliage')}
            value={foliageValue}
            editing={isEditingHealth}
          >
            <Dropdown
              fieldClassName={styles.dropdownField}
              menuClassname={styles.menuClassname}
              openClassname={styles.openClassname}
              items={foliageItems}
              value={{ translationKey: foliageValue }}
              onSelect={item => setHealth(prev => ({
                ...prev,
                foliage: item.id
              }))}
            />
          </EditingFieldWrapper>
          <EditingFieldWrapper
            title={t('tree.leafArea')}
            value={props.tree.leafArea || ''}
            unit={Tree.getUnit(DisplayableTreeProperty.LeafArea, account.organization)}
          />
          <EditingFieldWrapper
            title={t('tree.crownLightExposure')}
            value={props.tree.crownLightExposure || ''}
          />
          <EditingFieldWrapper
            title={t('tree.ndvi')}
            value={props.tree.ndvi || ''}
          />
        </div>
      </div>
      <Observations
        tree={props.tree}
        singleColumn={props.singleColumn}
      />
    </div>
  );
}

interface HealthAndVitalityTabProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organizationId: string
}
