import { CustomLayerInterface, EventData, MapEventType } from 'mapbox-gl';
import { HtmlTreeMarkerLight } from './HtmlTreeMarkerLight';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { RefObject } from 'react';
import * as THREE from 'three';
import { Organization } from '../../../organization/Organization';
import { MercatorCoordinate } from '../../../routes/Explore/MercatorCoordinate';
import { GeoJSONTree } from '../../../tree/GeoJSONTree';

export class InlineMapTreeMarkerLayer implements CustomLayerInterface {
  static readonly ID = 'custom-tree-layer';
  readonly id = InlineMapTreeMarkerLayer.ID;

  readonly type = 'custom' as const;
  private map?: mapboxgl.Map;
  private camera?: THREE.Camera;
  private scene = new THREE.Scene();
  private renderer?: THREE.WebGLRenderer;
  private markers: HtmlTreeMarkerLight[] = [];
  private hasInitialZoomCompleted;
  private htmlRenderer?: CSS2DRenderer;

  constructor(
    private readonly apiUrl: string,
    private organization: Organization,
    private selectedTreeId: string | null,
    private readonly containerRef: RefObject<HTMLDivElement>,
    private managedAreaId: string
  ) {
    this.hasInitialZoomCompleted = selectedTreeId === null;
  }

  private readonly onData = event => this.createMarkers(event);
  private onResize: () => void = () => {};

  onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    this.htmlRenderer = new CSS2DRenderer();
    this.htmlRenderer.domElement.style.position = 'absolute';
    this.htmlRenderer.domElement.style.top = '0px';
    this.htmlRenderer.domElement.style.left = '0px';

    this.containerRef.current!.insertBefore(this.htmlRenderer.domElement, this.containerRef.current!.firstChild);

    this.map = map;

    this.camera = new THREE.PerspectiveCamera();
    this.scene = new THREE.Scene();

    const canvas = map.getCanvas();

    this.htmlRenderer.setSize(
      Number(canvas.style.width.slice(0, canvas.style.width.indexOf('px'))),
      Number(canvas.style.height.slice(0, canvas.style.height.indexOf('px')))
    );

    this.renderer = new THREE.WebGLRenderer({
      canvas,
      context: gl,
      antialias: false,
      stencil: false,
      powerPreference: 'high-performance'
    });
    this.renderer.sortObjects = false;
    this.renderer.shadowMap.enabled = false;
    this.renderer.autoClear = false;

    this.addTreeSource();
    this.addTreeLoaderLayer();

    this.onResize = this.setSize.bind(this);
    window.addEventListener('resize', this.onResize);

    map.on('data', this.onData);
  }

  onRemove(map: mapboxgl.Map) {
    map.off('data', this.onData);

    this.removeTreeLoaderLayer();
    this.removeTreeSource();

    this.scene.clear();
    this.markers.forEach(it => it.removeListeners());
    this.markers = [];

    if (this.htmlRenderer?.domElement && this.containerRef.current) {
      this.containerRef.current.removeChild(this.htmlRenderer?.domElement);
    }
    window.removeEventListener('resize', this.onResize);
  }

  prerender(gl: WebGLRenderingContext, matrix: number[]) {
    this.scene.visible = true;

    if (this.htmlRenderer?.domElement.innerHTML) {
      this.htmlRenderer.domElement.innerHTML = '';
    }

    if (this.markers.length === 0) {
      return;
    }

    const mapCenterAsMercatorCoordinates = new MercatorCoordinate(this.map!.getCenter().toArray());
    const scale = mapCenterAsMercatorCoordinates.scale;

    for (let i = this.markers.length - 1; i >= 0; i--) {
      this.markers[i].setPosition(mapCenterAsMercatorCoordinates.x, mapCenterAsMercatorCoordinates.y, scale);
    }
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    gl.clear(gl.DEPTH_BUFFER_BIT);

    if (this.markers.length > 0) {
      const mapCenterAsMercatorCoordinates = new MercatorCoordinate(this.map!.getCenter().toArray());
      const scale = mapCenterAsMercatorCoordinates.scale;

      this.camera!.projectionMatrix = new THREE.Matrix4()
        .fromArray(matrix)
        .multiply(
          new THREE.Matrix4()
            .makeTranslation(mapCenterAsMercatorCoordinates.x, mapCenterAsMercatorCoordinates.y, 0)
            .scale(new THREE.Vector3(scale, -scale, scale))
        );
    }
    this.renderer!.resetState();
    this.renderer!.render(this.scene, this.camera!);
    this.htmlRenderer!.render(this.scene, this.camera!);
  }

  resize() {
    const canvas = this.map?.getCanvas();
    if (!this.htmlRenderer || !canvas || !this.camera) {
      return;
    }

    this.htmlRenderer.setSize(
      Number(canvas.style.width.slice(0, canvas.style.width.indexOf('px'))),
      Number(canvas.style.height.slice(0, canvas.style.height.indexOf('px')))
    );

    this.htmlRenderer.render(this.scene, this.camera);
  }

  private createMarkers(event: MapEventType['data'] & EventData) {
    if (event.sourceId !== 'trees') {
      return;
    }
    const treeData = event.target.querySourceFeatures('trees', { sourceLayer: 'trees' }) as unknown as GeoJSONTree[];
    if (treeData.length === 0) return;

    const newTreeData = treeData.filter(
      tree => !this.markers.some(marker => marker.belongsTo(tree.properties.id))
    );
    this.markers.push(
      ...newTreeData.map(data => {
        const marker = HtmlTreeMarkerLight.create(data.properties.id, data.geometry.coordinates);

        if (data.properties.managedAreaId === this.managedAreaId) {
          marker.disable();
          marker.addTo(this.scene);
        }
        return marker;
      }
      )
    );

    const selectedMarker = this.markers.find(it => it.belongsTo(this.selectedTreeId ?? ''))?.select();
    if (selectedMarker && !this.hasInitialZoomCompleted) {
      this.hasInitialZoomCompleted = true;
    }

    this.map?.triggerRepaint();
  }

  private addTreeSource() {
    this.map?.addSource('trees', {
      type: 'vector',
      tiles: [`${this.apiUrl}/v1/organizations/${this.organization.id}/mvt/trees?x={x}&y={y}&z={z}`]
    });
  }

  private removeTreeSource() {
    if (this.map?.getSource('trees')) {
      this.map.removeSource('trees');
    }
  }

  private addTreeLoaderLayer() {
    this.map?.addLayer({
      id: 'tree-loader-layer',
      type: 'circle',
      source: 'trees',
      'source-layer': 'trees',
      paint: { 'circle-opacity': 0 }
    });
  }

  private removeTreeLoaderLayer() {
    if (this.map?.getLayer('tree-loader-layer')) {
      this.map.removeLayer('tree-loader-layer');
    }
  }

  private setSize() {
    const canvas = this.map?.getCanvas();
    if (!canvas || !this.htmlRenderer || !this.renderer || !this.camera) {
      return;
    }

    this.htmlRenderer.setSize(canvas.clientWidth, canvas.clientHeight);

    this.renderer.render(this.scene, this.camera);
  }
}
