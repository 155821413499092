import styles from './TranslationTool.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import LegacyButton, { FunctionButton } from '../../components/UI/Button/LegacyButton';
import { downloadBlob } from '../Explore/table-view/components/FileDownloadButton';
import { ArrowLeft, CloudDownload, InfoCircle } from 'iconoir-react';
import Tooltip from '../../components/UI/Tooltip/Tooltip';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../components/UI/Toast/DismissibleToast';

type Translation = { key: string, outdatedFor: string[] } & { [key: string]: string };
export default function TranslationTool() {
  const navigate = useNavigate();
  const user = useContext(AuthContext).user;
  if (!user.isTranslator()) navigate('/');

  const { translationService } = useContext(DependencyInjectionContext);

  const [selectedLang, setSelectedLang] = useState(user.translatorFor[0]);
  const [translations, setTranslations] = useState<Translation[]>([]);
  const [reloadTrigger, setReloadTrigger] = useState(true);

  const sortTranslations = (translations, selectedLang) => {
    return translations.sort((a, b) => {
      const outdatedA = a.outdatedFor.includes(selectedLang);
      const outdatedB = b.outdatedFor.includes(selectedLang);

      if (outdatedA && !outdatedB) return -1;
      if (!outdatedA && outdatedB) return 1;

      const langA = a[selectedLang];
      const langB = b[selectedLang];

      if ((langA === null || langA === '') && (langB === null || '')) return 0;
      if (langA === null || langA === '') return -1;
      if (langB === null || langB === '') return 1;

      return 0;
    });
  };

  useEffect(() => {
    translationService.getLanguagesForTranslator()
      .then(it => {
        setTranslations(sortTranslations(it, selectedLang));
      });
  }, [reloadTrigger]);

  const updateTranslation = async (key: string, value: string) => {
    try {
      await translationService.updateTranslation(selectedLang, { key, value });
      dismissibleSuccessToast('Updated successfully!');
      const updatedIndex = translations.findIndex(it => it.key === key);
      setTranslations(prev => {
        const modifiedArray = [...prev];
        modifiedArray[updatedIndex] = { ...prev[updatedIndex], [selectedLang]: value };
        return modifiedArray;
      });
    } catch (e: unknown) {
      dismissibleErrorToast('Error: ' + (e as Error).message);
    }
  };

  const checkTranslationValidity = (newValue, translation) => {
    const regex = /\{\{([^}]+)}}/g;
    const neededVariables = (translation.en.match(regex) || []).map(match => match.trim());
    const currentVariables = (newValue.match(regex) || []).map(match => match.trim());
    const isValid = neededVariables.every(needed => currentVariables.find(current => current === needed));
    if (!isValid) dismissibleErrorToast(`Missing variables: \n${neededVariables.filter(it => !currentVariables.includes(it)).join('\n')}`);
    return isValid;
  };
  const handleBlur = async (e, translation: Translation) => {
    const newValue = e.target.value;
    const prevValue = translation[selectedLang] || '';
    if (prevValue === newValue) return;
    if (checkTranslationValidity(newValue, translation)) {
      await updateTranslation(translation.key, newValue);
    }
  };

  const handleConfirm = async (newValue: string, translation: Translation) => {
    if (checkTranslationValidity(newValue || '', translation)) {
      await updateTranslation(translation.key, newValue);
      setReloadTrigger(prev => !prev);
    }
  };

  const resizeTextarea = e => {
    e.currentTarget.style.height = 'auto';
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  };

  const selectLang = lang => {
    setSelectedLang(lang);
    setTranslations(sortTranslations(translations, lang));
    dismissibleSuccessToast(`Changed language to: ${lang}`);
  };

  const downloadTranslationJson = async () => {
    const data = await translationService.getLanguage(selectedLang);
    downloadBlob(JSON.stringify(data), 'application/json', `${selectedLang}.json`);
  };

  const handleKeyDown = (event, index: number, translation, selectedLang) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) return;
      event.preventDefault();
      if (checkTranslationValidity(event.target.value, translation)) {
        const nextInputElement = document.getElementById(`input-textarea-${index + 1}`);
        if (nextInputElement) nextInputElement.focus();
        else event.target.blur();
      }
    }
    if (event.key === 'Escape') {
      event.target.value = translation[selectedLang];
      event.target.blur();
    }
  };

  function getHighlightStyle(translation: Translation) {
    if (translation.outdatedFor.includes(selectedLang)) return { borderLeft: '8px solid rgba(255, 183, 0)' };
    if (!translation[selectedLang]) return { borderLeft: '8px solid rgb(208,80,78)' };
    return { borderLeft: '8px solid rgb(92, 180, 112)' };
  }

  return (
    <section className={styles.container}>
      <Link to={'/'} className={styles.backButton}>
        <ArrowLeft />
      </Link>
      <h1>Translation Tool</h1>
      <div className={styles.languageSelector}>
        <h3>Selected language:</h3>
        {user.translatorFor.sort((a, b) => a.localeCompare(b)).map(it => {
          return (
            <FunctionButton
              key={it}
              onClick={() => selectLang(it)}
              className={it === selectedLang ? styles.selected : ''}
            >
              {it}
            </FunctionButton>
          );
        })}
      </div>
      <div className={styles.headerContainer}>
        <div></div>
        <div className={styles.infoContainer}>
          <div>
            <h3>All: {translations.length}</h3>
          </div>
          <div>
            <h3>Outdated: {translations.filter(it => it.outdatedFor.includes(selectedLang)).length}</h3>
          </div>
          <div>
            <h3>Missing: {translations.filter(it => !it[selectedLang]).length}</h3>
          </div>
          <div>
            <h3>Progress: {Math.floor(translations.filter(it => it[selectedLang]).length / translations.length * 100)}%</h3>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <FunctionButton icon={<CloudDownload />} onClick={downloadTranslationJson}>
            Download {selectedLang}.json
          </FunctionButton>
        </div>
      </div>
      <ol>
        {translations.map((translation, index) => {
          const englishWithHighlights = {
            __html: `<span>${translation?.en?.replaceAll('{{', '<span style="color: grey">{{').replaceAll('}}', '}}</span>')}</span>`
          };
          return (
            <li key={translation.key + selectedLang} className={styles.row} style={getHighlightStyle(translation)}>
              <div>
                <Tooltip overlay={<div style={{ fontSize: '1rem' }}>Key: {translation.key}</div>}>
                  <span
                    dangerouslySetInnerHTML={englishWithHighlights}>
                  </span>
                </Tooltip>
              </div>
              <div className={styles.translation}>
                <textarea
                  onBlur={e => handleBlur(e, translation)}
                  defaultValue={translation[selectedLang]}
                  placeholder={'Translation needed'}
                  onInput={e => resizeTextarea(e)}
                  onKeyDown={e => handleKeyDown(e, index, translation, selectedLang)}
                  id={`input-textarea-${index}`}
                />
                {translation.outdatedFor.includes(selectedLang) &&
                  <>
                    <Tooltip overlay={'The original English text for this key has been updated since it was translated.'}>
                      <InfoCircle />
                    </Tooltip>
                    <LegacyButton variant={'secondary'} onClick={() => handleConfirm(translation[selectedLang], translation)}>Confirm</LegacyButton>
                  </>
                }
              </div>
            </li>
          );
        })}
      </ol>
    </section>
  );
}
