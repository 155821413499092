import { AxiosInstance } from 'axios';
import { ManagedArea } from './ManagedArea';
import { ManagedAreaDto } from './ManagedAreaDto';

export class ManagedAreaService {
  constructor(private readonly http: AxiosInstance) {}

  async list(organizationId: string): Promise<ManagedArea[]> {
    const response = await this.http.get<ManagedAreaDto[]>(`/v1/organizations/${organizationId}/managed-areas`);

    return response.data.map(ManagedArea.fromDto).sort((ma1, ma2) => ma1.getName().localeCompare(ma2.getName()));
  }

  async findByName(organizationId: string, name: string, limit?: number): Promise<ManagedArea[]> {
    const response = await this.http.get<ManagedAreaDto[]>(`/v1/organizations/${organizationId}/managed-areas/${name}${limit ? `?limit=${limit}` : ''}`);

    return response.data.map(ManagedArea.fromDto);
  }
}
