import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import { OpenNewWindow } from 'iconoir-react';
import React, { ChangeEvent, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import styles from '../../Explore/table-view/components/TableRow.module.scss';
import { FrameIcon, IssuesFoundIcon, NoIssuesIcon } from '../../../components/UI/Icon/Icon';
import PartialTree from '../../../tree/PartialTree';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentAccount } from '../../../account/useAccounts';
import { DetailsBackUrl } from '../../../utils/DetailsBackUrl';
import TableColumnSelector from './TableColumnSelector';
import { SortButton, SortingDirection } from '../../../components/UI/SortButton/SortButton';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import GraphModal from '../../Explore/workspace/components/GraphModal';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import {
  columnConfiguration,
  defaultColumnConfiguration,
  Property as WorkspaceProperty
} from '../../Explore/workspace/TreeTable';

export default function TreeTable(props: TreeTableProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { organization } = useCurrentAccount();
  const managedAreas = useManagedAreas(organization.id);
  const { urlContext } = useContext(DependencyInjectionContext);
  const windSpeed = urlContext.getWindSpeed() || organization.defaultWindSpeed;

  const [graphModalProperty, setGraphModalProperty] = React.useState<string | null>(null);

  const navigateToTreeDetails = (treeId: string) => {
    DetailsBackUrl.store(treeId, location);
    navigate(`/organizations/${organization.id}/inventory/trees/${treeId}`);
  };

  return (
    <div className="mt-6 rounded-t-lg overflow-hidden shadow-lg">
      <div className="overflow-auto max-h-[calc(100vh-300px)]">
        <table className="text-outer-space-50 w-full">
          <thead className="bg-outer-space-900">
            <tr>
              <th
                className="bg-outer-space-900 sticky top-0 z-10 h-14 p-4 px-6 w-0.5">
                <Checkbox
                  checked={props.selectedTreeIds.length === props.trees.length}
                  onChange={() => props.setSelectedTreeIds(props.selectedTreeIds.length === props.trees.length ? [] : props.trees.map(tree => tree.id))}
                />
              </th>
              <TableHeaderCell
                property={'externalId'}
                sortingDirection={props.sortingDirection}
                sortProperty={props.sortProperty}
                handleSort={props.handleSort}
                hideSorting={true}
              />
              <TableHeaderCell
                property={'managedAreaName'}
                sortingDirection={props.sortingDirection}
                sortProperty={props.sortProperty}
                handleSort={props.handleSort}
                hideSorting={true}
              />
              {props.selectedFields.map(field => (
                <TableHeaderCell
                  key={field}
                  property={field}
                  sortingDirection={props.sortingDirection}
                  sortProperty={props.sortProperty}
                  handleSort={props.handleSort}
                  setGraphModalProperty={setGraphModalProperty}
                />
              ))}
              <TableHeaderCell
                sortingDirection={props.sortingDirection}
                sortProperty={props.sortProperty}
                handleSort={props.handleSort}
              >
                <TableColumnSelector properties={props.selectedFields} setProperties={props.setSelectedFields} />
              </TableHeaderCell>
            </tr>
          </thead>
          <tbody className="bg-outer-space-700">
            {props.trees.map((tree, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 border border-outer-space-800">
                  <Checkbox
                    checked={props.selectedTreeIds.includes(tree.id)}
                    onChange={event => props.handleTreeSelection(event, tree.id)}
                  />
                </td>
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 border border-outer-space-800"
                  onClick={() => navigateToTreeDetails(tree.id)}
                >
                  <div className="flex items-center gap-2 cursor-pointer">
                    {tree.externalId}
                    <OpenNewWindow />
                  </div>
                </td>
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 border border-outer-space-800"
                >
                  {tree.managedAreaName}
                </td>
                {props.selectedFields.map(field => (
                  <td
                    key={field}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border border-outer-space-800">
                    <TableCell
                      property={field}
                      value={
                        field === DisplayableTreeProperty.SafetyFactors
                          ? tree.getSafetyFactor(windSpeed)
                          : (field === 'managedAreaId' ? tree['managedAreaName'] : tree[field])
                      } /></td>
                ))}
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border border-r-0 border-outer-space-800"></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {graphModalProperty && <GraphModal
        onClose={() => setGraphModalProperty(null)}
        property={graphModalProperty}
        managedAreas={managedAreas.managedAreaList}
        taskOrTaskTemplateId={props.taskOrTaskTemplateId}
      />}
    </div>
  );
}

const TableHeaderCell = (props: TableHeaderCellProps) => {
  const { t } = useTranslation();
  const configuration = getColumnConfiguration(props.property as WorkspaceProperty);
  return (
    <th className="bg-outer-space-900 border-l border-outer-space-800 sticky top-0 z-10 text-nowrap">
      <div className="h-14 p-4 justify-between items-center flex w-full">
        <span className="text-base font-medium font-['Inter'] leading-normal">
          {props.property ? t(`tree.${props.property}`) : props.children}
        </span>
        <div className="flex items-center gap-1">
          {configuration.graph && props.property !== DisplayableTreeProperty.SafetyFactors && (
            <div>
              <FunctionButton
                icon={<FrameIcon />}
                className="flex items-center p-2 ml-2"
                onClick={() => {
                  if (props.setGraphModalProperty && props.property) {
                    props.setGraphModalProperty(props.property);
                  }
                }}
              />
            </div>
          )}
          {!props.hideSorting && props.property && <SortButton
            sortingDirection={props.sortProperty === props.property ? props.sortingDirection : null}
            onClick={() => props.handleSort(props.property!)} />}
        </div>
      </div>
    </th>
  );
};

function TableCell({ property, value }: { property: Property, value: string | number | Array<{ name: string }> }) {
  const { t } = useTranslation();
  const locale = navigator.language;

  if (property === DisplayableTreeProperty.Status) {
    return <div>{value ? t('tree.statusTypes.' + value) : '-'}</div>;
  }

  if (property === DisplayableTreeProperty.VitalityVigor) {
    return <div>{value ? t('tree.vitalityVigorTypes.' + value) : '-'}</div>;
  }

  if (property === DisplayableTreeProperty.ViStatus) {
    return <div>{value ? t('virtualInspection.status.' + value) : '-'}</div>;
  }

  if (Tree.isViBooleanProperty(property)) {
    return <div className={styles.inspectionCell}>{value ? <NoIssuesIcon /> : <IssuesFoundIcon />}</div>;
  }

  if (value instanceof Array) {
    return <span
      className={styles.columnValue}
      title={value.map(it => t(Tree.getTKeyForProperty(property, it.name))).join(', ')}
    >
      {value.map(it => t(Tree.getTKeyForProperty(property, it.name))).join(', ')}
    </span>;
  }

  return <span
    className={`${styles.columnValue} ${Tree.ITALIC_PROPERTIES.includes(property) ? styles.italic : ''}`}
    title={property === 'species' ? value as string : ''}
  >
    {typeof value === 'number' ? new Intl.NumberFormat(locale, { maximumFractionDigits: 1 }).format(value) : value || '-'}
  </span>;
}

function getColumnConfiguration(property: WorkspaceProperty) {
  return columnConfiguration.get(property) ?? defaultColumnConfiguration;
}

interface TreeTableProps {
  trees: PartialTree[],
  selectedTreeIds: string[],
  setSelectedTreeIds: (selectedTreeIds: string[]) => void,
  selectedFields: DisplayableTreeProperty[],
  setSelectedFields: (selectedFields: DisplayableTreeProperty[]) => void,
  handleTreeSelection: (event: ChangeEvent<HTMLInputElement>, treeId: string) => void,
  sortProperty: string | null,
  sortingDirection: SortingDirection | null,
  handleSort: (property: string) => void,
  taskOrTaskTemplateId: string
}

interface TableHeaderCellProps {
  property?: Property,
  hideSorting?: boolean,
  children?: ReactNode,
  sortProperty: string | null,
  sortingDirection: SortingDirection | null,
  handleSort: (property: string) => void,
  setGraphModalProperty?: (property: string) => void
}

export type Property = DisplayableTreeProperty | 'externalId' | 'managedAreaName';
